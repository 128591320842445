import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';
import React, { VFC } from 'react';
import { useForm } from 'react-hook-form';
import { Button, useMessages } from 'components';
import { ErrorProvider, Input } from 'components/forms';
import { TextArea } from 'components/forms/TextArea';
import HubspotSubmit from 'utils/HubspotSubmit';
import Validation from './Validation';

export type ContactFormData = {
  name: string;
  email: string;
  text: string;
};

export const ContactForm: VFC = () => {
  const { alert } = useMessages();

  const { formState, handleSubmit, register } = useForm<ContactFormData>({
    defaultValues: {
      name: '',
      email: '',
      text: '',
    },
    resolver: yupResolver(Validation),
  });

  async function onSubmit({ name, email, text }: ContactFormData) {
    try {
      const portalId = '20147764';
      const formGuid = 'c3736048-203d-47fd-9560-df55d47b24a1';
      const fields = [
        {
          name: 'firstname',
          value: name,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'message',
          value: text,
        },
      ];

      await HubspotSubmit({ portalId, formGuid, fields });

      alert({
        key: 'ContactForm',
        type: 'success',
        title: 'Your message has been successfuly submitted!',
        message: 'Trade Jobs will be in touch shortly.',
        dismissable: true,
        duration: 8000,
      });
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'ContactForm',
          type: 'error',
          title: err?.message,
          dismissable: true,
        });
      }
      captureException(err);
    }
  }
  return (
    <form id="contact" onSubmit={handleSubmit(onSubmit)}>
      <ErrorProvider errors={formState.errors}>
        <Input
          {...register('name')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          type="text"
          label="Name"
          placeholder="Name"
        />
        <Input
          {...register('email')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          type="text"
          label="Email"
          placeholder="Email"
        />
        <TextArea
          {...register('text')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          label="Message"
          rows={4}
          placeholder="Minimum 20 characters..."
        />
        <div className="flex justify-end">
          <Button type="submit" color="black">
            Submit
          </Button>
        </div>
      </ErrorProvider>
    </form>
  );
};
