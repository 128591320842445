import { SVGProps, VFC } from 'react';

export const EditIcon: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="currentColor"
    {...props}
  >
    <path d="M14.9678 4.03405C15.2798 3.72205 15.2798 3.20205 14.9678 2.90605L13.0958 1.03405C12.7998 0.722049 12.2798 0.722049 11.9678 1.03405L10.4958 2.49805L13.4958 5.49805L14.9678 4.03405ZM0.799805 12.202V15.202H3.7998L12.6478 6.34605L9.64781 3.34605L0.799805 12.202Z" />
  </svg>
);
