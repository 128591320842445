import { FC } from 'react';
import type { GetEmployeeProfileQuery } from 'database/types';
import PencilIcon from 'components/register/RegisterProfileEdit/PencilIcon';
// import { getFirstLetterOfNames } from 'components/register/RegisterProfileEdit/helpers';
import { ProfileImage } from 'components/ProfileImage';
import PhoneIcon from './PhoneIcon';
import EmailIcon from './EmailIcon';
import ToolsIcon from './ToolsIcon';
import LocationIcon from './LocationIcon';

type ProfileEditHeaderProps = {
  userData?: GetEmployeeProfileQuery['user_by_pk'];
  hideTitle?: boolean;
  editAction?: () => void;
};

const ProfileEditHeader: FC<ProfileEditHeaderProps> = ({
  userData,
  hideTitle,
  editAction,
}) => {
  if (!userData) {
    return null;
  }

  const { email, first_name, last_name, phone } = userData;

  const categories = userData?.category as string[];
  const location = userData?.location;

  return (
    <>
      {!hideTitle && (
        <h1 className="mb-6 font-satoshi text-[26px] font-bold -tracking-two-percent text-charcoal-500">
          Here is how your profile looks to an employer
        </h1>
      )}
      <div className="mb-6 py-4">
        <div className="relative z-0 flex items-center gap-4">
          <div className="absolute right-0 top-0 z-10">
            <button
              type="button"
              aria-label="Edit profile info"
              onClick={editAction}
            >
              <PencilIcon className="size-4" />
            </button>
          </div>
          <div className="relative z-0 flex size-24 items-center justify-center rounded-full">
            <ProfileImage
              id={userData?.id as string}
              src={(userData?.profile_photo as string) ?? null}
            />
          </div>
          <p className="text-[36px] font-bold -tracking-two-percent text-charcoal-500">
            {first_name || ''} {last_name || ''}
          </p>
        </div>
      </div>
      <div>
        <div className="mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
          <div className="flex items-center gap-2">
            <div className="flex size-5 items-center justify-center rounded-full bg-orange-50">
              <PhoneIcon className="size-4" />
            </div>
            {phone ? (
              <a className="hover:underline" href={`tel:{$phone}`}>
                {phone}
              </a>
            ) : (
              '–'
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="flex size-5 items-center justify-center rounded-full bg-orange-50">
              <ToolsIcon className="size-4" />
            </div>
            <p>
              {categories &&
                categories
                  .slice(0, 3)
                  .map(
                    (category) =>
                      category.charAt(0).toUpperCase() + category.slice(1)
                  )
                  .join(', ')}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex size-5 items-center justify-center rounded-full bg-orange-50">
              <EmailIcon className="size-4" />
            </div>
            {email ? (
              <a className="hover:underline" href={`mailto:${email}`}>
                {email}
              </a>
            ) : (
              '–'
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="flex size-5 items-center justify-center rounded-full bg-orange-50">
              <LocationIcon className="size-4" />
            </div>
            <p>
              {location?.neighbourhood
                ? `${location.neighbourhood}, ${location.region}`
                : location?.region}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEditHeader;
