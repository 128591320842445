type HeaderDataProps = {
  company?: string;
  locationString: string;
  category?: {
    label: string;
  };
  work_type?: {
    label: string;
  };
};

const HeaderData: React.FC<HeaderDataProps> = ({
  company,
  locationString,
  category,
  work_type,
}) => {
  return (
    <>
      <div className="flex items-baseline justify-between space-x-2">
        <p className="text-lg text-charcoal-400">
          {company}
          <span className="px-2"> • </span>
          {locationString}
        </p>
      </div>
      <p className="text-base text-charcoal-200">
        {category?.label}
        <span className="px-2"> • </span>
        {work_type?.label}
      </p>
    </>
  );
};

export default HeaderData;
