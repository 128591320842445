import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: ReactNode;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function CheckboxRef({ className = '', label, onChange, ...props }, ref) {
    return (
      <div className={`flex items-center ${className}`}>
        <input
          ref={ref}
          type="checkbox"
          className="size-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
          onChange={onChange}
          {...props}
        />
        <label
          htmlFor={props?.name}
          className="ml-2 block text-sm text-gray-500"
        >
          {label}
        </label>
      </div>
    );
  }
);
