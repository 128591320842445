import { SVGProps, VFC } from 'react';

export const RotateRight: VFC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <g fill="currentColor" transform="scale(0.0234375 0.0234375)">
      <path d="M720 660q34-46 44-106h86q-12 92-68 166zM554 764q60-10 106-44l62 62q-72 56-168 68v-86zM850 470h-86q-10-60-44-106l62-60q58 72 68 166zM664 236l-194 190v-166q-92 16-153 87t-61 165 61 165 153 87v86q-126-16-213-112t-87-226 87-226 213-112v-132z" />
    </g>
  </svg>
);
