import { PlusIcon } from '@heroicons/react/24/solid';
import { CardElement, useStripe } from '@stripe/react-stripe-js';
import {
  SetupIntent,
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { twMerge } from 'tailwind-merge';
import React, { useEffect, useState, VFC } from 'react';
import { Button, FullPageLoader, StripeElements } from 'components';
import { ExistingCard } from 'components/CreditCards/CardManagement/ExistingCard';
import { Checkbox } from 'components/forms';
import { usePaymentMethods } from 'utils/stripe';

export type CardManagementProps = {
  onAdd?: (setupIntern: SetupIntent) => void | Promise<void>;
};

export const CardManagement: VFC<CardManagementProps> = (props) => (
  <StripeElements>
    <Cards {...props} />
  </StripeElements>
);

const Cards: VFC<CardManagementProps> = ({ onAdd }) => {
  const { paymentMethods, loading, defaultId, setDefault, addCard, detach } =
    usePaymentMethods();

  // Add Card
  const stripe = useStripe();

  const [card, setCard] = useState<StripeCardElement>();
  const [cardState, setCardState] = useState<
    Partial<StripeCardElementChangeEvent>
  >({});

  // UI
  const [showNew, setShowNew] = useState(false);
  useEffect(() => {
    setShowNew(!paymentMethods?.length);
  }, [paymentMethods]);
  const handleShowNew = () => {
    if (showNew) return;
    setShowNew(true);
    setShouldSetDefault(false);
  };

  const [shouldSetDefault, setShouldSetDefault] = useState(false);
  const [cardError, setCardError] = useState<string>();
  useEffect(() => setCardError(cardState.error?.message), [cardState]);

  const handleAddCard = async () => {
    setCardError(undefined);

    if (!stripe || !card) return;

    const response = await addCard(stripe, card, shouldSetDefault);

    if (response?.error?.message) setCardError(response.error.message);
    else {
      setShowNew(false);
      onAdd && response?.setupIntent && onAdd(response.setupIntent);
      card.clear();
    }
  };

  if (loading) return <FullPageLoader />;
  if (!paymentMethods) return <>Error</>;

  return (
    <div>
      <label className="mb-1 block text-sm uppercase tracking-wider text-grey">
        Saved Cards
      </label>

      {/* Existing Cards */}
      {paymentMethods?.length > 0 ? (
        <div className="space-y-1">
          {paymentMethods?.map(
            ({ id, card }) =>
              card && (
                <ExistingCard
                  key={id}
                  isDefault={id === defaultId}
                  {...{ id, card, setDefault, detach }}
                />
              )
          )}
        </div>
      ) : (
        <div className="my-2 text-center">You don't have any saved cards</div>
      )}
      {/* Add New */}
      <label
        htmlFor="add-new-card"
        className="mb-2 mt-4 inline-flex cursor-pointer text-sm uppercase tracking-wider text-grey"
        onClick={handleShowNew}
      >
        <PlusIcon className="mr-3 w-4" />
        <div>Add New Card</div>
      </label>
      <div className={!showNew ? 'hidden' : undefined}>
        <CardElement
          id="add-new-card"
          options={{
            hidePostalCode: true,
          }}
          onReady={setCard}
          onChange={setCardState}
          className={twMerge(
            'rounded border border-light bg-white p-4',
            cardError &&
              'border-red-500 focus:border-red-700 focus:ring-red-500'
          )}
        />

        <div className="relative min-h-[16px] text-xs text-red-500">
          {cardError}
        </div>

        {defaultId && (
          <Checkbox
            className="mt-2"
            label="Set as default card for future payments"
            checked={shouldSetDefault}
            onChange={({ target: { checked } }) => setShouldSetDefault(checked)}
          />
        )}
        <div className="flex w-full justify-end">
          <Button
            size="small"
            color="black"
            className="mt-4"
            disabled={showNew && (!cardState.complete || !!cardError)}
            onClick={handleAddCard}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
