import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMessages } from 'components/Messages';
import { Button } from 'components/Button';
import { ErrorProvider, Input, Select } from 'components/forms';
import { schema, onSubmit, ContactFormValues } from '../onSubmit';

const MESSAGE_KEY = 'CONTACT_FORM_SLACK_N8N';

function buildV(values: string[]) {
  return values.map((i) => ({ value: i, label: i }));
}

interface ContactFormProps {
  integration?: boolean;
}

export function ContactForm({ integration = false }: ContactFormProps) {
  const { alert, update } = useMessages();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<ContactFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      company: '',
      role: '',
      location: '',
      integration: '',
      requirements: [],
      email: '',
      phoneNumber: '',
    },
    resolver: yupResolver(schema) as any,
  });

  const submit = async (a: ContactFormValues): Promise<void> => {
    if (loading) return;
    alert({
      key: MESSAGE_KEY,
      title: 'Processing request',
      type: 'processing',
      dismissable: false,
    });
    setLoading(true);
    try {
      await onSubmit(a);

      update({
        key: MESSAGE_KEY,
        title: 'Message sent',
        type: 'success',
        duration: 3000,
        dismissable: true,
      });
      reset();
    } catch (err) {
      update({
        key: MESSAGE_KEY,
        title: 'Something went wrong',
        message: (err as any)?.message,
        type: 'error',
        duration: 3000,
        dismissable: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const requirements = watch('requirements')?.values();
  const showIntegration = !!(
    requirements && Array.from(requirements).some((i) => i === 'Integration')
  );

  return (
    <form
      id={`__contact_employer${integration ? '_integration' : ''}`}
      className={`__contact_employer${integration ? '_integration' : ''}`}
      onSubmit={handleSubmit((a) => submit(a))}
    >
      <ErrorProvider errors={errors as any}>
        <div className="grid grid-cols-2 gap-4">
          <Input
            className="text-white"
            {...register('firstName')}
            label="First name"
            placeholder="First name"
            required
          />
          <Input
            {...register('lastName')}
            label="Last name"
            placeholder="Last name"
          />
          <Input
            {...register('company')}
            label="Company"
            placeholder="Company name"
            required
          />
          <Input {...register('role')} label="Role" placeholder="Your role" />
        </div>
        <Input
          {...register('location')}
          label="Location"
          placeholder="Your location"
        />
        {integration && (
          <>
            <Controller
              control={control}
              name="requirements"
              render={({ field }) => (
                <Select
                  {...field}
                  label="Requirements"
                  multiselect
                  options={buildV([
                    'Job listing',
                    'Integration',
                    'Brand awareness',
                    'Job writing',
                    'Marketing',
                  ])}
                />
              )}
            />

            <Controller
              control={control}
              name="integration"
              render={({ field }) => (
                <Select
                  {...field}
                  className={showIntegration ? 'block' : 'hidden'}
                  name="integration"
                  label="Integration"
                  options={[
                    ...buildV(['JobAdder', 'Broadbean', 'Snaphire', 'Other']),
                    { value: '', label: 'none' },
                  ]}
                />
              )}
            />
          </>
        )}
        <Input
          label="Email address"
          placeholder="Email address"
          required
          {...register('email')}
        />
        <Input
          label="Phone number"
          placeholder="Phone number"
          required
          {...register('phoneNumber')}
        />
        <Button
          color={'primary'}
          disabled={isSubmitting}
          loading={isSubmitting}
          type="submit"
        >
          Send message
        </Button>
      </ErrorProvider>
    </form>
  );
}
