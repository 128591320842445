import { useEffect, useState } from 'react';
// import { useRouter } from 'next/router';
// import { useJitsu } from '@jitsu/nextjs';
import { AnimatePresence, motion } from 'framer-motion';
import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export function Features({
  id,
  // title,
  subtitle,
  description,
  price,
  info,
  plan,
  setPlan,
  bundles,
}: any): JSX.Element | null {
  const [open, setOpen] = useState<string>(info[0].id);
  const img = info.find((i: any) => i.id === open)?.image[0];
  // const router = useRouter();
  // const { track } = useJitsu();
  useEffect(() => {
    setOpen(info[0].id);
  }, [id]);
  return (
    <section className="mx-auto mt-32 max-w-7xl px-6">
      <div className="grid grid-cols-1 justify-between gap-8 align-top md:grid-cols-2">
        <div>
          <div className="mx-auto max-w-2xl">
            <div className="pb-6">
              <RadioGroup
                value={plan}
                onChange={setPlan}
                className=" grid min-w-[300px] grid-cols-3 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">
                  Select Plan
                </RadioGroup.Label>
                {bundles.map((option: any) => (
                  <RadioGroup.Option
                    key={option?.id}
                    value={option}
                    className={({ checked }) =>
                      twMerge(
                        checked ? 'bg-primary text-white' : 'text-gray-500',
                        'cursor-pointer rounded-full px-2.5 py-1'
                      )
                    }
                  >
                    <span>{option?.title}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
            <div className="rounded-3xl p-4 ring-1 ring-gray-900/10">
              <p className="text-sm font-light italic">{subtitle}</p>
              <div className="mt-6 flex gap-2">
                <div className="flex">
                  <p className="text-4xl font-medium tracking-tight text-gray-900">
                    ${price}
                  </p>
                </div>
                <div className="text-sm">
                  <p className="font-medium">one-time payment</p>
                  <p className="font-light text-gray-600">plus gst</p>
                </div>
              </div>
              <p className="mt-6 text-sm leading-6 text-gray-600">
                {description}
              </p>
            </div>
          </div>
          <div className="mt-9 grid gap-7">
            <dl className="col-span-2 grid grid-cols-1 gap-1 lg:grid-cols-2">
              {info.map(({ id, title, description }: any) => (
                <div
                  key={id}
                  onMouseEnter={() => setOpen(id)}
                  className={twMerge(
                    'cursor-pointer rounded-3xl border border-transparent bg-white p-4 transition-all hover:drop-shadow-md ',
                    id === open && 'drop-shadow-md'
                  )}
                >
                  <dt className="text-base font-medium leading-7 text-gray-900">
                    {title}
                  </dt>
                  <dd className="mt-1 text-base leading-7 text-gray-600">
                    {description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="mt-16 sm:mt-24 lg:mt-0 lg:shrink-0 lg:grow">
          <svg
            viewBox="0 0 366 729"
            role="img"
            className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
          >
            <title>App screenshot</title>
            <defs>
              <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                <rect width={316} height={684} rx={36} />
              </clipPath>
            </defs>
            <path
              fill="#E37839"
              d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
            />
            <path
              fill="#E06C29"
              d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
            />
            <foreignObject
              width={316}
              height={684}
              transform="translate(24 24)"
              clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
            >
              {img && (
                <AnimatePresence>
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    src={img.src as string}
                    alt={img.alt}
                    width={img.width}
                    height={img.height}
                  />
                </AnimatePresence>
              )}
            </foreignObject>
          </svg>
        </div>
      </div>
    </section>
  );
}
