import { twMerge } from 'tailwind-merge';
import { ChangeEvent, FC, InputHTMLAttributes } from 'react';

export type MonthPickerInputProps = InputHTMLAttributes<Element> & {
  className?: string;
  onChange: (a: string) => void;
  value: string;
};

export const MonthPickerInput: FC<MonthPickerInputProps> = ({
  className,
  onChange,
  value,
  ...rest
}) => (
  <div>
    <input
      {...rest}
      type="number"
      className={twMerge(
        'block rounded-lg border border-charcoal-50 h-12 py-3 px-4 text-base text-charcoal-500 bg-white placeholder:text-gray placeholder:text-opacity-50 focus:border-orange-500 focus:ring-1 focus:ring-orange-500 w-full',
        className
      )}
      value={value}
      min="1900"
      max="2100"
      onChange={(a: ChangeEvent<HTMLInputElement>) => {
        const n = a.target.value;
        if (value?.length === 4 && n?.length >= 4) return;
        if (n === '' && value?.length !== 1) return;
        onChange(n);
      }}
      placeholder="Year"
    />
  </div>
);
