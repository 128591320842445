export const CAMPAIGN_IMAGE = [
  {
    url: '/assets/images/portal/packages/social/1.png',
    sizes: 'w-[180px] h-[227px]',
  },
  {
    url: '/assets/images/portal/packages/social/2.png',
    sizes: 'w-[204px] h-[257px]',
  },
  {
    url: '/assets/images/portal/packages/social/3.png',
    sizes: 'w-[180px] h-[227px]',
  },
];

export const CARDS_TEXT = [
  {
    title: 'Featured listing',
    description: 'Stand out from the rest and be seen first.',
  },
  {
    title: 'Social media campaign',
    description:
      'We make sure your job ad is seen on tradies favourite platforms.',
  },
  {
    title: 'Feature on our homepage',
    description: 'Stand out from the rest and be seen first.',
  },
  {
    title: '\u2018Jobs of the Week\u2019 email highlight',
    description: 'Your ad sent to the inboxes of our tradie database.',
  },
  {
    title: 'We write your job ad',
    description: `We'll write a well measured job ad that highlights the benefits of working with your team.`,
  },
  {
    title: 'Radio advertising',
    description:
      'Reach the metros as well as the regions with an attention grabbing radio ad.',
  },
];
