import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type HalfImageProps = PropsWithChildren<{
  alternateWidth?: boolean;
  img: string;
  mobileOrder?: 'top' | 'bottom';
  overlay?: 'primary' | 'black';
  side?: 'left' | 'right';
}>;

export const HalfImage: FC<HalfImageProps> = ({
  alternateWidth,
  img,
  mobileOrder = 'bottom',
  overlay,
  side = 'right',
  children,
}) => (
  <div className="relative flex size-full flex-col lg:min-h-[480px] lg:flex-row lg:items-stretch">
    <div
      className={twMerge(
        'flex items-center py-16 text-left lg:w-1/2 lg:max-w-xl',
        side === 'left' ? 'mr-auto lg:!order-2' : 'ml-auto',
        mobileOrder === 'top' ? 'order-2 lg:order-none' : ''
      )}
    >
      <div>{children}</div>
    </div>

    {/* Image column */}
    <div
      className={twMerge(
        'relative order-3 h-80 w-full lg:h-auto lg:w-1/2',
        side === 'left' ? 'lg:!order-1' : '',
        mobileOrder === 'top' ? 'order-1 lg:order-none' : ''
      )}
    >
      {/* Image holder */}
      <div
        className={twMerge(
          // 'relative w-full h-full',
          'absolute inset-0 lg:inset-auto lg:inset-y-0',
          alternateWidth
            ? side === 'right'
              ? 'lg:absolute lg:right-0 lg:w-4/5'
              : 'lg:left-0 lg:w-4/5'
            : ''
        )}
      >
        <img
          className={twMerge('h-full w-full object-cover')}
          src={img}
          alt=""
        />
        {overlay && (
          <div
            className={twMerge(
              'absolute inset-0 z-10 opacity-20',
              overlay === 'primary' ? 'bg-primary' : 'bg-black'
            )}
          />
        )}
      </div>
    </div>
  </div>
);
