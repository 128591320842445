import { VFC } from 'react';
import dayjs from 'dayjs';
import { BaseInfoCardProps, InfoCard } from 'components';
import { User_Education } from 'database/types';

export type EducationCardProps = BaseInfoCardProps &
  Pick<User_Education, 'institution' | 'issue_date' | 'complete'>;

export const EducationCard: VFC<EducationCardProps> = ({
  institution: company,
  issue_date,
  complete,
  ...props
}) => {
  const end = dayjs(issue_date).format('YYYY');
  const corner_info = !complete ? 'In progress' : `Graduated ${end}`;

  return <InfoCard subtitle={company} corner_info={corner_info} {...props} />;
};
