import { Amplify } from 'aws-amplify';
import { NextApiRequest } from 'next';

import Stripe from 'stripe';
import config from 'aws-config';
import getDBClient, { getDBAdminClient } from 'database/DBClient';
import {
  GetStripeIdDocument,
  GetStripeIdQuery,
  GetStripeIdQueryVariables,
  UpdateStripeIdDocument,
  UpdateStripeIdMutation,
  UpdateStripeIdMutationVariables,
} from 'database/types';

Amplify.configure(config, { ssr: true });

export const getStripeAdminClient = () =>
  new Stripe(process.env.STRIPE_SERVER_KEY ?? '', {
    apiVersion: '2023-10-16',
  });

type StripeClientOptions = {
  admin?: boolean;
  skipCustomer?: boolean;
};

export const getStripeClient = async (
  apiReq: NextApiRequest,
  { admin = false, skipCustomer = false }: StripeClientOptions = {}
) => {
  const req = Object.assign(apiReq, {});
  const employerId = req.body.employerId ?? (req.query.employerId as string);

  if (!employerId && !skipCustomer && !admin) throw Error('Missing employerId');
  const db = admin
    ? getDBAdminClient()
    : getDBClient({
        token: apiReq.headers.authorization?.split(' ')[1],
        anonymous: false,
      });

  const res = await db.query<GetStripeIdQuery, GetStripeIdQueryVariables>({
    query: GetStripeIdDocument,
    variables: { id: employerId },
  });

  let { stripe_id } = res?.data.employer_by_pk ?? {};
  const { name } = res?.data.employer_by_pk ?? {};
  const stripe = getStripeAdminClient();

  if (!stripe_id && !skipCustomer) {
    // create stripe user and update employer
    const customer = await stripe.customers.create({
      metadata: { employer_id: employerId },
      shipping: {
        name: name as string,
        address: {
          country: 'NZ',
        },
      },
    });

    stripe_id = customer.id;

    await db.mutate<UpdateStripeIdMutation, UpdateStripeIdMutationVariables>({
      mutation: UpdateStripeIdDocument,
      variables: { id: employerId, stripe_id },
    });
  }

  return { stripe, employerId, stripe_id: stripe_id ?? undefined };
};
