import { twMerge } from 'tailwind-merge';
import { Dispatch, SetStateAction } from 'react';
import { Button } from 'components/Button';
import { Bundle } from '../../selfService/Pricing/SmallTab';

interface BundleItemProps extends Omit<Bundle, 'info'> {
  selected: boolean;
  checkout?: boolean;
  onClick: Dispatch<SetStateAction<string>> | null;
}

function BundleItem({
  id,
  title,
  subtitle,
  price,
  recommended,
  description,
  features = [],
  selected,
  onClick,
  checkout = false,
}: BundleItemProps) {
  return (
    <div
      onClick={() => (onClick ? onClick(id) : null)}
      className={twMerge(
        selected && 'ring-2 ring-primary',
        'relative flex cursor-pointer flex-col justify-between rounded-3xl p-6 shadow xl:p-10'
      )}
    >
      <div>
        <div className="flex items-center justify-between gap-x-4">
          <div>
            <h3 id={id} className="font-medium leading-8 text-primary">
              {title}
            </h3>
            {subtitle && (
              <p className="text-sm font-light italic">{subtitle}</p>
            )}
          </div>
          {recommended && (
            <p className="absolute -top-5 right-5 rounded-full bg-primary px-4 py-2 text-sm font-medium leading-5 text-white">
              Recommended
            </p>
          )}
        </div>
        <div className="mt-6 flex gap-2">
          <div className="flex">
            <p className="text-4xl font-medium tracking-tight text-gray-900">
              ${price}
            </p>
          </div>
          <div className="text-sm">
            <p className="font-medium">one-time payment</p>
            <p className="font-light text-gray-600">plus gst</p>
          </div>
        </div>
        <p className="mt-6 text-sm leading-6 text-gray-600">{description}</p>

        {features.length > 0 && (
          <ul
            role="list"
            className="mt-6 space-y-6 border-t-2 border-t-background-300 pt-6 text-sm leading-6 text-gray-600 xl:mt-10"
          >
            {features.map((feature) => (
              <li key={feature} className="flex gap-x-5 font-medium">
                {feature}
              </li>
            ))}
          </ul>
        )}
      </div>
      {onClick && (
        <Button
          color={selected ? 'primary' : 'black'}
          size="small"
          onClick={(e) => e.preventDefault()}
          className={twMerge(
            'mt-8',
            recommended && !selected && 'border border-primary text-primary'
          )}
        >
          {checkout ? 'Buy Now' : 'Register'}
        </Button>
      )}
    </div>
  );
}

export { BundleItem };
