import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components';
import {
  Application,
  InsertApplicationDocument,
  Job_Question,
  UpdateApplicationDocument,
} from 'database/types';
import { NullableAnswer } from 'types/utils';
import { useFormSubmit } from 'utils';
import { ErrorProvider, RadioGroup } from '..';
import makeValidation from './Validation';

const formatValues = ({ job_id, answers, id }: Application) => ({
  ...(id ? { id, updated_at: new Date().toISOString() } : { job_id }),
  answers: answers as NullableAnswer,
});

export type EmployerQuestionsProps = {
  questions?: Pick<Job_Question, 'id' | 'job_id' | 'type' | 'question'>[];
  defaultValues: Application;
  callback: () => void;
  back: () => void;
};

export const EmployerQuestions: FC<EmployerQuestionsProps> = ({
  back,
  callback,
  defaultValues,
  questions = [],
}) => {
  const isUpdate = !!defaultValues?.id;
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(makeValidation(questions)),
    defaultValues: formatValues(defaultValues),
  });
  const onSubmit = useFormSubmit({
    formName: 'EmployerQuestions',
    mutation: isUpdate ? UpdateApplicationDocument : InsertApplicationDocument,
    onSuccess: {
      message: `Application answers ${isUpdate ? 'updated' : 'submitted'}`,
      callback,
    },
  });
  return (
    <form
      id="application_questions"
      onSubmit={handleSubmit((vars) => onSubmit(vars))}
      className="my-6"
    >
      <ErrorProvider errors={formState.errors}>
        {questions?.map(({ id, question }) => (
          <RadioGroup
            control={control}
            name={`answers.${id}` as 'answers'}
            key={id}
            label={question}
          />
        ))}
        <div className="flex justify-end">
          <Button className="mr-2 px-12" color="white" onClick={back}>
            Back
          </Button>
          <Button className="px-12" type="submit" color="black">
            Next
          </Button>
        </div>
      </ErrorProvider>
    </form>
  );
};
