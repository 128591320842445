import { AuthTokens, fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useRouter } from 'next/router';
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FullPageLoader } from 'components';
import { useRouteCheck } from 'components/AuthContext/useRouteCheck';
import { default as NotFound } from 'pages/404';
import { Enum_Role_Enum } from 'database/types';
import useIsBrowser from 'utils/hooks/useIsBrowser';
import { AuthModal } from './AuthModal';

type Claims = {
  'x-hasura-allowed-roles': string[];
  'x-hasura-user-id': string;
  'x-hasura-user-email': string;
  'x-hasura-default-role': 'employer' | 'employee';
};

export type Token = {
  'cognito:groups'?: string[];
  'https://hasura.io/jwt/claims': string;
};

type TokenType = BaseUser & Token;

export function makeUserFromToken(idToken: AuthTokens['idToken']): User {
  const { email, family_name, given_name, ...rest } =
    idToken?.payload as TokenType;
  const claims = JSON.parse(rest['https://hasura.io/jwt/claims']) as Claims;
  return {
    email,
    family_name,
    given_name,
    sub: claims['x-hasura-user-id'],
    role: claims['x-hasura-default-role'],
    token: idToken?.toString(),
  };
}

export async function asyncFetchUserFromToken(): Promise<User> {
  const { tokens } = await fetchAuthSession();
  const token = tokens?.idToken;
  const { email, family_name, given_name, ...rest } =
    token?.payload as TokenType;
  const claims = JSON.parse(rest['https://hasura.io/jwt/claims']) as Claims;
  return {
    email,
    family_name,
    given_name,
    sub: claims['x-hasura-user-id'],
    role: claims['x-hasura-default-role'],
    token: token?.toString(),
  };
}

type BaseUser = {
  email: string;
  family_name: string;
  given_name: string;
  sub: string;
  token?: string;
};

type User = BaseUser & { role: Enum_Role_Enum };

export type AuthContextResult = {
  authenticated: boolean;
  user?: User | null;
  signout: () => void;
  loading: boolean;
  initialLoadComplete: boolean;
  setUser: Dispatch<SetStateAction<User | null | undefined>>;
};

const AuthContext = createContext<AuthContextResult>({
  authenticated: false,
  signout: () => false,
  loading: false,
  initialLoadComplete: false,
  setUser: () => null,
});

type EmptyProps = Record<never, never>;

export const AuthProvider: FC<PropsWithChildren<EmptyProps>> = ({
  children,
}) => {
  const router = useRouter();
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const isBrowser = useIsBrowser();
  const [, hash] = router.asPath.split('#');

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      const event = payload?.event;
      switch (event) {
        case 'signedIn': {
          const user = await asyncFetchUserFromToken();
          setUser(user);
          break;
        }
        case 'signedOut':
          setUser(null);
          break;
      }
    });
  }, []);

  useEffect(() => {
    if (loading && checked) return;

    // prevent setting load state when refreshing the token
    if (!user) setLoading(true);
    (async () => {
      try {
        // since this runs on every navigation, only update user when necessary
        const newUser = await asyncFetchUserFromToken();
        setUser(newUser);
      } catch {
        // This error is expected and therefore will be ignored from logging
        // captureException(e);
        setUser(null);
      } finally {
        setLoading(false);
        !init && setInit(true);
        setChecked(true);
      }
    })();
    // run on every navigation in order to keep the token fresh
  }, [router]);

  const signout = useCallback((a?: User) => setUser(a), [setUser]);

  const { isProtected, notAllowed, loginType } = useRouteCheck();

  if (notAllowed) return <NotFound />;

  const logIn = hash === 'login';

  const proceed =
    !isProtected || user?.role === loginType || user?.role === 'admin';

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: !!user,
        initialLoadComplete: init,
        signout,
        loading,
        setUser,
      }}
    >
      {logIn ? (
        <>
          {children}
          {isBrowser && <AuthModal type={loginType} open router={router} />}
        </>
      ) : proceed ? (
        children
      ) : (
        <>
          {/* Auth modal on protected routes  */}
          <div className="flex min-h-screen flex-col">
            <FullPageLoader />
          </div>
          {isBrowser && <AuthModal type={loginType} open router={router} />}
        </>
      )}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextResult => useContext(AuthContext);
