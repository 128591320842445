import React, { VFC } from 'react';
import { List } from 'components';
import { Empty } from 'components/Empty';
import { ListCard, ListCardProps } from 'components/List';
import {
  EmployerUsersMenu,
  TableEmployerUsersProps,
} from 'components/pages/PortalEmployer/Tables';

export const ListEmployerUsers: VFC<TableEmployerUsersProps> = ({
  empty = <Empty />,
  users,
  loading,
}) => {
  return (
    <List
      {...{ empty, loading }}
      data={users as TableEmployerUsersProps['users']}
      render={(user) => (
        <div className="-mx-6 pb-4 sm:-mx-12" key={user.id}>
          <ListCard {...makeUserListCard(user)} />
        </div>
      )}
    />
  );
};

const makeUserListCard = ({
  id,
  user: { email, first_name, last_name },
  access_level: { label },
}: any): ListCardProps => ({
  id,
  title: `${first_name} ${last_name}`,
  href: undefined,
  content: [
    ['', email],
    ['Access Level', label],
  ],
  menuElement: <EmployerUsersMenu {...{ id, email }} />,
});
