// Framework
import { createElement, type PropsWithChildren } from 'react';

// Third parties
import { twMerge } from 'tailwind-merge';

// Utils
import { getFromAs, getSize } from './utils';

// Type
export type EmployerTextProps = PropsWithChildren<{
  children: React.ReactNode;
  className?: string;
  size?:
    | '9xl'
    | '8xl'
    | '7xl'
    | '6xl'
    | '5xl'
    | '4xl'
    | '3xl'
    | '2xl'
    | 'xl'
    | 'lg'
    | 'base'
    | 'sm';
  style?: Record<string, unknown>;
  italic?: boolean;
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
}>;

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const EmployerText = ({
  as = 'p',
  children,
  className = '',
  size = 'base',
  italic = false,
  ..._rest
}: EmployerTextProps): JSX.Element => {
  const classNames = twMerge(
    italic && 'italic',
    getFromAs(as),
    getSize(size),
    className
  );
  return createElement(as, { className: classNames, ..._rest }, children);
};
