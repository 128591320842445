import { convert } from 'html-to-text';
import { useJitsu } from '@jitsu/nextjs';
import type { LocationObject } from 'utils';

export const makeLocationString = (location: LocationObject) => {
  const { neighbourhood, municipality, district, region } = location;
  const midTernary = municipality !== region ? municipality : district;
  const locationArray = [neighbourhood, midTernary, region].filter(Boolean);
  const locationString = locationArray.length
    ? locationArray.join(', ')
    : 'New Zealand';
  return locationString;
};

type InitJobListVariablesProps = {
  integration?: any;
  integration_type?: string;
  application_url?: string;
  apply_url: string | null | undefined;
  id: string;
  listing_type: string;
  show_banner: boolean | null | undefined;
  cover_photo: string | null | undefined;
  page_active?: boolean;
  parent?: any;
  employer_slug: string | undefined;
  location: any;
  company_description: any;
};

export const initJobListVariables = ({
  integration,
  apply_url,
  id,
  listing_type,
  show_banner,
  cover_photo,
  page_active,
  parent,
  employer_slug,
  location,
  company_description,
}: InitJobListVariablesProps) => {
  const { track } = useJitsu();

  const locationString = makeLocationString(location ?? {});
  const descriptionText = convert(
    company_description ?? parent?.description ?? '',
    {
      preserveNewlines: true,
      whitespaceCharacters: ' \t\r\n\f\u200b\u00a0',
      selectors: [
        { selector: 'a', format: 'skip' },
        { selector: 'img', format: 'skip' },
      ],
    }
  );

  const hasDescription = descriptionText.replace(/\n/g, '') !== '';

  const DESCRIPTION_MAX_LENGTH = 400;
  const descriptionTrimmed = `${descriptionText.slice(
    0,
    DESCRIPTION_MAX_LENGTH
  )}${descriptionText?.length > DESCRIPTION_MAX_LENGTH ? '...' : ''}`;
  const isTrimmed = descriptionText.length > descriptionTrimmed.length;
  const { integration_type, application_url } = integration ?? {};
  let externalApplicationLink = apply_url ? apply_url : undefined;
  if (!externalApplicationLink && integration_type && application_url) {
    externalApplicationLink = application_url;
  }

  const applyExternally = async () => {
    track('job_apply_external', {
      job_id: id,
      listing_type,
    });
    window.open(externalApplicationLink);
  };
  const isFeatured = listing_type === 'featured';
  const showBanner = (show_banner || isFeatured) && cover_photo;
  const pageActive = page_active || parent?.page_active;
  const employerSlug =
    parent && parent.page_active && !page_active ? parent.slug : employer_slug;
  const noPaddingTop = (show_banner || isFeatured) && cover_photo;

  return {
    showBanner,
    applyExternally,
    externalApplicationLink,
    employerSlug,
    pageActive,
    isTrimmed,
    descriptionTrimmed,
    isFeatured,
    descriptionText,
    locationString,
    hasDescription,
    noPaddingTop,
  };
};
