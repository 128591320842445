import * as yup from 'yup';
import { phoneValidation } from 'utils/forms/validation';

const schema = yup.object({
  first_name: yup.string().required('first name is required'),
  last_name: yup.string().required('last name is required'),
  email: yup.string().email('not a valid email').required('email is required'),
  phone: phoneValidation,
  password: yup
    .string()
    .required('password is required')
    .min(8, 'must be at least ${min} characters'),
  terms: yup.boolean().equals([true]).required('terms is required'),
  category: yup.array().optional().of(yup.string()),
});

export default schema;
