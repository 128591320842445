import React, { FC, PropsWithChildren, ReactChild } from 'react';
import { twMerge } from 'tailwind-merge';

// Components
import { PortalLayoutProps } from 'components/pages/Portal';
import { EmployerContextProvider } from 'components/pages/PortalEmployer/EmployerContext';
import { Breadcrumbs, BreadcrumbsProps } from 'components/Breadcrumbs';
import { MobileNav } from './MobileNav';
import { DesktopNav } from './DesktopNav';

export type PortalEmployerLayoutProps = PropsWithChildren<
  PortalLayoutProps & {
    action?: ReactChild;
    container?: boolean;
    full?: boolean;
    showNavigation?: boolean;
    pages?: BreadcrumbsProps['pages'];
  }
>;

export const PortalEmployerLayout: FC<PortalEmployerLayoutProps> = ({
  Banner = null,
  title,
  action,
  container,
  children,
  full = true,
  showNavigation = true,
  pages = [],
}) => {
  return (
    <EmployerContextProvider>
      <div className="flex grow flex-col lg:h-[calc(100vh-74px-74px)] lg:overflow-y-hidden">
        {/* Mobile Nav */}
        <MobileNav />
        {/* Desktop Nav */}
        <div className="flex h-full grow flex-nowrap bg-grey-light lg:overflow-y-hidden">
          {showNavigation && <DesktopNav />}

          {/* Main */}
          <div
            id="content"
            className="grow justify-center overflow-y-auto bg-grey-light"
          >
            <div
              className={twMerge(
                'pb-10 lg:pb-12',
                showNavigation ? 'py-10' : 'pb-10',
                container ? 'custom-container' : 'default-padding',
                full ? '' : 'mx-auto max-w-2xl'
              )}
            >
              {/* Header */}
              {Banner}

              <div className="flex flex-col items-start space-y-2 py-2 md:flex-row md:items-center md:justify-between">
                {showNavigation ? (
                  <div className="mb-4 self-center text-xl font-medium sm:text-2xl">
                    {typeof title === 'string' ? <h1>{title}</h1> : title}
                  </div>
                ) : (
                  <Breadcrumbs isEmployer pages={pages} />
                )}

                {/* Actions */}
                {action && (
                  <div className="mb-4 ml-auto min-w-max pl-8">{action}</div>
                )}
              </div>

              {/* Content */}
              {children}
            </div>
          </div>
        </div>
      </div>
    </EmployerContextProvider>
  );
};
