import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { ColorSubset } from 'styles/types';

export type ButtonProps = PropsWithChildren<
  ButtonHTMLAttributes<Element> & {
    full?: boolean;
    loading?: boolean;
    size?: 'small';
    color?:
      | ColorSubset<
          | 'primary'
          | 'secondary'
          | 'black'
          | 'white'
          | 'grey-light'
          | 'grey-dark'
        >
      | 'outline';
  }
>;

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  color = 'primary',
  disabled,
  full = false,
  loading,
  size,
  ...props
}) => (
  <button
    type="button"
    className={twMerge(
      'relative inline-flex items-center justify-center rounded-lg align-middle tracking-button shadow ring-1 ring-inset ring-transparent focus:outline-none focus:ring-2 font-satoshi font-medium',
      full && 'w-full',
      size === 'small'
        ? 'py-2 text-base leading-5'
        : 'py-4 text-[20px] leading-6',
      disabled
        ? 'cursor-not-allowed border-black-24 bg-grey-light text-black-200 shadow-none focus:pointer-events-none focus:outline-none focus:ring-0 active:pointer-events-none'
        : color === 'primary'
          ? 'bg-primary text-white hover:bg-primary-400 focus:ring-primary'
          : color === 'secondary'
            ? 'bg-secondary text-white hover:bg-secondary-400 focus:ring-secondary'
            : color === 'black'
              ? 'bg-black text-white hover:bg-black-400 focus:ring-black'
              : color === 'white'
                ? 'bg-white text-black hover:bg-white-600 hover:ring-primary focus:ring-black'
                : color === 'grey-light'
                  ? 'bg-grey-light text-black hover:bg-white hover:ring-primary focus:ring-black'
                  : color === 'grey-dark'
                    ? 'bg-grey-dark text-white hover:bg-grey focus:ring-black'
                    : color === 'outline'
                      ? 'border-white bg-black text-white hover:bg-grey hover:ring-primary focus:ring-black'
                      : '',
      loading ? 'px-6' : 'px-6',
      className
    )}
    style={{ height: 'max-content' }}
    tabIndex={disabled ? -1 : undefined}
    disabled={disabled}
    {...props}
  >
    <div className={twMerge('relative flex', loading && 'flex-col')}>
      {children}
      {loading && (
        <span className="absolute -bottom-1 left-0 h-1 w-full animate-pulse rounded bg-white opacity-20" />
      )}
    </div>
  </button>
);
