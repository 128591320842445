// Framework
import { useEffect, useState } from 'react';

// Components
import { LearnMoreColumn } from 'components/selfService';

// Utils
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';
import IntegrationVector from 'components/selfService/IntegrationsSection/IntegrationVector';

// Preps
import {
  iconTopLeftConfig,
  iconBottomLeftConfig,
  iconBottomRightConfig,
  iconTopRightConfig,
  centralLogoConfig,
} from './animations';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function IntegrationsSection(): JSX.Element {
  const [container, isIntersecting] = useIntersectionObserver({
    amount: 0.5,
    once: true,
  });

  const [positions, setPositions] = useState({
    iconTopLeft: iconTopLeftConfig.initial,
    iconTopRight: iconTopRightConfig.initial,
    centralLogo: centralLogoConfig.initial,
    iconBottomLeft: iconBottomLeftConfig.initial,
    iconBottomRight: iconBottomRightConfig.initial,
  });

  useEffect(() => {
    const updatedPositions = isIntersecting
      ? {
          iconTopLeft: iconTopLeftConfig.animated,
          iconTopRight: iconTopRightConfig.animated,
          centralLogo: centralLogoConfig.animated,
          iconBottomLeft: iconBottomLeftConfig.animated,
          iconBottomRight: iconBottomRightConfig.animated,
        }
      : {
          iconTopLeft: iconTopLeftConfig.initial,
          iconTopRight: iconTopRightConfig.initial,
          centralLogo: centralLogoConfig.initial,
          iconBottomLeft: iconBottomLeftConfig.initial,
          iconBottomRight: iconBottomRightConfig.initial,
        };

    setPositions(updatedPositions);
  }, [isIntersecting]);

  return (
    <section className="pb-32 pt-16 md:pb-16 md:pt-32">
      <div
        ref={container}
        className="mx-auto flex max-w-screen-2xl flex-col items-center md:flex-row"
      >
        <LearnMoreColumn
          title="Integrations"
          description="Trade Jobs NZ connects with your Recruitment software. "
          className="order-2 md:order-2"
          buttonText="Contact Sales"
          buttonHref="#__contact_employer"
          isIntersecting={isIntersecting}
          titleMaxWidth="max-w-[650px]"
        >
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut ante
          nec mauris efficitur. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. */}
        </LearnMoreColumn>
        <div className="order-1 flex w-full justify-start md:order-2 md:w-1/2 ">
          <div className="relative top-0 z-0 h-[603px] w-full md:w-[610px]">
            <IntegrationVector {...positions} className="size-full" />
          </div>
        </div>
      </div>
    </section>
  );
}
