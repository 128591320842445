// Framework
import Link from 'next/link';

//Third Parties
import { twJoin, twMerge } from 'tailwind-merge';

// Types
type LearnMoreColumnProps = {
  title: string;
  description?: string;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary';
  className?: string;
  buttonText?: string;
  buttonHref?: string;
  isIntersecting?: boolean;
  titleMaxWidth: string;
};

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { FadeFromTopAnimation } from 'components/selfService/LearnMoreColumn/FadeFromTopAnimation';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const LearnMoreColumn = ({
  title,
  description,
  children,
  variant = 'primary',
  className,
  buttonText,
  buttonHref = '#',
  isIntersecting = true,
  titleMaxWidth,
}: LearnMoreColumnProps): JSX.Element => {
  let style = {
    titleColor: 'text-orange-500',
    descriptionColor: 'text-charcoal-500',
    childrenColor: 'text-charcoal-400 mt-4',
    buttonBackground: 'bg-white',
  };

  if (variant === 'secondary') {
    style = {
      titleColor: 'text-orange-500',
      descriptionColor: 'text-white',
      childrenColor: 'text-orange-50',
      buttonBackground: 'bg-orange-500',
    };
  }

  return (
    <div
      className={twMerge(
        'relative z-0 flex justify-end pt-44 md:w-1/2 md:pt-0 md:pr-16',
        className
      )}
    >
      <FadeFromTopAnimation isIntersecting={isIntersecting}>
        <EmployerText
          as="h2"
          size="xl"
          className={twJoin('mb-2', style.titleColor)}
        >
          {title}
        </EmployerText>
        <div className={titleMaxWidth}>
          {description && (
            <EmployerText as="h3" size="7xl" className={style.descriptionColor}>
              {description}
            </EmployerText>
          )}
          {children && (
            <EmployerText as="p" size="lg" className={style.childrenColor}>
              {children}
            </EmployerText>
          )}
        </div>
        {buttonText && (
          <div className="mt-12 block w-max">
            <Link href={buttonHref} passHref>
              <button className="h-12 w-[200px] rounded-full border border-solid border-orange-500 bg-transparent">
                <p className="font-medium leading-6 text-orange-500">
                  {buttonText}
                </p>
              </button>
            </Link>
          </div>
        )}
      </FadeFromTopAnimation>
    </div>
  );
};
