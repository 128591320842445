import React from 'react';

interface JobTopLogoProps {
  logo?: string | null;
  cover_photo?: string | null;
}

const JobTopLogo: React.FC<JobTopLogoProps> = ({ logo, cover_photo }) => {
  return (
    <div
      className="absolute inset-0 h-[100px] w-full rounded-t-[7px] bg-orange-200 bg-cover bg-center"
      style={{
        backgroundImage: `url(${cover_photo})`,
      }}
    >
      <div className="relative left-5 top-10">
        {logo && (
          <div className="flex size-[100px] flex-col items-center justify-center overflow-hidden rounded-[5px] border border-solid border-charcoal-50 bg-white">
            <img className="h-auto w-full" src={logo} alt="employer banner" />
          </div>
        )}
      </div>
    </div>
  );
};

export default JobTopLogo;
