import { Job } from 'database/types';

export type GenericJobActionProps = {
  employerId: string;
  jobId: string;
  status: Job['job_status'];
};

export enum JobItemAlerts {
  DELETE = 'JOB_ITEM_DELETE',
  EDIT = 'JOB_ITEM_EDIT',
  RELIST = 'JOB_ITEM_RELIST',
  ARCHIVE = 'JOB_ITEM_ARCHIVE',
  APPLICANTS = 'JOB_ITEM_APPLICANTS',
}
