import { JobCardProps, JobListingProps } from 'components';
import {
  Enum_Access_Level,
  Enum_Application_Status,
  Enum_Category,
  Enum_Category_Enum,
  Enum_Job_Status,
  Enum_Pay_Type,
  Enum_Question_Type,
  Enum_Role,
  Enum_Work_Type,
  Maybe,
} from 'database/types';

// https://github.com/ts-essentials/ts-essentials/blob/6b27ee4b7849d2a2670d80000a928cd20e3981b7/lib/types.ts
export type ExtractStrict<T, U extends T> = Extract<T, U>;
export type ExcludeStrict<T, U extends T> = Exclude<T, U>;

// just to be explicit of the date format
export type DateISOString = string;

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[];

export type JobResult = JobCardProps & JobListingProps;

export type Geometry = { type: 'Point'; coordinates: [number, number] };

//https://github.com/Microsoft/TypeScript/issues/14094#issuecomment-373782604
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> =
  T | U extends Record<any, any>
    ? (Without<T, U> & U) | (Without<U, T> & T)
    : T | U;

export type NarrowBy<T, U extends Partial<T>> = T & {
  [K in keyof T]: U[K] extends T[K] ? U[K] : T[K];
};

export type Answer = boolean | string;
export type NullableAnswer = Maybe<Answer> | undefined;

export const isString = (s: unknown): s is string => typeof s === 'string';

export type EnumType =
  | Enum_Access_Level
  | Enum_Application_Status
  | Enum_Category
  | Enum_Job_Status
  | Enum_Pay_Type
  | Enum_Question_Type
  | Enum_Role
  | Enum_Work_Type;

export type EnumTypesAll = {
  access_level: Enum_Access_Level[];
  application_status: Enum_Application_Status[];
  category: Enum_Category[];
  job_status: Enum_Job_Status[];
  pay_type: Enum_Pay_Type[];
  question_type: Enum_Question_Type[];
  role: Enum_Role[];
  work_type: Enum_Work_Type[];
};

export type Strict_Enum_Category = Enum_Category & {
  value: Enum_Category_Enum;
};

export const isNonNullish = <T>(v: T): v is NonNullable<T> =>
  v !== undefined && v !== null;

//forces T to be of type <something> | undefined
export type UndefinedUnion<T> = undefined extends T ? T : never;

export type EmptyProps = Record<never, never>;

export enum EnumRegions {
  auckland = 'Auckland',
  bay_of_plenty = 'Bay of Plenty',
  canterbury = 'Canterbury',
  gisborne = 'Gisborne',
  hawkes_bay = "Hawke's Bay",
  manawatu_whanganui = 'Manawatu Whanganui',
  marlborough = 'Marlborough',
  nelson = 'Nelson',
  norfolk_island = 'Norfolk Island',
  northland = 'Northland',
  otago = 'Otago',
  queensland = 'Queensland',
  southland = 'Southland',
  taranaki = 'Taranaki',
  tasman = 'Tasman',
  tasman_nelson = 'Tasman/Nelson',
  waikato = 'Waikato',
  wellington = 'Wellington',
  west_coast = 'West Coast',
  western_autstralia = 'Western Australia',
}
