import { VFC, useEffect, useRef, useState } from 'react';
import type { LottiePlayer } from 'lottie-web';
import { Layout, Link } from 'components';

const NotFound: VFC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'canvas' as any,
        loop: true,
        autoplay: true,
        // path to your animation file, place it inside public folder
        path: '/assets/images/404-lottie.json',
      });

      animation.setSubframe(false);

      return () => animation.destroy();
    }
  }, [lottie]);

  return (
    <Layout meta={{ metaTitle: 'Page Not Found | TradeJobs.NZ' }}>
      <div
        className="flex min-h-screen flex-col pb-12 pt-16"
        style={{ backgroundColor: '#eac8ac' }}
      >
        <main className="mx-auto flex w-full grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div ref={ref} />
          <div
            className="py-16"
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '275px',
              paddingTop: '380px',
            }}
          >
            <div className="text-center">
              <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Page not found
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <Link
                  href="/"
                  className="text-base font-medium text-secondary-500 hover:text-secondary-400"
                >
                  Go back home
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default NotFound;
