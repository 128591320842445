import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import { Fragment, ReactNode, useEffect, useState, VFC } from 'react';
import { Loading } from 'components/icons';
import { Errors, SelectOption, useFieldError } from '..';

type SingleProps = {
  className?: string;
  label?: ReactNode;
  loading?: boolean;
  name?: string;
  options?: SelectOption[];
  placeholder?: string;
  onChange?: Parameters<typeof Listbox>['0']['onChange'];
  value?: SelectOption['value'];
  noValidation?: boolean;
  disabled?: boolean;
  border?: boolean;
  left?: boolean;
  primary?: boolean;
};

export const Single: VFC<SingleProps> = ({
  className,
  label,
  loading = false,
  name,
  noValidation,
  options,
  placeholder,
  onChange,
  value = '',
  disabled,
  border = true,
  left,
  primary,
}) => {
  const { error } = useFieldError(name);

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => setInternalValue(value), [value, setInternalValue]);

  const getSelected = (value?: SelectOption['value']) =>
    options?.find((option) => option.value === value);

  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        primary && 'grow',
        className
      )}
    >
      <Listbox
        value={internalValue}
        onChange={(newValue) => {
          setInternalValue(newValue);
          onChange && onChange(newValue);
        }}
        disabled={disabled}
      >
        {({ open, disabled }) => (
          <div>
            {label && (
              <Listbox.Label
                className="mb-1 block text-sm uppercase text-grey"
                htmlFor={name}
              >
                {label}
              </Listbox.Label>
            )}
            <div className=" relative text-sm sm:text-base">
              <Listbox.Button
                className={twMerge(
                  'relative w-full cursor-default py-4 pl-6 pr-10 text-left focus:outline-none focus:ring-1 focus:ring-black',
                  left ? 'rounded-l' : 'rounded-r',
                  border && 'border border-light',
                  internalValue ? '' : 'text-grey',
                  error && 'border-red-500',
                  disabled || !primary ? 'bg-grey-light' : 'bg-white shadow-sm'
                )}
              >
                <span className="block truncate text-base">
                  {getSelected(internalValue)?.label ||
                    placeholder ||
                    'Select an option'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  {loading ? (
                    <Loading className="size-5" aria-hidden="true" />
                  ) : (
                    !disabled && (
                      <ChevronDownIcon className="size-5" aria-hidden="true" />
                    )
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className={twMerge(
                    'absolute z-10 mt-1 max-h-60 w-full min-w-max overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none',
                    left ? 'left-0' : 'right-0'
                  )}
                >
                  {options?.map(({ value, label }, i) => (
                    <Listbox.Option
                      key={`${value}_${i}`}
                      className={({ active }) =>
                        twMerge(
                          'relative cursor-default select-none py-2 pl-6 pr-10 text-base',
                          active ? 'bg-grey-light' : ''
                        )
                      }
                      value={value}
                      data-test-value={value}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={twMerge(
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate text-base'
                            )}
                            data-test-selected={selected}
                          >
                            {label}
                          </span>

                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-base">
                              <CheckIcon
                                className="size-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      {!noValidation && <Errors error={error} />}
    </div>
  );
};
