import { useEffect, useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { Maybe } from 'database/types';

const isValidEmail = async (a?: string) => {
  try {
    await yup.string().email().validate(a);
    return true;
  } catch {
    return false;
  }
};
const NEXT_PUBLIC_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

type State = {
  error?: Maybe<Error>;
  loading: boolean;
  userExists?: boolean;
};

type UseEmailExistsHook = State & {
  setEmail: (email?: string) => void;
};

export function useEmailExists(): UseEmailExistsHook {
  const [loading, setLoading] = useState<State['loading']>(false);
  const [error, setError] = useState<State['error']>();
  const [userExists, setUser] = useState<State['userExists']>(false);
  const [email, setEmail] = useState<string | undefined>();
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (checked || !email) return;
    const timeout = setTimeout(() => checkEmail(), 1000);
    return () => clearTimeout(timeout);
  }, [email, checked]);

  async function checkEmail() {
    if (!(await isValidEmail(email)) || !email) {
      userExists && setUser(false);
      return;
    }
    setLoading(true);

    try {
      const { data } = await axios.get(
        `${NEXT_PUBLIC_SITE_URL}/api/email/${encodeURIComponent(email)}`
      );
      setUser(data);
    } catch (err) {
      if (err instanceof Error) setError(err);
    } finally {
      setChecked(true);
      setLoading(false);
    }
  }

  return {
    error,
    setEmail: (a) => {
      setChecked(false);
      setEmail(a);
    },
    loading,
    userExists,
  };
}
