import { FC, VFC, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import { captureException } from '@sentry/nextjs';

// Components
import { Divider, useMessages } from 'components';
import { Dropdown } from 'components/Dropdown';

// Queries
import {
  Enum_Job_Status_Enum,
  GetJobsForThisUserDocument,
  GetJobsByEmployerDocument,
  useDeleteJobByPkMutation,
  useUpdateJobStatusMutation,
  Job,
} from 'database/types';
import { useEmployerContext } from 'components/pages/PortalEmployer/EmployerContext';

type GenericItemProps = {
  jobId: string;
  employerId: string;
};

const ALERT_KEY = 'RELIST_ALERT_KEY';

const RelistItem: FC<GenericItemProps> = ({ jobId, employerId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const { alert, update } = useMessages();
  const handleClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    alert({
      key: ALERT_KEY,
      type: 'processing',
      title: 'Processing request',
      dismissable: false,
      duration: null,
    });
    try {
      const {
        data: { id },
      } = await axios.post(`/api/jobs/${jobId}/relist`);

      router.push({
        pathname: '/portal/[employerId]/job/[jobId]/post/1',
        query: {
          employerId,
          jobId: id,
        },
      });
      update({
        key: ALERT_KEY,
        type: 'success',
        title: 'Created new listing',
        duration: 3000,
        dismissable: true,
      });
    } catch (err) {
      alert({
        key: ALERT_KEY,
        type: 'error',
        title: 'Issue relisting job',
        dismissable: true,
      });
      captureException(err);
    }
  }, [employerId]);
  return (
    <Item className="!text-blue-500" title="Relist" onClick={handleClick} />
  );
};

const DeleteItem: VFC<GenericItemProps> = ({ jobId, employerId }) => {
  const { alert } = useMessages();
  const { isParent } = useEmployerContext();
  const [deleteJob] = useDeleteJobByPkMutation({
    refetchQueries: [
      {
        query: GetJobsForThisUserDocument,
        variables: {
          where: isParent ? {} : { employer_id: { _eq: employerId } },
        },
      },
    ],
  });
  const handleClick = async () => {
    try {
      await deleteJob({
        variables: { jobId, deleted_at: new Date().toISOString() },
      });
      alert({
        key: 'DELETE_JOB',
        type: 'success',
        title: 'Job deleted',
        dismissable: true,
        duration: 3000,
      });
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'DELETE_JOB',
          type: 'error',
          title: 'Issue deleting job',
          message: err?.message,
          dismissable: true,
        });
      }
    }
  };
  return (
    <Item className="!text-red-500" title="Delete" onClick={handleClick} />
  );
};

const { Item } = Dropdown;

type JobActionsDropdownProps = {
  className?: string;
  employerId: string;
  jobId: string;
  listing_type: Job['listing_type'];
  removeManager?: boolean;
  status: string;
};

export const JobActionsDropdown: VFC<JobActionsDropdownProps> = ({
  className,
  employerId,
  jobId,
  listing_type,
  status,
  removeManager = false,
}) => {
  const router = useRouter();
  const { pathname, query } = router;
  const { alert } = useMessages();
  const isPreview = pathname.includes('/preview');
  const [updateStatus] = useUpdateJobStatusMutation({
    refetchQueries: [
      { query: GetJobsByEmployerDocument, variables: { employerId } },
    ],
  });
  // #TODO set hasura permissions
  const onChange = async (status: Enum_Job_Status_Enum) => {
    try {
      await updateStatus({
        variables: {
          id: jobId as string,
          job_status: status,
          updated_at: new Date().toISOString(),
        },
      });
      alert({
        key: 'UpdateJobStatus',
        title: `Job ${status}`,
        type: 'success',
        dismissable: true,
      });
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'UpdateJobStatus',
          title: 'Error updating job',
          type: 'error',
          message: err?.message,
          dismissable: true,
        });
      }
      captureException(err);
    }
  };
  return (
    <Dropdown className={twMerge('z-20', className)}>
      {isPreview ? null : (
        <>
          {!removeManager && (
            <Item
              title="Manage candidates"
              href={{
                pathname: '/portal/[employerId]/job/[jobId]',
                query: {
                  ...query,
                  employerId,
                  jobId,
                },
              }}
            />
          )}
          <Item
            title="View job post"
            href={{
              pathname: '/portal/[employerId]/job/[jobId]/preview',
              query: {
                ...query,
                employerId,
                jobId,
              },
            }}
          />
        </>
      )}
      {status === 'expired' ||
      listing_type === 'expression_of_interest' ? null : (
        <Item
          title="Edit"
          href={{
            pathname: `/portal/[employerId]/job/[jobId]${
              status === 'active' ? '/edit' : '/post'
            }/1`,
            query: {
              ...query,
              employerId,
              jobId,
            },
          }}
        />
      )}
      {(() => {
        switch (status) {
          case 'active':
            return (
              <>
                <Divider />
                <Item
                  title="Archive"
                  className="!text-red-500"
                  onClick={() => onChange && onChange('archived')}
                />
              </>
            );
          case 'archived':
            return (
              <>
                <Divider />
                <Item
                  className="!text-blue-500"
                  title="Set Active"
                  onClick={() => onChange && onChange('active')}
                />
              </>
            );
          case 'draft':
            return (
              <>
                <Divider />
                <DeleteItem jobId={jobId} employerId={employerId} />
              </>
            );
          case 'expired':
            return (
              <>
                <RelistItem jobId={jobId} employerId={employerId} />
                <Divider />
                <DeleteItem jobId={jobId} employerId={employerId} />
              </>
            );
        }
      })()}
    </Dropdown>
  );
};
