/* eslint-disable no-undef */

import { ResourcesConfig } from 'aws-amplify';

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: process.env
        .NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID as string,
      // region: 'ap-southeast-2',
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID as string,
      userPoolClientId: process.env
        .NEXT_PUBLIC_COGNITO_USER_WEB_CLIENT_ID as string,
      // mandatorySignIn: false,
      loginWith: {
        oauth: {
          domain: process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN as string,
          scopes: ['email', 'openid', 'profile'],
          redirectSignIn: [
            process.env.NEXT_PUBLIC_COGNITO_OAUTH_REDIRECT_SIGNIN,
          ] as [string],
          redirectSignOut: [
            process.env.NEXT_PUBLIC_COGNITO_OAUTH_REDIRECT_SIGNOUT,
          ] as [string],
          responseType: 'code',
        },
      },
    },
  },
  Storage: {
    // identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID as string,
    S3: {
      bucket: process.env.NEXT_PUBLIC_S3_BUCKET as string,
      region: 'ap-southeast-2',
    },
  },
};

export default awsConfig;
