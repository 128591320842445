import {
  ApolloClient,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { captureException } from '@sentry/nextjs';
import { LocalStorageWrapper, persistCacheSync } from 'apollo3-cache-persist';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AuthContextResult, makeUserFromToken } from 'components/AuthContext';

const NEXT_PUBLIC_HASURA_API_URL = process.env
  .NEXT_PUBLIC_HASURA_API_URL as string;
const HASURA_ADMIN_SECRET = process.env.HASURA_ADMIN_SECRET as string;

const httpLink = new BatchHttpLink({
  // const httpLink = createHttpLink({
  uri: `https://${NEXT_PUBLIC_HASURA_API_URL}`,
  credentials: 'include',
  batchMax: 5,
  batchInterval: 20,
});

type TCognitoUserSession = Awaited<ReturnType<typeof fetchAuthSession>>;

type DBClientOps = {
  anonymous?: boolean;
  token?: string;
  setUser?: AuthContextResult['setUser'];
};
const DefaultOptions: DBClientOps = { anonymous: true };

const getDBClient = ({
  token,
  setUser,
  anonymous,
} = DefaultOptions): ApolloClient<NormalizedCacheObject> => {
  const ssrMode = typeof window === 'undefined';
  const cache = new InMemoryCache();

  if (!ssrMode) {
    const storage = new LocalStorageWrapper(window.localStorage);
    persistCacheSync({ cache, storage });
  }

  const authLink = createAuthLink({ token, anonymous });
  const errorLink = createErrorLink({ anonymous, setUser });

  const link = from([authLink, errorLink, httpLink]);

  return new ApolloClient({ link, ssrMode, cache });
};

export default getDBClient;

export const getDBAdminClient = (): ApolloClient<Record<string, unknown>> =>
  new ApolloClient({
    link: createHttpLink({
      uri: `https://${NEXT_PUBLIC_HASURA_API_URL}`,
      headers: { 'X-Hasura-Admin-Secret': HASURA_ADMIN_SECRET },
      credentials: 'include',
    }),
    ssrMode: true,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' },
    },
  });

const createAuthLink = ({ token, anonymous }: DBClientOps) => {
  return setContext((_, { headers }) =>
    token && !anonymous
      ? {
          headers: {
            ...headers,
            authorization: token?.startsWith('Bearer')
              ? token
              : `Bearer ${token}`,
          },
        }
      : { headers }
  );
};

// #TODO does this work in ssrMode?
const createErrorLink = ({ anonymous, setUser }: DBClientOps) =>
  onError(({ graphQLErrors = [], operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // [Handling GraphQL Errors with Hasura & React](https://hasura.io/blog/handling-graphql-hasura-errors-with-react/)
        if (err.extensions.code === 'invalid-jwt') {
          if (anonymous) {
            captureException('Unexpected invalid-jwt in anonymous query');
            return;
          }
          // [Apollo GraphQL : Async Access Token Refresh](https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8#)
          // `onError` isn't async, it's an Observable, so in order to use async operations we need to wrap them in `fromPromise` which converts a promise into an Observable
          return (
            fromPromise(
              // refresh token
              // #TODO if multiple queries fail at the same time, this code will run in parallel and could end up refreshing the token multiple times. Can we somehow prevent that? It's no big deal though.
              fetchAuthSession().catch((err) => {
                captureException(err);
              })
            )
              // `fromPromise` returns an array with the value(s) returned from the code wrapped.
              // In the code above, when there's an exception the returned value is void, so we filter it out
              .filter((value): value is TCognitoUserSession => Boolean(value))
              // now we work with the returned value. `.flatMap` works as a `.then` in this case.
              .flatMap((session) => {
                const newToken = session.tokens?.idToken?.toString();
                // if (newToken && newToken !== options.token) {
                if (newToken) {
                  // update current query to retry it
                  operation.setContext(({ headers }: Record<string, any>) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${newToken}`,
                    },
                  }));

                  // update AuthContext, which in turn will update the client, and future queries will use the new token
                  const user = makeUserFromToken(session.tokens?.idToken);
                  setUser && setUser(user);
                }
                // Retry the request, returning the new observable'
                return forward(operation);
              })
          );
        }
      }
    }
  });
