import { twMerge } from 'tailwind-merge';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  bg?: string;
  className?: string;
}>;

export const Divider: FC<Props> = ({
  bg = 'bg-white',
  children,
  className,
}) => (
  <div className={twMerge('relative', className)}>
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    {children && (
      <div className="relative flex justify-center">
        <span className={twMerge('px-2 font-light text-gray-500', bg)}>
          {children}
        </span>
      </div>
    )}
  </div>
);
