import { Location } from 'database/types';

export type LocationObject = Partial<
  Pick<Location, 'region' | 'district' | 'municipality' | 'neighbourhood'>
>;

export type LocationPath = string[];

export const parseLocationPath = (path?: LocationPath): LocationObject => {
  const [region, district, municipality, neighbourhood] = path ?? [];
  return { region, district, municipality, neighbourhood };
};

export const makeLocationPath = (obj: LocationObject): LocationPath => {
  const { region, district, municipality, neighbourhood } = obj;
  const path = [region, district, municipality, neighbourhood].filter(Boolean);

  return path as LocationPath;
};

export const makeLocationInputText = (path?: LocationPath): string => {
  const { region, district, municipality, neighbourhood } =
    parseLocationPath(path);
  const text = [neighbourhood, municipality, district, region]
    .filter(Boolean)
    .join(', ');
  return text;
};

export const parseLocationInputText = (
  location?: string
): LocationPath | undefined => location?.split(', ').reverse();
