import * as yup from 'yup';
import { RegisterEmployerData } from 'components/forms';
import { phoneValidation } from 'utils/forms/validation';

const schema: yup.ObjectSchema<Omit<RegisterEmployerData, 'terms'>> =
  yup.object({
    first_name: yup.string().required('first name is required'),
    last_name: yup.string().required('last name is required'),
    company: yup.string().required(),
    email: yup
      .string()
      .email('not a valid email')
      .required('email is required'),
    phone: phoneValidation,
    password: yup
      .string()
      .required('password is required')
      .min(8, 'must be at least ${min} characters'),
    terms: yup.boolean().equals([true]).required('terms must be accepted'),
  });

export default schema;
