import { Path, UseFormReturn } from 'react-hook-form';
import { UpdatedMonthPicker } from 'components/forms/UpdatedMonthPicker';
import { UpdatedRadioBoolean } from 'components/forms/UpdatedRadioBoolean';
import {
  UpdatedInput,
  UpdatedInputTextArea,
} from 'components/forms/UpdatedFormInput';
import { WatchValue } from '..';
import { CustomInputDate } from './CustomInputDate';

const DEFAULT_WORK_EXP = {
  id: undefined,
  title: '',
  company: '',
  start_date: '',
  end_date: null,
  current: undefined,
  description: null,
  updated_at: null,
};

type WorkExperienceBodyProps = Pick<
  UseFormReturn<any>,
  'control' | 'register' | 'setValue'
> & {
  prefix?: string;
};

export function WorkExperienceBody<T>({
  prefix = '',
  control,
  register,
  setValue,
}: WorkExperienceBodyProps): JSX.Element {
  const resetField = (a: keyof typeof DEFAULT_WORK_EXP) =>
    setValue(a as Path<T>, DEFAULT_WORK_EXP[a] as any);
  return (
    <>
      <UpdatedInput
        {...register(`${prefix}title` as Path<T>)}
        required
        autoCapitalize="false"
        label="Job title"
        placeholder="Job title"
        customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
      />
      <UpdatedInput
        {...register(`${prefix}company` as Path<T>)}
        required
        label="Company"
        placeholder="Company name"
        customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
      />
      <UpdatedRadioBoolean
        name={`${prefix}current` as Path<T>}
        control={control}
        label="Do you still work here?"
      />
      <UpdatedMonthPicker
        required
        control={control}
        name={`${prefix}start_date` as Path<T>}
        type="date"
        label="Start date"
        placeholder="Start date"
      />
      <WatchValue<T>
        control={control}
        name={`${prefix}current` as Path<T>}
        el={
          <CustomInputDate<T>
            control={control}
            prefix={prefix}
            name={`${prefix}end_date` as Path<T>}
            type="date"
            label="End date"
            placeholder="End date"
            resetField={resetField as any}
            required
          />
        }
      />

      <UpdatedInputTextArea
        {...register(`${prefix}description` as Path<T>)}
        label="Description"
        placeholder="Tell us more about your experience"
        required
        customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
      />
    </>
  );
}
