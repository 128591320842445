import { useState } from 'react';
import { AppDisclosure } from './AppDisclosure';

export interface FAQsProps {
  title: string;
  subtitle: string;
  questions: Array<{ title: string; description: string }>;
}

export function FAQs({ title, subtitle = 'FAQ', questions }: FAQsProps) {
  const [openIndex, setOpenIndex] = useState<number>();
  return (
    <section className="mx-auto max-w-7xl px-6">
      <div className="max-w-2xl">
        <p className="font-medium leading-7 text-primary">{subtitle}</p>
        <h2 className="mt-2 text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
          {title}
        </h2>
      </div>
      <div className="mt-14 grid grid-cols-1 gap-x-5 gap-y-7 md:grid-cols-2">
        {questions.map(({ title: t, description }, index) => (
          <AppDisclosure
            title={t}
            key={t}
            onToggle={(open) => open && setOpenIndex(index)}
            open={index === openIndex}
          >
            {description}
          </AppDisclosure>
        ))}
      </div>
    </section>
  );
}
