import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useFieldError, Errors } from '..';
import { UpdatedInputLabel } from './UpdatedInputLabel';

export type UpdatedInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: ReactNode;
  name?: string;
  noValidation?: boolean;
  subtitle?: string;
  customLabel?: string;
  inputClassName?: string;
};

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export const UpdatedInput = forwardRef<HTMLInputElement, UpdatedInputProps>(
  function InputRef(
    {
      autoCapitalize,
      className,
      inputClassName,
      disabled,
      label,
      name,
      noValidation,
      onChange,
      onBlur,
      placeholder,
      subtitle,
      required,
      type = 'text',
      value,
      customLabel,
    },
    ref
  ) {
    const { error } = useFieldError(name);
    return (
      <div
        className={twMerge(
          'relative',
          noValidation ? '' : error ? 'mb-2' : 'mb-8',
          className
        )}
      >
        <UpdatedInputLabel
          label={label}
          name={name}
          required={required}
          subtitle={subtitle}
          customLabel={customLabel}
        />
        <input
          ref={ref}
          type={type}
          value={value}
          className={twMerge(
            'block rounded-lg border border-charcoal-50 h-12 py-3 px-4 pr-10 text-base text-charcoal-500 bg-white placeholder:text-gray placeholder:text-opacity-50 focus:border-orange-500 focus:ring-1 focus:ring-orange-500',
            type === 'date' ? 'w-auto' : 'w-full',
            error && 'border-red-500 focus:border-red-700 focus:ring-red-500',
            disabled && 'cursor-not-allowed bg-gray-200',
            inputClassName
          )}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          autoCapitalize={autoCapitalize}
        />
        {!noValidation && <Errors error={error} />}
      </div>
    );
  }
);
