import { FC } from 'react';

import {
  DocumentIcon,
  CameraIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { getUrl, remove } from 'aws-amplify/storage';
import { Loading } from 'components/icons';

import {
  useGetDocumentsQuery,
  useDeleteDocumentByIdMutation,
  useGetDocumentsByUserIdQuery,
  // GetDocumentsByUserIdDocument,
  GetDocumentsDocument,
} from 'database/types';

import { Divider } from 'components';
import { DocumentUpload } from 'components/forms';

export type CallBack = {
  loaded: number;
  total: number;
};

type Props = {
  user_id?: string;
};

export const DocumentList: FC<Props> = ({ user_id }) => {
  const { data, loading } = user_id
    ? useGetDocumentsByUserIdQuery({
        variables: { user_id },
        fetchPolicy: 'cache-and-network',
      })
    : useGetDocumentsQuery({
        fetchPolicy: 'cache-and-network',
      });

  const [deleteDocument] = useDeleteDocumentByIdMutation({
    refetchQueries: [
      {
        query: GetDocumentsDocument,
      },
    ],
  });

  const deleteFile = async (id: string, key: string) => {
    await deleteDocument({
      variables: {
        id,
      },
    });
    await remove({ key });
  };

  const getFile = async (key: string) => {
    const { url } = await getUrl({
      key,
      options: { accessLevel: 'guest' },
    });
    window.open(url.toString(), '_blank');
  };

  if (loading) return <Loading />;
  if (user_id && data?.file.length === 0) return null;
  return (
    <>
      <div className="space-y-2">
        <p className="mb-4 text-lg font-bold -tracking-two-percent text-charcoal-300">
          Supporting documents
        </p>
        <Divider className="w-full text-charcoal-200" />
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {data?.file.map(({ key, mimetype, name, id }) => (
          <li key={key} className="flex py-4">
            {mimetype.includes('image') ? (
              <CameraIcon stroke="#f17732" className="size-10" />
            ) : (
              <DocumentIcon stroke="#f17732" className="size-10" />
            )}
            <div className="ml-3">
              <p className="text-sm font-medium text-charcoal-500">{name}</p>
              <button onClick={() => getFile(key)}>
                <p className="text-sm text-charcoal-500">View file</p>
              </button>
            </div>
            {!user_id && (
              <div>
                <button
                  className="pl-2 text-sm text-charcoal-500"
                  onClick={() => deleteFile(id, key)}
                >
                  <TrashIcon className="w-4" />
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
      <DocumentUpload
        className="text-charcoal-500"
        // bucket={process.env.NEXT_PUBLIC_S3_BUCKET}
        // level="public"
        src={null}
        renderPreview={(value) => <img src={value} />}
      />
    </>
  );
};

export default DocumentList;
