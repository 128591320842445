import { Alert, Button } from 'components';

type Props = {
  slug: string;
};

export function AlertAdvertising({ slug }: Props): JSX.Element {
  return (
    <Alert
      type="info"
      title="Your listing is live!"
      message="We'll be in touch shortly to discuss any additional services you may have purchased. You can use the link to view your live job, and share it with anyone who may be interested."
      dismissable={false}
      actions={
        <a
          href={`${process.env.NEXT_PUBLIC_SITE_URL}/job/${slug}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className="py-2 uppercase"
            onClick={() => null}
            color="white"
            size="small"
          >
            View
          </Button>
        </a>
      }
    />
  );
}
