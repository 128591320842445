import { useEffect, useState } from 'react';

const useIsBrowser = (): boolean => {
  const [isBrowser, setBrowser] = useState<boolean>(false);

  useEffect(() => setBrowser(true), []);

  return isBrowser;
};

export default useIsBrowser;
