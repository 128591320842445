import { SVGProps } from 'react';

const PackageBackground = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1258"
      height="1590"
      viewBox="0 0 1258 1590"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.4">
        <mask
          id="mask0_1_4093"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="157"
          y="646"
          width="944"
          height="944"
        >
          <rect
            width="943.5"
            height="943.5"
            transform="translate(157.25 646)"
            fill="url(#paint0_radial_1_4093)"
          />
        </mask>
        <g mask="url(#mask0_1_4093)">
          <g opacity="0.5">
            <g clipPath="url(#clip0_1_4093)">
              <g clipPath="url(#clip1_1_4093)">
                <line
                  x1="157.578"
                  y1="646"
                  x2="157.578"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="209.994"
                  y1="646"
                  x2="209.994"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="262.411"
                  y1="646"
                  x2="262.411"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="314.828"
                  y1="646"
                  x2="314.828"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="367.244"
                  y1="646"
                  x2="367.244"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="419.661"
                  y1="646"
                  x2="419.661"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="472.078"
                  y1="646"
                  x2="472.078"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="524.494"
                  y1="646"
                  x2="524.494"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="576.911"
                  y1="646"
                  x2="576.911"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="629.328"
                  y1="646"
                  x2="629.328"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="681.744"
                  y1="646"
                  x2="681.744"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="734.161"
                  y1="646"
                  x2="734.161"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="786.578"
                  y1="646"
                  x2="786.578"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="838.994"
                  y1="646"
                  x2="838.994"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="891.411"
                  y1="646"
                  x2="891.411"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="943.827"
                  y1="646"
                  x2="943.827"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="996.244"
                  y1="646"
                  x2="996.244"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  x1="1048.66"
                  y1="646"
                  x2="1048.66"
                  y2="1589.5"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
              </g>
              <g clipPath="url(#clip2_1_4093)">
                <line
                  y1="698.089"
                  x2="1258"
                  y2="698.089"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="750.506"
                  x2="1258"
                  y2="750.506"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="802.922"
                  x2="1258"
                  y2="802.922"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="855.339"
                  x2="1258"
                  y2="855.339"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="907.756"
                  x2="1258"
                  y2="907.756"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="960.172"
                  x2="1258"
                  y2="960.172"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1012.59"
                  x2="1258"
                  y2="1012.59"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1065.01"
                  x2="1258"
                  y2="1065.01"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1117.42"
                  x2="1258"
                  y2="1117.42"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1169.84"
                  x2="1258"
                  y2="1169.84"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1222.26"
                  x2="1258"
                  y2="1222.26"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1274.67"
                  x2="1258"
                  y2="1274.67"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1327.09"
                  x2="1258"
                  y2="1327.09"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1379.51"
                  x2="1258"
                  y2="1379.51"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1431.92"
                  x2="1258"
                  y2="1431.92"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1484.34"
                  x2="1258"
                  y2="1484.34"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
                <line
                  y1="1536.76"
                  x2="1258"
                  y2="1536.76"
                  stroke="#F17732"
                  strokeWidth="0.655208"
                />
              </g>
            </g>
          </g>
        </g>
        <ellipse
          opacity="0.1"
          cx="665.5"
          cy="785"
          rx="508.5"
          ry="305"
          fill="url(#paint1_radial_1_4093)"
        />
      </g>
      <ellipse
        opacity="0.05"
        cx="628.5"
        cy="932"
        rx="597.5"
        ry="518"
        fill="url(#paint2_radial_1_4093)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1_4093"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(471.75 -0.000112474) rotate(90) scale(943.5 492.447)"
        >
          <stop />
          <stop offset="0.953125" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1_4093"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(665.5 785) rotate(90) scale(305 508.5)"
        >
          <stop stopColor="#F17732" />
          <stop offset="1" stopColor="#F17732" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1_4093"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(628.5 932) rotate(90) scale(518 597.5)"
        >
          <stop stopColor="#F17732" />
          <stop offset="1" stopColor="#F17732" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_1_4093">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="translate(0 646)"
          />
        </clipPath>
        <clipPath id="clip1_1_4093">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="translate(0 646)"
          />
        </clipPath>
        <clipPath id="clip2_1_4093">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="translate(0 646)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PackageBackground;
