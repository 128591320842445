import * as yup from 'yup';
import { dateRegEx } from '../MonthPicker';
import { EducationData } from './Education';

const schema: yup.ObjectSchema<Omit<EducationData, 'updated_at' | 'id'>> =
  yup.object({
    title: yup.string().required('certification name is required'),
    institution: yup.string().required('organisation name is required'),
    complete: yup.boolean().required('please select an option'),
    issue_date: yup
      .string()
      .matches(dateRegEx, 'not a valid date')
      .nullable()
      .test(
        'issue_date_valid',
        'issue date is required',
        (value, { parent }) => !parent.complete || !!value
      ),
  });

export default schema;
