import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type PillProps = PropsWithChildren<{
  className?: string;
}>;

export const Pill: FC<PillProps> = ({ className, children }) => (
  <span
    className={twMerge(
      'inline-flex rounded bg-grey-light px-3 py-2 text-xs font-semibold leading-5',
      className
    )}
  >
    {children}
  </span>
);
