import { Dialog, RadioGroup } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import React, { ElementType, VFC } from 'react';
import {
  Button,
  CardManagement,
  CardManagementProps,
  Drawer,
  FullPageLoader,
  Link,
  SubscriptionViewProps,
} from 'components';
import { RadioOption } from 'components/forms';

export const SubscriptionDrawer: VFC<SubscriptionViewProps> = ({
  open,
  step,
  setStep,
  plan,
  setPlan,
  options,
  defaultCard,
  onClose,
}) => {
  const handleChange = (value: typeof plan) => value && setPlan(value);

  const onAdd: CardManagementProps['onAdd'] = () => {
    setStep('confirm');
  };

  return (
    <Drawer open={!!open} onClose={() => onClose && onClose()}>
      <div className="default-padding w-screen">
        <div className="mb-4 py-4">
          {step !== 'pay' && (
            <button className="link flex items-center p-2" onClick={onClose}>
              <ChevronLeftIcon className="mr-4 h-4" /> Back to Profile
            </button>
          )}
        </div>
        {(() => {
          switch (step) {
            case 'select':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add employer page
                  </Dialog.Title>

                  <div className="space-y-4 py-4">
                    <div className="prose">
                      <p>
                        Enjoy additional benefits with a Trade Jobs NZ Employer
                        Page:
                      </p>
                      <ul>
                        <li>Acts as a landing page (like a website)</li>
                        <li>Lists all your active Job Ads, in one place</li>
                        <li>
                          Allows candidates to read more about your company
                        </li>
                        <li>Included in our TJNZ Directory</li>
                      </ul>
                      <p>$499 per annum</p>
                      <p>*You can cancel your subscription at anytime.</p>
                    </div>
                    <RadioGroup<ElementType<any>, typeof plan>
                      value={plan}
                      onChange={handleChange}
                      className="flex w-full justify-between
              space-x-4"
                    >
                      {options.map(({ value, label }) => (
                        <RadioOption
                          {...{ value, label }}
                          key={value ?? Math.random().toString()}
                          className="w-full"
                        />
                      ))}
                    </RadioGroup>
                  </div>
                  <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                    <Button
                      className="w-full sm:w-auto"
                      onClick={() => setStep('card_check')}
                      size="small"
                    >
                      Next
                    </Button>
                    <Button
                      className="mr-0 w-full sm:mr-2 sm:w-auto"
                      size="small"
                      color="white"
                      onClick={onClose}
                    >
                      No thanks
                    </Button>
                  </div>
                </>
              );
            case 'card_check':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Checking card...
                  </Dialog.Title>
                  Please don't close or navigate away from this page.
                  <div className="flex h-16">
                    <FullPageLoader />
                  </div>
                </>
              );
            case 'card_add':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment Information
                  </Dialog.Title>
                  <div className="space-y-4 py-4">
                    <CardManagement {...{ onAdd }} />
                  </div>
                  <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                    <Button className="w-full sm:w-auto" disabled size="small">
                      Confirm
                    </Button>

                    <Button
                      className="mr-0 w-full sm:mr-2 sm:w-auto"
                      size="small"
                      color="white"
                      onClick={() => setStep('select')}
                    >
                      Back
                    </Button>
                  </div>
                </>
              );
            case 'confirm':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment Information
                  </Dialog.Title>
                  <div className="space-y-4 py-4">
                    <div className="prose">
                      <p>
                        You will be charged on your stored credit card ending in{' '}
                        <strong>{defaultCard?.card?.last4}</strong>.
                      </p>
                      <p>
                        If you wish to use a different card, please update your
                        default card in the{' '}
                        <Link
                          href="/portal/a75be271-a720-4cf7-ba0d-2dfba73d68d2/billing"
                          className="link"
                          htmlProps={{ onClick: onClose }}
                        >
                          billing page
                        </Link>
                        .
                      </p>
                    </div>
                    <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                      <Button
                        className="w-full sm:w-auto"
                        onClick={() => setStep('pay')}
                        size="small"
                      >
                        Confirm
                      </Button>

                      <Button
                        className="mr-0 w-full sm:mr-2 sm:w-auto"
                        size="small"
                        color="white"
                        onClick={() => setStep('select')}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                </>
              );
            case 'pay':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Redirecting to checkout...
                  </Dialog.Title>
                  <div className="space-y-4 py-4">
                    Please don't close or navigate away from this page.
                    <div className="flex h-16">
                      <FullPageLoader />
                    </div>
                  </div>
                </>
              );
            case 'return':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Thank you! You're subscribed.
                  </Dialog.Title>

                  <div className="space-y-4 py-4">
                    <div className="prose">
                      <p>Your page will be live shortly. Enjoy!</p>
                    </div>
                  </div>
                  <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                    <Button
                      className="w-full sm:w-auto"
                      onClick={onClose}
                      size="small"
                    >
                      Okay
                    </Button>
                  </div>
                </>
              );
            case 'failed':
              return (
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Error subscribing{' '}
                  </Dialog.Title>

                  <div className="space-y-4 py-4">
                    <div className="prose">
                      <p>
                        Sorry, there was an error processing your payment.
                        Please try with a different card. If the problem
                        persists, contact us.
                      </p>
                      <Link
                        href="/portal/a75be271-a720-4cf7-ba0d-2dfba73d68d2/billing"
                        className="link mt-4 underline"
                        htmlProps={{ onClick: onClose }}
                      >
                        Manage your cards
                      </Link>
                    </div>
                  </div>
                  <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
                    <Button
                      className="w-full sm:w-auto"
                      onClick={onClose}
                      size="small"
                    >
                      Okay
                    </Button>
                  </div>
                </>
              );
            default:
              return null;
          }
        })()}
      </div>
    </Drawer>
  );
};
