import { FC } from 'react';
import { Employer, useGetAllEmployersSlugsQuery } from 'database/types';
import { Select, SelectProps } from '..';

type SelectEmployerProps = Omit<SelectProps, 'options'>;

export const SelectCompany: FC<SelectEmployerProps> = (props) => {
  const { data, loading } = useGetAllEmployersSlugsQuery();
  const options = loading ? [] : data?.employer;
  return (
    <Select
      {...props}
      loading={loading}
      options={options?.map(
        ({ name, slug }: Pick<Employer, 'name' | 'slug'>) => ({
          value: slug,
          label: name,
        })
      )}
    />
  );
};
