import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useEmployerContext } from 'components/pages/PortalEmployer';

/**
 * Autocorrects mistmatching employerId
 * @param employer_id data from current db resource
 */
export const usePortalPathCorrection = (employer_id?: string): void => {
  const router = useRouter();
  const { pathname, query } = router;
  const employerId = query.employerId as string;
  const { isParent, loading } = useEmployerContext();

  useEffect(() => {
    if (!employer_id || employer_id === employerId || loading || isParent)
      return;
    router.replace({ pathname, query: { ...query, employerId: employer_id } });
  }, [employer_id]);
};
