import type { ReactNode } from 'react';

type LabelProps = {
  label?: ReactNode;
  subtitle?: string;
  name?: string;
  required?: boolean;
  customLabel?: string;
};

export const LABEL_CLASSES =
  'mb-1 block text-base text-gray-dark font-normal tracking-wider';

export function InputLabel({
  label,
  subtitle,
  name,
  required,
  customLabel,
}: LabelProps): JSX.Element | null {
  if (!label) return null;
  return (
    <label htmlFor={name} className={customLabel ? customLabel : LABEL_CLASSES}>
      {label}
      <span className="normal-case text-grey">
        {!required && ' (optional)'}
      </span>
      {subtitle && (
        <>
          <br />
          <span className="text-sm text-grey">{subtitle}</span>
        </>
      )}
    </label>
  );
}
