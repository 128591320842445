import React from 'react';
import { NavbarProps } from 'components';
import { useAuthContext } from 'components/AuthContext';
import { DesktopNavEmployer } from 'components/Navbar/Desktop';
import { MobileNavEmployer } from 'components/Navbar/Mobile';

export const NavbarEmployer: React.FC<NavbarProps> = ({ avatar }) => {
  const { authenticated } = useAuthContext();
  return (
    <>
      <DesktopNavEmployer
        {...{ authenticated, avatar }}
        className="hidden md:block"
      />
      <MobileNavEmployer {...{ authenticated, avatar }} className="md:hidden" />
    </>
  );
};
