// Components
import { Link } from 'components';
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const FooterEmployer = (): JSX.Element => {
  const router = useRouter();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const path = router.pathname;
    // iOS footer issue. Hide footer on apply and onboard pages.
    if (path.includes('apply') || path.includes('onboard')) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [router.pathname]);

  if (isHidden) {
    return <></>;
  }

  return (
    <footer
      className="h-full shrink bg-charcoal-500 font-satoshi font-medium"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="mx-4 flex flex-wrap justify-between gap-8 border-t border-solid border-orange-100 py-6 md:mx-24">
        <div className="flex items-center gap-8">
          <EmployerText as="p" size="base" className=" text-white">
            <Link href="/privacy" htmlProps={{ target: '_blank' }}>
              Privacy Policy
            </Link>
          </EmployerText>
          <div>
            <svg
              width="4"
              height="4"
              viewBox="0 0 4 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
            </svg>
          </div>
          <EmployerText as="p" size="base" className="text-white">
            <Link href="/terms" htmlProps={{ target: '_blank' }}>
              Terms of Service
            </Link>
          </EmployerText>
        </div>
        <EmployerText as="p" size="base" className="text-white">
          © Trade Jobs NZ – {new Date().getFullYear()}. All Rights Reserved
        </EmployerText>
      </div>
    </footer>
  );
};
