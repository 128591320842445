import dayjs from 'dayjs';
import React, { ReactChild, VFC, useState } from 'react';
import { Table, TableProps } from 'components/Table/Table';
import { GetJobApplicationsByJobIdQuery } from 'database/types';
import { Loading } from 'components/icons';
import { PillManager } from 'components/pages/PortalEmployer/ApplicationsEmployer/PillManager';
import { EyeIcon } from '@heroicons/react/24/outline';
export type TableApplicationsRowData = [string, string, string, ReactChild];

export type ApplicationRow = NonNullable<
  GetJobApplicationsByJobIdQuery['job']
>['applications'][number];

export type TableApplicationsProps = Pick<TableProps, 'loading' | 'empty'> & {
  applications?: ApplicationRow[];
  setApplicationId?: (id: string | null) => void;
};

export const TableApplications: VFC<TableApplicationsProps> = ({
  applications,
  setApplicationId,
  ...props
}) => {
  const [fakeLoading, setFakeLoading] = useState<null | string>(null);
  const headers = [
    'Candidate',
    '# Experience',
    '# Education',
    'Date Applied',
    'Status',
    '',
  ];

  const makeTableRows = ({
    applications = [],
  }: TableApplicationsProps): TableProps['rows'] =>
    applications?.map(
      ({
        id,
        created_at,
        user: {
          first_name,
          last_name,
          user_educations,
          user_experiences,
        },
        application_status: { label },
      }) => {
        const education = user_educations.length
        const experience = user_experiences.length
        return {
          id,
          data: [
            `${first_name} ${last_name}`,
            experience,
            education,
            created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
            <PillManager key={id}>{label}</PillManager>,
          ],
          menuElement: (
            <button
              className="h-10  px-8"
              type="button"
              onClick={() => {
                setFakeLoading(id);
                setApplicationId && setApplicationId(id);
                setTimeout(() => {
                  setFakeLoading(null);
                }, 1000);
              }}
            >
              <span className="font-satoshi text-sm font-medium text-black">
                {fakeLoading === id ? <Loading /> : <EyeIcon className="h-5 w-5 text-black" />}
              </span>
            </button>
          ),
        };
      }
    );

  return (
    <Table
      {...props}
      headers={headers}
      rows={makeTableRows({ applications })}
    />
  );
};
