import * as yup from 'yup';
// import { LoginData } from './Login';

const schema = yup.object({
  username: yup
    .string()
    .email('not a valid email')
    .required('email is required'),
  password: yup
    .string()
    .required('password is required')
    .min(8, 'must be at least ${min} characters'),
});

export default schema;
