import { createContext, FC, useContext } from 'react';
import { CurrentEmployer, useCurrentEmployer } from 'utils';

const EmployerContext = createContext<CurrentEmployer>({
  allEmployers: [],
  currentEmployer: '',
  employerId: undefined,
  error: false,
  hasMultipleEmployers: false,
  isEmployerPath: false,
  loading: false,
  hasNoEmployers: false,
  setCurrentEmployer: () => null,
  firstId: '',
  isParent: false,
});
export const useEmployerContext = () => useContext(EmployerContext);

export const EmployerContextProvider: FC = ({ children }) => (
  <EmployerContext.Provider value={useCurrentEmployer()}>
    {children}
  </EmployerContext.Provider>
);
