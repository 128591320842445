import * as yup from 'yup';
import { Enum_Access_Level_Enum } from 'database/types';
// import { phoneValidation } from 'utils/forms/validation';

const schema = yup.object({
  // first_name: yup.string().required('first name is required'),
  // last_name: yup.string().required('last name is required'),
  email: yup.string().email('not a valid email').required('email is required'),
  access_level: yup
    .mixed<Enum_Access_Level_Enum>()
    .required('access is required'),
  // phone: phoneValidation,
  // password: yup
  // .string()
  // .required('password is required')
  // .min(8, 'must be at least ${min} characters'),
});

export default schema;
