import { FC, PropsWithChildren } from 'react';
import { Link } from 'components';
import { LinkProps } from 'components/Link';

export type SocialIconProps = PropsWithChildren<{
  title?: string;
  href?: LinkProps['href'];
}>;

export const SocialIcon: FC<SocialIconProps> = ({ href, children }) => (
  <Link href={href} htmlProps={{ target: '_blank' }}>
    <span className="mx-auto inline-flex size-10 place-content-center items-center rounded-full fill-black transition duration-300 ease-in-out hover:fill-primary-500 hover:text-primary">
      {children}
    </span>
  </Link>
);
