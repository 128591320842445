import { MessageType } from 'components/Messages';

export const ALERT_CardSelectionStateError: MessageType = {
  type: 'error',
  key: 'CardSelectionStateError',
  title: 'No card present',
};

export const ALERT_PaymentApproved: MessageType = {
  type: 'success',
  key: 'PROCESSING_PAYMENT',
  title: 'Payment has been approved',
  duration: 3000,
};

export const ALERT_PaymentCancelled: MessageType = {
  type: 'warning',
  key: 'PROCESSING_PAYMENT',
  title: 'Payment was cancelled',
};

export const ALERT_PaymentError: MessageType = {
  type: 'error',
  key: 'PaymentError',
  title: 'An error has occurred',
  message: 'There was a problem processing your payment',
};

export const ALERT_UnexpectedAPIError: MessageType = {
  key: 'UnexpectedAPIError',
  title: 'There was a problem with your request',
  type: 'error',
};

export const ALERT_APIException: MessageType = {
  key: 'APIException',
  title: 'There was a problem with your request',
  type: 'error',
};

export const ALERT_ClientSecretError: MessageType = {
  key: 'ClientSecretError',
  title: 'An error ocurred. Please try again.',
  type: 'error',
};
