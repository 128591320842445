// Third Parties
import { twJoin } from 'tailwind-merge';

// Components
import { CardDescription } from 'components/selfService/PackagesSection/CardDescription';

// Types
type CardComponentProps = {
  item: {
    title: string;
    description: string;
  };
  textReverse?: boolean;
  children: React.ReactNode;
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const CardComponent = ({
  children,
  textReverse,
  item: { title, description },
}: CardComponentProps) => (
  <div className="group relative flex h-auto flex-col overflow-hidden text-clip rounded-lg border border-solid border-orange-200 bg-white px-4 py-6 hover:shadow-card lg:h-[437px]">
    <div
      className={twJoin(
        'flex justify-center',
        textReverse ? 'order-2' : 'order-1'
      )}
    >
      {children}
    </div>
    <CardDescription
      textReverse={textReverse}
      title={title}
      text={description}
    />
  </div>
);
