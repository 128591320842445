import { Menu, Transition } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';
import React, { Fragment, FC } from 'react';
import { Avatar } from 'components';
import { useAuthContext } from 'components/AuthContext';
import {
  ChevronLeft,
  LogOutIcon,
  ProfileIcon,
  SavedJobIcon,
  SettingsIcon,
  JobIcon,
} from 'components/icons';
import { Item } from '.';

type UserMenuProps = {
  type: 'employee' | 'employer';
};

export const UserMenu: FC<UserMenuProps> = ({ type }) => {
  const { user } = useAuthContext();
  const page = type === 'employer' ? 'portal' : 'employee';
  const subPage = type === 'employer' ? '/jobs' : '';

  return (
    <Menu as="div" className="relative ml-3">
      {({ open }) => (
        <>
          <Menu.Button>
            <span className="sr-only">Open user menu</span>
            <div className="relative z-10 flex items-center">
              {type !== 'employer' && (
                <p className="mr-2 hidden truncate font-satoshi text-lg font-medium leading-7 text-charcoal-500 sm:block">
                  {user?.given_name} {user?.family_name}
                </p>
              )}
              <Avatar size="tiny" alt="User Profile Picture" />
              <div className="absolute bottom-0 right-0 size-4 rounded-full bg-white p-1">
                <ChevronLeft
                  className={twJoin(
                    'size-2 transition-transform duration-200 ease-in-out',
                    open ? 'transform rotate-180' : 'transform rotate-0'
                  )}
                />
              </div>
            </div>
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-30 mt-2 w-48 origin-top-right divide-y divide-charcoal-50 rounded-lg border border-solid border-charcoal-50 bg-cream p-4 py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
            >
              <p className="px-4 py-3 font-satoshi truncate text-base font-medium leading-5 text-charcoal-500">
                {user?.email}
              </p>
              {(() => {
                if (type === 'employee')
                  return (
                    <div className="py-1">
                      <Item
                        href={`/jobs`}
                        text="Find a Job"
                        icon={<JobIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href={`/${page}${subPage}`}
                        text="Saved Jobs"
                        icon={<SavedJobIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href={`/${page}/profile`}
                        text="Profile"
                        icon={<ProfileIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href="/employee/settings"
                        text="Settings"
                        icon={<SettingsIcon className="mr-2 size-5" />}
                      />
                    </div>
                  );
              })()}
              <div className="py-1">
                <Item
                  href={`${type === 'employer' ? '/portal' : ''}/auth/signout`}
                  text="Sign out"
                  icon={<LogOutIcon className="mr-2 size-5" />}
                />
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
