import { VFC } from 'react';

export type TableHeaderProps = {
  headers?: string[];
};

export const TableHeader: VFC<TableHeaderProps> = ({ headers }) =>
  headers?.length ? (
    <thead className="bg-white">
      <tr>
        {headers.map((header, i) => (
          <th
            key={i}
            scope="col"
            className="rounded px-6 py-3 text-left text-xs font-normal uppercase tracking-wider text-grey"
          >
            {header}
          </th>
        ))}

        <th scope="col" className="relative rounded px-6 py-3">
          <span className="sr-only">Options</span>
        </th>
      </tr>
    </thead>
  ) : null;
