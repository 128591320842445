import React, { cloneElement, PropsWithChildren, useState } from 'react';

//Dnd Items
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

export type DNDItem = Record<string, unknown> & {
  id: string;
};

type DndFieldContextProps = PropsWithChildren<{
  id?: string;
  items: DNDItem[];
  ListItem: React.ReactElement;
  onChange: (a: DNDItem[]) => void;
}>;

export function DNDList({
  id,
  items = [],
  ListItem,
  onChange,
}: DndFieldContextProps): JSX.Element {
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      id={id}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, idx) =>
          cloneElement(ListItem, { key: item.id, ...item, idx })
        )}
      </SortableContext>
      <DragOverlay>
        {activeId
          ? cloneElement(ListItem, {
              ...items.find((i) => i.id === activeId),
            })
          : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId((active as any).id);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((i) => i.id === active.id);
      const newIndex = items.findIndex((j) => j.id === (over as any).id);
      const next = arrayMove(items, oldIndex, newIndex);
      onChange(next as DNDItem[]);
    }

    setActiveId(null);
  }
}
