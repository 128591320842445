import { useEffect, useState } from 'react';
import { UndefinedUnion } from 'types/utils';
import { usePersistentState } from 'utils/usePersistentState';

// tracks an input, and holds the last nonnullish value
// this is useful for preventing components from disappearing during apollo refetches (when data is temporarily set to undefined)
export const useLastNonNullishValue = <S>(
  trackedValue: UndefinedUnion<S>
): S => {
  const [current, setCurrent] = useState<S>(trackedValue);

  useEffect(() => setCurrent(trackedValue ?? current), [trackedValue]);

  return current;
};

/**
 *  tracks an input, and holds the last nonnullish value in browser storage
 *
 * @param obj The initial value in the form `{ key: value }`, where `key` is the variable name to be used for storage. The caveat is needing to manually ensure uniqueness.
 * @returns
 */
export const useLastNonNullishPersistentValue = <S>(
  obj: Record<string, UndefinedUnion<S>>
): S => {
  const trackedValue = obj[getVarName(obj)];

  const [current, setCurrent] = usePersistentState<S>(
    makeStorageKey(obj),
    trackedValue
  );

  useEffect(() => setCurrent(trackedValue ?? current), [trackedValue]);

  return current;
};

// helpers
const getVarName = (obj: Record<string, unknown>) => Object.keys(obj)[0];
const makeStorageKey = (obj: Record<string, unknown>) =>
  `portal__${getVarName(obj)}`;
