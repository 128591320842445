import { SVGProps } from 'react';

const FeedbackBackground = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1258"
      height="915"
      viewBox="0 0 1258 915"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.6" clipPath="url(#clip0_1_4098)">
        <mask
          id="mask0_1_4098"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="157"
          y="-29"
          width="944"
          height="944"
        >
          <rect
            width="943.5"
            height="943.5"
            transform="translate(1100.75 915) rotate(-180)"
            fill="url(#paint0_radial_1_4098)"
          />
        </mask>
        <g mask="url(#mask0_1_4098)">
          <g clipPath="url(#clip1_1_4098)">
            <g clipPath="url(#clip2_1_4098)">
              <line
                x1="1100.42"
                y1="915"
                x2="1100.42"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1048.01"
                y1="915"
                x2="1048.01"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="995.589"
                y1="915"
                x2="995.589"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="943.172"
                y1="915"
                x2="943.172"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="890.756"
                y1="915"
                x2="890.756"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="838.339"
                y1="915"
                x2="838.339"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="785.922"
                y1="915"
                x2="785.922"
                y2="-28.4999"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="733.506"
                y1="915"
                x2="733.506"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="681.089"
                y1="915"
                x2="681.089"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="628.673"
                y1="915"
                x2="628.673"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="576.256"
                y1="915"
                x2="576.256"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="523.839"
                y1="915"
                x2="523.839"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="471.423"
                y1="915"
                x2="471.423"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="419.006"
                y1="915"
                x2="419.006"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="366.589"
                y1="915"
                x2="366.589"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="314.173"
                y1="915"
                x2="314.173"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="261.756"
                y1="915"
                x2="261.756"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="209.339"
                y1="915"
                x2="209.339"
                y2="-28.5"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
            </g>
            <g clipPath="url(#clip3_1_4098)">
              <line
                x1="1258"
                y1="862.911"
                x2="0.00024411"
                y2="862.911"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="810.494"
                x2="0.00024411"
                y2="810.494"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="758.078"
                x2="0.00024411"
                y2="758.078"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="705.661"
                x2="0.00024411"
                y2="705.661"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="653.244"
                x2="0.00024411"
                y2="653.244"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="600.828"
                x2="0.00024411"
                y2="600.828"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="548.411"
                x2="0.00024411"
                y2="548.411"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="495.994"
                x2="0.00024411"
                y2="495.994"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="443.578"
                x2="0.00024411"
                y2="443.578"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="391.161"
                x2="0.00024411"
                y2="391.161"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="338.744"
                x2="0.00024411"
                y2="338.744"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="286.328"
                x2="0.00024411"
                y2="286.328"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="233.911"
                x2="0.00024411"
                y2="233.911"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="181.494"
                x2="0.00024411"
                y2="181.494"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="129.078"
                x2="0.00024411"
                y2="129.078"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="76.6611"
                x2="0.00024411"
                y2="76.661"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                x1="1258"
                y1="24.2445"
                x2="0.000488251"
                y2="24.2444"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
            </g>
          </g>
        </g>
      </g>
      <ellipse
        opacity="0.05"
        cx="628.5"
        cy="629.939"
        rx="597.5"
        ry="285.061"
        fill="url(#paint1_radial_1_4098)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1_4098"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(471.75 -0.000112474) rotate(90) scale(943.5 492.447)"
        >
          <stop />
          <stop offset="0.953125" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1_4098"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(628.5 629.939) rotate(90) scale(285.061 597.5)"
        >
          <stop stopColor="#F17732" />
          <stop offset="1" stopColor="#F17732" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_1_4098">
          <rect
            width="1258"
            height="915"
            fill="white"
            transform="translate(1258 915) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip1_1_4098">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="translate(1258 915) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip2_1_4098">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="translate(1258 915) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip3_1_4098">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="translate(1258 915) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FeedbackBackground;
