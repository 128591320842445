// Framework
import Image from 'next/image';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import FeedbackBackground from 'components/selfService/EmployerFeedback/FeedbackBackground';
import { InfiniteHorizontalScroll } from 'components/selfService/InfiniteHorizontalScroll';
import { Section } from 'components/selfService/Section';
import StarIcon from './StarIcon';

// Content
import FEEDBACK_DATA from './content';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const EmployerFeedback = () => {
  const starsToRender = [1, 2, 3, 4, 5];
  return (
    <Section bleed className="py-36">
      <div className="absolute bottom-0 left-1/2 z-10 flex h-[915px] w-full max-w-full -translate-x-1/2 items-start justify-center text-clip">
        <FeedbackBackground />
      </div>
      <div className="relative z-30 overflow-x-hidden">
        <div className="w-full px-4 text-center md:px-0">
          <EmployerText as="h2" size="xl" className="text-orange-500">
            Trades trusted
          </EmployerText>
          <EmployerText
            as="h3"
            size="6xl"
            className="mx-auto mb-6 text-charcoal-500"
          >
            What our customers are saying
          </EmployerText>
        </div>
        <div className="mx-auto mb-16 flex w-max">
          {starsToRender.map((item) => (
            <div key={item}>
              <StarIcon />
            </div>
          ))}
        </div>
        <InfiniteHorizontalScroll className="relative inset-0 flex size-max flex-row items-start justify-start gap-4 transition-transform duration-1000 ease-linear md:gap-8">
          {FEEDBACK_DATA.map((item, index) => (
            <div
              key={index}
              className="w-[307px] rounded-md border border-solid border-orange-100 bg-white p-6 shadow-faded md:w-[537px]"
            >
              <div className="mb-4 flex flex-col gap-0 md:mb-6 md:flex-row md:gap-9">
                <div className="relative z-0 w-min">
                  <div className="relative z-0 size-[72px]">
                    <Image
                      alt="company image"
                      src={item.image}
                      sizes="(max-width: 768px) 20vw, (max-width: 1200px) 15vw, 10vw"
                      className="rounded-full border border-solid border-orange-200 object-cover mix-blend-multiply"
                      fill
                    />
                  </div>
                  <div className="absolute -right-4 bottom-0 z-10 size-10">
                    <Image
                      alt="company logo"
                      src={item.logo}
                      sizes="(max-width: 768px) 15vw, (max-width: 1200px) 10vw, 5vw"
                      className="rounded-md object-cover"
                      fill
                    />
                  </div>
                </div>
                <div>
                  <EmployerText
                    as="p"
                    size="2xl"
                    className="mb-0 font-bold text-charcoal-500 md:mb-1.5"
                  >
                    {item.person}
                  </EmployerText>
                  <EmployerText as="p" size="lg" className="text-orange-500">
                    {item.position} | {item.company}
                  </EmployerText>
                </div>
              </div>
              <EmployerText as="p" size="xl" className="text-charcoal-400">
                {item.text}
              </EmployerText>
            </div>
          ))}
        </InfiniteHorizontalScroll>
      </div>
    </Section>
  );
};
