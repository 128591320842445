// Framework
import { useCallback, useState } from 'react';
import { useMessages } from 'components/Messages';
import { useRouter } from 'next/router';
import axios from 'axios';
import { captureException } from '@sentry/nextjs';

// Components
import { ActionButton } from 'components/ActionButtonGroup/ActionButton';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

// Data
import { Job } from 'database/types';

// Types/Utils
import { GenericJobActionProps, JobItemAlerts } from './types';
import { IconDefaultProps } from './utils';

export function RelistItem({
  jobId,
  employerId,
  status,
}: GenericJobActionProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const { alert, update } = useMessages();
  const handleClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    alert({
      key: JobItemAlerts.RELIST,
      type: 'processing',
      title: 'Processing request',
      dismissable: false,
      duration: null,
    });
    try {
      const {
        data: { id },
      } = await axios.post(`/api/jobs/${jobId}/relist`);

      router.push({
        pathname: '/portal/[employerId]/job/[jobId]/post/1',
        query: {
          employerId,
          jobId: id,
        },
      });
      update({
        key: JobItemAlerts.RELIST,
        type: 'success',
        title: 'Created new listing',
        duration: 3000,
        dismissable: true,
      });
    } catch (err) {
      alert({
        key: JobItemAlerts.RELIST,
        type: 'error',
        title: 'Issue relisting job',
        dismissable: true,
      });
      captureException(err);
    }
  }, [employerId]);
  return (
    <ActionButton
      className="!text-blue-500"
      title="Relist"
      onClick={handleClick}
      show={status === 'expired'}
    >
      <ArrowPathIcon {...IconDefaultProps} />
    </ActionButton>
  );
}
