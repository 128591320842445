import * as yup from 'yup';
import { dateRegEx } from '..';
import { WorkExpData } from '.';

const Validation: yup.ObjectSchema<Omit<WorkExpData, 'id' | 'updated_at'>> =
  yup.object({
    title: yup.string().required('title is required'),
    company: yup.string().required('company name is required'),
    start_date: yup
      .string()
      .matches(dateRegEx, 'not a valid date')
      .required('start date is required'),
    end_date: yup
      .string()
      .matches(dateRegEx, 'not a valid date')
      .nullable()
      // enforce end date if not current
      .test(
        'end_date_valid',
        'end date must be greater than start date',
        (value, { parent }) =>
          parent.current || (value && value >= parent.start_date)
      ),
    current: yup.boolean().required('please select an option'),
    description: yup
      .string()
      .required('please describe your experience in this job.'),
  });

export default Validation;
