import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Maybe } from 'database/types';
import { EmptyProps } from 'types/utils';
import { MessageContainer } from './MessagesContainer';
import { Message } from './Message';

const MESSAGE_DEFAULTS = {
  dismissable: true,
  duration: 3000,
  key: '-1',
  loading: false,
  message: '',
  type: 'info' as MessageType['type'],
  title: undefined,
};

export type MessageType = {
  dismissable?: Maybe<boolean>;
  duration?: Maybe<number>;
  key: string;
  loading?: boolean;
  message?: ReactNode;
  type?: 'error' | 'warning' | 'success' | 'info' | 'processing';
  title?: ReactNode;
};

type MessagesContextType = {
  alert: (a: MessageType) => MessageType;
  dismiss: (c: MessageType['key']) => MessageType;
  messages: MessageType[];
  setMessages: (b: MessageType[]) => void;
  update: (d: UpdateMessageProps) => MessageType;
};

const MessagesContext = createContext<MessagesContextType>({
  alert: (a: MessageType) => a,
  dismiss: ((b: MessageType['key']) => {
    b;
  }) as MessagesContextType['dismiss'],
  messages: [],
  setMessages: (c: MessageType[]) => {
    c;
  },
  update: (d: MessageType) => d,
});

export const Messages: FC<PropsWithChildren<EmptyProps>> = ({ children }) => {
  const [messages, setMessages] = useState<MessageType[]>([]);

  const dismiss = (k: MessageType['key']): MessageType => {
    let dismissed: MessageType = { key: k };
    setMessages((messages) => {
      dismissed = messages?.find(({ key }) => key === k) as MessageType;
      const newMessages = messages?.filter(({ key }) => key !== k);
      return newMessages;
    });
    return dismissed;
  };

  const alert = (message: MessageType) => {
    let newMessage: MessageType = { ...MESSAGE_DEFAULTS, ...message };

    setMessages((messages) => {
      const existing = messages?.find(({ key }) => key === message.key);

      if (existing) {
        const updated: MessageType = { ...existing, ...message };
        newMessage = updated;

        const io = messages.indexOf(existing);
        messages[io] = updated;

        return messages;
      }

      return [...messages, newMessage];
    });
    return newMessage;
  };

  const update = (up: UpdateMessageProps) => {
    let updated = up;
    setMessages((messages) => {
      const old = messages.find(({ key }) => key === up.key) as MessageType;
      updated = { ...old, ...up };

      const next = [...messages];
      const io = messages.indexOf(old);
      next[io] = updated;

      return [...next];
    });
    return updated as MessageType;
  };

  return (
    <MessagesContext.Provider
      value={{
        alert,
        dismiss,
        messages,
        setMessages: useCallback(
          (up: MessageType[]) => setMessages([...up]),
          [setMessages]
        ),
        update,
      }}
    >
      <MessageContainer>
        {messages?.map(({ key, ...message }) => (
          <Message key={key} id={key} {...message} />
        ))}
      </MessageContainer>
      {children}
    </MessagesContext.Provider>
  );
};

type UpdateMessageProps = Pick<
  MessageType,
  'key' | 'title' | 'message' | 'type' | 'duration' | 'dismissable'
>;

export type UseMessageHook = Pick<
  MessagesContextType,
  'alert' | 'dismiss' | 'update'
>;

export const useMessages = (): UseMessageHook => {
  const { alert, dismiss, update } = useContext(MessagesContext);

  return {
    alert,
    dismiss,
    update,
  };
};
