import { useLocalstorageState, useSessionstorageState } from 'rooks';

export const usePersistentState: typeof useSessionstorageState = (
  key,
  defaultValue?
) => {
  const [storedState, setStoredState, removeLocal] = useLocalstorageState(
    key,
    defaultValue
  );
  const [sessionState, setSessionState, removeSession] = useSessionstorageState(
    key,
    storedState
  );

  const setState: typeof setSessionState = (value: any) => {
    // force trigger event on the same window (it won't by default)
    // that will trigger rerender on components that use this hook
    // see also
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    // https://github.com/imbhargav5/rooks/blob/535f788bbd1196fed4ae88cfd2afea36f85a0c53/src/hooks/useSessionstorage.ts
    const event = new StorageEvent('set', {
      key,
      storageArea: sessionStorage,
      newValue: null,
    });
    window.dispatchEvent(event);

    setSessionState(value);
    setStoredState(value);
  };

  const remove = () => {
    removeLocal();
    removeSession();
    // This is a hack to refetch the state when remove function is called
    const a: any = undefined;
    setState(a);
  };

  return [sessionState, setState, remove];
};
