import {
  PencilSquareIcon,
  UsersIcon,
  EyeIcon,
  DocumentPlusIcon,
} from '@heroicons/react/24/outline';

// Hooks
import { useRouter } from 'next/router';

// Database
import { Job } from 'database/types';

// Components
import { DeleteItem } from './DeleteItem';
import { RelistItem } from './RelistItem';
import { IconDefaultProps } from './utils';
import { ActionButtonGroup } from 'components/ActionButtonGroup';

type JobActionsProps = {
  employerId: string;
  jobId: string;
  status: Job['job_status'];
  listing_type: Job['listing_type'];
};

export function JobActions({
  listing_type,
  ..._props
}: JobActionsProps): JSX.Element {
  const router = useRouter();
  const { query } = router;

  const { employerId, jobId, status } = _props;

  const eoi = listing_type === 'expression_of_interest';

  return (
    <ActionButtonGroup>
      <ActionButtonGroup.Item
        show={status !== 'draft'}
        onClick={() =>
          router.push({
            pathname: `/portal/[employerId]/job/[jobId]`,
            query: {
              ...query,
              employerId,
              jobId,
            },
          })
        }
        title="Applicants"
        aria-label="Manage candidates"
      >
        <UsersIcon
          className="-ml-0.5 size-5 text-gray-400"
          aria-hidden="true"
        />
      </ActionButtonGroup.Item>
      {!eoi && (
        <ActionButtonGroup.Item
          show={['active', 'draft'].includes(`${status}`)}
          onClick={() =>
            router.push({
              pathname: `/portal/[employerId]/job/[jobId]${
                `${status}` === 'active' ? '/edit' : '/post'
              }/1`,
              query: {
                ...query,
                employerId,
                jobId,
              },
            })
          }
          title="Edit"
          aria-label="Edit job"
        >
          <PencilSquareIcon {...IconDefaultProps} />
        </ActionButtonGroup.Item>
      )}
      {!eoi && (
        <ActionButtonGroup.Item
          show={true}
          onClick={() =>
            router.push({
              pathname: `/portal/[employerId]/job/[jobId]/preview`,
              query: {
                ...query,
                employerId,
                jobId,
              },
            })
          }
          title="View Job"
          aria-label="View job details"
        >
          <EyeIcon
            className="-ml-0.5 size-5 text-gray-400"
            aria-hidden="true"
          />
        </ActionButtonGroup.Item>
      )}
      <RelistItem {..._props} />
      <DeleteItem {..._props} />
    </ActionButtonGroup>
  );
}
