import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { AlertProps } from './Alert';

export const AlertIcon = ({ type }: Pick<AlertProps, 'type'>): JSX.Element => (
  <div className="shrink-0">
    {(() => {
      switch (type) {
        case 'error':
          return (
            <XMarkIcon className="size-5 text-red-400" aria-hidden="true" />
          );
        case 'info':
          return (
            <InformationCircleIcon
              className="size-5 text-blue-400"
              aria-hidden="true"
            />
          );
        case 'warning':
          return (
            <ExclamationCircleIcon
              className="size-5 text-yellow-400"
              aria-hidden="true"
            />
          );
        case 'success':
          return (
            <CheckCircleIcon
              className="size-5 text-green-400"
              aria-hidden="true"
            />
          );
        default:
          return null;
      }
    })()}
  </div>
);
