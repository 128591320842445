import { twMerge } from 'tailwind-merge';
import { useEffect, useState, VFC } from 'react';

export type TabsProps = {
  tabs: {
    name: string;
    label: string;
    count?: number;
  }[];
  onClick?: (name: string) => void;
  onChange?: (name: string) => void;
  value?: string;
};

export const Tabs: VFC<TabsProps> = ({
  tabs = [],
  onClick,
  onChange,
  value,
}) => {
  const [current, setCurrent] = useState(value);
  useEffect(() => setCurrent(value), [value]);

  const handleClick = (name: string) => {
    setCurrent(name);
    onClick && onClick(name);

    name !== current && onChange && onChange(name);
  };

  if (!tabs.length) return null;

  return (
    <nav
      className="flex min-w-max space-x-6 border-b border-light"
      aria-label="Tabs"
    >
      {tabs.map(({ name, label, count }) => (
        <button
          key={name}
          onClick={() => handleClick(name)}
          className={twMerge(
            'group mb-3 inline-flex items-center space-x-3 border-b-2 px-2 pb-1 pt-4 text-sm uppercase md:text-base',
            current === name
              ? 'border-black font-medium'
              : 'border-transparent tracking-wide text-grey hover:border-grey hover:text-grey-dark'
          )}
          aria-current={current ? 'page' : undefined}
        >
          <span>{label}</span>

          <span
            className={
              current === name
                ? 'text-primary'
                : 'text-grey group-hover:text-grey-dark'
            }
          >
            {count}
          </span>
        </button>
      ))}
    </nav>
  );
};
