import { CreditCardIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import { useState, VFC } from 'react';
import Stripe from 'stripe';
import { Dropdown } from 'components/Dropdown';
import { Modal } from 'components/Modal';
import { Pill } from 'components/Pill';
import { MasterCardIcon, VisaIcon } from 'components/CreditCards/Icons';

type ExistingCardProps = {
  id: string;
  card: Stripe.PaymentMethod.Card;
  isDefault?: boolean;
  detach: (id: string) => Promise<void>;
  setDefault: (id: string) => Promise<void>;
};

export const ExistingCard: VFC<ExistingCardProps> = ({
  id,
  card: { last4, exp_month, exp_year, brand },
  isDefault,
  detach,
  setDefault,
}) => {
  const handleSetDefault = () => setDefault(id);

  const [modalOpen, setModalOpen] = useState(false);
  const handleRemove = () => setModalOpen(true);
  const confirmRemove = () => detach(id);

  const handleModalCancel = () => setModalOpen(false);

  return (
    <div
      className={twMerge(
        'relative inline-flex w-full items-center rounded border bg-white px-4 py-2',
        isDefault ? 'border-black' : 'border-light'
      )}
    >
      <div className="flex w-full flex-nowrap items-center justify-between pl-2">
        <div className="inline-flex flex-nowrap items-center">
          {/* Icon */}
          {brand === 'mastercard' ? (
            <MasterCardIcon className="mr-3 w-5" />
          ) : brand === 'visa' ? (
            <VisaIcon className="mr-3 w-5" />
          ) : (
            <CreditCardIcon className="mr-2 w-6" />
          )}

          <div className="inline-flex flex-nowrap items-center">
            <span className="mr-1 hidden sm:inline">**** ****</span>
            <span> **** {last4}</span>
            {isDefault && <Pill className="ml-3 !py-1">Default</Pill>}
          </div>
        </div>
        <div className="inline-flex flex-nowrap items-center">
          <div className="px-4">
            {exp_month.toLocaleString('en-NZ', {
              minimumIntegerDigits: 2,
            })}
            /
            {(exp_year % 100).toLocaleString('en-NZ', {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div>
            <Dropdown>
              {!isDefault && (
                <Dropdown.Item title="Set Default" onClick={handleSetDefault} />
              )}
              <Dropdown.Item title="Remove" onClick={handleRemove} />
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal
        title="Confirm Delete Card?"
        open={modalOpen}
        onConfirm={confirmRemove}
        onCancel={handleModalCancel}
        dismissable={false}
        forceFocus
      />
    </div>
  );
};
