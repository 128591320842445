import { twMerge } from 'tailwind-merge';

export type StepProps = {
  active?: boolean;
  number: number;
};

export const Step: React.FC<StepProps> = ({ active, number }) => (
  <span
    className={twMerge(
      'mx-auto inline-flex h-8 w-8 place-content-center items-center rounded-full text-lg font-medium text-white',
      active ? 'bg-primary' : 'bg-black-24'
    )}
  >
    {number}
  </span>
);
