import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useFieldError, Errors } from '..';
import { UpdatedInputLabel } from './UpdatedInputLabel';

export type UpdatedInputTextAreaProps =
  InputHTMLAttributes<HTMLTextAreaElement> & {
    className?: string;
    label?: ReactNode;
    name?: string;
    noValidation?: boolean;
    customLabel?: string;
  };

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export const UpdatedInputTextArea = forwardRef<
  HTMLTextAreaElement,
  UpdatedInputTextAreaProps
>(function InputRef(
  { className, customLabel, disabled, label, noValidation, required, ...props },
  ref
) {
  const { error } = useFieldError(props?.name);
  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <UpdatedInputLabel
        label={label}
        name={props?.name}
        required={required}
        customLabel={customLabel}
      />
      <textarea
        ref={ref}
        rows={3}
        cols={50}
        className={twMerge(
          'block w-full rounded border border-light py-4 pl-6 pr-10 text-base text-black shadow-sm placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey',
          error && 'border-red-500 focus:border-red-700 focus:ring-red-500',
          disabled && 'cursor-not-allowed bg-gray-200'
        )}
        disabled={disabled}
        {...props}
      />
      {!noValidation && <Errors error={error} />}
    </div>
  );
});
