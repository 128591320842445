import { VFC } from 'react';

// Components
import {
  CogIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  ArrowLeftEndOnRectangleIcon,
    BriefcaseIcon,
  UserCircleIcon,
  UsersIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { Divider } from 'components/Divider';
import { useEmployerContext } from 'components/pages/PortalEmployer/EmployerContext';
import { PortalLink } from 'components/pages/Portal/PortalLink';
import { Select } from 'components/forms';

export const DesktopNav: VFC = () => {
  const {
    currentEmployer,
    hasMultipleEmployers,
    hasNoEmployers,
    setCurrentEmployer,
    allEmployers,
  } = useEmployerContext();

  const employerIdPath = currentEmployer ? `${currentEmployer}/` : '';
  const selectOptions = allEmployers
    .sort((i) =>
      ['recruiter', 'conglomerate'].includes(i.organisation_type) ? -1 : 1
    )
    .map((i) => ({ value: i.id, label: i.name }));
  return (
    <div
      id="nav-desktop"
      className="relative hidden w-52 bg-white shadow-sm lg:block"
    >
      <div className="sticky top-0 h-full flex-col pt-4 lg:flex">
        {/* Company Select  Desktop*/}
        {hasMultipleEmployers && (
          <Select
            value={currentEmployer}
            onChange={setCurrentEmployer}
            options={selectOptions}
            className="mb-0 mt-4 px-4"
          />
        )}

        <PortalLink
          disabled={hasNoEmployers}
          url={`/portal/${employerIdPath}jobs`}
          icon={BriefcaseIcon}
          matches={[/\/portal\/\[employerId\]\/job.*/]}
        >
          Jobs
        </PortalLink>

        <PortalLink
          url={`/portal/${employerIdPath}applicants`}
          icon={QueueListIcon}
          matches={['/portal/[employerId]/applicants']}
        >
          Applicants
        </PortalLink>

        <PortalLink
          url={
            hasNoEmployers
              ? `/portal/profile`
              : `/portal/${employerIdPath}company`
          }
          icon={UserCircleIcon}
          matches={['/portal/[employerId]/company', '/portal/profile']}
        >
          Profile
        </PortalLink>

        <PortalLink
          disabled={hasNoEmployers}
          url={`/portal/${employerIdPath}billing`}
          icon={CreditCardIcon}
          matches={['/portal/[employerId]/billing']}
        >
          Billing
        </PortalLink>

        <PortalLink
          disabled={hasNoEmployers}
          url={`/portal/${employerIdPath}users`}
          icon={UsersIcon}
          matches={[/\/portal\/\[employerId\]\/user.*/]}
        >
          Users
        </PortalLink>

        <PortalLink url="/portal/settings" icon={CogIcon}>
          Settings
        </PortalLink>

        <Divider className='pt-2 pb-2'/>

        <PortalLink url="/portal/pricing" icon={CurrencyDollarIcon}>
          Pricing
        </PortalLink>

        <PortalLink
          className="link-grey mt-auto inline-flex w-full items-center px-5 py-3 hover:bg-grey-light sm:px-8"
          url="/portal/auth/signout"
          icon={ArrowLeftEndOnRectangleIcon}
        >
          Sign out
        </PortalLink>
      </div>
    </div>
  );
};
