// Types
export interface ContactProps {
  title: string;
  subtitle?: string;
  description: string;
  phone: string;
  email: string;
  address: string;
}

// Components
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { Logo } from 'components/Logo';
import { EmployerText } from '../typography/EmployerText';
import { Section } from '../Section';
import { NewContactForm } from './NewContactForm';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function Contact({
  title,
  phone, // email
}: ContactProps): JSX.Element {
  return (
    <Section
      dark
      id="__contact_form_container"
      className="-mb-8 pb-8 pt-24 md:-mb-12 md:pb-12 lg:-mb-24 lg:pb-24"
    >
      <div className="mx-auto flex max-w-7xl flex-col gap-24 px-6 lg:flex-row lg:gap-32">
        <div className="flex w-full flex-col items-center lg:block lg:w-1/2">
          <div className="max-w-2xl">
            <Logo />
            <EmployerText as="h2" size="2xl" className="mt-[72px]">
              {title}
            </EmployerText>
            <div className="mt-2">
              <a
                href={`tel:${phone}`}
                className="flex gap-4 align-middle hover:underline"
              >
                <PhoneIcon className="size-6 text-primary-500" />
                <EmployerText as="p" size="lg" className="text-orange-50">
                  {phone}
                </EmployerText>
              </a>
              <a
                href="mailto:sales@tradejobsnz.co.nz"
                className="mt-2 flex gap-4 align-middle hover:underline"
              >
                <EnvelopeIcon className="size-6 text-primary-500" />
                <EmployerText as="p" size="lg" className="text-orange-50">
                  sales@tradejobsnz.co.nz
                </EmployerText>
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center lg:w-1/2">
          <NewContactForm />
        </div>
      </div>
    </Section>
  );
}
