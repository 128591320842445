export const Features = [
  {
    name: 'Standard Listing',
    description: 'List your job on Trade Jobs NZ',
    image: '/assets/images/bundle/regular.jpg',
  },
  {
    name: 'Featured Listing',
    description:
      'A Featured Post ensures that your job ad receives increased visibility by appearing at the top of search results. This prime positioning can lead to a higher engagement rate with qualified candidates, making it easier for you to find the right fit for your team.',
    image: '/assets/images/bundle/featured.jpg',
  },
  {
    name: 'Job Re-write',
    description:
      'Let us take care of writing your Job Ad, saving you time and effort.',
    image: '/assets/images/bundle/wewrite.jpg',
  },
  {
    name: 'Bronze Paid Social Campaign',
    description:
      'Reach more potential applications and increase views to your Job Ad.',
    image: '/assets/images/bundle/social.jpg',
  },
  {
    name: 'Silver Paid Social Campaign',
    description:
      'Reach a wider audience with a campaign that will target tradies more likely to apply for a job.',
    image: '/assets/images/bundle/social.jpg',
  },
  {
    name: 'Gold Paid Social Campaign',
    description:
      'Reach a larger, highly targeted audience that will target tradies most likely to apply for a job. Multiple creative assets included.',
    image: '/assets/images/bundle/social.jpg',
  },
  {
    name: 'Radio Donut',
    description:
      'Maximise your reach and brand recognition with inclusion in our Trade Jobs NZ radio donut campaign, reaching even more potential candidates through a highly effective and engaging advertising format.',
    image: '/assets/images/bundle/radio.jpg',
  },
  {
    name: 'Included in our weekly eDM',
    description:
      'Increase visibility and exposure by featuring in our weekly jobs of the week eDM to tradies.',
    image: '/assets/images/bundle/edm.jpg',
  },
  {
    name: 'Homepage feature (1 week)',
    description:
      'Stand out even more with a featured listing on our homepage under "jobs of the week", capturing the attention of job seekers as soon as they land on our website.',
    image: '/assets/images/bundle/jobs_of_the_week.jpg',
  },
];
