import { HomeIcon } from '@heroicons/react/20/solid';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

type Page = {
  name: string;
  href: LinkProps['href'];
  current?: boolean;
};

export type BreadcrumbsProps = {
  isEmployer?: boolean;
  pages?: Page[];
};

export function Breadcrumbs({
  isEmployer,
  pages = [],
}: BreadcrumbsProps): JSX.Element {
  const { query } = useRouter();

  const homeHref = isEmployer
    ? {
        pathname: '/portal/[employerId]/jobs',
        query: { employerId: query.employerId },
      }
    : '/';

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link href={homeHref} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="size-5 shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="size-5 shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {page.current ? (
                <span
                  className="ml-4 cursor-not-allowed text-sm font-medium text-gray-500"
                  aria-current="page"
                >
                  {page.name}
                </span>
              ) : (
                <Link
                  href={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
