import { FC } from 'react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Loading } from 'components/icons';
import { MessageProps } from './Message';

export const MessageIcon: FC<Pick<MessageProps, 'type'>> = ({ type }) => (
  <div className="shrink-0">
    {(() => {
      switch (type) {
        case 'error':
          return (
            <XCircleIcon className="size-5 text-red-400" aria-hidden="true" />
          );
        case 'info':
          return (
            <InformationCircleIcon
              className="size-5 text-blue-400"
              aria-hidden="true"
            />
          );
        case 'warning':
          return (
            <ExclamationTriangleIcon
              className="size-5 text-yellow-400"
              aria-hidden="true"
            />
          );
        case 'success':
          return (
            <CheckCircleIcon
              className="size-5 text-green-400"
              aria-hidden="true"
            />
          );
        case 'processing':
          return (
            <Loading className="size-5 text-gray-400" aria-hidden="true" />
          );
        default:
          return null;
      }
    })()}
  </div>
);
