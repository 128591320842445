import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import Stripe from 'stripe';
import useSWR, { KeyedMutator } from 'swr';
import { AxiosError } from 'axios';
import { useMessages } from 'components';
import { useAxiosSWR } from 'utils/stripe';
import { Maybe } from 'database/types';

export type StripeLineItem = {
  price?: Maybe<string>;
  quantity?: Maybe<number>;
  discounts?: { coupon: string }[];
};

export const INVOICE_ALERT_PROCESSING = 'INVOICE_ALERT_PROCESSING';

type HandlePromiseResponse = Maybe<Stripe.Invoice | Error | void>;

type CreateUpdateFN = (a: PostPutArgs) => Promise<HandlePromiseResponse>;

type PostPutArgs = {
  lineItems?: StripeLineItem[];
  promotion?: string;
  finalize?: boolean;
};

const POST_DEFAULTS: PostPutArgs = {
  lineItems: [],
  promotion: undefined,
  finalize: false,
};

type InvoiceHook = () => {
  create: CreateUpdateFN;
  fetch: () => Promise<HandlePromiseResponse>;
  finalize: () => Promise<HandlePromiseResponse>;
  invoice?: Stripe.Invoice;
  loading: boolean;
  mutate: KeyedMutator<Stripe.Invoice>;
  processing?: boolean;
  promotion: (c?: string) => Promise<HandlePromiseResponse> | undefined;
  update: CreateUpdateFN;
  voidInvoice: () => Promise<HandlePromiseResponse> | undefined;
};

type CheckoutHook = () => {
  processing?: boolean;
  checkout: (a: string, b?: string) => Promise<Stripe.Checkout.Session | void>;
};

export const useCheckout: CheckoutHook = () => {
  const { alert, dismiss } = useMessages();
  const [processing, setProcessing] = useState<boolean>(false);
  const router = useRouter();
  const { employerId, jobId } = router.query;

  const axiosSWR = useAxiosSWR();
  const checkoutEndpoint = `${
    process.env.NEXT_PUBLIC_SITE_URL
  }/api/stripe/customer/${employerId}/checkout${jobId ? '/' + jobId : ''}`;

  useEffect(() => {
    if (processing) {
      alert({
        key: INVOICE_ALERT_PROCESSING,
        title: 'Processing request',
        type: 'processing',
        dismissable: false,
      });
      return;
    }
    dismiss(INVOICE_ALERT_PROCESSING);
  }, [processing]);

  const checkout = async (priceId: string, submissionId?: string) => {
    if (processing) return;
    if (!priceId) {
      alert({
        key: INVOICE_ALERT_PROCESSING,
        title: 'Error processing request',
        message: 'Cart is empty',
        type: 'warning',
        dismissable: true,
        duration: 3000,
      });
      return;
    }
    setProcessing(true);
    try {
      const res = await axiosSWR.post(checkoutEndpoint, {
        priceId,
        submissionId,
      });
      return res as Stripe.Checkout.Session;
    } catch (err) {
      captureException(err);
    } finally {
      setProcessing(false);
    }
  };

  return {
    checkout,
    processing,
  };
};

export const useInvoice: InvoiceHook = () => {
  const { alert, dismiss } = useMessages();
  const [processing, setProcessing] = useState<boolean>(false);
  const router = useRouter();
  const { employerId, jobId } = router.query;

  const axiosSWR = useAxiosSWR();
  const invoiceEndpoint = `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoices/${jobId}`;

  const {
    data: invoice,
    mutate,
    error,
  } = useSWR<Stripe.Invoice>(
    employerId && jobId ? `${invoiceEndpoint}/active` : null,
    axiosSWR.get
  );

  const loading = !(invoice || error);

  useEffect(() => {
    if (processing) {
      alert({
        key: INVOICE_ALERT_PROCESSING,
        title: 'Processing request',
        type: 'processing',
        dismissable: false,
      });
      return;
    }
    dismiss(INVOICE_ALERT_PROCESSING);
  }, [processing]);

  const handlePromiseMutation = useCallback(
    (call: () => Promise<Stripe.Invoice>) =>
      async (): Promise<HandlePromiseResponse> => {
        if (processing || !(employerId && jobId)) return;
        setProcessing(true);
        try {
          const newInvoice = await call();
          mutate(newInvoice, true);
          return newInvoice;
        } catch (err) {
          if (err instanceof AxiosError) {
            const message = err?.response?.data?.message;
            alert({
              key: 'use_invoice_error',
              title:
                typeof message === 'string'
                  ? message
                  : 'Error processing your request',
              type: 'error',
              dismissable: true,
              duration: 3000,
            });
            captureException(err);
            return err;
          }
        } finally {
          setProcessing(false);
        }
      },
    [mutate, processing]
  );

  const fetch = handlePromiseMutation(() =>
    axiosSWR.get<Stripe.Invoice>(`${invoiceEndpoint}/active`)
  );

  const create = async (
    { lineItems = [] }: PostPutArgs = POST_DEFAULTS,
    finalize = false
  ) =>
    axiosSWR.post(invoiceEndpoint, {
      lineItems,
      finalize,
    });

  const update = ({ lineItems = [], promotion }: PostPutArgs) =>
    handlePromiseMutation(() =>
      axiosSWR.put(invoiceEndpoint, {
        promotion,
        lineItems,
        invoiceId: invoice?.id,
      })
    )();

  const finalize = handlePromiseMutation(() =>
    axiosSWR.post(
      `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoice/${invoice?.id}/finalize`
    )
  );

  const voidInvoice = () => {
    if (!(invoice?.id || invoice?.status !== 'open')) return;
    return handlePromiseMutation(() =>
      axiosSWR.delete(
        `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoice/${invoice?.id}/void`
      )
    )();
  };

  const promotion = (code?: string) => {
    if (!invoice?.id) return;
    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoice/${invoice?.id}/promotion`;
    return handlePromiseMutation(() =>
      !code
        ? axiosSWR.delete(url)
        : axiosSWR.post(url, {
            promotion: code,
          })
    )();
  };

  return {
    invoice,
    create,
    update,
    fetch,
    finalize,
    mutate,
    promotion,
    loading,
    processing,
    voidInvoice,
  };
};
