import dayjs from 'dayjs';
import React, {
  Dispatch,
  ReactChild,
  SetStateAction,
  useCallback,
  VFC,
} from 'react';
import { Table, TableProps } from 'components';
import { GetJobsForThisUserQuery } from 'database/types';
import { useEmployerContext } from 'components/pages/PortalEmployer/EmployerContext';
import { PillManager } from 'components/pages/PortalEmployer/ApplicationsEmployer/PillManager';
import { TableTitle } from './TableTitle';
import { JobActions } from './JobActions/JobActions';

export type TableJobsRowData = [ReactChild, string, string, number, ReactChild];

type JobRow = GetJobsForThisUserQuery['job'][number];

export type TableJobsProps = Pick<TableProps, 'loading' | 'empty'> & {
  jobs?: JobRow[];
  setSelectedJobId?: Dispatch<SetStateAction<string | null>>;
  jobSelectedLoading?: boolean;
};

function toDate(date: string): string {
  return dayjs(date).format('D MMM YY');
}

function formatDate({
  list_date,
  expiry_date,
}: Pick<JobRow, 'list_date' | 'expiry_date'>): string {
  return `${list_date ? toDate(list_date) : ''} – ${expiry_date ? toDate(expiry_date) : ''}`;
}

export const TableJobs: VFC<TableJobsProps> = ({
  empty,
  loading,
  jobs,
  setSelectedJobId,
  jobSelectedLoading,
}) => {
  const { isParent } = useEmployerContext();
  const headers = [
    'Job Title',
    'Date Posted',
    'Views',
    'Candidates',
    'Status',
    '',
  ];

  const makeTableRows = useCallback(
    () =>
      ({ jobs = [] }: TableJobsProps): TableProps['rows'] =>
        jobs?.map(
          ({
            id,
            title,
            location: { region, label },
            list_date,
            expiry_date,
            status: { label: statusLabel, value: statusValue },
            applications_aggregate: { aggregate: candidates },
            integration_job,
            listing_type,
            job_views,
            employer_id,
            employer,
          }) => {
            const isIntegration = !!integration_job;
            const data = [
              <TableTitle
                key={title}
                title={title}
                location={region ?? label}
                employer={isParent ? employer?.name : ''}
              />,
              formatDate({ list_date, expiry_date }),
              job_views?.views ?? 0,
              isIntegration ? (
                <a
                  className="inline-flex rounded bg-grey-light px-3 py-2 text-xs font-semibold leading-5 hover:bg-gray-200"
                  href={`${integration_job?.integration.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {integration_job?.integration.label}
                </a>
              ) : (
                <span>{candidates?.count ?? 0}</span>
              ),
              <PillManager key={id}>{statusLabel}</PillManager>,
            ];
            return {
              id,
              data,
              menuElement: (
                <JobActions
                  listing_type={listing_type}
                  employerId={employer_id}
                  jobId={id}
                  status={statusValue as any}
                />
              ),
            };
          }
        ),
    [isParent]
  );

  return (
    <Table
      loading={loading}
      empty={empty}
      headers={headers}
      rows={makeTableRows()({ jobs })}
    />
  );
};
