import { signInWithRedirect } from 'aws-amplify/auth';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import React, { ButtonHTMLAttributes } from 'react';
import { Facebook, Google } from 'components/icons';

export type SocialButtonProps = ButtonHTMLAttributes<Element> & {
  signup?: boolean;
  variant: 'google' | 'facebook';
};

export const SocialButton: React.FC<SocialButtonProps> = ({
  className,
  signup = false,
  variant = 'google',
  ...props
}) => {
  const { pathname, query } = useRouter();
  return (
    <button
      {...(signup && { id: `__social_register_${variant}` })}
      type="button"
      className={twMerge(
        'inline-flex items-center justify-center rounded border border-black-24 px-6 py-4 font-sans font-medium tracking-button shadow drop-shadow-sm focus:outline-none focus:ring-1',
        variant === 'google'
          ? 'border-none bg-white text-black hover:bg-gray-100 focus:ring-gray-500'
          : variant === 'facebook'
            ? 'border-none bg-[#4267B2] text-white hover:bg-[#5074BE] focus:ring-[#4267B2]'
            : '',
        className
      )}
      style={{ height: 'max-content' }}
      onClick={() => {
        !pathname.startsWith('/auth') && !pathname.startsWith('/register')
          ? localStorage.setItem(
              '__federated_signin_origin',
              JSON.stringify({ pathname, query })
            )
          : localStorage.removeItem('__federated_signin_origin');
        // Hack this keeps complaining.
        signInWithRedirect({
          provider: variant === 'google' ? 'Google' : 'Facebook',
        });
      }}
      {...props}
    >
      {variant === 'google' ? (
        <Google className="mr-4 size-5" />
      ) : (
        <Facebook fill="white" className="mr-4 size-5" />
      )}
      <p>
        {signup ? 'Sign up' : 'Log in'} with{' '}
        {variant === 'google' ? 'Google' : 'Facebook'}
      </p>
    </button>
  );
};
