import React, {
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
type DeleteConfirmProps = {
  onDelete: MouseEventHandler<HTMLButtonElement> | undefined;
  onCancel: Dispatch<SetStateAction<boolean>>;
};

const DeleteConfirm = ({ onDelete, onCancel }: DeleteConfirmProps) => {
  const [deleting, setDeleting] = useState(false);
  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    setDeleting(true);
    onDelete && onDelete(e);
  };

  return createPortal(
    <div className="fixed inset-0 flex size-full items-center justify-center bg-charcoal-500/50">
      <div className="rounded-lg bg-cream px-12 py-6 text-center font-satoshi -tracking-two-percent">
        <h2 className="mb-2 text-2xl font-bold text-charcoal-500">
          This can't be undone
        </h2>
        <p className="mb-10 text-lg font-medium text-charcoal-200">
          Are you sure you want to delete it?
        </p>
        <div className="flex justify-between gap-4">
          <button
            className="h-10 rounded-lg bg-orange-500 px-8"
            onClick={handleDelete}
            disabled={deleting}
          >
            <span className="text-lg font-medium text-white">
              {!deleting ? 'Delete' : 'Deleting...'}
            </span>
          </button>
          <button
            className="h-10 rounded-lg border border-solid border-orange-500 bg-white px-8"
            onClick={() => onCancel(true)}
            disabled={deleting}
          >
            <span className="text-lg font-medium text-orange-500">Cancel</span>
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteConfirm;
