import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import getDBClient from 'database/DBClient';
import {
  UpdateCategoryRegisterMutationVariables,
  UpdateCategoryRegisterDocument,
  UpdateCategoryRegisterMutation,
} from 'database/types';

export async function updateCategory(category: string[]) {
  const { userId } = await getCurrentUser();
  const jwtToken = (await fetchAuthSession()).tokens?.idToken?.toString();
  if (!jwtToken) throw Error('Missing auth session');
  const client = await getDBClient({ token: jwtToken as string });

  return client.mutate<
    UpdateCategoryRegisterMutation,
    UpdateCategoryRegisterMutationVariables
  >({
    mutation: UpdateCategoryRegisterDocument,
    variables: {
      id: userId,
      category: category,
    },
  });
}
