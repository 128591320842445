import { ChevronRightIcon } from '@heroicons/react/24/solid';

interface AppDisclosureProps {
  title: string;
  children: React.ReactNode;
  open?: boolean;
  onToggle?: (state: boolean) => void;
}

export function AppDisclosure({
  title,
  children,
  open = false,
  onToggle,
}: AppDisclosureProps): JSX.Element {
  return (
    <details
      className="group"
      open={open}
      onToggle={(e) => onToggle && onToggle((e.target as any).open)}
    >
      <summary className="relative block cursor-pointer">
        <ChevronRightIcon className="absolute inset-1 size-5 text-primary-500 group-open:rotate-90" />
        <p className="pl-10 text-left">{title}</p>
      </summary>
      <div className="mt-4 pb-6 pl-11 pr-1 text-sm text-gray-700">
        {children}
      </div>
    </details>
  );
}
