import { SVGProps } from 'react';
import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from 'next/router';
import { useMessages } from 'components/Messages';

const roles = [
  {
    title: 'Job Seeker',
    description: 'I am applying for roles in a Trade Industry',
    value: 'employee',
  },
  {
    title: 'Employer',
    description: 'I am advertising to find staff for my Trade Business',
    value: 'employer',
  },
];

type RoleType = (typeof roles)[number];

type SelectRoleProps = {
  name?: string;
  onChange: (a: RoleType['value']) => void;
  type: 'main' | 'employer';
  value?: RoleType['value'];
};

const ALERT_KEY = 'REGISTRATION_REDIRECT_ALERT';

export function SelectRole({
  onChange,
  type,
  value,
}: SelectRoleProps): JSX.Element {
  const router = useRouter();
  const { alert, update } = useMessages();

  function redirectCallback(): void {
    const redirectPath = type === 'main' ? '/portal/register' : '/register';
    router.push({
      pathname: redirectPath,
      hash: 'redirect',
    });
    update({
      key: ALERT_KEY,
      dismissable: true,
      message: 'Successfully re-routed',
      duration: 2000,
      type: 'success',
    });
  }

  return (
    <div className="relative mx-auto mb-2 w-full">
      <RadioGroup
        value={value}
        onChange={(a) => {
          onChange(a);
          let role = '';
          if (type === 'employer' && a === 'employee') {
            role = 'job seeker';
          } else if (type === 'main' && a === 'employer') {
            role = 'employer';
          } else {
            onChange(a);
            return;
          }
          alert({
            key: ALERT_KEY,
            message: `Re-routing to ${role} registration`,
            type: 'processing',
            dismissable: false,
            duration: 2000,
          });
          setTimeout(redirectCallback, 1000);
        }}
      >
        <RadioGroup.Label className="sr-only">Role</RadioGroup.Label>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {roles.map(({ value, description, title }) => (
            <RadioGroup.Option
              key={value}
              value={value}
              className={({ active, checked }) =>
                `${
                  active
                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-primary-300'
                    : ''
                }
                  ${checked ? 'bg-primary-500 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
              }
            >
              {({ checked }) => (
                <div className="flex flex-row items-start space-x-2 align-middle">
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`mb-1 font-medium ${
                            checked ? 'text-white' : 'text-gray-900'
                          }`}
                        >
                          {title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? 'text-primary-100' : 'text-gray-500'
                          }`}
                        >
                          {description}
                        </RadioGroup.Description>
                      </div>
                    </div>
                  </div>
                  <div className="shrink-0 text-white">
                    <CheckIcon
                      className={twMerge(
                        'h-6 w-6',
                        checked ? 'visible' : 'opacity-0'
                      )}
                    />
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}

function CheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
