import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const Linkedin: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  fill = 'primary',
  ...props
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 41 41"
    xmlns="http://wwww3.org/2000/svg"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <path
      d="M20 3.05176e-05C8.9542 3.05176e-05 3.05176e-05 8.9542 3.05176e-05 20C3.05176e-05 31.0459 8.9542 40 20 40C31.0459 40 40 31.0459 40 20C40 8.9542 31.0459 3.05176e-05 20 3.05176e-05ZM15.1042 28.2896H11.0542V15.2563H15.1042V28.2896ZM13.0542 13.6563C11.775 13.6563 10.9479 12.75 10.9479 11.6292C10.9479 10.4854 11.8 9.60628 13.1063 9.60628C14.4125 9.60628 15.2125 10.4854 15.2375 11.6292C15.2375 12.75 14.4125 13.6563 13.0542 13.6563ZM29.8959 28.2896H25.8459V21.0667C25.8459 19.3854 25.2584 18.2438 23.7938 18.2438C22.675 18.2438 22.0104 19.0167 21.7167 19.7604C21.6084 20.025 21.5813 20.4 21.5813 20.7729V28.2875H17.5292V19.4125C17.5292 17.7854 17.4771 16.425 17.4229 15.2542H20.9417L21.1271 17.0646H21.2084C21.7417 16.2146 23.0479 14.9604 25.2334 14.9604C27.8979 14.9604 29.8959 16.7459 29.8959 20.5834V28.2896Z"
      fill={fill}
    />
  </svg>
);
