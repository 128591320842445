import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useJitsu } from '@jitsu/nextjs';
import {
  InsertEducationDocument,
  UpdateEducationByPkDocument,
  User_Education,
} from 'database/types';
import { DateISOString } from 'types/utils';
import { useFormSubmit } from 'utils';
import { UpdatedInput } from 'components/forms/UpdatedFormInput';
import { UpdatedRadioBoolean } from 'components/forms/UpdatedRadioBoolean';
import { ErrorProvider, ModalFormButtons, WatchValue } from '..';
import { CustomInputDate } from './CustomInputDate';
import Validation from './Validation';

const DEFAULT_EDU = {
  id: undefined,
  title: '',
  institution: '',
  complete: undefined,
  issue_date: null,
  updated_at: undefined,
};

const formatDefault = ({
  id,
  title,
  institution,
  issue_date,
  complete,
}: User_Education): EducationData => ({
  ...(id && {
    id,
    updated_at: new Date().toISOString(),
  }),
  title,
  institution,
  complete,
  issue_date,
});

export type EducationData = {
  id?: string;
  title: string;
  institution: string;
  complete?: boolean;
  issue_date?: DateISOString | null;
  updated_at?: DateISOString;
};

type EducationProps = {
  onCancel: () => void;
  defaultValues?: User_Education;
};

export const Education: FC<EducationProps> = ({
  defaultValues = DEFAULT_EDU,
  onCancel,
}) => {
  const { track } = useJitsu();
  const initialValues = formatDefault(defaultValues as User_Education);
  const isUpdate = !!initialValues?.id;

  const methods = useForm<EducationData>({
    defaultValues: initialValues,
    resolver: yupResolver(Validation),
  });
  const { control, formState, handleSubmit, register, setValue } = methods;

  const onSubmit = useFormSubmit({
    formName: 'Education',
    mutation: isUpdate ? UpdateEducationByPkDocument : InsertEducationDocument,
    onSuccess: {
      message: `Education ${isUpdate ? 'updated' : 'saved'}`,
      callback: onCancel,
    },
  });

  const resetField = (a: keyof EducationData) => setValue(a, DEFAULT_EDU[a]);

  return (
    <FormProvider {...methods}>
      <ErrorProvider errors={formState.errors}>
        <form
          id="education"
          onSubmit={handleSubmit((vars) => {
            track('education_added');
            onSubmit(vars);
          })}
          className="my-6"
        >
          <UpdatedInput
            {...register('title')}
            required
            autoCapitalize="false"
            label="Certification name"
            placeholder="Certification name"
            customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
          />
          <UpdatedInput
            {...register('institution')}
            required
            label="Issuing organisation"
            customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
            placeholder="Organisation name"
          />
          <UpdatedRadioBoolean
            control={control}
            name="complete"
            label="Have you completed this course?"
          />

          <WatchValue<EducationData>
            control={control}
            name="complete"
            el={
              <CustomInputDate
                control={control}
                name="issue_date"
                label="Issue date"
                placeholder="Issue date"
                resetField={resetField}
                required
              />
            }
          />

          <ModalFormButtons onCancel={onCancel} />
        </form>
      </ErrorProvider>
    </FormProvider>
  );
};
