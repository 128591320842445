const BENEFITS_ITEMS = [
  {
    title: 'We write your job ads',
    description:
      "Too busy on the tools? Tell us who you're looking for and we'll write a well measured job ad that highlights the benefits of working with your team.",
    image: '/assets/images/portal/what/1.jpeg',
  },
  {
    title: 'We talk to tradies where they are',
    description:
      'Facebook, Instagram & Radio. We create social and radio campaigns that get in front of top-tier talent and generate quality applications.',
    image: '/assets/images/portal/what/2.png',
  },
  {
    title: 'Put your business front & centre',
    description:
      "Share the limelight with New Zealand's biggest companies in our Employer Directory and make candidate enquiries simple with a direct link to your profile.",
    image: '/assets/images/portal/what/3.png',
  },
  {
    title: 'We can narrow it down to the best people',
    description:
      'Our recruitment specialists can screen and review applicants to provide you with the dead right person first up.',
    image: '/assets/images/portal/what/4.jpeg',
  },
];

export default BENEFITS_ITEMS;
