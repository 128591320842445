import React from 'react';
import { twMerge } from 'tailwind-merge';
import { DesktopNav } from 'components/Navbar/Desktop';
import { MobileNav } from 'components/Navbar/Mobile/MobileNav';
import { useAuthContext } from 'components/AuthContext';

export type NavbarItemProps = {
  authenticated?: boolean;
} & NavbarProps;

export type NavbarProps = {
  avatar?: string;
  className?: string;
  headerPosition?: 'fixed' | 'relative' | 'absolute';
};

export const Navbar: React.FC<NavbarProps> = ({ avatar, headerPosition }) => {
  const { authenticated } = useAuthContext();

  return (
    <>
      <DesktopNav
        {...{ authenticated, avatar }}
        className={twMerge(
          'z-10 hidden bg-cream-500 lg:block',
          headerPosition ?? 'relative'
        )}
      />
      <MobileNav {...{ authenticated, avatar }} className="lg:hidden" />
    </>
  );
};
