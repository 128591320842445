import { Dayjs } from 'dayjs';
import { Job } from 'database/types';
import { AuthenticatedActions } from 'components/JobCard/AuthenticatedActions';

type TopDateTagProps = Pick<Job, 'id' | 'slug'> & {
  dashboard?: boolean;
  isNew?: boolean;
  featured?: boolean;
  listed: Dayjs;
};

const TopDateTag: React.FC<TopDateTagProps> = ({
  featured,
  isNew,
  listed,
  dashboard,
  id,
  slug,
}) => {
  return (
    <>
      {dashboard && (
        <AuthenticatedActions
          job_id={id}
          dashboard={dashboard}
          slug={slug}
          className="flex flex-col items-end space-x-4 space-x-reverse sm:flex-row-reverse sm:items-center sm:space-x-0"
        />
      )}

      {!dashboard && (
        <div className="absolute right-4 top-4 z-0">
          {isNew && !featured && (
            <>
              <span className="text-base font-medium text-orange">New</span>
              <span className="px-2 text-charcoal-200"> • </span>
              <span className="text-base font-medium text-orange">
                Just now
              </span>
            </>
          )}
          {isNew && featured && (
            <>
              <span className="text-base font-medium text-orange">New</span>
              <span className="px-2 text-charcoal-200"> • </span>
              <span className="text-base font-medium text-orange">
                Featured
              </span>
            </>
          )}
          {!isNew && featured && (
            <>
              <span className="hidden text-base font-medium text-orange sm:inline-block">
                Featured
              </span>
              <span className="hidden px-2 text-charcoal-200 sm:inline-block">
                {' '}
                •{' '}
              </span>
              <span className="shrink-0 text-base text-charcoal-200">
                {listed.fromNow(true)}
              </span>
            </>
          )}
          {!isNew && !featured && (
            <>
              <span className="shrink-0 text-base text-charcoal-200">
                {listed.fromNow(true)}
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TopDateTag;

<span className="px-2 text-charcoal-200"> • </span>;
