import { captureException } from '@sentry/nextjs';
import { confirmSignUp, signIn } from 'aws-amplify/auth';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { Errors } from 'components/forms';
import { useMessages } from 'components';

export type VerifyProps = {
  code?: string;
  username: string;
  password?: string;
  isEmployer?: boolean;
};

export function Verify({
  code,
  username,
  password,
  isEmployer,
}: VerifyProps): JSX.Element {
  const { alert } = useMessages();
  const [loading, setLoading] = useState(false);

  const router = useRouter();

  const [error, setError] = useState('');

  async function onSubmit(c: string) {
    try {
      await confirmSignUp({
        username,
        confirmationCode: c,
      });

      password && (await signIn({ username, password }));
      alert({
        key: 'VERIFY',
        type: 'success',
        title: 'Email verified',
      });

      const redirectUrl = isEmployer
        ? password
          ? '/portal/jobs'
          : `/portal?e=${username}`
        : password
          ? '/jobs'
          : `/auth?e=${username}`;

      router.push(redirectUrl);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setError(error?.message);
      }
      captureException(error);
    }
  }

  useEffect(() => {
    if (!code || code.length !== 6) return;
    onSubmit(code);
  }, [code]);

  return (
    <div>
      <label className="mb-2 block text-sm uppercase tracking-wider text-grey">
        Validation Code
      </label>
      <p className="mb-5 block text-left text-sm">
        Enter the validation code that has been sent to{' '}
        <strong>{username}</strong>
      </p>
      <ReactCodeInput
        loading={loading}
        values={code?.split('') ?? []}
        autoFocus
        onComplete={(value: string) =>
          setTimeout(() => {
            setLoading(true);
            onSubmit(value);
          }, 200)
        }
      />
      <Errors error={{ type: 'value', message: error }} />
    </div>
  );
}
