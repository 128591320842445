import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const Instagram: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  fill = 'primary',
  ...props
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 41 41"
    xmlns="http://wwww3.org/2000/svg"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM16.6626 20C16.6626 18.1638 18.1611 16.6652 19.9973 16.6652C21.8335 16.6652 23.332 18.1638 23.332 20C23.332 21.8362 21.8335 23.3348 19.9973 23.3348C18.1611 23.3348 16.6626 21.8362 16.6626 20ZM29.9993 19.4949C29.9991 19.6631 29.9989 19.8314 29.9989 20C29.9989 20.1885 29.9992 20.3768 29.9994 20.5648C30.0012 21.7547 30.003 22.9375 29.9338 24.1278C29.8563 25.7289 29.4936 27.1473 28.3203 28.3206C27.1495 29.4914 25.7286 29.8567 24.1275 29.9342C22.9174 30.0023 21.7149 30.001 20.5049 29.9996C20.3367 29.9994 20.1684 29.9993 19.9998 29.9993C19.8316 29.9993 19.6636 29.9994 19.4956 29.9996C18.2873 30.001 17.0845 30.0023 15.8721 29.9342C14.271 29.8567 12.8526 29.4939 11.6793 28.3206C10.5086 27.1498 10.1433 25.7289 10.0658 24.1278C9.9977 22.9175 9.99903 21.7131 10.0004 20.5043C10.0006 20.3363 10.0007 20.1682 10.0007 20C10.0007 19.8318 10.0006 19.6637 10.0004 19.4958C9.99903 18.2874 9.9977 17.0846 10.0658 15.8722C10.1433 14.2711 10.5061 12.8527 11.6793 11.6794C12.8501 10.5086 14.271 10.1433 15.8721 10.0658C17.0823 9.9977 18.2847 9.99903 19.4947 10.0004C19.6629 10.0006 19.8313 10.0007 19.9998 10.0007C20.168 10.0007 20.3361 10.0006 20.504 10.0004C21.7124 9.99903 22.9151 9.9977 24.1275 10.0658C25.7286 10.1433 27.147 10.5061 28.3203 11.6794C29.4911 12.8502 29.8563 14.2711 29.9338 15.8722C30.0019 17.0824 30.0006 18.2849 29.9993 19.4949ZM14.8664 20C14.8664 22.8394 17.1579 25.131 19.9973 25.131C22.8367 25.131 25.1282 22.8394 25.1282 20C25.1282 17.1606 22.8367 14.869 19.9973 14.869C17.1579 14.869 14.8664 17.1606 14.8664 20ZM24.14 14.6589C24.14 15.3218 24.6754 15.8572 25.3383 15.8572C25.4957 15.8574 25.6516 15.8265 25.7971 15.7664C25.9426 15.7062 26.0748 15.618 26.1861 15.5067C26.2974 15.3954 26.3856 15.2632 26.4458 15.1177C26.5059 14.9722 26.5368 14.8163 26.5366 14.6589C26.5366 13.9959 26.0013 13.4606 25.3383 13.4606C24.6754 13.4606 24.14 13.9959 24.14 14.6589Z"
      fill={fill}
    />
  </svg>
);
