import { Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { VFC } from 'react';
type HambuerguerButtonProps = {
  open: boolean;
};

export const HambuerguerButton: VFC<HambuerguerButtonProps> = ({ open }) => (
  <div className="-my-2 -mr-2">
    <Popover.Button
      className="inline-flex items-center justify-center rounded py-2 pr-2 text-night-rider focus:outline-none focus:ring focus:ring-inset focus:ring-white"
      aria-label={open ? 'Close menu' : 'Open menu'}
    >
      {!open && (
        <Bars3Icon className="size-6" color="#1C1B19" aria-hidden="true" />
      )}
      {open && (
        <XMarkIcon className="size-6" color="#1C1B19" aria-hidden="true" />
      )}
    </Popover.Button>
  </div>
);

export default HambuerguerButton;
