import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Menu } from '@headlessui/react';
import { Link, LinkProps } from 'components/Link';
type ItemProps = {
  text: ReactNode;
  href: LinkProps['href'];
  icon: ReactNode;
};

export const Item: FC<ItemProps> = ({ text, href, icon }) => (
  <Menu.Item>
    {({ active }) => (
      <Link
        href={href}
        className={twMerge(
          ' flex justify-start items-center px-4 py-2 text-sm hover:bg-grey-light',
          active ? 'bg-grey-light' : '',
          'hover:bg-cream-500 hover:rounded-md'
        )}
      >
        {icon}
        {text}
      </Link>
    )}
  </Menu.Item>
);
