import { useState } from 'react';
import { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import { useJitsu } from '@jitsu/nextjs';
import { Maybe } from 'database/types';

import { BundleItem } from 'components/Products/Bundles/BundleItem';
import { Features } from './Features';
import { Bundles } from './Bundles/Bundles';

export interface Bundle {
  id: string;
  features?: Array<string>;
  price: number;
  description: string;
  recommended?: boolean;
  subtitle?: Maybe<string>;
  title: string;
  checkout?: boolean;
  info: Array<{
    id: string;
    title: string;
    description: string;
    image: [ImageProps];
  }>;
}

export type ProductsProps = {
  bundles: Array<Bundle>;
  selectedId: string;
};

export function Products({ bundles, selectedId }: ProductsProps): JSX.Element {
  const selected = bundles.find((i) => i.id === selectedId);
  const [plan, setPlan] = useState(bundles[1]);
  const router = useRouter();
  const { track } = useJitsu();
  return (
    <div className="my-16 space-y-16">
      <div className="isolate mx-auto grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:mx-0 lg:grid-cols-2">
        <div>
          <BundleItem
            selected={false}
            id="standard"
            price={99}
            title="Standard"
            subtitle="Job Listing"
            description="Our regular listing type. Default priority in search results."
            checkout={false}
            onClick={() => {
              router.push(`/portal/register`);
              track('package_register', {
                id: 'standard',
                price: 99,
                title: 'Standard',
              });
            }}
          />
        </div>
        <div>
          <BundleItem
            selected={false}
            id="featured"
            price={189}
            title="Featured"
            subtitle="Job Listing"
            description="Higher priority in search results, stand-out listing."
            checkout={false}
            onClick={() => {
              router.push(`/portal/register`);
              track('package_register', {
                id: 'featured',
                price: 189,
                title: 'Featured',
              });
            }}
          />
        </div>
      </div>
      <Bundles
        bundles={bundles.sort((a, b) => a.price - b.price)}
        selected={selectedId}
      />
      {/* <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
        <div className="lg:min-w-0 lg:flex-1">
          <h3 className="text-lg font-semibold leading-8 tracking-tight text-primary">
            Just looking to post a job?
          </h3>
          <p className="mt-1 text-base leading-7 text-gray-600">
            Post a standard ad from $99 and get access to our employer portal.
          </p>
        </div>
        <Link
          href="/portal/register"
          className="relative right-0 inline-flex items-center justify-center rounded-full bg-primary px-6 py-2 align-middle font-sans text-base leading-5 tracking-button text-white shadow ring-1 ring-inset ring-transparent hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary"
        >
          Register now
        </Link>
      </div> */}
      <div className="">
        {selected && (
          <Features {...plan} plan={plan} setPlan={setPlan} bundles={bundles} />
        )}
      </div>
    </div>
  );
}
