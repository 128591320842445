import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import Stripe from 'stripe';
import useSWR from 'swr';
import { useMessages } from 'components';
import { useAxiosSWR } from 'utils/stripe';

type CustomerExpanded = Omit<Stripe.Customer, 'invoice_settings'> & {
  invoice_settings: Omit<
    Stripe.Customer.InvoiceSettings,
    'default_payment_method'
  > & {
    default_payment_method: Stripe.PaymentMethod;
  };
};

export const useCustomer = () => {
  const router = useRouter();
  const { employerId } = router.query;

  const { alert } = useMessages();

  const axiosSWR = useAxiosSWR();
  const customerEndpoint = `/api/stripe/customer/${employerId}`;

  const { data, mutate, error } = useSWR<CustomerExpanded>(
    employerId ? customerEndpoint : null,
    axiosSWR.get
  );
  const loading = !data && !error;

  const update = useCallback(
    async (payload: Stripe.CustomerUpdateParams) => {
      try {
        const newData = await axiosSWR.post<CustomerExpanded>(
          `${customerEndpoint}/update`,
          payload
        );

        return mutateWithAlerts(newData, false);
      } catch (err) {
        console.error(err);
        captureException(err);
      }
    },
    [employerId, mutate]
  );

  const mutateWithAlerts: typeof mutate = async (...args) => {
    try {
      const promise = mutate(...args);

      alert({
        key: 'CustomerUpdateSuccess',
        title: 'Customer Updated!',
        type: 'success',
        dismissable: true,
        duration: 3000,
      });

      return promise;
    } catch (err) {
      console.error(err);
      captureException(err);
      alert({
        key: 'CustomerUpdateError',
        title: 'Error Updating Customer',
        type: 'error',
        dismissable: true,
        duration: 3000,
      });
    }
  };

  return { customer: data, loading, update, mutate: mutateWithAlerts };
};
