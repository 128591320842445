import { captureException } from '@sentry/nextjs';
import axios from 'axios';

type HubspotSubmitProps = {
  portalId: string;
  formGuid: string;
  fields: any;
};

const HubspotSubmit = async ({
  portalId,
  formGuid,
  fields,
}: HubspotSubmitProps): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields,
      },
      config
    );
  } catch (err) {
    captureException(err);
    return err;
  }
};

export default HubspotSubmit;
