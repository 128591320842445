import React, { ReactElement, VFC } from 'react';
import { TableHeader, TableHeaderProps } from 'components/Table/Header';
import { TableLoading } from 'components/Table/Loading';
import { TableRow, TableRowProps } from 'components/Table/Row';
import { Empty } from '..';

export type TableProps = TableHeaderProps & {
  rows?: TableRowProps[];
  loading?: boolean;
  empty?: ReactElement<typeof Empty>;
};

export const Table: VFC<TableProps> = ({
  rows = [],
  headers,
  empty = <Empty />,
  loading,
}) => (
  <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div className="min-w-full rounded border border-grey-light bg-white shadow">
      {/* Loading */}
      {loading && !rows?.length ? (
        <TableLoading />
      ) : (
        <table className="min-w-full divide-y divide-grey-light">
          <TableHeader {...{ headers }} />
          <tbody className="divide-y divide-grey-light rounded bg-white">
            {rows.map((row, i) => (
              <TableRow {...row} key={i} />
            ))}
          </tbody>
        </table>
      )}

      {/* Empty */}
      {!loading && !rows?.length && (
        <div className="flex justify-center">
          <div className="min-w-max py-10">{empty}</div>
        </div>
      )}
    </div>
  </div>
);
