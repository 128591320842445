import { useEffect, useState, VFC } from 'react';
import Stripe from 'stripe';
import { SubscriptionModal } from 'components';
import { Modal, ModalProps } from 'components/Modal';
import { makeLabel } from 'components/SubscriptionController/helpers';
import { SubscriptionDrawer } from 'components/SubscriptionController/SubscriptionDrawer';
import { SubscriptionViewProps } from 'components/SubscriptionController/SubscriptionModal';
import { usePaymentMethods, useSubscriptions } from 'utils/stripe';

export type CompanySubscriptionControllerProps = {
  employer_id?: string;
  onClose?: () => void;
  open?: SubscriptionView;
};

export type SubscriptionView = 'drawer' | 'modal' | 'warning';
export type SubscriptionPaymentSteps =
  | 'select'
  | 'card_check'
  | 'card_add'
  | 'confirm'
  | 'pay'
  | 'return'
  | 'failed';

export const SubscriptionController: VFC<
  CompanySubscriptionControllerProps
> = ({ open, onClose }) => {
  const {
    pageSubscriptionItems,
    create,
    loading: subsLoading,
  } = useSubscriptions();

  // Make Options
  const getOptions = (): SubscriptionViewProps['options'] =>
    pageSubscriptionItems.map((item) => ({
      label: makeLabel(item),
      value: item.id,
    }));
  const defaultPlan = getOptions()[0]?.value;
  const [plan, setPlan] = useState<SubscriptionViewProps['plan']>(defaultPlan);
  useEffect(() => setPlan(plan || defaultPlan), [defaultPlan]);

  // modal/drawer state
  const { loading: cardLoading, defaultId, defaultCard } = usePaymentMethods();
  const [step, setStep] = useState<SubscriptionPaymentSteps>('select');
  useEffect(() => {
    if (cardLoading || subsLoading) return;
    switch (step) {
      case 'card_check':
        setStep(defaultId ? 'confirm' : 'card_add');
        break;
      case 'pay':
        handlePayment();
        break;
      default:
        break;
    }
  }, [step, defaultId, cardLoading]);

  const closeAndReset = () => {
    onClose && onClose();
    setTimeout(() => setStep('select'), 300);
  };

  const handlePayment = async () => {
    const newSub: Stripe.SubscriptionCreateParams['items'] = [{ price: plan }];
    const created = await create(newSub);

    if (created?.status === 'active') setStep('return');
    else setStep('failed');
  };

  return (
    <>
      {/* handle case where page_active = false even though there's an active subscription (e.g. webhook failed for some reason) */}
      <Modal
        open={open === 'warning'}
        onClose={closeAndReset}
        {...MODAL_SubscriptionActiveWarning}
      />

      <SubscriptionDrawer
        open={open === 'drawer'}
        options={getOptions()}
        onClose={closeAndReset}
        {...{ step, setStep, plan, setPlan, defaultCard }}
      />

      <SubscriptionModal
        open={open === 'modal'}
        options={getOptions()}
        onClose={closeAndReset}
        {...{ step, setStep, plan, setPlan, defaultCard }}
      />
    </>
  );
};

const MODAL_SubscriptionActiveWarning: ModalProps = {
  title: 'Something went wrong',
  description:
    "You already have an active subscription. If your Employer Page isn't active yet, please reload the page and try again. If the issue persists, please contact our support team.",
  type: 'warning',
};
