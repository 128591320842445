import React, { useState, useEffect, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useJitsu } from '@jitsu/nextjs';
import { JSONValue } from '@segment/analytics-next';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { RichText } from 'components/forms/RichText';
import { UpdatedDrawer } from 'components/UpdatedDrawer';
import {
  UpdateEmployeeByPkDocument,
  User,
  Maybe,
  GetUserByPkDocument,
  useGetEnumCategoriesQuery,
  Enum_Category_Enum,
  Enum_Category,
  GetEmployeeProfileQuery,
} from 'database/types';
import { useFormSubmit } from 'utils';
import { useAuthContext } from 'components/AuthContext';
import {
  UpdatedInput,
  UpdatedInputPhone,
} from 'components/forms/UpdatedFormInput';
import { ErrorProvider } from 'components/forms';
import UpdatedMultiSelect from 'components/forms/newDesign/UpdatedMultiSelect';
import { SkeletonCard } from 'components';
import ProfileEditHeader from 'components/register/RegisterProfileEdit/ProfileEditHeader';
import ProfileEditAbout from 'components/register/RegisterProfileEdit/ProfileEditAbout';
import Validation from './Validation';

type Location = {
  district: string;
  id: string;
  label: string;
  region: string;
};

export type ProfileFieldData = {
  id: string;
  category?: Maybe<JSONValue>;
  email: string;
  first_name: string;
  last_name: string;
  phone?: Maybe<string>;
  profile_photo?: string;
  about: Maybe<string>;
  location?: Location;
};

const DEFAULT_VALUES: ProfileFieldData = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  category: [],
  profile_photo: '',
  about: '',
  location: {
    district: '',
    label: '',
    region: '',
    id: '',
  },
};

const formatValues =
  (tradie = false) =>
  ({
    id,
    email,
    first_name,
    last_name,
    phone,
    location,
    category = [],
    profile_photo = '',
    about = '',
  } = DEFAULT_VALUES): ProfileFieldData => ({
    id,
    email,
    first_name,
    last_name,
    phone,
    profile_photo,
    about,
    location,
    ...(tradie && { category }),
  });

type ProfileProps = {
  defaultValues: User;
  tradie: boolean;
  loading?: boolean;
};

export const Profile: FC<ProfileProps> = ({
  defaultValues: d,
  tradie,
  loading,
}) => {
  const [isClient, setIsClient] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const { data } = useGetEnumCategoriesQuery();
  const { user } = useAuthContext();
  const { track } = useJitsu();
  const defaultValues = formatValues(tradie)(d as ProfileFieldData);
  const methods = (useForm as any)({
    defaultValues,
    resolver: yupResolver(Validation),
  });

  const { formState, handleSubmit, register, setValue, control } = methods;

  const onSubmit = useFormSubmit({
    formName: 'Profile',
    mutation: UpdateEmployeeByPkDocument,
    options: {
      refetchQueries: [
        { query: GetUserByPkDocument, variables: { id: user?.sub } },
      ],
    },
    onSuccess: {
      message: 'Profile updated',
    },
  });

  // watch('about');
  const onDescriptionChange = (content: string) =>
    setValue(
      'about',
      content.replace(
        // trim 2+ repeating blank lines
        /<p><br><\/p><p><br><\/p>(.+)/g,
        '<br>$1'
      ),
      { shouldDirty: true, shouldTouch: true }
    );

  const categories = data?.enum_category;

  return (
    <>
      <ProfileEditHeader
        hideTitle
        editAction={() => setShowForm(true)}
        userData={defaultValues as GetEmployeeProfileQuery['user_by_pk']}
      />
      <ProfileEditAbout
        userData={defaultValues as GetEmployeeProfileQuery['user_by_pk']}
        // editAction={() => setShowForm(true)}
      />
      <UpdatedDrawer open={!!showForm} onClose={() => setShowForm(false)}>
        <div className="default-padding z-20 w-screen pb-8 sm:pb-12 md:max-w-xl xl:max-w-2xl">
          <button
            onClick={() => setShowForm(false)}
            className="link flex items-center p-2 py-4"
          >
            <ChevronLeftIcon className="mr-4 h-4" /> Back
          </button>
          <div id="profile-personal" className="space-y-4 md:space-y-6">
            <p className="border-b border-solid border-charcoal-50 pb-2 text-2xl font-bold">
              Personal Info
            </p>
            {loading ? (
              <SkeletonCard />
            ) : (
              <form
                id="profile"
                onSubmit={handleSubmit((vars: ProfileFieldData) => {
                  onSubmit(vars);
                  track('profile_updated');
                })}
                className="my-6"
              >
                <ErrorProvider errors={formState.errors}>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-5">
                      <UpdatedInput
                        {...register('first_name')}
                        autoFocus
                        required
                        label="First name"
                        placeholder="First name"
                        customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
                      />
                    </div>
                    <div className="col-span-7">
                      <UpdatedInput
                        {...register('last_name')}
                        required
                        label="Last name"
                        placeholder="Last name"
                        customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
                      />
                    </div>
                  </div>
                  <UpdatedInput
                    name="email"
                    value={defaultValues?.email}
                    required
                    disabled
                    autoCapitalize="false"
                    label="Email address"
                    placeholder="Email address"
                    customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
                  />
                  <Controller
                    control={control}
                    defaultValue={defaultValues.phone}
                    name="phone"
                    render={({ field }) => (
                      <UpdatedInputPhone
                        required
                        defaultValue={defaultValues.phone}
                        label="Phone"
                        onChange={field.onChange}
                        customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
                      />
                    )}
                  />
                  <RichText
                    name="about"
                    control={control}
                    label="About you"
                    placeholder="Description"
                    onChange={onDescriptionChange}
                  />
                  {tradie && categories && isClient && (
                    <Controller
                      control={control}
                      defaultValue={
                        defaultValues.category as Enum_Category_Enum[]
                      }
                      name="category"
                      render={({ field }) => (
                        <UpdatedMultiSelect
                          value={field.value}
                          options={categories as Enum_Category[]}
                          defaultValues={
                            defaultValues.category as Enum_Category_Enum[]
                          }
                          label="Trade category"
                          name="category"
                          onChange={field.onChange}
                          customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
                        />
                      )}
                    />
                  )}
                  <div className="flex justify-start">
                    <button
                      disabled={formState.isSubmitting}
                      className="mx-auto mt-4 h-10 min-w-52 rounded-lg bg-orange-500 px-8"
                      type="submit"
                    >
                      <span className="text-lg font-medium text-white">
                        {formState.isSubmitting ? 'Loading' : 'Save'}
                      </span>
                    </button>
                  </div>
                </ErrorProvider>
              </form>
            )}
          </div>
        </div>
      </UpdatedDrawer>
    </>
  );
};
