import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';
import { useForm } from 'react-hook-form';
import HubspotSubmit from 'utils/HubspotSubmit';
import { Input } from '..';
import { Button, useMessages } from '../..';
import Validation from './Validation';

export type FormData = {
  name: string;
  email: string;
};

type Props = {
  hash?: string;
};

export const NewsLetter: FC<Props> = ({ hash = '' }) => {
  const { alert } = useMessages();
  const { handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      name: '',
      email: '',
    },
    resolver: yupResolver(Validation),
  });

  async function onSubmit({ name, email }: FormData) {
    try {
      const portalId = '20147764';
      const formGuid = '5fd91072-791e-4ec1-926c-3a9b73c5b5a2';
      const fields = [
        {
          name: 'firstname',
          value: name,
        },
        {
          name: 'email',
          value: email,
        },
      ];

      await HubspotSubmit({ portalId, formGuid, fields });

      alert({
        key: 'NewsLetter',
        type: 'success',
        title: 'You are subscribed',
        // message: 'Please check your email to approve your subscription.',
        dismissable: true,
        duration: 3000,
      });
    } catch (error: any) {
      alert({
        key: 'NewsLetter',
        type: 'error',
        title: error.message,
        dismissable: true,
      });
      captureException(error);
    }
  }

  return (
    <form
      id={`newsletter${hash}`}
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-row"
    >
      <div className="flex flex-col space-y-2 self-center md:flex-row md:items-end md:space-x-3">
        <div className="mt-2 flex grow flex-col space-y-2">
          <Input
            {...register('name')}
            required
            noValidation
            placeholder="First name"
          />
          <Input
            {...register('email')}
            required
            noValidation
            autoCapitalize="false"
            placeholder="Email address"
          />
        </div>
        <div className="relative shrink">
          <Button type="submit" className="whitespace-nowrap !text-base">
            Sign up
          </Button>
        </div>
      </div>
    </form>
  );
};
