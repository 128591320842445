// Framework
import { TrashIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

// Hooks
import { useMessages } from 'components/Messages';
import { useEmployerContext } from 'components/pages/PortalEmployer/EmployerContext';

// Database
import {
  useDeleteJobByPkMutation,
  GetJobsForThisUserDocument,
  Job,
} from 'database/types';

// Types
import { GenericJobActionProps, JobItemAlerts } from './types';
import { IconDefaultProps } from './utils';

// Components
import { ActionButton } from 'components/ActionButtonGroup/ActionButton';

export function DeleteItem({
  jobId,
  employerId,
  status,
}: GenericJobActionProps): JSX.Element {
  const { alert } = useMessages();
  const { isParent } = useEmployerContext();
  const [deleteJob] = useDeleteJobByPkMutation({
    refetchQueries: [
      {
        query: GetJobsForThisUserDocument,
        variables: {
          where: isParent ? {} : { employer_id: { _eq: employerId } },
        },
      },
    ],
  });
  const handleClick = async () => {
    try {
      await deleteJob({
        variables: { jobId, deleted_at: new Date().toISOString() },
      });
      alert({
        key: JobItemAlerts.DELETE,
        type: 'success',
        title: 'Job deleted',
        dismissable: true,
        duration: 3000,
      });
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: JobItemAlerts.DELETE,
          type: 'error',
          title: 'Issue deleting job',
          message: err?.message,
          dismissable: true,
        });
      }
    }
  };
  return (
    <ActionButton
      title="Delete"
      onClick={handleClick}
      show={['expired', 'draft'].includes(status)}
    >
      <TrashIcon
        className={twMerge(IconDefaultProps.className, 'text-red-500')}
        aria-hidden={IconDefaultProps['aria-hidden']}
      />
    </ActionButton>
  );
}
