import { VFC } from 'react';
import Stripe from 'stripe';
import { CartLine } from './CartLine';

type InvoiceItemProps = Stripe.InvoiceLineItem & {
  title: string | ((a: Stripe.InvoiceLineItem) => string);
  subtitle?: string | ((b: Stripe.InvoiceLineItem) => string);
};

export const InvoiceItem: VFC<InvoiceItemProps> = ({
  title,
  subtitle,
  ...props
}) => {
  const { price, discount_amounts } = props;
  const amount = price !== null ? price.unit_amount : 0;
  const discount = discount_amounts?.reduce(
    (res, { amount }) => (res += amount),
    0
  );
  return (
    <CartLine
      title={typeof title === 'string' ? title : title(props)}
      subtitle={
        !subtitle || typeof subtitle === 'string' ? subtitle : subtitle(props)
      }
      price={amount ?? 0}
      discount={discount}
    />
  );
};
