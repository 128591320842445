import { RadioGroup as RadioGroupDefault } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import { RadioOption } from 'components/forms/RadioGroup/RadioOption';
import { Errors, InputLabel } from '..';

const defaultOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export type RadioGroupProps = Pick<
  Parameters<typeof RadioGroupDefault>[0],
  'disabled' | 'className'
> & {
  control: any;
  defaultValue?: RadioOption['value'];
  label?: string;
  name: string;
  options?: RadioOption[];
  required?: boolean;
  type?: 'vertical' | 'horizontal';
};

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  function RenderRadioGroup(
    {
      control: c,
      className,
      defaultValue,
      disabled,
      label,
      name,
      options = defaultOptions,
      required = false,
      type = 'vertical',
    },
    ref
  ) {
    const {
      field,
      fieldState: { error },
    } = useController({ control: c, name, defaultValue });

    return (
      <RadioGroupDefault
        {...field}
        disabled={disabled}
        ref={ref}
        className={twMerge(className, error ? 'mb-2' : 'mb-8')}
      >
        <InputLabel label={label} name={name} required={required} />
        <span
          className={twMerge(
            'flex',
            type === 'vertical' ? 'flex-col space-y-2' : 'flex-row space-x-2'
          )}
        >
          {options?.map(({ value, label }) => (
            <RadioOption {...{ value, label }} key={value?.toString() ?? ''} />
          ))}
        </span>
        <Errors error={error} />
      </RadioGroupDefault>
    );
  }
);
