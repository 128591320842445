import { FC, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { FullPageLoader } from 'components/FallbackLoader/FullPageLoader';
import { EmptyProps } from 'types/utils';

export const FallbackLoader: FC<PropsWithChildren<EmptyProps>> = ({
  children,
}) => {
  const router = useRouter();

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  // this prevents page breaking due to undefined props
  // https://nextjs.org/docs/basic-features/data-fetching#fallback-pages
  return router.isFallback ? <FullPageLoader /> : <>{children}</>;
};
