// Framework
import Image from 'next/image';
import Link from 'next/link';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { EmployerButton } from 'components/selfService';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const HireCta = () => (
  <section className="relative z-0 mx-auto mb-24 size-full max-h-[426px] max-w-[1733px] md:mb-36">
    <div className="relative z-0 h-[426px] w-full overflow-hidden  rounded-lg bg-[#1C1C1A] mix-blend-multiply">
      <Image
        alt=""
        src="/assets/images/portal/start_hiring.jpeg"
        sizes="(max-width: 768px) 100vw, 95vw"
        className="object-cover object-center opacity-50"
        fill
      />
    </div>
    <div className="absolute inset-0 z-10 flex h-full flex-col items-center justify-center px-4 text-center">
      <EmployerText
        as="h2"
        size="8xl"
        className="mb-6 w-72 max-w-[550px] text-white md:w-full"
      >
        Start hiring for your work site now
      </EmployerText>
      {/* <EmployerText
        as="p"
        size="xl"
        className="max-w-[619px] font-medium text-orange-50"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut ante nec
        mauris efficitur vehicula. Fusce bibendum eget
      </EmployerText> */}
      <div className="mx-auto mt-10 block w-max">
        <Link href="/portal/register" passHref>
          <EmployerButton size="lg" className="w-[200px]">
            Get started
          </EmployerButton>
        </Link>
      </div>
    </div>
  </section>
);
