import { FC, useState } from 'react';
import { AxiosError } from 'axios';
import { Alert, Button } from 'components';
import { Input } from 'components/forms';
import { useInvoice } from 'utils/stripe';

export const Discount: FC = () => {
  const { loading: invLoading, promotion } = useInvoice();

  const [error, setError] = useState<void | string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>('');

  const handleClick = async () => {
    if (loading || !coupon) return;
    setLoading(true);
    try {
      const res = await promotion(coupon);
      if (res instanceof Error) throw res;
      setCoupon('');
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err?.message ?? err?.response?.data?.message ?? '');
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="space-y-2">
      <>
        <div className="flex flex-row space-x-2">
          <div className="grow">
            <Input
              required
              value={coupon}
              onChange={(e) => {
                error && setError();
                setCoupon(e.target.value);
              }}
              className="mb-0"
              label="Discount code"
              placeholder="Enter code"
              name="discount"
            />
          </div>
          <Button
            color="black"
            className="self-end"
            onClick={handleClick}
            disabled={loading ?? invLoading}
            loading={loading ?? invLoading}
          >
            APPLY
          </Button>
        </div>
        {error && <Alert type="error" title={error} />}
      </>
    </div>
  );
};
