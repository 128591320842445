import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const Facebook: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  fill = 'primary',
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 25"
    xmlns="http://wwww3.org/2000/svg"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.067C0 18.033 4.333 22.994 10 24V15.333H7V12H10V9.333C10 6.333 11.933 4.667 14.667 4.667C15.533 4.667 16.467 4.8 17.333 4.933V8H15.8C14.333 8 14 8.733 14 9.667V12H17.2L16.667 15.333H14V24C19.667 22.994 24 18.034 24 12.067C24 5.43 18.6 0 12 0C5.4 0 0 5.43 0 12.067Z"
      fill={fill}
    />
  </svg>
);
