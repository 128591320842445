// Framework
import Image from 'next/image';

// Third Parties
import { twJoin } from 'tailwind-merge';

// Components
import { useEffect, useState } from 'react';
import { LearnMoreColumn } from 'components/selfService';

// Utils
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';
import { Section } from '../Section';

// Preps

const ImagesRenderer = ({ src, active }: any) => (
  <Image
    alt=""
    src={src}
    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 35vw, 15vw"
    className={twJoin(
      'rounded-md object-cover object-top transform transition-all duration-500 ease-in-out',
      active ? 'grayscale-0 scale-105' : 'grayscale'
    )}
    fill
  />
);

const IMAGE_PATH = '/assets/images/portal/team';

// Content
const TEAM_DATA = [
  `${IMAGE_PATH}/colleen.jpg`,
  `${IMAGE_PATH}/cam.jpg`,
  `${IMAGE_PATH}/riley.jpg`,
  `${IMAGE_PATH}/liam.jpg`,
  `${IMAGE_PATH}/kim.jpg`,
  `${IMAGE_PATH}/ollie.jpg`,
  `${IMAGE_PATH}/regan.jpg`,
  `${IMAGE_PATH}/daniel.jpg`,
  `${IMAGE_PATH}/lauren.jpg`,
];

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function TeamSection(): JSX.Element {
  const [imageActive, setImageActive] = useState(TEAM_DATA.length);
  const [container, isIntersecting] = useIntersectionObserver({
    amount: 0.1,
    once: false,
  });
  const counter = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setImageActive((prev) => {
        if (prev === 1) {
          return TEAM_DATA.flat().length;
        }
        return prev - 1;
      });
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Section
      dark
      className="items-center pb-0 pt-24 md:flex md:items-center md:py-32"
    >
      <LearnMoreColumn
        title="About us"
        description="Our team that becomes your team."
        variant="secondary"
        // buttonText="Learn More"
        // buttonHref="/about"
        isIntersecting={isIntersecting}
        titleMaxWidth="max-w-[507px]"
      >
        Our team becomes your team. As a New Zealand owned and operated
        specialist trades business, we're small enough to care but big enough to
        get the job sorted. With 50+ years of recruitment and advertising
        experience, we're on the recruitment tools for you everyday.
      </LearnMoreColumn>
      {/* <div className="absolute -left-32 top-1/2 z-10 flex -translate-y-1/2 items-start justify-center">
          <TeamBackground className="size-full" />
        </div> */}
      <div
        ref={container}
        className="relative z-0 flex w-full justify-center pt-44 md:w-1/2 md:justify-start md:pt-0"
      >
        <div className="grid w-full max-w-[650px] grid-cols-3 gap-4 pb-[104px] md:grid-cols-2 lg:grid-cols-3">
          {TEAM_DATA.map((image, index) => {
            return (
              <div
                key={index}
                className={twJoin(
                  'relative z-0 col-span-1 aspect-[3/4] h-auto',
                  [0, 3, 6, 9].includes(index) && 'top-[104px] lg:top-[104px]',
                  [1, 4, 7].includes(index) && 'top-0 lg:top-0',
                  [2, 5, 8].includes(index) ? 'top-[64px] lg:top-[64px]' : '',
                  [0, 2, 4, 6, 8].includes(index) && 'md:top-[104px]',
                  [3, 5, 7, 9].includes(index) && 'md:top-[0px]'
                )}
              >
                <ImagesRenderer src={image} active={counter === imageActive} />
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}
