import React from 'react';
import { ControllerFieldState } from 'react-hook-form';

export type ErrorsProps = {
  error?: ControllerFieldState['error'];
  level?: 'warning' | 'error';
};

export const Errors: React.FC<ErrorsProps> = ({ error, level = 'error' }) => {
  if (!error) return null;
  return (
    <span
      className={`
        relative text-xs
          ${
            level === 'error'
              ? 'text-red-500'
              : level === 'warning'
                ? 'text-gray-500'
                : ''
          }
        `}
    >
      {error?.message}
    </span>
  );
};
