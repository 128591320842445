import { ReactElement, VFC } from 'react';
import Link, { LinkProps } from 'next/link';
import { SkeletonCard } from 'components/InfoCard';

export type ListCardProps = {
  id: string;
  title: string | JSX.Element;
  href?: LinkProps['href'];
  menuElement?: ReactElement;
  content?: [string, string][];
  status?: ReactElement;
  loading?: boolean;
  disabled?: boolean;
};

export const ListCard: VFC<ListCardProps> = ({
  href,
  title,
  menuElement,
  content,
  status,
  loading,
}) => {
  if (loading) return <SkeletonCard />;

  return (
    <div className="bg-white">
      <div className="default-padding space-y-2 px-6 py-4 shadow">
        <div className="flex items-center justify-between space-x-4">
          {href ? (
            <Link href={href}>
              <h3 className="text-lg font-medium">{title}</h3>
            </Link>
          ) : (
            <h3 className="text-lg font-medium">{title}</h3>
          )}
          <div>{menuElement}</div>
        </div>
        <div className="flex flex-col space-y-1">
          {content?.map(([key, value]) => (
            <span key={key} className="inline-flex">
              {key && <span className="mr-2 text-grey">{key}:</span>}
              {value}
            </span>
          ))}
        </div>
        <div>{status}</div>
      </div>
    </div>
  );
};
