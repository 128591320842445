import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { ReactChild, VFC } from 'react';

type Value =
  | boolean
  | string
  | readonly string[]
  | number
  | Date
  | null
  | Record<string, unknown>;
// type Value = ComponentProps<typeof RadioGroup.Option>['value'];

export type UpdatedBooleanOption<V extends Value = Value> = {
  value?: V;
  label: ReactChild;
  className?: string;
};

export const UpdatedBooleanOption: VFC<UpdatedBooleanOption> = ({
  value,
  label,
  className,
}) => (
  <RadioGroup.Option<'div', typeof value> {...{ value, className }}>
    {({ active, checked = false }) => (
      <div
        className={'relative inline-flex h-12 w-full items-center rounded p-3'}
      >
        <input
          type="radio"
          className={twMerge(
            'mr-2 rounded-full border border-charcoal-50 text-base text-orange-500 shadow-sm placeholder:text-charcoal-200 focus:ring-1 focus:ring-orange-500',
            checked ? 'focus:ring-orange-500' : active ? 'focus:ring-black' : ''
          )}
          checked={checked}
          onChange={(e) => null}
        />
        <label
          className={twMerge(
            'w-full text-sm tracking-wider',
            checked ? 'text-orange-500' : 'text-charcoal-200'
          )}
        >
          {label}
        </label>
      </div>
    )}
  </RadioGroup.Option>
);
