/* eslint-disable @next/next/no-img-element */
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import reactStringReplace from 'react-string-replace';

interface TradeJobsTemplateProps {
  theme: string;
  background: string;
  logo: string;
  employer: string;
  job: string;
  location: string;
  header: 'full' | 'short';
  headerColor: 'white' | 'black';
  content: string;
  dimensions: any;
}

const LogoHeader: FC<{
  header: 'full' | 'short';
  headerColor: 'white' | 'black';
  logo: string;
  isSmall: boolean;
}> = ({ header, headerColor, logo, isSmall }) => {
  return (
    <div
      className={twMerge(
        'absolute flex items-center justify-end px-6 py-3 shadow-2xl',
        header === 'full' && 'w-full',
        headerColor === 'white' ? 'bg-white' : 'bg-black',
        'right-0 top-8'
      )}
    >
      <img className={isSmall ? 'h-10' : 'h-14'} src={logo} />
    </div>
  );
};

const Content: FC<{
  content: string;
  themeObj: any;
  isSmall: boolean;
}> = ({ content, themeObj, isSmall }) => {
  return (
    <div
      style={{
        backgroundColor: themeObj?.backgroundColor || 'white',
        color: themeObj?.text || 'black',
      }}
      className={twMerge(
        'absolute bottom-0 inset-x-0 h-60 pt-8',
        isSmall ? 'text-2xl' : 'text-3xl'
      )}
    >
      <div className="absolute inset-x-[5px] bottom-60 h-[30px]">
        <svg
          width="102%"
          height="100%"
          viewBox="0 0 1006 134"
          preserveAspectRatio="none"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '-1%' }}
        >
          <path
            style={{ fill: themeObj.backgroundColor }}
            d="M0.5 0.518799V134H1005.49V0.518799C583.585 238.044 159.703 99.4877 0.5 0.518799Z"
          />
        </svg>
      </div>
      <p
        className={twMerge('whitespace-pre-wrap', isSmall ? 'px-10' : 'px-14')}
      >
        {reactStringReplace(content, /\*(.+)\*/, (match, i) => (
          <span key={i} style={{ color: themeObj.highlight }}>
            {match}
          </span>
        ))}
      </p>
    </div>
  );
};

const SocialTemplate: FC<TradeJobsTemplateProps> = ({
  // theme,
  background,
  logo,
  employer,
  job,
  location,
  header,
  dimensions = { width: 550, height: 550 },
  content: customContent,
  headerColor = 'white',
}) => {
  const colorChoices: any = {
    '#181717': {
      text: 'white',
      highlight: '#da6a20',
      backgroundColor: '#181717',
    },
    '#343535': {
      text: 'white',
      highlight: '#da6a20',
      backgroundColor: '#343535',
    },
    '#3E4A38': {
      text: 'white',
      highlight: '#da6a20',
      backgroundColor: '#3E4A38',
    },
    '#DA6A20': {
      text: 'white',
      highlight: '#181717',
      backgroundColor: '#da6a20',
    },
    '#EFEFEF': {
      text: '#343535',
      highlight: '#da6a20',
      backgroundColor: '#EFEFEF',
    },
  };
  function getRandomColorChoice() {
    const colorKeys = Object.keys(colorChoices);
    const randomIndex = Math.floor(Math.random() * colorKeys.length);
    const randomColorKey = colorKeys[randomIndex];

    return colorChoices[randomColorKey];
  }
  const themeObj: any = {
    text: '#343535',
    highlight: '#da6a20',
    backgroundColor: '#EFEFEF',
  };

  const randomColorChoice = getRandomColorChoice();
  const content =
    customContent || `${employer} are looking for *${job}* across ${location}`;
  const isSmall = dimensions.width < 550;

  return (
    <div style={dimensions} className="relative overflow-hidden">
      <img
        className="size-full object-cover"
        src={background || '/assets/placeholder.png'}
      />
      <LogoHeader
        header={header}
        headerColor={headerColor}
        logo={logo}
        isSmall={isSmall}
      />
      <Content content={content} themeObj={themeObj} isSmall={isSmall} />
      <div
        className={twMerge(
          'flex mt-8 absolute bottom-8 inset-x-0',
          isSmall ? 'pl-10' : 'pl-14'
        )}
      >
        <span className="text-2xl" style={{ color: themeObj.text }}>
          tradejobs.nz
        </span>
        <div className="absolute -right-56 flex h-[2.4rem] w-full flex-1 items-center justify-end pl-3">
          <div
            className={twMerge('h-[2px] w-full')}
            style={{ background: themeObj.text }}
          />
        </div>
      </div>
    </div>
  );
};

export { SocialTemplate };
