import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, VFC } from 'react';
import { List } from 'components';
import { Empty } from 'components/Empty';
import { ListCard, ListCardProps } from 'components/List';
import { TableJobsProps } from 'components/pages/PortalEmployer/Tables';
import { GetJobsForThisUserQuery } from 'database/types';
import { JobActionsDropdown } from '../JobActionsDropdown';

type Job = GetJobsForThisUserQuery['job'][number];

export type ListJobsProps = TableJobsProps;

export const ListJobs: VFC<ListJobsProps> = ({
  empty = <Empty />,
  jobs,
  loading,
  setSelectedJobId,
}) => {
  return (
    <List<Job>
      {...{ empty, loading }}
      data={jobs}
      render={(job) => (
        <div className="-mx-6 pb-4 sm:-mx-12" key={job.id}>
          {setSelectedJobId && (
            <ListCard {...makeJobListCard({ ...job, setSelectedJobId })} />
          )}
        </div>
      )}
    />
  );
};

const makeJobListCard = ({
  title,
  id,
  employer_id,
  list_date,
  expiry_date,
  listing_type,
  status: { label, value },
  location: { label: location },
  applications_aggregate: { aggregate: candidates },
  job_views,
  setSelectedJobId,
}: Job & {
  setSelectedJobId: Dispatch<SetStateAction<string | null>>;
}): ListCardProps => ({
  id,
  title,
  href: {
    pathname: '/portal/[employerId]/job/[jobId]',
    query: {
      employerId: employer_id,
      jobId: id,
    },
  },
  content: [
    ['Location', location ?? ''],
    ['Date Listed', dayjs(list_date).format('DD/MM/YYYY')],
    ['Date Ends', dayjs(expiry_date).format('DD/MM/YYYY')],
    ['Views', `${job_views?.views ?? 0}`],
    ['Candidates', `${candidates?.count ?? 0}`],
  ],
  disabled: ['expired'].includes(value),
  // status: (
  //   <div className="flex flex-1 items-center justify-between gap-4">
  //     <PillManager>{label}</PillManager>
  //     <button
  //       className={twMerge(
  //         'h-8 rounded-lg bg-orange-500 px-4 sm:px-8 lg:h-10',
  //         candidates?.count === 0 && 'cursor-not-allowed bg-orange-500/50'
  //       )}
  //       key={id}
  //       type="button"
  //       disabled={candidates?.count === 0}
  //       onClick={() => {
  //         setSelectedJobId && setSelectedJobId(id);
  //       }}
  //     >
  //       <span className="font-satoshi text-sm font-medium text-white">
  //         View Candidates
  //       </span>
  //     </button>
  //   </div>
  // ),
  menuElement: (
    <JobActionsDropdown
      jobId={id}
      employerId={employer_id}
      status={value}
      listing_type={listing_type}
    />
  ),
});
