import { ParsedUrlQuery } from 'querystring';
import fastDeepEqual from 'fast-deep-equal';
import { useRouter } from 'next/router';
import {
  Enum_Pay_Type_Enum,
  Enum_Work_Type_Enum,
  Order_By,
} from 'database/types';
import { MAX_PATH_LENGTH } from 'utils/jobsPath';

// all possible params, just add new ones here if needed
export const paramKeys = [
  'jobType',
  'company',
  'payType',
  'sortByDate',
  'page',
  'jobSlug',
  'from',
  'to',
] as const;

export type JobsQueryParams = { [key in (typeof paramKeys)[number]]?: string };
export type JobsQuery = JobsQueryParams & { jobs?: string[] };

/**
 * Reads a given query param, to be used with router query objects
 * @param param the param name
 * @returns either the param value or the first value in case it's an array
 */
const parseQueryParam = (param?: string | string[]) =>
  typeof param === 'string' ? param : param?.[0];

/**
 * Reads all valid query params
 */
export const getQueryParams = (query: ParsedUrlQuery): JobsQueryParams => {
  const params = paramKeys.reduce(
    (obj, key) =>
      query[key] ? { ...obj, [key]: parseQueryParam(query[key]) } : obj,
    {} as JobsQueryParams
  );

  return params;
};

export const useJobsQueryParams = (): JobsQueryParams => {
  const router = useRouter();
  const { query } = router;
  return getQueryParams(query);
};

/**
 * Removes unnecessary query params to generate a clean url.
 * Keeps only the valid params from above, plus `jobs` which is necessary for path
 * Useful for links, router.push, etc
 *
 * @param query the query to be cleaned up
 * @returns a query object with only the relevant fields
 */
export const cleanupQuery = (dirtyQuery: JobsQuery): JobsQuery => {
  let query: JobsQuery = {};
  // remove defaults
  Object.keys(dirtyQuery).forEach((k) => {
    const v = dirtyQuery[k as keyof JobsQuery];
    query = {
      ...query,
      [k]: v,
    };
  });
  // always cleanup page
  // const params = paramKeys.filter((param) => param !== 'page');

  const { company, from, to, jobSlug } = query;

  const jobType = isValidEnum(query['jobType'] as string, Enum_Work_Type_Enum)
    ? query['jobType']
    : undefined;

  const payType = isValidEnum(query['payType'], Enum_Pay_Type_Enum)
    ? query['payType']
    : undefined;

  const sortByDate = isValidEnum(query['sortByDate'], Order_By)
    ? query['sortByDate']
    : undefined;

  const cleanQuery: JobsQuery = {
    jobs: query.jobs?.slice(0, MAX_PATH_LENGTH) ?? [],
    // discards undefined fields
    ...(jobType ? { jobType } : {}),
    ...(company ? { company } : {}),
    ...(from ? { from } : {}),
    ...(to ? { to } : {}),
    ...(payType ? { payType } : {}),
    ...(sortByDate ? { sortByDate } : {}),
    ...(jobSlug ? { jobSlug } : {}),
  };

  return cleanQuery;
};

export const isQueryClean = (query: JobsQuery): boolean =>
  fastDeepEqual(query, cleanupQuery(query));

export const isValidEnum = (
  value: string | undefined,
  enumObj: Record<string, string>
): boolean => !!value && Object.values(enumObj).includes(value);
