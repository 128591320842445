import { twMerge } from 'tailwind-merge';
import { Link, LinkProps } from 'components/Link';

export type NavLinkProps = LinkProps;

export const NavLink: React.FC<NavLinkProps> = ({
  className,
  hover = true,
  ...props
}) => (
  <Link
    className={twMerge(
      'whitespace-nowrap text-base text-white lg:text-lg',
      hover ? 'hover:text-white-56' : '',
      className
    )}
    {...props}
  />
);
