import { SVGAttributes } from 'react';

type ToolsIconProps = SVGAttributes<SVGSVGElement>;

const ToolsIcon = (props: ToolsIconProps) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.00008 4.99935L8.75008 8.74935M5.00008 4.99935H2.50008L1.66675 2.49935L2.50008 1.66602L5.00008 2.49935V4.99935ZM16.0492 2.28353L13.8596 4.47321C13.5295 4.80322 13.3645 4.96823 13.3027 5.1585C13.2483 5.32587 13.2483 5.50616 13.3027 5.67353C13.3645 5.8638 13.5295 6.02881 13.8596 6.35882L14.0573 6.55654C14.3873 6.88655 14.5523 7.05156 14.7426 7.11338C14.9099 7.16777 15.0902 7.16777 15.2576 7.11338C15.4479 7.05156 15.6129 6.88655 15.9429 6.55654L17.9912 4.50828C18.2118 5.04509 18.3334 5.63301 18.3334 6.24935C18.3334 8.78065 16.2814 10.8327 13.7501 10.8327C13.4449 10.8327 13.1467 10.8029 12.8582 10.746C12.4531 10.6661 12.2505 10.6261 12.1277 10.6384C11.9972 10.6514 11.9328 10.6709 11.8172 10.7328C11.7084 10.7911 11.5992 10.9002 11.3809 11.1185L5.41675 17.0827C4.72639 17.773 3.6071 17.773 2.91675 17.0827C2.22639 16.3923 2.22639 15.273 2.91675 14.5827L8.88094 8.61849C9.09923 8.4002 9.20837 8.29106 9.2666 8.18226C9.3285 8.06658 9.34807 8.00224 9.36108 7.87169C9.37332 7.74889 9.33337 7.54633 9.25347 7.14122C9.19657 6.85273 9.16675 6.55452 9.16675 6.24935C9.16675 3.71804 11.2188 1.66602 13.7501 1.66602C14.588 1.66602 15.3734 1.89087 16.0492 2.28353ZM10.0001 12.4993L14.5834 17.0826C15.2738 17.7729 16.3931 17.7729 17.0834 17.0826C17.7738 16.3922 17.7738 15.2729 17.0834 14.5826L13.3128 10.8121C13.0459 10.7868 12.7857 10.7387 12.5341 10.6696C12.2098 10.5806 11.8542 10.6452 11.6165 10.883L10.0001 12.4993Z"
      stroke="#F17732"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ToolsIcon;
