import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, VFC } from 'react';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import { Link, LinkButton, LogoEmployer } from 'components';
import { NavbarItemProps } from 'components/Navbar/Navbar';

const links = (isAuth = false, employerId: string) => [
  ...(isAuth
    ? [
        {
          name: 'Your Jobs',
          href: {
            pathname: `/portal${employerId ? '/[employerId]' : ''}/jobs`,
            query: { employerId },
          },
        },
        { name: 'Profile', href: '/portal/profile' },
      ]
    : [
        { name: 'Learn more', href: '/portal' },
        { name: 'Post a job', href: '/portal/register' },
      ]),
];
export const MobileNavEmployer: VFC<NavbarItemProps> = ({
  authenticated,
  className,
}) => {
  const router = useRouter();
  const { query, pathname } = router;
  const { employerId } = query;
  return (
    <Popover
      as="header"
      className={twMerge('relative w-full bg-black', className)}
    >
      {({ open }) => (
        <>
          <div className="mx-auto flex h-16 items-center justify-between space-x-10 px-4 py-3 sm:px-8">
            {/* Logo */}
            <Link className="flex h-10 justify-start" href="/portal">
              <span className="sr-only">Home Page</span>
              <LogoEmployer />
            </Link>
            <div className="flex items-center justify-end space-x-4">
              {!authenticated && (
                <LinkButton
                  className="py-2"
                  buttonProps={{ size: 'small' }}
                  href="/portal/register"
                >
                  Create Account
                </LinkButton>
              )}
              {/* Burger */}
              <div className="-my-2 -mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded p-2 text-white hover:text-grey focus:outline-none focus:ring focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="size-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          {/* Mobile Menu */}
          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 -translate-y-8"
            enterTo="opacity-100 translate-y-0"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-8"
          >
            <Popover.Panel
              focus
              static
              className="absolute inset-x-0 top-0 z-10 origin-top-right transition"
            >
              <div className="rounded bg-black shadow-lg ring-1 ring-black/5">
                <div className="mx-auto px-4 py-3 sm:px-8">
                  <div className="flex items-center justify-between">
                    {/* Logo */}
                    <Link href="/portal" className="flex h-10 justify-start">
                      <span className="sr-only">Home Page</span>
                      <LogoEmployer />
                    </Link>
                    <Popover.Button className="inline-flex items-center justify-center bg-black p-2 text-white hover:text-white-56 focus:outline-none focus:ring focus:ring-inset focus:ring-white">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="size-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <div>
                    <nav className=" mb-6 mt-12 space-y-4 text-white">
                      {links(authenticated, employerId as string).map(
                        (item) => (
                          <Link
                            key={item.name}
                            href={item.href}
                            showActive={true}
                            className="-m-3 flex items-start p-3 pl-6 hover:text-white-56"
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </nav>
                    {authenticated ? (
                      <LinkButton
                        href="/portal/auth/signout"
                        className="w-full py-2"
                        buttonProps={{ size: 'small' }}
                      >
                        Sign out
                      </LinkButton>
                    ) : (
                      <div className="space-y-2">
                        <LinkButton
                          href={`${process.env.NEXT_PUBLIC_SITE_URL}`}
                          buttonProps={{
                            size: 'small',
                            color: 'outline',
                          }}
                          className="w-full py-3"
                        >
                          Main site
                        </LinkButton>
                        <LinkButton
                          className="w-full py-2"
                          href={{ pathname, query, hash: 'login' }}
                          buttonProps={{ size: 'small' }}
                        >
                          Login
                        </LinkButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
