import Link from 'next/link';

import { useJobViewTrack } from 'utils/hooks/useJobViewTrack';

interface ExpressionOfInterestProps {
  id: string;
  title: string;
  metadata?: {
    image: string;
  };
  slug: string;
  about: string | null | undefined;
  applying?: boolean;
}

const ExpressionOfInterest: React.FC<ExpressionOfInterestProps> = ({
  id,
  title,
  metadata,
  slug,
  about,
  applying = false,
}) => {
  useJobViewTrack({ id, listing_type: 'expression_of_interest' });
  return (
    <div className="relative z-0 mb-8 flex flex-col overflow-hidden rounded-lg border border-orange-200 bg-orange-50 lg:flex-row">
      <div className="absolute inset-0 h-[3px] bg-orange-500" />
      {!applying && (
        <div className="w-fullshrink lg:w-auto lg:shrink-0">
          <img
            src={metadata?.image}
            alt=""
            className="max-h-[288px] w-full object-cover object-top lg:w-auto"
          />
        </div>
      )}
      <div className="max-w-[750px] bg-orange-50 p-8">
        <p className="text-[36px] font-bold -tracking-two-percent text-orange-500">
          {title}
        </p>
        <p className="mb-4 text-[18px] font-medium -tracking-two-percent text-charcoal-500">
          {about?.replace(/<\/?[^>]+(>|$)/g, '')}
        </p>
        {!applying && (
          <Link
            href={`/job/${slug}/apply`}
            className="flex h-10 w-max items-center rounded-lg bg-orange-500 px-8"
          >
            <span className="font-medium text-white">Apply Now</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ExpressionOfInterest;
