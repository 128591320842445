import { captureException } from '@sentry/nextjs';
import { twMerge } from 'tailwind-merge';
import { FC } from 'react';
import { Dropdown, useMessages } from 'components';
import { useAuthContext } from 'components/AuthContext';
import {
  Enum_Application_Status_Enum,
  useGetApplicationsQuery,
  useUpdateApplicationStatusMutation,
} from 'database/types';

const { Item } = Dropdown;

const { Applied, Draft, Rejected, Shortlisted, Hired, Withdrawn } =
  Enum_Application_Status_Enum;

type Props = {
  dashboard?: boolean;
  job_id: string;
  slug: string;
  className?: string;
};

export const AuthenticatedActions: FC<Props> = ({
  job_id,
  slug,
  className,
}) => {
  const { authenticated } = useAuthContext();
  const { alert } = useMessages();
  const [updateStatus] = useUpdateApplicationStatusMutation();

  const { data, loading } = useGetApplicationsQuery();
  // No need to poll.
  // This query's data is updated by the parent query, which is already set to poll.
  // See useGetEmployeeJobsQuery in pages/employee/index.tsx
  const { status, id = '' } =
    data?.application.find((app) => app.job_id === job_id) ?? {};

  if (!authenticated || loading) return null;

  return (
    <div className="-mr-4 ml-2 shrink sm:ml-4">
      <div className={className}>
        <Dropdown>
          <Item href={`/job/${slug}`} title="View listing" />
          {status === Draft && (
            <Item href={`/job/${slug}/apply/1`} title="Complete application" />
          )}

          {/* Actions */}
          {status && status !== Withdrawn && (
            <Item
              title="Withdraw application"
              onClick={async () => {
                try {
                  await updateStatus({
                    variables: {
                      id,
                      updated_at: new Date().toISOString(),
                      status: Enum_Application_Status_Enum.Withdrawn,
                    },
                  });
                  alert({
                    key: 'UpdateApplication',
                    type: 'success',
                    title: 'Application withdrawn',
                    dismissable: true,
                    duration: 3000,
                  });
                } catch (err) {
                  if (err instanceof Error) {
                    alert({
                      key: 'UpdateApplication',
                      type: 'error',
                      title: 'Error withdrawing application',
                      message: err?.message,
                      dismissable: true,
                    });
                  }
                  captureException(err);
                }
              }}
            />
          )}
        </Dropdown>

        {/* Status Pill */}
        {status && (
          <span
            className={twMerge(
              'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium',
              (() => {
                switch (status) {
                  case Hired:
                    return 'text-green-800 bg-green-100';
                  case Applied:
                  case Shortlisted:
                    return 'text-secondary-800 bg-secondary-100';
                  case Rejected:
                    return 'text-red-800 bg-red-100';
                  case Withdrawn:
                  case Draft:
                    return 'text-gray-800 bg-gray-100';
                  default:
                    return '';
                }
              })()
            )}
          >
            {(() => {
              switch (status) {
                case Hired:
                  return 'Hired';
                case Shortlisted:
                case Applied:
                  return 'Applied';
                case Rejected:
                  return 'Unsuccessful';
                case Draft:
                  return 'Draft';
                case Withdrawn:
                  return 'Withdrawn';
                default:
                  return null;
              }
            })()}
          </span>
        )}
      </div>
    </div>
  );
};
