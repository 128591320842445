import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren<{
  className?: string;
}>;

export function Device({ children, className }: Props): JSX.Element {
  return (
    <div className={twMerge(className, 'relative')}>
      <svg
        className="relative"
        width="206"
        height="409"
        viewBox="0 0 206 409"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_114_368)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.5584 0H160.489C187.42 0 200.069 14.9456 200.069 39.5905V127.297C200.639 127.498 201.047 128.042 201.047 128.681V174.215C201.047 174.854 200.639 175.398 200.069 175.599V365.277C200.069 387.677 187.373 400.827 164.977 400.827H40.0699C17.6748 400.827 4.9779 388.126 4.9779 365.277V175.225C4.40818 175.023 4 174.48 4 173.841V146.772C4 146.133 4.40818 145.59 4.9779 145.388V136.417C4.40818 136.216 4 135.672 4 135.033V107.516C4 106.877 4.40818 106.333 4.9779 106.132V92.4305C4.40818 92.2291 4 91.6856 4 91.0467V78.8186C4 78.1797 4.40818 77.6362 4.9779 77.4347V39.5905C4.9779 14.9469 17.6271 0 44.5584 0Z"
            fill="black"
          />
        </g>
        <mask
          id="mask0_114_368"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="8"
          width="177"
          height="383"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M190.723 361.777V37.7039C190.723 20.1985 183.766 8.52832 161.996 8.52832H43.05C21.2805 8.52832 14.3232 20.1985 14.3232 37.7039V361.777C14.3232 379.282 21.2805 390.953 43.05 390.953H161.996C183.766 390.953 190.723 379.282 190.723 361.777ZM82.7736 13.4657C78.1875 13.4657 74.4698 17.1835 74.4698 21.7695C74.4698 26.3556 78.1875 30.0734 82.7736 30.0734H122.273C126.859 30.0734 130.577 26.3556 130.577 21.7695C130.577 17.1835 126.859 13.4657 122.273 13.4657H82.7736Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_114_368)">
          <rect
            width="176.4"
            height="382.424"
            transform="translate(14.3232 8.52832)"
            fill="#EFEFEF"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_114_368"
            x="0"
            y="0"
            width="205.047"
            height="408.827"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_114_368"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_114_368"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <div className="absolute inset-0 top-[34px] mx-auto h-auto w-[177px]">
        {children}
      </div>
    </div>
  );
}
