import { FC, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { useController } from 'react-hook-form';
import { default as ReactCodeInput } from 'react-verification-code-input';

import { Errors } from '..';

export type CodeInputProps = InputHTMLAttributes<Element> & {
  code: string;
  control: any;
  className?: string;
  defaultValue?: string;
  label?: string;
  name: string;
  value?: string;
  loading?: boolean;
};

export const CodeInput: FC<CodeInputProps> = ({
  code,
  control,
  className,
  defaultValue = '',
  required,
  label,
  loading,
  ...props
}) => {
  const {
    fieldState: { error },
    field: { onChange },
  } = useController({
    control,
    name: props?.name,
    defaultValue,
  });

  return (
    <div className={twMerge('relative', error ? 'mb-2' : 'mb-8', className)}>
      <label
        htmlFor={props?.name}
        className="block text-sm uppercase tracking-wider text-grey"
      >
        {label}
        <span className="normal-case">{!required && ' (Optional)'}</span>
      </label>
      <div className="mt-1">
        <div className="grid grid-cols-3 space-x-4">
          <ReactCodeInput
            values={code ? code.split('') : []}
            loading={loading}
            onChange={(value: string) => onChange(value)}
          />
        </div>
      </div>
      <Errors error={error} />
    </div>
  );
};
