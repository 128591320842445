// Types
type HamburguerButtonProps = {
  active: boolean;
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

const HamburguerButton = ({ active }: HamburguerButtonProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="18"
    viewBox="0 0 32 18"
    fill="none"
  >
    <line
      x1="1"
      y1="1"
      x2="31"
      y2="1"
      stroke="#1C1B19"
      strokeWidth="2"
      strokeLinecap="round"
      className="transition-transform"
      style={
        !active
          ? { transform: 'translate(25%, -25%) rotateZ(45deg)' }
          : { transform: 'translate(0, 0) rotateZ(0deg)' }
      }
    />
    <line
      x1="1"
      y1="9"
      x2="31"
      y2="9"
      stroke="#1C1B19"
      strokeWidth="2"
      strokeLinecap="round"
      className={`transition-opacity ${!active ? 'opacity-0' : 'opacity-100'}`}
    />
    <line
      x1="1"
      y1="17"
      x2="31"
      y2="17"
      stroke="#1C1B19"
      strokeWidth="2"
      strokeLinecap="round"
      className="origin-center transition-transform"
      style={
        !active
          ? { transform: 'translate(-15%, -15%) rotateZ(-45deg)' }
          : { transform: 'translate(0, 0) rotateZ(0deg)' }
      }
    />
  </svg>
);

export default HamburguerButton;
