import type { ReactNode } from 'react';

type UpdatedLabelProps = {
  label?: ReactNode;
  subtitle?: string;
  name?: string;
  required?: boolean;
  customLabel?: string;
};

export const LABEL_CLASSES =
  'mb-1 block text-base text-gray-dark font-normal tracking-wider';

export function UpdatedInputLabel({
  label,
  subtitle,
  name,
  required,
  customLabel,
}: UpdatedLabelProps): JSX.Element | null {
  if (!label) return null;
  return (
    <label htmlFor={name} className={customLabel ? customLabel : LABEL_CLASSES}>
      {label}
      <span className="font-medium normal-case text-charcoal-200">
        {!required && ' (optional)'}
      </span>
      {subtitle && (
        <>
          <br />
          <span className="text-sm font-medium text-charcoal-200">
            {subtitle}
          </span>
        </>
      )}
    </label>
  );
}
