import * as yup from 'yup';
import { EmployerQuestionsProps } from '.';

const validBool = yup.boolean().required('please select one');

const makeValidation = (questions: EmployerQuestionsProps['questions']): any =>
  questions &&
  yup.object({
    answers: yup
      .object()
      .shape(
        questions.reduce((res, { id }) => ({ [id]: validBool, ...res }), {})
      ),
  });

export default makeValidation;
