import { NextRouter, useRouter } from 'next/router';
import {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  FC,
  PropsWithChildren,
} from 'react';
import { Button, ButtonProps } from './Button';

export type LinkButtonProps = PropsWithChildren<
  {
    href: Parameters<NextRouter['push']>[0];
    className?: string;
    buttonProps?: ButtonProps;
    role?: string;
    onClick?: (e: any) => void;
    disabled?: boolean;
    htmlProps?: Pick<
      DetailedHTMLProps<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >,
      'target' | 'onClick' | 'rel'
    >;
  } & Parameters<NextRouter['push']>[2]
>;

export const LinkButton: FC<LinkButtonProps> = ({
  className,
  href,
  children,
  buttonProps,
  htmlProps,
  scroll,
  ...props
}) => {
  const router = useRouter();

  return (
    <Button
      className={className}
      {...buttonProps}
      {...htmlProps}
      onClick={() =>
        router.push(href, undefined, {
          scroll: scroll ?? !!href,
          ...props,
        })
      }
    >
      {children}
    </Button>
  );
};
