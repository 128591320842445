import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { useAuthenticatedAxios } from 'utils/stripe';

type InvoiceHook = {
  loading: boolean;
  invoices: Stripe.Invoice[];
};

export const useInvoices = (): InvoiceHook => {
  const [invoices, setInvoices] = useState<Stripe.Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const { employerId, jobId } = router.query;

  const axios = useAuthenticatedAxios();
  useEffect(() => {
    if (!(jobId || employerId)) return;
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoices/${jobId}`
        );
        setInvoices(data);
      } catch (err) {
        console.error(err);
        captureException(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [employerId, jobId]);

  return { invoices, loading };
};
