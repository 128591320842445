import React, { useEffect, VFC } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import { Select, SelectOption } from 'components/forms';
import {
  TableJobsProps,
  Tabs,
  TabsProps,
  useEmployerContext,
} from 'components/pages/PortalEmployer';
import { Enum_Job_Status_Enum } from 'database/types';
import { isString } from 'types/utils';
import { usePersistentState } from 'utils/usePersistentState';
import { FilterDropdown } from './FilterDropdown';

export const SortTitles = ['Applicants', 'Views', 'Title', 'Date'];
const SortOptions = [
  'Applicants ASC',
  'Applicants DESC',
  'Views ASC',
  'Views DESC',
  'Title ASC',
  'Title DESC',
  'Date ASC',
  'Date DESC',
] as const;

type JobFilters = {
  employer?: string;
  location?: string;
  sortBy: (typeof SortOptions)[number];
  status: Enum_Job_Status_Enum;
};

export type FilterJobsProps = {
  jobs: NonNullable<TableJobsProps['jobs']>;
  onChange: (...args: any) => void;
  loading?: boolean;
};

export const FilterJobs: VFC<FilterJobsProps> = ({
  jobs,
  loading = false,
  onChange,
}) => {
  const { isParent, allEmployers } = useEmployerContext();
  const defaultState: JobFilters = {
    sortBy: 'Title ASC',
    status: 'active',
    location: '',
    employer: '',
  };

  // persist filter settings to local storage
  const [filters, setFilters] = usePersistentState(
    'portal_jobs_filters',
    defaultState
  );

  // update table data
  useEffect(() => {
    if (!jobs || jobs.length < 1) return;
    onChange(filterSort(jobs, filters));
  }, [...Object.values(filters), jobs, loading]);

  // Tabs
  const tabs: TabsProps['tabs'] = makeTabs(jobs);
  const handleTabs: TabsProps['onClick'] = (tab) =>
    setFilters((filters) => ({
      ...filters,
      status: tab as Enum_Job_Status_Enum,
    }));

  // Locations
  const locations = makeLocationOptions(jobs);
  const handleLocation = (location: string) =>
    setFilters((filters) => ({ ...filters, location }));
  const handleEmployer = (employer: string) =>
    setFilters((filters) => ({ ...filters, employer }));

  // Sorting
  const handleSorting = (a: (typeof SortTitles)[number]) => {
    const [, order] = filters.sortBy.split(' ');
    setFilters((filters) => ({
      ...filters,
      sortBy: `${a} ${order}` as (typeof SortOptions)[number],
    }));
  };

  const toggleOrder = () => {
    const [prop, order] = filters.sortBy.split(' ');
    setFilters((filters) => ({
      ...filters,
      sortBy: `${prop} ${
        order === 'ASC' ? 'DESC' : 'ASC'
      }` as (typeof SortOptions)[number],
    }));
  };

  return (
    <div className="flex flex-wrap">
      {/* Tabs */}
      <div className="block min-w-full overflow-x-auto">
        <Tabs tabs={tabs} onClick={handleTabs} value={filters.status} />
      </div>

      {/* Filter */}
      <div className="mb-4 mt-6 flex w-full flex-col items-end justify-between sm:flex-row sm:space-x-4">
        <div className="flex flex-row space-x-2">
          {/* <Select
            placeholder="Location"
            options={locations}
            onChange={handleLocation}
            value={filters.location}
            className="!mb-4 w-full sm:!mb-0 sm:w-80 sm:max-w-screen-sm"
          /> */}
          {isParent && (
            <Select
              placeholder="Employer"
              options={[
                { value: '', label: 'All Employers' },
                ...allEmployers.map((i) => ({
                  value: i.name,
                  label: i.name,
                })),
              ]}
              value={filters.employer}
              onChange={handleEmployer}
              className="!mb-4 w-full sm:!mb-0 sm:w-80 sm:max-w-screen-sm"
            />
          )}
        </div>
        {/* Sorting */}
        <div className="flex flex-row items-center">
          <FilterDropdown handleChange={handleSorting}>
            <span>
              Sort by{' '}
              <span className="font-medium">
                {filters.sortBy.split(' ')[0]}
              </span>
            </span>
          </FilterDropdown>
          <div
            onClick={toggleOrder}
            className="flex cursor-pointer items-center rounded-full p-2 text-gray-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-secondary-500"
          >
            {filters.sortBy.includes('ASC') ? (
              <ChevronUpIcon className="w-5" />
            ) : (
              <ChevronDownIcon className="w-5" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const filterSort = (
  jobs: FilterJobsProps['jobs'],
  { employer, location, status, sortBy }: JobFilters
) =>
  _.orderBy(
    jobs.filter(
      ({ status: { value }, location: { label }, employer: e }) =>
        (!status || value === status) &&
        (!location || label === location) &&
        (!employer || employer === e?.name)
    ),
    [
      (() => {
        if (sortBy.includes('Applicants'))
          return 'applications_aggregate.aggregate.count';
        else if (sortBy.includes('Date')) return 'list_date';
        else if (sortBy.includes('Views')) return 'job_views.views';
        else if (sortBy.includes('Title')) return 'title';
        else if (sortBy.includes('Employer')) return 'employer.name';
        return 'title';
      })(),
    ],
    [(() => (sortBy.includes('DESC') ? 'desc' : 'asc'))()]
  );

const filterByStatus = (
  jobs: FilterJobsProps['jobs'],
  status: Enum_Job_Status_Enum
) => jobs.filter(({ status: { value } }) => value === status);

const makeLocationOptions = (jobs: FilterJobsProps['jobs']): SelectOption[] => [
  { value: '', label: 'All of New Zealand' },
  ...jobs
    .map(({ location: { label } }) => label)
    .filter(isString) // get rid of nulls/undefs
    .sort()
    // dedupe
    .reduce((arr, s) => (arr.includes(s) ? arr : [...arr, s]), [] as string[])
    .map((label) => ({ label, value: label })),
];

const makeTabs = (jobs: FilterJobsProps['jobs']): TabsProps['tabs'] =>
  Object.entries(Enum_Job_Status_Enum).map(([label, value]) => ({
    label,
    name: value,
    count: filterByStatus(jobs, value).length,
  }));
