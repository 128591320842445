import * as yup from 'yup';
import { ChangePasswordData } from './ChangePassword';

const schema: yup.ObjectSchema<ChangePasswordData> = yup.object({
  password: yup
    .string()
    .required('password is required')
    .min(8, 'must be at least ${min} characters'),
  new_password: yup
    .string()
    .required('new password is required')
    .min(8, 'must be at least ${min} characters'),
  repeat_password: yup
    .string()
    .required('repeat password is required')
    .oneOf([yup.ref('new_password'), ''], 'passwords do not match'),
});

export default schema;
