import dayjs from 'dayjs';
import React, { VFC } from 'react';
import { useRouter } from 'next/router';
import { List } from 'components';
import { Empty } from 'components/Empty';
import { ListCard, ListCardProps } from 'components/List';
import {
  ApplicationRow,
  TableApplicationsProps,
} from 'components/pages/PortalEmployer/Tables';
import { PillManager } from 'components/pages/PortalEmployer/ApplicationsEmployer/PillManager';

type RouterQuery = {
  jobId: string;
  employerId: string;
};

type Application = ApplicationRow;

export type ListApplicationsProps = TableApplicationsProps;

export const ListApplications: VFC<ListApplicationsProps> = ({
  empty = <Empty />,
  applications,
  loading,
  setApplicationId,
}) => {
  return (
    <List<Application>
      {...{ empty, loading }}
      data={applications}
      render={(application) => (
        <div className="-mx-6 pb-4 sm:-mx-12" key={application.id}>
          <ListCard
            {...makeApplicationListCard({ ...application, setApplicationId })}
          />
        </div>
      )}
    />
  );
};

const makeApplicationListCard = ({
  setApplicationId,
  id,
  created_at,
  user: {
    first_name,
    last_name,
    user_educations: [latestEducation] = [],
    user_experiences: [latestExperience] = [],
  },
  application_status: { label, value },
}: Application & {
  setApplicationId?: (id: string | null) => void;
}): ListCardProps => {
  const { jobId, employerId } = useRouter().query as RouterQuery;
  const education = latestEducation
    ? `${latestEducation.title}, ${latestEducation.institution}`
    : 'N/A';
  const experience = latestExperience
    ? `${latestExperience.title}, ${latestExperience.company}`
    : 'N/A';

  return {
    id,
    href: {
      pathname: '/portal/[employerId]/job/[jobId]/application/[applicationId]',
      query: {
        applicationId: id,
        employerId,
        jobId,
      },
    },
    title: `${first_name} ${last_name}`,
    content: [
      ['Experience', experience],
      ['Education', education],
      ['Date Applied', dayjs(created_at).format('DD/MM/YYYY')],
    ],
    disabled: ['rejected'].includes(value),
    status: (
      <div className="flex flex-1 items-center justify-between gap-4">
        <PillManager>{label}</PillManager>
        <button
          className="h-8 rounded-lg bg-orange-500 px-4 sm:px-8 lg:h-10"
          key={id}
          type="button"
          onClick={() => {
            setApplicationId && setApplicationId(id);
          }}
        >
          <span className="font-satoshi text-sm font-medium text-white">
            View Candidate
          </span>
        </button>
      </div>
    ),
  };
};
