// Framework
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

// Components
import { ActionButton } from './ActionButton';

type Props = PropsWithChildren<{
  className?: string;
}>;

function ActionButtonGroup({ children, className = '' }: Props): JSX.Element {
  return (
    <span
      className={twMerge('isolate inline-flex rounded-md gap-x-0.5', className)}
    >
      {children}
    </span>
  );
}

ActionButtonGroup.Item = ActionButton;

export { ActionButtonGroup };
