const GETSTARTED_ITEMS = [
  {
    title: 'Create an account.',
    description: '',
  },
  {
    title: 'Post a job ad.',
    description: '',
  },
  {
    title: 'Hire the right candidate.',
    description: '',
  },
];

export default GETSTARTED_ITEMS;
