import { twMerge } from 'tailwind-merge';
import { Dispatch, SetStateAction } from 'react';
import { Button } from 'components/Button';
import type { Listing } from './Listings';

interface ListingItemProps extends Omit<Listing, 'info'> {
  selected: boolean;
  onClick: Dispatch<SetStateAction<string>> | null;
}

function ListingItem({
  id,
  title,
  subtitle,
  price,
  description,
  selected,
  onClick,
}: ListingItemProps) {
  return (
    <div
      onClick={() => (onClick ? onClick(id) : null)}
      className={twMerge(
        selected && 'ring-2 ring-primary',
        'relative flex cursor-pointer flex-col justify-between rounded-3xl p-6 shadow xl:p-10'
      )}
    >
      <div>
        <div className="flex items-center justify-between gap-x-4">
          <div>
            <h3 id={id} className="font-medium leading-8 text-primary">
              {title}
            </h3>
            {subtitle && (
              <p className="text-sm font-light italic">{subtitle}</p>
            )}
          </div>
        </div>
        <div className="mt-6 flex gap-2">
          <div className="flex">
            <p className="text-4xl font-medium tracking-tight text-gray-900">
              ${price}
            </p>
          </div>
          <div className="text-sm">
            <p className="font-medium">one-time payment</p>
            <p className="font-light text-gray-600">plus gst</p>
          </div>
        </div>
        <p className="mt-6 text-sm leading-6 text-gray-600">{description}</p>
      </div>
      {onClick && (
        <Button
          color={selected ? 'primary' : 'black'}
          size="small"
          onClick={(e) => e.preventDefault()}
          className="mt-8"
        >
          {selected ? 'Selected' : 'Select'}
        </Button>
      )}
    </div>
  );
}

export { ListingItem };
