import { VFC } from 'react';
import { twMerge } from 'tailwind-merge';

// components
import { CreditCardIcon } from '@heroicons/react/24/outline';
import {
  BriefcaseIcon,
  CogIcon,
  QueueListIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import { useEmployerContext } from 'components/pages/PortalEmployer/EmployerContext';
import { MobileLink } from 'components/pages/Portal/PortalLink';
import { Select } from 'components/forms';

export const MobileNav: VFC = () => {
  const {
    currentEmployer,
    hasMultipleEmployers,
    hasNoEmployers,
    setCurrentEmployer,
    allEmployers,
  } = useEmployerContext();

  const employerIdPath = currentEmployer ? `${currentEmployer}/` : '';
  const selectOptions = allEmployers
    .sort((i) =>
      ['recruiter', 'conglomerate'].includes(i.organisation_type) ? -1 : 1
    )
    .map((i) => ({ value: i.id, label: i.name }));

  return (
    <>
      {/* Company Select Mobile*/}
      {hasMultipleEmployers && (
        <Select
          value={currentEmployer}
          onChange={setCurrentEmployer}
          options={selectOptions}
          className={twMerge('mb-0 w-full lg:hidden')}
        />
      )}
      {/* Mobile Nav */}
      <div
        id="nav-mobile"
        className="flex w-auto flex-nowrap justify-between overflow-x-scroll shadow-sm lg:hidden"
      >
        <MobileLink
          disabled={hasNoEmployers}
          url={`/portal/${employerIdPath}jobs`}
          icon={BriefcaseIcon}
          matches={[
            /\/portal\/\[employerId\]\/job.*/,
            /\/portal\/\[employerId\]\/application.*/,
          ]}
        >
          Jobs
        </MobileLink>
        <MobileLink
          url={`/portal/${employerIdPath}applicants`}
          icon={QueueListIcon}
          matches={['/portal/[employerId]/applicants']}
        >
          Applicants
        </MobileLink>
        <MobileLink
          url={
            hasNoEmployers
              ? `/portal/profile`
              : `/portal/${employerIdPath}company`
          }
          icon={UserCircleIcon}
          matches={['/portal/[employerId]/company', '/portal/profile']}
        >
          Profile
        </MobileLink>
        <MobileLink
          disabled={hasNoEmployers}
          url={`/portal/${employerIdPath}billing`}
          icon={CreditCardIcon}
          matches={['/portal/[employerId]/billing']}
        >
          Billing
        </MobileLink>
        <MobileLink
          disabled={hasNoEmployers}
          url={`/portal/${employerIdPath}users`}
          icon={UsersIcon}
          matches={[/\/portal\/\[employerId\]\/user.*/]}
        >
          Users
        </MobileLink>
        <MobileLink url="/portal/settings" icon={CogIcon}>
          Settings
        </MobileLink>
      </div>
    </>
  );
};
