export const iconTopLeftConfig = {
  initial: {
    transform: 'translate(185px, 135px)',
  },
  animated: {
    transform: 'translate(0, 0)',
  },
};

export const iconBottomLeftConfig = {
  initial: {
    transform: 'translate(180px, -135px)',
  },
  animated: {
    transform: 'translate(0, 0)',
  },
};

export const iconBottomRightConfig = {
  initial: {
    transform: 'translate(-150px, -105px)',
  },
  animated: {
    transform: 'translate(0, 0)',
  },
};

export const iconTopRightConfig = {
  initial: {
    transform: 'translate(-105px, 172px)',
  },
  animated: {
    transform: 'translate(0, 0)',
  },
};

export const centralLogoConfig = {
  initial: {
    transform: 'rotate(0)',
  },
  animated: {
    transform: 'rotate(360deg)',
  },
};
