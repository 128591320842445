import { Menu } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { FC, ReactElement } from 'react';
import { LinkProps } from 'components';
import { Link } from 'components/Dropdown/Link';

export type ItemProps = {
  className?: string | null | false;
  href?: LinkProps['href'];
  onClick?: () => void;
  title: string | ReactElement;
  htmlProps?: LinkProps['htmlProps'];
};

export const Item: FC<ItemProps> = ({
  className,
  href,
  onClick,
  title,
  htmlProps,
}) => {
  const Component = href ? Link : 'div';
  return (
    <Menu.Item>
      {({ active }) => (
        <Component
          {...{ href, onClick, children: title }}
          {...(href && htmlProps && { htmlProps })}
          className={twMerge(
            'flex cursor-pointer justify-between px-4 py-2 text-sm hover:bg-gray-100',
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            className
          )}
        />
      )}
    </Menu.Item>
  );
};
