import { SVGProps } from 'react';

const GetStartBackgroundPattern = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1258"
      height="791"
      viewBox="0 0 1258 791"
      fill="none"
      {...props}
    >
      <g opacity="0.8" clipPath="url(#clip0_179_2124)">
        <mask
          id="mask0_179_2124"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="157"
          y="-225"
          width="944"
          height="1016"
        >
          <rect
            width="943.5"
            height="943.5"
            transform="matrix(1 0 0 -1 157 791)"
            fill="url(#paint0_radial_179_2124)"
          />
        </mask>
        <g mask="url(#mask0_179_2124)">
          <g clipPath="url(#clip1_179_2124)">
            <g clipPath="url(#clip2_179_2124)">
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 157.25 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 209.667 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 262.083 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 314.5 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 366.917 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 419.333 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 471.75 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 524.167 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 576.583 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 629 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 681.417 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 733.833 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 786.25 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 838.667 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 891.083 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 943.5 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 995.916 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="943.5"
                y2="-0.327604"
                transform="matrix(0 -1 -1 0 1048.33 790.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
            </g>
            <g clipPath="url(#clip3_179_2124)">
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 738.083)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 685.667)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 633.25)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 580.833)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 528.417)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 476)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 423.583)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 371.167)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 318.75)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 266.333)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 213.917)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 161.5)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 109.083)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 56.667)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
              <line
                y1="-0.327604"
                x2="1258"
                y2="-0.327604"
                transform="matrix(1 0 0 -1 0.00012207 4.25024)"
                stroke="#FFCD99"
                strokeWidth="0.655208"
              />
            </g>
          </g>
          <rect
            x="146"
            y="658"
            width="966"
            height="140"
            fill="url(#paint1_linear_179_2124)"
          />
        </g>
      </g>
      <ellipse
        opacity="0.1"
        cx="629.5"
        cy="423"
        rx="597.5"
        ry="305"
        fill="url(#paint2_radial_179_2124)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_179_2124"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(471.75 -0.000112474) rotate(90) scale(943.5 492.447)"
        >
          <stop />
          <stop offset="0.953125" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_179_2124"
          x1="629"
          y1="658"
          x2="629"
          y2="798"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F8F5" stopOpacity="0" />
          <stop offset="1" stopColor="#F9F8F5" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_179_2124"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(629.5 423) rotate(90) scale(305 597.5)"
        >
          <stop stopColor="#F17732" />
          <stop offset="1" stopColor="#F17732" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_179_2124">
          <rect
            width="1258"
            height="791"
            fill="white"
            transform="matrix(1 0 0 -1 0 791)"
          />
        </clipPath>
        <clipPath id="clip1_179_2124">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="matrix(1 0 0 -1 0.00012207 790.5)"
          />
        </clipPath>
        <clipPath id="clip2_179_2124">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="matrix(1 0 0 -1 0.00012207 790.5)"
          />
        </clipPath>
        <clipPath id="clip3_179_2124">
          <rect
            width="1258"
            height="943.5"
            fill="white"
            transform="matrix(1 0 0 -1 0.00012207 790.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GetStartBackgroundPattern;
