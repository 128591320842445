import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, PropsWithChildren, useRef } from 'react';

export type DrawerProps = PropsWithChildren<{
  open: boolean;
  onClose: (value: boolean) => void;
}>;

export const Drawer: FC<DrawerProps> = ({ open, children, onClose }) => {
  const focusRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-30 overflow-hidden"
        open={open}
        onClose={onClose}
        initialFocus={focusRef}
      >
        {/* hack to prevent drawer from focusing elements inadvertently
        (https://headlessui.dev/react/dialog#managing-focus-within-your-dialog)
        */}
        <div ref={focusRef} />

        <div className="absolute inset-0 z-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 z-0 bg-grey/75 transition-opacity" />
          </Transition.Child>

          {/* The drawer holder */}
          <div className="fixed inset-y-0 right-0 z-0 flex shadow-sm">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              {/* The actual drawer */}
              <div className="h-full overflow-y-auto bg-grey-light">
                {/* Content */}
                {children}
              </div>
              {/* /End replace */}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
