import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Stripe from 'stripe';
import next from 'next';
import { useAuthenticatedAxios, useInvoice } from 'utils/stripe';
import { useMessages } from 'components';

const ALERT_KEY = 'INVOICE_LINE_ITEM';

type UseInvoiceLineHook = () => {
  createItem: (a: Stripe.Price) => Promise<void>;
  deleteItem: (b: string) => Promise<void>;
  updateItem: (id: string, c: Stripe.Price) => Promise<void>;
};

export const useInvoiceLineItemAPI: UseInvoiceLineHook = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { alert, dismiss } = useMessages();
  const router = useRouter();
  const { create, mutate, invoice } = useInvoice();
  const { employerId } = router.query;

  const axios = useAuthenticatedAxios();

  useEffect(() => {
    if (loading) {
      alert({
        key: ALERT_KEY,
        title: 'Updating your order',
        type: 'processing',
        dismissable: false,
      });
      return;
    }
    dismiss(ALERT_KEY);
  }, [loading]);

  const deleteItem = async (id: string) => {
    if (!invoice) return;
    try {
      setLoading(true);
      const { data: nextInvoice } = await axios.delete(
        `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoice/${invoice.id}/invoiceItem/${id}`
      );
      mutate(nextInvoice, true);
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'delete_line_item_error',
          title: 'Error deleting item',
          dismissable: true,
          ...(!!err && { message: err?.message }),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const createItem = async (price: Stripe.Price) => {
    try {
      setLoading(true);
      if (!invoice) {
        setLoading(false);
        await create({
          lineItems: [{ price: price.id }],
        });
      } else {
        const { data: nextInvoice } = await axios.post(
          `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoice/${invoice.id}/invoiceItem`,
          { price, invoiceId: invoice?.id }
        );
        mutate(nextInvoice, true);
      }
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'create_line_item_error',
          title: 'Error adding item',
          dismissable: true,
          ...(!!err && { message: err?.message }),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const updateItem = async (id: string, price: Stripe.Price) => {
    if (!invoice) return;
    try {
      setLoading(true);
      const { data: nextInvoice } = await axios.put(
        `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/customer/${employerId}/invoice/${invoice.id}/invoiceItem/${id}`,
        { price }
      );
      mutate(nextInvoice, true);
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'update_line_item_error',
          title: 'Error updating item',
          dismissable: true,
          ...(!!err && { message: err?.message }),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    createItem,
    deleteItem,
    updateItem,
  };
};
