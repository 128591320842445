import * as yup from 'yup';
import { phoneValidation } from 'utils/forms/validation';

const schema = yup.object({
  id: yup.string().required(),
  first_name: yup.string().required('first name is required'),
  last_name: yup.string().required('last name is required'),
  phone: phoneValidation,
  about: yup.string().max(2000),
});

export default schema;
