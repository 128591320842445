import { SVGProps, CSSProperties } from 'react';
import {
  ImageFiveIntegration,
  ImageFourIntegration,
  ImageOneIntegration,
  ImageThreeIntegration,
  ImageTwoIntegration,
} from 'components/selfService/IntegrationsSection/content';

// Types
type IntegrationVectorPros = SVGProps<SVGSVGElement> & {
  iconTopLeft: CSSProperties;
  iconTopRight: CSSProperties;
  centralLogo: CSSProperties;
  iconBottomLeft: CSSProperties;
  iconBottomRight: CSSProperties;
};

const IntegrationVector = ({
  iconTopLeft,
  iconTopRight,
  centralLogo,
  iconBottomLeft,
  iconBottomRight,
  ..._rest
}: IntegrationVectorPros) => {
  return (
    <svg
      id="a"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 603 610"
      {..._rest}
    >
      <defs>
        <radialGradient
          id="f"
          data-name="Gradiente sem nome"
          cx="-372.4"
          cy="799.1"
          fx="-372.4"
          fy="799.1"
          r="1"
          gradientTransform="translate(-240637.5 113901) rotate(90) scale(305 -301.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fc7122" />
          <stop offset=".7" stopColor="#f17732" stopOpacity="0" />
        </radialGradient>
        <clipPath id="g">
          <rect
            x="249.8"
            y="287.8"
            width="99.2"
            height="29.2"
            fill="none"
            strokeWidth="0"
          />
        </clipPath>
      </defs>
      <g id="b" data-name="ZhxJuT.tif">
        <image
          id="c"
          data-name="Layer 0"
          width="557"
          height="556"
          transform="translate(163 159.7) scale(.5)"
          xlinkHref={`data:image/png;base64,${ImageOneIntegration}`}
        />
      </g>
      <g id="d" data-name="B8KYzp.tif">
        <image
          id="e"
          data-name="Layer 0"
          width="874"
          height="875"
          transform="translate(83.5 79.8) scale(.5)"
          xlinkHref={`data:image/png;base64,${ImageTwoIntegration}`}
        />
      </g>
      <ellipse
        cx="301.5"
        cy="305"
        rx="301.5"
        ry="305"
        fill="url(#f)"
        opacity=".2"
        strokeWidth="0"
      />
      <g
        id="positionIconTopLeft"
        className="transition-transform duration-1000 ease-out"
        style={iconTopLeft}
      >
        <rect
          x="71"
          y="119"
          width="94"
          height="94"
          rx="8"
          ry="8"
          fill="#fff"
          strokeWidth="0"
        />
        <rect
          x="71.6"
          y="119.6"
          width="92.9"
          height="92.9"
          rx="7.4"
          ry="7.4"
          fill="none"
          stroke="#ffece1"
          strokeWidth="1.1"
        />
        <image
          id="m"
          data-name="Layer 0"
          width="130"
          height="78"
          transform="translate(85 146.2) scale(.5)"
          xlinkHref={`data:image/png;base64,${ImageFiveIntegration}`}
        />
      </g>
      <rect x="85" y="147" width="66" height="39" fill="none" strokeWidth="0" />
      <g
        id="positionIconTopRight"
        className="transition-all duration-1000 ease-out"
        style={iconTopRight}
      >
        <rect
          x="359"
          y="82"
          width="94"
          height="94"
          rx="8"
          ry="8"
          fill="#fff"
          strokeWidth="0"
        />
        <rect
          x="359.6"
          y="82.6"
          width="92.9"
          height="92.9"
          rx="7.4"
          ry="7.4"
          fill="none"
          stroke="#ffece1"
          strokeWidth="1.1"
        />
        <path
          d="m438.1,129.5c0-1.8.9-3.4,3.2-3.4s1.8.8,1.8,1.4v.8h0c-.3-.1-.7-.3-1.3-.3-1,0-1.5.5-1.5,1.9v5.1h-.6c-1,0-1.6-.5-1.6-1.4v-4.1Zm-59.7,1.3c0-2.6,1.5-4.8,4.4-4.8s4.2,2.1,4.2,4.4-1.5,4.7-4.4,4.7-4.2-2.1-4.2-4.4Zm2.3-.3c0,1.7.6,2.8,2.1,2.8s1.9-1,1.9-2.5-.7-2.8-2.1-2.8-1.9,1-1.9,2.5Zm-5.8-6.8h.8c1,0,1.5.5,1.5,1.5v6.6c0,1.8-1,3.2-3.3,3.2s-2.1-.6-2.1-1.3v-1h0c.4.2,1,.4,1.7.4,1,0,1.4-.4,1.4-1.3v-8Zm13.2-.5h.8c.9,0,1.4.5,1.4,1.5v2.5c.4-.6,1.2-1.3,2.4-1.3,2.2,0,4,1.8,4,4.4s-1.7,4.8-4.4,4.8-4.2-1.8-4.2-4.5v-7.4Zm2.2,7.1c0,2,.9,2.8,2.2,2.8s1.9-1,1.9-2.4c0-2.1-.8-2.8-2.1-2.8s-2,1.1-2,2.4Zm12.3-6.6h.9c.9,0,1.5.5,1.8,1,1,1.8,2.7,6.6,3.1,10.1h-.9c-.9,0-1.4-.5-1.5-1.1,0-.7-.2-1.3-.3-1.8h-4.5c-.2.9-.4,1.9-.6,2.9h-.9c-.7,0-1-.4-1-1.1,0-1.6,2.1-8.2,3.8-10.1Zm.9,2.1c-.7,1.2-1.4,2.9-1.7,4.5h3.5c-.4-1.6-1.1-3.4-1.7-4.5Zm12.2-2.5h.7c1.1,0,1.5.6,1.5,1.5v6.2c0,2.5-1.2,4.2-4.3,4.2s-4.1-2-4.1-4.4,1.4-4.7,4-4.7,1.9.6,2.2,1.4v-4.1Zm-4,7c0,1.8,1,3,2.2,3s1.8-.9,1.8-2.5-1-3-2.2-3-1.8,1.1-1.8,2.5Zm13.8-7h.7c1.1,0,1.5.6,1.5,1.5v6.2c0,2.5-1.2,4.2-4.3,4.2s-4.1-2-4.1-4.4,1.4-4.7,4-4.7,1.9.6,2.2,1.4v-4.1Zm-4,7c0,1.8,1,3,2.2,3s1.8-.9,1.8-2.5-1-3-2.2-3-1.8,1.1-1.8,2.5Zm7.6.6c0-2.9,1.8-4.8,4.3-4.8s3.5,1.3,3.5,3.4-1.2,2.1-4.1,2.1h-1.4c.2,1.1,1.2,1.8,2.5,1.8s2-.2,2.7-.8h0v.7c0,1.1-.8,2-3.2,2s-4.3-1.7-4.3-4.4Zm2.3-1.1v.3s1,0,1,0c2.1,0,2.6-.2,2.6-.8,0-.9-.7-1.3-1.7-1.3s-1.8.9-1.8,1.9Z"
          fill="#0069b2"
          strokeWidth="0"
        />
      </g>
      <g
        id="iconBottomLeftLogo"
        className="transition-transform duration-1000 ease-out"
        style={iconBottomLeft}
      >
        <rect
          x="77"
          y="400"
          width="94"
          height="94"
          rx="8"
          ry="8"
          fill="#fff"
          strokeWidth="0"
        />
        <rect
          x="77.6"
          y="400.6"
          width="92.9"
          height="92.9"
          rx="7.4"
          ry="7.4"
          fill="none"
          stroke="#ffece1"
          strokeWidth="1.1"
        />
        <image
          id="k"
          data-name="Layer 0"
          width="128"
          height="81"
          transform="translate(92 426.7) scale(.5)"
          xlinkHref={`data:image/png;base64,${ImageFourIntegration}`}
        />
      </g>
      <rect x="92" y="426" width="64" height="42" fill="none" strokeWidth="0" />
      <g
        id="iconBottomRightLogo"
        className="transition-transform duration-1000 ease-out"
        style={iconBottomRight}
      >
        <rect
          x="406"
          y="355"
          width="94"
          height="94"
          rx="8"
          ry="8"
          fill="#fff"
          strokeWidth="0"
        />
        <rect
          x="406.6"
          y="355.6"
          width="92.9"
          height="92.9"
          rx="7.4"
          ry="7.4"
          fill="none"
          stroke="#ffece1"
          strokeWidth="1.1"
        />
        <image
          id="i"
          data-name="Layer 0"
          width="140"
          height="42"
          transform="translate(420 393.5) scale(.5)"
          xlinkHref={`data:image/png;base64,${ImageThreeIntegration}`}
        />
      </g>
      <rect
        x="418"
        y="392"
        width="70"
        height="21"
        fill="none"
        strokeWidth="0"
      />
      <g
        id="centralLogo"
        className="origin-center transition-transform duration-1000 ease-out"
        style={centralLogo}
      >
        <rect
          x="230.5"
          y="231.5"
          width="141"
          height="141"
          rx="7.5"
          ry="7.5"
          fill="#19150c"
          stroke="#ebaa85"
        />
        <g clipPath="url(#g)">
          <g>
            <rect
              x="230.5"
              y="231.5"
              width="141"
              height="141"
              rx="7.5"
              ry="7.5"
              fill="#19150c"
              stroke="#ebaa85"
            />
            <path
              d="m254,301v-11.7h-4.2v-1.4h10.1v1.4h-4.2v11.7h-1.6Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m261.9,301v-13.1h5.3c.7,0,1.5.1,2.2.4.6.3,1.1.7,1.5,1.2.4.6.5,1.2.5,1.9v.2c0,.7-.2,1.5-.6,2-.4.5-.9.8-1.5,1v.3c.4,0,.8.2,1.1.4.2.2.3.4.4.6,0,.2.1.4,0,.7v4.4h-1.6v-4.2c0-.2,0-.3,0-.5,0-.1-.1-.3-.2-.4-.3-.2-.6-.3-.9-.3h-4.6v5.4h-1.6Zm1.6-6.8h3.6c.7,0,1.4-.2,2-.6.2-.2.4-.5.5-.8.1-.3.2-.6.2-1h0c0-.5,0-.8-.2-1.1-.1-.3-.3-.6-.5-.8-.6-.5-1.3-.7-2-.6h-3.6v4.9Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m272.7,301l4.1-13.1h2.8l4.1,13.1h-1.6l-1-3.3h-5.6l-1,3.3h-1.6Zm3.1-4.7h4.8l-2.3-7.4h-.3l-2.3,7.4Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m285,301v-1.4h1.8v-10.3h-1.8v-1.4h5.1c1.7,0,3.1.4,4,1.3.9.9,1.4,2.2,1.4,4v2.4c0,1.8-.5,3.2-1.4,4-.9.9-2.3,1.3-4,1.3h-5.1Zm3.4-1.4h1.7c1.1,0,2.1-.3,2.9-.9.7-.6,1-1.6,1-2.9v-2.6c0-1.3-.3-2.3-1-2.9-.8-.7-1.9-1-2.9-.9h-1.7v10.2Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m298.1,301v-13.1h8.1v1.4h-6.5v4.4h6v1.4h-6v4.5h6.6v1.4h-8.2Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m290.6,316.6c-.6,0-1.1,0-1.7-.2-.5-.2-1-.5-1.4-.9-.4-.4-.7-.9-.9-1.4-.2-.5-.3-1.1-.2-1.7v-1h1.6v1c0,.7.2,1.4.6,2,.2.3.5.5.9.6.3.1.7.2,1.1.2.4,0,.7,0,1-.1.3-.1.6-.3.8-.6.4-.6.6-1.3.5-2v-7.8h-2.3v-1.4h5.3v1.4h-1.4v7.8c0,1.1-.3,2.2-1,3.1-.4.4-.9.7-1.4.9-.5.2-1.1.3-1.6.2Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m302.8,316.6c-.7,0-1.4,0-2-.3-.6-.2-1.2-.6-1.7-1.1-.9-.9-1.4-2.2-1.4-4v-3c0-1.7.5-3.1,1.4-4,1.1-.9,2.4-1.4,3.8-1.4s2.7.5,3.8,1.4c.9.9,1.4,2.2,1.4,4v3c0,1.7-.5,3.1-1.4,4-.5.5-1.1.8-1.8,1.1-.6.2-1.3.3-2,.3Zm0-1.4c.5,0,1,0,1.4-.2.5-.2.9-.4,1.2-.8.7-.8,1-1.8.9-2.8v-3.1c0-1-.3-2-.9-2.8-.7-.7-1.7-1-2.6-1s-1.9.4-2.6,1c-.7.8-1,1.8-.9,2.8v3.1c0,1,.3,2,.9,2.8.3.3.8.6,1.2.8.5.2.9.3,1.4.2Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m309.6,316.4v-1.4h1.8v-10.3h-1.8v-1.4h6.6c.7,0,1.4.1,2,.4.6.2,1,.6,1.4,1.2.3.5.5,1.1.5,1.7v.2c0,.5,0,.9-.3,1.4-.2.4-.5.8-.9,1.1-.3.2-.6.4-1,.5v.3c.3,0,.7.2,1,.4.4.2.6.6.9.9.2.5.4,1,.4,1.5v.2c0,.6-.2,1.3-.5,1.8-.3.5-.8,1-1.4,1.2-.6.3-1.3.4-2,.4h-6.6Zm3.4-7.3h3.1c.7,0,1.3-.2,1.8-.6.2-.2.4-.4.5-.7.1-.3.2-.5.2-.8v-.2c0-.3,0-.6-.2-.8-.1-.3-.3-.5-.5-.7-.5-.4-1.2-.6-1.8-.6h-3.1v4.4Zm0,5.9h3c.7,0,1.4-.2,1.9-.6.2-.2.4-.4.5-.7.1-.3.2-.6.2-.9v-.2c0-.3,0-.6-.2-.9-.1-.3-.3-.5-.5-.7-.5-.4-1.2-.6-1.9-.6h-3v4.5Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m327,316.7c-.9,0-1.8-.2-2.6-.5-.7-.3-1.4-.9-1.8-1.5-.5-.8-.7-1.7-.7-2.6v-.4h1.6v.4c0,.5,0,.9.2,1.3.2.4.4.8.8,1.1.7.6,1.6.8,2.5.8.8,0,1.7-.2,2.4-.7.3-.2.5-.5.6-.8.1-.3.2-.6.2-1,0-.4-.1-.8-.4-1.2-.3-.3-.6-.6-1-.7-.5-.2-1-.4-1.5-.5l-1-.2c-.7-.2-1.4-.4-2-.7-.6-.3-1-.6-1.4-1.1-.4-.5-.5-1.2-.5-1.8,0-.7.2-1.3.6-1.9.4-.6.9-1,1.6-1.2.7-.3,1.5-.4,2.3-.4.8,0,1.6.1,2.4.5.7.3,1.3.7,1.7,1.3.4.7.6,1.4.6,2.2v.8h-1.6v-.8c0-.5-.1-1.1-.4-1.5-.3-.4-.7-.7-1.1-.9-.5-.2-1.1-.3-1.6-.3-.7,0-1.5.2-2.1.6-.3.2-.5.4-.6.7-.1.3-.2.6-.2.9,0,.4.1.8.3,1.1.3.3.6.6,1,.7.5.2,1,.4,1.5.5l1,.2c.7.1,1.4.4,2.1.7.6.3,1.1.6,1.5,1.1.4.6.6,1.2.5,1.9,0,.7-.2,1.4-.6,2-.4.6-1,1.1-1.6,1.3-.8.3-1.6.5-2.5.5Z"
              fill="#fff"
              strokeWidth="0"
            />
            <path
              d="m335.1,311.6v-8.4h2l2.9,7.7h.2v-7.7h1v8.4h-2l-2.9-7.8h-.2v7.8h-1Z"
              fill="#d9750c"
              strokeWidth="0"
            />
            <path
              d="m342.8,311.6v-1.3l4.7-6h0c0-.1-4.5-.1-4.5-.1v-.9h5.5v1.3l-4.7,6h0c0,.1,4.8.1,4.8.1v.9h-5.8Z"
              fill="#d9750c"
              strokeWidth="0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IntegrationVector;
