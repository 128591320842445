import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useAuthContext } from 'components/AuthContext';
import { useMessages } from 'components';

const SUBSCRIPTION_ALERT = 'HUBSPOT_SUBSCRIPTION_ALERT_KEY';

import { makeNoticeFactory } from 'utils/factories/notices';

export function useHubspotSubscriptions() {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean | undefined>(undefined);
  const notices = makeNoticeFactory({
    ...useMessages(),
    KEY: SUBSCRIPTION_ALERT,
  });

  useEffect(() => {
    getUserSubscriptionStatus();
  }, []);

  const getUserSubscriptionStatus = useCallback(async () => {
    if (loading || !user?.email) return;
    setLoading(true);

    try {
      const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_SITE_URL}/api/hubspot/${encodeURIComponent(
          user.email
        )}`
      );
      setSubscribed(data.subscribed as boolean);
    } catch (error) {
      if (error instanceof AxiosError) {
        notices.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [loading, user?.email]);

  const updateSubscription = useCallback(
    async (subscribe: boolean) => {
      if (loading || !user?.email) return;
      setLoading(true);
      notices.processing();

      //Optimistic rendering assumes a successful query
      setSubscribed(subscribe);

      try {
        // Returns a 204 – empty response
        await axios.post(
          `${process.env.NEXT_PUBLIC_SITE_URL}/api/hubspot/${encodeURIComponent(
            user.email
          )}/subscribe`,
          {
            subscribe,
          }
        );
        notices.success();
      } catch (error) {
        // Something has gone wrong so reverting optimistic render
        setSubscribed(!subscribe);
        if (error instanceof AxiosError) {
          notices.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [loading, user?.email]
  );

  return {
    get: getUserSubscriptionStatus,
    update: updateSubscription,
    subscribed,
    loading,
  };
}
