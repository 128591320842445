// Framework
import Image, { type ImageProps } from 'next/image';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Types
export type CraftImage = ImageProps;
type PartnersSectionProps = {
  logos: CraftImage[];
};

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { Section } from 'components/selfService/Section';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function PartnersSection({ logos }: PartnersSectionProps): JSX.Element {
  return (
    <Section bleed className="py-16">
      <EmployerText
        as="h2"
        size="2xl"
        className="mx-auto mb-16 w-64 text-center text-orange-500 md:w-full"
      >
        Trusted by New Zealand's trades employers
      </EmployerText>
      <div className="relative z-0 h-[46px] overflow-hidden md:h-16">
        <Slider
          pauseOnFocus={false}
          pauseOnHover={false}
          infinite
          autoplay
          cssEase="linear"
          autoplaySpeed={5000}
          speed={5000}
          slidesToShow={5}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1.5,
              },
            },
          ]}
        >
          {logos.map((logo, i) => (
            <Image
              key={i}
              className="h-[46px] w-auto object-contain px-8 md:h-16 md:px-16"
              {...logo}
              src={logo.src as string}
            />
          ))}
        </Slider>
      </div>
    </Section>
  );
}
