type TableTitleProps = {
  title: string;
  location: string;
  employer?: string;
};

export function TableTitle({
  title,
  employer,
  location,
}: TableTitleProps): JSX.Element {
  return (
    <p className="font-medium text-black">
      {title}{' '}
      {employer && (
        <>
          <span className="font-normal text-grey-dark">in</span> {location}
        </>
      )}
      <br />
      <span className="font-normal text-grey-dark">{employer ?? location}</span>
    </p>
  );
}
