import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useMessages } from 'components';
import { useAuthenticatedAxios } from 'utils/stripe';

type UseCustomerPortal = {
  onInit?: () => void;
  onSuccess?: () => void;
  onError?: (e?: any) => void;
  onReturn?: (returnCode: string) => void;
};

export const useCustomerPortal = ({
  onInit,
  onError,
  onSuccess,
  onReturn,
}: UseCustomerPortal = {}) => {
  const { alert } = useMessages();
  const router = useRouter();
  const { query, pathname } = router;

  // handle call to stripe customer portal
  const { employerId } = query;

  const axios = useAuthenticatedAxios();
  const callStripePortal = useCallback(async () => {
    try {
      onInit && onInit();
      const { status, data } = await axios.post(
        `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/billing`,
        {
          employerId: employerId as string,
          url: window.location.href,
        }
      );

      // redirect to checkout url
      onSuccess && onSuccess();
      if (status === 200) window.location.href = data;
    } catch (e) {
      if (e instanceof Error) {
        alert({
          key: `stripe_error`,
          type: 'error',
          title: e?.message,
          dismissable: true,
          duration: 3000,
        });
      }
      onError && onError(e);
      captureException(e);
    }
  }, [employerId, window.location.href]);

  // handle return to employer portal
  const { returnCode, ...rest } = query;
  useEffect(() => {
    if (!returnCode) return;

    router.replace({ pathname, query: rest }, undefined, { shallow: true });

    onReturn && onReturn(returnCode as string);
  }, [returnCode]);

  return { callStripePortal };
};
