import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import { Link } from 'components';
import { Maybe } from 'database/types';
import useIsBrowser from 'utils/hooks/useIsBrowser';
import { SmallTab, SmallTabProps } from './SmallTab';
import { LargeTab } from './LargeTab';

export type PricingProps = SmallTabProps & {
  // title: string;
  subtitle?: Maybe<string>;
  tabs: Array<{
    hash: string;
    subtitle: string;
    title: string;
  }>;
};

export function Pricing({
  bundles,
  tabs,
  // title,
  subtitle,
}: PricingProps): JSX.Element | null {
  const { pathname, asPath } = useRouter();
  const [, h] = asPath.split('#');
  const [hash, setHash] = useState<string>(h ? `#${h}` : tabs[0].hash);
  const isBrowser = useIsBrowser();

  if (!isBrowser) return null;

  return (
    <section className="mx-auto w-full max-w-7xl px-6" id="pricing">
      <div className="mx-auto max-w-2xl lg:text-center">
        <p className="font-medium leading-7 text-primary">Pricing</p>
        <h2 className="mt-2 text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
          {subtitle}
        </h2>
      </div>
      <div className="mt-16 flex justify-center">
        <nav
          className={`flex flex-row rounded-full border p-2 text-center font-medium`}
        >
          {tabs.map((tab) => (
            <Link
              href={{
                pathname,
                hash: tab.hash,
              }}
              onClick={() => setHash(tab.hash)}
              shallow
              key={tab.hash}
              className={twMerge(
                'translate-x-0',
                tab.hash === hash
                  ? 'rounded-full border-primary-500 bg-primary text-white duration-500 ease-in-out'
                  : 'border-transparent text-gray-500 hover:text-gray-700',
                `border-b-2 p-4 text-center text-sm font-medium duration-500 ease-in-out`
              )}
            >
              {tab.title}
            </Link>
          ))}
        </nav>
      </div>
      <p className="mx-auto mt-14 max-w-xl text-center text-lg leading-8 text-gray-600">
        {tabs.find((i) => i.hash === hash)?.subtitle ?? ''}
      </p>
      {(() => {
        switch (hash) {
          case '#small':
          default:
            return <SmallTab bundles={bundles} />;
          case '#medium':
            return <LargeTab />;
        }
      })()}
    </section>
  );
}
