import { FC } from 'react';

type EmptyProps = {
  title?: string;
  description?: string;
  button?: {
    text: string;
    onClick: () => void;
  };
};

export const Empty: FC<EmptyProps> = ({ title, description, button }) => (
  <div className="space-y-2 overflow-hidden rounded border border-dashed border-orange-500 bg-none p-4 text-center sm:p-8">
    <svg
      className="mx-auto size-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#f17732"
        strokeWidth="1"
        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
      />
    </svg>
    {title && (
      <h3 className="mt-2 text-sm font-medium -tracking-two-percent text-charcoal-500">
        {title}
      </h3>
    )}
    {description && (
      <p className="mt-1 text-sm -tracking-two-percent text-charcoal-200">
        {description}
      </p>
    )}
    {button && (
      <div className="mt-6">
        <button
          color="black"
          onClick={button.onClick}
          className="mx-auto mt-4 flex h-10 items-center justify-center gap-2 rounded-lg bg-orange-500 px-8 text-white"
        >
          {button.text}
        </button>
      </div>
    )}
  </div>
);
