import { RadioGroup } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Dispatch, ElementType, SetStateAction, VFC } from 'react';
import {
  Button,
  CardManagement,
  CardManagementProps,
  FullPageLoader,
  Link,
  ModalBase,
  SubscriptionPaymentSteps,
} from 'components';
import { RadioOption } from 'components/forms';
import { usePaymentMethods } from 'utils/stripe';

export type SubscriptionViewProps = {
  open?: boolean;
  step: SubscriptionPaymentSteps;
  setStep: Dispatch<SetStateAction<SubscriptionPaymentSteps>>;
  plan?: string;
  setPlan: Dispatch<SetStateAction<string | undefined>>;
  options: RadioOption<string>[];
  defaultCard: ReturnType<typeof usePaymentMethods>['defaultCard'];
  onClose?: () => void;
};

export const SubscriptionModal: VFC<SubscriptionViewProps> = ({
  open,
  step,
  setStep,
  plan,
  setPlan,
  options,
  defaultCard,
  onClose,
}) => {
  const handleChange = (value: typeof plan) => value && setPlan(value);

  const onAdd: CardManagementProps['onAdd'] = () => setStep('confirm');

  switch (step) {
    case 'select':
      return (
        <ModalBase
          open={open}
          title="Add Employer page"
          onClose={onClose}
          dismissable
        >
          <div className="space-y-4 py-4">
            <div className="prose">
              <p>
                Enjoy additional benefits with a Trade Jobs NZ Employer Page
                Subscription:
              </p>
              <ul>
                <li>Acts as a landing page (like a website)</li>
                <li>Lists all your active Job Ads, in one place</li>
                <li>Allows candidates to read more about your company</li>
                <li>Included in our TJNZ Directory</li>
                {/* <li>30% discounts on all new listings</li> */}
              </ul>
              <p>$499 per annum</p>
              <p>*You can cancel your subscription at anytime</p>
            </div>
            <RadioGroup<ElementType<any>, typeof plan>
              value={plan}
              onChange={handleChange}
              className="flex w-full justify-between
              space-x-4"
            >
              {options.map(({ value, label }) => (
                <RadioOption
                  {...{ value, label }}
                  key={value ?? Math.random().toString()}
                  className="w-full"
                />
              ))}
            </RadioGroup>
          </div>
          <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
            <Button
              className="w-full sm:w-auto"
              onClick={() => setStep('card_check')}
              size="small"
            >
              Next
            </Button>
            <Button
              className="mr-0 w-full sm:mr-2 sm:w-auto"
              size="small"
              color="white"
              onClick={onClose}
            >
              No thanks
            </Button>
          </div>
        </ModalBase>
      );
    case 'card_check':
      return (
        <ModalBase open={open} title="Checking Card..." forceFocus>
          Please don't close or navigate away from this page.
          <div className="flex h-16">
            <FullPageLoader />
          </div>
        </ModalBase>
      );
    case 'card_add':
      return (
        <ModalBase
          open={open}
          title="Payment Information"
          onClose={onClose}
          dismissable
          forceFocus
        >
          <div className="space-y-4 py-4">
            <CardManagement {...{ onAdd }} />
          </div>
          <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
            <Button className="w-full sm:w-auto" disabled size="small">
              Confirm
            </Button>

            <Button
              className="mr-0 w-full sm:mr-2 sm:w-auto"
              size="small"
              color="white"
              onClick={() => setStep('select')}
            >
              Back
            </Button>
          </div>
        </ModalBase>
      );
    case 'confirm':
      return (
        <ModalBase
          open={open}
          title="Payment Information"
          onClose={onClose}
          dismissable
          forceFocus
        >
          <div className="space-y-4 py-4">
            <div className="prose">
              <p>
                You will be charged on your stored credit card ending in{' '}
                <strong>{defaultCard?.card?.last4}</strong>.
              </p>
              <p>
                If you wish to use a different card, please update your default
                card in the{' '}
                <Link
                  href="/portal/a75be271-a720-4cf7-ba0d-2dfba73d68d2/billing"
                  className="link"
                  htmlProps={{ onClick: onClose }}
                >
                  billing page
                </Link>
                .
              </p>
            </div>
          </div>
          <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
            <Button
              className="w-full sm:w-auto"
              onClick={() => setStep('pay')}
              size="small"
            >
              Confirm
            </Button>

            <Button
              className="mr-0 w-full sm:mr-2 sm:w-auto"
              size="small"
              color="white"
              onClick={() => setStep('select')}
            >
              Back
            </Button>
          </div>
        </ModalBase>
      );
    case 'pay':
      return (
        <ModalBase
          open={open}
          dismissable={false}
          title="Processing Subscription..."
          forceFocus
        >
          Please don't close or navigate away from this page.
          <div className="flex h-16">
            <FullPageLoader />
          </div>
        </ModalBase>
      );
    case 'return':
      return (
        <ModalBase
          open={open}
          title="Thank you! You're subscribed."
          onClose={onClose}
          dismissable
          sidebar={
            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:mr-4 sm:size-10">
              <CheckIcon className="size-6 text-green-500" />
            </div>
          }
        >
          <div className="space-y-4 py-4">
            <div className="prose">
              <p>Your page will be live shortly. Enjoy!</p>
            </div>
          </div>
          <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
            <Button className="w-full sm:w-auto" onClick={onClose} size="small">
              Okay
            </Button>
          </div>
        </ModalBase>
      );
    case 'failed':
      return (
        <ModalBase
          open={open}
          title="Error subscribing"
          onClose={onClose}
          dismissable
          sidebar={
            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:mr-4 sm:size-10">
              <XMarkIcon className="size-6 text-red-500" />
            </div>
          }
        >
          <div className="space-y-4 py-4">
            <div className="prose">
              <p>
                Sorry, there was an error processing your payment. Please try
                with a different card. If the problem persists, contact us.
              </p>
              <Link
                href="/portal/a75be271-a720-4cf7-ba0d-2dfba73d68d2/billing"
                className="link mt-4 underline"
                htmlProps={{ onClick: onClose }}
              >
                Manage your cards
              </Link>
            </div>
          </div>
          <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
            <Button className="w-full sm:w-auto" onClick={onClose} size="small">
              Okay
            </Button>
          </div>
        </ModalBase>
      );
    default:
      return null;
  }
};
