import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { useInvoice } from './useInvoice';

type Props = {
  isPaymentStep?: boolean;
  status: Stripe.Invoice['status'][];
  to: string;
};

type UseInvoiceHook = (a: Props) => void;

export const useInvoiceRedirect: UseInvoiceHook = ({
  isPaymentStep,
  status,
  to,
}) => {
  const [done, setDone] = useState<boolean>(false);
  const { invoice, loading } = useInvoice();
  const router = useRouter();
  const { query } = router;
  useEffect(() => {
    if (loading || done) return;
    if (
      !invoice ||
      (invoice.status && !status.includes(invoice.status)) ||
      (isPaymentStep && !invoice.payment_intent)
    ) {
      router.replace({
        pathname: to,
        query,
      });
    }
    setDone(true);
  }, [done, invoice, loading]);
};

type UseVoidInvoiceRedirect = (b?: string) => void;

export const useVoidInvoiceRedirect: UseVoidInvoiceRedirect = (to) => {
  const { invoice, loading, voidInvoice } = useInvoice();
  const [done, setDone] = useState<boolean>(false);
  const router = useRouter();
  const { query } = router;
  useEffect(() => {
    if (loading || done) return;
    if (invoice && invoice.status === 'open') {
      (async () => {
        await voidInvoice();
        to &&
          router.replace({
            pathname: to,
            query,
          });
      })();
    } else {
      setDone(true);
    }
  }, [loading, invoice, done]);
};
