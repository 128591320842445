const HomeVector = () => (
  <svg
    width="721"
    height="334"
    viewBox="0 0 721 334"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className="origin-bottom-left transition-transform duration-300 ease-in-out group-hover:scale-105">
      <rect
        y="171"
        width="76.8"
        height="76.8"
        rx="5.3"
        ry="5.3"
        fill="#f17732"
        strokeWidth="0"
      />

      <text
        transform="translate(5.8 231.7)"
        fill="#fff"
        fontFamily="InterVariable-SemiBold, 'Inter Variable'"
        fontSize="9"
        fontWeight="600"
      >
        <tspan x="0" y="0" letterSpacing="0em">
          R
        </tspan>
        <tspan x="5.6" y="0" letterSpacing="0em">
          e
        </tspan>
        <tspan x="10.9" y="0" letterSpacing="0em">
          ach inc
        </tspan>
        <tspan x="41.2" y="0" letterSpacing="0em">
          r
        </tspan>
        <tspan x="44.5" y="0" letterSpacing="0em">
          e
        </tspan>
        <tspan x="49.8" y="0" letterSpacing="0em">
          ase
        </tspan>
      </text>
      <text
        transform="translate(7.3 211.4)"
        fill="#fff"
        fontFamily="InterVariable-SemiBold, 'Inter Variable'"
        fontSize="26.1"
        fontWeight="600"
      >
        <tspan x="0" y="0" letterSpacing="0em">
          6
        </tspan>
        <tspan x="15.9" y="0" letterSpacing="0em">
          5
        </tspan>
        <tspan x="30.5" y="0" letterSpacing="0em">
          %
        </tspan>
      </text>
    </g>
    <g className="origin-top-right transition-transform duration-300 ease-in-out group-hover:scale-105">
      <rect
        x="572"
        width="68.8"
        height="68.8"
        rx="4.7"
        ry="4.7"
        fill="#f17732"
        strokeWidth="0"
      />
      <text
        transform="translate(577.5 25.8)"
        fill="#fff"
        fontFamily="InterVariable-SemiBold, 'Inter Variable'"
        fontSize="17.2"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x="0" y="0">
          1200+
        </tspan>
      </text>
      <text
        transform="translate(577.5 57.4)"
        fill="#fff"
        fontFamily="InterVariable-Medium, 'Inter Variable'"
        fontSize="10"
        fontWeight="500"
      >
        <tspan x="0" y="0" letterSpacing="0em">
          per d
        </tspan>
        <tspan x="23.3" y="0" letterSpacing="0em">
          a
        </tspan>
        <tspan x="28.5" y="0" letterSpacing="0em">
          y
        </tspan>
      </text>
      <text
        transform="translate(577.5 43.2)"
        fill="#fff"
        fontFamily="InterVariable-Medium, 'Inter Variable'"
        fontSize="10"
        fontWeight="500"
      >
        <tspan x="0" y="0" letterSpacing="0em">
          Vi
        </tspan>
        <tspan x="9" y="0" letterSpacing="0em">
          e
        </tspan>
        <tspan x="14.4" y="0" letterSpacing="0em">
          ws
        </tspan>
      </text>
    </g>
  </svg>
);

export default HomeVector;
