import { useRouter } from 'next/router';
import { useJitsu } from '@jitsu/nextjs';
import type { ProductsProps } from '../Products';

import { BundleItem } from './BundleItem';

type Props = Pick<ProductsProps, 'bundles'> & {
  selected: string;
};

export function Bundles({ bundles, selected }: Props): JSX.Element {
  const router = useRouter();
  const { track } = useJitsu();
  return (
    <div className="isolate mx-auto grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:mx-0 lg:grid-cols-3">
      {bundles.map(
        ({
          id,
          title,
          subtitle,
          price,
          description,
          features,
          recommended,
          checkout,
        }) => (
          <BundleItem
            key={id}
            id={id}
            price={price}
            recommended={recommended}
            title={title}
            subtitle={subtitle}
            description={description}
            features={features}
            selected={id === selected}
            checkout={checkout}
            onClick={() => {
              router.push(
                checkout ? `/portal/checkout/${id}` : `/portal/register`
              );
              track('package_register', {
                id,
                price,
                title,
              });
            }}
          />
        )
      )}
    </div>
  );
}
