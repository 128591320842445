import { Listbox } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import React, {
  ReactNode,
  // useEffect,
  // useState,
  VFC,
} from 'react';
import { Errors, SelectOption, useFieldError } from 'components/forms';
import { Single } from './Single';

export type SelectDuoOptions = {
  primary: SelectOption[];
  secondary: SelectOption[];
};

export type SelectDuoProps = {
  className?: string;
  label?: ReactNode;
  loading?: boolean;
  name?: string;
  options?: SelectDuoOptions;
  placeholder?: {
    primary?: string;
    secondary?: string;
  };
  onChange?: Parameters<typeof Listbox>['0']['onChange'];
  value?: {
    primary?: string | number;
    secondary?: string | number;
  };
  noValidation?: boolean;
  disabled?:
    | boolean
    | {
        primary?: boolean;
        secondary?: boolean;
      };
  border?: boolean;
  left?: boolean;
  required?: boolean;
};

export const SelectDuo: VFC<SelectDuoProps> = ({
  className,
  label,
  loading = false,
  name,
  noValidation,
  options,
  placeholder,
  value = {},
  disabled,
  border = true,
  required,
  left,
}) => {
  const { error } = useFieldError(name);

  // TODO: Not sure what this was doing
  // const [internalValue, setInternalValue] = useState(value);
  // useEffect(() => setInternalValue(value), [value, setInternalValue]);

  // const getSelected = (value?: SelectOption['value']) =>
  //   options?.find((option) => option.value === value);

  const onChangePrimary = () => null;
  const onChangeSecondary = () => null;

  const isSingleDisabled = (element: 'primary' | 'secondary') =>
    loading ||
    disabled === true ||
    (typeof disabled === 'object' && disabled?.[element]);

  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      {label && (
        <label
          htmlFor={name}
          className="mb-1 block text-sm uppercase tracking-wider text-grey"
        >
          {label}
          <span className="normal-case">{!required && ' (Optional)'}</span>
        </label>
      )}
      <div
        id={name}
        className={twMerge(
          'inline-flex space-x-[1px]',
          border && 'border border-light',
          left && 'flex-row-reverse space-x-reverse'
        )}
      >
        {/* Primary */}
        <Single
          primary
          name={`${name}_primary`}
          value={value.primary}
          placeholder={placeholder?.primary}
          options={options?.primary}
          onChange={onChangePrimary}
          disabled={isSingleDisabled('primary')}
          border={false}
          noValidation
          loading={loading}
          left={!left}
        />
        {/* Secondary */}
        <Single
          name={`${name}_secondary`}
          value={value.secondary}
          placeholder={placeholder?.secondary}
          options={options?.secondary}
          onChange={onChangeSecondary}
          disabled={isSingleDisabled('secondary')}
          border={false}
          noValidation
          loading={loading}
          className="w-1/3"
          left={left}
        />
      </div>
      {!noValidation && <Errors error={error} />}
    </div>
  );
};
