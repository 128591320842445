import { FC, Fragment, PropsWithChildren } from 'react';
import { Menu as MenuDefault, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import _ from 'lodash';
import { Item } from 'components/Dropdown/Item';

const { Button, Items } = MenuDefault;

import { useEmployerContext } from 'components/pages/PortalEmployer/EmployerContext';
import { SortTitles } from '../FilterJobs';

type FilterDropdownProps = PropsWithChildren<{
  className?: string;
  handleChange: (a: (typeof SortTitles)[number]) => void;
}>;

export const FilterDropdown: FC<FilterDropdownProps> = ({
  className,
  children,
  handleChange,
}) => {
  const { isParent } = useEmployerContext();
  const options = isParent ? [...SortTitles, 'Employer'] : SortTitles;
  return (
    <MenuDefault as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Button
            className={twMerge(
              'flex items-center rounded-full p-2 text-gray-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-secondary-500',
              className
            )}
          >
            {children}
          </Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Items
              static
              className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
            >
              <div className="py-1">
                {options.map((option) => (
                  <Item
                    onClick={() => handleChange(option)}
                    title={option}
                    key="option"
                  />
                ))}
              </div>
            </Items>
          </Transition>
        </>
      )}
    </MenuDefault>
  );
};
