import { VFC, useState } from 'react';
import _get from 'lodash.get';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import { Button, useMessages } from 'components';
import { ErrorProvider, Input, RegisterData, Select } from 'components/forms';
import { Enum_Access_Level_Enum, useGetEnumsQuery } from 'database/types';
import { InsertUserByEmailResponse } from 'pages/api/employers/[employerId]/user';
import Validation from './Validation';

export type InviteData = Pick<RegisterData, 'email'> & {
  access_level: Enum_Access_Level_Enum;
};

const ALERT_KEY = 'ALLERT_KEY_INVITE';

const DEFAULT_INVITE: InviteData = { email: '', access_level: 'full' };

export const InviteEmail: VFC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const { query } = router;
  const { data: data } = useGetEnumsQuery();
  const employerId = query?.employerId as string;

  const { alert, update } = useMessages();

  const { control, formState, handleSubmit, register } = useForm<InviteData>({
    defaultValues: DEFAULT_INVITE,
    resolver: yupResolver(Validation),
  });

  /**
   * The api call to find user needs auth. There's a chance the token will have expired and the call will fail with 401, and axios throws. When that happens the function retries itself, but only once.
   *
   * @param shouldRetry If the function should re-run after the API call failed
   */
  async function invite({ email, access_level }: InviteData) {
    if (loading) return;
    setLoading(true);
    alert({
      key: ALERT_KEY,
      type: 'processing',
      loading,
      dismissable: false,
      duration: undefined,
      message: 'Creating new user',
    });
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();
      await axios.post<InsertUserByEmailResponse>(
        `/api/employers/${employerId}/user`,
        { email, access_level },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      update({
        key: ALERT_KEY,
        type: 'success',
        dismissable: true,
        title: 'User has been successfully added',
        duration: 3000,
      });
      router.push({ pathname: router.pathname, hash: '' });
    } catch (error: unknown) {
      const message =
        _get(error, 'response.status') === 409
          ? 'User already exists on organisation'
          : _get(error, 'response.data.message', 'Something went wrong');
      update({
        key: ALERT_KEY,
        type: 'error',
        dismissable: true,
        title: 'Issue with registration',
        message,
        duration: 3000,
      });
      captureException(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form
      id="check_user_email"
      onSubmit={handleSubmit(invite)}
      className="my-6 text-left"
    >
      <ErrorProvider errors={formState.errors}>
        <Input
          {...register('email')}
          required
          type="email"
          autoCapitalize="false"
          label="Email address"
          placeholder="Email address"
        />
        <Controller
          name="access_level"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              name="access_level"
              label="Access level"
              placeholder="Select access level"
              options={data?.access_level}
              onChange={field.onChange}
            />
          )}
        />
      </ErrorProvider>
      <div className="flex flex-row justify-end">
        <Button
          loading={loading}
          disabled={loading}
          color="black"
          type="submit"
          className="self-end"
          size="small"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
