import { useState } from 'react';
import { useRouter } from 'next/router';
import { useJitsu } from '@jitsu/nextjs';
import { BundleItem } from 'components/Products/Bundles/BundleItem';

import { Features } from './Features';
import { Bundles } from './Bundles/Bundles';
import type { Bundle } from './Products';

export type ProductsCartProps = {
  bundles: Array<Bundle>;
  selectedId: string;
};

export function ProductsCart({
  bundles,
  selectedId,
}: ProductsCartProps): JSX.Element {
  const { query } = useRouter();
  const router = useRouter();
  const { track } = useJitsu();
  const selected = bundles.find((i) => i.id === selectedId);
  const [plan, setPlan] = useState(bundles[1]);
  return (
    <div className="my-16 space-y-16">
      <div className="isolate mx-auto grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:mx-0 lg:grid-cols-2">
        <div>
          <BundleItem
            selected={false}
            id="standard"
            price={99}
            title="Standard"
            subtitle="Job Listing"
            description="Our regular listing type. Default priority in search results."
            checkout={true}
            onClick={() => {
              router.push({
                pathname: '/portal/[employerId]/job/post',
                query,
              });
              track('package_register', {
                id: 'standard',
                price: 99,
                title: 'Standard',
              });
            }}
          />
        </div>
        <div>
          <BundleItem
            selected={false}
            id="featured"
            price={189}
            title="Featured"
            subtitle="Job Listing"
            description="Higher priority in search results, stand-out listing."
            checkout={true}
            onClick={() => {
              router.push({
                pathname: '/portal/[employerId]/job/post',
                query,
              });
              track('package_register', {
                id: 'featured',
                price: 189,
                title: 'Featured',
              });
            }}
          />
        </div>
      </div>
      <Bundles
        bundles={bundles
          .map((a) => {
            return {
              checkout: true,
              ...a,
            };
          })
          .sort((a, b) => a.price - b.price)}
        selected={selectedId}
      />
      <div className="">
        {selected && (
          <Features {...plan} plan={plan} setPlan={setPlan} bundles={bundles} />
        )}
      </div>
    </div>
  );
}
