import { ApplicationProps } from 'components/Application/Application';
import {
  QuestionBooleanProps,
  QuestionStringProps,
} from 'components/Application/questions';
import { Application, Questions_And_Answers } from 'database/types';

export type QuestionWithAnswer = QuestionBooleanProps | QuestionStringProps;

export const isBooleanQuestion = (
  question: QuestionWithAnswer
): question is QuestionBooleanProps => typeof question.answer !== 'string';

export const mapQuestion = ({
  answers,
  question,
  type,
}: Questions_And_Answers): QuestionWithAnswer => ({
  question: question as string,
  answer: type === 'boolean' ? (answers as boolean) : (answers as string),
});

export const mapApplication = ({
  questions,
  ...application
}: NonNullable<Application>): Omit<ApplicationProps, 'role'> => ({
  ...application,
  questions: questions ? questions.map(mapQuestion) : undefined,
});
