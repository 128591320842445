import { useFormContext } from 'react-hook-form';

type Props = {
  onCancel: () => void;
};

export function ModalFormButtons({ onCancel }: Props): JSX.Element {
  const { formState } = useFormContext();
  return (
    <div className="flex flex-col justify-start space-y-2 self-end sm:flex-row-reverse sm:space-x-2 sm:space-y-0 sm:space-x-reverse">
      <button
        className="h-10 rounded-lg border border-solid border-orange-200 bg-orange-500 px-8"
        type="submit"
        disabled={!formState.isDirty || formState.isSubmitting}
      >
        <span className="font-satoshi text-lg font-medium text-white">
          {formState.isSubmitting ? 'Loading' : 'Save'}
        </span>
      </button>
      <button
        className="h-10 rounded-lg border border-solid border-orange-500 bg-white px-8"
        onClick={onCancel}
      >
        <span className="font-satoshi text-lg font-medium text-orange-500">
          Cancel
        </span>
      </button>
    </div>
  );
}
