import { Dropdown } from 'components';
import { Item } from 'components/Dropdown/Item';

type ApplicationsMenuProps = { id: string; employerId: string; jobId: string };

export const ApplicationsMenu = ({
  id,
  employerId,
  jobId,
}: ApplicationsMenuProps): JSX.Element => {
  return (
    <Dropdown>
      <Item
        title="View"
        href={{
          pathname:
            '/portal/[employerId]/job/[jobId]/application/[applicationId]',
          query: { applicationId: id, employerId, jobId },
        }}
      />
    </Dropdown>
  );
};
