import { twMerge } from 'tailwind-merge';
import { VFC } from 'react';

type CartLineProps = {
  alt?: boolean;
  discount?: number | null;
  title: string;
  subtitle?: string | null;
  price: number | null;
};

export const CartLine: VFC<CartLineProps> = ({
  alt = false,
  discount,
  title,
  subtitle,
  price,
}) => {
  const p = price ?? 0;
  const d = discount ?? 0;
  return (
    <span
      className={twMerge(
        'flex flex-row items-center justify-between text-sm',
        alt ? 'font-normal text-grey' : 'font-medium text-black'
      )}
    >
      <div className="grow">
        {title}
        {subtitle && (
          <>
            <br />
            <span className="text-xs font-light text-grey">{subtitle}</span>
          </>
        )}
      </div>
      <div className="flex flex-col text-right">
        <span
          className={twMerge(
            discount ? 'text-gray-500 line-through' : 'text-black'
          )}
        >
          ${(p / 100).toFixed(2)}
        </span>
        {d > 0 && (
          <>
            <br />${((p - d) / 100).toFixed(2)}
          </>
        )}
      </div>
    </span>
  );
};
