import {
  FetchResult,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { useMessages } from 'components';

type OnError = {
  message?: string;
  callback?: () => void;
};

export type OnSuccess<
  TData extends Record<string, any> = Record<string, any>,
  TVariables extends OperationVariables = OperationVariables,
> = {
  message?: string;
  callback?: (
    data: FetchResult<TData>,
    variables: TVariables
  ) => void | Promise<void>;
};

export type FormSubmitParams<
  TVariables extends OperationVariables = OperationVariables,
  TData extends Record<string, any> = Record<string, any>,
> = {
  formName: string;
  mutation: TypedDocumentNode<TData, TVariables>;
  options?: MutationHookOptions<TData, TVariables>;
  onSuccess?: OnSuccess<TData, TVariables>;
  onError?: OnError;
  debug?: boolean;
};

type submitFn<
  TVariables extends OperationVariables = OperationVariables,
  TData extends Record<string, any> = Record<string, any>,
> = (
  vars: TVariables,
  postProcess?: OnSuccess<TData, TVariables>['callback']
) => Promise<void>;

export const useFormSubmit = <
  TVariables extends OperationVariables = OperationVariables,
  TData extends Record<string, any> = Record<string, any>,
>({
  formName,
  mutation,
  options,
  onSuccess,
  onError,
  debug = false,
}: FormSubmitParams<TVariables, TData>): submitFn<TVariables, TData> => {
  const { alert } = useMessages();
  const [generateMutation] = useMutation<TData, TVariables>(mutation, options);

  const handleSubmit: submitFn<TVariables, TData> = async (
    variables,
    postProcess,
    refetchQueries = []
  ) => {
    if (debug) {
      console.group('handleSubmit DEBUG');
      console.log('values::', variables);
    }
    try {
      const response = await generateMutation({
        variables,
        refetchQueries,
      });
      debug && console.log('response::', response);
      if (onSuccess?.message) {
        alert({
          key: `${formName}`,
          type: 'success',
          title: onSuccess?.message,
          dismissable: true,
          duration: 3000,
        });
      }
      onSuccess?.callback?.(response, variables);
      postProcess?.(response, variables);
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: `${formName}`,
          type: 'error',
          title: `${onError?.message ?? err?.message}`,
          dismissable: true,
          duration: 10000,
        });
      }
      onError?.callback?.();
      captureException(err);
    } finally {
      debug && console.groupEnd();
    }
  };
  return handleSubmit;
};
