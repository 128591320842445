// Framework
import { SVGProps } from 'react';

// Types
type CloseIconProps = SVGProps<SVGSVGElement> & {
  color?: string;
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

const CloseIcon = ({
  color = 'black',
  opacity = 0.24,
  strokeWidth = 1.4,
  ..._rest
}: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {..._rest}
  >
    <g opacity={opacity}>
      <path
        d="M17 7L7 17M7 7L17 17"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CloseIcon;
