import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeZone from 'dayjs/plugin/timezone';
import { Maybe } from 'database/types';
export type HandleListedProps = {
  updated_at?: string;
  list_date?: Maybe<string>;
  created_at: string;
};
export type getMockedJobcardProps = {
  isNew?: boolean;
  tagline?: Maybe<string>;
  highlights?: string[];
  updated_at: string;
  featured?: boolean;
  config: {
    mockIsNew?: boolean;
    mockTagline?: boolean;
    mockHighlights?: boolean;
    mockUpdated_at?: boolean;
    mockFeatured?: boolean;
  };
};

dayjs.extend(relativeTime);
dayjs.extend(timeZone);
export const customLocale = {
  ...dayjs.Ls.en,
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
};
dayjs.locale(customLocale, undefined, true);

/**
 * Calculates the listed date for a job card.
 *
 * @param {HandleListedProps} - The parameters for calculating the listed date.
 * @returns {Date} - The calculated listed date.
 */
export const handleListed = ({
  updated_at,
  list_date,
  created_at,
}: HandleListedProps) => {
  const updated = dayjs(updated_at ?? created_at);
  const listed = dayjs(list_date)
    .set('hours', updated.get('hours'))
    .set('minutes', updated.get('minutes'))
    .set('seconds', updated.get('seconds'));

  return listed;
};

/**
 * Generates a mocked job card object.
 *
 * @param {HandleListedProps} - The parameters for generating the mocked job card.
 * @returns {object} - The mocked job card object.
 */
export const getMockedJobcard = ({
  isNew,
  tagline,
  highlights,
  updated_at,
  config,
  featured,
}: getMockedJobcardProps): object => {
  const {
    mockIsNew = false,
    mockTagline = false,
    mockHighlights = false,
    mockUpdated_at = false,
    mockFeatured = false,
  } = config;

  const mockedJobcard = {
    isNew: mockIsNew ? true : isNew,
    tagline: mockTagline
      ? 'Our Wellington depot are looking for an experienced Driver Operator / Labourer to join their team!'
      : tagline,
    highlights: mockHighlights
      ? ['Mocked Highlight 1', 'Mocked Highlight 2']
      : highlights,
    updated_at: mockUpdated_at ? '2022-01-01' : updated_at,
    featured: mockFeatured ? true : featured,
  };

  return mockedJobcard;
};
