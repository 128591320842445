import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useFieldError, Errors } from '..';
import { InputLabel } from './InputLabel';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: ReactNode;
  name?: string;
  noValidation?: boolean;
  subtitle?: string;
};

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export const Input = forwardRef<HTMLInputElement, InputProps>(function InputRef(
  {
    autoCapitalize,
    className,
    disabled,
    label,
    name,
    noValidation,
    onChange,
    onBlur,
    placeholder,
    subtitle,
    required,
    type = 'text',
    value,
  },
  ref
) {
  const { error } = useFieldError(name);
  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <InputLabel
        label={label}
        name={name}
        required={required}
        subtitle={subtitle}
      />
      <input
        ref={ref}
        type={type}
        value={value}
        className={twMerge(
          'block rounded border border-light py-4 pl-6 pr-10 text-base text-black shadow-sm placeholder:text-grey focus:border-orange focus:ring-1 focus:ring-orange',
          type === 'date' ? 'w-auto' : 'w-full',
          error && 'border-red-500 focus:border-red-700 focus:ring-red-500',
          disabled && 'cursor-not-allowed bg-gray-200'
        )}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        autoCapitalize={autoCapitalize}
      />
      {!noValidation && <Errors error={error} />}
    </div>
  );
});
