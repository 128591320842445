import { useRouter } from 'next/router';
import { Link } from 'components';

export const PopupMenu = () => {
  const router = useRouter();
  const { pathname, query } = router;

  return (
    <div className="mx-auto px-6 py-5 sm:px-8">
      <div>
        <nav className="text-charcoal-500">
          <Link
            href="/"
            className="mb-6 block text-xl leading-[30px] underline decoration-orange-500 decoration-2 underline-offset-4"
          >
            Home
          </Link>
          <Link href="/portal" className="mb-6 block text-xl leading-[30px]">
            Employers
          </Link>
        </nav>
        <div className="mb-12 mt-24 h-px w-full bg-charcoal-50" />
        <div className="space-y-2 font-satoshi text-lg font-medium leading-[27px]">
          <Link
            href={{
              pathname,
              query,
              hash: 'login',
            }}
            className="mb-6 flex h-10 w-full items-center justify-center text-orange-500"
          >
            Login
          </Link>
          <Link
            href="/register"
            className="flex h-10 w-full items-center justify-center rounded-lg bg-orange-500 text-white"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};
