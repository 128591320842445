// Framework
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router'; // Added import for useRouter

// Third Parties
import { useToggle } from 'rooks';

// Components
import { twMerge } from 'tailwind-merge';
import { NavbarProps, LogoEmployerHorizontal } from 'components';
import { EmployerButton } from 'components/selfService';
import { useAuthContext } from 'components/AuthContext';
import HamburguerButton from './HamburguerButton';

// Preps
const NavLink = ({
  active,
  href,
  children,
}: {
  href: LinkProps['href'];
  children: string;
  active?: boolean;
}) => (
  <li
    className={
      active
        ? 'w-max border-b-2 border-solid border-orange text-charcoal-500'
        : ''
    }
  >
    <Link href={href}>{children}</Link>
  </li>
);

const AuthButtons = ({ isMobile }: { isMobile?: boolean }) => {
  const { user } = useAuthContext();
  const isSignedIn = !!user;
  return (
    <>
      <Link href={isSignedIn ? '/portal/jobs' : '#login'} passHref>
        <EmployerButton
          className={isMobile ? 'block w-full' : 'hidden md:block'}
          variant="secondary"
        >
          {isSignedIn ? 'Employer Portal' : 'Login'}
        </EmployerButton>
      </Link>
      {!isSignedIn && (
        <Link href="/portal/register" passHref>
          <EmployerButton className={isMobile ? 'w-full' : ''}>
            Register
          </EmployerButton>
        </Link>
      )}
      <div className="hidden h-8 w-1 self-center border-l border-[#1C1B19] md:flex"></div>
      <Link
        href={`${process.env.NEXT_PUBLIC_SITE_URL}`}
        className={
          isMobile ? 'block w-full text-center' : 'hidden self-center md:block'
        }
      >
        Main site
      </Link>
    </>
  );
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const NewNavbarEmployer: React.FC<NavbarProps> = () => {
  const [open, toggle] = useToggle();
  const router = useRouter(); // Added useRouter hook

  return (
    <nav
      className={
        'fixed inset-0 z-40 flex h-max w-full flex-wrap items-center justify-between bg-cream-500 p-4  drop-shadow-sm md:flex-nowrap lg:px-16 2xl:px-24'
      }
    >
      <div className="order-1 w-1/2 md:w-auto">
        <Link href="/portal">
          <LogoEmployerHorizontal />
        </Link>
      </div>
      <div
        className={twMerge(
          'order-3 w-full overflow-hidden transition-all duration-500 ease-out md:order-2 md:w-auto',
          open ? 'max-h-screen' : 'max-h-0 md:max-h-screen'
        )}
      >
        <ul className="flex w-max flex-col gap-6 py-6 leading-6 text-charcoal-200 md:h-6 md:w-max md:flex-row md:py-0">
          <NavLink href="/" active={!router.pathname.includes('/pricing')}>
            Home
          </NavLink>
          <NavLink
            href="/portal/pricing"
            active={router.pathname.includes('/pricing')}
          >
            Pricing
          </NavLink>
          <NavLink href="#__contact_employer">Contact our team</NavLink>
        </ul>
        <div className="my-4 block h-px w-full bg-charcoal-50 md:hidden" />
        <div className="flex w-full flex-col space-y-4 md:hidden">
          <AuthButtons isMobile />
        </div>
      </div>
      <div className="order-2 flex w-1/2 justify-end gap-6 text-sm font-normal leading-5 md:order-3 md:w-auto md:gap-4">
        <AuthButtons />
        <button
          aria-label="open mobile menu"
          className="flex items-center justify-center md:hidden"
          onClick={toggle}
        >
          <HamburguerButton active={!open} />
        </button>
      </div>
    </nav>
  );
};
