import { SVGAttributes } from 'react';

type ToolsIconProps = SVGAttributes<SVGSVGElement>;

const PencilIcon = (props: ToolsIconProps) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 7.50023L9.50002 4.50023M0.875 16.1252L3.41328 15.8432C3.72339 15.8087 3.87845 15.7915 4.02338 15.7446C4.15197 15.703 4.27434 15.6442 4.38717 15.5698C4.51434 15.4859 4.62466 15.3756 4.84529 15.1549L14.75 5.25023C15.5784 4.4218 15.5784 3.07865 14.75 2.25023C13.9216 1.4218 12.5784 1.4218 11.75 2.25023L1.8453 12.1549C1.62466 12.3756 1.51434 12.4859 1.43048 12.6131C1.35607 12.7259 1.29726 12.8483 1.25564 12.9768C1.20872 13.1218 1.19149 13.2768 1.15703 13.587L0.875 16.1252Z"
      stroke="#F17732"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilIcon;
