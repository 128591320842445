import { Dialog, Transition } from '@headlessui/react';
import { NextRouter } from 'next/router';
import { FC, Fragment, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Login, LoginEmployer } from 'components/forms';
import { HandleModalVersion } from 'components/AuthContext/HandleModalVersion';

type AuthModalProps = {
  open: boolean;
  router: NextRouter;
  type: 'employer' | 'employee';
};

export const AuthModal: FC<AuthModalProps> = ({ open, router, type }) => {
  useEffect(() => {
    (async () => {
      try {
        // Attempt to refresh session
        await fetchAuthSession();
      } catch (e) {
        // This error is expected and therefore will be ignored from logging
        // captureException(e);
      }
    })();
  }, []);
  const onCancel =
    (isEmployer = false) =>
    () => {
      const { asPath } = router;
      const [path, hash] = asPath.split('#');
      if (hash && hash === 'login') {
        router.replace(path);
      } else {
        router.replace(isEmployer ? '/portal' : '');
      }
    };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={onCancel(type === 'employer')}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HandleModalVersion version={type === 'employer' ? 'v1' : 'v2'}>
              {(() => {
                switch (type) {
                  case 'employee':
                    return <Login onCancel={onCancel(false)} />;
                  case 'employer':
                    return <LoginEmployer onCancel={onCancel(true)} />;
                }
              })()}
            </HandleModalVersion>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
