import { SVGAttributes } from 'react';

const DefaultCheckMark = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.166748"
      y="0.666748"
      width="24.6667"
      height="24.6667"
      rx="12.3333"
      fill="#F17732"
    />
    <path
      d="M18.7223 8.3335L10.1667 16.889L6.27783 13.0002"
      stroke="#F9F8F5"
      strokeWidth="1.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DefaultCheckMark;
