// Framework
import { useEffect, useState, useRef } from 'react';

// Components
import { useIntervalWhen } from 'rooks';

// Types
type InfiniteHorizontalScrollProps = {
  children: React.ReactNode[];
  speed?: number;
  className?: string;
};
/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const InfiniteHorizontalScroll = ({
  children,
  speed = 300,
  className,
}: InfiniteHorizontalScrollProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [screensFilledScreen, setSlidesFilledScreen] = useState(false);
  const [biggestSlideWidth, setBiggestSlideWidth] = useState(0);
  const [position, setPosition] = useState(0);
  const [dynamicChildren, setDynamicChildren] = useState<
    null | typeof children
  >(null);
  const container = useRef<HTMLDivElement | null>(null);

  const updatePosition = () => {
    if (container.current) {
      setPosition(position + 10);
      container.current.style.transform = `translateX(-${position}px)`;
    }
  };

  const loadNewSlides = () => {
    if (container.current && dynamicChildren) {
      setDynamicChildren([...dynamicChildren, ...children]);
    }
  };

  useEffect(() => {
    if (container.current) {
      setDynamicChildren(children);
    }
  }, []);

  /*
   * This useEffect is to check if the slides filled the screen
   * If they didn't, we load more until they do
   */
  useEffect(() => {
    if (container.current && !screensFilledScreen) {
      const w = container.current.clientWidth;
      const screenW = window.innerWidth;
      if (w < screenW) {
        loadNewSlides();
      } else {
        setSlidesFilledScreen(true);
        const allChidren = Array.from(container.current.children);
        let maxWidth = 0;
        allChidren.forEach((child) => {
          if (child.clientWidth > maxWidth) {
            maxWidth = child.clientWidth;
          }
        });
        setBiggestSlideWidth(maxWidth);
      }
    }
  }, [dynamicChildren]);

  /*
   * This useEffect is to check if the position is almost at the end,
   * loading more slides, if necessary
   */
  useEffect(() => {
    if (container.current && screensFilledScreen) {
      const limit =
        container.current?.clientWidth - window.innerWidth - position;
      const shouldLoadMore = limit < biggestSlideWidth;

      if (shouldLoadMore) {
        loadNewSlides();
      }
    }
  }, [position]);

  useIntervalWhen(
    () => {
      if (!isHovered) {
        updatePosition();
      }
    },
    speed,
    !!container.current
  );

  return (
    <div className={className} ref={container}>
      {dynamicChildren &&
        dynamicChildren.map((item, index) => (
          <div
            key={index}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {item}
          </div>
        ))}
    </div>
  );
};
