import { captureException } from '@sentry/nextjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';
import { useRouter } from 'next/router';
import { FC, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { makeSubdomainLink } from 'utils/makeSubdomainLink';
import getDBClient from 'database/DBClient';
import { usePersistentState } from 'utils';
import CloseIcon from 'components/forms/SelectCloud/CloseIcon';
import { UpdatedSocialButton } from 'components/shared/UpdatedSocialButton';
import { UpdatedInput } from 'components/forms/UpdatedFormInput';
import { ErrorProvider } from '..';
import { Alert, Divider, Link, Logo } from '../..';
import Validation from './Validation';

export type LoginData = {
  username: string;
  password: string;
};

export type LoginProps = {
  onCancel?: () => void;
};

export const Login: FC<LoginProps> = ({ onCancel }) => {
  const [unAuthKey, setUnauthKey] = usePersistentState<string>(
    '__unauth_search_key'
  );
  const router = useRouter();
  const { pathname, query } = router;

  const [error, setError] = useState<ReactNode>('');

  const { formState, handleSubmit, register } = useForm<LoginData>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(Validation),
  });

  async function onSubmit({ username, password }: LoginData) {
    try {
      //cleanup
      localStorage.clear();
      await getDBClient().clearStore();
      setError('');
      unAuthKey && setUnauthKey(unAuthKey);

      try {
        const session = await fetchAuthSession();
        if (session) await signOut();
      } catch {
        console.log('session reset did catch');
      }

      //sign in
      await signIn({ username, password });
      const authSession = await fetchAuthSession();
      const accessToken = authSession.tokens?.accessToken.payload;
      const isEmployer = !!(accessToken as any)['cognito:groups']?.includes(
        'employer-portal'
      );
      if (isEmployer) {
        await signOut();
        isEmployer &&
          setError(
            <span>
              Employers please log in through the
              <Link
                className="ml-1 text-primary underline"
                href={makeSubdomainLink('/portal')}
              >
                Employer portal
              </Link>
            </span>
          );
        return;
      }
      router.replace(
        {
          pathname,
          query,
        },
        undefined,
        {
          shallow: true,
        }
      );
    } catch (error: any) {
      setError(error.message);
      captureException(error);
    }
  }

  return (
    <div className="relative z-0 font-satoshi">
      {error && <Alert type="error" title={error} />}
      {onCancel && (
        <button onClick={onCancel} className="absolute right-0 top-0 z-0">
          <CloseIcon strokeWidth={2} />
        </button>
      )}
      <Logo
        color="black"
        className="mx-auto mb-[13.5px] flex h-[29px] w-auto items-center justify-center"
      />
      <div className="text-center">
        <p className="mb-1 text-[32px] font-bold leading-[43.2px] tracking-[-0.01em] text-night-rider">
          Log in to continue
        </p>
        <p className="mb-8 text-sm font-medium leading-[19.6px] text-charcoal-500 opacity-60">
          Welcome back! login or sign up to continue
        </p>
      </div>
      <ErrorProvider errors={formState.errors}>
        <form id="login" onSubmit={handleSubmit(onSubmit)} className="mb-1.5">
          <UpdatedInput
            {...register('username')}
            required
            autoCapitalize="false"
            label="Email address*"
            placeholder="Email address"
            className="mb-4"
            customLabel="font-bold text-sm text-charcoal-500 mb-1.5"
          />
          <UpdatedInput
            {...register('password')}
            required
            autoCorrect="false"
            autoCapitalize="false"
            type="password"
            label="Password*"
            placeholder="Password"
            className="mb-4"
            customLabel="font-bold text-sm text-charcoal-500 mb-1.5"
          />
          <Link
            href="/auth/forgot"
            className="relative -top-3 mr-auto block text-right text-sm font-medium text-orange-500 underline"
          >
            Forgot password
          </Link>
          <div className="my-4 flex flex-wrap justify-center gap-4">
            <button
              className={twMerge(
                'bg-orange-500 w-full rounded-lg h-11',
                formState.isSubmitting && 'opacity-50 cursor-not-allowed'
              )}
              type="submit"
            >
              <span className="text-lg font-medium leading-[24.3px] text-white">
                Log in
              </span>
            </button>
          </div>
        </form>
      </ErrorProvider>
      <>
        <Divider className="my-6 bg-transparent" bg="bg-cream-500">
          OR
        </Divider>
        <div className="my-6 grid grid-cols-1 justify-center gap-3">
          <UpdatedSocialButton variant="google" />
          <UpdatedSocialButton variant="facebook" />
        </div>
      </>
      <div className="block w-full text-center text-sm font-bold text-charcoal-500">
        Don't have an account?{' '}
        <Link href="/register" className="text-primary-500 underline">
          Sign up
        </Link>
      </div>
    </div>
  );
};
