// Framework
import { VFC } from 'react';
import { twMerge } from 'tailwind-merge';

// Database & Types
import { Employer, Enum_Category, Enum_Work_Type, Job } from 'database/types';
import { LocationObject } from 'utils';

// Components
import { makeLocationString } from 'components/JobListing/helpers';
import { handleListed } from 'components/JobCard/helpers';
import FeaturedTopBorder from 'components/JobCard/FeaturedTopBorder';
import TopDateTag from 'components/JobCard/TopDateTag';
import DescriptionHandle from 'components/JobCard/DescriptionHandle';
import HeaderData from 'components/JobCard/HeaderData';
import { SaveButton } from './SaveButton';

// Hooks
import { useJobViewTrack } from 'utils/hooks/useJobViewTrack';

export type JobCardProps = Pick<
  Job,
  | 'id'
  | 'title'
  | 'list_date'
  | 'expiry_date'
  | 'created_at'
  | 'tagline'
  | 'listing_type'
  | 'updated_at'
  | 'slug'
> & {
  category: Pick<Enum_Category, 'label'>;
  className?: string;
  work_type: Pick<Enum_Work_Type, 'label'>;
  employer?: Pick<Employer, 'name' | 'logo' | 'description' | 'slug'> | null;
  dashboard?: boolean;
  location?: LocationObject | null;
  requirements?: string[];
  isNew?: boolean;
  highlights?: string[];
  clicked?: boolean;
  selected?: boolean;
  demo?: boolean;
};

export const JobCard: VFC<JobCardProps> = ({
  id,
  category,
  className = '',
  created_at,
  dashboard,
  employer,
  listing_type,
  highlights,
  isNew,
  list_date,
  location,
  selected = false,
  slug,
  tagline,
  title,
  updated_at,
  work_type,
  demo,
}) => {
  const { logo, name: company } = employer ?? {};
  const featured = listing_type === 'featured';
  const basic = listing_type === 'basic';
  const locationString = makeLocationString(location ?? {});
  const listed = handleListed({
    updated_at,
    list_date,
    created_at,
  });
  const regular = !selected && !featured;

  useJobViewTrack({ id, listing_type });

  return (
    <div
      className={twMerge(
        'break-words rounded-lg py-4 px-5 font-satoshi',
        'border border-solid bg-cream-500 relative z-0 overflow-hidden font-medium',
        selected && 'ring-1 ring-inset ring-orange',
        featured && 'border-orange-200',
        regular && 'border-charcoal-50',
        className
      )}
    >
      <FeaturedTopBorder featured={featured} />
      <div className="divide-y divide-black">
        <div className="pb-4 md:pb-6">
          <TopDateTag
            featured={featured}
            isNew={isNew}
            listed={listed}
            slug={slug}
            id={id}
            dashboard={dashboard}
          />
          <div className="flex justify-between sm:items-center">
            <div className="flex-1">
              <h3 className="max-w-[290px] truncate text-[18px] font-bold text-charcoal-500 sm:text-[26px] md:max-w-[520px]">
                {title}
              </h3>
            </div>
          </div>
          <HeaderData
            company={company}
            locationString={locationString}
            category={category}
            work_type={work_type}
          />
        </div>
        <div className="py-4 md:py-6">
          <DescriptionHandle tagline={tagline} highlights={highlights} />
          <div className="mt-4">
            <div className="flex items-end justify-between space-x-4">
              <SaveButton job_id={demo ? undefined : (id as any)} />
              {!basic && logo && (
                <img
                  className="max-h-12 max-w-[120px] object-scale-down"
                  src={logo}
                  alt="Company Logo"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
