import { CreditCardIcon } from '@heroicons/react/24/solid';
import { VFC } from 'react';
import Stripe from 'stripe';
import { RadioOption } from 'components/forms';
import { Pill } from 'components/Pill';
import { MasterCardIcon, VisaIcon } from 'components/CreditCards/Icons';

type ExistingCardProps = {
  id: string;
  card: Stripe.PaymentMethod.Card;
  isDefault?: boolean;
};

export const ExistingCard: VFC<ExistingCardProps> = ({
  id,
  card: { last4, exp_month, exp_year, brand },
  isDefault,
}) => (
  <RadioOption
    value={id}
    label={
      <div className="!-my-1 flex h-7 cursor-pointer flex-nowrap items-center justify-between pl-2">
        <div className="inline-flex flex-nowrap items-center">
          {brand === 'mastercard' ? (
            <MasterCardIcon className="mr-3 w-5" />
          ) : brand === 'visa' ? (
            <VisaIcon className="mr-3 w-5" />
          ) : (
            <CreditCardIcon className="mr-2 w-6" />
          )}
          <div>
            <span className="hidden sm:inline">**** ****</span> **** {last4}
            {isDefault && (
              <Pill className="ml-3 !py-1 text-black">Default</Pill>
            )}
          </div>
        </div>
        <div className="inline-flex flex-nowrap items-center">
          <div className="px-4">
            {exp_month.toLocaleString('en-NZ', {
              minimumIntegerDigits: 2,
            })}
            /
            {(exp_year % 100).toLocaleString('en-NZ', {
              minimumIntegerDigits: 2,
            })}
          </div>
        </div>
      </div>
    }
    className="w-full"
  />
);
