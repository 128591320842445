import { ReactElement, VFC } from 'react';
import { twMerge } from 'tailwind-merge';
import { TableCell, TableCellProps } from 'components/Table/Cell';

export type TableRowProps = {
  data?: TableCellProps['children'][];
  loading?: boolean;
  menuElement?: ReactElement;
  trProps?: JSX.IntrinsicElements['tr'];
};

export const TableRow: VFC<TableRowProps> = ({
  data,
  loading,
  menuElement,
  trProps,
}) => (
  <tr
    className={twMerge(
      'rounded bg-white focus-within:bg-gray-50 hover:bg-gray-50',
      !!trProps?.onClick && 'hover:cursor-pointer'
    )}
    tabIndex={0}
    {...trProps}
  >
    {data?.map((cell, i) => (
      <TableCell key={i} loading={loading}>
        {cell}
      </TableCell>
    ))}
    {menuElement && (
      <TableCell
        key="menu"
        loading={loading}
        className="flex w-full justify-end !overflow-visible"
      >
        {menuElement}
      </TableCell>
    )}
  </tr>
);
