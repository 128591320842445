import { useHubspotSubscriptions } from 'utils/hubspot/useHubspotSubscriptions';
import { RadioGroupBoolean as RadioGroup } from './RadioGroupBoolean';

export type UserNotificationFormData = {
  notifications: boolean;
};

export function UserNotifications(): JSX.Element {
  const { loading, subscribed, update } = useHubspotSubscriptions();
  return (
    <div className="my-6">
      <RadioGroup
        label="Allow notifications from Trade Jobs NZ"
        value={subscribed}
        disabled={loading}
        loading={loading}
        onChange={update}
      />
    </div>
  );
}
