import { FC, PropsWithChildren } from 'react';
import { FallbackLoader, LayoutProps, Navbar, PageMeta } from 'components';

export const LayoutEmployee: FC<PropsWithChildren<LayoutProps>> = ({
  navbar = true,
  children,
  meta,
  headerPosition,
}) => (
  <>
    <PageMeta {...meta} />
    <div className="flex min-h-screen flex-col overflow-y-hidden">
      {navbar && <Navbar headerPosition={headerPosition} />}

      <main className="relative z-0 flex flex-1 flex-col justify-start">
        <FallbackLoader>{children}</FallbackLoader>
      </main>
    </div>
  </>
);
