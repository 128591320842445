import { FC, useEffect } from 'react';
import {
  UpdatedMonthPicker,
  UpdatedMonthPickerProps,
} from 'components/forms/UpdatedMonthPicker';
import { LicenceData } from '.';

type Props = {
  expires?: boolean;
  resetField: (a: keyof LicenceData) => void;
} & UpdatedMonthPickerProps;

// Make sure we don't remove the name prop from the rest of our properties
export const CustomInputDate: FC<Props> = ({
  expires,
  resetField,
  ...props
}) => {
  useEffect(() => {
    !expires && resetField(props?.name as keyof LicenceData);
  }, [expires, resetField, props?.name]);

  return expires ? <UpdatedMonthPicker {...props} /> : null;
};
