import { SVGAttributes } from 'react';

type CheckMarkProps = SVGAttributes<SVGSVGElement>;

const CheckMark = ({ stroke, strokeWidth, ..._props }: CheckMarkProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
      {..._props}
    >
      <path
        d="M15.5558 5.33301L7.00022 13.8886L3.11133 9.99967"
        stroke={stroke || '#F9F8F5'}
        strokeWidth={strokeWidth || '1.55556'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMark;
