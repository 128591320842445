import React, { VFC } from 'react';
import { Popover } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import { NavbarItemProps } from 'components/Navbar/Navbar';
import { Link, Logo } from 'components';
import { NavLink } from 'components/Navbar/NavLink';
import { UserMenu } from 'components/Navbar/UserMenu';

export const DesktopNav: VFC<NavbarItemProps> = ({
  authenticated,
  className,
}) => {
  const { pathname, query } = useRouter();
  return (
    <header
      className={twMerge(
        'relative w-full bg-black font-satoshi font-medium',
        className
      )}
    >
      <div className="mx-auto flex items-center justify-between space-x-4 px-8 py-4 xl:px-16">
        {/* Logo */}
        <Link className="h-9" href="/">
          <span className="sr-only">Home Page</span>
          <Logo color="black" />
        </Link>
        {/* Desktop items */}

        <Popover.Group as="nav" className="flex items-center space-x-8">
          {!authenticated && (
            <NavLink
              className="text-night-rider lg:text-base"
              href={{ pathname, query, hash: 'login' }}
              hover={false}
            >
              Log in
            </NavLink>
          )}
          {authenticated ? (
            <UserMenu type="employee" />
          ) : (
            <>
              <NavLink
                className="rounded-lg bg-orange px-6 py-1.5 lg:text-base"
                href="/register"
                hover={false}
              >
                Sign Up
              </NavLink>
              <NavLink
                hover={false}
                className="text-night-rider lg:text-base"
                href="/portal"
              >
                Employer site
              </NavLink>
            </>
          )}
        </Popover.Group>
      </div>
    </header>
  );
};
