import { useEffect, useState } from 'react';
import { NextRouter } from 'next/router';

const allowedLocal = ['localhost:3000', 'localhost:3001'];
// const domainRegex =
//   /^(www.|employers.)?(tradejobs(?:nz)?(\.co)?\.nz)|employers\.tradejobsnz\.co\.nz|(?:tradejobs-frontend-)(?:[\w-]*)(?:(-whileandfor.vercel.app))/g;

export const useCheckHost = (router: NextRouter): void => {
  const [ssr, setSSR] = useState<boolean>(true);

  useEffect(() => setSSR(false), []);

  useEffect(() => {
    if (ssr) return;
    const host = window.location.host;
    const isDev = host && allowedLocal.includes(host);
    if (isDev) return;
    // if (!domainRegex.test(host)) {
    //   window.location.replace(
    //     'https://www.google.com/safebrowsing/static/faq.html#q1'
    //   );
    // }
  }, [ssr]);

  // SUBDOMAIN REDIRECTION
  // this effect handles internal routing
  // redirects from external navigation are handled in next.config.js

  useEffect(() => {
    if (ssr) return;
    const origin = window.location.origin;
    const subdomain = process.env.NEXT_PUBLIC_EMPLOYERS_SUBDOMAIN;

    // optionally disabled for localhost or other test envs
    if (!subdomain || subdomain.includes(origin)) return;

    // portal access from domains other than the specified will be redirected
    const handler = (path: string) => {
      if (!path.startsWith('/portal')) return;

      window?.location?.replace(`${subdomain}${path}`);
    };

    router.events.on('routeChangeStart', handler);
    return () => router.events.off('routeChangeStart', handler);
  }, [ssr]);
};
