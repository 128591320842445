// Types
import { type FC, type PropsWithChildren } from 'react';

// Components
import { twMerge } from 'tailwind-merge';
import { LayoutProps, NewNavbarEmployer } from 'components';
import { FallbackLoader } from 'components/FallbackLoader';
import { FooterEmployer } from 'components/Footer';
import { PageMeta } from 'components/PageMeta';
import { EmployerContextProvider } from 'components/pages/PortalEmployer';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const NewLayoutEmployer: FC<PropsWithChildren<LayoutProps>> = ({
  navbar = true,
  footer = true,
  children,
  meta,
  className,
}) => {
  return (
    <EmployerContextProvider>
      <PageMeta {...meta} />
      <div
        className={twMerge(
          'relative z-0 flex min-h-screen flex-col bg-cream-500 font-satoshi pt-[68px] lg:h-screen',
          className
        )}
      >
        {navbar && <NewNavbarEmployer />}
        <main className="relative flex grow flex-col justify-start p-8 md:p-12 lg:p-24 bg-cream-500">
          <FallbackLoader>{children}</FallbackLoader>
        </main>
        {footer && <FooterEmployer />}
      </div>
    </EmployerContextProvider>
  );
};
