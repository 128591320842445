import gql from 'graphql-tag';
import { CMSData, CMSHalfImage } from 'utils/cmsData';
import CMSClient from 'utils/CMSClient';

type HomePageProps = {
  weekjobs: CMSHalfImage[];
};

async function JobsOfTheWeek() {
  const result = await CMSClient(undefined).query<CMSData<HomePageProps>>({
    query: gql`
      query HomePage {
        entries(title: "home") {
          ... on home_home_Entry {
            weekjobs {
              ... on weekjobs_jobs_BlockType {
                job_url
                image @transform(handle: "small") {
                  url
                }
              }
            }
          }
        }
      }
    `,
  });

  return result;
}

export { JobsOfTheWeek };
