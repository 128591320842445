import { SVGProps, VFC } from 'react';

export const DeleteIcon: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M13.6 1.6H10.8L9.99999 0.800003H5.99999L5.19999 1.6H2.39999V3.2H13.6V1.6ZM3.19999 13.6C3.19999 14.0243 3.36856 14.4313 3.66862 14.7314C3.96868 15.0314 4.37565 15.2 4.79999 15.2H11.2C11.6243 15.2 12.0313 15.0314 12.3314 14.7314C12.6314 14.4313 12.8 14.0243 12.8 13.6V4H3.19999V13.6Z"
      fill="primary"
    />
  </svg>
);
