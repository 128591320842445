import { SVGProps } from 'react';

export function BackgroundPattern(_props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1038"
      height="802"
      viewBox="0 0 1038 802"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {..._props}
    >
      <g opacity="0.5" clipPath="url(#clip0_1_4102)">
        <mask
          id="mask0_1_4102"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="-413"
          y="-638"
          width="1441"
          height="1440"
        >
          <rect
            width="1440"
            height="1440"
            transform="translate(1027.03 802) rotate(-180)"
            fill="url(#paint0_radial_1_4102)"
          />
        </mask>
        <g mask="url(#mask0_1_4102)">
          <g clipPath="url(#clip1_1_4102)">
            <g clipPath="url(#clip2_1_4102)">
              <line
                x1="1026.68"
                y1="802"
                x2="1026.68"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="970.228"
                y1="802"
                x2="970.228"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="913.78"
                y1="802"
                x2="913.78"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="857.333"
                y1="802"
                x2="857.333"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="800.885"
                y1="802"
                x2="800.885"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="744.437"
                y1="802"
                x2="744.438"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="687.99"
                y1="802"
                x2="687.99"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="631.542"
                y1="802"
                x2="631.542"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="575.095"
                y1="802"
                x2="575.095"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="518.647"
                y1="802"
                x2="518.647"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="462.199"
                y1="802"
                x2="462.2"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="405.752"
                y1="802"
                x2="405.752"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="349.304"
                y1="802"
                x2="349.305"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="292.857"
                y1="802"
                x2="292.857"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="236.409"
                y1="802"
                x2="236.409"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="179.962"
                y1="802"
                x2="179.962"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="123.514"
                y1="802"
                x2="123.514"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="67.0661"
                y1="802"
                x2="67.0662"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="10.6189"
                y1="802"
                x2="10.619"
                y2="-214.057"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
            </g>
            <g clipPath="url(#clip3_1_4102)">
              <line
                x1="1196.37"
                y1="745.905"
                x2="-158.371"
                y2="745.905"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="689.458"
                x2="-158.371"
                y2="689.458"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="633.01"
                x2="-158.371"
                y2="633.01"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="576.562"
                x2="-158.371"
                y2="576.562"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="520.115"
                x2="-158.371"
                y2="520.115"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="463.667"
                x2="-158.371"
                y2="463.667"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="407.219"
                x2="-158.371"
                y2="407.219"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="350.772"
                x2="-158.371"
                y2="350.772"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="294.324"
                x2="-158.371"
                y2="294.324"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="237.877"
                x2="-158.371"
                y2="237.877"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="181.429"
                x2="-158.371"
                y2="181.429"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="124.982"
                x2="-158.371"
                y2="124.982"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="68.5339"
                x2="-158.371"
                y2="68.5338"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
              <line
                x1="1196.37"
                y1="12.0862"
                x2="-158.371"
                y2="12.0861"
                stroke="#FFCD99"
                strokeWidth="0.705595"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1_4102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(720 -0.000171661) rotate(90) scale(1440 751.588)"
        >
          <stop />
          <stop offset="0.953125" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_1_4102">
          <rect
            width="1038"
            height="802"
            fill="white"
            transform="translate(1038 802) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip1_1_4102">
          <rect
            width="1354.74"
            height="1016.06"
            fill="white"
            transform="translate(1196.37 802) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip2_1_4102">
          <rect
            width="1354.74"
            height="1016.06"
            fill="white"
            transform="translate(1196.37 802) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip3_1_4102">
          <rect
            width="1354.74"
            height="1016.06"
            fill="white"
            transform="translate(1196.37 802) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
