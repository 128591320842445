import { Maybe } from 'database/types';

export type FAQBlockProps = {
  title: string;
  description?: Maybe<string>;
  faqs: {
    title: string;
    description: string;
    id: string;
  }[];
};

export function FAQBlock({
  title = '',
  // description,
  faqs = [],
}: FAQBlockProps): JSX.Element | null {
  if (faqs.length < 1) return null;
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-black-500">
          {title}
        </h2>
        <div className="mt-6 border-t border-gray-200 pt-10">
          <dl className="space-y-10 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 md:space-y-0">
            {faqs.map(({ title: t, description, id }) => (
              <div key={id}>
                <dt className="text-lg font-medium leading-6 text-black">
                  {t}
                </dt>
                <dd className="mt-2 text-base text-black-300">{description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
