// Framework
import Link from 'next/link';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { EmployerButton } from 'components/selfService';

// Content
import GetStartBackgroundPattern from 'components/selfService/GetStartedSection/GetStartBackgroundPattern';
import GETSTARTED_ITEMS from './content';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function GetStartedSection(): JSX.Element {
  return (
    <section className="relative z-0 py-16 text-center md:py-24">
      <div className="absolute bottom-0 left-1/2 z-10 flex h-[791px] w-full -translate-x-1/2 items-start justify-center text-clip">
        <GetStartBackgroundPattern />
      </div>
      <div className="relative z-20">
        <EmployerText as="h2" size="xl" className="text-orange-500">
          Get started
        </EmployerText>
        <EmployerText
          as="h3"
          size="4xl"
          className="mx-auto mb-12 mt-4 text-charcoal-500"
        >
          Have your job up by the end of smoko.
        </EmployerText>
        <div className=" flex flex-col justify-center gap-[17px] px-4 md:flex-row md:px-0">
          {GETSTARTED_ITEMS.map((item, index) => {
            return (
              <div
                key={index}
                className="mx-auto w-full max-w-md rounded-lg px-12 py-[27px] text-center sm:w-[352px] md:mx-0"
              >
                <p className="mx-auto mb-6 flex size-12 items-center justify-center rounded-full bg-orange-500 p-2 font-inter text-2xl font-semibold leading-[30px] text-orange-50">
                  {index + 1}
                </p>
                <EmployerText
                  as="h4"
                  size="2xl"
                  className="mb-2 text-charcoal-500"
                >
                  {item.title}
                </EmployerText>
                <EmployerText as="p" size="lg" className="text-charcoal-400">
                  {item.description}
                </EmployerText>
              </div>
            );
          })}
        </div>
        <div className="mx-auto block w-max">
          <Link href="/portal/register" passHref>
            <EmployerButton size="lg" className="w-[200px]">
              Register
            </EmployerButton>
          </Link>
        </div>
      </div>
    </section>
  );
}
