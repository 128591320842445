import { useRouter } from 'next/router';
import { useEffect } from 'react';
import _ from 'lodash';
import { useGetEmployerUserQuery } from 'database/types';
import { usePersistentState } from 'utils/usePersistentState';

export type CurrentEmployer = ReturnType<typeof useCurrentEmployer>;

export const useCurrentEmployer = () => {
  const router = useRouter();
  const { query, pathname, isReady } = router;
  const employerId = query?.employerId as string | undefined;
  const isEmployerPath = isReady && !!employerId;

  const { data, loading, error } = useGetEmployerUserQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isReady,
  });

  const allEmployers = _.sortBy(data?.employer, 'name').sort((i) =>
    ['recruiter', 'conglomerate'].includes(i.organisation_type) ? -1 : 1
  );
  const firstId = allEmployers[0]?.id;
  const hasMultipleEmployers = allEmployers.length > 1;
  const hasNoEmployers = data && !firstId;

  const isValidEmployer = (id?: string) =>
    !!id && allEmployers.some((employer) => employer.id === id);

  /**
   * sync employerId from path with stored value
   */
  useEffect(() => {
    if (isEmployerPath) {
      // route is the authoritative source, but only if it's valid
      // always update stored value when navigating to a valid route
      if (isValidEmployer(employerId)) return setCurrentEmployer(employerId);

      // a non-valid route will try to self-correct by looking elsewhere:
      // either from storage (i.e. currentEmployer exists),
      if (isValidEmployer(currentEmployer)) {
        router.replace({
          pathname,
          query: { ...query, employerId: currentEmployer },
        });
        // or the first employer from the list
      } else if (firstId)
        router.push({
          pathname,
          query: { ...query, employerId: firstId },
        });
    } else if (firstId) setCurrentEmployer(firstId);
  }, [isEmployerPath, firstId, employerId]);
  /**
   * store state in the browser to enable session persistence + multiple tabs with different employers
   */
  const [currentEmployer, storeCurrentEmployer, removeStored] =
    usePersistentState<string | undefined>(
      'portal_current_employerId',
      employerId
    );

  const setCurrentEmployer = (id?: string) => {
    //noop
    if (!id || id === currentEmployer || !isValidEmployer(id)) return;
    storeCurrentEmployer(id);

    if (!isEmployerPath || id === employerId) return;

    // keep user on the same routes when switching employer
    if (
      [
        '/portal/[employerId]/company',
        '/portal/[employerId]/billing',
        '/portal/[employerId]/users',
      ].includes(pathname)
    ) {
      router.push({
        pathname,
        query: { ...query, employerId: id },
      });
    } else {
      // for all others, defaults back to the jobs page
      router.push({
        pathname: '/portal/[employerId]/jobs',
        query: { employerId: id },
      });
    }
  };

  /**
   * prevent user with no employers from accessing any employer path
   */
  useEffect(() => {
    if (hasNoEmployers) {
      //cleanup any previous stored value
      removeStored();

      if (isEmployerPath) router.replace({ pathname: '/portal/profile' });
      // redirect to an allowed route if needed
      // const allowedRoutes = ['/portal/profile', '/portal/settings'];
      // if (!allowedRoutes.includes(pathname))
      //   router.replace({ pathname: '/portal/profile' });
    }
  }, [employerId, hasNoEmployers]);

  const e = !loading && !currentEmployer && !hasNoEmployers;

  return {
    employerId,
    isEmployerPath,
    allEmployers,
    hasMultipleEmployers,
    currentEmployer,
    firstId,
    setCurrentEmployer,
    hasNoEmployers,
    loading,
    error: error ?? e,
    isParent: ['recruiter', 'conglomerate'].includes(
      allEmployers.find((i) => i.id === employerId)?.organisation_type ?? ''
    ),
  };
};
