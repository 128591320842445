import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  icon: ReactNode;
  onClick?: () => void;
  srOnly?: string;
};

export const ButtonIcon: FC<Props> = ({ className, icon, onClick, srOnly }) => (
  <button
    type="button"
    className={twMerge(
      'rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2',
      className
    )}
    onClick={onClick}
  >
    {srOnly && <span className="sr-only">{srOnly}</span>}
    {icon}
  </button>
);
