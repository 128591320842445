import * as jose from 'jose';
import type { JWTPayload } from 'jose';

const cognitoIssuer = `https://cognito-idp.ap-southeast-2.amazonaws.com/${process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID}`;

interface AWSAuth extends JWTPayload {
  'cognito:groups': string[];
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  username: string;
  sub: string;
}

export const validateToken = async (token: string): Promise<any> => {
  try {
    const JWKS = jose.createRemoteJWKSet(
      new URL(`${cognitoIssuer}/.well-known/jwks.json`)
    );
    const { payload } = await jose.jwtVerify(token, JWKS, {});
    if (!payload) throw new Error('Failed to verify token.');
    const { event, iss, sub } = payload as AWSAuth;
    if (iss !== cognitoIssuer) throw new Error('Invalid issuer');
    return { event: `${event}`.replace(/"+/g, ''), sub, valid: true };
  } catch (err: any) {
    console.log('validateToken::err', err);
    // Silent return for JWSInvalid issue...
    // Look into resolving this issue, is our token formatted incorrectly?...
    if (err.toString().includes('JWSInvalid: Invalid Compact JWS')) {
      1 === 1;
    } else {
      return { valid: false };
    }
  }
};
