import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email().required('Email is required'),
  text: yup
    .string()
    .required('Message is required')
    .min(40, 'Message must be at least ${min} characters'),
});

export default schema;
