import Stripe from 'stripe';
import {
  SubscriptionMeta,
  SubscriptionPrice,
  SubscriptionWithMeta,
} from 'utils/stripe';

const getPageItems = (items: SubscriptionPrice[] = []) => {
  if (!Array.isArray(items)) return [];
  return items.filter(({ metadata: { level } }) => level === 'page');
};

type CustomerSubscriptions = {
  all?: Stripe.Subscription[];
  page?: SubscriptionWithMeta;
};

const makeCustomerSubs = (
  subscriptions?: Stripe.Subscription[]
): CustomerSubscriptions => ({
  all: subscriptions,
  page: subscriptions?.find(
    (sub) => sub.status === 'active' && isPageSubscription(sub)
  ) as SubscriptionWithMeta,
});

const getPageSubscriptionPriceItem = (
  sub?: Stripe.Subscription
): SubscriptionPrice | undefined =>
  sub?.items.data.find(
    ({ price: { metadata } }) =>
      (metadata as SubscriptionMeta)?.level === 'page'
  )?.price as any;

const isPageSubscription = (
  sub: Stripe.Subscription
): sub is SubscriptionWithMeta => !!getPageSubscriptionPriceItem(sub);

export {
  getPageItems,
  makeCustomerSubs,
  getPageSubscriptionPriceItem,
  isPageSubscription,
};
