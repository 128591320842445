import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';
import { updatePassword } from 'aws-amplify/auth';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMessages } from 'components/Messages';
import { UpdatedInput } from 'components/forms/UpdatedFormInput';
import { ErrorProvider } from '..';
import Validation from './Validation';

export type ChangePasswordData = {
  password: string;
  new_password: string;
  repeat_password: string;
};

export const ChangePassword: FC = () => {
  const { alert } = useMessages();
  const { formState, handleSubmit, register, reset } =
    useForm<ChangePasswordData>({
      defaultValues: {
        password: '',
        new_password: '',
        repeat_password: '',
      },
      resolver: yupResolver(Validation),
    });
  const onSubmit = async (values: ChangePasswordData) => {
    try {
      await updatePassword({
        oldPassword: values.password,
        newPassword: values.new_password,
      });
      alert({
        key: 'ChangePassword',
        type: 'success',
        title: 'Password changed',
        duration: 3000,
      });
      reset();
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'ChangePassword',
          type: 'error',
          title: 'Issue changing password',
          message: err?.message,
          dismissable: true,
        });
      }
      captureException(err);
    }
  };
  return (
    <form
      id="change_password"
      onSubmit={handleSubmit(onSubmit)}
      className="my-6"
    >
      <ErrorProvider errors={formState.errors}>
        <UpdatedInput
          {...register('password')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          type="password"
          label="Current password"
          placeholder="Current password"
          customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
        />
        <UpdatedInput
          {...register('new_password')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          type="password"
          label="New password"
          placeholder="New password"
          customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
        />
        <UpdatedInput
          {...register('repeat_password')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          type="password"
          label="Repeat password"
          placeholder="Repeat password"
          customLabel="text-charcoal-500 font-bold text-sm leading-5 flex mb-1 text-left"
        />
        <div className="flex justify-end">
          <button
            type="submit"
            className="mx-auto mt-4 h-10 min-w-52 rounded-lg bg-orange-500 px-8"
          >
            <span className="text-lg font-medium text-white">Submit</span>
          </button>
        </div>
      </ErrorProvider>
    </form>
  );
};
