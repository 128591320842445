import * as UtilityTypes from 'types/utils';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  application_scalar: { input: Application; output: Application; }
  bigint: { input: any; output: any; }
  date: { input: string; output: string; }
  float8: { input: any; output: any; }
  geography: { input: UtilityTypes.Geometry; output: UtilityTypes.Geometry; }
  geometry: { input: UtilityTypes.Geometry; output: UtilityTypes.Geometry; }
  job_scalar: { input: Job; output: Job; }
  jsonb: { input: UtilityTypes.JSONValue; output: UtilityTypes.JSONValue; }
  name: { input: any; output: any; }
  numeric: { input: number; output: number; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface Boolean_Comparison_Exp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_Comparison_Exp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface SampleOutput {
  __typename?: 'SampleOutput';
  address: Scalars['String']['output'];
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_Comparison_Exp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
}

/** columns and relationships of "application" */
export interface Application {
  __typename?: 'application';
  answers?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  application_status: Enum_Application_Status;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid']['output'];
  note?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "compute_questions" */
  questions?: Maybe<Array<Questions_And_Answers>>;
  status: Enum_Application_Status_Enum;
  updated_at: Scalars['timestamptz']['output'];
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user: User;
  /** An object relationship */
  userByUpdatedByUserId?: Maybe<User>;
  user_id: Scalars['uuid']['output'];
}


/** columns and relationships of "application" */
export interface ApplicationAnswersArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "application" */
export interface ApplicationQuestionsArgs {
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}

/** aggregated selection of "application" */
export interface Application_Aggregate {
  __typename?: 'application_aggregate';
  aggregate?: Maybe<Application_Aggregate_Fields>;
  nodes: Array<Application>;
}

export interface Application_Aggregate_Bool_Exp {
  count?: InputMaybe<Application_Aggregate_Bool_Exp_Count>;
}

export interface Application_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Application_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Application_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "application" */
export interface Application_Aggregate_Fields {
  __typename?: 'application_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Application_Max_Fields>;
  min?: Maybe<Application_Min_Fields>;
}


/** aggregate fields of "application" */
export interface Application_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Application_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "application" */
export interface Application_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Application_Max_Order_By>;
  min?: InputMaybe<Application_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Application_Append_Input {
  answers?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "application" */
export interface Application_Arr_Rel_Insert_Input {
  data: Array<Application_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Application_On_Conflict>;
}

/** Boolean expression to filter rows from the table "application". All fields are combined with a logical 'AND'. */
export interface Application_Bool_Exp {
  _and?: InputMaybe<Array<Application_Bool_Exp>>;
  _not?: InputMaybe<Application_Bool_Exp>;
  _or?: InputMaybe<Array<Application_Bool_Exp>>;
  answers?: InputMaybe<Jsonb_Comparison_Exp>;
  application_status?: InputMaybe<Enum_Application_Status_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  questions?: InputMaybe<Questions_And_Answers_Bool_Exp>;
  status?: InputMaybe<Enum_Application_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userByUpdatedByUserId?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "application" */
export const Application_Constraint = {
  /** unique or primary key constraint on columns "job_id", "user_id" */
  ApplicationJobIdUserIdKey: 'application_job_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  ApplicationPkey: 'application_pkey'
} as const;

export type Application_Constraint = typeof Application_Constraint[keyof typeof Application_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Application_Delete_At_Path_Input {
  answers?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Application_Delete_Elem_Input {
  answers?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Application_Delete_Key_Input {
  answers?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "application" */
export interface Application_Insert_Input {
  answers?: InputMaybe<Scalars['jsonb']['input']>;
  application_status?: InputMaybe<Enum_Application_Status_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Enum_Application_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userByUpdatedByUserId?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface Application_Max_Fields {
  __typename?: 'application_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "application" */
export interface Application_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Application_Min_Fields {
  __typename?: 'application_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "application" */
export interface Application_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "application" */
export interface Application_Mutation_Response {
  __typename?: 'application_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Application>;
}

/** on_conflict condition type for table "application" */
export interface Application_On_Conflict {
  constraint: Application_Constraint;
  update_columns?: Array<Application_Update_Column>;
  where?: InputMaybe<Application_Bool_Exp>;
}

/** Ordering options when selecting data from "application". */
export interface Application_Order_By {
  answers?: InputMaybe<Order_By>;
  application_status?: InputMaybe<Enum_Application_Status_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Questions_And_Answers_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userByUpdatedByUserId?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: application */
export interface Application_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Application_Prepend_Input {
  answers?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "application" */
export const Application_Select_Column = {
  /** column name */
  Answers: 'answers',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  Note: 'note',
  /** column name */
  Status: 'status',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UpdatedByUserId: 'updated_by_user_id',
  /** column name */
  UserId: 'user_id'
} as const;

export type Application_Select_Column = typeof Application_Select_Column[keyof typeof Application_Select_Column];
/** input type for updating data in table "application" */
export interface Application_Set_Input {
  answers?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Enum_Application_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "application" */
export interface Application_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Application_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Application_Stream_Cursor_Value_Input {
  answers?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Enum_Application_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "application" */
export const Application_Update_Column = {
  /** column name */
  Answers: 'answers',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  Note: 'note',
  /** column name */
  Status: 'status',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UpdatedByUserId: 'updated_by_user_id',
  /** column name */
  UserId: 'user_id'
} as const;

export type Application_Update_Column = typeof Application_Update_Column[keyof typeof Application_Update_Column];
export interface Application_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Application_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Application_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Application_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Application_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Application_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Application_Set_Input>;
  where: Application_Bool_Exp;
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface Bigint_Comparison_Exp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

export interface Compute_Category_Args {
  job_row?: InputMaybe<Scalars['job_scalar']['input']>;
}

export interface Compute_Job_Employer_Args {
  job_row?: InputMaybe<Scalars['job_scalar']['input']>;
}

export interface Compute_Job_Integration_Args {
  job_row?: InputMaybe<Scalars['job_scalar']['input']>;
}

export interface Compute_Listing_Type_Args {
  job_row?: InputMaybe<Scalars['job_scalar']['input']>;
}

export interface Compute_Location_Args {
  job_row?: InputMaybe<Scalars['job_scalar']['input']>;
}

export interface Compute_Questions_Args {
  application?: InputMaybe<Scalars['application_scalar']['input']>;
}

export interface Compute_Work_Type_Args {
  job_row?: InputMaybe<Scalars['job_scalar']['input']>;
}

/** ordering argument of a cursor */
export const Cursor_Ordering = {
  /** ascending ordering of the cursor */
  Asc: 'ASC',
  /** descending ordering of the cursor */
  Desc: 'DESC'
} as const;

export type Cursor_Ordering = typeof Cursor_Ordering[keyof typeof Cursor_Ordering];
/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface Date_Comparison_Exp {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
}

/** columns and relationships of "employer" */
export interface Employer {
  __typename?: 'employer';
  /** An object relationship */
  billing?: Maybe<Enum_Billing>;
  billing_type: Enum_Billing_Enum;
  category?: Maybe<Enum_Category_Enum>;
  /** An array relationship */
  children: Array<Employer>;
  /** An aggregate relationship */
  children_aggregate: Employer_Aggregate;
  cover_photo?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  employer_category?: Maybe<Enum_Category>;
  facebook?: Maybe<Scalars['String']['output']>;
  founded?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['uuid']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  integration_employers: Array<Integration_Employer>;
  /** An aggregate relationship */
  integration_employers_aggregate: Integration_Employer_Aggregate;
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  linkedin?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  main_contact?: Maybe<Scalars['String']['output']>;
  managed_by_parent: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organisation: Enum_Organisation;
  organisation_type: Enum_Organisation_Enum;
  page_active: Scalars['Boolean']['output'];
  /** An object relationship */
  parent?: Maybe<Employer>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  slug: Scalars['String']['output'];
  stripe_id?: Maybe<Scalars['String']['output']>;
  testimonial?: Maybe<Scalars['String']['output']>;
  testimonial_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  user_searches: Array<User_Search>;
  /** An aggregate relationship */
  user_searches_aggregate: User_Search_Aggregate;
  /** An array relationship */
  users: Array<Employer_User>;
  /** An aggregate relationship */
  users_aggregate: Employer_User_Aggregate;
  video?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
}


/** columns and relationships of "employer" */
export interface EmployerChildrenArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerChildren_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerIntegration_EmployersArgs {
  distinct_on?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Employer_Order_By>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerIntegration_Employers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Employer_Order_By>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerJobsArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerJobs_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerUser_SearchesArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerUser_Searches_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerUsersArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


/** columns and relationships of "employer" */
export interface EmployerUsers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}

/** aggregated selection of "employer" */
export interface Employer_Aggregate {
  __typename?: 'employer_aggregate';
  aggregate?: Maybe<Employer_Aggregate_Fields>;
  nodes: Array<Employer>;
}

export interface Employer_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Employer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Employer_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Employer_Aggregate_Bool_Exp_Count>;
}

export interface Employer_Aggregate_Bool_Exp_Bool_And {
  arguments: Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Employer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Employer_Aggregate_Bool_Exp_Bool_Or {
  arguments: Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Employer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Employer_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Employer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Employer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "employer" */
export interface Employer_Aggregate_Fields {
  __typename?: 'employer_aggregate_fields';
  avg?: Maybe<Employer_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Employer_Max_Fields>;
  min?: Maybe<Employer_Min_Fields>;
  stddev?: Maybe<Employer_Stddev_Fields>;
  stddev_pop?: Maybe<Employer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employer_Stddev_Samp_Fields>;
  sum?: Maybe<Employer_Sum_Fields>;
  var_pop?: Maybe<Employer_Var_Pop_Fields>;
  var_samp?: Maybe<Employer_Var_Samp_Fields>;
  variance?: Maybe<Employer_Variance_Fields>;
}


/** aggregate fields of "employer" */
export interface Employer_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Employer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "employer" */
export interface Employer_Aggregate_Order_By {
  avg?: InputMaybe<Employer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employer_Max_Order_By>;
  min?: InputMaybe<Employer_Min_Order_By>;
  stddev?: InputMaybe<Employer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employer_Sum_Order_By>;
  var_pop?: InputMaybe<Employer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employer_Var_Samp_Order_By>;
  variance?: InputMaybe<Employer_Variance_Order_By>;
}

/** input type for inserting array relation for remote table "employer" */
export interface Employer_Arr_Rel_Insert_Input {
  data: Array<Employer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employer_On_Conflict>;
}

/** aggregate avg on columns */
export interface Employer_Avg_Fields {
  __typename?: 'employer_avg_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "employer" */
export interface Employer_Avg_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "employer". All fields are combined with a logical 'AND'. */
export interface Employer_Bool_Exp {
  _and?: InputMaybe<Array<Employer_Bool_Exp>>;
  _not?: InputMaybe<Employer_Bool_Exp>;
  _or?: InputMaybe<Array<Employer_Bool_Exp>>;
  billing?: InputMaybe<Enum_Billing_Bool_Exp>;
  billing_type?: InputMaybe<Enum_Billing_Enum_Comparison_Exp>;
  category?: InputMaybe<Enum_Category_Enum_Comparison_Exp>;
  children?: InputMaybe<Employer_Bool_Exp>;
  children_aggregate?: InputMaybe<Employer_Aggregate_Bool_Exp>;
  cover_photo?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<User_Bool_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  employees?: InputMaybe<Numeric_Comparison_Exp>;
  employer_category?: InputMaybe<Enum_Category_Bool_Exp>;
  facebook?: InputMaybe<String_Comparison_Exp>;
  founded?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instagram?: InputMaybe<String_Comparison_Exp>;
  integration_employers?: InputMaybe<Integration_Employer_Bool_Exp>;
  integration_employers_aggregate?: InputMaybe<Integration_Employer_Aggregate_Bool_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  main_contact?: InputMaybe<String_Comparison_Exp>;
  managed_by_parent?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisation?: InputMaybe<Enum_Organisation_Bool_Exp>;
  organisation_type?: InputMaybe<Enum_Organisation_Enum_Comparison_Exp>;
  page_active?: InputMaybe<Boolean_Comparison_Exp>;
  parent?: InputMaybe<Employer_Bool_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  stripe_id?: InputMaybe<String_Comparison_Exp>;
  testimonial?: InputMaybe<String_Comparison_Exp>;
  testimonial_from?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<User_Bool_Exp>;
  updated_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_searches?: InputMaybe<User_Search_Bool_Exp>;
  user_searches_aggregate?: InputMaybe<User_Search_Aggregate_Bool_Exp>;
  users?: InputMaybe<Employer_User_Bool_Exp>;
  users_aggregate?: InputMaybe<Employer_User_Aggregate_Bool_Exp>;
  video?: InputMaybe<String_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "employer" */
export const Employer_Constraint = {
  /** unique or primary key constraint on columns "id" */
  EmployerPkey: 'employer_pkey',
  /** unique or primary key constraint on columns "slug" */
  EmployerSlugKey: 'employer_slug_key'
} as const;

export type Employer_Constraint = typeof Employer_Constraint[keyof typeof Employer_Constraint];
/** input type for incrementing numeric columns in table "employer" */
export interface Employer_Inc_Input {
  employees?: InputMaybe<Scalars['numeric']['input']>;
  founded?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "employer" */
export interface Employer_Insert_Input {
  billing?: InputMaybe<Enum_Billing_Obj_Rel_Insert_Input>;
  billing_type?: InputMaybe<Enum_Billing_Enum>;
  category?: InputMaybe<Enum_Category_Enum>;
  children?: InputMaybe<Employer_Arr_Rel_Insert_Input>;
  cover_photo?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<Scalars['numeric']['input']>;
  employer_category?: InputMaybe<Enum_Category_Obj_Rel_Insert_Input>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  founded?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  integration_employers?: InputMaybe<Integration_Employer_Arr_Rel_Insert_Input>;
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  main_contact?: InputMaybe<Scalars['String']['input']>;
  managed_by_parent?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisation?: InputMaybe<Enum_Organisation_Obj_Rel_Insert_Input>;
  organisation_type?: InputMaybe<Enum_Organisation_Enum>;
  page_active?: InputMaybe<Scalars['Boolean']['input']>;
  parent?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  testimonial?: InputMaybe<Scalars['String']['input']>;
  testimonial_from?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  user_searches?: InputMaybe<User_Search_Arr_Rel_Insert_Input>;
  users?: InputMaybe<Employer_User_Arr_Rel_Insert_Input>;
  video?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Employer_Max_Fields {
  __typename?: 'employer_max_fields';
  cover_photo?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Scalars['numeric']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  founded?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  main_contact?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  testimonial?: Maybe<Scalars['String']['output']>;
  testimonial_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "employer" */
export interface Employer_Max_Order_By {
  cover_photo?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employees?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  main_contact?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  testimonial?: InputMaybe<Order_By>;
  testimonial_from?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  video?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Employer_Min_Fields {
  __typename?: 'employer_min_fields';
  cover_photo?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Scalars['numeric']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  founded?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  main_contact?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  testimonial?: Maybe<Scalars['String']['output']>;
  testimonial_from?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "employer" */
export interface Employer_Min_Order_By {
  cover_photo?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employees?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  main_contact?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  testimonial?: InputMaybe<Order_By>;
  testimonial_from?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  video?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "employer" */
export interface Employer_Mutation_Response {
  __typename?: 'employer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Employer>;
}

/** input type for inserting object relation for remote table "employer" */
export interface Employer_Obj_Rel_Insert_Input {
  data: Employer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employer_On_Conflict>;
}

/** on_conflict condition type for table "employer" */
export interface Employer_On_Conflict {
  constraint: Employer_Constraint;
  update_columns?: Array<Employer_Update_Column>;
  where?: InputMaybe<Employer_Bool_Exp>;
}

/** Ordering options when selecting data from "employer". */
export interface Employer_Order_By {
  billing?: InputMaybe<Enum_Billing_Order_By>;
  billing_type?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  children_aggregate?: InputMaybe<Employer_Aggregate_Order_By>;
  cover_photo?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<User_Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employees?: InputMaybe<Order_By>;
  employer_category?: InputMaybe<Enum_Category_Order_By>;
  facebook?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  integration_employers_aggregate?: InputMaybe<Integration_Employer_Aggregate_Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  linkedin?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  main_contact?: InputMaybe<Order_By>;
  managed_by_parent?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Enum_Organisation_Order_By>;
  organisation_type?: InputMaybe<Order_By>;
  page_active?: InputMaybe<Order_By>;
  parent?: InputMaybe<Employer_Order_By>;
  parent_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  testimonial?: InputMaybe<Order_By>;
  testimonial_from?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<User_Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  user_searches_aggregate?: InputMaybe<User_Search_Aggregate_Order_By>;
  users_aggregate?: InputMaybe<Employer_User_Aggregate_Order_By>;
  video?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
}

/** primary key columns input for table: employer */
export interface Employer_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "employer" */
export const Employer_Select_Column = {
  /** column name */
  BillingType: 'billing_type',
  /** column name */
  Category: 'category',
  /** column name */
  CoverPhoto: 'cover_photo',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Description: 'description',
  /** column name */
  Employees: 'employees',
  /** column name */
  Facebook: 'facebook',
  /** column name */
  Founded: 'founded',
  /** column name */
  Id: 'id',
  /** column name */
  Instagram: 'instagram',
  /** column name */
  Linkedin: 'linkedin',
  /** column name */
  LocationId: 'location_id',
  /** column name */
  Logo: 'logo',
  /** column name */
  MainContact: 'main_contact',
  /** column name */
  ManagedByParent: 'managed_by_parent',
  /** column name */
  Name: 'name',
  /** column name */
  OrganisationType: 'organisation_type',
  /** column name */
  PageActive: 'page_active',
  /** column name */
  ParentId: 'parent_id',
  /** column name */
  Slug: 'slug',
  /** column name */
  StripeId: 'stripe_id',
  /** column name */
  Testimonial: 'testimonial',
  /** column name */
  TestimonialFrom: 'testimonial_from',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UpdatedByUserId: 'updated_by_user_id',
  /** column name */
  Video: 'video',
  /** column name */
  Website: 'website'
} as const;

export type Employer_Select_Column = typeof Employer_Select_Column[keyof typeof Employer_Select_Column];
/** select "employer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "employer" */
export const Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  ManagedByParent: 'managed_by_parent',
  /** column name */
  PageActive: 'page_active'
} as const;

export type Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "employer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "employer" */
export const Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  ManagedByParent: 'managed_by_parent',
  /** column name */
  PageActive: 'page_active'
} as const;

export type Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof Employer_Select_Column_Employer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
/** input type for updating data in table "employer" */
export interface Employer_Set_Input {
  billing_type?: InputMaybe<Enum_Billing_Enum>;
  category?: InputMaybe<Enum_Category_Enum>;
  cover_photo?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<Scalars['numeric']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  founded?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  main_contact?: InputMaybe<Scalars['String']['input']>;
  managed_by_parent?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisation_type?: InputMaybe<Enum_Organisation_Enum>;
  page_active?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  testimonial?: InputMaybe<Scalars['String']['input']>;
  testimonial_from?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Employer_Stddev_Fields {
  __typename?: 'employer_stddev_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "employer" */
export interface Employer_Stddev_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Employer_Stddev_Pop_Fields {
  __typename?: 'employer_stddev_pop_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "employer" */
export interface Employer_Stddev_Pop_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Employer_Stddev_Samp_Fields {
  __typename?: 'employer_stddev_samp_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "employer" */
export interface Employer_Stddev_Samp_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "employer" */
export interface Employer_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Employer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Employer_Stream_Cursor_Value_Input {
  billing_type?: InputMaybe<Enum_Billing_Enum>;
  category?: InputMaybe<Enum_Category_Enum>;
  cover_photo?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  employees?: InputMaybe<Scalars['numeric']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  founded?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  main_contact?: InputMaybe<Scalars['String']['input']>;
  managed_by_parent?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisation_type?: InputMaybe<Enum_Organisation_Enum>;
  page_active?: InputMaybe<Scalars['Boolean']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  testimonial?: InputMaybe<Scalars['String']['input']>;
  testimonial_from?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Employer_Sum_Fields {
  __typename?: 'employer_sum_fields';
  employees?: Maybe<Scalars['numeric']['output']>;
  founded?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "employer" */
export interface Employer_Sum_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** update columns of table "employer" */
export const Employer_Update_Column = {
  /** column name */
  BillingType: 'billing_type',
  /** column name */
  Category: 'category',
  /** column name */
  CoverPhoto: 'cover_photo',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Description: 'description',
  /** column name */
  Employees: 'employees',
  /** column name */
  Facebook: 'facebook',
  /** column name */
  Founded: 'founded',
  /** column name */
  Id: 'id',
  /** column name */
  Instagram: 'instagram',
  /** column name */
  Linkedin: 'linkedin',
  /** column name */
  LocationId: 'location_id',
  /** column name */
  Logo: 'logo',
  /** column name */
  MainContact: 'main_contact',
  /** column name */
  ManagedByParent: 'managed_by_parent',
  /** column name */
  Name: 'name',
  /** column name */
  OrganisationType: 'organisation_type',
  /** column name */
  PageActive: 'page_active',
  /** column name */
  ParentId: 'parent_id',
  /** column name */
  Slug: 'slug',
  /** column name */
  StripeId: 'stripe_id',
  /** column name */
  Testimonial: 'testimonial',
  /** column name */
  TestimonialFrom: 'testimonial_from',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UpdatedByUserId: 'updated_by_user_id',
  /** column name */
  Video: 'video',
  /** column name */
  Website: 'website'
} as const;

export type Employer_Update_Column = typeof Employer_Update_Column[keyof typeof Employer_Update_Column];
export interface Employer_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employer_Set_Input>;
  where: Employer_Bool_Exp;
}

/** columns and relationships of "employer_user" */
export interface Employer_User {
  __typename?: 'employer_user';
  access_id: Enum_Access_Level_Enum;
  /** An object relationship */
  access_level: Enum_Access_Level;
  /** An object relationship */
  employer: Employer;
  employer_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "employer_user" */
export interface Employer_User_Aggregate {
  __typename?: 'employer_user_aggregate';
  aggregate?: Maybe<Employer_User_Aggregate_Fields>;
  nodes: Array<Employer_User>;
}

export interface Employer_User_Aggregate_Bool_Exp {
  count?: InputMaybe<Employer_User_Aggregate_Bool_Exp_Count>;
}

export interface Employer_User_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Employer_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Employer_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "employer_user" */
export interface Employer_User_Aggregate_Fields {
  __typename?: 'employer_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Employer_User_Max_Fields>;
  min?: Maybe<Employer_User_Min_Fields>;
}


/** aggregate fields of "employer_user" */
export interface Employer_User_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Employer_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "employer_user" */
export interface Employer_User_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employer_User_Max_Order_By>;
  min?: InputMaybe<Employer_User_Min_Order_By>;
}

/** input type for inserting array relation for remote table "employer_user" */
export interface Employer_User_Arr_Rel_Insert_Input {
  data: Array<Employer_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employer_User_On_Conflict>;
}

/** Boolean expression to filter rows from the table "employer_user". All fields are combined with a logical 'AND'. */
export interface Employer_User_Bool_Exp {
  _and?: InputMaybe<Array<Employer_User_Bool_Exp>>;
  _not?: InputMaybe<Employer_User_Bool_Exp>;
  _or?: InputMaybe<Array<Employer_User_Bool_Exp>>;
  access_id?: InputMaybe<Enum_Access_Level_Enum_Comparison_Exp>;
  access_level?: InputMaybe<Enum_Access_Level_Bool_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

export interface Employer_User_By_Id_Args {
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** unique or primary key constraints on table "employer_user" */
export const Employer_User_Constraint = {
  /** unique or primary key constraint on columns "id" */
  EmployerRolePkey: 'employer_role_pkey'
} as const;

export type Employer_User_Constraint = typeof Employer_User_Constraint[keyof typeof Employer_User_Constraint];
/** input type for inserting data into table "employer_user" */
export interface Employer_User_Insert_Input {
  access_id?: InputMaybe<Enum_Access_Level_Enum>;
  access_level?: InputMaybe<Enum_Access_Level_Obj_Rel_Insert_Input>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface Employer_User_Max_Fields {
  __typename?: 'employer_user_max_fields';
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "employer_user" */
export interface Employer_User_Max_Order_By {
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Employer_User_Min_Fields {
  __typename?: 'employer_user_min_fields';
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "employer_user" */
export interface Employer_User_Min_Order_By {
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "employer_user" */
export interface Employer_User_Mutation_Response {
  __typename?: 'employer_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Employer_User>;
}

/** on_conflict condition type for table "employer_user" */
export interface Employer_User_On_Conflict {
  constraint: Employer_User_Constraint;
  update_columns?: Array<Employer_User_Update_Column>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}

/** Ordering options when selecting data from "employer_user". */
export interface Employer_User_Order_By {
  access_id?: InputMaybe<Order_By>;
  access_level?: InputMaybe<Enum_Access_Level_Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: employer_user */
export interface Employer_User_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "employer_user" */
export const Employer_User_Select_Column = {
  /** column name */
  AccessId: 'access_id',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Id: 'id',
  /** column name */
  UserId: 'user_id'
} as const;

export type Employer_User_Select_Column = typeof Employer_User_Select_Column[keyof typeof Employer_User_Select_Column];
/** input type for updating data in table "employer_user" */
export interface Employer_User_Set_Input {
  access_id?: InputMaybe<Enum_Access_Level_Enum>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "employer_user" */
export interface Employer_User_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Employer_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Employer_User_Stream_Cursor_Value_Input {
  access_id?: InputMaybe<Enum_Access_Level_Enum>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "employer_user" */
export const Employer_User_Update_Column = {
  /** column name */
  AccessId: 'access_id',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Id: 'id',
  /** column name */
  UserId: 'user_id'
} as const;

export type Employer_User_Update_Column = typeof Employer_User_Update_Column[keyof typeof Employer_User_Update_Column];
export interface Employer_User_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employer_User_Set_Input>;
  where: Employer_User_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Employer_Var_Pop_Fields {
  __typename?: 'employer_var_pop_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "employer" */
export interface Employer_Var_Pop_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Employer_Var_Samp_Fields {
  __typename?: 'employer_var_samp_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "employer" */
export interface Employer_Var_Samp_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Employer_Variance_Fields {
  __typename?: 'employer_variance_fields';
  employees?: Maybe<Scalars['Float']['output']>;
  founded?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "employer" */
export interface Employer_Variance_Order_By {
  employees?: InputMaybe<Order_By>;
  founded?: InputMaybe<Order_By>;
}

/** columns and relationships of "enum.access_level" */
export interface Enum_Access_Level {
  __typename?: 'enum_access_level';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.access_level" */
export interface Enum_Access_Level_Aggregate {
  __typename?: 'enum_access_level_aggregate';
  aggregate?: Maybe<Enum_Access_Level_Aggregate_Fields>;
  nodes: Array<Enum_Access_Level>;
}

/** aggregate fields of "enum.access_level" */
export interface Enum_Access_Level_Aggregate_Fields {
  __typename?: 'enum_access_level_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Access_Level_Max_Fields>;
  min?: Maybe<Enum_Access_Level_Min_Fields>;
}


/** aggregate fields of "enum.access_level" */
export interface Enum_Access_Level_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Access_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.access_level". All fields are combined with a logical 'AND'. */
export interface Enum_Access_Level_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Access_Level_Bool_Exp>>;
  _not?: InputMaybe<Enum_Access_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Access_Level_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.access_level" */
export const Enum_Access_Level_Constraint = {
  /** unique or primary key constraint on columns "value" */
  EmployerRolePkey: 'employer_role_pkey'
} as const;

export type Enum_Access_Level_Constraint = typeof Enum_Access_Level_Constraint[keyof typeof Enum_Access_Level_Constraint];
export const Enum_Access_Level_Enum = {
  /** Full access */
  Full: 'full',
  /** Read only */
  Read: 'read',
  /** Read-write */
  Write: 'write'
} as const;

export type Enum_Access_Level_Enum = typeof Enum_Access_Level_Enum[keyof typeof Enum_Access_Level_Enum];
/** Boolean expression to compare columns of type "enum_access_level_enum". All fields are combined with logical 'AND'. */
export interface Enum_Access_Level_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Access_Level_Enum>;
  _in?: InputMaybe<Array<Enum_Access_Level_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Access_Level_Enum>;
  _nin?: InputMaybe<Array<Enum_Access_Level_Enum>>;
}

/** input type for inserting data into table "enum.access_level" */
export interface Enum_Access_Level_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Access_Level_Max_Fields {
  __typename?: 'enum_access_level_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Access_Level_Min_Fields {
  __typename?: 'enum_access_level_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.access_level" */
export interface Enum_Access_Level_Mutation_Response {
  __typename?: 'enum_access_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Access_Level>;
}

/** input type for inserting object relation for remote table "enum.access_level" */
export interface Enum_Access_Level_Obj_Rel_Insert_Input {
  data: Enum_Access_Level_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Access_Level_On_Conflict>;
}

/** on_conflict condition type for table "enum.access_level" */
export interface Enum_Access_Level_On_Conflict {
  constraint: Enum_Access_Level_Constraint;
  update_columns?: Array<Enum_Access_Level_Update_Column>;
  where?: InputMaybe<Enum_Access_Level_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.access_level". */
export interface Enum_Access_Level_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.access_level */
export interface Enum_Access_Level_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.access_level" */
export const Enum_Access_Level_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Access_Level_Select_Column = typeof Enum_Access_Level_Select_Column[keyof typeof Enum_Access_Level_Select_Column];
/** input type for updating data in table "enum.access_level" */
export interface Enum_Access_Level_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_access_level" */
export interface Enum_Access_Level_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Access_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Access_Level_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.access_level" */
export const Enum_Access_Level_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Access_Level_Update_Column = typeof Enum_Access_Level_Update_Column[keyof typeof Enum_Access_Level_Update_Column];
export interface Enum_Access_Level_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Access_Level_Set_Input>;
  where: Enum_Access_Level_Bool_Exp;
}

/** columns and relationships of "enum.application_status" */
export interface Enum_Application_Status {
  __typename?: 'enum_application_status';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.application_status" */
export interface Enum_Application_Status_Aggregate {
  __typename?: 'enum_application_status_aggregate';
  aggregate?: Maybe<Enum_Application_Status_Aggregate_Fields>;
  nodes: Array<Enum_Application_Status>;
}

/** aggregate fields of "enum.application_status" */
export interface Enum_Application_Status_Aggregate_Fields {
  __typename?: 'enum_application_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Application_Status_Max_Fields>;
  min?: Maybe<Enum_Application_Status_Min_Fields>;
}


/** aggregate fields of "enum.application_status" */
export interface Enum_Application_Status_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Application_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.application_status". All fields are combined with a logical 'AND'. */
export interface Enum_Application_Status_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Application_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Application_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Application_Status_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.application_status" */
export const Enum_Application_Status_Constraint = {
  /** unique or primary key constraint on columns "value" */
  ApplicationStatusPkey: 'application_status_pkey'
} as const;

export type Enum_Application_Status_Constraint = typeof Enum_Application_Status_Constraint[keyof typeof Enum_Application_Status_Constraint];
export const Enum_Application_Status_Enum = {
  /** Applied */
  Applied: 'applied',
  /** Draft */
  Draft: 'draft',
  /** Hired */
  Hired: 'hired',
  /** Rejected */
  Rejected: 'rejected',
  /** Shortlisted */
  Shortlisted: 'shortlisted',
  /** Withdrawn */
  Withdrawn: 'withdrawn'
} as const;

export type Enum_Application_Status_Enum = typeof Enum_Application_Status_Enum[keyof typeof Enum_Application_Status_Enum];
/** Boolean expression to compare columns of type "enum_application_status_enum". All fields are combined with logical 'AND'. */
export interface Enum_Application_Status_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Application_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Application_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Application_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Application_Status_Enum>>;
}

/** input type for inserting data into table "enum.application_status" */
export interface Enum_Application_Status_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Application_Status_Max_Fields {
  __typename?: 'enum_application_status_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Application_Status_Min_Fields {
  __typename?: 'enum_application_status_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.application_status" */
export interface Enum_Application_Status_Mutation_Response {
  __typename?: 'enum_application_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Application_Status>;
}

/** input type for inserting object relation for remote table "enum.application_status" */
export interface Enum_Application_Status_Obj_Rel_Insert_Input {
  data: Enum_Application_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Application_Status_On_Conflict>;
}

/** on_conflict condition type for table "enum.application_status" */
export interface Enum_Application_Status_On_Conflict {
  constraint: Enum_Application_Status_Constraint;
  update_columns?: Array<Enum_Application_Status_Update_Column>;
  where?: InputMaybe<Enum_Application_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.application_status". */
export interface Enum_Application_Status_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.application_status */
export interface Enum_Application_Status_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.application_status" */
export const Enum_Application_Status_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Application_Status_Select_Column = typeof Enum_Application_Status_Select_Column[keyof typeof Enum_Application_Status_Select_Column];
/** input type for updating data in table "enum.application_status" */
export interface Enum_Application_Status_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_application_status" */
export interface Enum_Application_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Application_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Application_Status_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.application_status" */
export const Enum_Application_Status_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Application_Status_Update_Column = typeof Enum_Application_Status_Update_Column[keyof typeof Enum_Application_Status_Update_Column];
export interface Enum_Application_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Application_Status_Set_Input>;
  where: Enum_Application_Status_Bool_Exp;
}

/** columns and relationships of "enum.billing" */
export interface Enum_Billing {
  __typename?: 'enum_billing';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.billing" */
export interface Enum_Billing_Aggregate {
  __typename?: 'enum_billing_aggregate';
  aggregate?: Maybe<Enum_Billing_Aggregate_Fields>;
  nodes: Array<Enum_Billing>;
}

/** aggregate fields of "enum.billing" */
export interface Enum_Billing_Aggregate_Fields {
  __typename?: 'enum_billing_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Billing_Max_Fields>;
  min?: Maybe<Enum_Billing_Min_Fields>;
}


/** aggregate fields of "enum.billing" */
export interface Enum_Billing_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Billing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.billing". All fields are combined with a logical 'AND'. */
export interface Enum_Billing_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Billing_Bool_Exp>>;
  _not?: InputMaybe<Enum_Billing_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Billing_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.billing" */
export const Enum_Billing_Constraint = {
  /** unique or primary key constraint on columns "label" */
  BillingLabelKey: 'billing_label_key',
  /** unique or primary key constraint on columns "value" */
  BillingPkey: 'billing_pkey'
} as const;

export type Enum_Billing_Constraint = typeof Enum_Billing_Constraint[keyof typeof Enum_Billing_Constraint];
export const Enum_Billing_Enum = {
  /** Monthly invoiced */
  Monthly: 'monthly',
  /** Standard */
  Standard: 'standard'
} as const;

export type Enum_Billing_Enum = typeof Enum_Billing_Enum[keyof typeof Enum_Billing_Enum];
/** Boolean expression to compare columns of type "enum_billing_enum". All fields are combined with logical 'AND'. */
export interface Enum_Billing_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Billing_Enum>;
  _in?: InputMaybe<Array<Enum_Billing_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Billing_Enum>;
  _nin?: InputMaybe<Array<Enum_Billing_Enum>>;
}

/** input type for inserting data into table "enum.billing" */
export interface Enum_Billing_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Billing_Max_Fields {
  __typename?: 'enum_billing_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Billing_Min_Fields {
  __typename?: 'enum_billing_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.billing" */
export interface Enum_Billing_Mutation_Response {
  __typename?: 'enum_billing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing>;
}

/** input type for inserting object relation for remote table "enum.billing" */
export interface Enum_Billing_Obj_Rel_Insert_Input {
  data: Enum_Billing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Billing_On_Conflict>;
}

/** on_conflict condition type for table "enum.billing" */
export interface Enum_Billing_On_Conflict {
  constraint: Enum_Billing_Constraint;
  update_columns?: Array<Enum_Billing_Update_Column>;
  where?: InputMaybe<Enum_Billing_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.billing". */
export interface Enum_Billing_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.billing */
export interface Enum_Billing_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.billing" */
export const Enum_Billing_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Billing_Select_Column = typeof Enum_Billing_Select_Column[keyof typeof Enum_Billing_Select_Column];
/** input type for updating data in table "enum.billing" */
export interface Enum_Billing_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_billing" */
export interface Enum_Billing_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Billing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Billing_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.billing" */
export const Enum_Billing_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Billing_Update_Column = typeof Enum_Billing_Update_Column[keyof typeof Enum_Billing_Update_Column];
export interface Enum_Billing_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Billing_Set_Input>;
  where: Enum_Billing_Bool_Exp;
}

/** columns and relationships of "enum.category" */
export interface Enum_Category {
  __typename?: 'enum_category';
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}


/** columns and relationships of "enum.category" */
export interface Enum_CategoryJobsArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


/** columns and relationships of "enum.category" */
export interface Enum_CategoryJobs_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}

export interface Enum_Category_Aggregate {
  __typename?: 'enum_category_aggregate';
  aggregate?: Maybe<Enum_Category_Aggregate_Fields>;
  nodes: Array<Enum_Category>;
}

/** aggregate fields of "enum.category" */
export interface Enum_Category_Aggregate_Fields {
  __typename?: 'enum_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Category_Max_Fields>;
  min?: Maybe<Enum_Category_Min_Fields>;
}


/** aggregate fields of "enum.category" */
export interface Enum_Category_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "enum.category" */
export interface Enum_Category_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Enum_Category_Max_Order_By>;
  min?: InputMaybe<Enum_Category_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "enum.category". All fields are combined with a logical 'AND'. */
export interface Enum_Category_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Category_Bool_Exp>>;
  _not?: InputMaybe<Enum_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Category_Bool_Exp>>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.category" */
export const Enum_Category_Constraint = {
  /** unique or primary key constraint on columns "value" */
  JobCategoryPkey: 'job_category_pkey'
} as const;

export type Enum_Category_Constraint = typeof Enum_Category_Constraint[keyof typeof Enum_Category_Constraint];
export const Enum_Category_Enum = {
  /** Agriculture & Farming */
  Agriculture: 'agriculture',
  /** Automotive & Mechanical */
  Automotive: 'automotive',
  /** Civil */
  Civil: 'civil',
  /** Construction & Building */
  Construction: 'construction',
  /** Electrical & Air-Conditioning */
  Electrical: 'electrical',
  /** Engineering & Surveying */
  Engineering: 'engineering',
  /** Entry Level & Apprentice */
  Entry: 'entry',
  /** Fabrication & Metalwork */
  Fabrication: 'fabrication',
  /** Facilities & Hospitality */
  Facilities: 'facilities',
  /** Joinery & Cabinetry */
  Joinery: 'joinery',
  /** Landscaping & Gardening */
  Landscaping: 'landscaping',
  /** Management */
  Management: 'management',
  /** Manufacturing & Production */
  Manufacturing: 'manufacturing',
  /** Marine & Shipping */
  Marine: 'marine',
  /** Mining, Resources & Energy */
  Mining: 'mining',
  /** Networking & Telecommunications */
  Networking: 'networking',
  /** Other */
  Other: 'other',
  /** Painting & Decorating */
  Painting: 'painting',
  /** Plumbing & Gasfitting */
  Plumbing: 'plumbing',
  /** Roading & Transport */
  Roading: 'roading',
  /** Roofing */
  Roofing: 'roofing',
  /** Security */
  Security: 'security',
  /** Trade-supply */
  Supply: 'supply',
  /** Support & Admin */
  Support: 'support',
  /** Warehousing & Logistics */
  Warehousing: 'warehousing',
  /** Water */
  Water: 'water'
} as const;

export type Enum_Category_Enum = typeof Enum_Category_Enum[keyof typeof Enum_Category_Enum];
/** Boolean expression to compare columns of type "enum_category_enum". All fields are combined with logical 'AND'. */
export interface Enum_Category_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Category_Enum>;
  _in?: InputMaybe<Array<Enum_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Category_Enum>;
  _nin?: InputMaybe<Array<Enum_Category_Enum>>;
}

/** input type for inserting data into table "enum.category" */
export interface Enum_Category_Insert_Input {
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Category_Max_Fields {
  __typename?: 'enum_category_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "enum.category" */
export interface Enum_Category_Max_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Enum_Category_Min_Fields {
  __typename?: 'enum_category_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "enum.category" */
export interface Enum_Category_Min_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "enum.category" */
export interface Enum_Category_Mutation_Response {
  __typename?: 'enum_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Category>;
}

/** input type for inserting object relation for remote table "enum.category" */
export interface Enum_Category_Obj_Rel_Insert_Input {
  data: Enum_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Category_On_Conflict>;
}

/** on_conflict condition type for table "enum.category" */
export interface Enum_Category_On_Conflict {
  constraint: Enum_Category_Constraint;
  update_columns?: Array<Enum_Category_Update_Column>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.category". */
export interface Enum_Category_Order_By {
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.category */
export interface Enum_Category_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.category" */
export const Enum_Category_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Category_Select_Column = typeof Enum_Category_Select_Column[keyof typeof Enum_Category_Select_Column];
/** input type for updating data in table "enum.category" */
export interface Enum_Category_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_category" */
export interface Enum_Category_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Category_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.category" */
export const Enum_Category_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Category_Update_Column = typeof Enum_Category_Update_Column[keyof typeof Enum_Category_Update_Column];
export interface Enum_Category_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Category_Set_Input>;
  where: Enum_Category_Bool_Exp;
}

/** columns and relationships of "enum.dial_code" */
export interface Enum_Dial_Code {
  __typename?: 'enum_dial_code';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.dial_code" */
export interface Enum_Dial_Code_Aggregate {
  __typename?: 'enum_dial_code_aggregate';
  aggregate?: Maybe<Enum_Dial_Code_Aggregate_Fields>;
  nodes: Array<Enum_Dial_Code>;
}

/** aggregate fields of "enum.dial_code" */
export interface Enum_Dial_Code_Aggregate_Fields {
  __typename?: 'enum_dial_code_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Dial_Code_Max_Fields>;
  min?: Maybe<Enum_Dial_Code_Min_Fields>;
}


/** aggregate fields of "enum.dial_code" */
export interface Enum_Dial_Code_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Dial_Code_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.dial_code". All fields are combined with a logical 'AND'. */
export interface Enum_Dial_Code_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Dial_Code_Bool_Exp>>;
  _not?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Dial_Code_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.dial_code" */
export const Enum_Dial_Code_Constraint = {
  /** unique or primary key constraint on columns "label" */
  DialCodeLabelKey: 'dial_code_label_key',
  /** unique or primary key constraint on columns "value" */
  DialCodePkey: 'dial_code_pkey'
} as const;

export type Enum_Dial_Code_Constraint = typeof Enum_Dial_Code_Constraint[keyof typeof Enum_Dial_Code_Constraint];
/** input type for inserting data into table "enum.dial_code" */
export interface Enum_Dial_Code_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Dial_Code_Max_Fields {
  __typename?: 'enum_dial_code_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Dial_Code_Min_Fields {
  __typename?: 'enum_dial_code_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.dial_code" */
export interface Enum_Dial_Code_Mutation_Response {
  __typename?: 'enum_dial_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Dial_Code>;
}

/** on_conflict condition type for table "enum.dial_code" */
export interface Enum_Dial_Code_On_Conflict {
  constraint: Enum_Dial_Code_Constraint;
  update_columns?: Array<Enum_Dial_Code_Update_Column>;
  where?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.dial_code". */
export interface Enum_Dial_Code_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.dial_code */
export interface Enum_Dial_Code_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.dial_code" */
export const Enum_Dial_Code_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Dial_Code_Select_Column = typeof Enum_Dial_Code_Select_Column[keyof typeof Enum_Dial_Code_Select_Column];
/** input type for updating data in table "enum.dial_code" */
export interface Enum_Dial_Code_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_dial_code" */
export interface Enum_Dial_Code_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Dial_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Dial_Code_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.dial_code" */
export const Enum_Dial_Code_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Dial_Code_Update_Column = typeof Enum_Dial_Code_Update_Column[keyof typeof Enum_Dial_Code_Update_Column];
export interface Enum_Dial_Code_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Dial_Code_Set_Input>;
  where: Enum_Dial_Code_Bool_Exp;
}

/** columns and relationships of "enum.integration_type" */
export interface Enum_Integration_Type {
  __typename?: 'enum_integration_type';
  api_key?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.integration_type" */
export interface Enum_Integration_Type_Aggregate {
  __typename?: 'enum_integration_type_aggregate';
  aggregate?: Maybe<Enum_Integration_Type_Aggregate_Fields>;
  nodes: Array<Enum_Integration_Type>;
}

/** aggregate fields of "enum.integration_type" */
export interface Enum_Integration_Type_Aggregate_Fields {
  __typename?: 'enum_integration_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Integration_Type_Max_Fields>;
  min?: Maybe<Enum_Integration_Type_Min_Fields>;
}


/** aggregate fields of "enum.integration_type" */
export interface Enum_Integration_Type_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Integration_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.integration_type". All fields are combined with a logical 'AND'. */
export interface Enum_Integration_Type_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Integration_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Integration_Type_Bool_Exp>>;
  api_key?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.integration_type" */
export const Enum_Integration_Type_Constraint = {
  /** unique or primary key constraint on columns "api_key" */
  IntegrationTypeApiKeyKey: 'integration_type_api_key_key',
  /** unique or primary key constraint on columns "label" */
  IntegrationTypeLabelKey: 'integration_type_label_key',
  /** unique or primary key constraint on columns "value" */
  IntegrationTypePkey: 'integration_type_pkey'
} as const;

export type Enum_Integration_Type_Constraint = typeof Enum_Integration_Type_Constraint[keyof typeof Enum_Integration_Type_Constraint];
/** input type for inserting data into table "enum.integration_type" */
export interface Enum_Integration_Type_Insert_Input {
  api_key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Integration_Type_Max_Fields {
  __typename?: 'enum_integration_type_max_fields';
  api_key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Integration_Type_Min_Fields {
  __typename?: 'enum_integration_type_min_fields';
  api_key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.integration_type" */
export interface Enum_Integration_Type_Mutation_Response {
  __typename?: 'enum_integration_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Integration_Type>;
}

/** input type for inserting object relation for remote table "enum.integration_type" */
export interface Enum_Integration_Type_Obj_Rel_Insert_Input {
  data: Enum_Integration_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Integration_Type_On_Conflict>;
}

/** on_conflict condition type for table "enum.integration_type" */
export interface Enum_Integration_Type_On_Conflict {
  constraint: Enum_Integration_Type_Constraint;
  update_columns?: Array<Enum_Integration_Type_Update_Column>;
  where?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.integration_type". */
export interface Enum_Integration_Type_Order_By {
  api_key?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.integration_type */
export interface Enum_Integration_Type_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.integration_type" */
export const Enum_Integration_Type_Select_Column = {
  /** column name */
  ApiKey: 'api_key',
  /** column name */
  Label: 'label',
  /** column name */
  Url: 'url',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Integration_Type_Select_Column = typeof Enum_Integration_Type_Select_Column[keyof typeof Enum_Integration_Type_Select_Column];
/** input type for updating data in table "enum.integration_type" */
export interface Enum_Integration_Type_Set_Input {
  api_key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_integration_type" */
export interface Enum_Integration_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Integration_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Integration_Type_Stream_Cursor_Value_Input {
  api_key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.integration_type" */
export const Enum_Integration_Type_Update_Column = {
  /** column name */
  ApiKey: 'api_key',
  /** column name */
  Label: 'label',
  /** column name */
  Url: 'url',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Integration_Type_Update_Column = typeof Enum_Integration_Type_Update_Column[keyof typeof Enum_Integration_Type_Update_Column];
export interface Enum_Integration_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Integration_Type_Set_Input>;
  where: Enum_Integration_Type_Bool_Exp;
}

/** columns and relationships of "enum.job_status" */
export interface Enum_Job_Status {
  __typename?: 'enum_job_status';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.job_status" */
export interface Enum_Job_Status_Aggregate {
  __typename?: 'enum_job_status_aggregate';
  aggregate?: Maybe<Enum_Job_Status_Aggregate_Fields>;
  nodes: Array<Enum_Job_Status>;
}

/** aggregate fields of "enum.job_status" */
export interface Enum_Job_Status_Aggregate_Fields {
  __typename?: 'enum_job_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Job_Status_Max_Fields>;
  min?: Maybe<Enum_Job_Status_Min_Fields>;
}


/** aggregate fields of "enum.job_status" */
export interface Enum_Job_Status_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Job_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.job_status". All fields are combined with a logical 'AND'. */
export interface Enum_Job_Status_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Job_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Job_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Job_Status_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.job_status" */
export const Enum_Job_Status_Constraint = {
  /** unique or primary key constraint on columns "label" */
  JobStatusCommentKey: 'job_status_comment_key',
  /** unique or primary key constraint on columns "value" */
  JobStatusPkey: 'job_status_pkey'
} as const;

export type Enum_Job_Status_Constraint = typeof Enum_Job_Status_Constraint[keyof typeof Enum_Job_Status_Constraint];
export const Enum_Job_Status_Enum = {
  /** Active */
  Active: 'active',
  /** Archived */
  Archived: 'archived',
  /** Draft */
  Draft: 'draft',
  /** Expired */
  Expired: 'expired'
} as const;

export type Enum_Job_Status_Enum = typeof Enum_Job_Status_Enum[keyof typeof Enum_Job_Status_Enum];
/** Boolean expression to compare columns of type "enum_job_status_enum". All fields are combined with logical 'AND'. */
export interface Enum_Job_Status_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Job_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Job_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Job_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Job_Status_Enum>>;
}

/** input type for inserting data into table "enum.job_status" */
export interface Enum_Job_Status_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Job_Status_Max_Fields {
  __typename?: 'enum_job_status_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Job_Status_Min_Fields {
  __typename?: 'enum_job_status_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.job_status" */
export interface Enum_Job_Status_Mutation_Response {
  __typename?: 'enum_job_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Job_Status>;
}

/** input type for inserting object relation for remote table "enum.job_status" */
export interface Enum_Job_Status_Obj_Rel_Insert_Input {
  data: Enum_Job_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Job_Status_On_Conflict>;
}

/** on_conflict condition type for table "enum.job_status" */
export interface Enum_Job_Status_On_Conflict {
  constraint: Enum_Job_Status_Constraint;
  update_columns?: Array<Enum_Job_Status_Update_Column>;
  where?: InputMaybe<Enum_Job_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.job_status". */
export interface Enum_Job_Status_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.job_status */
export interface Enum_Job_Status_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.job_status" */
export const Enum_Job_Status_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Job_Status_Select_Column = typeof Enum_Job_Status_Select_Column[keyof typeof Enum_Job_Status_Select_Column];
/** input type for updating data in table "enum.job_status" */
export interface Enum_Job_Status_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_job_status" */
export interface Enum_Job_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Job_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Job_Status_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.job_status" */
export const Enum_Job_Status_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Job_Status_Update_Column = typeof Enum_Job_Status_Update_Column[keyof typeof Enum_Job_Status_Update_Column];
export interface Enum_Job_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Job_Status_Set_Input>;
  where: Enum_Job_Status_Bool_Exp;
}

/** columns and relationships of "enum.listing" */
export interface Enum_Listing {
  __typename?: 'enum_listing';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface Enum_Listing_Aggregate {
  __typename?: 'enum_listing_aggregate';
  aggregate?: Maybe<Enum_Listing_Aggregate_Fields>;
  nodes: Array<Enum_Listing>;
}

/** aggregate fields of "enum.listing" */
export interface Enum_Listing_Aggregate_Fields {
  __typename?: 'enum_listing_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Listing_Max_Fields>;
  min?: Maybe<Enum_Listing_Min_Fields>;
}


/** aggregate fields of "enum.listing" */
export interface Enum_Listing_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "enum.listing" */
export interface Enum_Listing_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Enum_Listing_Max_Order_By>;
  min?: InputMaybe<Enum_Listing_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "enum.listing". All fields are combined with a logical 'AND'. */
export interface Enum_Listing_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Listing_Bool_Exp>>;
  _not?: InputMaybe<Enum_Listing_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Listing_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.listing" */
export const Enum_Listing_Constraint = {
  /** unique or primary key constraint on columns "label" */
  ListingTypeLabelKey: 'listing_type_label_key',
  /** unique or primary key constraint on columns "value" */
  ListingTypePkey: 'listing_type_pkey'
} as const;

export type Enum_Listing_Constraint = typeof Enum_Listing_Constraint[keyof typeof Enum_Listing_Constraint];
export const Enum_Listing_Enum = {
  /** Basic */
  Basic: 'basic',
  /** Expression of Interest */
  ExpressionOfInterest: 'expression_of_interest',
  /** Featured */
  Featured: 'featured',
  /** Standard */
  Standard: 'standard'
} as const;

export type Enum_Listing_Enum = typeof Enum_Listing_Enum[keyof typeof Enum_Listing_Enum];
/** Boolean expression to compare columns of type "enum_listing_enum". All fields are combined with logical 'AND'. */
export interface Enum_Listing_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Listing_Enum>;
  _in?: InputMaybe<Array<Enum_Listing_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Listing_Enum>;
  _nin?: InputMaybe<Array<Enum_Listing_Enum>>;
}

/** input type for inserting data into table "enum.listing" */
export interface Enum_Listing_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Listing_Max_Fields {
  __typename?: 'enum_listing_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "enum.listing" */
export interface Enum_Listing_Max_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Enum_Listing_Min_Fields {
  __typename?: 'enum_listing_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "enum.listing" */
export interface Enum_Listing_Min_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "enum.listing" */
export interface Enum_Listing_Mutation_Response {
  __typename?: 'enum_listing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Listing>;
}

/** input type for inserting object relation for remote table "enum.listing" */
export interface Enum_Listing_Obj_Rel_Insert_Input {
  data: Enum_Listing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Listing_On_Conflict>;
}

/** on_conflict condition type for table "enum.listing" */
export interface Enum_Listing_On_Conflict {
  constraint: Enum_Listing_Constraint;
  update_columns?: Array<Enum_Listing_Update_Column>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.listing". */
export interface Enum_Listing_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.listing */
export interface Enum_Listing_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.listing" */
export const Enum_Listing_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Listing_Select_Column = typeof Enum_Listing_Select_Column[keyof typeof Enum_Listing_Select_Column];
/** input type for updating data in table "enum.listing" */
export interface Enum_Listing_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_listing" */
export interface Enum_Listing_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Listing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Listing_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.listing" */
export const Enum_Listing_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Listing_Update_Column = typeof Enum_Listing_Update_Column[keyof typeof Enum_Listing_Update_Column];
export interface Enum_Listing_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Listing_Set_Input>;
  where: Enum_Listing_Bool_Exp;
}

/** columns and relationships of "enum.organisation" */
export interface Enum_Organisation {
  __typename?: 'enum_organisation';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.organisation" */
export interface Enum_Organisation_Aggregate {
  __typename?: 'enum_organisation_aggregate';
  aggregate?: Maybe<Enum_Organisation_Aggregate_Fields>;
  nodes: Array<Enum_Organisation>;
}

/** aggregate fields of "enum.organisation" */
export interface Enum_Organisation_Aggregate_Fields {
  __typename?: 'enum_organisation_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Organisation_Max_Fields>;
  min?: Maybe<Enum_Organisation_Min_Fields>;
}


/** aggregate fields of "enum.organisation" */
export interface Enum_Organisation_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Organisation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.organisation". All fields are combined with a logical 'AND'. */
export interface Enum_Organisation_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Organisation_Bool_Exp>>;
  _not?: InputMaybe<Enum_Organisation_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Organisation_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.organisation" */
export const Enum_Organisation_Constraint = {
  /** unique or primary key constraint on columns "label" */
  OrganisationLabelKey: 'organisation_label_key',
  /** unique or primary key constraint on columns "value" */
  OrganisationPkey: 'organisation_pkey',
  /** unique or primary key constraint on columns "value" */
  OrganisationValueKey: 'organisation_value_key'
} as const;

export type Enum_Organisation_Constraint = typeof Enum_Organisation_Constraint[keyof typeof Enum_Organisation_Constraint];
export const Enum_Organisation_Enum = {
  /** Conglomerate */
  Conglomerate: 'conglomerate',
  /** Default */
  Default: 'default',
  /** Recruiter */
  Recruiter: 'recruiter'
} as const;

export type Enum_Organisation_Enum = typeof Enum_Organisation_Enum[keyof typeof Enum_Organisation_Enum];
/** Boolean expression to compare columns of type "enum_organisation_enum". All fields are combined with logical 'AND'. */
export interface Enum_Organisation_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Organisation_Enum>;
  _in?: InputMaybe<Array<Enum_Organisation_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Organisation_Enum>;
  _nin?: InputMaybe<Array<Enum_Organisation_Enum>>;
}

/** input type for inserting data into table "enum.organisation" */
export interface Enum_Organisation_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Organisation_Max_Fields {
  __typename?: 'enum_organisation_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Organisation_Min_Fields {
  __typename?: 'enum_organisation_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.organisation" */
export interface Enum_Organisation_Mutation_Response {
  __typename?: 'enum_organisation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Organisation>;
}

/** input type for inserting object relation for remote table "enum.organisation" */
export interface Enum_Organisation_Obj_Rel_Insert_Input {
  data: Enum_Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Organisation_On_Conflict>;
}

/** on_conflict condition type for table "enum.organisation" */
export interface Enum_Organisation_On_Conflict {
  constraint: Enum_Organisation_Constraint;
  update_columns?: Array<Enum_Organisation_Update_Column>;
  where?: InputMaybe<Enum_Organisation_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.organisation". */
export interface Enum_Organisation_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.organisation */
export interface Enum_Organisation_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.organisation" */
export const Enum_Organisation_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Organisation_Select_Column = typeof Enum_Organisation_Select_Column[keyof typeof Enum_Organisation_Select_Column];
/** input type for updating data in table "enum.organisation" */
export interface Enum_Organisation_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_organisation" */
export interface Enum_Organisation_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Organisation_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.organisation" */
export const Enum_Organisation_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Organisation_Update_Column = typeof Enum_Organisation_Update_Column[keyof typeof Enum_Organisation_Update_Column];
export interface Enum_Organisation_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Organisation_Set_Input>;
  where: Enum_Organisation_Bool_Exp;
}

/** columns and relationships of "enum.pay_type" */
export interface Enum_Pay_Type {
  __typename?: 'enum_pay_type';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.pay_type" */
export interface Enum_Pay_Type_Aggregate {
  __typename?: 'enum_pay_type_aggregate';
  aggregate?: Maybe<Enum_Pay_Type_Aggregate_Fields>;
  nodes: Array<Enum_Pay_Type>;
}

/** aggregate fields of "enum.pay_type" */
export interface Enum_Pay_Type_Aggregate_Fields {
  __typename?: 'enum_pay_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Pay_Type_Max_Fields>;
  min?: Maybe<Enum_Pay_Type_Min_Fields>;
}


/** aggregate fields of "enum.pay_type" */
export interface Enum_Pay_Type_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Pay_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.pay_type". All fields are combined with a logical 'AND'. */
export interface Enum_Pay_Type_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Pay_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Pay_Type_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.pay_type" */
export const Enum_Pay_Type_Constraint = {
  /** unique or primary key constraint on columns "value" */
  PayTypePkey: 'pay_type_pkey'
} as const;

export type Enum_Pay_Type_Constraint = typeof Enum_Pay_Type_Constraint[keyof typeof Enum_Pay_Type_Constraint];
export const Enum_Pay_Type_Enum = {
  /** Annual pay */
  Annually: 'annually',
  /** Hourly pay */
  Hourly: 'hourly'
} as const;

export type Enum_Pay_Type_Enum = typeof Enum_Pay_Type_Enum[keyof typeof Enum_Pay_Type_Enum];
/** Boolean expression to compare columns of type "enum_pay_type_enum". All fields are combined with logical 'AND'. */
export interface Enum_Pay_Type_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Pay_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Pay_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Pay_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Pay_Type_Enum>>;
}

/** input type for inserting data into table "enum.pay_type" */
export interface Enum_Pay_Type_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Pay_Type_Max_Fields {
  __typename?: 'enum_pay_type_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Pay_Type_Min_Fields {
  __typename?: 'enum_pay_type_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.pay_type" */
export interface Enum_Pay_Type_Mutation_Response {
  __typename?: 'enum_pay_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Pay_Type>;
}

/** input type for inserting object relation for remote table "enum.pay_type" */
export interface Enum_Pay_Type_Obj_Rel_Insert_Input {
  data: Enum_Pay_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Pay_Type_On_Conflict>;
}

/** on_conflict condition type for table "enum.pay_type" */
export interface Enum_Pay_Type_On_Conflict {
  constraint: Enum_Pay_Type_Constraint;
  update_columns?: Array<Enum_Pay_Type_Update_Column>;
  where?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.pay_type". */
export interface Enum_Pay_Type_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.pay_type */
export interface Enum_Pay_Type_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.pay_type" */
export const Enum_Pay_Type_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Pay_Type_Select_Column = typeof Enum_Pay_Type_Select_Column[keyof typeof Enum_Pay_Type_Select_Column];
/** input type for updating data in table "enum.pay_type" */
export interface Enum_Pay_Type_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_pay_type" */
export interface Enum_Pay_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Pay_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Pay_Type_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.pay_type" */
export const Enum_Pay_Type_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Pay_Type_Update_Column = typeof Enum_Pay_Type_Update_Column[keyof typeof Enum_Pay_Type_Update_Column];
export interface Enum_Pay_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Pay_Type_Set_Input>;
  where: Enum_Pay_Type_Bool_Exp;
}

/** columns and relationships of "enum.question_type" */
export interface Enum_Question_Type {
  __typename?: 'enum_question_type';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.question_type" */
export interface Enum_Question_Type_Aggregate {
  __typename?: 'enum_question_type_aggregate';
  aggregate?: Maybe<Enum_Question_Type_Aggregate_Fields>;
  nodes: Array<Enum_Question_Type>;
}

/** aggregate fields of "enum.question_type" */
export interface Enum_Question_Type_Aggregate_Fields {
  __typename?: 'enum_question_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Question_Type_Max_Fields>;
  min?: Maybe<Enum_Question_Type_Min_Fields>;
}


/** aggregate fields of "enum.question_type" */
export interface Enum_Question_Type_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Question_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.question_type". All fields are combined with a logical 'AND'. */
export interface Enum_Question_Type_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Question_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Question_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Question_Type_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.question_type" */
export const Enum_Question_Type_Constraint = {
  /** unique or primary key constraint on columns "value" */
  QuestionTypePkey: 'question_type_pkey'
} as const;

export type Enum_Question_Type_Constraint = typeof Enum_Question_Type_Constraint[keyof typeof Enum_Question_Type_Constraint];
export const Enum_Question_Type_Enum = {
  /** Boolean */
  Boolean: 'boolean'
} as const;

export type Enum_Question_Type_Enum = typeof Enum_Question_Type_Enum[keyof typeof Enum_Question_Type_Enum];
/** Boolean expression to compare columns of type "enum_question_type_enum". All fields are combined with logical 'AND'. */
export interface Enum_Question_Type_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Question_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Question_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Question_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Question_Type_Enum>>;
}

/** input type for inserting data into table "enum.question_type" */
export interface Enum_Question_Type_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Question_Type_Max_Fields {
  __typename?: 'enum_question_type_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Question_Type_Min_Fields {
  __typename?: 'enum_question_type_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.question_type" */
export interface Enum_Question_Type_Mutation_Response {
  __typename?: 'enum_question_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Question_Type>;
}

/** input type for inserting object relation for remote table "enum.question_type" */
export interface Enum_Question_Type_Obj_Rel_Insert_Input {
  data: Enum_Question_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Question_Type_On_Conflict>;
}

/** on_conflict condition type for table "enum.question_type" */
export interface Enum_Question_Type_On_Conflict {
  constraint: Enum_Question_Type_Constraint;
  update_columns?: Array<Enum_Question_Type_Update_Column>;
  where?: InputMaybe<Enum_Question_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.question_type". */
export interface Enum_Question_Type_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.question_type */
export interface Enum_Question_Type_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.question_type" */
export const Enum_Question_Type_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Question_Type_Select_Column = typeof Enum_Question_Type_Select_Column[keyof typeof Enum_Question_Type_Select_Column];
/** input type for updating data in table "enum.question_type" */
export interface Enum_Question_Type_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_question_type" */
export interface Enum_Question_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Question_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Question_Type_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.question_type" */
export const Enum_Question_Type_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Question_Type_Update_Column = typeof Enum_Question_Type_Update_Column[keyof typeof Enum_Question_Type_Update_Column];
export interface Enum_Question_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Question_Type_Set_Input>;
  where: Enum_Question_Type_Bool_Exp;
}

/** columns and relationships of "enum.role" */
export interface Enum_Role {
  __typename?: 'enum_role';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "enum.role" */
export interface Enum_Role_Aggregate {
  __typename?: 'enum_role_aggregate';
  aggregate?: Maybe<Enum_Role_Aggregate_Fields>;
  nodes: Array<Enum_Role>;
}

/** aggregate fields of "enum.role" */
export interface Enum_Role_Aggregate_Fields {
  __typename?: 'enum_role_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Role_Max_Fields>;
  min?: Maybe<Enum_Role_Min_Fields>;
}


/** aggregate fields of "enum.role" */
export interface Enum_Role_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "enum.role". All fields are combined with a logical 'AND'. */
export interface Enum_Role_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Role_Bool_Exp>>;
  _not?: InputMaybe<Enum_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Role_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.role" */
export const Enum_Role_Constraint = {
  /** unique or primary key constraint on columns "value" */
  RolePkey: 'role_pkey'
} as const;

export type Enum_Role_Constraint = typeof Enum_Role_Constraint[keyof typeof Enum_Role_Constraint];
export const Enum_Role_Enum = {
  /** admin */
  Admin: 'admin',
  /** Employee Role */
  Employee: 'employee',
  /** Employer Role */
  Employer: 'employer'
} as const;

export type Enum_Role_Enum = typeof Enum_Role_Enum[keyof typeof Enum_Role_Enum];
/** Boolean expression to compare columns of type "enum_role_enum". All fields are combined with logical 'AND'. */
export interface Enum_Role_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Role_Enum>;
  _in?: InputMaybe<Array<Enum_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Role_Enum>;
  _nin?: InputMaybe<Array<Enum_Role_Enum>>;
}

/** input type for inserting data into table "enum.role" */
export interface Enum_Role_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Role_Max_Fields {
  __typename?: 'enum_role_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Enum_Role_Min_Fields {
  __typename?: 'enum_role_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "enum.role" */
export interface Enum_Role_Mutation_Response {
  __typename?: 'enum_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Role>;
}

/** input type for inserting object relation for remote table "enum.role" */
export interface Enum_Role_Obj_Rel_Insert_Input {
  data: Enum_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Role_On_Conflict>;
}

/** on_conflict condition type for table "enum.role" */
export interface Enum_Role_On_Conflict {
  constraint: Enum_Role_Constraint;
  update_columns?: Array<Enum_Role_Update_Column>;
  where?: InputMaybe<Enum_Role_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.role". */
export interface Enum_Role_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.role */
export interface Enum_Role_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.role" */
export const Enum_Role_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Role_Select_Column = typeof Enum_Role_Select_Column[keyof typeof Enum_Role_Select_Column];
/** input type for updating data in table "enum.role" */
export interface Enum_Role_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_role" */
export interface Enum_Role_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Role_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.role" */
export const Enum_Role_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Role_Update_Column = typeof Enum_Role_Update_Column[keyof typeof Enum_Role_Update_Column];
export interface Enum_Role_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Role_Set_Input>;
  where: Enum_Role_Bool_Exp;
}

/** columns and relationships of "enum.work_type" */
export interface Enum_Work_Type {
  __typename?: 'enum_work_type';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface Enum_Work_Type_Aggregate {
  __typename?: 'enum_work_type_aggregate';
  aggregate?: Maybe<Enum_Work_Type_Aggregate_Fields>;
  nodes: Array<Enum_Work_Type>;
}

/** aggregate fields of "enum.work_type" */
export interface Enum_Work_Type_Aggregate_Fields {
  __typename?: 'enum_work_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Enum_Work_Type_Max_Fields>;
  min?: Maybe<Enum_Work_Type_Min_Fields>;
}


/** aggregate fields of "enum.work_type" */
export interface Enum_Work_Type_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "enum.work_type" */
export interface Enum_Work_Type_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Enum_Work_Type_Max_Order_By>;
  min?: InputMaybe<Enum_Work_Type_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "enum.work_type". All fields are combined with a logical 'AND'. */
export interface Enum_Work_Type_Bool_Exp {
  _and?: InputMaybe<Array<Enum_Work_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Work_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Work_Type_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "enum.work_type" */
export const Enum_Work_Type_Constraint = {
  /** unique or primary key constraint on columns "value" */
  JobTypePkey: 'job_type_pkey'
} as const;

export type Enum_Work_Type_Constraint = typeof Enum_Work_Type_Constraint[keyof typeof Enum_Work_Type_Constraint];
export const Enum_Work_Type_Enum = {
  /** Apprentice */
  Apprentice: 'apprentice',
  /** Casual work */
  Casual: 'casual',
  /** Contract work */
  Contract: 'contract',
  /** Full-time employment */
  FullTime: 'full_time',
  /** Part-time employment */
  PartTime: 'part_time'
} as const;

export type Enum_Work_Type_Enum = typeof Enum_Work_Type_Enum[keyof typeof Enum_Work_Type_Enum];
/** Boolean expression to compare columns of type "enum_work_type_enum". All fields are combined with logical 'AND'. */
export interface Enum_Work_Type_Enum_Comparison_Exp {
  _eq?: InputMaybe<Enum_Work_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Work_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Work_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Work_Type_Enum>>;
}

/** input type for inserting data into table "enum.work_type" */
export interface Enum_Work_Type_Insert_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Enum_Work_Type_Max_Fields {
  __typename?: 'enum_work_type_max_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "enum.work_type" */
export interface Enum_Work_Type_Max_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Enum_Work_Type_Min_Fields {
  __typename?: 'enum_work_type_min_fields';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "enum.work_type" */
export interface Enum_Work_Type_Min_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "enum.work_type" */
export interface Enum_Work_Type_Mutation_Response {
  __typename?: 'enum_work_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Work_Type>;
}

/** input type for inserting object relation for remote table "enum.work_type" */
export interface Enum_Work_Type_Obj_Rel_Insert_Input {
  data: Enum_Work_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Work_Type_On_Conflict>;
}

/** on_conflict condition type for table "enum.work_type" */
export interface Enum_Work_Type_On_Conflict {
  constraint: Enum_Work_Type_Constraint;
  update_columns?: Array<Enum_Work_Type_Update_Column>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}

/** Ordering options when selecting data from "enum.work_type". */
export interface Enum_Work_Type_Order_By {
  label?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: enum.work_type */
export interface Enum_Work_Type_Pk_Columns_Input {
  value: Scalars['String']['input'];
}

/** select columns of table "enum.work_type" */
export const Enum_Work_Type_Select_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Work_Type_Select_Column = typeof Enum_Work_Type_Select_Column[keyof typeof Enum_Work_Type_Select_Column];
/** input type for updating data in table "enum.work_type" */
export interface Enum_Work_Type_Set_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "enum_work_type" */
export interface Enum_Work_Type_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Enum_Work_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Enum_Work_Type_Stream_Cursor_Value_Input {
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "enum.work_type" */
export const Enum_Work_Type_Update_Column = {
  /** column name */
  Label: 'label',
  /** column name */
  Value: 'value'
} as const;

export type Enum_Work_Type_Update_Column = typeof Enum_Work_Type_Update_Column[keyof typeof Enum_Work_Type_Update_Column];
export interface Enum_Work_Type_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Work_Type_Set_Input>;
  where: Enum_Work_Type_Bool_Exp;
}

/** columns and relationships of "events" */
export interface Events {
  __typename?: 'events';
  _hsenc?: Maybe<Scalars['String']['output']>;
  _hsmi?: Maybe<Scalars['String']['output']>;
  _unnamed?: Maybe<Scalars['String']['output']>;
  anonymous_id?: Maybe<Scalars['String']['output']>;
  apcid?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  context_campaign_content?: Maybe<Scalars['String']['output']>;
  context_campaign_id?: Maybe<Scalars['String']['output']>;
  context_campaign_medium?: Maybe<Scalars['String']['output']>;
  context_campaign_name?: Maybe<Scalars['String']['output']>;
  context_campaign_source?: Maybe<Scalars['String']['output']>;
  context_campaign_term?: Maybe<Scalars['String']['output']>;
  context_client_ids_fbc?: Maybe<Scalars['String']['output']>;
  context_client_ids_fbp?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_client_id?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_session_ids?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: Maybe<Scalars['String']['output']>;
  context_geo_city_name?: Maybe<Scalars['String']['output']>;
  context_geo_continent_code?: Maybe<Scalars['String']['output']>;
  context_geo_country_code?: Maybe<Scalars['String']['output']>;
  context_geo_country_is_e_u?: Maybe<Scalars['Boolean']['output']>;
  context_geo_country_name?: Maybe<Scalars['String']['output']>;
  context_geo_location_accuracy_radius?: Maybe<Scalars['bigint']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_timezone?: Maybe<Scalars['String']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['bigint']['output']>;
  context_geo_postal_code_code?: Maybe<Scalars['String']['output']>;
  context_geo_provider_as_name?: Maybe<Scalars['String']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['bigint']['output']>;
  context_geo_provider_domain?: Maybe<Scalars['String']['output']>;
  context_geo_provider_isp?: Maybe<Scalars['String']['output']>;
  context_geo_region_code?: Maybe<Scalars['String']['output']>;
  context_ip?: Maybe<Scalars['String']['output']>;
  context_library_env?: Maybe<Scalars['String']['output']>;
  context_library_name?: Maybe<Scalars['String']['output']>;
  context_library_version?: Maybe<Scalars['String']['output']>;
  context_locale?: Maybe<Scalars['String']['output']>;
  context_page_encoding?: Maybe<Scalars['String']['output']>;
  context_page_host?: Maybe<Scalars['String']['output']>;
  context_page_path?: Maybe<Scalars['String']['output']>;
  context_page_referrer?: Maybe<Scalars['String']['output']>;
  context_page_referring_domain?: Maybe<Scalars['String']['output']>;
  context_page_search?: Maybe<Scalars['String']['output']>;
  context_page_title?: Maybe<Scalars['String']['output']>;
  context_page_url?: Maybe<Scalars['String']['output']>;
  context_screen_density?: Maybe<Scalars['bigint']['output']>;
  context_screen_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_width?: Maybe<Scalars['bigint']['output']>;
  context_screen_width?: Maybe<Scalars['bigint']['output']>;
  context_traits_0?: Maybe<Scalars['String']['output']>;
  context_traits_1?: Maybe<Scalars['String']['output']>;
  context_traits_2?: Maybe<Scalars['String']['output']>;
  context_traits_3?: Maybe<Scalars['String']['output']>;
  context_traits_4?: Maybe<Scalars['String']['output']>;
  context_traits_5?: Maybe<Scalars['String']['output']>;
  context_traits_email?: Maybe<Scalars['String']['output']>;
  context_traits_family_name?: Maybe<Scalars['String']['output']>;
  context_traits_given_name?: Maybe<Scalars['String']['output']>;
  context_traits_id?: Maybe<Scalars['String']['output']>;
  context_traits_role?: Maybe<Scalars['String']['output']>;
  context_user_agent?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employer?: Maybe<Scalars['String']['output']>;
  employer_id?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  event_job: Array<Job>;
  event_job_aggregate: Job_Aggregate;
  external_browser_redirect?: Maybe<Scalars['String']['output']>;
  fbclid?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  gad?: Maybe<Scalars['String']['output']>;
  gad_source?: Maybe<Scalars['String']['output']>;
  gbraid?: Maybe<Scalars['String']['output']>;
  gclid?: Maybe<Scalars['String']['output']>;
  hsa_acc?: Maybe<Scalars['String']['output']>;
  hsa_ad?: Maybe<Scalars['String']['output']>;
  hsa_cam?: Maybe<Scalars['String']['output']>;
  hsa_grp?: Maybe<Scalars['String']['output']>;
  hsa_kw?: Maybe<Scalars['String']['output']>;
  hsa_mt?: Maybe<Scalars['String']['output']>;
  hsa_net?: Maybe<Scalars['String']['output']>;
  hsa_src?: Maybe<Scalars['String']['output']>;
  hsa_tgt?: Maybe<Scalars['String']['output']>;
  hsa_ver?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  job_slug?: Maybe<Scalars['String']['output']>;
  job_type?: Maybe<Scalars['String']['output']>;
  jobs?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  listing_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message_id: Scalars['String']['output'];
  mibextid?: Maybe<Scalars['String']['output']>;
  msclkid?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['String']['output']>;
  pay_type?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['bigint']['output']>;
  received_at?: Maybe<Scalars['timestamptz']['output']>;
  request_ip?: Maybe<Scalars['String']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  sort_by_date?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_content?: Maybe<Scalars['String']['output']>;
  utm_id?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  utm_term?: Maybe<Scalars['String']['output']>;
  wbraid?: Maybe<Scalars['String']['output']>;
  write_key?: Maybe<Scalars['String']['output']>;
}


/** columns and relationships of "events" */
export interface EventsEvent_JobArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


/** columns and relationships of "events" */
export interface EventsEvent_Job_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}

/** aggregated selection of "events" */
export interface Events_Aggregate {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
}

/** aggregate fields of "events" */
export interface Events_Aggregate_Fields {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<Events_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
  stddev?: Maybe<Events_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Sum_Fields>;
  var_pop?: Maybe<Events_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Var_Samp_Fields>;
  variance?: Maybe<Events_Variance_Fields>;
}


/** aggregate fields of "events" */
export interface Events_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Events_Avg_Fields {
  __typename?: 'events_avg_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export interface Events_Bool_Exp {
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _hsenc?: InputMaybe<String_Comparison_Exp>;
  _hsmi?: InputMaybe<String_Comparison_Exp>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  _unnamed?: InputMaybe<String_Comparison_Exp>;
  anonymous_id?: InputMaybe<String_Comparison_Exp>;
  apcid?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  context_campaign_content?: InputMaybe<String_Comparison_Exp>;
  context_campaign_id?: InputMaybe<String_Comparison_Exp>;
  context_campaign_medium?: InputMaybe<String_Comparison_Exp>;
  context_campaign_name?: InputMaybe<String_Comparison_Exp>;
  context_campaign_source?: InputMaybe<String_Comparison_Exp>;
  context_campaign_term?: InputMaybe<String_Comparison_Exp>;
  context_client_ids_fbc?: InputMaybe<String_Comparison_Exp>;
  context_client_ids_fbp?: InputMaybe<String_Comparison_Exp>;
  context_client_ids_ga4_client_id?: InputMaybe<String_Comparison_Exp>;
  context_client_ids_ga4_session_ids?: InputMaybe<String_Comparison_Exp>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: InputMaybe<String_Comparison_Exp>;
  context_geo_city_name?: InputMaybe<String_Comparison_Exp>;
  context_geo_continent_code?: InputMaybe<String_Comparison_Exp>;
  context_geo_country_code?: InputMaybe<String_Comparison_Exp>;
  context_geo_country_is_e_u?: InputMaybe<Boolean_Comparison_Exp>;
  context_geo_country_name?: InputMaybe<String_Comparison_Exp>;
  context_geo_location_accuracy_radius?: InputMaybe<Bigint_Comparison_Exp>;
  context_geo_location_latitude?: InputMaybe<Float8_Comparison_Exp>;
  context_geo_location_longitude?: InputMaybe<Float8_Comparison_Exp>;
  context_geo_location_timezone?: InputMaybe<String_Comparison_Exp>;
  context_geo_location_usa_data_metro_code?: InputMaybe<Bigint_Comparison_Exp>;
  context_geo_postal_code_code?: InputMaybe<String_Comparison_Exp>;
  context_geo_provider_as_name?: InputMaybe<String_Comparison_Exp>;
  context_geo_provider_as_num?: InputMaybe<Bigint_Comparison_Exp>;
  context_geo_provider_domain?: InputMaybe<String_Comparison_Exp>;
  context_geo_provider_isp?: InputMaybe<String_Comparison_Exp>;
  context_geo_region_code?: InputMaybe<String_Comparison_Exp>;
  context_ip?: InputMaybe<String_Comparison_Exp>;
  context_library_env?: InputMaybe<String_Comparison_Exp>;
  context_library_name?: InputMaybe<String_Comparison_Exp>;
  context_library_version?: InputMaybe<String_Comparison_Exp>;
  context_locale?: InputMaybe<String_Comparison_Exp>;
  context_page_encoding?: InputMaybe<String_Comparison_Exp>;
  context_page_host?: InputMaybe<String_Comparison_Exp>;
  context_page_path?: InputMaybe<String_Comparison_Exp>;
  context_page_referrer?: InputMaybe<String_Comparison_Exp>;
  context_page_referring_domain?: InputMaybe<String_Comparison_Exp>;
  context_page_search?: InputMaybe<String_Comparison_Exp>;
  context_page_title?: InputMaybe<String_Comparison_Exp>;
  context_page_url?: InputMaybe<String_Comparison_Exp>;
  context_screen_density?: InputMaybe<Bigint_Comparison_Exp>;
  context_screen_height?: InputMaybe<Bigint_Comparison_Exp>;
  context_screen_inner_height?: InputMaybe<Bigint_Comparison_Exp>;
  context_screen_inner_width?: InputMaybe<Bigint_Comparison_Exp>;
  context_screen_width?: InputMaybe<Bigint_Comparison_Exp>;
  context_traits_0?: InputMaybe<String_Comparison_Exp>;
  context_traits_1?: InputMaybe<String_Comparison_Exp>;
  context_traits_2?: InputMaybe<String_Comparison_Exp>;
  context_traits_3?: InputMaybe<String_Comparison_Exp>;
  context_traits_4?: InputMaybe<String_Comparison_Exp>;
  context_traits_5?: InputMaybe<String_Comparison_Exp>;
  context_traits_email?: InputMaybe<String_Comparison_Exp>;
  context_traits_family_name?: InputMaybe<String_Comparison_Exp>;
  context_traits_given_name?: InputMaybe<String_Comparison_Exp>;
  context_traits_id?: InputMaybe<String_Comparison_Exp>;
  context_traits_role?: InputMaybe<String_Comparison_Exp>;
  context_user_agent?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employer?: InputMaybe<String_Comparison_Exp>;
  employer_id?: InputMaybe<String_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  external_browser_redirect?: InputMaybe<String_Comparison_Exp>;
  fbclid?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  gad?: InputMaybe<String_Comparison_Exp>;
  gad_source?: InputMaybe<String_Comparison_Exp>;
  gbraid?: InputMaybe<String_Comparison_Exp>;
  gclid?: InputMaybe<String_Comparison_Exp>;
  hsa_acc?: InputMaybe<String_Comparison_Exp>;
  hsa_ad?: InputMaybe<String_Comparison_Exp>;
  hsa_cam?: InputMaybe<String_Comparison_Exp>;
  hsa_grp?: InputMaybe<String_Comparison_Exp>;
  hsa_kw?: InputMaybe<String_Comparison_Exp>;
  hsa_mt?: InputMaybe<String_Comparison_Exp>;
  hsa_net?: InputMaybe<String_Comparison_Exp>;
  hsa_src?: InputMaybe<String_Comparison_Exp>;
  hsa_tgt?: InputMaybe<String_Comparison_Exp>;
  hsa_ver?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  job_id?: InputMaybe<String_Comparison_Exp>;
  job_slug?: InputMaybe<String_Comparison_Exp>;
  job_type?: InputMaybe<String_Comparison_Exp>;
  jobs?: InputMaybe<String_Comparison_Exp>;
  keywords?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  listing_type?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  message_id?: InputMaybe<String_Comparison_Exp>;
  mibextid?: InputMaybe<String_Comparison_Exp>;
  msclkid?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  page?: InputMaybe<String_Comparison_Exp>;
  pay_type?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Bigint_Comparison_Exp>;
  received_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  request_ip?: InputMaybe<String_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sort_by_date?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  utm_campaign?: InputMaybe<String_Comparison_Exp>;
  utm_content?: InputMaybe<String_Comparison_Exp>;
  utm_id?: InputMaybe<String_Comparison_Exp>;
  utm_medium?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<String_Comparison_Exp>;
  utm_term?: InputMaybe<String_Comparison_Exp>;
  wbraid?: InputMaybe<String_Comparison_Exp>;
  write_key?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "events" */
export const Events_Constraint = {
  /** unique or primary key constraint on columns "message_id" */
  JitsuPk_10131217b21648eb93f16439d9b6c374: 'jitsu_pk_10131217b21648eb93f16439d9b6c374'
} as const;

export type Events_Constraint = typeof Events_Constraint[keyof typeof Events_Constraint];
/** input type for incrementing numeric columns in table "events" */
export interface Events_Inc_Input {
  context_geo_location_accuracy_radius?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_location_latitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_longitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_usa_data_metro_code?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_provider_as_num?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_density?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_width?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_width?: InputMaybe<Scalars['bigint']['input']>;
  price?: InputMaybe<Scalars['bigint']['input']>;
}

/** input type for inserting data into table "events" */
export interface Events_Insert_Input {
  _hsenc?: InputMaybe<Scalars['String']['input']>;
  _hsmi?: InputMaybe<Scalars['String']['input']>;
  _unnamed?: InputMaybe<Scalars['String']['input']>;
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  apcid?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  context_campaign_content?: InputMaybe<Scalars['String']['input']>;
  context_campaign_id?: InputMaybe<Scalars['String']['input']>;
  context_campaign_medium?: InputMaybe<Scalars['String']['input']>;
  context_campaign_name?: InputMaybe<Scalars['String']['input']>;
  context_campaign_source?: InputMaybe<Scalars['String']['input']>;
  context_campaign_term?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_fbc?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_fbp?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_client_id?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_session_ids?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: InputMaybe<Scalars['String']['input']>;
  context_geo_city_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_continent_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_country_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_country_is_e_u?: InputMaybe<Scalars['Boolean']['input']>;
  context_geo_country_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_location_accuracy_radius?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_location_latitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_longitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_timezone?: InputMaybe<Scalars['String']['input']>;
  context_geo_location_usa_data_metro_code?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_postal_code_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_as_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_as_num?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_provider_domain?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_isp?: InputMaybe<Scalars['String']['input']>;
  context_geo_region_code?: InputMaybe<Scalars['String']['input']>;
  context_ip?: InputMaybe<Scalars['String']['input']>;
  context_library_env?: InputMaybe<Scalars['String']['input']>;
  context_library_name?: InputMaybe<Scalars['String']['input']>;
  context_library_version?: InputMaybe<Scalars['String']['input']>;
  context_locale?: InputMaybe<Scalars['String']['input']>;
  context_page_encoding?: InputMaybe<Scalars['String']['input']>;
  context_page_host?: InputMaybe<Scalars['String']['input']>;
  context_page_path?: InputMaybe<Scalars['String']['input']>;
  context_page_referrer?: InputMaybe<Scalars['String']['input']>;
  context_page_referring_domain?: InputMaybe<Scalars['String']['input']>;
  context_page_search?: InputMaybe<Scalars['String']['input']>;
  context_page_title?: InputMaybe<Scalars['String']['input']>;
  context_page_url?: InputMaybe<Scalars['String']['input']>;
  context_screen_density?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_width?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_width?: InputMaybe<Scalars['bigint']['input']>;
  context_traits_0?: InputMaybe<Scalars['String']['input']>;
  context_traits_1?: InputMaybe<Scalars['String']['input']>;
  context_traits_2?: InputMaybe<Scalars['String']['input']>;
  context_traits_3?: InputMaybe<Scalars['String']['input']>;
  context_traits_4?: InputMaybe<Scalars['String']['input']>;
  context_traits_5?: InputMaybe<Scalars['String']['input']>;
  context_traits_email?: InputMaybe<Scalars['String']['input']>;
  context_traits_family_name?: InputMaybe<Scalars['String']['input']>;
  context_traits_given_name?: InputMaybe<Scalars['String']['input']>;
  context_traits_id?: InputMaybe<Scalars['String']['input']>;
  context_traits_role?: InputMaybe<Scalars['String']['input']>;
  context_user_agent?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  employer_id?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  external_browser_redirect?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  gad?: InputMaybe<Scalars['String']['input']>;
  gad_source?: InputMaybe<Scalars['String']['input']>;
  gbraid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  hsa_acc?: InputMaybe<Scalars['String']['input']>;
  hsa_ad?: InputMaybe<Scalars['String']['input']>;
  hsa_cam?: InputMaybe<Scalars['String']['input']>;
  hsa_grp?: InputMaybe<Scalars['String']['input']>;
  hsa_kw?: InputMaybe<Scalars['String']['input']>;
  hsa_mt?: InputMaybe<Scalars['String']['input']>;
  hsa_net?: InputMaybe<Scalars['String']['input']>;
  hsa_src?: InputMaybe<Scalars['String']['input']>;
  hsa_tgt?: InputMaybe<Scalars['String']['input']>;
  hsa_ver?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  job_slug?: InputMaybe<Scalars['String']['input']>;
  job_type?: InputMaybe<Scalars['String']['input']>;
  jobs?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  listing_type?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  mibextid?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pay_type?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['bigint']['input']>;
  received_at?: InputMaybe<Scalars['timestamptz']['input']>;
  request_ip?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sort_by_date?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  utm_campaign?: InputMaybe<Scalars['String']['input']>;
  utm_content?: InputMaybe<Scalars['String']['input']>;
  utm_id?: InputMaybe<Scalars['String']['input']>;
  utm_medium?: InputMaybe<Scalars['String']['input']>;
  utm_source?: InputMaybe<Scalars['String']['input']>;
  utm_term?: InputMaybe<Scalars['String']['input']>;
  wbraid?: InputMaybe<Scalars['String']['input']>;
  write_key?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Events_Max_Fields {
  __typename?: 'events_max_fields';
  _hsenc?: Maybe<Scalars['String']['output']>;
  _hsmi?: Maybe<Scalars['String']['output']>;
  _unnamed?: Maybe<Scalars['String']['output']>;
  anonymous_id?: Maybe<Scalars['String']['output']>;
  apcid?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  context_campaign_content?: Maybe<Scalars['String']['output']>;
  context_campaign_id?: Maybe<Scalars['String']['output']>;
  context_campaign_medium?: Maybe<Scalars['String']['output']>;
  context_campaign_name?: Maybe<Scalars['String']['output']>;
  context_campaign_source?: Maybe<Scalars['String']['output']>;
  context_campaign_term?: Maybe<Scalars['String']['output']>;
  context_client_ids_fbc?: Maybe<Scalars['String']['output']>;
  context_client_ids_fbp?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_client_id?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_session_ids?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: Maybe<Scalars['String']['output']>;
  context_geo_city_name?: Maybe<Scalars['String']['output']>;
  context_geo_continent_code?: Maybe<Scalars['String']['output']>;
  context_geo_country_code?: Maybe<Scalars['String']['output']>;
  context_geo_country_name?: Maybe<Scalars['String']['output']>;
  context_geo_location_accuracy_radius?: Maybe<Scalars['bigint']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_timezone?: Maybe<Scalars['String']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['bigint']['output']>;
  context_geo_postal_code_code?: Maybe<Scalars['String']['output']>;
  context_geo_provider_as_name?: Maybe<Scalars['String']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['bigint']['output']>;
  context_geo_provider_domain?: Maybe<Scalars['String']['output']>;
  context_geo_provider_isp?: Maybe<Scalars['String']['output']>;
  context_geo_region_code?: Maybe<Scalars['String']['output']>;
  context_ip?: Maybe<Scalars['String']['output']>;
  context_library_env?: Maybe<Scalars['String']['output']>;
  context_library_name?: Maybe<Scalars['String']['output']>;
  context_library_version?: Maybe<Scalars['String']['output']>;
  context_locale?: Maybe<Scalars['String']['output']>;
  context_page_encoding?: Maybe<Scalars['String']['output']>;
  context_page_host?: Maybe<Scalars['String']['output']>;
  context_page_path?: Maybe<Scalars['String']['output']>;
  context_page_referrer?: Maybe<Scalars['String']['output']>;
  context_page_referring_domain?: Maybe<Scalars['String']['output']>;
  context_page_search?: Maybe<Scalars['String']['output']>;
  context_page_title?: Maybe<Scalars['String']['output']>;
  context_page_url?: Maybe<Scalars['String']['output']>;
  context_screen_density?: Maybe<Scalars['bigint']['output']>;
  context_screen_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_width?: Maybe<Scalars['bigint']['output']>;
  context_screen_width?: Maybe<Scalars['bigint']['output']>;
  context_traits_0?: Maybe<Scalars['String']['output']>;
  context_traits_1?: Maybe<Scalars['String']['output']>;
  context_traits_2?: Maybe<Scalars['String']['output']>;
  context_traits_3?: Maybe<Scalars['String']['output']>;
  context_traits_4?: Maybe<Scalars['String']['output']>;
  context_traits_5?: Maybe<Scalars['String']['output']>;
  context_traits_email?: Maybe<Scalars['String']['output']>;
  context_traits_family_name?: Maybe<Scalars['String']['output']>;
  context_traits_given_name?: Maybe<Scalars['String']['output']>;
  context_traits_id?: Maybe<Scalars['String']['output']>;
  context_traits_role?: Maybe<Scalars['String']['output']>;
  context_user_agent?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employer?: Maybe<Scalars['String']['output']>;
  employer_id?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  external_browser_redirect?: Maybe<Scalars['String']['output']>;
  fbclid?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  gad?: Maybe<Scalars['String']['output']>;
  gad_source?: Maybe<Scalars['String']['output']>;
  gbraid?: Maybe<Scalars['String']['output']>;
  gclid?: Maybe<Scalars['String']['output']>;
  hsa_acc?: Maybe<Scalars['String']['output']>;
  hsa_ad?: Maybe<Scalars['String']['output']>;
  hsa_cam?: Maybe<Scalars['String']['output']>;
  hsa_grp?: Maybe<Scalars['String']['output']>;
  hsa_kw?: Maybe<Scalars['String']['output']>;
  hsa_mt?: Maybe<Scalars['String']['output']>;
  hsa_net?: Maybe<Scalars['String']['output']>;
  hsa_src?: Maybe<Scalars['String']['output']>;
  hsa_tgt?: Maybe<Scalars['String']['output']>;
  hsa_ver?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  job_slug?: Maybe<Scalars['String']['output']>;
  job_type?: Maybe<Scalars['String']['output']>;
  jobs?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  listing_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message_id?: Maybe<Scalars['String']['output']>;
  mibextid?: Maybe<Scalars['String']['output']>;
  msclkid?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['String']['output']>;
  pay_type?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['bigint']['output']>;
  received_at?: Maybe<Scalars['timestamptz']['output']>;
  request_ip?: Maybe<Scalars['String']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  sort_by_date?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_content?: Maybe<Scalars['String']['output']>;
  utm_id?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  utm_term?: Maybe<Scalars['String']['output']>;
  wbraid?: Maybe<Scalars['String']['output']>;
  write_key?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Events_Min_Fields {
  __typename?: 'events_min_fields';
  _hsenc?: Maybe<Scalars['String']['output']>;
  _hsmi?: Maybe<Scalars['String']['output']>;
  _unnamed?: Maybe<Scalars['String']['output']>;
  anonymous_id?: Maybe<Scalars['String']['output']>;
  apcid?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  context_campaign_content?: Maybe<Scalars['String']['output']>;
  context_campaign_id?: Maybe<Scalars['String']['output']>;
  context_campaign_medium?: Maybe<Scalars['String']['output']>;
  context_campaign_name?: Maybe<Scalars['String']['output']>;
  context_campaign_source?: Maybe<Scalars['String']['output']>;
  context_campaign_term?: Maybe<Scalars['String']['output']>;
  context_client_ids_fbc?: Maybe<Scalars['String']['output']>;
  context_client_ids_fbp?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_client_id?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_session_ids?: Maybe<Scalars['String']['output']>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: Maybe<Scalars['String']['output']>;
  context_geo_city_name?: Maybe<Scalars['String']['output']>;
  context_geo_continent_code?: Maybe<Scalars['String']['output']>;
  context_geo_country_code?: Maybe<Scalars['String']['output']>;
  context_geo_country_name?: Maybe<Scalars['String']['output']>;
  context_geo_location_accuracy_radius?: Maybe<Scalars['bigint']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_timezone?: Maybe<Scalars['String']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['bigint']['output']>;
  context_geo_postal_code_code?: Maybe<Scalars['String']['output']>;
  context_geo_provider_as_name?: Maybe<Scalars['String']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['bigint']['output']>;
  context_geo_provider_domain?: Maybe<Scalars['String']['output']>;
  context_geo_provider_isp?: Maybe<Scalars['String']['output']>;
  context_geo_region_code?: Maybe<Scalars['String']['output']>;
  context_ip?: Maybe<Scalars['String']['output']>;
  context_library_env?: Maybe<Scalars['String']['output']>;
  context_library_name?: Maybe<Scalars['String']['output']>;
  context_library_version?: Maybe<Scalars['String']['output']>;
  context_locale?: Maybe<Scalars['String']['output']>;
  context_page_encoding?: Maybe<Scalars['String']['output']>;
  context_page_host?: Maybe<Scalars['String']['output']>;
  context_page_path?: Maybe<Scalars['String']['output']>;
  context_page_referrer?: Maybe<Scalars['String']['output']>;
  context_page_referring_domain?: Maybe<Scalars['String']['output']>;
  context_page_search?: Maybe<Scalars['String']['output']>;
  context_page_title?: Maybe<Scalars['String']['output']>;
  context_page_url?: Maybe<Scalars['String']['output']>;
  context_screen_density?: Maybe<Scalars['bigint']['output']>;
  context_screen_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_width?: Maybe<Scalars['bigint']['output']>;
  context_screen_width?: Maybe<Scalars['bigint']['output']>;
  context_traits_0?: Maybe<Scalars['String']['output']>;
  context_traits_1?: Maybe<Scalars['String']['output']>;
  context_traits_2?: Maybe<Scalars['String']['output']>;
  context_traits_3?: Maybe<Scalars['String']['output']>;
  context_traits_4?: Maybe<Scalars['String']['output']>;
  context_traits_5?: Maybe<Scalars['String']['output']>;
  context_traits_email?: Maybe<Scalars['String']['output']>;
  context_traits_family_name?: Maybe<Scalars['String']['output']>;
  context_traits_given_name?: Maybe<Scalars['String']['output']>;
  context_traits_id?: Maybe<Scalars['String']['output']>;
  context_traits_role?: Maybe<Scalars['String']['output']>;
  context_user_agent?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employer?: Maybe<Scalars['String']['output']>;
  employer_id?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  external_browser_redirect?: Maybe<Scalars['String']['output']>;
  fbclid?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  gad?: Maybe<Scalars['String']['output']>;
  gad_source?: Maybe<Scalars['String']['output']>;
  gbraid?: Maybe<Scalars['String']['output']>;
  gclid?: Maybe<Scalars['String']['output']>;
  hsa_acc?: Maybe<Scalars['String']['output']>;
  hsa_ad?: Maybe<Scalars['String']['output']>;
  hsa_cam?: Maybe<Scalars['String']['output']>;
  hsa_grp?: Maybe<Scalars['String']['output']>;
  hsa_kw?: Maybe<Scalars['String']['output']>;
  hsa_mt?: Maybe<Scalars['String']['output']>;
  hsa_net?: Maybe<Scalars['String']['output']>;
  hsa_src?: Maybe<Scalars['String']['output']>;
  hsa_tgt?: Maybe<Scalars['String']['output']>;
  hsa_ver?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  job_slug?: Maybe<Scalars['String']['output']>;
  job_type?: Maybe<Scalars['String']['output']>;
  jobs?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  listing_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  message_id?: Maybe<Scalars['String']['output']>;
  mibextid?: Maybe<Scalars['String']['output']>;
  msclkid?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['String']['output']>;
  pay_type?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['bigint']['output']>;
  received_at?: Maybe<Scalars['timestamptz']['output']>;
  request_ip?: Maybe<Scalars['String']['output']>;
  sent_at?: Maybe<Scalars['timestamptz']['output']>;
  sort_by_date?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['timestamptz']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_content?: Maybe<Scalars['String']['output']>;
  utm_id?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  utm_term?: Maybe<Scalars['String']['output']>;
  wbraid?: Maybe<Scalars['String']['output']>;
  write_key?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "events" */
export interface Events_Mutation_Response {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
}

/** on_conflict condition type for table "events" */
export interface Events_On_Conflict {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
}

/** Ordering options when selecting data from "events". */
export interface Events_Order_By {
  _hsenc?: InputMaybe<Order_By>;
  _hsmi?: InputMaybe<Order_By>;
  _unnamed?: InputMaybe<Order_By>;
  anonymous_id?: InputMaybe<Order_By>;
  apcid?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  context_campaign_content?: InputMaybe<Order_By>;
  context_campaign_id?: InputMaybe<Order_By>;
  context_campaign_medium?: InputMaybe<Order_By>;
  context_campaign_name?: InputMaybe<Order_By>;
  context_campaign_source?: InputMaybe<Order_By>;
  context_campaign_term?: InputMaybe<Order_By>;
  context_client_ids_fbc?: InputMaybe<Order_By>;
  context_client_ids_fbp?: InputMaybe<Order_By>;
  context_client_ids_ga4_client_id?: InputMaybe<Order_By>;
  context_client_ids_ga4_session_ids?: InputMaybe<Order_By>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: InputMaybe<Order_By>;
  context_geo_city_name?: InputMaybe<Order_By>;
  context_geo_continent_code?: InputMaybe<Order_By>;
  context_geo_country_code?: InputMaybe<Order_By>;
  context_geo_country_is_e_u?: InputMaybe<Order_By>;
  context_geo_country_name?: InputMaybe<Order_By>;
  context_geo_location_accuracy_radius?: InputMaybe<Order_By>;
  context_geo_location_latitude?: InputMaybe<Order_By>;
  context_geo_location_longitude?: InputMaybe<Order_By>;
  context_geo_location_timezone?: InputMaybe<Order_By>;
  context_geo_location_usa_data_metro_code?: InputMaybe<Order_By>;
  context_geo_postal_code_code?: InputMaybe<Order_By>;
  context_geo_provider_as_name?: InputMaybe<Order_By>;
  context_geo_provider_as_num?: InputMaybe<Order_By>;
  context_geo_provider_domain?: InputMaybe<Order_By>;
  context_geo_provider_isp?: InputMaybe<Order_By>;
  context_geo_region_code?: InputMaybe<Order_By>;
  context_ip?: InputMaybe<Order_By>;
  context_library_env?: InputMaybe<Order_By>;
  context_library_name?: InputMaybe<Order_By>;
  context_library_version?: InputMaybe<Order_By>;
  context_locale?: InputMaybe<Order_By>;
  context_page_encoding?: InputMaybe<Order_By>;
  context_page_host?: InputMaybe<Order_By>;
  context_page_path?: InputMaybe<Order_By>;
  context_page_referrer?: InputMaybe<Order_By>;
  context_page_referring_domain?: InputMaybe<Order_By>;
  context_page_search?: InputMaybe<Order_By>;
  context_page_title?: InputMaybe<Order_By>;
  context_page_url?: InputMaybe<Order_By>;
  context_screen_density?: InputMaybe<Order_By>;
  context_screen_height?: InputMaybe<Order_By>;
  context_screen_inner_height?: InputMaybe<Order_By>;
  context_screen_inner_width?: InputMaybe<Order_By>;
  context_screen_width?: InputMaybe<Order_By>;
  context_traits_0?: InputMaybe<Order_By>;
  context_traits_1?: InputMaybe<Order_By>;
  context_traits_2?: InputMaybe<Order_By>;
  context_traits_3?: InputMaybe<Order_By>;
  context_traits_4?: InputMaybe<Order_By>;
  context_traits_5?: InputMaybe<Order_By>;
  context_traits_email?: InputMaybe<Order_By>;
  context_traits_family_name?: InputMaybe<Order_By>;
  context_traits_given_name?: InputMaybe<Order_By>;
  context_traits_id?: InputMaybe<Order_By>;
  context_traits_role?: InputMaybe<Order_By>;
  context_user_agent?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employer?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  external_browser_redirect?: InputMaybe<Order_By>;
  fbclid?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  gad?: InputMaybe<Order_By>;
  gad_source?: InputMaybe<Order_By>;
  gbraid?: InputMaybe<Order_By>;
  gclid?: InputMaybe<Order_By>;
  hsa_acc?: InputMaybe<Order_By>;
  hsa_ad?: InputMaybe<Order_By>;
  hsa_cam?: InputMaybe<Order_By>;
  hsa_grp?: InputMaybe<Order_By>;
  hsa_kw?: InputMaybe<Order_By>;
  hsa_mt?: InputMaybe<Order_By>;
  hsa_net?: InputMaybe<Order_By>;
  hsa_src?: InputMaybe<Order_By>;
  hsa_tgt?: InputMaybe<Order_By>;
  hsa_ver?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_slug?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  jobs?: InputMaybe<Order_By>;
  keywords?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  listing_type?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  mibextid?: InputMaybe<Order_By>;
  msclkid?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
  pay_type?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  received_at?: InputMaybe<Order_By>;
  request_ip?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  sort_by_date?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_id?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  utm_term?: InputMaybe<Order_By>;
  wbraid?: InputMaybe<Order_By>;
  write_key?: InputMaybe<Order_By>;
}

/** primary key columns input for table: events */
export interface Events_Pk_Columns_Input {
  message_id: Scalars['String']['input'];
}

/** select columns of table "events" */
export const Events_Select_Column = {
  /** column name */
  Hsenc: '_hsenc',
  /** column name */
  Hsmi: '_hsmi',
  /** column name */
  Unnamed: '_unnamed',
  /** column name */
  AnonymousId: 'anonymous_id',
  /** column name */
  Apcid: 'apcid',
  /** column name */
  Category: 'category',
  /** column name */
  Company: 'company',
  /** column name */
  ContextCampaignContent: 'context_campaign_content',
  /** column name */
  ContextCampaignId: 'context_campaign_id',
  /** column name */
  ContextCampaignMedium: 'context_campaign_medium',
  /** column name */
  ContextCampaignName: 'context_campaign_name',
  /** column name */
  ContextCampaignSource: 'context_campaign_source',
  /** column name */
  ContextCampaignTerm: 'context_campaign_term',
  /** column name */
  ContextClientIdsFbc: 'context_client_ids_fbc',
  /** column name */
  ContextClientIdsFbp: 'context_client_ids_fbp',
  /** column name */
  ContextClientIdsGa4ClientId: 'context_client_ids_ga4_client_id',
  /** column name */
  ContextClientIdsGa4SessionIds: 'context_client_ids_ga4_session_ids',
  /** column name */
  ContextClientIdsGa4SessionsVRM6386DY4: 'context_client_ids_ga4_sessions_v_r_m6386_d_y4',
  /** column name */
  ContextGeoCityName: 'context_geo_city_name',
  /** column name */
  ContextGeoContinentCode: 'context_geo_continent_code',
  /** column name */
  ContextGeoCountryCode: 'context_geo_country_code',
  /** column name */
  ContextGeoCountryIsEU: 'context_geo_country_is_e_u',
  /** column name */
  ContextGeoCountryName: 'context_geo_country_name',
  /** column name */
  ContextGeoLocationAccuracyRadius: 'context_geo_location_accuracy_radius',
  /** column name */
  ContextGeoLocationLatitude: 'context_geo_location_latitude',
  /** column name */
  ContextGeoLocationLongitude: 'context_geo_location_longitude',
  /** column name */
  ContextGeoLocationTimezone: 'context_geo_location_timezone',
  /** column name */
  ContextGeoLocationUsaDataMetroCode: 'context_geo_location_usa_data_metro_code',
  /** column name */
  ContextGeoPostalCodeCode: 'context_geo_postal_code_code',
  /** column name */
  ContextGeoProviderAsName: 'context_geo_provider_as_name',
  /** column name */
  ContextGeoProviderAsNum: 'context_geo_provider_as_num',
  /** column name */
  ContextGeoProviderDomain: 'context_geo_provider_domain',
  /** column name */
  ContextGeoProviderIsp: 'context_geo_provider_isp',
  /** column name */
  ContextGeoRegionCode: 'context_geo_region_code',
  /** column name */
  ContextIp: 'context_ip',
  /** column name */
  ContextLibraryEnv: 'context_library_env',
  /** column name */
  ContextLibraryName: 'context_library_name',
  /** column name */
  ContextLibraryVersion: 'context_library_version',
  /** column name */
  ContextLocale: 'context_locale',
  /** column name */
  ContextPageEncoding: 'context_page_encoding',
  /** column name */
  ContextPageHost: 'context_page_host',
  /** column name */
  ContextPagePath: 'context_page_path',
  /** column name */
  ContextPageReferrer: 'context_page_referrer',
  /** column name */
  ContextPageReferringDomain: 'context_page_referring_domain',
  /** column name */
  ContextPageSearch: 'context_page_search',
  /** column name */
  ContextPageTitle: 'context_page_title',
  /** column name */
  ContextPageUrl: 'context_page_url',
  /** column name */
  ContextScreenDensity: 'context_screen_density',
  /** column name */
  ContextScreenHeight: 'context_screen_height',
  /** column name */
  ContextScreenInnerHeight: 'context_screen_inner_height',
  /** column name */
  ContextScreenInnerWidth: 'context_screen_inner_width',
  /** column name */
  ContextScreenWidth: 'context_screen_width',
  /** column name */
  ContextTraits_0: 'context_traits_0',
  /** column name */
  ContextTraits_1: 'context_traits_1',
  /** column name */
  ContextTraits_2: 'context_traits_2',
  /** column name */
  ContextTraits_3: 'context_traits_3',
  /** column name */
  ContextTraits_4: 'context_traits_4',
  /** column name */
  ContextTraits_5: 'context_traits_5',
  /** column name */
  ContextTraitsEmail: 'context_traits_email',
  /** column name */
  ContextTraitsFamilyName: 'context_traits_family_name',
  /** column name */
  ContextTraitsGivenName: 'context_traits_given_name',
  /** column name */
  ContextTraitsId: 'context_traits_id',
  /** column name */
  ContextTraitsRole: 'context_traits_role',
  /** column name */
  ContextUserAgent: 'context_user_agent',
  /** column name */
  Email: 'email',
  /** column name */
  Employer: 'employer',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Event: 'event',
  /** column name */
  ExternalBrowserRedirect: 'external_browser_redirect',
  /** column name */
  Fbclid: 'fbclid',
  /** column name */
  FirstName: 'first_name',
  /** column name */
  From: 'from',
  /** column name */
  Gad: 'gad',
  /** column name */
  GadSource: 'gad_source',
  /** column name */
  Gbraid: 'gbraid',
  /** column name */
  Gclid: 'gclid',
  /** column name */
  HsaAcc: 'hsa_acc',
  /** column name */
  HsaAd: 'hsa_ad',
  /** column name */
  HsaCam: 'hsa_cam',
  /** column name */
  HsaGrp: 'hsa_grp',
  /** column name */
  HsaKw: 'hsa_kw',
  /** column name */
  HsaMt: 'hsa_mt',
  /** column name */
  HsaNet: 'hsa_net',
  /** column name */
  HsaSrc: 'hsa_src',
  /** column name */
  HsaTgt: 'hsa_tgt',
  /** column name */
  HsaVer: 'hsa_ver',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  JobSlug: 'job_slug',
  /** column name */
  JobType: 'job_type',
  /** column name */
  Jobs: 'jobs',
  /** column name */
  Keywords: 'keywords',
  /** column name */
  LastName: 'last_name',
  /** column name */
  ListingType: 'listing_type',
  /** column name */
  Location: 'location',
  /** column name */
  MessageId: 'message_id',
  /** column name */
  Mibextid: 'mibextid',
  /** column name */
  Msclkid: 'msclkid',
  /** column name */
  Origin: 'origin',
  /** column name */
  Page: 'page',
  /** column name */
  PayType: 'pay_type',
  /** column name */
  Phone: 'phone',
  /** column name */
  Price: 'price',
  /** column name */
  ReceivedAt: 'received_at',
  /** column name */
  RequestIp: 'request_ip',
  /** column name */
  SentAt: 'sent_at',
  /** column name */
  SortByDate: 'sort_by_date',
  /** column name */
  Timestamp: 'timestamp',
  /** column name */
  Title: 'title',
  /** column name */
  To: 'to',
  /** column name */
  Type: 'type',
  /** column name */
  UserId: 'user_id',
  /** column name */
  UtmCampaign: 'utm_campaign',
  /** column name */
  UtmContent: 'utm_content',
  /** column name */
  UtmId: 'utm_id',
  /** column name */
  UtmMedium: 'utm_medium',
  /** column name */
  UtmSource: 'utm_source',
  /** column name */
  UtmTerm: 'utm_term',
  /** column name */
  Wbraid: 'wbraid',
  /** column name */
  WriteKey: 'write_key'
} as const;

export type Events_Select_Column = typeof Events_Select_Column[keyof typeof Events_Select_Column];
/** input type for updating data in table "events" */
export interface Events_Set_Input {
  _hsenc?: InputMaybe<Scalars['String']['input']>;
  _hsmi?: InputMaybe<Scalars['String']['input']>;
  _unnamed?: InputMaybe<Scalars['String']['input']>;
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  apcid?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  context_campaign_content?: InputMaybe<Scalars['String']['input']>;
  context_campaign_id?: InputMaybe<Scalars['String']['input']>;
  context_campaign_medium?: InputMaybe<Scalars['String']['input']>;
  context_campaign_name?: InputMaybe<Scalars['String']['input']>;
  context_campaign_source?: InputMaybe<Scalars['String']['input']>;
  context_campaign_term?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_fbc?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_fbp?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_client_id?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_session_ids?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: InputMaybe<Scalars['String']['input']>;
  context_geo_city_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_continent_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_country_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_country_is_e_u?: InputMaybe<Scalars['Boolean']['input']>;
  context_geo_country_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_location_accuracy_radius?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_location_latitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_longitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_timezone?: InputMaybe<Scalars['String']['input']>;
  context_geo_location_usa_data_metro_code?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_postal_code_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_as_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_as_num?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_provider_domain?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_isp?: InputMaybe<Scalars['String']['input']>;
  context_geo_region_code?: InputMaybe<Scalars['String']['input']>;
  context_ip?: InputMaybe<Scalars['String']['input']>;
  context_library_env?: InputMaybe<Scalars['String']['input']>;
  context_library_name?: InputMaybe<Scalars['String']['input']>;
  context_library_version?: InputMaybe<Scalars['String']['input']>;
  context_locale?: InputMaybe<Scalars['String']['input']>;
  context_page_encoding?: InputMaybe<Scalars['String']['input']>;
  context_page_host?: InputMaybe<Scalars['String']['input']>;
  context_page_path?: InputMaybe<Scalars['String']['input']>;
  context_page_referrer?: InputMaybe<Scalars['String']['input']>;
  context_page_referring_domain?: InputMaybe<Scalars['String']['input']>;
  context_page_search?: InputMaybe<Scalars['String']['input']>;
  context_page_title?: InputMaybe<Scalars['String']['input']>;
  context_page_url?: InputMaybe<Scalars['String']['input']>;
  context_screen_density?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_width?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_width?: InputMaybe<Scalars['bigint']['input']>;
  context_traits_0?: InputMaybe<Scalars['String']['input']>;
  context_traits_1?: InputMaybe<Scalars['String']['input']>;
  context_traits_2?: InputMaybe<Scalars['String']['input']>;
  context_traits_3?: InputMaybe<Scalars['String']['input']>;
  context_traits_4?: InputMaybe<Scalars['String']['input']>;
  context_traits_5?: InputMaybe<Scalars['String']['input']>;
  context_traits_email?: InputMaybe<Scalars['String']['input']>;
  context_traits_family_name?: InputMaybe<Scalars['String']['input']>;
  context_traits_given_name?: InputMaybe<Scalars['String']['input']>;
  context_traits_id?: InputMaybe<Scalars['String']['input']>;
  context_traits_role?: InputMaybe<Scalars['String']['input']>;
  context_user_agent?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  employer_id?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  external_browser_redirect?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  gad?: InputMaybe<Scalars['String']['input']>;
  gad_source?: InputMaybe<Scalars['String']['input']>;
  gbraid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  hsa_acc?: InputMaybe<Scalars['String']['input']>;
  hsa_ad?: InputMaybe<Scalars['String']['input']>;
  hsa_cam?: InputMaybe<Scalars['String']['input']>;
  hsa_grp?: InputMaybe<Scalars['String']['input']>;
  hsa_kw?: InputMaybe<Scalars['String']['input']>;
  hsa_mt?: InputMaybe<Scalars['String']['input']>;
  hsa_net?: InputMaybe<Scalars['String']['input']>;
  hsa_src?: InputMaybe<Scalars['String']['input']>;
  hsa_tgt?: InputMaybe<Scalars['String']['input']>;
  hsa_ver?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  job_slug?: InputMaybe<Scalars['String']['input']>;
  job_type?: InputMaybe<Scalars['String']['input']>;
  jobs?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  listing_type?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  mibextid?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pay_type?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['bigint']['input']>;
  received_at?: InputMaybe<Scalars['timestamptz']['input']>;
  request_ip?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sort_by_date?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  utm_campaign?: InputMaybe<Scalars['String']['input']>;
  utm_content?: InputMaybe<Scalars['String']['input']>;
  utm_id?: InputMaybe<Scalars['String']['input']>;
  utm_medium?: InputMaybe<Scalars['String']['input']>;
  utm_source?: InputMaybe<Scalars['String']['input']>;
  utm_term?: InputMaybe<Scalars['String']['input']>;
  wbraid?: InputMaybe<Scalars['String']['input']>;
  write_key?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Events_Stddev_Fields {
  __typename?: 'events_stddev_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Events_Stddev_Pop_Fields {
  __typename?: 'events_stddev_pop_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Events_Stddev_Samp_Fields {
  __typename?: 'events_stddev_samp_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "events" */
export interface Events_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Events_Stream_Cursor_Value_Input {
  _hsenc?: InputMaybe<Scalars['String']['input']>;
  _hsmi?: InputMaybe<Scalars['String']['input']>;
  _unnamed?: InputMaybe<Scalars['String']['input']>;
  anonymous_id?: InputMaybe<Scalars['String']['input']>;
  apcid?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  context_campaign_content?: InputMaybe<Scalars['String']['input']>;
  context_campaign_id?: InputMaybe<Scalars['String']['input']>;
  context_campaign_medium?: InputMaybe<Scalars['String']['input']>;
  context_campaign_name?: InputMaybe<Scalars['String']['input']>;
  context_campaign_source?: InputMaybe<Scalars['String']['input']>;
  context_campaign_term?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_fbc?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_fbp?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_client_id?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_session_ids?: InputMaybe<Scalars['String']['input']>;
  context_client_ids_ga4_sessions_v_r_m6386_d_y4?: InputMaybe<Scalars['String']['input']>;
  context_geo_city_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_continent_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_country_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_country_is_e_u?: InputMaybe<Scalars['Boolean']['input']>;
  context_geo_country_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_location_accuracy_radius?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_location_latitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_longitude?: InputMaybe<Scalars['float8']['input']>;
  context_geo_location_timezone?: InputMaybe<Scalars['String']['input']>;
  context_geo_location_usa_data_metro_code?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_postal_code_code?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_as_name?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_as_num?: InputMaybe<Scalars['bigint']['input']>;
  context_geo_provider_domain?: InputMaybe<Scalars['String']['input']>;
  context_geo_provider_isp?: InputMaybe<Scalars['String']['input']>;
  context_geo_region_code?: InputMaybe<Scalars['String']['input']>;
  context_ip?: InputMaybe<Scalars['String']['input']>;
  context_library_env?: InputMaybe<Scalars['String']['input']>;
  context_library_name?: InputMaybe<Scalars['String']['input']>;
  context_library_version?: InputMaybe<Scalars['String']['input']>;
  context_locale?: InputMaybe<Scalars['String']['input']>;
  context_page_encoding?: InputMaybe<Scalars['String']['input']>;
  context_page_host?: InputMaybe<Scalars['String']['input']>;
  context_page_path?: InputMaybe<Scalars['String']['input']>;
  context_page_referrer?: InputMaybe<Scalars['String']['input']>;
  context_page_referring_domain?: InputMaybe<Scalars['String']['input']>;
  context_page_search?: InputMaybe<Scalars['String']['input']>;
  context_page_title?: InputMaybe<Scalars['String']['input']>;
  context_page_url?: InputMaybe<Scalars['String']['input']>;
  context_screen_density?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_height?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_inner_width?: InputMaybe<Scalars['bigint']['input']>;
  context_screen_width?: InputMaybe<Scalars['bigint']['input']>;
  context_traits_0?: InputMaybe<Scalars['String']['input']>;
  context_traits_1?: InputMaybe<Scalars['String']['input']>;
  context_traits_2?: InputMaybe<Scalars['String']['input']>;
  context_traits_3?: InputMaybe<Scalars['String']['input']>;
  context_traits_4?: InputMaybe<Scalars['String']['input']>;
  context_traits_5?: InputMaybe<Scalars['String']['input']>;
  context_traits_email?: InputMaybe<Scalars['String']['input']>;
  context_traits_family_name?: InputMaybe<Scalars['String']['input']>;
  context_traits_given_name?: InputMaybe<Scalars['String']['input']>;
  context_traits_id?: InputMaybe<Scalars['String']['input']>;
  context_traits_role?: InputMaybe<Scalars['String']['input']>;
  context_user_agent?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  employer_id?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  external_browser_redirect?: InputMaybe<Scalars['String']['input']>;
  fbclid?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  gad?: InputMaybe<Scalars['String']['input']>;
  gad_source?: InputMaybe<Scalars['String']['input']>;
  gbraid?: InputMaybe<Scalars['String']['input']>;
  gclid?: InputMaybe<Scalars['String']['input']>;
  hsa_acc?: InputMaybe<Scalars['String']['input']>;
  hsa_ad?: InputMaybe<Scalars['String']['input']>;
  hsa_cam?: InputMaybe<Scalars['String']['input']>;
  hsa_grp?: InputMaybe<Scalars['String']['input']>;
  hsa_kw?: InputMaybe<Scalars['String']['input']>;
  hsa_mt?: InputMaybe<Scalars['String']['input']>;
  hsa_net?: InputMaybe<Scalars['String']['input']>;
  hsa_src?: InputMaybe<Scalars['String']['input']>;
  hsa_tgt?: InputMaybe<Scalars['String']['input']>;
  hsa_ver?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  job_slug?: InputMaybe<Scalars['String']['input']>;
  job_type?: InputMaybe<Scalars['String']['input']>;
  jobs?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  listing_type?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message_id?: InputMaybe<Scalars['String']['input']>;
  mibextid?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pay_type?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['bigint']['input']>;
  received_at?: InputMaybe<Scalars['timestamptz']['input']>;
  request_ip?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sort_by_date?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  utm_campaign?: InputMaybe<Scalars['String']['input']>;
  utm_content?: InputMaybe<Scalars['String']['input']>;
  utm_id?: InputMaybe<Scalars['String']['input']>;
  utm_medium?: InputMaybe<Scalars['String']['input']>;
  utm_source?: InputMaybe<Scalars['String']['input']>;
  utm_term?: InputMaybe<Scalars['String']['input']>;
  wbraid?: InputMaybe<Scalars['String']['input']>;
  write_key?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Events_Sum_Fields {
  __typename?: 'events_sum_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['bigint']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['float8']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['bigint']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['bigint']['output']>;
  context_screen_density?: Maybe<Scalars['bigint']['output']>;
  context_screen_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_height?: Maybe<Scalars['bigint']['output']>;
  context_screen_inner_width?: Maybe<Scalars['bigint']['output']>;
  context_screen_width?: Maybe<Scalars['bigint']['output']>;
  price?: Maybe<Scalars['bigint']['output']>;
}

/** update columns of table "events" */
export const Events_Update_Column = {
  /** column name */
  Hsenc: '_hsenc',
  /** column name */
  Hsmi: '_hsmi',
  /** column name */
  Unnamed: '_unnamed',
  /** column name */
  AnonymousId: 'anonymous_id',
  /** column name */
  Apcid: 'apcid',
  /** column name */
  Category: 'category',
  /** column name */
  Company: 'company',
  /** column name */
  ContextCampaignContent: 'context_campaign_content',
  /** column name */
  ContextCampaignId: 'context_campaign_id',
  /** column name */
  ContextCampaignMedium: 'context_campaign_medium',
  /** column name */
  ContextCampaignName: 'context_campaign_name',
  /** column name */
  ContextCampaignSource: 'context_campaign_source',
  /** column name */
  ContextCampaignTerm: 'context_campaign_term',
  /** column name */
  ContextClientIdsFbc: 'context_client_ids_fbc',
  /** column name */
  ContextClientIdsFbp: 'context_client_ids_fbp',
  /** column name */
  ContextClientIdsGa4ClientId: 'context_client_ids_ga4_client_id',
  /** column name */
  ContextClientIdsGa4SessionIds: 'context_client_ids_ga4_session_ids',
  /** column name */
  ContextClientIdsGa4SessionsVRM6386DY4: 'context_client_ids_ga4_sessions_v_r_m6386_d_y4',
  /** column name */
  ContextGeoCityName: 'context_geo_city_name',
  /** column name */
  ContextGeoContinentCode: 'context_geo_continent_code',
  /** column name */
  ContextGeoCountryCode: 'context_geo_country_code',
  /** column name */
  ContextGeoCountryIsEU: 'context_geo_country_is_e_u',
  /** column name */
  ContextGeoCountryName: 'context_geo_country_name',
  /** column name */
  ContextGeoLocationAccuracyRadius: 'context_geo_location_accuracy_radius',
  /** column name */
  ContextGeoLocationLatitude: 'context_geo_location_latitude',
  /** column name */
  ContextGeoLocationLongitude: 'context_geo_location_longitude',
  /** column name */
  ContextGeoLocationTimezone: 'context_geo_location_timezone',
  /** column name */
  ContextGeoLocationUsaDataMetroCode: 'context_geo_location_usa_data_metro_code',
  /** column name */
  ContextGeoPostalCodeCode: 'context_geo_postal_code_code',
  /** column name */
  ContextGeoProviderAsName: 'context_geo_provider_as_name',
  /** column name */
  ContextGeoProviderAsNum: 'context_geo_provider_as_num',
  /** column name */
  ContextGeoProviderDomain: 'context_geo_provider_domain',
  /** column name */
  ContextGeoProviderIsp: 'context_geo_provider_isp',
  /** column name */
  ContextGeoRegionCode: 'context_geo_region_code',
  /** column name */
  ContextIp: 'context_ip',
  /** column name */
  ContextLibraryEnv: 'context_library_env',
  /** column name */
  ContextLibraryName: 'context_library_name',
  /** column name */
  ContextLibraryVersion: 'context_library_version',
  /** column name */
  ContextLocale: 'context_locale',
  /** column name */
  ContextPageEncoding: 'context_page_encoding',
  /** column name */
  ContextPageHost: 'context_page_host',
  /** column name */
  ContextPagePath: 'context_page_path',
  /** column name */
  ContextPageReferrer: 'context_page_referrer',
  /** column name */
  ContextPageReferringDomain: 'context_page_referring_domain',
  /** column name */
  ContextPageSearch: 'context_page_search',
  /** column name */
  ContextPageTitle: 'context_page_title',
  /** column name */
  ContextPageUrl: 'context_page_url',
  /** column name */
  ContextScreenDensity: 'context_screen_density',
  /** column name */
  ContextScreenHeight: 'context_screen_height',
  /** column name */
  ContextScreenInnerHeight: 'context_screen_inner_height',
  /** column name */
  ContextScreenInnerWidth: 'context_screen_inner_width',
  /** column name */
  ContextScreenWidth: 'context_screen_width',
  /** column name */
  ContextTraits_0: 'context_traits_0',
  /** column name */
  ContextTraits_1: 'context_traits_1',
  /** column name */
  ContextTraits_2: 'context_traits_2',
  /** column name */
  ContextTraits_3: 'context_traits_3',
  /** column name */
  ContextTraits_4: 'context_traits_4',
  /** column name */
  ContextTraits_5: 'context_traits_5',
  /** column name */
  ContextTraitsEmail: 'context_traits_email',
  /** column name */
  ContextTraitsFamilyName: 'context_traits_family_name',
  /** column name */
  ContextTraitsGivenName: 'context_traits_given_name',
  /** column name */
  ContextTraitsId: 'context_traits_id',
  /** column name */
  ContextTraitsRole: 'context_traits_role',
  /** column name */
  ContextUserAgent: 'context_user_agent',
  /** column name */
  Email: 'email',
  /** column name */
  Employer: 'employer',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Event: 'event',
  /** column name */
  ExternalBrowserRedirect: 'external_browser_redirect',
  /** column name */
  Fbclid: 'fbclid',
  /** column name */
  FirstName: 'first_name',
  /** column name */
  From: 'from',
  /** column name */
  Gad: 'gad',
  /** column name */
  GadSource: 'gad_source',
  /** column name */
  Gbraid: 'gbraid',
  /** column name */
  Gclid: 'gclid',
  /** column name */
  HsaAcc: 'hsa_acc',
  /** column name */
  HsaAd: 'hsa_ad',
  /** column name */
  HsaCam: 'hsa_cam',
  /** column name */
  HsaGrp: 'hsa_grp',
  /** column name */
  HsaKw: 'hsa_kw',
  /** column name */
  HsaMt: 'hsa_mt',
  /** column name */
  HsaNet: 'hsa_net',
  /** column name */
  HsaSrc: 'hsa_src',
  /** column name */
  HsaTgt: 'hsa_tgt',
  /** column name */
  HsaVer: 'hsa_ver',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  JobSlug: 'job_slug',
  /** column name */
  JobType: 'job_type',
  /** column name */
  Jobs: 'jobs',
  /** column name */
  Keywords: 'keywords',
  /** column name */
  LastName: 'last_name',
  /** column name */
  ListingType: 'listing_type',
  /** column name */
  Location: 'location',
  /** column name */
  MessageId: 'message_id',
  /** column name */
  Mibextid: 'mibextid',
  /** column name */
  Msclkid: 'msclkid',
  /** column name */
  Origin: 'origin',
  /** column name */
  Page: 'page',
  /** column name */
  PayType: 'pay_type',
  /** column name */
  Phone: 'phone',
  /** column name */
  Price: 'price',
  /** column name */
  ReceivedAt: 'received_at',
  /** column name */
  RequestIp: 'request_ip',
  /** column name */
  SentAt: 'sent_at',
  /** column name */
  SortByDate: 'sort_by_date',
  /** column name */
  Timestamp: 'timestamp',
  /** column name */
  Title: 'title',
  /** column name */
  To: 'to',
  /** column name */
  Type: 'type',
  /** column name */
  UserId: 'user_id',
  /** column name */
  UtmCampaign: 'utm_campaign',
  /** column name */
  UtmContent: 'utm_content',
  /** column name */
  UtmId: 'utm_id',
  /** column name */
  UtmMedium: 'utm_medium',
  /** column name */
  UtmSource: 'utm_source',
  /** column name */
  UtmTerm: 'utm_term',
  /** column name */
  Wbraid: 'wbraid',
  /** column name */
  WriteKey: 'write_key'
} as const;

export type Events_Update_Column = typeof Events_Update_Column[keyof typeof Events_Update_Column];
export interface Events_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Events_Var_Pop_Fields {
  __typename?: 'events_var_pop_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Events_Var_Samp_Fields {
  __typename?: 'events_var_samp_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Events_Variance_Fields {
  __typename?: 'events_variance_fields';
  context_geo_location_accuracy_radius?: Maybe<Scalars['Float']['output']>;
  context_geo_location_latitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_longitude?: Maybe<Scalars['Float']['output']>;
  context_geo_location_usa_data_metro_code?: Maybe<Scalars['Float']['output']>;
  context_geo_provider_as_num?: Maybe<Scalars['Float']['output']>;
  context_screen_density?: Maybe<Scalars['Float']['output']>;
  context_screen_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_height?: Maybe<Scalars['Float']['output']>;
  context_screen_inner_width?: Maybe<Scalars['Float']['output']>;
  context_screen_width?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "file" */
export interface File {
  __typename?: 'file';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "file" */
export interface File_Aggregate {
  __typename?: 'file_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
}

export interface File_Aggregate_Bool_Exp {
  count?: InputMaybe<File_Aggregate_Bool_Exp_Count>;
}

export interface File_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "file" */
export interface File_Aggregate_Fields {
  __typename?: 'file_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
}


/** aggregate fields of "file" */
export interface File_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "file" */
export interface File_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<File_Max_Order_By>;
  min?: InputMaybe<File_Min_Order_By>;
}

/** input type for inserting array relation for remote table "file" */
export interface File_Arr_Rel_Insert_Input {
  data: Array<File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
}

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export interface File_Bool_Exp {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  mimetype?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "file" */
export const File_Constraint = {
  /** unique or primary key constraint on columns "id" */
  FilePkey: 'file_pkey'
} as const;

export type File_Constraint = typeof File_Constraint[keyof typeof File_Constraint];
/** input type for inserting data into table "file" */
export interface File_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface File_Max_Fields {
  __typename?: 'file_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "file" */
export interface File_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  mimetype?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface File_Min_Fields {
  __typename?: 'file_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "file" */
export interface File_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  mimetype?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "file" */
export interface File_Mutation_Response {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
}

/** on_conflict condition type for table "file" */
export interface File_On_Conflict {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
}

/** Ordering options when selecting data from "file". */
export interface File_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  mimetype?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: file */
export interface File_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "file" */
export const File_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  Key: 'key',
  /** column name */
  Mimetype: 'mimetype',
  /** column name */
  Name: 'name',
  /** column name */
  UserId: 'user_id'
} as const;

export type File_Select_Column = typeof File_Select_Column[keyof typeof File_Select_Column];
/** input type for updating data in table "file" */
export interface File_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "file" */
export interface File_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface File_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "file" */
export const File_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  Key: 'key',
  /** column name */
  Mimetype: 'mimetype',
  /** column name */
  Name: 'name',
  /** column name */
  UserId: 'user_id'
} as const;

export type File_Update_Column = typeof File_Update_Column[keyof typeof File_Update_Column];
export interface File_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface Float8_Comparison_Exp {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
}

export interface Geography_Cast_Exp {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
}

/** columns and relationships of "geography_columns" */
export interface Geography_Columns {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_geography_column?: Maybe<Scalars['name']['output']>;
  f_table_catalog?: Maybe<Scalars['name']['output']>;
  f_table_name?: Maybe<Scalars['name']['output']>;
  f_table_schema?: Maybe<Scalars['name']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "geography_columns" */
export interface Geography_Columns_Aggregate {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
}

/** aggregate fields of "geography_columns" */
export interface Geography_Columns_Aggregate_Fields {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
}


/** aggregate fields of "geography_columns" */
export interface Geography_Columns_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Geography_Columns_Avg_Fields {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export interface Geography_Columns_Bool_Exp {
  _and?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geography_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geography_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<Name_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Geography_Columns_Max_Fields {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Geography_Columns_Min_Fields {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** Ordering options when selecting data from "geography_columns". */
export interface Geography_Columns_Order_By {
  coord_dimension?: InputMaybe<Order_By>;
  f_geography_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
}

/** select columns of table "geography_columns" */
export const Geography_Columns_Select_Column = {
  /** column name */
  CoordDimension: 'coord_dimension',
  /** column name */
  FGeographyColumn: 'f_geography_column',
  /** column name */
  FTableCatalog: 'f_table_catalog',
  /** column name */
  FTableName: 'f_table_name',
  /** column name */
  FTableSchema: 'f_table_schema',
  /** column name */
  Srid: 'srid',
  /** column name */
  Type: 'type'
} as const;

export type Geography_Columns_Select_Column = typeof Geography_Columns_Select_Column[keyof typeof Geography_Columns_Select_Column];
/** aggregate stddev on columns */
export interface Geography_Columns_Stddev_Fields {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Geography_Columns_Stddev_Pop_Fields {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Geography_Columns_Stddev_Samp_Fields {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "geography_columns" */
export interface Geography_Columns_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Geography_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Geography_Columns_Stream_Cursor_Value_Input {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geography_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['name']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Geography_Columns_Sum_Fields {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
}

/** aggregate var_pop on columns */
export interface Geography_Columns_Var_Pop_Fields {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Geography_Columns_Var_Samp_Fields {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Geography_Columns_Variance_Fields {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export interface Geography_Comparison_Exp {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']['input']>;
  _gt?: InputMaybe<Scalars['geography']['input']>;
  _gte?: InputMaybe<Scalars['geography']['input']>;
  _in?: InputMaybe<Array<Scalars['geography']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geography']['input']>;
  _lte?: InputMaybe<Scalars['geography']['input']>;
  _neq?: InputMaybe<Scalars['geography']['input']>;
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>;
}

export interface Geometry_Cast_Exp {
  geography?: InputMaybe<Geography_Comparison_Exp>;
}

/** columns and relationships of "geometry_columns" */
export interface Geometry_Columns {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_geometry_column?: Maybe<Scalars['name']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  f_table_name?: Maybe<Scalars['name']['output']>;
  f_table_schema?: Maybe<Scalars['name']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "geometry_columns" */
export interface Geometry_Columns_Aggregate {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
}

/** aggregate fields of "geometry_columns" */
export interface Geometry_Columns_Aggregate_Fields {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
}


/** aggregate fields of "geometry_columns" */
export interface Geometry_Columns_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Geometry_Columns_Avg_Fields {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export interface Geometry_Columns_Bool_Exp {
  _and?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geometry_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geometry_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<String_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
}

/** input type for incrementing numeric columns in table "geometry_columns" */
export interface Geometry_Columns_Inc_Input {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "geometry_columns" */
export interface Geometry_Columns_Insert_Input {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Geometry_Columns_Max_Fields {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Geometry_Columns_Min_Fields {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "geometry_columns" */
export interface Geometry_Columns_Mutation_Response {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
}

/** Ordering options when selecting data from "geometry_columns". */
export interface Geometry_Columns_Order_By {
  coord_dimension?: InputMaybe<Order_By>;
  f_geometry_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
}

/** select columns of table "geometry_columns" */
export const Geometry_Columns_Select_Column = {
  /** column name */
  CoordDimension: 'coord_dimension',
  /** column name */
  FGeometryColumn: 'f_geometry_column',
  /** column name */
  FTableCatalog: 'f_table_catalog',
  /** column name */
  FTableName: 'f_table_name',
  /** column name */
  FTableSchema: 'f_table_schema',
  /** column name */
  Srid: 'srid',
  /** column name */
  Type: 'type'
} as const;

export type Geometry_Columns_Select_Column = typeof Geometry_Columns_Select_Column[keyof typeof Geometry_Columns_Select_Column];
/** input type for updating data in table "geometry_columns" */
export interface Geometry_Columns_Set_Input {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Geometry_Columns_Stddev_Fields {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Geometry_Columns_Stddev_Pop_Fields {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Geometry_Columns_Stddev_Samp_Fields {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "geometry_columns" */
export interface Geometry_Columns_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Geometry_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Geometry_Columns_Stream_Cursor_Value_Input {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Geometry_Columns_Sum_Fields {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
}

export interface Geometry_Columns_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Geometry_Columns_Var_Pop_Fields {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Geometry_Columns_Var_Samp_Fields {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Geometry_Columns_Variance_Fields {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export interface Geometry_Comparison_Exp {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']['input']>;
  _gt?: InputMaybe<Scalars['geometry']['input']>;
  _gte?: InputMaybe<Scalars['geometry']['input']>;
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geometry']['input']>;
  _lte?: InputMaybe<Scalars['geometry']['input']>;
  _neq?: InputMaybe<Scalars['geometry']['input']>;
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>;
}

/** columns and relationships of "integration_employer" */
export interface Integration_Employer {
  __typename?: 'integration_employer';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  employer?: Maybe<Employer>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  integration: Enum_Integration_Type;
  integration_fkey: Scalars['String']['output'];
  integration_type: Scalars['String']['output'];
  /** An array relationship */
  integrations_jobs: Array<Integration_Job>;
  /** An aggregate relationship */
  integrations_jobs_aggregate: Integration_Job_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}


/** columns and relationships of "integration_employer" */
export interface Integration_EmployerIntegrations_JobsArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


/** columns and relationships of "integration_employer" */
export interface Integration_EmployerIntegrations_Jobs_AggregateArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}

/** aggregated selection of "integration_employer" */
export interface Integration_Employer_Aggregate {
  __typename?: 'integration_employer_aggregate';
  aggregate?: Maybe<Integration_Employer_Aggregate_Fields>;
  nodes: Array<Integration_Employer>;
}

export interface Integration_Employer_Aggregate_Bool_Exp {
  count?: InputMaybe<Integration_Employer_Aggregate_Bool_Exp_Count>;
}

export interface Integration_Employer_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Integration_Employer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "integration_employer" */
export interface Integration_Employer_Aggregate_Fields {
  __typename?: 'integration_employer_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Integration_Employer_Max_Fields>;
  min?: Maybe<Integration_Employer_Min_Fields>;
}


/** aggregate fields of "integration_employer" */
export interface Integration_Employer_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "integration_employer" */
export interface Integration_Employer_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Integration_Employer_Max_Order_By>;
  min?: InputMaybe<Integration_Employer_Min_Order_By>;
}

/** input type for inserting array relation for remote table "integration_employer" */
export interface Integration_Employer_Arr_Rel_Insert_Input {
  data: Array<Integration_Employer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Employer_On_Conflict>;
}

/** Boolean expression to filter rows from the table "integration_employer". All fields are combined with a logical 'AND'. */
export interface Integration_Employer_Bool_Exp {
  _and?: InputMaybe<Array<Integration_Employer_Bool_Exp>>;
  _not?: InputMaybe<Integration_Employer_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Employer_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
  integration_fkey?: InputMaybe<String_Comparison_Exp>;
  integration_type?: InputMaybe<String_Comparison_Exp>;
  integrations_jobs?: InputMaybe<Integration_Job_Bool_Exp>;
  integrations_jobs_aggregate?: InputMaybe<Integration_Job_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "integration_employer" */
export const Integration_Employer_Constraint = {
  /** unique or primary key constraint on columns "integration_fkey" */
  IntegrationsEmployersIntegrationFkeyKey: 'integrations_employers_integration_fkey_key',
  /** unique or primary key constraint on columns "id" */
  IntegrationsEmployersPkey: 'integrations_employers_pkey'
} as const;

export type Integration_Employer_Constraint = typeof Integration_Employer_Constraint[keyof typeof Integration_Employer_Constraint];
/** input type for inserting data into table "integration_employer" */
export interface Integration_Employer_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration?: InputMaybe<Enum_Integration_Type_Obj_Rel_Insert_Input>;
  integration_fkey?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Scalars['String']['input']>;
  integrations_jobs?: InputMaybe<Integration_Job_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface Integration_Employer_Max_Fields {
  __typename?: 'integration_employer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_fkey?: Maybe<Scalars['String']['output']>;
  integration_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "integration_employer" */
export interface Integration_Employer_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_fkey?: InputMaybe<Order_By>;
  integration_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Integration_Employer_Min_Fields {
  __typename?: 'integration_employer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_fkey?: Maybe<Scalars['String']['output']>;
  integration_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "integration_employer" */
export interface Integration_Employer_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_fkey?: InputMaybe<Order_By>;
  integration_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "integration_employer" */
export interface Integration_Employer_Mutation_Response {
  __typename?: 'integration_employer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Employer>;
}

/** input type for inserting object relation for remote table "integration_employer" */
export interface Integration_Employer_Obj_Rel_Insert_Input {
  data: Integration_Employer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Employer_On_Conflict>;
}

/** on_conflict condition type for table "integration_employer" */
export interface Integration_Employer_On_Conflict {
  constraint: Integration_Employer_Constraint;
  update_columns?: Array<Integration_Employer_Update_Column>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}

/** Ordering options when selecting data from "integration_employer". */
export interface Integration_Employer_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Enum_Integration_Type_Order_By>;
  integration_fkey?: InputMaybe<Order_By>;
  integration_type?: InputMaybe<Order_By>;
  integrations_jobs_aggregate?: InputMaybe<Integration_Job_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: integration_employer */
export interface Integration_Employer_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "integration_employer" */
export const Integration_Employer_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Id: 'id',
  /** column name */
  IntegrationFkey: 'integration_fkey',
  /** column name */
  IntegrationType: 'integration_type',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Integration_Employer_Select_Column = typeof Integration_Employer_Select_Column[keyof typeof Integration_Employer_Select_Column];
/** input type for updating data in table "integration_employer" */
export interface Integration_Employer_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_fkey?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "integration_employer" */
export interface Integration_Employer_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Integration_Employer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Integration_Employer_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_fkey?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "integration_employer" */
export const Integration_Employer_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Id: 'id',
  /** column name */
  IntegrationFkey: 'integration_fkey',
  /** column name */
  IntegrationType: 'integration_type',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Integration_Employer_Update_Column = typeof Integration_Employer_Update_Column[keyof typeof Integration_Employer_Update_Column];
export interface Integration_Employer_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Employer_Set_Input>;
  where: Integration_Employer_Bool_Exp;
}

/** columns and relationships of "integration_job" */
export interface Integration_Job {
  __typename?: 'integration_job';
  application_url?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  integration: Enum_Integration_Type;
  /** An object relationship */
  integration_employer?: Maybe<Integration_Employer>;
  integration_employer_id?: Maybe<Scalars['uuid']['output']>;
  integration_fkey: Scalars['String']['output'];
  integration_type: Scalars['String']['output'];
  /** An object relationship */
  job: Job;
  /** An object relationship */
  jobById?: Maybe<Job>;
  job_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}


/** columns and relationships of "integration_job" */
export interface Integration_JobDataArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "integration_job" */
export interface Integration_Job_Aggregate {
  __typename?: 'integration_job_aggregate';
  aggregate?: Maybe<Integration_Job_Aggregate_Fields>;
  nodes: Array<Integration_Job>;
}

export interface Integration_Job_Aggregate_Bool_Exp {
  count?: InputMaybe<Integration_Job_Aggregate_Bool_Exp_Count>;
}

export interface Integration_Job_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Integration_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Integration_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "integration_job" */
export interface Integration_Job_Aggregate_Fields {
  __typename?: 'integration_job_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Integration_Job_Max_Fields>;
  min?: Maybe<Integration_Job_Min_Fields>;
}


/** aggregate fields of "integration_job" */
export interface Integration_Job_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Integration_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "integration_job" */
export interface Integration_Job_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Integration_Job_Max_Order_By>;
  min?: InputMaybe<Integration_Job_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Integration_Job_Append_Input {
  data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "integration_job" */
export interface Integration_Job_Arr_Rel_Insert_Input {
  data: Array<Integration_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Job_On_Conflict>;
}

/** Boolean expression to filter rows from the table "integration_job". All fields are combined with a logical 'AND'. */
export interface Integration_Job_Bool_Exp {
  _and?: InputMaybe<Array<Integration_Job_Bool_Exp>>;
  _not?: InputMaybe<Integration_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Job_Bool_Exp>>;
  application_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
  integration_employer?: InputMaybe<Integration_Employer_Bool_Exp>;
  integration_employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  integration_fkey?: InputMaybe<String_Comparison_Exp>;
  integration_type?: InputMaybe<String_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  jobById?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "integration_job" */
export const Integration_Job_Constraint = {
  /** unique or primary key constraint on columns "integration_fkey" */
  IntegrationsIntegrationFkeyKey: 'integrations_integration_fkey_key',
  /** unique or primary key constraint on columns "job_id" */
  IntegrationsJobIdKey: 'integrations_job_id_key',
  /** unique or primary key constraint on columns "id" */
  IntegrationsPkey: 'integrations_pkey'
} as const;

export type Integration_Job_Constraint = typeof Integration_Job_Constraint[keyof typeof Integration_Job_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Integration_Job_Delete_At_Path_Input {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Integration_Job_Delete_Elem_Input {
  data?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Integration_Job_Delete_Key_Input {
  data?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "integration_job" */
export interface Integration_Job_Insert_Input {
  application_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration?: InputMaybe<Enum_Integration_Type_Obj_Rel_Insert_Input>;
  integration_employer?: InputMaybe<Integration_Employer_Obj_Rel_Insert_Input>;
  integration_employer_id?: InputMaybe<Scalars['uuid']['input']>;
  integration_fkey?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  jobById?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface Integration_Job_Max_Fields {
  __typename?: 'integration_job_max_fields';
  application_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_employer_id?: Maybe<Scalars['uuid']['output']>;
  integration_fkey?: Maybe<Scalars['String']['output']>;
  integration_type?: Maybe<Scalars['String']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "integration_job" */
export interface Integration_Job_Max_Order_By {
  application_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_employer_id?: InputMaybe<Order_By>;
  integration_fkey?: InputMaybe<Order_By>;
  integration_type?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Integration_Job_Min_Fields {
  __typename?: 'integration_job_min_fields';
  application_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_employer_id?: Maybe<Scalars['uuid']['output']>;
  integration_fkey?: Maybe<Scalars['String']['output']>;
  integration_type?: Maybe<Scalars['String']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "integration_job" */
export interface Integration_Job_Min_Order_By {
  application_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_employer_id?: InputMaybe<Order_By>;
  integration_fkey?: InputMaybe<Order_By>;
  integration_type?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "integration_job" */
export interface Integration_Job_Mutation_Response {
  __typename?: 'integration_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Job>;
}

/** input type for inserting object relation for remote table "integration_job" */
export interface Integration_Job_Obj_Rel_Insert_Input {
  data: Integration_Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Job_On_Conflict>;
}

/** on_conflict condition type for table "integration_job" */
export interface Integration_Job_On_Conflict {
  constraint: Integration_Job_Constraint;
  update_columns?: Array<Integration_Job_Update_Column>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}

/** Ordering options when selecting data from "integration_job". */
export interface Integration_Job_Order_By {
  application_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Enum_Integration_Type_Order_By>;
  integration_employer?: InputMaybe<Integration_Employer_Order_By>;
  integration_employer_id?: InputMaybe<Order_By>;
  integration_fkey?: InputMaybe<Order_By>;
  integration_type?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  jobById?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: integration_job */
export interface Integration_Job_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Integration_Job_Prepend_Input {
  data?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "integration_job" */
export const Integration_Job_Select_Column = {
  /** column name */
  ApplicationUrl: 'application_url',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Data: 'data',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  IntegrationEmployerId: 'integration_employer_id',
  /** column name */
  IntegrationFkey: 'integration_fkey',
  /** column name */
  IntegrationType: 'integration_type',
  /** column name */
  JobId: 'job_id',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Integration_Job_Select_Column = typeof Integration_Job_Select_Column[keyof typeof Integration_Job_Select_Column];
/** input type for updating data in table "integration_job" */
export interface Integration_Job_Set_Input {
  application_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_employer_id?: InputMaybe<Scalars['uuid']['input']>;
  integration_fkey?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Scalars['String']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "integration_job" */
export interface Integration_Job_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Integration_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Integration_Job_Stream_Cursor_Value_Input {
  application_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_employer_id?: InputMaybe<Scalars['uuid']['input']>;
  integration_fkey?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Scalars['String']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "integration_job" */
export const Integration_Job_Update_Column = {
  /** column name */
  ApplicationUrl: 'application_url',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Data: 'data',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  IntegrationEmployerId: 'integration_employer_id',
  /** column name */
  IntegrationFkey: 'integration_fkey',
  /** column name */
  IntegrationType: 'integration_type',
  /** column name */
  JobId: 'job_id',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Integration_Job_Update_Column = typeof Integration_Job_Update_Column[keyof typeof Integration_Job_Update_Column];
export interface Integration_Job_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Integration_Job_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Integration_Job_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Integration_Job_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Integration_Job_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Integration_Job_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Job_Set_Input>;
  where: Integration_Job_Bool_Exp;
}

/** columns and relationships of "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission {
  __typename?: 'internal_raw_job_submission';
  created_at: Scalars['timestamptz']['output'];
  created_by_user_id: Scalars['uuid']['output'];
  employer_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  job_id?: Maybe<Scalars['uuid']['output']>;
  stripe_checkout_id?: Maybe<Scalars['String']['output']>;
  submission: Scalars['jsonb']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}


/** columns and relationships of "internal.raw_job_submission" */
export interface Internal_Raw_Job_SubmissionSubmissionArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Aggregate {
  __typename?: 'internal_raw_job_submission_aggregate';
  aggregate?: Maybe<Internal_Raw_Job_Submission_Aggregate_Fields>;
  nodes: Array<Internal_Raw_Job_Submission>;
}

/** aggregate fields of "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Aggregate_Fields {
  __typename?: 'internal_raw_job_submission_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Internal_Raw_Job_Submission_Max_Fields>;
  min?: Maybe<Internal_Raw_Job_Submission_Min_Fields>;
}


/** aggregate fields of "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Internal_Raw_Job_Submission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Internal_Raw_Job_Submission_Append_Input {
  submission?: InputMaybe<Scalars['jsonb']['input']>;
}

/** Boolean expression to filter rows from the table "internal.raw_job_submission". All fields are combined with a logical 'AND'. */
export interface Internal_Raw_Job_Submission_Bool_Exp {
  _and?: InputMaybe<Array<Internal_Raw_Job_Submission_Bool_Exp>>;
  _not?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
  _or?: InputMaybe<Array<Internal_Raw_Job_Submission_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  stripe_checkout_id?: InputMaybe<String_Comparison_Exp>;
  submission?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "internal.raw_job_submission" */
export const Internal_Raw_Job_Submission_Constraint = {
  /** unique or primary key constraint on columns "job_id" */
  RawJobSubmissionJobIdKey: 'raw_job_submission_job_id_key',
  /** unique or primary key constraint on columns "id" */
  RawJobSubmissionPkey: 'raw_job_submission_pkey',
  /** unique or primary key constraint on columns "stripe_checkout_id" */
  RawJobSubmissionStripeCheckoutIdKey: 'raw_job_submission_stripe_checkout_id_key'
} as const;

export type Internal_Raw_Job_Submission_Constraint = typeof Internal_Raw_Job_Submission_Constraint[keyof typeof Internal_Raw_Job_Submission_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Internal_Raw_Job_Submission_Delete_At_Path_Input {
  submission?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Internal_Raw_Job_Submission_Delete_Elem_Input {
  submission?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Internal_Raw_Job_Submission_Delete_Key_Input {
  submission?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  stripe_checkout_id?: InputMaybe<Scalars['String']['input']>;
  submission?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface Internal_Raw_Job_Submission_Max_Fields {
  __typename?: 'internal_raw_job_submission_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  stripe_checkout_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface Internal_Raw_Job_Submission_Min_Fields {
  __typename?: 'internal_raw_job_submission_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  stripe_checkout_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Mutation_Response {
  __typename?: 'internal_raw_job_submission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Raw_Job_Submission>;
}

/** input type for inserting object relation for remote table "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Obj_Rel_Insert_Input {
  data: Internal_Raw_Job_Submission_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Internal_Raw_Job_Submission_On_Conflict>;
}

/** on_conflict condition type for table "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_On_Conflict {
  constraint: Internal_Raw_Job_Submission_Constraint;
  update_columns?: Array<Internal_Raw_Job_Submission_Update_Column>;
  where?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
}

/** Ordering options when selecting data from "internal.raw_job_submission". */
export interface Internal_Raw_Job_Submission_Order_By {
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  stripe_checkout_id?: InputMaybe<Order_By>;
  submission?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: internal.raw_job_submission */
export interface Internal_Raw_Job_Submission_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Internal_Raw_Job_Submission_Prepend_Input {
  submission?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "internal.raw_job_submission" */
export const Internal_Raw_Job_Submission_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  StripeCheckoutId: 'stripe_checkout_id',
  /** column name */
  Submission: 'submission',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Internal_Raw_Job_Submission_Select_Column = typeof Internal_Raw_Job_Submission_Select_Column[keyof typeof Internal_Raw_Job_Submission_Select_Column];
/** input type for updating data in table "internal.raw_job_submission" */
export interface Internal_Raw_Job_Submission_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  stripe_checkout_id?: InputMaybe<Scalars['String']['input']>;
  submission?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "internal_raw_job_submission" */
export interface Internal_Raw_Job_Submission_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Internal_Raw_Job_Submission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Internal_Raw_Job_Submission_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  stripe_checkout_id?: InputMaybe<Scalars['String']['input']>;
  submission?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "internal.raw_job_submission" */
export const Internal_Raw_Job_Submission_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  StripeCheckoutId: 'stripe_checkout_id',
  /** column name */
  Submission: 'submission',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Internal_Raw_Job_Submission_Update_Column = typeof Internal_Raw_Job_Submission_Update_Column[keyof typeof Internal_Raw_Job_Submission_Update_Column];
export interface Internal_Raw_Job_Submission_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Internal_Raw_Job_Submission_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Internal_Raw_Job_Submission_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Internal_Raw_Job_Submission_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Internal_Raw_Job_Submission_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Internal_Raw_Job_Submission_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Internal_Raw_Job_Submission_Set_Input>;
  where: Internal_Raw_Job_Submission_Bool_Exp;
}

/** columns and relationships of "job" */
export interface Job {
  __typename?: 'job';
  about?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  additional_categories: Array<Job_Additional_Category>;
  /** An aggregate relationship */
  additional_categories_aggregate: Job_Additional_Category_Aggregate;
  /** A computed field, executes function "compute_job_additional_categories" */
  additional_categories_computed?: Maybe<Array<Job_Additional_Category>>;
  /** An array relationship */
  applications: Array<Application>;
  /** An aggregate relationship */
  applications_aggregate: Application_Aggregate;
  apply_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  category: Enum_Category;
  /** A computed field, executes function "compute_category" */
  category_computed?: Maybe<Array<Enum_Category>>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  employer?: Maybe<Employer>;
  /** A computed field, executes function "compute_job_employer" */
  employer_computed?: Maybe<Array<Employer>>;
  employer_id: Scalars['uuid']['output'];
  event_job: Array<Events>;
  event_job_aggregate: Events_Aggregate;
  expiry_date?: Maybe<Scalars['date']['output']>;
  highlights?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  integration?: Maybe<Integration_Job>;
  /** A computed field, executes function "compute_job_integration" */
  integration_computed?: Maybe<Array<Integration_Job>>;
  integration_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  integration_job?: Maybe<Integration_Job>;
  job_category: Enum_Category_Enum;
  /** An array relationship */
  job_questions: Array<Job_Question>;
  /** An aggregate relationship */
  job_questions_aggregate: Job_Question_Aggregate;
  job_status: Enum_Job_Status_Enum;
  /** An object relationship */
  job_views?: Maybe<Job_Views>;
  list_date?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  listing: Enum_Listing;
  /** A computed field, executes function "compute_listing_type" */
  listing_computed?: Maybe<Array<Enum_Listing>>;
  listing_type: Enum_Listing_Enum;
  /** An object relationship */
  location: Location;
  /** A computed field, executes function "compute_location" */
  location_computed?: Maybe<Array<Location>>;
  location_id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  pay: Enum_Pay_Type;
  pay_high: Scalars['numeric']['output'];
  pay_low: Scalars['numeric']['output'];
  pay_type: Enum_Pay_Type_Enum;
  /** An array relationship */
  promotion_redemptions: Array<Stripe_Promotion_Redemption>;
  /** An aggregate relationship */
  promotion_redemptions_aggregate: Stripe_Promotion_Redemption_Aggregate;
  requirements?: Maybe<Scalars['jsonb']['output']>;
  show_banner?: Maybe<Scalars['Boolean']['output']>;
  show_pay: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  /** An object relationship */
  status: Enum_Job_Status;
  tagline?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  watchlists: Array<Watchlist>;
  /** An aggregate relationship */
  watchlists_aggregate: Watchlist_Aggregate;
  /** An object relationship */
  work: Enum_Work_Type;
  work_type: Enum_Work_Type_Enum;
  /** A computed field, executes function "compute_work_type" */
  work_type_computed?: Maybe<Array<Enum_Work_Type>>;
}


/** columns and relationships of "job" */
export interface JobAdditional_CategoriesArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobAdditional_Categories_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobAdditional_Categories_ComputedArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobApplicationsArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobApplications_AggregateArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobCategory_ComputedArgs {
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobEmployer_ComputedArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobEvent_JobArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobEvent_Job_AggregateArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobHighlightsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "job" */
export interface JobIntegration_ComputedArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobJob_QuestionsArgs {
  distinct_on?: InputMaybe<Array<Job_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Question_Order_By>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobJob_Questions_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Question_Order_By>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobListing_ComputedArgs {
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobLocation_ComputedArgs {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobMetadataArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "job" */
export interface JobPromotion_RedemptionsArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobPromotion_Redemptions_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobRequirementsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "job" */
export interface JobWatchlistsArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobWatchlists_AggregateArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


/** columns and relationships of "job" */
export interface JobWork_Type_ComputedArgs {
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}

/** columns and relationships of "job_additional_category" */
export interface Job_Additional_Category {
  __typename?: 'job_additional_category';
  category: Enum_Category_Enum;
  /** An object relationship */
  categoryObject: Enum_Category;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid']['output'];
}

/** aggregated selection of "job_additional_category" */
export interface Job_Additional_Category_Aggregate {
  __typename?: 'job_additional_category_aggregate';
  aggregate?: Maybe<Job_Additional_Category_Aggregate_Fields>;
  nodes: Array<Job_Additional_Category>;
}

export interface Job_Additional_Category_Aggregate_Bool_Exp {
  count?: InputMaybe<Job_Additional_Category_Aggregate_Bool_Exp_Count>;
}

export interface Job_Additional_Category_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Job_Additional_Category_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "job_additional_category" */
export interface Job_Additional_Category_Aggregate_Fields {
  __typename?: 'job_additional_category_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Job_Additional_Category_Max_Fields>;
  min?: Maybe<Job_Additional_Category_Min_Fields>;
}


/** aggregate fields of "job_additional_category" */
export interface Job_Additional_Category_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "job_additional_category" */
export interface Job_Additional_Category_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Additional_Category_Max_Order_By>;
  min?: InputMaybe<Job_Additional_Category_Min_Order_By>;
}

/** input type for inserting array relation for remote table "job_additional_category" */
export interface Job_Additional_Category_Arr_Rel_Insert_Input {
  data: Array<Job_Additional_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Additional_Category_On_Conflict>;
}

/** Boolean expression to filter rows from the table "job_additional_category". All fields are combined with a logical 'AND'. */
export interface Job_Additional_Category_Bool_Exp {
  _and?: InputMaybe<Array<Job_Additional_Category_Bool_Exp>>;
  _not?: InputMaybe<Job_Additional_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Additional_Category_Bool_Exp>>;
  category?: InputMaybe<Enum_Category_Enum_Comparison_Exp>;
  categoryObject?: InputMaybe<Enum_Category_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "job_additional_category" */
export const Job_Additional_Category_Constraint = {
  /** unique or primary key constraint on columns "job_id", "category" */
  JobAdditionalCategoriesJobIdCategoryKey: 'job_additional_categories_job_id_category_key',
  /** unique or primary key constraint on columns "id" */
  JobAdditionalCategoriesPkey: 'job_additional_categories_pkey'
} as const;

export type Job_Additional_Category_Constraint = typeof Job_Additional_Category_Constraint[keyof typeof Job_Additional_Category_Constraint];
/** input type for inserting data into table "job_additional_category" */
export interface Job_Additional_Category_Insert_Input {
  category?: InputMaybe<Enum_Category_Enum>;
  categoryObject?: InputMaybe<Enum_Category_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface Job_Additional_Category_Max_Fields {
  __typename?: 'job_additional_category_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "job_additional_category" */
export interface Job_Additional_Category_Max_Order_By {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Job_Additional_Category_Min_Fields {
  __typename?: 'job_additional_category_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "job_additional_category" */
export interface Job_Additional_Category_Min_Order_By {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "job_additional_category" */
export interface Job_Additional_Category_Mutation_Response {
  __typename?: 'job_additional_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Additional_Category>;
}

/** on_conflict condition type for table "job_additional_category" */
export interface Job_Additional_Category_On_Conflict {
  constraint: Job_Additional_Category_Constraint;
  update_columns?: Array<Job_Additional_Category_Update_Column>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}

/** Ordering options when selecting data from "job_additional_category". */
export interface Job_Additional_Category_Order_By {
  category?: InputMaybe<Order_By>;
  categoryObject?: InputMaybe<Enum_Category_Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: job_additional_category */
export interface Job_Additional_Category_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "job_additional_category" */
export const Job_Additional_Category_Select_Column = {
  /** column name */
  Category: 'category',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id'
} as const;

export type Job_Additional_Category_Select_Column = typeof Job_Additional_Category_Select_Column[keyof typeof Job_Additional_Category_Select_Column];
/** input type for updating data in table "job_additional_category" */
export interface Job_Additional_Category_Set_Input {
  category?: InputMaybe<Enum_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "job_additional_category" */
export interface Job_Additional_Category_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Job_Additional_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Job_Additional_Category_Stream_Cursor_Value_Input {
  category?: InputMaybe<Enum_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "job_additional_category" */
export const Job_Additional_Category_Update_Column = {
  /** column name */
  Category: 'category',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id'
} as const;

export type Job_Additional_Category_Update_Column = typeof Job_Additional_Category_Update_Column[keyof typeof Job_Additional_Category_Update_Column];
export interface Job_Additional_Category_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Additional_Category_Set_Input>;
  where: Job_Additional_Category_Bool_Exp;
}

/** aggregated selection of "job" */
export interface Job_Aggregate {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
}

export interface Job_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<Job_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Job_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Job_Aggregate_Bool_Exp_Count>;
}

export interface Job_Aggregate_Bool_Exp_Bool_And {
  arguments: Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Job_Aggregate_Bool_Exp_Bool_Or {
  arguments: Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface Job_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "job" */
export interface Job_Aggregate_Fields {
  __typename?: 'job_aggregate_fields';
  avg?: Maybe<Job_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
  stddev?: Maybe<Job_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Sum_Fields>;
  var_pop?: Maybe<Job_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Var_Samp_Fields>;
  variance?: Maybe<Job_Variance_Fields>;
}


/** aggregate fields of "job" */
export interface Job_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "job" */
export interface Job_Aggregate_Order_By {
  avg?: InputMaybe<Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Max_Order_By>;
  min?: InputMaybe<Job_Min_Order_By>;
  stddev?: InputMaybe<Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Variance_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Job_Append_Input {
  highlights?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "job" */
export interface Job_Arr_Rel_Insert_Input {
  data: Array<Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
}

/** aggregate avg on columns */
export interface Job_Avg_Fields {
  __typename?: 'job_avg_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "job" */
export interface Job_Avg_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export interface Job_Bool_Exp {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  additional_categories?: InputMaybe<Job_Additional_Category_Bool_Exp>;
  additional_categories_aggregate?: InputMaybe<Job_Additional_Category_Aggregate_Bool_Exp>;
  additional_categories_computed?: InputMaybe<Job_Additional_Category_Bool_Exp>;
  applications?: InputMaybe<Application_Bool_Exp>;
  applications_aggregate?: InputMaybe<Application_Aggregate_Bool_Exp>;
  apply_url?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<Enum_Category_Bool_Exp>;
  category_computed?: InputMaybe<Enum_Category_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<User_Bool_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_computed?: InputMaybe<Employer_Bool_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  expiry_date?: InputMaybe<Date_Comparison_Exp>;
  highlights?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integration_Job_Bool_Exp>;
  integration_computed?: InputMaybe<Integration_Job_Bool_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  integration_job?: InputMaybe<Integration_Job_Bool_Exp>;
  job_category?: InputMaybe<Enum_Category_Enum_Comparison_Exp>;
  job_questions?: InputMaybe<Job_Question_Bool_Exp>;
  job_questions_aggregate?: InputMaybe<Job_Question_Aggregate_Bool_Exp>;
  job_status?: InputMaybe<Enum_Job_Status_Enum_Comparison_Exp>;
  job_views?: InputMaybe<Job_Views_Bool_Exp>;
  list_date?: InputMaybe<Date_Comparison_Exp>;
  listing?: InputMaybe<Enum_Listing_Bool_Exp>;
  listing_computed?: InputMaybe<Enum_Listing_Bool_Exp>;
  listing_type?: InputMaybe<Enum_Listing_Enum_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_computed?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  pay?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
  pay_high?: InputMaybe<Numeric_Comparison_Exp>;
  pay_low?: InputMaybe<Numeric_Comparison_Exp>;
  pay_type?: InputMaybe<Enum_Pay_Type_Enum_Comparison_Exp>;
  promotion_redemptions?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
  promotion_redemptions_aggregate?: InputMaybe<Stripe_Promotion_Redemption_Aggregate_Bool_Exp>;
  requirements?: InputMaybe<Jsonb_Comparison_Exp>;
  show_banner?: InputMaybe<Boolean_Comparison_Exp>;
  show_pay?: InputMaybe<Boolean_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Enum_Job_Status_Bool_Exp>;
  tagline?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<User_Bool_Exp>;
  updated_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  watchlists?: InputMaybe<Watchlist_Bool_Exp>;
  watchlists_aggregate?: InputMaybe<Watchlist_Aggregate_Bool_Exp>;
  work?: InputMaybe<Enum_Work_Type_Bool_Exp>;
  work_type?: InputMaybe<Enum_Work_Type_Enum_Comparison_Exp>;
  work_type_computed?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}

/** unique or primary key constraints on table "job" */
export const Job_Constraint = {
  /** unique or primary key constraint on columns "integration_id" */
  JobIntegrationIdKey: 'job_integration_id_key',
  /** unique or primary key constraint on columns "id" */
  JobPkey: 'job_pkey',
  /** unique or primary key constraint on columns "slug" */
  JobSlugKey: 'job_slug_key'
} as const;

export type Job_Constraint = typeof Job_Constraint[keyof typeof Job_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Job_Delete_At_Path_Input {
  highlights?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  requirements?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Job_Delete_Elem_Input {
  highlights?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  requirements?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Job_Delete_Key_Input {
  highlights?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  requirements?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "job" */
export interface Job_Inc_Input {
  pay_high?: InputMaybe<Scalars['numeric']['input']>;
  pay_low?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "job" */
export interface Job_Insert_Input {
  about?: InputMaybe<Scalars['String']['input']>;
  additional_categories?: InputMaybe<Job_Additional_Category_Arr_Rel_Insert_Input>;
  applications?: InputMaybe<Application_Arr_Rel_Insert_Input>;
  apply_url?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Enum_Category_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  expiry_date?: InputMaybe<Scalars['date']['input']>;
  highlights?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration?: InputMaybe<Integration_Job_Obj_Rel_Insert_Input>;
  integration_id?: InputMaybe<Scalars['uuid']['input']>;
  integration_job?: InputMaybe<Integration_Job_Obj_Rel_Insert_Input>;
  job_category?: InputMaybe<Enum_Category_Enum>;
  job_questions?: InputMaybe<Job_Question_Arr_Rel_Insert_Input>;
  job_status?: InputMaybe<Enum_Job_Status_Enum>;
  job_views?: InputMaybe<Job_Views_Obj_Rel_Insert_Input>;
  list_date?: InputMaybe<Scalars['date']['input']>;
  listing?: InputMaybe<Enum_Listing_Obj_Rel_Insert_Input>;
  listing_type?: InputMaybe<Enum_Listing_Enum>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pay?: InputMaybe<Enum_Pay_Type_Obj_Rel_Insert_Input>;
  pay_high?: InputMaybe<Scalars['numeric']['input']>;
  pay_low?: InputMaybe<Scalars['numeric']['input']>;
  pay_type?: InputMaybe<Enum_Pay_Type_Enum>;
  promotion_redemptions?: InputMaybe<Stripe_Promotion_Redemption_Arr_Rel_Insert_Input>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
  show_banner?: InputMaybe<Scalars['Boolean']['input']>;
  show_pay?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Enum_Job_Status_Obj_Rel_Insert_Input>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  watchlists?: InputMaybe<Watchlist_Arr_Rel_Insert_Input>;
  work?: InputMaybe<Enum_Work_Type_Obj_Rel_Insert_Input>;
  work_type?: InputMaybe<Enum_Work_Type_Enum>;
}

/** aggregate max on columns */
export interface Job_Max_Fields {
  __typename?: 'job_max_fields';
  about?: Maybe<Scalars['String']['output']>;
  apply_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  expiry_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_id?: Maybe<Scalars['uuid']['output']>;
  list_date?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  pay_high?: Maybe<Scalars['numeric']['output']>;
  pay_low?: Maybe<Scalars['numeric']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tagline?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "job" */
export interface Job_Max_Order_By {
  about?: InputMaybe<Order_By>;
  apply_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  list_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  tagline?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Job_Min_Fields {
  __typename?: 'job_min_fields';
  about?: Maybe<Scalars['String']['output']>;
  apply_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  expiry_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_id?: Maybe<Scalars['uuid']['output']>;
  list_date?: Maybe<Scalars['date']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  pay_high?: Maybe<Scalars['numeric']['output']>;
  pay_low?: Maybe<Scalars['numeric']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tagline?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "job" */
export interface Job_Min_Order_By {
  about?: InputMaybe<Order_By>;
  apply_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  list_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  tagline?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "job" */
export interface Job_Mutation_Response {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
}

/** input type for inserting object relation for remote table "job" */
export interface Job_Obj_Rel_Insert_Input {
  data: Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
}

/** on_conflict condition type for table "job" */
export interface Job_On_Conflict {
  constraint: Job_Constraint;
  update_columns?: Array<Job_Update_Column>;
  where?: InputMaybe<Job_Bool_Exp>;
}

/** Ordering options when selecting data from "job". */
export interface Job_Order_By {
  about?: InputMaybe<Order_By>;
  additional_categories_aggregate?: InputMaybe<Job_Additional_Category_Aggregate_Order_By>;
  additional_categories_computed_aggregate?: InputMaybe<Job_Additional_Category_Aggregate_Order_By>;
  applications_aggregate?: InputMaybe<Application_Aggregate_Order_By>;
  apply_url?: InputMaybe<Order_By>;
  category?: InputMaybe<Enum_Category_Order_By>;
  category_computed_aggregate?: InputMaybe<Enum_Category_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<User_Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_computed_aggregate?: InputMaybe<Employer_Aggregate_Order_By>;
  employer_id?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  highlights?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integration_Job_Order_By>;
  integration_computed_aggregate?: InputMaybe<Integration_Job_Aggregate_Order_By>;
  integration_id?: InputMaybe<Order_By>;
  integration_job?: InputMaybe<Integration_Job_Order_By>;
  job_category?: InputMaybe<Order_By>;
  job_questions_aggregate?: InputMaybe<Job_Question_Aggregate_Order_By>;
  job_status?: InputMaybe<Order_By>;
  job_views?: InputMaybe<Job_Views_Order_By>;
  list_date?: InputMaybe<Order_By>;
  listing?: InputMaybe<Enum_Listing_Order_By>;
  listing_computed_aggregate?: InputMaybe<Enum_Listing_Aggregate_Order_By>;
  listing_type?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_computed_aggregate?: InputMaybe<Location_Aggregate_Order_By>;
  location_id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  pay?: InputMaybe<Enum_Pay_Type_Order_By>;
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
  pay_type?: InputMaybe<Order_By>;
  promotion_redemptions_aggregate?: InputMaybe<Stripe_Promotion_Redemption_Aggregate_Order_By>;
  requirements?: InputMaybe<Order_By>;
  show_banner?: InputMaybe<Order_By>;
  show_pay?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Enum_Job_Status_Order_By>;
  tagline?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<User_Order_By>;
  updated_by_user_id?: InputMaybe<Order_By>;
  watchlists_aggregate?: InputMaybe<Watchlist_Aggregate_Order_By>;
  work?: InputMaybe<Enum_Work_Type_Order_By>;
  work_type?: InputMaybe<Order_By>;
  work_type_computed_aggregate?: InputMaybe<Enum_Work_Type_Aggregate_Order_By>;
}

/** primary key columns input for table: job */
export interface Job_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Job_Prepend_Input {
  highlights?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
}

/** columns and relationships of "job_question" */
export interface Job_Question {
  __typename?: 'job_question';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  question: Scalars['String']['output'];
  /** An object relationship */
  question_type: Enum_Question_Type;
  type: Enum_Question_Type_Enum;
  updated_at: Scalars['timestamptz']['output'];
}


/** columns and relationships of "job_question" */
export interface Job_QuestionOptionsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "job_question" */
export interface Job_Question_Aggregate {
  __typename?: 'job_question_aggregate';
  aggregate?: Maybe<Job_Question_Aggregate_Fields>;
  nodes: Array<Job_Question>;
}

export interface Job_Question_Aggregate_Bool_Exp {
  count?: InputMaybe<Job_Question_Aggregate_Bool_Exp_Count>;
}

export interface Job_Question_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Job_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Job_Question_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "job_question" */
export interface Job_Question_Aggregate_Fields {
  __typename?: 'job_question_aggregate_fields';
  avg?: Maybe<Job_Question_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Job_Question_Max_Fields>;
  min?: Maybe<Job_Question_Min_Fields>;
  stddev?: Maybe<Job_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Question_Sum_Fields>;
  var_pop?: Maybe<Job_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Question_Var_Samp_Fields>;
  variance?: Maybe<Job_Question_Variance_Fields>;
}


/** aggregate fields of "job_question" */
export interface Job_Question_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Job_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "job_question" */
export interface Job_Question_Aggregate_Order_By {
  avg?: InputMaybe<Job_Question_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Question_Max_Order_By>;
  min?: InputMaybe<Job_Question_Min_Order_By>;
  stddev?: InputMaybe<Job_Question_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Question_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Question_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Question_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Question_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Question_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Question_Variance_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Job_Question_Append_Input {
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "job_question" */
export interface Job_Question_Arr_Rel_Insert_Input {
  data: Array<Job_Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Question_On_Conflict>;
}

/** aggregate avg on columns */
export interface Job_Question_Avg_Fields {
  __typename?: 'job_question_avg_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "job_question" */
export interface Job_Question_Avg_Order_By {
  order?: InputMaybe<Order_By>;
}

/** Boolean expression to filter rows from the table "job_question". All fields are combined with a logical 'AND'. */
export interface Job_Question_Bool_Exp {
  _and?: InputMaybe<Array<Job_Question_Bool_Exp>>;
  _not?: InputMaybe<Job_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Question_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  question_type?: InputMaybe<Enum_Question_Type_Bool_Exp>;
  type?: InputMaybe<Enum_Question_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "job_question" */
export const Job_Question_Constraint = {
  /** unique or primary key constraint on columns "id" */
  JobQuestionsPkey: 'job_questions_pkey'
} as const;

export type Job_Question_Constraint = typeof Job_Question_Constraint[keyof typeof Job_Question_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Job_Question_Delete_At_Path_Input {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Job_Question_Delete_Elem_Input {
  options?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Job_Question_Delete_Key_Input {
  options?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "job_question" */
export interface Job_Question_Inc_Input {
  order?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "job_question" */
export interface Job_Question_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  question_type?: InputMaybe<Enum_Question_Type_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Enum_Question_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface Job_Question_Max_Fields {
  __typename?: 'job_question_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by max() on columns of table "job_question" */
export interface Job_Question_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Job_Question_Min_Fields {
  __typename?: 'job_question_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** order by min() on columns of table "job_question" */
export interface Job_Question_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "job_question" */
export interface Job_Question_Mutation_Response {
  __typename?: 'job_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Question>;
}

/** on_conflict condition type for table "job_question" */
export interface Job_Question_On_Conflict {
  constraint: Job_Question_Constraint;
  update_columns?: Array<Job_Question_Update_Column>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}

/** Ordering options when selecting data from "job_question". */
export interface Job_Question_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  question_type?: InputMaybe<Enum_Question_Type_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: job_question */
export interface Job_Question_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Job_Question_Prepend_Input {
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "job_question" */
export const Job_Question_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  Options: 'options',
  /** column name */
  Order: 'order',
  /** column name */
  Question: 'question',
  /** column name */
  Type: 'type',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Job_Question_Select_Column = typeof Job_Question_Select_Column[keyof typeof Job_Question_Select_Column];
/** input type for updating data in table "job_question" */
export interface Job_Question_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Question_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface Job_Question_Stddev_Fields {
  __typename?: 'job_question_stddev_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "job_question" */
export interface Job_Question_Stddev_Order_By {
  order?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Job_Question_Stddev_Pop_Fields {
  __typename?: 'job_question_stddev_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "job_question" */
export interface Job_Question_Stddev_Pop_Order_By {
  order?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Job_Question_Stddev_Samp_Fields {
  __typename?: 'job_question_stddev_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "job_question" */
export interface Job_Question_Stddev_Samp_Order_By {
  order?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "job_question" */
export interface Job_Question_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Job_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Job_Question_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Question_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface Job_Question_Sum_Fields {
  __typename?: 'job_question_sum_fields';
  order?: Maybe<Scalars['Int']['output']>;
}

/** order by sum() on columns of table "job_question" */
export interface Job_Question_Sum_Order_By {
  order?: InputMaybe<Order_By>;
}

/** update columns of table "job_question" */
export const Job_Question_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  Options: 'options',
  /** column name */
  Order: 'order',
  /** column name */
  Question: 'question',
  /** column name */
  Type: 'type',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Job_Question_Update_Column = typeof Job_Question_Update_Column[keyof typeof Job_Question_Update_Column];
export interface Job_Question_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Job_Question_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Job_Question_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Job_Question_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Job_Question_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Question_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Job_Question_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Question_Set_Input>;
  where: Job_Question_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Job_Question_Var_Pop_Fields {
  __typename?: 'job_question_var_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "job_question" */
export interface Job_Question_Var_Pop_Order_By {
  order?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Job_Question_Var_Samp_Fields {
  __typename?: 'job_question_var_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "job_question" */
export interface Job_Question_Var_Samp_Order_By {
  order?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Job_Question_Variance_Fields {
  __typename?: 'job_question_variance_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "job_question" */
export interface Job_Question_Variance_Order_By {
  order?: InputMaybe<Order_By>;
}

/** select columns of table "job" */
export const Job_Select_Column = {
  /** column name */
  About: 'about',
  /** column name */
  ApplyUrl: 'apply_url',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  ExpiryDate: 'expiry_date',
  /** column name */
  Highlights: 'highlights',
  /** column name */
  Id: 'id',
  /** column name */
  IntegrationId: 'integration_id',
  /** column name */
  JobCategory: 'job_category',
  /** column name */
  JobStatus: 'job_status',
  /** column name */
  ListDate: 'list_date',
  /** column name */
  ListingType: 'listing_type',
  /** column name */
  LocationId: 'location_id',
  /** column name */
  Metadata: 'metadata',
  /** column name */
  PayHigh: 'pay_high',
  /** column name */
  PayLow: 'pay_low',
  /** column name */
  PayType: 'pay_type',
  /** column name */
  Requirements: 'requirements',
  /** column name */
  ShowBanner: 'show_banner',
  /** column name */
  ShowPay: 'show_pay',
  /** column name */
  Slug: 'slug',
  /** column name */
  Tagline: 'tagline',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UpdatedByUserId: 'updated_by_user_id',
  /** column name */
  WorkType: 'work_type'
} as const;

export type Job_Select_Column = typeof Job_Select_Column[keyof typeof Job_Select_Column];
/** select "job_aggregate_bool_exp_bool_and_arguments_columns" columns of table "job" */
export const Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  ShowBanner: 'show_banner',
  /** column name */
  ShowPay: 'show_pay'
} as const;

export type Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "job_aggregate_bool_exp_bool_or_arguments_columns" columns of table "job" */
export const Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  ShowBanner: 'show_banner',
  /** column name */
  ShowPay: 'show_pay'
} as const;

export type Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof Job_Select_Column_Job_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
/** input type for updating data in table "job" */
export interface Job_Set_Input {
  about?: InputMaybe<Scalars['String']['input']>;
  apply_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  expiry_date?: InputMaybe<Scalars['date']['input']>;
  highlights?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_id?: InputMaybe<Scalars['uuid']['input']>;
  job_category?: InputMaybe<Enum_Category_Enum>;
  job_status?: InputMaybe<Enum_Job_Status_Enum>;
  list_date?: InputMaybe<Scalars['date']['input']>;
  listing_type?: InputMaybe<Enum_Listing_Enum>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pay_high?: InputMaybe<Scalars['numeric']['input']>;
  pay_low?: InputMaybe<Scalars['numeric']['input']>;
  pay_type?: InputMaybe<Enum_Pay_Type_Enum>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
  show_banner?: InputMaybe<Scalars['Boolean']['input']>;
  show_pay?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_type?: InputMaybe<Enum_Work_Type_Enum>;
}

/** aggregate stddev on columns */
export interface Job_Stddev_Fields {
  __typename?: 'job_stddev_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "job" */
export interface Job_Stddev_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** aggregate stddev_pop on columns */
export interface Job_Stddev_Pop_Fields {
  __typename?: 'job_stddev_pop_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "job" */
export interface Job_Stddev_Pop_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** aggregate stddev_samp on columns */
export interface Job_Stddev_Samp_Fields {
  __typename?: 'job_stddev_samp_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "job" */
export interface Job_Stddev_Samp_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** Streaming cursor of the table "job" */
export interface Job_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Job_Stream_Cursor_Value_Input {
  about?: InputMaybe<Scalars['String']['input']>;
  apply_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  expiry_date?: InputMaybe<Scalars['date']['input']>;
  highlights?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_id?: InputMaybe<Scalars['uuid']['input']>;
  job_category?: InputMaybe<Enum_Category_Enum>;
  job_status?: InputMaybe<Enum_Job_Status_Enum>;
  list_date?: InputMaybe<Scalars['date']['input']>;
  listing_type?: InputMaybe<Enum_Listing_Enum>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pay_high?: InputMaybe<Scalars['numeric']['input']>;
  pay_low?: InputMaybe<Scalars['numeric']['input']>;
  pay_type?: InputMaybe<Enum_Pay_Type_Enum>;
  requirements?: InputMaybe<Scalars['jsonb']['input']>;
  show_banner?: InputMaybe<Scalars['Boolean']['input']>;
  show_pay?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  work_type?: InputMaybe<Enum_Work_Type_Enum>;
}

/** aggregate sum on columns */
export interface Job_Sum_Fields {
  __typename?: 'job_sum_fields';
  pay_high?: Maybe<Scalars['numeric']['output']>;
  pay_low?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "job" */
export interface Job_Sum_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** update columns of table "job" */
export const Job_Update_Column = {
  /** column name */
  About: 'about',
  /** column name */
  ApplyUrl: 'apply_url',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  ExpiryDate: 'expiry_date',
  /** column name */
  Highlights: 'highlights',
  /** column name */
  Id: 'id',
  /** column name */
  IntegrationId: 'integration_id',
  /** column name */
  JobCategory: 'job_category',
  /** column name */
  JobStatus: 'job_status',
  /** column name */
  ListDate: 'list_date',
  /** column name */
  ListingType: 'listing_type',
  /** column name */
  LocationId: 'location_id',
  /** column name */
  Metadata: 'metadata',
  /** column name */
  PayHigh: 'pay_high',
  /** column name */
  PayLow: 'pay_low',
  /** column name */
  PayType: 'pay_type',
  /** column name */
  Requirements: 'requirements',
  /** column name */
  ShowBanner: 'show_banner',
  /** column name */
  ShowPay: 'show_pay',
  /** column name */
  Slug: 'slug',
  /** column name */
  Tagline: 'tagline',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UpdatedByUserId: 'updated_by_user_id',
  /** column name */
  WorkType: 'work_type'
} as const;

export type Job_Update_Column = typeof Job_Update_Column[keyof typeof Job_Update_Column];
export interface Job_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Job_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Job_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Job_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Job_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Job_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Job_Var_Pop_Fields {
  __typename?: 'job_var_pop_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "job" */
export interface Job_Var_Pop_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** aggregate var_samp on columns */
export interface Job_Var_Samp_Fields {
  __typename?: 'job_var_samp_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "job" */
export interface Job_Var_Samp_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** aggregate variance on columns */
export interface Job_Variance_Fields {
  __typename?: 'job_variance_fields';
  pay_high?: Maybe<Scalars['Float']['output']>;
  pay_low?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "job" */
export interface Job_Variance_Order_By {
  pay_high?: InputMaybe<Order_By>;
  pay_low?: InputMaybe<Order_By>;
}

/** columns and relationships of "job_views" */
export interface Job_Views {
  __typename?: 'job_views';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid']['output'];
  views: Scalars['Int']['output'];
}

/** aggregated selection of "job_views" */
export interface Job_Views_Aggregate {
  __typename?: 'job_views_aggregate';
  aggregate?: Maybe<Job_Views_Aggregate_Fields>;
  nodes: Array<Job_Views>;
}

/** aggregate fields of "job_views" */
export interface Job_Views_Aggregate_Fields {
  __typename?: 'job_views_aggregate_fields';
  avg?: Maybe<Job_Views_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Job_Views_Max_Fields>;
  min?: Maybe<Job_Views_Min_Fields>;
  stddev?: Maybe<Job_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Views_Sum_Fields>;
  var_pop?: Maybe<Job_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Views_Var_Samp_Fields>;
  variance?: Maybe<Job_Views_Variance_Fields>;
}


/** aggregate fields of "job_views" */
export interface Job_Views_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Job_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Job_Views_Avg_Fields {
  __typename?: 'job_views_avg_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "job_views". All fields are combined with a logical 'AND'. */
export interface Job_Views_Bool_Exp {
  _and?: InputMaybe<Array<Job_Views_Bool_Exp>>;
  _not?: InputMaybe<Job_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Views_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
}

/** unique or primary key constraints on table "job_views" */
export const Job_Views_Constraint = {
  /** unique or primary key constraint on columns "job_id" */
  JobViewsJobIdKey: 'job_views_job_id_key',
  /** unique or primary key constraint on columns "id" */
  JobViewsPkey: 'job_views_pkey'
} as const;

export type Job_Views_Constraint = typeof Job_Views_Constraint[keyof typeof Job_Views_Constraint];
/** input type for incrementing numeric columns in table "job_views" */
export interface Job_Views_Inc_Input {
  views?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "job_views" */
export interface Job_Views_Insert_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate max on columns */
export interface Job_Views_Max_Fields {
  __typename?: 'job_views_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

/** aggregate min on columns */
export interface Job_Views_Min_Fields {
  __typename?: 'job_views_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

/** response of any mutation on the table "job_views" */
export interface Job_Views_Mutation_Response {
  __typename?: 'job_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Views>;
}

/** input type for inserting object relation for remote table "job_views" */
export interface Job_Views_Obj_Rel_Insert_Input {
  data: Job_Views_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Views_On_Conflict>;
}

/** on_conflict condition type for table "job_views" */
export interface Job_Views_On_Conflict {
  constraint: Job_Views_Constraint;
  update_columns?: Array<Job_Views_Update_Column>;
  where?: InputMaybe<Job_Views_Bool_Exp>;
}

/** Ordering options when selecting data from "job_views". */
export interface Job_Views_Order_By {
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
}

/** primary key columns input for table: job_views */
export interface Job_Views_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "job_views" */
export const Job_Views_Select_Column = {
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  Views: 'views'
} as const;

export type Job_Views_Select_Column = typeof Job_Views_Select_Column[keyof typeof Job_Views_Select_Column];
/** input type for updating data in table "job_views" */
export interface Job_Views_Set_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate stddev on columns */
export interface Job_Views_Stddev_Fields {
  __typename?: 'job_views_stddev_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Job_Views_Stddev_Pop_Fields {
  __typename?: 'job_views_stddev_pop_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Job_Views_Stddev_Samp_Fields {
  __typename?: 'job_views_stddev_samp_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "job_views" */
export interface Job_Views_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Job_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Job_Views_Stream_Cursor_Value_Input {
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

/** aggregate sum on columns */
export interface Job_Views_Sum_Fields {
  __typename?: 'job_views_sum_fields';
  views?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "job_views" */
export const Job_Views_Update_Column = {
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  Views: 'views'
} as const;

export type Job_Views_Update_Column = typeof Job_Views_Update_Column[keyof typeof Job_Views_Update_Column];
export interface Job_Views_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Job_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Views_Set_Input>;
  where: Job_Views_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Job_Views_Var_Pop_Fields {
  __typename?: 'job_views_var_pop_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Job_Views_Var_Samp_Fields {
  __typename?: 'job_views_var_samp_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Job_Views_Variance_Fields {
  __typename?: 'job_views_variance_fields';
  views?: Maybe<Scalars['Float']['output']>;
}

export interface Jsonb_Cast_Exp {
  String?: InputMaybe<String_Comparison_Exp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface Jsonb_Comparison_Exp {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

/** columns and relationships of "location" */
export interface Location {
  __typename?: 'location';
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  geometry?: Maybe<Scalars['geography']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  label: Scalars['String']['output'];
  municipality?: Maybe<Scalars['String']['output']>;
  neighbourhood?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
}


/** columns and relationships of "location" */
export interface LocationJobsArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


/** columns and relationships of "location" */
export interface LocationJobs_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}

export interface Location_Aggregate {
  __typename?: 'location_aggregate';
  aggregate?: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
}

/** aggregate fields of "location" */
export interface Location_Aggregate_Fields {
  __typename?: 'location_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Location_Max_Fields>;
  min?: Maybe<Location_Min_Fields>;
}


/** aggregate fields of "location" */
export interface Location_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "location" */
export interface Location_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Location_Max_Order_By>;
  min?: InputMaybe<Location_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export interface Location_Bool_Exp {
  _and?: InputMaybe<Array<Location_Bool_Exp>>;
  _not?: InputMaybe<Location_Bool_Exp>;
  _or?: InputMaybe<Array<Location_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  district?: InputMaybe<String_Comparison_Exp>;
  geometry?: InputMaybe<Geography_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  municipality?: InputMaybe<String_Comparison_Exp>;
  neighbourhood?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "location" */
export const Location_Constraint = {
  /** unique or primary key constraint on columns "label" */
  LocationLabelKey: 'location_label_key',
  /** unique or primary key constraint on columns "id" */
  LocationPkey: 'location_pkey'
} as const;

export type Location_Constraint = typeof Location_Constraint[keyof typeof Location_Constraint];
/** input type for inserting data into table "location" */
export interface Location_Insert_Input {
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<Scalars['geography']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  label?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Location_Max_Fields {
  __typename?: 'location_max_fields';
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  neighbourhood?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "location" */
export interface Location_Max_Order_By {
  country?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  neighbourhood?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Location_Min_Fields {
  __typename?: 'location_min_fields';
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  neighbourhood?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "location" */
export interface Location_Min_Order_By {
  country?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  neighbourhood?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "location" */
export interface Location_Mutation_Response {
  __typename?: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
}

/** input type for inserting object relation for remote table "location" */
export interface Location_Obj_Rel_Insert_Input {
  data: Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>;
}

/** on_conflict condition type for table "location" */
export interface Location_On_Conflict {
  constraint: Location_Constraint;
  update_columns?: Array<Location_Update_Column>;
  where?: InputMaybe<Location_Bool_Exp>;
}

/** Ordering options when selecting data from "location". */
export interface Location_Order_By {
  country?: InputMaybe<Order_By>;
  district?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  label?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  neighbourhood?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
}

/** primary key columns input for table: location */
export interface Location_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "location" */
export const Location_Select_Column = {
  /** column name */
  Country: 'country',
  /** column name */
  District: 'district',
  /** column name */
  Geometry: 'geometry',
  /** column name */
  Id: 'id',
  /** column name */
  Label: 'label',
  /** column name */
  Municipality: 'municipality',
  /** column name */
  Neighbourhood: 'neighbourhood',
  /** column name */
  Number: 'number',
  /** column name */
  PostalCode: 'postal_code',
  /** column name */
  Region: 'region',
  /** column name */
  Street: 'street'
} as const;

export type Location_Select_Column = typeof Location_Select_Column[keyof typeof Location_Select_Column];
/** input type for updating data in table "location" */
export interface Location_Set_Input {
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<Scalars['geography']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "location" */
export interface Location_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Location_Stream_Cursor_Value_Input {
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<Scalars['geography']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  neighbourhood?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "location" */
export const Location_Update_Column = {
  /** column name */
  Country: 'country',
  /** column name */
  District: 'district',
  /** column name */
  Geometry: 'geometry',
  /** column name */
  Id: 'id',
  /** column name */
  Label: 'label',
  /** column name */
  Municipality: 'municipality',
  /** column name */
  Neighbourhood: 'neighbourhood',
  /** column name */
  Number: 'number',
  /** column name */
  PostalCode: 'postal_code',
  /** column name */
  Region: 'region',
  /** column name */
  Street: 'street'
} as const;

export type Location_Update_Column = typeof Location_Update_Column[keyof typeof Location_Update_Column];
export interface Location_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Set_Input>;
  where: Location_Bool_Exp;
}

/** mutation root */
export interface Mutation_Root {
  __typename?: 'mutation_root';
  /** delete data from the table: "application" */
  delete_application?: Maybe<Application_Mutation_Response>;
  /** delete single row from the table: "application" */
  delete_application_by_pk?: Maybe<Application>;
  /** delete data from the table: "employer" */
  delete_employer?: Maybe<Employer_Mutation_Response>;
  /** delete single row from the table: "employer" */
  delete_employer_by_pk?: Maybe<Employer>;
  /** delete data from the table: "employer_user" */
  delete_employer_user?: Maybe<Employer_User_Mutation_Response>;
  /** delete single row from the table: "employer_user" */
  delete_employer_user_by_pk?: Maybe<Employer_User>;
  /** delete data from the table: "enum.access_level" */
  delete_enum_access_level?: Maybe<Enum_Access_Level_Mutation_Response>;
  /** delete single row from the table: "enum.access_level" */
  delete_enum_access_level_by_pk?: Maybe<Enum_Access_Level>;
  /** delete data from the table: "enum.application_status" */
  delete_enum_application_status?: Maybe<Enum_Application_Status_Mutation_Response>;
  /** delete single row from the table: "enum.application_status" */
  delete_enum_application_status_by_pk?: Maybe<Enum_Application_Status>;
  /** delete data from the table: "enum.billing" */
  delete_enum_billing?: Maybe<Enum_Billing_Mutation_Response>;
  /** delete single row from the table: "enum.billing" */
  delete_enum_billing_by_pk?: Maybe<Enum_Billing>;
  /** delete data from the table: "enum.category" */
  delete_enum_category?: Maybe<Enum_Category_Mutation_Response>;
  /** delete single row from the table: "enum.category" */
  delete_enum_category_by_pk?: Maybe<Enum_Category>;
  /** delete data from the table: "enum.dial_code" */
  delete_enum_dial_code?: Maybe<Enum_Dial_Code_Mutation_Response>;
  /** delete single row from the table: "enum.dial_code" */
  delete_enum_dial_code_by_pk?: Maybe<Enum_Dial_Code>;
  /** delete data from the table: "enum.integration_type" */
  delete_enum_integration_type?: Maybe<Enum_Integration_Type_Mutation_Response>;
  /** delete single row from the table: "enum.integration_type" */
  delete_enum_integration_type_by_pk?: Maybe<Enum_Integration_Type>;
  /** delete data from the table: "enum.job_status" */
  delete_enum_job_status?: Maybe<Enum_Job_Status_Mutation_Response>;
  /** delete single row from the table: "enum.job_status" */
  delete_enum_job_status_by_pk?: Maybe<Enum_Job_Status>;
  /** delete data from the table: "enum.listing" */
  delete_enum_listing?: Maybe<Enum_Listing_Mutation_Response>;
  /** delete single row from the table: "enum.listing" */
  delete_enum_listing_by_pk?: Maybe<Enum_Listing>;
  /** delete data from the table: "enum.organisation" */
  delete_enum_organisation?: Maybe<Enum_Organisation_Mutation_Response>;
  /** delete single row from the table: "enum.organisation" */
  delete_enum_organisation_by_pk?: Maybe<Enum_Organisation>;
  /** delete data from the table: "enum.pay_type" */
  delete_enum_pay_type?: Maybe<Enum_Pay_Type_Mutation_Response>;
  /** delete single row from the table: "enum.pay_type" */
  delete_enum_pay_type_by_pk?: Maybe<Enum_Pay_Type>;
  /** delete data from the table: "enum.question_type" */
  delete_enum_question_type?: Maybe<Enum_Question_Type_Mutation_Response>;
  /** delete single row from the table: "enum.question_type" */
  delete_enum_question_type_by_pk?: Maybe<Enum_Question_Type>;
  /** delete data from the table: "enum.role" */
  delete_enum_role?: Maybe<Enum_Role_Mutation_Response>;
  /** delete single row from the table: "enum.role" */
  delete_enum_role_by_pk?: Maybe<Enum_Role>;
  /** delete data from the table: "enum.work_type" */
  delete_enum_work_type?: Maybe<Enum_Work_Type_Mutation_Response>;
  /** delete single row from the table: "enum.work_type" */
  delete_enum_work_type_by_pk?: Maybe<Enum_Work_Type>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "file" */
  delete_file?: Maybe<File_Mutation_Response>;
  /** delete single row from the table: "file" */
  delete_file_by_pk?: Maybe<File>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "integration_employer" */
  delete_integration_employer?: Maybe<Integration_Employer_Mutation_Response>;
  /** delete single row from the table: "integration_employer" */
  delete_integration_employer_by_pk?: Maybe<Integration_Employer>;
  /** delete data from the table: "integration_job" */
  delete_integration_job?: Maybe<Integration_Job_Mutation_Response>;
  /** delete single row from the table: "integration_job" */
  delete_integration_job_by_pk?: Maybe<Integration_Job>;
  /** delete data from the table: "internal.raw_job_submission" */
  delete_internal_raw_job_submission?: Maybe<Internal_Raw_Job_Submission_Mutation_Response>;
  /** delete single row from the table: "internal.raw_job_submission" */
  delete_internal_raw_job_submission_by_pk?: Maybe<Internal_Raw_Job_Submission>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<Job_Mutation_Response>;
  /** delete data from the table: "job_additional_category" */
  delete_job_additional_category?: Maybe<Job_Additional_Category_Mutation_Response>;
  /** delete single row from the table: "job_additional_category" */
  delete_job_additional_category_by_pk?: Maybe<Job_Additional_Category>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "job_question" */
  delete_job_question?: Maybe<Job_Question_Mutation_Response>;
  /** delete single row from the table: "job_question" */
  delete_job_question_by_pk?: Maybe<Job_Question>;
  /** delete data from the table: "job_views" */
  delete_job_views?: Maybe<Job_Views_Mutation_Response>;
  /** delete single row from the table: "job_views" */
  delete_job_views_by_pk?: Maybe<Job_Views>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>;
  /** delete data from the table: "question" */
  delete_question?: Maybe<Question_Mutation_Response>;
  /** delete single row from the table: "question" */
  delete_question_by_pk?: Maybe<Question>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "stripe.bundle" */
  delete_stripe_bundle?: Maybe<Stripe_Bundle_Mutation_Response>;
  /** delete single row from the table: "stripe.bundle" */
  delete_stripe_bundle_by_pk?: Maybe<Stripe_Bundle>;
  /** delete data from the table: "stripe.promotion" */
  delete_stripe_promotion?: Maybe<Stripe_Promotion_Mutation_Response>;
  /** delete single row from the table: "stripe.promotion" */
  delete_stripe_promotion_by_pk?: Maybe<Stripe_Promotion>;
  /** delete data from the table: "stripe.promotion_redemption" */
  delete_stripe_promotion_redemption?: Maybe<Stripe_Promotion_Redemption_Mutation_Response>;
  /** delete single row from the table: "stripe.promotion_redemption" */
  delete_stripe_promotion_redemption_by_pk?: Maybe<Stripe_Promotion_Redemption>;
  /** delete data from the table: "stripe.recovered_session" */
  delete_stripe_recovered_session?: Maybe<Stripe_Recovered_Session_Mutation_Response>;
  /** delete single row from the table: "stripe.recovered_session" */
  delete_stripe_recovered_session_by_pk?: Maybe<Stripe_Recovered_Session>;
  /** delete data from the table: "stripe.recovered_session_status" */
  delete_stripe_recovered_session_status?: Maybe<Stripe_Recovered_Session_Status_Mutation_Response>;
  /** delete single row from the table: "stripe.recovered_session_status" */
  delete_stripe_recovered_session_status_by_pk?: Maybe<Stripe_Recovered_Session_Status>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "user_answer" */
  delete_user_answer?: Maybe<User_Answer_Mutation_Response>;
  /** delete single row from the table: "user_answer" */
  delete_user_answer_by_pk?: Maybe<User_Answer>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_education" */
  delete_user_education?: Maybe<User_Education_Mutation_Response>;
  /** delete single row from the table: "user_education" */
  delete_user_education_by_pk?: Maybe<User_Education>;
  /** delete data from the table: "user_experience" */
  delete_user_experience?: Maybe<User_Experience_Mutation_Response>;
  /** delete single row from the table: "user_experience" */
  delete_user_experience_by_pk?: Maybe<User_Experience>;
  /** delete data from the table: "user_licence" */
  delete_user_licence?: Maybe<User_Licence_Mutation_Response>;
  /** delete single row from the table: "user_licence" */
  delete_user_licence_by_pk?: Maybe<User_Licence>;
  /** delete data from the table: "user_search" */
  delete_user_search?: Maybe<User_Search_Mutation_Response>;
  /** delete single row from the table: "user_search" */
  delete_user_search_by_pk?: Maybe<User_Search>;
  /** delete data from the table: "watchlist" */
  delete_watchlist?: Maybe<Watchlist_Mutation_Response>;
  /** delete single row from the table: "watchlist" */
  delete_watchlist_by_pk?: Maybe<Watchlist>;
  /** insert data into the table: "application" */
  insert_application?: Maybe<Application_Mutation_Response>;
  /** insert a single row into the table: "application" */
  insert_application_one?: Maybe<Application>;
  /** insert data into the table: "employer" */
  insert_employer?: Maybe<Employer_Mutation_Response>;
  /** insert a single row into the table: "employer" */
  insert_employer_one?: Maybe<Employer>;
  /** insert data into the table: "employer_user" */
  insert_employer_user?: Maybe<Employer_User_Mutation_Response>;
  /** insert a single row into the table: "employer_user" */
  insert_employer_user_one?: Maybe<Employer_User>;
  /** insert data into the table: "enum.access_level" */
  insert_enum_access_level?: Maybe<Enum_Access_Level_Mutation_Response>;
  /** insert a single row into the table: "enum.access_level" */
  insert_enum_access_level_one?: Maybe<Enum_Access_Level>;
  /** insert data into the table: "enum.application_status" */
  insert_enum_application_status?: Maybe<Enum_Application_Status_Mutation_Response>;
  /** insert a single row into the table: "enum.application_status" */
  insert_enum_application_status_one?: Maybe<Enum_Application_Status>;
  /** insert data into the table: "enum.billing" */
  insert_enum_billing?: Maybe<Enum_Billing_Mutation_Response>;
  /** insert a single row into the table: "enum.billing" */
  insert_enum_billing_one?: Maybe<Enum_Billing>;
  /** insert data into the table: "enum.category" */
  insert_enum_category?: Maybe<Enum_Category_Mutation_Response>;
  /** insert a single row into the table: "enum.category" */
  insert_enum_category_one?: Maybe<Enum_Category>;
  /** insert data into the table: "enum.dial_code" */
  insert_enum_dial_code?: Maybe<Enum_Dial_Code_Mutation_Response>;
  /** insert a single row into the table: "enum.dial_code" */
  insert_enum_dial_code_one?: Maybe<Enum_Dial_Code>;
  /** insert data into the table: "enum.integration_type" */
  insert_enum_integration_type?: Maybe<Enum_Integration_Type_Mutation_Response>;
  /** insert a single row into the table: "enum.integration_type" */
  insert_enum_integration_type_one?: Maybe<Enum_Integration_Type>;
  /** insert data into the table: "enum.job_status" */
  insert_enum_job_status?: Maybe<Enum_Job_Status_Mutation_Response>;
  /** insert a single row into the table: "enum.job_status" */
  insert_enum_job_status_one?: Maybe<Enum_Job_Status>;
  /** insert data into the table: "enum.listing" */
  insert_enum_listing?: Maybe<Enum_Listing_Mutation_Response>;
  /** insert a single row into the table: "enum.listing" */
  insert_enum_listing_one?: Maybe<Enum_Listing>;
  /** insert data into the table: "enum.organisation" */
  insert_enum_organisation?: Maybe<Enum_Organisation_Mutation_Response>;
  /** insert a single row into the table: "enum.organisation" */
  insert_enum_organisation_one?: Maybe<Enum_Organisation>;
  /** insert data into the table: "enum.pay_type" */
  insert_enum_pay_type?: Maybe<Enum_Pay_Type_Mutation_Response>;
  /** insert a single row into the table: "enum.pay_type" */
  insert_enum_pay_type_one?: Maybe<Enum_Pay_Type>;
  /** insert data into the table: "enum.question_type" */
  insert_enum_question_type?: Maybe<Enum_Question_Type_Mutation_Response>;
  /** insert a single row into the table: "enum.question_type" */
  insert_enum_question_type_one?: Maybe<Enum_Question_Type>;
  /** insert data into the table: "enum.role" */
  insert_enum_role?: Maybe<Enum_Role_Mutation_Response>;
  /** insert a single row into the table: "enum.role" */
  insert_enum_role_one?: Maybe<Enum_Role>;
  /** insert data into the table: "enum.work_type" */
  insert_enum_work_type?: Maybe<Enum_Work_Type_Mutation_Response>;
  /** insert a single row into the table: "enum.work_type" */
  insert_enum_work_type_one?: Maybe<Enum_Work_Type>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "file" */
  insert_file?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "integration_employer" */
  insert_integration_employer?: Maybe<Integration_Employer_Mutation_Response>;
  /** insert a single row into the table: "integration_employer" */
  insert_integration_employer_one?: Maybe<Integration_Employer>;
  /** insert data into the table: "integration_job" */
  insert_integration_job?: Maybe<Integration_Job_Mutation_Response>;
  /** insert a single row into the table: "integration_job" */
  insert_integration_job_one?: Maybe<Integration_Job>;
  /** insert data into the table: "internal.raw_job_submission" */
  insert_internal_raw_job_submission?: Maybe<Internal_Raw_Job_Submission_Mutation_Response>;
  /** insert a single row into the table: "internal.raw_job_submission" */
  insert_internal_raw_job_submission_one?: Maybe<Internal_Raw_Job_Submission>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<Job_Mutation_Response>;
  /** insert data into the table: "job_additional_category" */
  insert_job_additional_category?: Maybe<Job_Additional_Category_Mutation_Response>;
  /** insert a single row into the table: "job_additional_category" */
  insert_job_additional_category_one?: Maybe<Job_Additional_Category>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "job_question" */
  insert_job_question?: Maybe<Job_Question_Mutation_Response>;
  /** insert a single row into the table: "job_question" */
  insert_job_question_one?: Maybe<Job_Question>;
  /** insert data into the table: "job_views" */
  insert_job_views?: Maybe<Job_Views_Mutation_Response>;
  /** insert a single row into the table: "job_views" */
  insert_job_views_one?: Maybe<Job_Views>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>;
  /** insert data into the table: "question" */
  insert_question?: Maybe<Question_Mutation_Response>;
  /** insert a single row into the table: "question" */
  insert_question_one?: Maybe<Question>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "stripe.bundle" */
  insert_stripe_bundle?: Maybe<Stripe_Bundle_Mutation_Response>;
  /** insert a single row into the table: "stripe.bundle" */
  insert_stripe_bundle_one?: Maybe<Stripe_Bundle>;
  /** insert data into the table: "stripe.promotion" */
  insert_stripe_promotion?: Maybe<Stripe_Promotion_Mutation_Response>;
  /** insert a single row into the table: "stripe.promotion" */
  insert_stripe_promotion_one?: Maybe<Stripe_Promotion>;
  /** insert data into the table: "stripe.promotion_redemption" */
  insert_stripe_promotion_redemption?: Maybe<Stripe_Promotion_Redemption_Mutation_Response>;
  /** insert a single row into the table: "stripe.promotion_redemption" */
  insert_stripe_promotion_redemption_one?: Maybe<Stripe_Promotion_Redemption>;
  /** insert data into the table: "stripe.recovered_session" */
  insert_stripe_recovered_session?: Maybe<Stripe_Recovered_Session_Mutation_Response>;
  /** insert a single row into the table: "stripe.recovered_session" */
  insert_stripe_recovered_session_one?: Maybe<Stripe_Recovered_Session>;
  /** insert data into the table: "stripe.recovered_session_status" */
  insert_stripe_recovered_session_status?: Maybe<Stripe_Recovered_Session_Status_Mutation_Response>;
  /** insert a single row into the table: "stripe.recovered_session_status" */
  insert_stripe_recovered_session_status_one?: Maybe<Stripe_Recovered_Session_Status>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_answer" */
  insert_user_answer?: Maybe<User_Answer_Mutation_Response>;
  /** insert a single row into the table: "user_answer" */
  insert_user_answer_one?: Maybe<User_Answer>;
  /** insert data into the table: "user_education" */
  insert_user_education?: Maybe<User_Education_Mutation_Response>;
  /** insert a single row into the table: "user_education" */
  insert_user_education_one?: Maybe<User_Education>;
  /** insert data into the table: "user_experience" */
  insert_user_experience?: Maybe<User_Experience_Mutation_Response>;
  /** insert a single row into the table: "user_experience" */
  insert_user_experience_one?: Maybe<User_Experience>;
  /** insert data into the table: "user_licence" */
  insert_user_licence?: Maybe<User_Licence_Mutation_Response>;
  /** insert a single row into the table: "user_licence" */
  insert_user_licence_one?: Maybe<User_Licence>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_search" */
  insert_user_search?: Maybe<User_Search_Mutation_Response>;
  /** insert a single row into the table: "user_search" */
  insert_user_search_one?: Maybe<User_Search>;
  /** insert data into the table: "watchlist" */
  insert_watchlist?: Maybe<Watchlist_Mutation_Response>;
  /** insert a single row into the table: "watchlist" */
  insert_watchlist_one?: Maybe<Watchlist>;
  /** execute VOLATILE function "set_expiry_date" which returns "job" */
  set_expiry_date?: Maybe<Job>;
  /** update data of the table: "application" */
  update_application?: Maybe<Application_Mutation_Response>;
  /** update single row of the table: "application" */
  update_application_by_pk?: Maybe<Application>;
  /** update multiples rows of table: "application" */
  update_application_many?: Maybe<Array<Maybe<Application_Mutation_Response>>>;
  /** update data of the table: "employer" */
  update_employer?: Maybe<Employer_Mutation_Response>;
  /** update single row of the table: "employer" */
  update_employer_by_pk?: Maybe<Employer>;
  /** update multiples rows of table: "employer" */
  update_employer_many?: Maybe<Array<Maybe<Employer_Mutation_Response>>>;
  /** update data of the table: "employer_user" */
  update_employer_user?: Maybe<Employer_User_Mutation_Response>;
  /** update single row of the table: "employer_user" */
  update_employer_user_by_pk?: Maybe<Employer_User>;
  /** update multiples rows of table: "employer_user" */
  update_employer_user_many?: Maybe<Array<Maybe<Employer_User_Mutation_Response>>>;
  /** update data of the table: "enum.access_level" */
  update_enum_access_level?: Maybe<Enum_Access_Level_Mutation_Response>;
  /** update single row of the table: "enum.access_level" */
  update_enum_access_level_by_pk?: Maybe<Enum_Access_Level>;
  /** update multiples rows of table: "enum.access_level" */
  update_enum_access_level_many?: Maybe<Array<Maybe<Enum_Access_Level_Mutation_Response>>>;
  /** update data of the table: "enum.application_status" */
  update_enum_application_status?: Maybe<Enum_Application_Status_Mutation_Response>;
  /** update single row of the table: "enum.application_status" */
  update_enum_application_status_by_pk?: Maybe<Enum_Application_Status>;
  /** update multiples rows of table: "enum.application_status" */
  update_enum_application_status_many?: Maybe<Array<Maybe<Enum_Application_Status_Mutation_Response>>>;
  /** update data of the table: "enum.billing" */
  update_enum_billing?: Maybe<Enum_Billing_Mutation_Response>;
  /** update single row of the table: "enum.billing" */
  update_enum_billing_by_pk?: Maybe<Enum_Billing>;
  /** update multiples rows of table: "enum.billing" */
  update_enum_billing_many?: Maybe<Array<Maybe<Enum_Billing_Mutation_Response>>>;
  /** update data of the table: "enum.category" */
  update_enum_category?: Maybe<Enum_Category_Mutation_Response>;
  /** update single row of the table: "enum.category" */
  update_enum_category_by_pk?: Maybe<Enum_Category>;
  /** update multiples rows of table: "enum.category" */
  update_enum_category_many?: Maybe<Array<Maybe<Enum_Category_Mutation_Response>>>;
  /** update data of the table: "enum.dial_code" */
  update_enum_dial_code?: Maybe<Enum_Dial_Code_Mutation_Response>;
  /** update single row of the table: "enum.dial_code" */
  update_enum_dial_code_by_pk?: Maybe<Enum_Dial_Code>;
  /** update multiples rows of table: "enum.dial_code" */
  update_enum_dial_code_many?: Maybe<Array<Maybe<Enum_Dial_Code_Mutation_Response>>>;
  /** update data of the table: "enum.integration_type" */
  update_enum_integration_type?: Maybe<Enum_Integration_Type_Mutation_Response>;
  /** update single row of the table: "enum.integration_type" */
  update_enum_integration_type_by_pk?: Maybe<Enum_Integration_Type>;
  /** update multiples rows of table: "enum.integration_type" */
  update_enum_integration_type_many?: Maybe<Array<Maybe<Enum_Integration_Type_Mutation_Response>>>;
  /** update data of the table: "enum.job_status" */
  update_enum_job_status?: Maybe<Enum_Job_Status_Mutation_Response>;
  /** update single row of the table: "enum.job_status" */
  update_enum_job_status_by_pk?: Maybe<Enum_Job_Status>;
  /** update multiples rows of table: "enum.job_status" */
  update_enum_job_status_many?: Maybe<Array<Maybe<Enum_Job_Status_Mutation_Response>>>;
  /** update data of the table: "enum.listing" */
  update_enum_listing?: Maybe<Enum_Listing_Mutation_Response>;
  /** update single row of the table: "enum.listing" */
  update_enum_listing_by_pk?: Maybe<Enum_Listing>;
  /** update multiples rows of table: "enum.listing" */
  update_enum_listing_many?: Maybe<Array<Maybe<Enum_Listing_Mutation_Response>>>;
  /** update data of the table: "enum.organisation" */
  update_enum_organisation?: Maybe<Enum_Organisation_Mutation_Response>;
  /** update single row of the table: "enum.organisation" */
  update_enum_organisation_by_pk?: Maybe<Enum_Organisation>;
  /** update multiples rows of table: "enum.organisation" */
  update_enum_organisation_many?: Maybe<Array<Maybe<Enum_Organisation_Mutation_Response>>>;
  /** update data of the table: "enum.pay_type" */
  update_enum_pay_type?: Maybe<Enum_Pay_Type_Mutation_Response>;
  /** update single row of the table: "enum.pay_type" */
  update_enum_pay_type_by_pk?: Maybe<Enum_Pay_Type>;
  /** update multiples rows of table: "enum.pay_type" */
  update_enum_pay_type_many?: Maybe<Array<Maybe<Enum_Pay_Type_Mutation_Response>>>;
  /** update data of the table: "enum.question_type" */
  update_enum_question_type?: Maybe<Enum_Question_Type_Mutation_Response>;
  /** update single row of the table: "enum.question_type" */
  update_enum_question_type_by_pk?: Maybe<Enum_Question_Type>;
  /** update multiples rows of table: "enum.question_type" */
  update_enum_question_type_many?: Maybe<Array<Maybe<Enum_Question_Type_Mutation_Response>>>;
  /** update data of the table: "enum.role" */
  update_enum_role?: Maybe<Enum_Role_Mutation_Response>;
  /** update single row of the table: "enum.role" */
  update_enum_role_by_pk?: Maybe<Enum_Role>;
  /** update multiples rows of table: "enum.role" */
  update_enum_role_many?: Maybe<Array<Maybe<Enum_Role_Mutation_Response>>>;
  /** update data of the table: "enum.work_type" */
  update_enum_work_type?: Maybe<Enum_Work_Type_Mutation_Response>;
  /** update single row of the table: "enum.work_type" */
  update_enum_work_type_by_pk?: Maybe<Enum_Work_Type>;
  /** update multiples rows of table: "enum.work_type" */
  update_enum_work_type_many?: Maybe<Array<Maybe<Enum_Work_Type_Mutation_Response>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "file" */
  update_file?: Maybe<File_Mutation_Response>;
  /** update single row of the table: "file" */
  update_file_by_pk?: Maybe<File>;
  /** update multiples rows of table: "file" */
  update_file_many?: Maybe<Array<Maybe<File_Mutation_Response>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<Geometry_Columns_Mutation_Response>>>;
  /** update data of the table: "integration_employer" */
  update_integration_employer?: Maybe<Integration_Employer_Mutation_Response>;
  /** update single row of the table: "integration_employer" */
  update_integration_employer_by_pk?: Maybe<Integration_Employer>;
  /** update multiples rows of table: "integration_employer" */
  update_integration_employer_many?: Maybe<Array<Maybe<Integration_Employer_Mutation_Response>>>;
  /** update data of the table: "integration_job" */
  update_integration_job?: Maybe<Integration_Job_Mutation_Response>;
  /** update single row of the table: "integration_job" */
  update_integration_job_by_pk?: Maybe<Integration_Job>;
  /** update multiples rows of table: "integration_job" */
  update_integration_job_many?: Maybe<Array<Maybe<Integration_Job_Mutation_Response>>>;
  /** update data of the table: "internal.raw_job_submission" */
  update_internal_raw_job_submission?: Maybe<Internal_Raw_Job_Submission_Mutation_Response>;
  /** update single row of the table: "internal.raw_job_submission" */
  update_internal_raw_job_submission_by_pk?: Maybe<Internal_Raw_Job_Submission>;
  /** update multiples rows of table: "internal.raw_job_submission" */
  update_internal_raw_job_submission_many?: Maybe<Array<Maybe<Internal_Raw_Job_Submission_Mutation_Response>>>;
  /** update data of the table: "job" */
  update_job?: Maybe<Job_Mutation_Response>;
  /** update data of the table: "job_additional_category" */
  update_job_additional_category?: Maybe<Job_Additional_Category_Mutation_Response>;
  /** update single row of the table: "job_additional_category" */
  update_job_additional_category_by_pk?: Maybe<Job_Additional_Category>;
  /** update multiples rows of table: "job_additional_category" */
  update_job_additional_category_many?: Maybe<Array<Maybe<Job_Additional_Category_Mutation_Response>>>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update multiples rows of table: "job" */
  update_job_many?: Maybe<Array<Maybe<Job_Mutation_Response>>>;
  /** update data of the table: "job_question" */
  update_job_question?: Maybe<Job_Question_Mutation_Response>;
  /** update single row of the table: "job_question" */
  update_job_question_by_pk?: Maybe<Job_Question>;
  /** update multiples rows of table: "job_question" */
  update_job_question_many?: Maybe<Array<Maybe<Job_Question_Mutation_Response>>>;
  /** update data of the table: "job_views" */
  update_job_views?: Maybe<Job_Views_Mutation_Response>;
  /** update single row of the table: "job_views" */
  update_job_views_by_pk?: Maybe<Job_Views>;
  /** update multiples rows of table: "job_views" */
  update_job_views_many?: Maybe<Array<Maybe<Job_Views_Mutation_Response>>>;
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>;
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>;
  /** update data of the table: "question" */
  update_question?: Maybe<Question_Mutation_Response>;
  /** update single row of the table: "question" */
  update_question_by_pk?: Maybe<Question>;
  /** update multiples rows of table: "question" */
  update_question_many?: Maybe<Array<Maybe<Question_Mutation_Response>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<Spatial_Ref_Sys_Mutation_Response>>>;
  /** update data of the table: "stripe.bundle" */
  update_stripe_bundle?: Maybe<Stripe_Bundle_Mutation_Response>;
  /** update single row of the table: "stripe.bundle" */
  update_stripe_bundle_by_pk?: Maybe<Stripe_Bundle>;
  /** update multiples rows of table: "stripe.bundle" */
  update_stripe_bundle_many?: Maybe<Array<Maybe<Stripe_Bundle_Mutation_Response>>>;
  /** update data of the table: "stripe.promotion" */
  update_stripe_promotion?: Maybe<Stripe_Promotion_Mutation_Response>;
  /** update single row of the table: "stripe.promotion" */
  update_stripe_promotion_by_pk?: Maybe<Stripe_Promotion>;
  /** update multiples rows of table: "stripe.promotion" */
  update_stripe_promotion_many?: Maybe<Array<Maybe<Stripe_Promotion_Mutation_Response>>>;
  /** update data of the table: "stripe.promotion_redemption" */
  update_stripe_promotion_redemption?: Maybe<Stripe_Promotion_Redemption_Mutation_Response>;
  /** update single row of the table: "stripe.promotion_redemption" */
  update_stripe_promotion_redemption_by_pk?: Maybe<Stripe_Promotion_Redemption>;
  /** update multiples rows of table: "stripe.promotion_redemption" */
  update_stripe_promotion_redemption_many?: Maybe<Array<Maybe<Stripe_Promotion_Redemption_Mutation_Response>>>;
  /** update data of the table: "stripe.recovered_session" */
  update_stripe_recovered_session?: Maybe<Stripe_Recovered_Session_Mutation_Response>;
  /** update single row of the table: "stripe.recovered_session" */
  update_stripe_recovered_session_by_pk?: Maybe<Stripe_Recovered_Session>;
  /** update multiples rows of table: "stripe.recovered_session" */
  update_stripe_recovered_session_many?: Maybe<Array<Maybe<Stripe_Recovered_Session_Mutation_Response>>>;
  /** update data of the table: "stripe.recovered_session_status" */
  update_stripe_recovered_session_status?: Maybe<Stripe_Recovered_Session_Status_Mutation_Response>;
  /** update single row of the table: "stripe.recovered_session_status" */
  update_stripe_recovered_session_status_by_pk?: Maybe<Stripe_Recovered_Session_Status>;
  /** update multiples rows of table: "stripe.recovered_session_status" */
  update_stripe_recovered_session_status_many?: Maybe<Array<Maybe<Stripe_Recovered_Session_Status_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "user_answer" */
  update_user_answer?: Maybe<User_Answer_Mutation_Response>;
  /** update single row of the table: "user_answer" */
  update_user_answer_by_pk?: Maybe<User_Answer>;
  /** update multiples rows of table: "user_answer" */
  update_user_answer_many?: Maybe<Array<Maybe<User_Answer_Mutation_Response>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_education" */
  update_user_education?: Maybe<User_Education_Mutation_Response>;
  /** update single row of the table: "user_education" */
  update_user_education_by_pk?: Maybe<User_Education>;
  /** update multiples rows of table: "user_education" */
  update_user_education_many?: Maybe<Array<Maybe<User_Education_Mutation_Response>>>;
  /** update data of the table: "user_experience" */
  update_user_experience?: Maybe<User_Experience_Mutation_Response>;
  /** update single row of the table: "user_experience" */
  update_user_experience_by_pk?: Maybe<User_Experience>;
  /** update multiples rows of table: "user_experience" */
  update_user_experience_many?: Maybe<Array<Maybe<User_Experience_Mutation_Response>>>;
  /** update data of the table: "user_licence" */
  update_user_licence?: Maybe<User_Licence_Mutation_Response>;
  /** update single row of the table: "user_licence" */
  update_user_licence_by_pk?: Maybe<User_Licence>;
  /** update multiples rows of table: "user_licence" */
  update_user_licence_many?: Maybe<Array<Maybe<User_Licence_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_search" */
  update_user_search?: Maybe<User_Search_Mutation_Response>;
  /** update single row of the table: "user_search" */
  update_user_search_by_pk?: Maybe<User_Search>;
  /** execute VOLATILE function "update_user_search_created_by" which returns "user_search" */
  update_user_search_created_by: Array<User_Search>;
  /** update multiples rows of table: "user_search" */
  update_user_search_many?: Maybe<Array<Maybe<User_Search_Mutation_Response>>>;
  /** update data of the table: "watchlist" */
  update_watchlist?: Maybe<Watchlist_Mutation_Response>;
  /** update single row of the table: "watchlist" */
  update_watchlist_by_pk?: Maybe<Watchlist>;
  /** update multiples rows of table: "watchlist" */
  update_watchlist_many?: Maybe<Array<Maybe<Watchlist_Mutation_Response>>>;
}


/** mutation root */
export interface Mutation_RootDelete_ApplicationArgs {
  where: Application_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Application_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_EmployerArgs {
  where: Employer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Employer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Employer_UserArgs {
  where: Employer_User_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Employer_User_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Access_LevelArgs {
  where: Enum_Access_Level_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Access_Level_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Application_StatusArgs {
  where: Enum_Application_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Application_Status_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_BillingArgs {
  where: Enum_Billing_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Billing_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_CategoryArgs {
  where: Enum_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Category_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Dial_CodeArgs {
  where: Enum_Dial_Code_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Dial_Code_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Integration_TypeArgs {
  where: Enum_Integration_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Integration_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Job_StatusArgs {
  where: Enum_Job_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Job_Status_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_ListingArgs {
  where: Enum_Listing_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Listing_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_OrganisationArgs {
  where: Enum_Organisation_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Organisation_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Pay_TypeArgs {
  where: Enum_Pay_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Pay_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Question_TypeArgs {
  where: Enum_Question_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Question_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_RoleArgs {
  where: Enum_Role_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Role_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Work_TypeArgs {
  where: Enum_Work_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Enum_Work_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_EventsArgs {
  where: Events_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Events_By_PkArgs {
  message_id: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_FileArgs {
  where: File_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_File_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Geometry_ColumnsArgs {
  where: Geometry_Columns_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Integration_EmployerArgs {
  where: Integration_Employer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Integration_Employer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Integration_JobArgs {
  where: Integration_Job_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Integration_Job_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Internal_Raw_Job_SubmissionArgs {
  where: Internal_Raw_Job_Submission_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Internal_Raw_Job_Submission_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_JobArgs {
  where: Job_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Job_Additional_CategoryArgs {
  where: Job_Additional_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Job_Additional_Category_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Job_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Job_QuestionArgs {
  where: Job_Question_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Job_Question_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Job_ViewsArgs {
  where: Job_Views_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Job_Views_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_LocationArgs {
  where: Location_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Location_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_QuestionArgs {
  where: Question_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Question_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Spatial_Ref_SysArgs {
  where: Spatial_Ref_Sys_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs {
  srid: Scalars['Int']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_BundleArgs {
  where: Stripe_Bundle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Bundle_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_PromotionArgs {
  where: Stripe_Promotion_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Promotion_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Promotion_RedemptionArgs {
  where: Stripe_Promotion_Redemption_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Promotion_Redemption_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Recovered_SessionArgs {
  where: Stripe_Recovered_Session_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Recovered_Session_By_PkArgs {
  session_id: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Recovered_Session_StatusArgs {
  where: Stripe_Recovered_Session_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Stripe_Recovered_Session_Status_By_PkArgs {
  type: Scalars['String']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_UserArgs {
  where: User_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_User_AnswerArgs {
  where: User_Answer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_User_Answer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_User_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_User_EducationArgs {
  where: User_Education_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_User_Education_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_User_ExperienceArgs {
  where: User_Experience_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_User_Experience_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_User_LicenceArgs {
  where: User_Licence_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_User_Licence_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_User_SearchArgs {
  where: User_Search_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_User_Search_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootDelete_WatchlistArgs {
  where: Watchlist_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootDelete_Watchlist_By_PkArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface Mutation_RootInsert_ApplicationArgs {
  objects: Array<Application_Insert_Input>;
  on_conflict?: InputMaybe<Application_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Application_OneArgs {
  object: Application_Insert_Input;
  on_conflict?: InputMaybe<Application_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_EmployerArgs {
  objects: Array<Employer_Insert_Input>;
  on_conflict?: InputMaybe<Employer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Employer_OneArgs {
  object: Employer_Insert_Input;
  on_conflict?: InputMaybe<Employer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Employer_UserArgs {
  objects: Array<Employer_User_Insert_Input>;
  on_conflict?: InputMaybe<Employer_User_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Employer_User_OneArgs {
  object: Employer_User_Insert_Input;
  on_conflict?: InputMaybe<Employer_User_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Access_LevelArgs {
  objects: Array<Enum_Access_Level_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Access_Level_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Access_Level_OneArgs {
  object: Enum_Access_Level_Insert_Input;
  on_conflict?: InputMaybe<Enum_Access_Level_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Application_StatusArgs {
  objects: Array<Enum_Application_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Application_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Application_Status_OneArgs {
  object: Enum_Application_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Application_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_BillingArgs {
  objects: Array<Enum_Billing_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Billing_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Billing_OneArgs {
  object: Enum_Billing_Insert_Input;
  on_conflict?: InputMaybe<Enum_Billing_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_CategoryArgs {
  objects: Array<Enum_Category_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Category_OneArgs {
  object: Enum_Category_Insert_Input;
  on_conflict?: InputMaybe<Enum_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Dial_CodeArgs {
  objects: Array<Enum_Dial_Code_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Dial_Code_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Dial_Code_OneArgs {
  object: Enum_Dial_Code_Insert_Input;
  on_conflict?: InputMaybe<Enum_Dial_Code_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Integration_TypeArgs {
  objects: Array<Enum_Integration_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Integration_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Integration_Type_OneArgs {
  object: Enum_Integration_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Integration_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Job_StatusArgs {
  objects: Array<Enum_Job_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Job_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Job_Status_OneArgs {
  object: Enum_Job_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Job_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_ListingArgs {
  objects: Array<Enum_Listing_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Listing_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Listing_OneArgs {
  object: Enum_Listing_Insert_Input;
  on_conflict?: InputMaybe<Enum_Listing_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_OrganisationArgs {
  objects: Array<Enum_Organisation_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Organisation_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Organisation_OneArgs {
  object: Enum_Organisation_Insert_Input;
  on_conflict?: InputMaybe<Enum_Organisation_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Pay_TypeArgs {
  objects: Array<Enum_Pay_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Pay_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Pay_Type_OneArgs {
  object: Enum_Pay_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Pay_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Question_TypeArgs {
  objects: Array<Enum_Question_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Question_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Question_Type_OneArgs {
  object: Enum_Question_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Question_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_RoleArgs {
  objects: Array<Enum_Role_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Role_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Role_OneArgs {
  object: Enum_Role_Insert_Input;
  on_conflict?: InputMaybe<Enum_Role_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Work_TypeArgs {
  objects: Array<Enum_Work_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Work_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Enum_Work_Type_OneArgs {
  object: Enum_Work_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Work_Type_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_EventsArgs {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Events_OneArgs {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_FileArgs {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_File_OneArgs {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Geometry_ColumnsArgs {
  objects: Array<Geometry_Columns_Insert_Input>;
}


/** mutation root */
export interface Mutation_RootInsert_Geometry_Columns_OneArgs {
  object: Geometry_Columns_Insert_Input;
}


/** mutation root */
export interface Mutation_RootInsert_Integration_EmployerArgs {
  objects: Array<Integration_Employer_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Employer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Integration_Employer_OneArgs {
  object: Integration_Employer_Insert_Input;
  on_conflict?: InputMaybe<Integration_Employer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Integration_JobArgs {
  objects: Array<Integration_Job_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Job_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Integration_Job_OneArgs {
  object: Integration_Job_Insert_Input;
  on_conflict?: InputMaybe<Integration_Job_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Internal_Raw_Job_SubmissionArgs {
  objects: Array<Internal_Raw_Job_Submission_Insert_Input>;
  on_conflict?: InputMaybe<Internal_Raw_Job_Submission_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Internal_Raw_Job_Submission_OneArgs {
  object: Internal_Raw_Job_Submission_Insert_Input;
  on_conflict?: InputMaybe<Internal_Raw_Job_Submission_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_JobArgs {
  objects: Array<Job_Insert_Input>;
  on_conflict?: InputMaybe<Job_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_Additional_CategoryArgs {
  objects: Array<Job_Additional_Category_Insert_Input>;
  on_conflict?: InputMaybe<Job_Additional_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_Additional_Category_OneArgs {
  object: Job_Additional_Category_Insert_Input;
  on_conflict?: InputMaybe<Job_Additional_Category_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_OneArgs {
  object: Job_Insert_Input;
  on_conflict?: InputMaybe<Job_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_QuestionArgs {
  objects: Array<Job_Question_Insert_Input>;
  on_conflict?: InputMaybe<Job_Question_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_Question_OneArgs {
  object: Job_Question_Insert_Input;
  on_conflict?: InputMaybe<Job_Question_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_ViewsArgs {
  objects: Array<Job_Views_Insert_Input>;
  on_conflict?: InputMaybe<Job_Views_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Job_Views_OneArgs {
  object: Job_Views_Insert_Input;
  on_conflict?: InputMaybe<Job_Views_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_LocationArgs {
  objects: Array<Location_Insert_Input>;
  on_conflict?: InputMaybe<Location_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Location_OneArgs {
  object: Location_Insert_Input;
  on_conflict?: InputMaybe<Location_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_QuestionArgs {
  objects: Array<Question_Insert_Input>;
  on_conflict?: InputMaybe<Question_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Question_OneArgs {
  object: Question_Insert_Input;
  on_conflict?: InputMaybe<Question_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Spatial_Ref_SysArgs {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Spatial_Ref_Sys_OneArgs {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_BundleArgs {
  objects: Array<Stripe_Bundle_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Bundle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Bundle_OneArgs {
  object: Stripe_Bundle_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Bundle_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_PromotionArgs {
  objects: Array<Stripe_Promotion_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Promotion_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Promotion_OneArgs {
  object: Stripe_Promotion_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Promotion_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Promotion_RedemptionArgs {
  objects: Array<Stripe_Promotion_Redemption_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Promotion_Redemption_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Promotion_Redemption_OneArgs {
  object: Stripe_Promotion_Redemption_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Promotion_Redemption_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Recovered_SessionArgs {
  objects: Array<Stripe_Recovered_Session_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Recovered_Session_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Recovered_Session_OneArgs {
  object: Stripe_Recovered_Session_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Recovered_Session_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Recovered_Session_StatusArgs {
  objects: Array<Stripe_Recovered_Session_Status_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Recovered_Session_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Stripe_Recovered_Session_Status_OneArgs {
  object: Stripe_Recovered_Session_Status_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Recovered_Session_Status_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_UserArgs {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_AnswerArgs {
  objects: Array<User_Answer_Insert_Input>;
  on_conflict?: InputMaybe<User_Answer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_Answer_OneArgs {
  object: User_Answer_Insert_Input;
  on_conflict?: InputMaybe<User_Answer_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_EducationArgs {
  objects: Array<User_Education_Insert_Input>;
  on_conflict?: InputMaybe<User_Education_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_Education_OneArgs {
  object: User_Education_Insert_Input;
  on_conflict?: InputMaybe<User_Education_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_ExperienceArgs {
  objects: Array<User_Experience_Insert_Input>;
  on_conflict?: InputMaybe<User_Experience_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_Experience_OneArgs {
  object: User_Experience_Insert_Input;
  on_conflict?: InputMaybe<User_Experience_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_LicenceArgs {
  objects: Array<User_Licence_Insert_Input>;
  on_conflict?: InputMaybe<User_Licence_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_Licence_OneArgs {
  object: User_Licence_Insert_Input;
  on_conflict?: InputMaybe<User_Licence_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_OneArgs {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_SearchArgs {
  objects: Array<User_Search_Insert_Input>;
  on_conflict?: InputMaybe<User_Search_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_User_Search_OneArgs {
  object: User_Search_Insert_Input;
  on_conflict?: InputMaybe<User_Search_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_WatchlistArgs {
  objects: Array<Watchlist_Insert_Input>;
  on_conflict?: InputMaybe<Watchlist_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootInsert_Watchlist_OneArgs {
  object: Watchlist_Insert_Input;
  on_conflict?: InputMaybe<Watchlist_On_Conflict>;
}


/** mutation root */
export interface Mutation_RootSet_Expiry_DateArgs {
  args: Set_Expiry_Date_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


/** mutation root */
export interface Mutation_RootUpdate_ApplicationArgs {
  _append?: InputMaybe<Application_Append_Input>;
  _delete_at_path?: InputMaybe<Application_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Application_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Application_Delete_Key_Input>;
  _prepend?: InputMaybe<Application_Prepend_Input>;
  _set?: InputMaybe<Application_Set_Input>;
  where: Application_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Application_By_PkArgs {
  _append?: InputMaybe<Application_Append_Input>;
  _delete_at_path?: InputMaybe<Application_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Application_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Application_Delete_Key_Input>;
  _prepend?: InputMaybe<Application_Prepend_Input>;
  _set?: InputMaybe<Application_Set_Input>;
  pk_columns: Application_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Application_ManyArgs {
  updates: Array<Application_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_EmployerArgs {
  _inc?: InputMaybe<Employer_Inc_Input>;
  _set?: InputMaybe<Employer_Set_Input>;
  where: Employer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Employer_By_PkArgs {
  _inc?: InputMaybe<Employer_Inc_Input>;
  _set?: InputMaybe<Employer_Set_Input>;
  pk_columns: Employer_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Employer_ManyArgs {
  updates: Array<Employer_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Employer_UserArgs {
  _set?: InputMaybe<Employer_User_Set_Input>;
  where: Employer_User_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Employer_User_By_PkArgs {
  _set?: InputMaybe<Employer_User_Set_Input>;
  pk_columns: Employer_User_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Employer_User_ManyArgs {
  updates: Array<Employer_User_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Access_LevelArgs {
  _set?: InputMaybe<Enum_Access_Level_Set_Input>;
  where: Enum_Access_Level_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Access_Level_By_PkArgs {
  _set?: InputMaybe<Enum_Access_Level_Set_Input>;
  pk_columns: Enum_Access_Level_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Access_Level_ManyArgs {
  updates: Array<Enum_Access_Level_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Application_StatusArgs {
  _set?: InputMaybe<Enum_Application_Status_Set_Input>;
  where: Enum_Application_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Application_Status_By_PkArgs {
  _set?: InputMaybe<Enum_Application_Status_Set_Input>;
  pk_columns: Enum_Application_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Application_Status_ManyArgs {
  updates: Array<Enum_Application_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_BillingArgs {
  _set?: InputMaybe<Enum_Billing_Set_Input>;
  where: Enum_Billing_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Billing_By_PkArgs {
  _set?: InputMaybe<Enum_Billing_Set_Input>;
  pk_columns: Enum_Billing_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Billing_ManyArgs {
  updates: Array<Enum_Billing_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_CategoryArgs {
  _set?: InputMaybe<Enum_Category_Set_Input>;
  where: Enum_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Category_By_PkArgs {
  _set?: InputMaybe<Enum_Category_Set_Input>;
  pk_columns: Enum_Category_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Category_ManyArgs {
  updates: Array<Enum_Category_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Dial_CodeArgs {
  _set?: InputMaybe<Enum_Dial_Code_Set_Input>;
  where: Enum_Dial_Code_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Dial_Code_By_PkArgs {
  _set?: InputMaybe<Enum_Dial_Code_Set_Input>;
  pk_columns: Enum_Dial_Code_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Dial_Code_ManyArgs {
  updates: Array<Enum_Dial_Code_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Integration_TypeArgs {
  _set?: InputMaybe<Enum_Integration_Type_Set_Input>;
  where: Enum_Integration_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Integration_Type_By_PkArgs {
  _set?: InputMaybe<Enum_Integration_Type_Set_Input>;
  pk_columns: Enum_Integration_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Integration_Type_ManyArgs {
  updates: Array<Enum_Integration_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Job_StatusArgs {
  _set?: InputMaybe<Enum_Job_Status_Set_Input>;
  where: Enum_Job_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Job_Status_By_PkArgs {
  _set?: InputMaybe<Enum_Job_Status_Set_Input>;
  pk_columns: Enum_Job_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Job_Status_ManyArgs {
  updates: Array<Enum_Job_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_ListingArgs {
  _set?: InputMaybe<Enum_Listing_Set_Input>;
  where: Enum_Listing_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Listing_By_PkArgs {
  _set?: InputMaybe<Enum_Listing_Set_Input>;
  pk_columns: Enum_Listing_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Listing_ManyArgs {
  updates: Array<Enum_Listing_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_OrganisationArgs {
  _set?: InputMaybe<Enum_Organisation_Set_Input>;
  where: Enum_Organisation_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Organisation_By_PkArgs {
  _set?: InputMaybe<Enum_Organisation_Set_Input>;
  pk_columns: Enum_Organisation_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Organisation_ManyArgs {
  updates: Array<Enum_Organisation_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Pay_TypeArgs {
  _set?: InputMaybe<Enum_Pay_Type_Set_Input>;
  where: Enum_Pay_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Pay_Type_By_PkArgs {
  _set?: InputMaybe<Enum_Pay_Type_Set_Input>;
  pk_columns: Enum_Pay_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Pay_Type_ManyArgs {
  updates: Array<Enum_Pay_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Question_TypeArgs {
  _set?: InputMaybe<Enum_Question_Type_Set_Input>;
  where: Enum_Question_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Question_Type_By_PkArgs {
  _set?: InputMaybe<Enum_Question_Type_Set_Input>;
  pk_columns: Enum_Question_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Question_Type_ManyArgs {
  updates: Array<Enum_Question_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_RoleArgs {
  _set?: InputMaybe<Enum_Role_Set_Input>;
  where: Enum_Role_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Role_By_PkArgs {
  _set?: InputMaybe<Enum_Role_Set_Input>;
  pk_columns: Enum_Role_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Role_ManyArgs {
  updates: Array<Enum_Role_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Work_TypeArgs {
  _set?: InputMaybe<Enum_Work_Type_Set_Input>;
  where: Enum_Work_Type_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Work_Type_By_PkArgs {
  _set?: InputMaybe<Enum_Work_Type_Set_Input>;
  pk_columns: Enum_Work_Type_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Enum_Work_Type_ManyArgs {
  updates: Array<Enum_Work_Type_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_EventsArgs {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Events_By_PkArgs {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Events_ManyArgs {
  updates: Array<Events_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_FileArgs {
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_File_By_PkArgs {
  _set?: InputMaybe<File_Set_Input>;
  pk_columns: File_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_File_ManyArgs {
  updates: Array<File_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Geometry_ColumnsArgs {
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Geometry_Columns_ManyArgs {
  updates: Array<Geometry_Columns_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Integration_EmployerArgs {
  _set?: InputMaybe<Integration_Employer_Set_Input>;
  where: Integration_Employer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Integration_Employer_By_PkArgs {
  _set?: InputMaybe<Integration_Employer_Set_Input>;
  pk_columns: Integration_Employer_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Integration_Employer_ManyArgs {
  updates: Array<Integration_Employer_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Integration_JobArgs {
  _append?: InputMaybe<Integration_Job_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Job_Delete_Key_Input>;
  _prepend?: InputMaybe<Integration_Job_Prepend_Input>;
  _set?: InputMaybe<Integration_Job_Set_Input>;
  where: Integration_Job_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Integration_Job_By_PkArgs {
  _append?: InputMaybe<Integration_Job_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Job_Delete_Key_Input>;
  _prepend?: InputMaybe<Integration_Job_Prepend_Input>;
  _set?: InputMaybe<Integration_Job_Set_Input>;
  pk_columns: Integration_Job_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Integration_Job_ManyArgs {
  updates: Array<Integration_Job_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Internal_Raw_Job_SubmissionArgs {
  _append?: InputMaybe<Internal_Raw_Job_Submission_Append_Input>;
  _delete_at_path?: InputMaybe<Internal_Raw_Job_Submission_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Internal_Raw_Job_Submission_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Internal_Raw_Job_Submission_Delete_Key_Input>;
  _prepend?: InputMaybe<Internal_Raw_Job_Submission_Prepend_Input>;
  _set?: InputMaybe<Internal_Raw_Job_Submission_Set_Input>;
  where: Internal_Raw_Job_Submission_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Internal_Raw_Job_Submission_By_PkArgs {
  _append?: InputMaybe<Internal_Raw_Job_Submission_Append_Input>;
  _delete_at_path?: InputMaybe<Internal_Raw_Job_Submission_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Internal_Raw_Job_Submission_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Internal_Raw_Job_Submission_Delete_Key_Input>;
  _prepend?: InputMaybe<Internal_Raw_Job_Submission_Prepend_Input>;
  _set?: InputMaybe<Internal_Raw_Job_Submission_Set_Input>;
  pk_columns: Internal_Raw_Job_Submission_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Internal_Raw_Job_Submission_ManyArgs {
  updates: Array<Internal_Raw_Job_Submission_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_JobArgs {
  _append?: InputMaybe<Job_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Delete_Key_Input>;
  _inc?: InputMaybe<Job_Inc_Input>;
  _prepend?: InputMaybe<Job_Prepend_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Additional_CategoryArgs {
  _set?: InputMaybe<Job_Additional_Category_Set_Input>;
  where: Job_Additional_Category_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Additional_Category_By_PkArgs {
  _set?: InputMaybe<Job_Additional_Category_Set_Input>;
  pk_columns: Job_Additional_Category_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Additional_Category_ManyArgs {
  updates: Array<Job_Additional_Category_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_By_PkArgs {
  _append?: InputMaybe<Job_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Delete_Key_Input>;
  _inc?: InputMaybe<Job_Inc_Input>;
  _prepend?: InputMaybe<Job_Prepend_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_ManyArgs {
  updates: Array<Job_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_QuestionArgs {
  _append?: InputMaybe<Job_Question_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Question_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Question_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Question_Delete_Key_Input>;
  _inc?: InputMaybe<Job_Question_Inc_Input>;
  _prepend?: InputMaybe<Job_Question_Prepend_Input>;
  _set?: InputMaybe<Job_Question_Set_Input>;
  where: Job_Question_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Question_By_PkArgs {
  _append?: InputMaybe<Job_Question_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Question_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Question_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Question_Delete_Key_Input>;
  _inc?: InputMaybe<Job_Question_Inc_Input>;
  _prepend?: InputMaybe<Job_Question_Prepend_Input>;
  _set?: InputMaybe<Job_Question_Set_Input>;
  pk_columns: Job_Question_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Question_ManyArgs {
  updates: Array<Job_Question_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_ViewsArgs {
  _inc?: InputMaybe<Job_Views_Inc_Input>;
  _set?: InputMaybe<Job_Views_Set_Input>;
  where: Job_Views_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Views_By_PkArgs {
  _inc?: InputMaybe<Job_Views_Inc_Input>;
  _set?: InputMaybe<Job_Views_Set_Input>;
  pk_columns: Job_Views_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Job_Views_ManyArgs {
  updates: Array<Job_Views_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_LocationArgs {
  _set?: InputMaybe<Location_Set_Input>;
  where: Location_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_By_PkArgs {
  _set?: InputMaybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Location_ManyArgs {
  updates: Array<Location_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_QuestionArgs {
  _append?: InputMaybe<Question_Append_Input>;
  _delete_at_path?: InputMaybe<Question_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Question_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Question_Delete_Key_Input>;
  _inc?: InputMaybe<Question_Inc_Input>;
  _prepend?: InputMaybe<Question_Prepend_Input>;
  _set?: InputMaybe<Question_Set_Input>;
  where: Question_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Question_By_PkArgs {
  _append?: InputMaybe<Question_Append_Input>;
  _delete_at_path?: InputMaybe<Question_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Question_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Question_Delete_Key_Input>;
  _inc?: InputMaybe<Question_Inc_Input>;
  _prepend?: InputMaybe<Question_Prepend_Input>;
  _set?: InputMaybe<Question_Set_Input>;
  pk_columns: Question_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Question_ManyArgs {
  updates: Array<Question_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Spatial_Ref_SysArgs {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Spatial_Ref_Sys_ManyArgs {
  updates: Array<Spatial_Ref_Sys_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_BundleArgs {
  _append?: InputMaybe<Stripe_Bundle_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Bundle_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Bundle_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Bundle_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Bundle_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Bundle_Prepend_Input>;
  _set?: InputMaybe<Stripe_Bundle_Set_Input>;
  where: Stripe_Bundle_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Bundle_By_PkArgs {
  _append?: InputMaybe<Stripe_Bundle_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Bundle_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Bundle_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Bundle_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Bundle_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Bundle_Prepend_Input>;
  _set?: InputMaybe<Stripe_Bundle_Set_Input>;
  pk_columns: Stripe_Bundle_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Bundle_ManyArgs {
  updates: Array<Stripe_Bundle_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_PromotionArgs {
  _inc?: InputMaybe<Stripe_Promotion_Inc_Input>;
  _set?: InputMaybe<Stripe_Promotion_Set_Input>;
  where: Stripe_Promotion_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Promotion_By_PkArgs {
  _inc?: InputMaybe<Stripe_Promotion_Inc_Input>;
  _set?: InputMaybe<Stripe_Promotion_Set_Input>;
  pk_columns: Stripe_Promotion_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Promotion_ManyArgs {
  updates: Array<Stripe_Promotion_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Promotion_RedemptionArgs {
  _set?: InputMaybe<Stripe_Promotion_Redemption_Set_Input>;
  where: Stripe_Promotion_Redemption_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Promotion_Redemption_By_PkArgs {
  _set?: InputMaybe<Stripe_Promotion_Redemption_Set_Input>;
  pk_columns: Stripe_Promotion_Redemption_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Promotion_Redemption_ManyArgs {
  updates: Array<Stripe_Promotion_Redemption_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Recovered_SessionArgs {
  _set?: InputMaybe<Stripe_Recovered_Session_Set_Input>;
  where: Stripe_Recovered_Session_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Recovered_Session_By_PkArgs {
  _set?: InputMaybe<Stripe_Recovered_Session_Set_Input>;
  pk_columns: Stripe_Recovered_Session_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Recovered_Session_ManyArgs {
  updates: Array<Stripe_Recovered_Session_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Recovered_Session_StatusArgs {
  _set?: InputMaybe<Stripe_Recovered_Session_Status_Set_Input>;
  where: Stripe_Recovered_Session_Status_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Recovered_Session_Status_By_PkArgs {
  _set?: InputMaybe<Stripe_Recovered_Session_Status_Set_Input>;
  pk_columns: Stripe_Recovered_Session_Status_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Stripe_Recovered_Session_Status_ManyArgs {
  updates: Array<Stripe_Recovered_Session_Status_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_UserArgs {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_User_AnswerArgs {
  _append?: InputMaybe<User_Answer_Append_Input>;
  _delete_at_path?: InputMaybe<User_Answer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Answer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Answer_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Answer_Prepend_Input>;
  _set?: InputMaybe<User_Answer_Set_Input>;
  where: User_Answer_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Answer_By_PkArgs {
  _append?: InputMaybe<User_Answer_Append_Input>;
  _delete_at_path?: InputMaybe<User_Answer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Answer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Answer_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Answer_Prepend_Input>;
  _set?: InputMaybe<User_Answer_Set_Input>;
  pk_columns: User_Answer_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Answer_ManyArgs {
  updates: Array<User_Answer_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_By_PkArgs {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_User_EducationArgs {
  _set?: InputMaybe<User_Education_Set_Input>;
  where: User_Education_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Education_By_PkArgs {
  _set?: InputMaybe<User_Education_Set_Input>;
  pk_columns: User_Education_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Education_ManyArgs {
  updates: Array<User_Education_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_ExperienceArgs {
  _set?: InputMaybe<User_Experience_Set_Input>;
  where: User_Experience_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Experience_By_PkArgs {
  _set?: InputMaybe<User_Experience_Set_Input>;
  pk_columns: User_Experience_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Experience_ManyArgs {
  updates: Array<User_Experience_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_LicenceArgs {
  _set?: InputMaybe<User_Licence_Set_Input>;
  where: User_Licence_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Licence_By_PkArgs {
  _set?: InputMaybe<User_Licence_Set_Input>;
  pk_columns: User_Licence_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Licence_ManyArgs {
  updates: Array<User_Licence_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_ManyArgs {
  updates: Array<User_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_SearchArgs {
  _set?: InputMaybe<User_Search_Set_Input>;
  where: User_Search_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Search_By_PkArgs {
  _set?: InputMaybe<User_Search_Set_Input>;
  pk_columns: User_Search_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Search_Created_ByArgs {
  args: Update_User_Search_Created_By_Args;
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


/** mutation root */
export interface Mutation_RootUpdate_User_Search_ManyArgs {
  updates: Array<User_Search_Updates>;
}


/** mutation root */
export interface Mutation_RootUpdate_WatchlistArgs {
  _set?: InputMaybe<Watchlist_Set_Input>;
  where: Watchlist_Bool_Exp;
}


/** mutation root */
export interface Mutation_RootUpdate_Watchlist_By_PkArgs {
  _set?: InputMaybe<Watchlist_Set_Input>;
  pk_columns: Watchlist_Pk_Columns_Input;
}


/** mutation root */
export interface Mutation_RootUpdate_Watchlist_ManyArgs {
  updates: Array<Watchlist_Updates>;
}

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export interface Name_Comparison_Exp {
  _eq?: InputMaybe<Scalars['name']['input']>;
  _gt?: InputMaybe<Scalars['name']['input']>;
  _gte?: InputMaybe<Scalars['name']['input']>;
  _in?: InputMaybe<Array<Scalars['name']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['name']['input']>;
  _lte?: InputMaybe<Scalars['name']['input']>;
  _neq?: InputMaybe<Scalars['name']['input']>;
  _nin?: InputMaybe<Array<Scalars['name']['input']>>;
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface Numeric_Comparison_Exp {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
}

/** column ordering options */
export const Order_By = {
  /** in ascending order, nulls last */
  Asc: 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst: 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast: 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc: 'desc',
  /** in descending order, nulls first */
  DescNullsFirst: 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast: 'desc_nulls_last'
} as const;

export type Order_By = typeof Order_By[keyof typeof Order_By];
export interface Query_Root {
  __typename?: 'query_root';
  /** fetch data from the table: "application" */
  application: Array<Application>;
  /** fetch aggregated fields from the table: "application" */
  application_aggregate: Application_Aggregate;
  /** fetch data from the table: "application" using primary key columns */
  application_by_pk?: Maybe<Application>;
  /** execute function "compute_category" which returns "enum.category" */
  compute_category?: Maybe<Enum_Category>;
  /** execute function "compute_category" and query aggregates on result of table type "enum.category" */
  compute_category_aggregate: Enum_Category_Aggregate;
  /** execute function "compute_job_employer" which returns "employer" */
  compute_job_employer: Array<Employer>;
  /** execute function "compute_job_employer" and query aggregates on result of table type "employer" */
  compute_job_employer_aggregate: Employer_Aggregate;
  /** execute function "compute_job_integration" which returns "integration_job" */
  compute_job_integration: Array<Integration_Job>;
  /** execute function "compute_job_integration" and query aggregates on result of table type "integration_job" */
  compute_job_integration_aggregate: Integration_Job_Aggregate;
  /** execute function "compute_listing_type" which returns "enum.listing" */
  compute_listing_type?: Maybe<Enum_Listing>;
  /** execute function "compute_listing_type" and query aggregates on result of table type "enum.listing" */
  compute_listing_type_aggregate: Enum_Listing_Aggregate;
  /** execute function "compute_location" which returns "location" */
  compute_location?: Maybe<Location>;
  /** execute function "compute_location" and query aggregates on result of table type "location" */
  compute_location_aggregate: Location_Aggregate;
  /** execute function "compute_questions" which returns "questions_and_answers" */
  compute_questions: Array<Questions_And_Answers>;
  /** execute function "compute_questions" and query aggregates on result of table type "questions_and_answers" */
  compute_questions_aggregate: Questions_And_Answers_Aggregate;
  /** execute function "compute_work_type" which returns "enum.work_type" */
  compute_work_type?: Maybe<Enum_Work_Type>;
  /** execute function "compute_work_type" and query aggregates on result of table type "enum.work_type" */
  compute_work_type_aggregate: Enum_Work_Type_Aggregate;
  /** fetch data from the table: "employer" */
  employer: Array<Employer>;
  /** fetch aggregated fields from the table: "employer" */
  employer_aggregate: Employer_Aggregate;
  /** fetch data from the table: "employer" using primary key columns */
  employer_by_pk?: Maybe<Employer>;
  /** fetch data from the table: "employer_user" */
  employer_user: Array<Employer_User>;
  /** fetch aggregated fields from the table: "employer_user" */
  employer_user_aggregate: Employer_User_Aggregate;
  /** execute function "employer_user_by_id" which returns "user" */
  employer_user_by_id?: Maybe<User>;
  /** execute function "employer_user_by_id" and query aggregates on result of table type "user" */
  employer_user_by_id_aggregate: User_Aggregate;
  /** fetch data from the table: "employer_user" using primary key columns */
  employer_user_by_pk?: Maybe<Employer_User>;
  /** fetch data from the table: "enum.access_level" */
  enum_access_level: Array<Enum_Access_Level>;
  /** fetch aggregated fields from the table: "enum.access_level" */
  enum_access_level_aggregate: Enum_Access_Level_Aggregate;
  /** fetch data from the table: "enum.access_level" using primary key columns */
  enum_access_level_by_pk?: Maybe<Enum_Access_Level>;
  /** fetch data from the table: "enum.application_status" */
  enum_application_status: Array<Enum_Application_Status>;
  /** fetch aggregated fields from the table: "enum.application_status" */
  enum_application_status_aggregate: Enum_Application_Status_Aggregate;
  /** fetch data from the table: "enum.application_status" using primary key columns */
  enum_application_status_by_pk?: Maybe<Enum_Application_Status>;
  /** fetch data from the table: "enum.billing" */
  enum_billing: Array<Enum_Billing>;
  /** fetch aggregated fields from the table: "enum.billing" */
  enum_billing_aggregate: Enum_Billing_Aggregate;
  /** fetch data from the table: "enum.billing" using primary key columns */
  enum_billing_by_pk?: Maybe<Enum_Billing>;
  /** fetch data from the table: "enum.category" */
  enum_category: Array<Enum_Category>;
  /** fetch aggregated fields from the table: "enum.category" */
  enum_category_aggregate: Enum_Category_Aggregate;
  /** fetch data from the table: "enum.category" using primary key columns */
  enum_category_by_pk?: Maybe<Enum_Category>;
  /** fetch data from the table: "enum.dial_code" */
  enum_dial_code: Array<Enum_Dial_Code>;
  /** fetch aggregated fields from the table: "enum.dial_code" */
  enum_dial_code_aggregate: Enum_Dial_Code_Aggregate;
  /** fetch data from the table: "enum.dial_code" using primary key columns */
  enum_dial_code_by_pk?: Maybe<Enum_Dial_Code>;
  /** fetch data from the table: "enum.integration_type" */
  enum_integration_type: Array<Enum_Integration_Type>;
  /** fetch aggregated fields from the table: "enum.integration_type" */
  enum_integration_type_aggregate: Enum_Integration_Type_Aggregate;
  /** fetch data from the table: "enum.integration_type" using primary key columns */
  enum_integration_type_by_pk?: Maybe<Enum_Integration_Type>;
  /** fetch data from the table: "enum.job_status" */
  enum_job_status: Array<Enum_Job_Status>;
  /** fetch aggregated fields from the table: "enum.job_status" */
  enum_job_status_aggregate: Enum_Job_Status_Aggregate;
  /** fetch data from the table: "enum.job_status" using primary key columns */
  enum_job_status_by_pk?: Maybe<Enum_Job_Status>;
  /** fetch data from the table: "enum.listing" */
  enum_listing: Array<Enum_Listing>;
  /** fetch aggregated fields from the table: "enum.listing" */
  enum_listing_aggregate: Enum_Listing_Aggregate;
  /** fetch data from the table: "enum.listing" using primary key columns */
  enum_listing_by_pk?: Maybe<Enum_Listing>;
  /** fetch data from the table: "enum.organisation" */
  enum_organisation: Array<Enum_Organisation>;
  /** fetch aggregated fields from the table: "enum.organisation" */
  enum_organisation_aggregate: Enum_Organisation_Aggregate;
  /** fetch data from the table: "enum.organisation" using primary key columns */
  enum_organisation_by_pk?: Maybe<Enum_Organisation>;
  /** fetch data from the table: "enum.pay_type" */
  enum_pay_type: Array<Enum_Pay_Type>;
  /** fetch aggregated fields from the table: "enum.pay_type" */
  enum_pay_type_aggregate: Enum_Pay_Type_Aggregate;
  /** fetch data from the table: "enum.pay_type" using primary key columns */
  enum_pay_type_by_pk?: Maybe<Enum_Pay_Type>;
  /** fetch data from the table: "enum.question_type" */
  enum_question_type: Array<Enum_Question_Type>;
  /** fetch aggregated fields from the table: "enum.question_type" */
  enum_question_type_aggregate: Enum_Question_Type_Aggregate;
  /** fetch data from the table: "enum.question_type" using primary key columns */
  enum_question_type_by_pk?: Maybe<Enum_Question_Type>;
  /** fetch data from the table: "enum.role" */
  enum_role: Array<Enum_Role>;
  /** fetch aggregated fields from the table: "enum.role" */
  enum_role_aggregate: Enum_Role_Aggregate;
  /** fetch data from the table: "enum.role" using primary key columns */
  enum_role_by_pk?: Maybe<Enum_Role>;
  /** fetch data from the table: "enum.work_type" */
  enum_work_type: Array<Enum_Work_Type>;
  /** fetch aggregated fields from the table: "enum.work_type" */
  enum_work_type_aggregate: Enum_Work_Type_Aggregate;
  /** fetch data from the table: "enum.work_type" using primary key columns */
  enum_work_type_by_pk?: Maybe<Enum_Work_Type>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  geolocation?: Maybe<SampleOutput>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table: "integration_employer" */
  integration_employer: Array<Integration_Employer>;
  /** fetch aggregated fields from the table: "integration_employer" */
  integration_employer_aggregate: Integration_Employer_Aggregate;
  /** fetch data from the table: "integration_employer" using primary key columns */
  integration_employer_by_pk?: Maybe<Integration_Employer>;
  /** fetch data from the table: "integration_job" */
  integration_job: Array<Integration_Job>;
  /** fetch aggregated fields from the table: "integration_job" */
  integration_job_aggregate: Integration_Job_Aggregate;
  /** fetch data from the table: "integration_job" using primary key columns */
  integration_job_by_pk?: Maybe<Integration_Job>;
  /** fetch data from the table: "internal.raw_job_submission" */
  internal_raw_job_submission: Array<Internal_Raw_Job_Submission>;
  /** fetch aggregated fields from the table: "internal.raw_job_submission" */
  internal_raw_job_submission_aggregate: Internal_Raw_Job_Submission_Aggregate;
  /** fetch data from the table: "internal.raw_job_submission" using primary key columns */
  internal_raw_job_submission_by_pk?: Maybe<Internal_Raw_Job_Submission>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch data from the table: "job_additional_category" */
  job_additional_category: Array<Job_Additional_Category>;
  /** fetch aggregated fields from the table: "job_additional_category" */
  job_additional_category_aggregate: Job_Additional_Category_Aggregate;
  /** fetch data from the table: "job_additional_category" using primary key columns */
  job_additional_category_by_pk?: Maybe<Job_Additional_Category>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "job_question" */
  job_question: Array<Job_Question>;
  /** fetch aggregated fields from the table: "job_question" */
  job_question_aggregate: Job_Question_Aggregate;
  /** fetch data from the table: "job_question" using primary key columns */
  job_question_by_pk?: Maybe<Job_Question>;
  /** fetch data from the table: "job_views" */
  job_views: Array<Job_Views>;
  /** fetch aggregated fields from the table: "job_views" */
  job_views_aggregate: Job_Views_Aggregate;
  /** fetch data from the table: "job_views" using primary key columns */
  job_views_by_pk?: Maybe<Job_Views>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: Question_Aggregate;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table: "questions_and_answers" */
  questions_and_answers: Array<Questions_And_Answers>;
  /** fetch aggregated fields from the table: "questions_and_answers" */
  questions_and_answers_aggregate: Questions_And_Answers_Aggregate;
  /** execute function "random_eoi" which returns "job" */
  random_eoi: Array<Job>;
  /** execute function "random_eoi" and query aggregates on result of table type "job" */
  random_eoi_aggregate: Job_Aggregate;
  /** execute function "search" which returns "job" */
  search: Array<Job>;
  /** execute function "search" and query aggregates on result of table type "job" */
  search_aggregate: Job_Aggregate;
  /** execute function "search_local_id" which returns "job" */
  search_local_id: Array<Job>;
  /** execute function "search_local_id" and query aggregates on result of table type "job" */
  search_local_id_aggregate: Job_Aggregate;
  /** execute function "search_local_params" which returns "job" */
  search_local_params: Array<Job>;
  /** execute function "search_local_params" and query aggregates on result of table type "job" */
  search_local_params_aggregate: Job_Aggregate;
  /** execute function "search_location" which returns "location" */
  search_location: Array<Location>;
  /** execute function "search_location" and query aggregates on result of table type "location" */
  search_location_aggregate: Location_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table: "stripe.bundle" */
  stripe_bundle: Array<Stripe_Bundle>;
  /** fetch aggregated fields from the table: "stripe.bundle" */
  stripe_bundle_aggregate: Stripe_Bundle_Aggregate;
  /** fetch data from the table: "stripe.bundle" using primary key columns */
  stripe_bundle_by_pk?: Maybe<Stripe_Bundle>;
  /** fetch data from the table: "stripe.promotion" */
  stripe_promotion: Array<Stripe_Promotion>;
  /** fetch aggregated fields from the table: "stripe.promotion" */
  stripe_promotion_aggregate: Stripe_Promotion_Aggregate;
  /** fetch data from the table: "stripe.promotion" using primary key columns */
  stripe_promotion_by_pk?: Maybe<Stripe_Promotion>;
  /** fetch data from the table: "stripe.promotion_redemption" */
  stripe_promotion_redemption: Array<Stripe_Promotion_Redemption>;
  /** fetch aggregated fields from the table: "stripe.promotion_redemption" */
  stripe_promotion_redemption_aggregate: Stripe_Promotion_Redemption_Aggregate;
  /** fetch data from the table: "stripe.promotion_redemption" using primary key columns */
  stripe_promotion_redemption_by_pk?: Maybe<Stripe_Promotion_Redemption>;
  /** fetch data from the table: "stripe.recovered_session" */
  stripe_recovered_session: Array<Stripe_Recovered_Session>;
  /** fetch aggregated fields from the table: "stripe.recovered_session" */
  stripe_recovered_session_aggregate: Stripe_Recovered_Session_Aggregate;
  /** fetch data from the table: "stripe.recovered_session" using primary key columns */
  stripe_recovered_session_by_pk?: Maybe<Stripe_Recovered_Session>;
  /** fetch data from the table: "stripe.recovered_session_status" */
  stripe_recovered_session_status: Array<Stripe_Recovered_Session_Status>;
  /** fetch aggregated fields from the table: "stripe.recovered_session_status" */
  stripe_recovered_session_status_aggregate: Stripe_Recovered_Session_Status_Aggregate;
  /** fetch data from the table: "stripe.recovered_session_status" using primary key columns */
  stripe_recovered_session_status_by_pk?: Maybe<Stripe_Recovered_Session_Status>;
  /** execute function "unauth_user_search" which returns "user_search" */
  unauth_user_search: Array<User_Search>;
  /** execute function "unauth_user_search" and query aggregates on result of table type "user_search" */
  unauth_user_search_aggregate: User_Search_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user_answer" */
  user_answer: Array<User_Answer>;
  /** fetch aggregated fields from the table: "user_answer" */
  user_answer_aggregate: User_Answer_Aggregate;
  /** fetch data from the table: "user_answer" using primary key columns */
  user_answer_by_pk?: Maybe<User_Answer>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_education" */
  user_education: Array<User_Education>;
  /** fetch aggregated fields from the table: "user_education" */
  user_education_aggregate: User_Education_Aggregate;
  /** fetch data from the table: "user_education" using primary key columns */
  user_education_by_pk?: Maybe<User_Education>;
  /** fetch data from the table: "user_experience" */
  user_experience: Array<User_Experience>;
  /** fetch aggregated fields from the table: "user_experience" */
  user_experience_aggregate: User_Experience_Aggregate;
  /** fetch data from the table: "user_experience" using primary key columns */
  user_experience_by_pk?: Maybe<User_Experience>;
  /** fetch data from the table: "user_licence" */
  user_licence: Array<User_Licence>;
  /** fetch aggregated fields from the table: "user_licence" */
  user_licence_aggregate: User_Licence_Aggregate;
  /** fetch data from the table: "user_licence" using primary key columns */
  user_licence_by_pk?: Maybe<User_Licence>;
  /** fetch data from the table: "user_search" */
  user_search: Array<User_Search>;
  /** fetch aggregated fields from the table: "user_search" */
  user_search_aggregate: User_Search_Aggregate;
  /** fetch data from the table: "user_search" using primary key columns */
  user_search_by_pk?: Maybe<User_Search>;
  /** An array relationship */
  watchlist: Array<Watchlist>;
  /** An aggregate relationship */
  watchlist_aggregate: Watchlist_Aggregate;
  /** fetch data from the table: "watchlist" using primary key columns */
  watchlist_by_pk?: Maybe<Watchlist>;
}


export interface Query_RootApplicationArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


export interface Query_RootApplication_AggregateArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


export interface Query_RootApplication_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootCompute_CategoryArgs {
  args: Compute_Category_Args;
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Query_RootCompute_Category_AggregateArgs {
  args: Compute_Category_Args;
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Query_RootCompute_Job_EmployerArgs {
  args: Compute_Job_Employer_Args;
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Query_RootCompute_Job_Employer_AggregateArgs {
  args: Compute_Job_Employer_Args;
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Query_RootCompute_Job_IntegrationArgs {
  args: Compute_Job_Integration_Args;
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Query_RootCompute_Job_Integration_AggregateArgs {
  args: Compute_Job_Integration_Args;
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Query_RootCompute_Listing_TypeArgs {
  args: Compute_Listing_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Query_RootCompute_Listing_Type_AggregateArgs {
  args: Compute_Listing_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Query_RootCompute_LocationArgs {
  args: Compute_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Query_RootCompute_Location_AggregateArgs {
  args: Compute_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Query_RootCompute_QuestionsArgs {
  args: Compute_Questions_Args;
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Query_RootCompute_Questions_AggregateArgs {
  args: Compute_Questions_Args;
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Query_RootCompute_Work_TypeArgs {
  args: Compute_Work_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Query_RootCompute_Work_Type_AggregateArgs {
  args: Compute_Work_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Query_RootEmployerArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Query_RootEmployer_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Query_RootEmployer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootEmployer_UserArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


export interface Query_RootEmployer_User_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


export interface Query_RootEmployer_User_By_IdArgs {
  args: Employer_User_By_Id_Args;
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Query_RootEmployer_User_By_Id_AggregateArgs {
  args: Employer_User_By_Id_Args;
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Query_RootEmployer_User_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootEnum_Access_LevelArgs {
  distinct_on?: InputMaybe<Array<Enum_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Access_Level_Order_By>>;
  where?: InputMaybe<Enum_Access_Level_Bool_Exp>;
}


export interface Query_RootEnum_Access_Level_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Access_Level_Order_By>>;
  where?: InputMaybe<Enum_Access_Level_Bool_Exp>;
}


export interface Query_RootEnum_Access_Level_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Application_StatusArgs {
  distinct_on?: InputMaybe<Array<Enum_Application_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Application_Status_Order_By>>;
  where?: InputMaybe<Enum_Application_Status_Bool_Exp>;
}


export interface Query_RootEnum_Application_Status_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Application_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Application_Status_Order_By>>;
  where?: InputMaybe<Enum_Application_Status_Bool_Exp>;
}


export interface Query_RootEnum_Application_Status_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_BillingArgs {
  distinct_on?: InputMaybe<Array<Enum_Billing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Billing_Order_By>>;
  where?: InputMaybe<Enum_Billing_Bool_Exp>;
}


export interface Query_RootEnum_Billing_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Billing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Billing_Order_By>>;
  where?: InputMaybe<Enum_Billing_Bool_Exp>;
}


export interface Query_RootEnum_Billing_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_CategoryArgs {
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Query_RootEnum_Category_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Query_RootEnum_Category_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Dial_CodeArgs {
  distinct_on?: InputMaybe<Array<Enum_Dial_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Dial_Code_Order_By>>;
  where?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
}


export interface Query_RootEnum_Dial_Code_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Dial_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Dial_Code_Order_By>>;
  where?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
}


export interface Query_RootEnum_Dial_Code_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Integration_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Integration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Integration_Type_Order_By>>;
  where?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
}


export interface Query_RootEnum_Integration_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Integration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Integration_Type_Order_By>>;
  where?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
}


export interface Query_RootEnum_Integration_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Job_StatusArgs {
  distinct_on?: InputMaybe<Array<Enum_Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Job_Status_Order_By>>;
  where?: InputMaybe<Enum_Job_Status_Bool_Exp>;
}


export interface Query_RootEnum_Job_Status_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Job_Status_Order_By>>;
  where?: InputMaybe<Enum_Job_Status_Bool_Exp>;
}


export interface Query_RootEnum_Job_Status_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_ListingArgs {
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Query_RootEnum_Listing_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Query_RootEnum_Listing_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_OrganisationArgs {
  distinct_on?: InputMaybe<Array<Enum_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Organisation_Order_By>>;
  where?: InputMaybe<Enum_Organisation_Bool_Exp>;
}


export interface Query_RootEnum_Organisation_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Organisation_Order_By>>;
  where?: InputMaybe<Enum_Organisation_Bool_Exp>;
}


export interface Query_RootEnum_Organisation_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Pay_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Pay_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Pay_Type_Order_By>>;
  where?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
}


export interface Query_RootEnum_Pay_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Pay_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Pay_Type_Order_By>>;
  where?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
}


export interface Query_RootEnum_Pay_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Question_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Question_Type_Order_By>>;
  where?: InputMaybe<Enum_Question_Type_Bool_Exp>;
}


export interface Query_RootEnum_Question_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Question_Type_Order_By>>;
  where?: InputMaybe<Enum_Question_Type_Bool_Exp>;
}


export interface Query_RootEnum_Question_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_RoleArgs {
  distinct_on?: InputMaybe<Array<Enum_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Role_Order_By>>;
  where?: InputMaybe<Enum_Role_Bool_Exp>;
}


export interface Query_RootEnum_Role_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Role_Order_By>>;
  where?: InputMaybe<Enum_Role_Bool_Exp>;
}


export interface Query_RootEnum_Role_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEnum_Work_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Query_RootEnum_Work_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Query_RootEnum_Work_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Query_RootEventsArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


export interface Query_RootEvents_AggregateArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


export interface Query_RootEvents_By_PkArgs {
  message_id: Scalars['String']['input'];
}


export interface Query_RootFileArgs {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
}


export interface Query_RootFile_AggregateArgs {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
}


export interface Query_RootFile_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootGeography_ColumnsArgs {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
}


export interface Query_RootGeography_Columns_AggregateArgs {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
}


export interface Query_RootGeolocationArgs {
  address: Scalars['String']['input'];
}


export interface Query_RootGeometry_ColumnsArgs {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
}


export interface Query_RootGeometry_Columns_AggregateArgs {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
}


export interface Query_RootIntegration_EmployerArgs {
  distinct_on?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Employer_Order_By>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


export interface Query_RootIntegration_Employer_AggregateArgs {
  distinct_on?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Employer_Order_By>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


export interface Query_RootIntegration_Employer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootIntegration_JobArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Query_RootIntegration_Job_AggregateArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Query_RootIntegration_Job_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootInternal_Raw_Job_SubmissionArgs {
  distinct_on?: InputMaybe<Array<Internal_Raw_Job_Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Raw_Job_Submission_Order_By>>;
  where?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
}


export interface Query_RootInternal_Raw_Job_Submission_AggregateArgs {
  distinct_on?: InputMaybe<Array<Internal_Raw_Job_Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Raw_Job_Submission_Order_By>>;
  where?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
}


export interface Query_RootInternal_Raw_Job_Submission_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootJobArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootJob_Additional_CategoryArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


export interface Query_RootJob_Additional_Category_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


export interface Query_RootJob_Additional_Category_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootJob_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootJob_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootJob_QuestionArgs {
  distinct_on?: InputMaybe<Array<Job_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Question_Order_By>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


export interface Query_RootJob_Question_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Question_Order_By>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


export interface Query_RootJob_Question_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootJob_ViewsArgs {
  distinct_on?: InputMaybe<Array<Job_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Views_Order_By>>;
  where?: InputMaybe<Job_Views_Bool_Exp>;
}


export interface Query_RootJob_Views_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Views_Order_By>>;
  where?: InputMaybe<Job_Views_Bool_Exp>;
}


export interface Query_RootJob_Views_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootLocationArgs {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Query_RootLocation_AggregateArgs {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Query_RootLocation_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootQuestionArgs {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
}


export interface Query_RootQuestion_AggregateArgs {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
}


export interface Query_RootQuestion_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootQuestions_And_AnswersArgs {
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Query_RootQuestions_And_Answers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Query_RootRandom_EoiArgs {
  args: Random_Eoi_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootRandom_Eoi_AggregateArgs {
  args: Random_Eoi_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearchArgs {
  args: Search_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearch_AggregateArgs {
  args: Search_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearch_Local_IdArgs {
  args: Search_Local_Id_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearch_Local_Id_AggregateArgs {
  args: Search_Local_Id_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearch_Local_ParamsArgs {
  args: Search_Local_Params_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearch_Local_Params_AggregateArgs {
  args: Search_Local_Params_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Query_RootSearch_LocationArgs {
  args: Search_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Query_RootSearch_Location_AggregateArgs {
  args: Search_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Query_RootSpatial_Ref_SysArgs {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
}


export interface Query_RootSpatial_Ref_Sys_AggregateArgs {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
}


export interface Query_RootSpatial_Ref_Sys_By_PkArgs {
  srid: Scalars['Int']['input'];
}


export interface Query_RootStripe_BundleArgs {
  distinct_on?: InputMaybe<Array<Stripe_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Bundle_Order_By>>;
  where?: InputMaybe<Stripe_Bundle_Bool_Exp>;
}


export interface Query_RootStripe_Bundle_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Bundle_Order_By>>;
  where?: InputMaybe<Stripe_Bundle_Bool_Exp>;
}


export interface Query_RootStripe_Bundle_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootStripe_PromotionArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Bool_Exp>;
}


export interface Query_RootStripe_Promotion_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Bool_Exp>;
}


export interface Query_RootStripe_Promotion_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootStripe_Promotion_RedemptionArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


export interface Query_RootStripe_Promotion_Redemption_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


export interface Query_RootStripe_Promotion_Redemption_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootStripe_Recovered_SessionArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
}


export interface Query_RootStripe_Recovered_Session_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
}


export interface Query_RootStripe_Recovered_Session_By_PkArgs {
  session_id: Scalars['String']['input'];
}


export interface Query_RootStripe_Recovered_Session_StatusArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Status_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
}


export interface Query_RootStripe_Recovered_Session_Status_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Status_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
}


export interface Query_RootStripe_Recovered_Session_Status_By_PkArgs {
  type: Scalars['String']['input'];
}


export interface Query_RootUnauth_User_SearchArgs {
  args: Unauth_User_Search_Args;
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Query_RootUnauth_User_Search_AggregateArgs {
  args: Unauth_User_Search_Args;
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Query_RootUserArgs {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Query_RootUser_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Query_RootUser_AnswerArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


export interface Query_RootUser_Answer_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


export interface Query_RootUser_Answer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootUser_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootUser_EducationArgs {
  distinct_on?: InputMaybe<Array<User_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Education_Order_By>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


export interface Query_RootUser_Education_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Education_Order_By>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


export interface Query_RootUser_Education_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootUser_ExperienceArgs {
  distinct_on?: InputMaybe<Array<User_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Experience_Order_By>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


export interface Query_RootUser_Experience_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Experience_Order_By>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


export interface Query_RootUser_Experience_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootUser_LicenceArgs {
  distinct_on?: InputMaybe<Array<User_Licence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Licence_Order_By>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


export interface Query_RootUser_Licence_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Licence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Licence_Order_By>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


export interface Query_RootUser_Licence_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootUser_SearchArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Query_RootUser_Search_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Query_RootUser_Search_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Query_RootWatchlistArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


export interface Query_RootWatchlist_AggregateArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


export interface Query_RootWatchlist_By_PkArgs {
  id: Scalars['uuid']['input'];
}

/** question and answer pairs  */
export interface Question {
  __typename?: 'question';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  explainer?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
  order: Scalars['numeric']['output'];
  question: Scalars['String']['output'];
  section: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_answers: Array<User_Answer>;
  /** An aggregate relationship */
  user_answers_aggregate: User_Answer_Aggregate;
}


/** question and answer pairs  */
export interface QuestionOptionsArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** question and answer pairs  */
export interface QuestionUser_AnswersArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


/** question and answer pairs  */
export interface QuestionUser_Answers_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}

/** aggregated selection of "question" */
export interface Question_Aggregate {
  __typename?: 'question_aggregate';
  aggregate?: Maybe<Question_Aggregate_Fields>;
  nodes: Array<Question>;
}

/** aggregate fields of "question" */
export interface Question_Aggregate_Fields {
  __typename?: 'question_aggregate_fields';
  avg?: Maybe<Question_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Question_Max_Fields>;
  min?: Maybe<Question_Min_Fields>;
  stddev?: Maybe<Question_Stddev_Fields>;
  stddev_pop?: Maybe<Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Question_Stddev_Samp_Fields>;
  sum?: Maybe<Question_Sum_Fields>;
  var_pop?: Maybe<Question_Var_Pop_Fields>;
  var_samp?: Maybe<Question_Var_Samp_Fields>;
  variance?: Maybe<Question_Variance_Fields>;
}


/** aggregate fields of "question" */
export interface Question_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Question_Append_Input {
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate avg on columns */
export interface Question_Avg_Fields {
  __typename?: 'question_avg_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'. */
export interface Question_Bool_Exp {
  _and?: InputMaybe<Array<Question_Bool_Exp>>;
  _not?: InputMaybe<Question_Bool_Exp>;
  _or?: InputMaybe<Array<Question_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  explainer?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  section?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_answers?: InputMaybe<User_Answer_Bool_Exp>;
  user_answers_aggregate?: InputMaybe<User_Answer_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "question" */
export const Question_Constraint = {
  /** unique or primary key constraint on columns "id" */
  QuestionPkey: 'question_pkey'
} as const;

export type Question_Constraint = typeof Question_Constraint[keyof typeof Question_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Question_Delete_At_Path_Input {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Question_Delete_Elem_Input {
  options?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Question_Delete_Key_Input {
  options?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "question" */
export interface Question_Inc_Input {
  order?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "question" */
export interface Question_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  explainer?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_answers?: InputMaybe<User_Answer_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Question_Max_Fields {
  __typename?: 'question_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  explainer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface Question_Min_Fields {
  __typename?: 'question_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  explainer?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "question" */
export interface Question_Mutation_Response {
  __typename?: 'question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Question>;
}

/** input type for inserting object relation for remote table "question" */
export interface Question_Obj_Rel_Insert_Input {
  data: Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Question_On_Conflict>;
}

/** on_conflict condition type for table "question" */
export interface Question_On_Conflict {
  constraint: Question_Constraint;
  update_columns?: Array<Question_Update_Column>;
  where?: InputMaybe<Question_Bool_Exp>;
}

/** Ordering options when selecting data from "question". */
export interface Question_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  explainer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  section?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_answers_aggregate?: InputMaybe<User_Answer_Aggregate_Order_By>;
}

/** primary key columns input for table: question */
export interface Question_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Question_Prepend_Input {
  options?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "question" */
export const Question_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Explainer: 'explainer',
  /** column name */
  Id: 'id',
  /** column name */
  Options: 'options',
  /** column name */
  Order: 'order',
  /** column name */
  Question: 'question',
  /** column name */
  Section: 'section',
  /** column name */
  Type: 'type',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Question_Select_Column = typeof Question_Select_Column[keyof typeof Question_Select_Column];
/** input type for updating data in table "question" */
export interface Question_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  explainer?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate stddev on columns */
export interface Question_Stddev_Fields {
  __typename?: 'question_stddev_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Question_Stddev_Pop_Fields {
  __typename?: 'question_stddev_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Question_Stddev_Samp_Fields {
  __typename?: 'question_stddev_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "question" */
export interface Question_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Question_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  explainer?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate sum on columns */
export interface Question_Sum_Fields {
  __typename?: 'question_sum_fields';
  order?: Maybe<Scalars['numeric']['output']>;
}

/** update columns of table "question" */
export const Question_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Explainer: 'explainer',
  /** column name */
  Id: 'id',
  /** column name */
  Options: 'options',
  /** column name */
  Order: 'order',
  /** column name */
  Question: 'question',
  /** column name */
  Section: 'section',
  /** column name */
  Type: 'type',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Question_Update_Column = typeof Question_Update_Column[keyof typeof Question_Update_Column];
export interface Question_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Question_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Question_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Question_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Question_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Question_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Question_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Question_Set_Input>;
  where: Question_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Question_Var_Pop_Fields {
  __typename?: 'question_var_pop_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Question_Var_Samp_Fields {
  __typename?: 'question_var_samp_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Question_Variance_Fields {
  __typename?: 'question_variance_fields';
  order?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "questions_and_answers" */
export interface Questions_And_Answers {
  __typename?: 'questions_and_answers';
  answers?: Maybe<Scalars['jsonb']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}


/** columns and relationships of "questions_and_answers" */
export interface Questions_And_AnswersAnswersArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

export interface Questions_And_Answers_Aggregate {
  __typename?: 'questions_and_answers_aggregate';
  aggregate?: Maybe<Questions_And_Answers_Aggregate_Fields>;
  nodes: Array<Questions_And_Answers>;
}

/** aggregate fields of "questions_and_answers" */
export interface Questions_And_Answers_Aggregate_Fields {
  __typename?: 'questions_and_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Questions_And_Answers_Max_Fields>;
  min?: Maybe<Questions_And_Answers_Min_Fields>;
}


/** aggregate fields of "questions_and_answers" */
export interface Questions_And_Answers_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "questions_and_answers" */
export interface Questions_And_Answers_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_And_Answers_Max_Order_By>;
  min?: InputMaybe<Questions_And_Answers_Min_Order_By>;
}

/** Boolean expression to filter rows from the table "questions_and_answers". All fields are combined with a logical 'AND'. */
export interface Questions_And_Answers_Bool_Exp {
  _and?: InputMaybe<Array<Questions_And_Answers_Bool_Exp>>;
  _not?: InputMaybe<Questions_And_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_And_Answers_Bool_Exp>>;
  answers?: InputMaybe<Jsonb_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
}

/** aggregate max on columns */
export interface Questions_And_Answers_Max_Fields {
  __typename?: 'questions_and_answers_max_fields';
  question?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** order by max() on columns of table "questions_and_answers" */
export interface Questions_And_Answers_Max_Order_By {
  question?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Questions_And_Answers_Min_Fields {
  __typename?: 'questions_and_answers_min_fields';
  question?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

/** order by min() on columns of table "questions_and_answers" */
export interface Questions_And_Answers_Min_Order_By {
  question?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
}

/** Ordering options when selecting data from "questions_and_answers". */
export interface Questions_And_Answers_Order_By {
  answers?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
}

/** select columns of table "questions_and_answers" */
export const Questions_And_Answers_Select_Column = {
  /** column name */
  Answers: 'answers',
  /** column name */
  Question: 'question',
  /** column name */
  Type: 'type'
} as const;

export type Questions_And_Answers_Select_Column = typeof Questions_And_Answers_Select_Column[keyof typeof Questions_And_Answers_Select_Column];
/** Streaming cursor of the table "questions_and_answers" */
export interface Questions_And_Answers_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Questions_And_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Questions_And_Answers_Stream_Cursor_Value_Input {
  answers?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface Random_Eoi_Args {
  eoi_category?: InputMaybe<Scalars['String']['input']>;
}

export interface Search_Args {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface Search_Local_Id_Args {
  search?: InputMaybe<Scalars['String']['input']>;
  user_loc_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface Search_Local_Params_Args {
  search?: InputMaybe<Scalars['String']['input']>;
  user_district?: InputMaybe<Scalars['String']['input']>;
  user_municipality?: InputMaybe<Scalars['String']['input']>;
  user_neighbourhood?: InputMaybe<Scalars['String']['input']>;
  user_region?: InputMaybe<Scalars['String']['input']>;
}

export interface Search_Location_Args {
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface Set_Expiry_Date_Args {
  job_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** columns and relationships of "spatial_ref_sys" */
export interface Spatial_Ref_Sys {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid: Scalars['Int']['output'];
  srtext?: Maybe<Scalars['String']['output']>;
}

/** aggregated selection of "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Aggregate {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
}

/** aggregate fields of "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Aggregate_Fields {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
}


/** aggregate fields of "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Spatial_Ref_Sys_Avg_Fields {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export interface Spatial_Ref_Sys_Bool_Exp {
  _and?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: InputMaybe<String_Comparison_Exp>;
  auth_srid?: InputMaybe<Int_Comparison_Exp>;
  proj4text?: InputMaybe<String_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  srtext?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "spatial_ref_sys" */
export const Spatial_Ref_Sys_Constraint = {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey: 'spatial_ref_sys_pkey'
} as const;

export type Spatial_Ref_Sys_Constraint = typeof Spatial_Ref_Sys_Constraint[keyof typeof Spatial_Ref_Sys_Constraint];
/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Inc_Input {
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Insert_Input {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Spatial_Ref_Sys_Max_Fields {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  srtext?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Spatial_Ref_Sys_Min_Fields {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  srtext?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Mutation_Response {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
}

/** on_conflict condition type for table "spatial_ref_sys" */
export interface Spatial_Ref_Sys_On_Conflict {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
}

/** Ordering options when selecting data from "spatial_ref_sys". */
export interface Spatial_Ref_Sys_Order_By {
  auth_name?: InputMaybe<Order_By>;
  auth_srid?: InputMaybe<Order_By>;
  proj4text?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  srtext?: InputMaybe<Order_By>;
}

/** primary key columns input for table: spatial_ref_sys */
export interface Spatial_Ref_Sys_Pk_Columns_Input {
  srid: Scalars['Int']['input'];
}

/** select columns of table "spatial_ref_sys" */
export const Spatial_Ref_Sys_Select_Column = {
  /** column name */
  AuthName: 'auth_name',
  /** column name */
  AuthSrid: 'auth_srid',
  /** column name */
  Proj4text: 'proj4text',
  /** column name */
  Srid: 'srid',
  /** column name */
  Srtext: 'srtext'
} as const;

export type Spatial_Ref_Sys_Select_Column = typeof Spatial_Ref_Sys_Select_Column[keyof typeof Spatial_Ref_Sys_Select_Column];
/** input type for updating data in table "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Set_Input {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Spatial_Ref_Sys_Stddev_Fields {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Spatial_Ref_Sys_Stddev_Pop_Fields {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Spatial_Ref_Sys_Stddev_Samp_Fields {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "spatial_ref_sys" */
export interface Spatial_Ref_Sys_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Spatial_Ref_Sys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Spatial_Ref_Sys_Stream_Cursor_Value_Input {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Spatial_Ref_Sys_Sum_Fields {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "spatial_ref_sys" */
export const Spatial_Ref_Sys_Update_Column = {
  /** column name */
  AuthName: 'auth_name',
  /** column name */
  AuthSrid: 'auth_srid',
  /** column name */
  Proj4text: 'proj4text',
  /** column name */
  Srid: 'srid',
  /** column name */
  Srtext: 'srtext'
} as const;

export type Spatial_Ref_Sys_Update_Column = typeof Spatial_Ref_Sys_Update_Column[keyof typeof Spatial_Ref_Sys_Update_Column];
export interface Spatial_Ref_Sys_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Spatial_Ref_Sys_Var_Pop_Fields {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Spatial_Ref_Sys_Var_Samp_Fields {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Spatial_Ref_Sys_Variance_Fields {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
}

export interface St_D_Within_Geography_Input {
  distance: Scalars['Float']['input'];
  from: Scalars['geography']['input'];
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface St_D_Within_Input {
  distance: Scalars['Float']['input'];
  from: Scalars['geometry']['input'];
}

/** columns and relationships of "stripe.bundle" */
export interface Stripe_Bundle {
  __typename?: 'stripe_bundle';
  bundle: Scalars['jsonb']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description: Scalars['String']['output'];
  features?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  listing?: Maybe<Enum_Listing>;
  listing_type?: Maybe<Scalars['String']['output']>;
  package: Scalars['Boolean']['output'];
  price: Scalars['numeric']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}


/** columns and relationships of "stripe.bundle" */
export interface Stripe_BundleBundleArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "stripe.bundle" */
export interface Stripe_BundleFeaturesArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "stripe.bundle" */
export interface Stripe_Bundle_Aggregate {
  __typename?: 'stripe_bundle_aggregate';
  aggregate?: Maybe<Stripe_Bundle_Aggregate_Fields>;
  nodes: Array<Stripe_Bundle>;
}

/** aggregate fields of "stripe.bundle" */
export interface Stripe_Bundle_Aggregate_Fields {
  __typename?: 'stripe_bundle_aggregate_fields';
  avg?: Maybe<Stripe_Bundle_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Bundle_Max_Fields>;
  min?: Maybe<Stripe_Bundle_Min_Fields>;
  stddev?: Maybe<Stripe_Bundle_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Bundle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Bundle_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Bundle_Sum_Fields>;
  var_pop?: Maybe<Stripe_Bundle_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Bundle_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Bundle_Variance_Fields>;
}


/** aggregate fields of "stripe.bundle" */
export interface Stripe_Bundle_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Stripe_Bundle_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface Stripe_Bundle_Append_Input {
  bundle?: InputMaybe<Scalars['jsonb']['input']>;
  features?: InputMaybe<Scalars['jsonb']['input']>;
}

/** aggregate avg on columns */
export interface Stripe_Bundle_Avg_Fields {
  __typename?: 'stripe_bundle_avg_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "stripe.bundle". All fields are combined with a logical 'AND'. */
export interface Stripe_Bundle_Bool_Exp {
  _and?: InputMaybe<Array<Stripe_Bundle_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Bundle_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Bundle_Bool_Exp>>;
  bundle?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  features?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  listing?: InputMaybe<Enum_Listing_Bool_Exp>;
  listing_type?: InputMaybe<String_Comparison_Exp>;
  package?: InputMaybe<Boolean_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "stripe.bundle" */
export const Stripe_Bundle_Constraint = {
  /** unique or primary key constraint on columns "id" */
  BundlePkey: 'bundle_pkey',
  /** unique or primary key constraint on columns "title" */
  BundleTitleKey: 'bundle_title_key'
} as const;

export type Stripe_Bundle_Constraint = typeof Stripe_Bundle_Constraint[keyof typeof Stripe_Bundle_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface Stripe_Bundle_Delete_At_Path_Input {
  bundle?: InputMaybe<Array<Scalars['String']['input']>>;
  features?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface Stripe_Bundle_Delete_Elem_Input {
  bundle?: InputMaybe<Scalars['Int']['input']>;
  features?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface Stripe_Bundle_Delete_Key_Input {
  bundle?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Scalars['String']['input']>;
}

/** input type for incrementing numeric columns in table "stripe.bundle" */
export interface Stripe_Bundle_Inc_Input {
  price?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "stripe.bundle" */
export interface Stripe_Bundle_Insert_Input {
  bundle?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  listing?: InputMaybe<Enum_Listing_Obj_Rel_Insert_Input>;
  listing_type?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Stripe_Bundle_Max_Fields {
  __typename?: 'stripe_bundle_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  listing_type?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Stripe_Bundle_Min_Fields {
  __typename?: 'stripe_bundle_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  listing_type?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "stripe.bundle" */
export interface Stripe_Bundle_Mutation_Response {
  __typename?: 'stripe_bundle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Bundle>;
}

/** on_conflict condition type for table "stripe.bundle" */
export interface Stripe_Bundle_On_Conflict {
  constraint: Stripe_Bundle_Constraint;
  update_columns?: Array<Stripe_Bundle_Update_Column>;
  where?: InputMaybe<Stripe_Bundle_Bool_Exp>;
}

/** Ordering options when selecting data from "stripe.bundle". */
export interface Stripe_Bundle_Order_By {
  bundle?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  features?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listing?: InputMaybe<Enum_Listing_Order_By>;
  listing_type?: InputMaybe<Order_By>;
  package?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
}

/** primary key columns input for table: stripe.bundle */
export interface Stripe_Bundle_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface Stripe_Bundle_Prepend_Input {
  bundle?: InputMaybe<Scalars['jsonb']['input']>;
  features?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "stripe.bundle" */
export const Stripe_Bundle_Select_Column = {
  /** column name */
  Bundle: 'bundle',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Description: 'description',
  /** column name */
  Features: 'features',
  /** column name */
  Id: 'id',
  /** column name */
  ListingType: 'listing_type',
  /** column name */
  Package: 'package',
  /** column name */
  Price: 'price',
  /** column name */
  Subtitle: 'subtitle',
  /** column name */
  Title: 'title'
} as const;

export type Stripe_Bundle_Select_Column = typeof Stripe_Bundle_Select_Column[keyof typeof Stripe_Bundle_Select_Column];
/** input type for updating data in table "stripe.bundle" */
export interface Stripe_Bundle_Set_Input {
  bundle?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  listing_type?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate stddev on columns */
export interface Stripe_Bundle_Stddev_Fields {
  __typename?: 'stripe_bundle_stddev_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Stripe_Bundle_Stddev_Pop_Fields {
  __typename?: 'stripe_bundle_stddev_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Stripe_Bundle_Stddev_Samp_Fields {
  __typename?: 'stripe_bundle_stddev_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "stripe_bundle" */
export interface Stripe_Bundle_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Stripe_Bundle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Stripe_Bundle_Stream_Cursor_Value_Input {
  bundle?: InputMaybe<Scalars['jsonb']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  listing_type?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate sum on columns */
export interface Stripe_Bundle_Sum_Fields {
  __typename?: 'stripe_bundle_sum_fields';
  price?: Maybe<Scalars['numeric']['output']>;
}

/** update columns of table "stripe.bundle" */
export const Stripe_Bundle_Update_Column = {
  /** column name */
  Bundle: 'bundle',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Description: 'description',
  /** column name */
  Features: 'features',
  /** column name */
  Id: 'id',
  /** column name */
  ListingType: 'listing_type',
  /** column name */
  Package: 'package',
  /** column name */
  Price: 'price',
  /** column name */
  Subtitle: 'subtitle',
  /** column name */
  Title: 'title'
} as const;

export type Stripe_Bundle_Update_Column = typeof Stripe_Bundle_Update_Column[keyof typeof Stripe_Bundle_Update_Column];
export interface Stripe_Bundle_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Bundle_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Bundle_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Bundle_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Bundle_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Bundle_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Bundle_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Bundle_Set_Input>;
  where: Stripe_Bundle_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Stripe_Bundle_Var_Pop_Fields {
  __typename?: 'stripe_bundle_var_pop_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Stripe_Bundle_Var_Samp_Fields {
  __typename?: 'stripe_bundle_var_samp_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Stripe_Bundle_Variance_Fields {
  __typename?: 'stripe_bundle_variance_fields';
  price?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "stripe.promotion" */
export interface Stripe_Promotion {
  __typename?: 'stripe_promotion';
  active: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  coupon_fkey: Scalars['String']['output'];
  /** An object relationship */
  employer?: Maybe<Employer>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  expires_at?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  max_redemptions?: Maybe<Scalars['Int']['output']>;
  minimum_order?: Maybe<Scalars['Int']['output']>;
  one_per_customer: Scalars['Boolean']['output'];
  /** An array relationship */
  redemptions: Array<Stripe_Promotion_Redemption>;
  /** An aggregate relationship */
  redemptions_aggregate: Stripe_Promotion_Redemption_Aggregate;
}


/** columns and relationships of "stripe.promotion" */
export interface Stripe_PromotionRedemptionsArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


/** columns and relationships of "stripe.promotion" */
export interface Stripe_PromotionRedemptions_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}

/** aggregated selection of "stripe.promotion" */
export interface Stripe_Promotion_Aggregate {
  __typename?: 'stripe_promotion_aggregate';
  aggregate?: Maybe<Stripe_Promotion_Aggregate_Fields>;
  nodes: Array<Stripe_Promotion>;
}

/** aggregate fields of "stripe.promotion" */
export interface Stripe_Promotion_Aggregate_Fields {
  __typename?: 'stripe_promotion_aggregate_fields';
  avg?: Maybe<Stripe_Promotion_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Promotion_Max_Fields>;
  min?: Maybe<Stripe_Promotion_Min_Fields>;
  stddev?: Maybe<Stripe_Promotion_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Promotion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Promotion_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Promotion_Sum_Fields>;
  var_pop?: Maybe<Stripe_Promotion_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Promotion_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Promotion_Variance_Fields>;
}


/** aggregate fields of "stripe.promotion" */
export interface Stripe_Promotion_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Stripe_Promotion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate avg on columns */
export interface Stripe_Promotion_Avg_Fields {
  __typename?: 'stripe_promotion_avg_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** Boolean expression to filter rows from the table "stripe.promotion". All fields are combined with a logical 'AND'. */
export interface Stripe_Promotion_Bool_Exp {
  _and?: InputMaybe<Array<Stripe_Promotion_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Promotion_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Promotion_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  coupon_fkey?: InputMaybe<String_Comparison_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  expires_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  max_redemptions?: InputMaybe<Int_Comparison_Exp>;
  minimum_order?: InputMaybe<Int_Comparison_Exp>;
  one_per_customer?: InputMaybe<Boolean_Comparison_Exp>;
  redemptions?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
  redemptions_aggregate?: InputMaybe<Stripe_Promotion_Redemption_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "stripe.promotion" */
export const Stripe_Promotion_Constraint = {
  /** unique or primary key constraint on columns "code" */
  PromotionCodeKey: 'promotion_code_key',
  /** unique or primary key constraint on columns "id" */
  PromotionPkey: 'promotion_pkey'
} as const;

export type Stripe_Promotion_Constraint = typeof Stripe_Promotion_Constraint[keyof typeof Stripe_Promotion_Constraint];
/** input type for incrementing numeric columns in table "stripe.promotion" */
export interface Stripe_Promotion_Inc_Input {
  max_redemptions?: InputMaybe<Scalars['Int']['input']>;
  minimum_order?: InputMaybe<Scalars['Int']['input']>;
}

/** input type for inserting data into table "stripe.promotion" */
export interface Stripe_Promotion_Insert_Input {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  coupon_fkey?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  expires_at?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  max_redemptions?: InputMaybe<Scalars['Int']['input']>;
  minimum_order?: InputMaybe<Scalars['Int']['input']>;
  one_per_customer?: InputMaybe<Scalars['Boolean']['input']>;
  redemptions?: InputMaybe<Stripe_Promotion_Redemption_Arr_Rel_Insert_Input>;
}

/** aggregate max on columns */
export interface Stripe_Promotion_Max_Fields {
  __typename?: 'stripe_promotion_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  coupon_fkey?: Maybe<Scalars['String']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  expires_at?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  max_redemptions?: Maybe<Scalars['Int']['output']>;
  minimum_order?: Maybe<Scalars['Int']['output']>;
}

/** aggregate min on columns */
export interface Stripe_Promotion_Min_Fields {
  __typename?: 'stripe_promotion_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  coupon_fkey?: Maybe<Scalars['String']['output']>;
  employer_id?: Maybe<Scalars['uuid']['output']>;
  expires_at?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  max_redemptions?: Maybe<Scalars['Int']['output']>;
  minimum_order?: Maybe<Scalars['Int']['output']>;
}

/** response of any mutation on the table "stripe.promotion" */
export interface Stripe_Promotion_Mutation_Response {
  __typename?: 'stripe_promotion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Promotion>;
}

/** input type for inserting object relation for remote table "stripe.promotion" */
export interface Stripe_Promotion_Obj_Rel_Insert_Input {
  data: Stripe_Promotion_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Promotion_On_Conflict>;
}

/** on_conflict condition type for table "stripe.promotion" */
export interface Stripe_Promotion_On_Conflict {
  constraint: Stripe_Promotion_Constraint;
  update_columns?: Array<Stripe_Promotion_Update_Column>;
  where?: InputMaybe<Stripe_Promotion_Bool_Exp>;
}

/** Ordering options when selecting data from "stripe.promotion". */
export interface Stripe_Promotion_Order_By {
  active?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  coupon_fkey?: InputMaybe<Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_redemptions?: InputMaybe<Order_By>;
  minimum_order?: InputMaybe<Order_By>;
  one_per_customer?: InputMaybe<Order_By>;
  redemptions_aggregate?: InputMaybe<Stripe_Promotion_Redemption_Aggregate_Order_By>;
}

/** primary key columns input for table: stripe.promotion */
export interface Stripe_Promotion_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** columns and relationships of "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption {
  __typename?: 'stripe_promotion_redemption';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid']['output'];
  /** An object relationship */
  promotion: Stripe_Promotion;
  promotion_id: Scalars['uuid']['output'];
}

/** aggregated selection of "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Aggregate {
  __typename?: 'stripe_promotion_redemption_aggregate';
  aggregate?: Maybe<Stripe_Promotion_Redemption_Aggregate_Fields>;
  nodes: Array<Stripe_Promotion_Redemption>;
}

export interface Stripe_Promotion_Redemption_Aggregate_Bool_Exp {
  count?: InputMaybe<Stripe_Promotion_Redemption_Aggregate_Bool_Exp_Count>;
}

export interface Stripe_Promotion_Redemption_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Aggregate_Fields {
  __typename?: 'stripe_promotion_redemption_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Promotion_Redemption_Max_Fields>;
  min?: Maybe<Stripe_Promotion_Redemption_Min_Fields>;
}


/** aggregate fields of "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stripe_Promotion_Redemption_Max_Order_By>;
  min?: InputMaybe<Stripe_Promotion_Redemption_Min_Order_By>;
}

/** input type for inserting array relation for remote table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Arr_Rel_Insert_Input {
  data: Array<Stripe_Promotion_Redemption_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Promotion_Redemption_On_Conflict>;
}

/** Boolean expression to filter rows from the table "stripe.promotion_redemption". All fields are combined with a logical 'AND'. */
export interface Stripe_Promotion_Redemption_Bool_Exp {
  _and?: InputMaybe<Array<Stripe_Promotion_Redemption_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Promotion_Redemption_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  promotion?: InputMaybe<Stripe_Promotion_Bool_Exp>;
  promotion_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "stripe.promotion_redemption" */
export const Stripe_Promotion_Redemption_Constraint = {
  /** unique or primary key constraint on columns "id" */
  PromotionRedemptionPkey: 'promotion_redemption_pkey'
} as const;

export type Stripe_Promotion_Redemption_Constraint = typeof Stripe_Promotion_Redemption_Constraint[keyof typeof Stripe_Promotion_Redemption_Constraint];
/** input type for inserting data into table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  promotion?: InputMaybe<Stripe_Promotion_Obj_Rel_Insert_Input>;
  promotion_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface Stripe_Promotion_Redemption_Max_Fields {
  __typename?: 'stripe_promotion_redemption_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  promotion_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  promotion_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Stripe_Promotion_Redemption_Min_Fields {
  __typename?: 'stripe_promotion_redemption_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  promotion_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  promotion_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Mutation_Response {
  __typename?: 'stripe_promotion_redemption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Promotion_Redemption>;
}

/** on_conflict condition type for table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_On_Conflict {
  constraint: Stripe_Promotion_Redemption_Constraint;
  update_columns?: Array<Stripe_Promotion_Redemption_Update_Column>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}

/** Ordering options when selecting data from "stripe.promotion_redemption". */
export interface Stripe_Promotion_Redemption_Order_By {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  promotion?: InputMaybe<Stripe_Promotion_Order_By>;
  promotion_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: stripe.promotion_redemption */
export interface Stripe_Promotion_Redemption_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "stripe.promotion_redemption" */
export const Stripe_Promotion_Redemption_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  PromotionId: 'promotion_id'
} as const;

export type Stripe_Promotion_Redemption_Select_Column = typeof Stripe_Promotion_Redemption_Select_Column[keyof typeof Stripe_Promotion_Redemption_Select_Column];
/** input type for updating data in table "stripe.promotion_redemption" */
export interface Stripe_Promotion_Redemption_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  promotion_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "stripe_promotion_redemption" */
export interface Stripe_Promotion_Redemption_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Stripe_Promotion_Redemption_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Stripe_Promotion_Redemption_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  promotion_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "stripe.promotion_redemption" */
export const Stripe_Promotion_Redemption_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  PromotionId: 'promotion_id'
} as const;

export type Stripe_Promotion_Redemption_Update_Column = typeof Stripe_Promotion_Redemption_Update_Column[keyof typeof Stripe_Promotion_Redemption_Update_Column];
export interface Stripe_Promotion_Redemption_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Promotion_Redemption_Set_Input>;
  where: Stripe_Promotion_Redemption_Bool_Exp;
}

/** select columns of table "stripe.promotion" */
export const Stripe_Promotion_Select_Column = {
  /** column name */
  Active: 'active',
  /** column name */
  Code: 'code',
  /** column name */
  CouponFkey: 'coupon_fkey',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  ExpiresAt: 'expires_at',
  /** column name */
  Id: 'id',
  /** column name */
  MaxRedemptions: 'max_redemptions',
  /** column name */
  MinimumOrder: 'minimum_order',
  /** column name */
  OnePerCustomer: 'one_per_customer'
} as const;

export type Stripe_Promotion_Select_Column = typeof Stripe_Promotion_Select_Column[keyof typeof Stripe_Promotion_Select_Column];
/** input type for updating data in table "stripe.promotion" */
export interface Stripe_Promotion_Set_Input {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  coupon_fkey?: InputMaybe<Scalars['String']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  expires_at?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  max_redemptions?: InputMaybe<Scalars['Int']['input']>;
  minimum_order?: InputMaybe<Scalars['Int']['input']>;
  one_per_customer?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate stddev on columns */
export interface Stripe_Promotion_Stddev_Fields {
  __typename?: 'stripe_promotion_stddev_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_pop on columns */
export interface Stripe_Promotion_Stddev_Pop_Fields {
  __typename?: 'stripe_promotion_stddev_pop_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate stddev_samp on columns */
export interface Stripe_Promotion_Stddev_Samp_Fields {
  __typename?: 'stripe_promotion_stddev_samp_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** Streaming cursor of the table "stripe_promotion" */
export interface Stripe_Promotion_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Stripe_Promotion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Stripe_Promotion_Stream_Cursor_Value_Input {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  coupon_fkey?: InputMaybe<Scalars['String']['input']>;
  employer_id?: InputMaybe<Scalars['uuid']['input']>;
  expires_at?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  max_redemptions?: InputMaybe<Scalars['Int']['input']>;
  minimum_order?: InputMaybe<Scalars['Int']['input']>;
  one_per_customer?: InputMaybe<Scalars['Boolean']['input']>;
}

/** aggregate sum on columns */
export interface Stripe_Promotion_Sum_Fields {
  __typename?: 'stripe_promotion_sum_fields';
  max_redemptions?: Maybe<Scalars['Int']['output']>;
  minimum_order?: Maybe<Scalars['Int']['output']>;
}

/** update columns of table "stripe.promotion" */
export const Stripe_Promotion_Update_Column = {
  /** column name */
  Active: 'active',
  /** column name */
  Code: 'code',
  /** column name */
  CouponFkey: 'coupon_fkey',
  /** column name */
  EmployerId: 'employer_id',
  /** column name */
  ExpiresAt: 'expires_at',
  /** column name */
  Id: 'id',
  /** column name */
  MaxRedemptions: 'max_redemptions',
  /** column name */
  MinimumOrder: 'minimum_order',
  /** column name */
  OnePerCustomer: 'one_per_customer'
} as const;

export type Stripe_Promotion_Update_Column = typeof Stripe_Promotion_Update_Column[keyof typeof Stripe_Promotion_Update_Column];
export interface Stripe_Promotion_Updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Promotion_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Promotion_Set_Input>;
  where: Stripe_Promotion_Bool_Exp;
}

/** aggregate var_pop on columns */
export interface Stripe_Promotion_Var_Pop_Fields {
  __typename?: 'stripe_promotion_var_pop_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate var_samp on columns */
export interface Stripe_Promotion_Var_Samp_Fields {
  __typename?: 'stripe_promotion_var_samp_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** aggregate variance on columns */
export interface Stripe_Promotion_Variance_Fields {
  __typename?: 'stripe_promotion_variance_fields';
  max_redemptions?: Maybe<Scalars['Float']['output']>;
  minimum_order?: Maybe<Scalars['Float']['output']>;
}

/** columns and relationships of "stripe.recovered_session" */
export interface Stripe_Recovered_Session {
  __typename?: 'stripe_recovered_session';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  job?: Maybe<Job>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  recovered_session_url?: Maybe<Scalars['String']['output']>;
  session_id: Scalars['String']['output'];
  session_status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  status?: Maybe<Stripe_Recovered_Session_Status>;
  /** An object relationship */
  submission?: Maybe<Internal_Raw_Job_Submission>;
  submission_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregated selection of "stripe.recovered_session" */
export interface Stripe_Recovered_Session_Aggregate {
  __typename?: 'stripe_recovered_session_aggregate';
  aggregate?: Maybe<Stripe_Recovered_Session_Aggregate_Fields>;
  nodes: Array<Stripe_Recovered_Session>;
}

/** aggregate fields of "stripe.recovered_session" */
export interface Stripe_Recovered_Session_Aggregate_Fields {
  __typename?: 'stripe_recovered_session_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Recovered_Session_Max_Fields>;
  min?: Maybe<Stripe_Recovered_Session_Min_Fields>;
}


/** aggregate fields of "stripe.recovered_session" */
export interface Stripe_Recovered_Session_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Stripe_Recovered_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "stripe.recovered_session". All fields are combined with a logical 'AND'. */
export interface Stripe_Recovered_Session_Bool_Exp {
  _and?: InputMaybe<Array<Stripe_Recovered_Session_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Recovered_Session_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  recovered_session_url?: InputMaybe<String_Comparison_Exp>;
  session_id?: InputMaybe<String_Comparison_Exp>;
  session_status?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
  submission?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
  submission_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
}

/** unique or primary key constraints on table "stripe.recovered_session" */
export const Stripe_Recovered_Session_Constraint = {
  /** unique or primary key constraint on columns "session_id" */
  RecoveredSessionPkey: 'recovered_session_pkey',
  /** unique or primary key constraint on columns "recovered_session_url" */
  RecoveredSessionRecoveredSessionIdKey: 'recovered_session_recovered_session_id_key',
  /** unique or primary key constraint on columns "session_id" */
  RecoveredSessionSessionIdKey: 'recovered_session_session_id_key'
} as const;

export type Stripe_Recovered_Session_Constraint = typeof Stripe_Recovered_Session_Constraint[keyof typeof Stripe_Recovered_Session_Constraint];
/** input type for inserting data into table "stripe.recovered_session" */
export interface Stripe_Recovered_Session_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  recovered_session_url?: InputMaybe<Scalars['String']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  session_status?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Stripe_Recovered_Session_Status_Obj_Rel_Insert_Input>;
  submission?: InputMaybe<Internal_Raw_Job_Submission_Obj_Rel_Insert_Input>;
  submission_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** aggregate max on columns */
export interface Stripe_Recovered_Session_Max_Fields {
  __typename?: 'stripe_recovered_session_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  recovered_session_url?: Maybe<Scalars['String']['output']>;
  session_id?: Maybe<Scalars['String']['output']>;
  session_status?: Maybe<Scalars['String']['output']>;
  submission_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface Stripe_Recovered_Session_Min_Fields {
  __typename?: 'stripe_recovered_session_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  recovered_session_url?: Maybe<Scalars['String']['output']>;
  session_id?: Maybe<Scalars['String']['output']>;
  session_status?: Maybe<Scalars['String']['output']>;
  submission_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "stripe.recovered_session" */
export interface Stripe_Recovered_Session_Mutation_Response {
  __typename?: 'stripe_recovered_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Recovered_Session>;
}

/** on_conflict condition type for table "stripe.recovered_session" */
export interface Stripe_Recovered_Session_On_Conflict {
  constraint: Stripe_Recovered_Session_Constraint;
  update_columns?: Array<Stripe_Recovered_Session_Update_Column>;
  where?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
}

/** Ordering options when selecting data from "stripe.recovered_session". */
export interface Stripe_Recovered_Session_Order_By {
  created_at?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  recovered_session_url?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  session_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Stripe_Recovered_Session_Status_Order_By>;
  submission?: InputMaybe<Internal_Raw_Job_Submission_Order_By>;
  submission_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
}

/** primary key columns input for table: stripe.recovered_session */
export interface Stripe_Recovered_Session_Pk_Columns_Input {
  session_id: Scalars['String']['input'];
}

/** select columns of table "stripe.recovered_session" */
export const Stripe_Recovered_Session_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  JobId: 'job_id',
  /** column name */
  RecoveredSessionUrl: 'recovered_session_url',
  /** column name */
  SessionId: 'session_id',
  /** column name */
  SessionStatus: 'session_status',
  /** column name */
  SubmissionId: 'submission_id',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Stripe_Recovered_Session_Select_Column = typeof Stripe_Recovered_Session_Select_Column[keyof typeof Stripe_Recovered_Session_Select_Column];
/** input type for updating data in table "stripe.recovered_session" */
export interface Stripe_Recovered_Session_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  recovered_session_url?: InputMaybe<Scalars['String']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  session_status?: InputMaybe<Scalars['String']['input']>;
  submission_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** columns and relationships of "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status {
  __typename?: 'stripe_recovered_session_status';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** aggregated selection of "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Aggregate {
  __typename?: 'stripe_recovered_session_status_aggregate';
  aggregate?: Maybe<Stripe_Recovered_Session_Status_Aggregate_Fields>;
  nodes: Array<Stripe_Recovered_Session_Status>;
}

/** aggregate fields of "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Aggregate_Fields {
  __typename?: 'stripe_recovered_session_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Recovered_Session_Status_Max_Fields>;
  min?: Maybe<Stripe_Recovered_Session_Status_Min_Fields>;
}


/** aggregate fields of "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Stripe_Recovered_Session_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Boolean expression to filter rows from the table "stripe.recovered_session_status". All fields are combined with a logical 'AND'. */
export interface Stripe_Recovered_Session_Status_Bool_Exp {
  _and?: InputMaybe<Array<Stripe_Recovered_Session_Status_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Recovered_Session_Status_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
}

/** unique or primary key constraints on table "stripe.recovered_session_status" */
export const Stripe_Recovered_Session_Status_Constraint = {
  /** unique or primary key constraint on columns "type" */
  RecoveredSessionStatusPkey: 'recovered_session_status_pkey',
  /** unique or primary key constraint on columns "value" */
  RecoveredSessionStatusValueKey: 'recovered_session_status_value_key'
} as const;

export type Stripe_Recovered_Session_Status_Constraint = typeof Stripe_Recovered_Session_Status_Constraint[keyof typeof Stripe_Recovered_Session_Status_Constraint];
/** input type for inserting data into table "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Insert_Input {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** aggregate max on columns */
export interface Stripe_Recovered_Session_Status_Max_Fields {
  __typename?: 'stripe_recovered_session_status_max_fields';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** aggregate min on columns */
export interface Stripe_Recovered_Session_Status_Min_Fields {
  __typename?: 'stripe_recovered_session_status_min_fields';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

/** response of any mutation on the table "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Mutation_Response {
  __typename?: 'stripe_recovered_session_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Recovered_Session_Status>;
}

/** input type for inserting object relation for remote table "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Obj_Rel_Insert_Input {
  data: Stripe_Recovered_Session_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Recovered_Session_Status_On_Conflict>;
}

/** on_conflict condition type for table "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_On_Conflict {
  constraint: Stripe_Recovered_Session_Status_Constraint;
  update_columns?: Array<Stripe_Recovered_Session_Status_Update_Column>;
  where?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
}

/** Ordering options when selecting data from "stripe.recovered_session_status". */
export interface Stripe_Recovered_Session_Status_Order_By {
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
}

/** primary key columns input for table: stripe.recovered_session_status */
export interface Stripe_Recovered_Session_Status_Pk_Columns_Input {
  type: Scalars['String']['input'];
}

/** select columns of table "stripe.recovered_session_status" */
export const Stripe_Recovered_Session_Status_Select_Column = {
  /** column name */
  Type: 'type',
  /** column name */
  Value: 'value'
} as const;

export type Stripe_Recovered_Session_Status_Select_Column = typeof Stripe_Recovered_Session_Status_Select_Column[keyof typeof Stripe_Recovered_Session_Status_Select_Column];
/** input type for updating data in table "stripe.recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Set_Input {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** Streaming cursor of the table "stripe_recovered_session_status" */
export interface Stripe_Recovered_Session_Status_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Stripe_Recovered_Session_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Stripe_Recovered_Session_Status_Stream_Cursor_Value_Input {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

/** update columns of table "stripe.recovered_session_status" */
export const Stripe_Recovered_Session_Status_Update_Column = {
  /** column name */
  Type: 'type',
  /** column name */
  Value: 'value'
} as const;

export type Stripe_Recovered_Session_Status_Update_Column = typeof Stripe_Recovered_Session_Status_Update_Column[keyof typeof Stripe_Recovered_Session_Status_Update_Column];
export interface Stripe_Recovered_Session_Status_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Recovered_Session_Status_Set_Input>;
  where: Stripe_Recovered_Session_Status_Bool_Exp;
}

/** Streaming cursor of the table "stripe_recovered_session" */
export interface Stripe_Recovered_Session_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Stripe_Recovered_Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Stripe_Recovered_Session_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  recovered_session_url?: InputMaybe<Scalars['String']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  session_status?: InputMaybe<Scalars['String']['input']>;
  submission_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "stripe.recovered_session" */
export const Stripe_Recovered_Session_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  JobId: 'job_id',
  /** column name */
  RecoveredSessionUrl: 'recovered_session_url',
  /** column name */
  SessionId: 'session_id',
  /** column name */
  SessionStatus: 'session_status',
  /** column name */
  SubmissionId: 'submission_id',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type Stripe_Recovered_Session_Update_Column = typeof Stripe_Recovered_Session_Update_Column[keyof typeof Stripe_Recovered_Session_Update_Column];
export interface Stripe_Recovered_Session_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Recovered_Session_Set_Input>;
  where: Stripe_Recovered_Session_Bool_Exp;
}

export interface Subscription_Root {
  __typename?: 'subscription_root';
  /** fetch data from the table: "application" */
  application: Array<Application>;
  /** fetch aggregated fields from the table: "application" */
  application_aggregate: Application_Aggregate;
  /** fetch data from the table: "application" using primary key columns */
  application_by_pk?: Maybe<Application>;
  /** fetch data from the table in a streaming manner: "application" */
  application_stream: Array<Application>;
  /** execute function "compute_category" which returns "enum.category" */
  compute_category?: Maybe<Enum_Category>;
  /** execute function "compute_category" and query aggregates on result of table type "enum.category" */
  compute_category_aggregate: Enum_Category_Aggregate;
  /** execute function "compute_job_employer" which returns "employer" */
  compute_job_employer: Array<Employer>;
  /** execute function "compute_job_employer" and query aggregates on result of table type "employer" */
  compute_job_employer_aggregate: Employer_Aggregate;
  /** execute function "compute_job_integration" which returns "integration_job" */
  compute_job_integration: Array<Integration_Job>;
  /** execute function "compute_job_integration" and query aggregates on result of table type "integration_job" */
  compute_job_integration_aggregate: Integration_Job_Aggregate;
  /** execute function "compute_listing_type" which returns "enum.listing" */
  compute_listing_type?: Maybe<Enum_Listing>;
  /** execute function "compute_listing_type" and query aggregates on result of table type "enum.listing" */
  compute_listing_type_aggregate: Enum_Listing_Aggregate;
  /** execute function "compute_location" which returns "location" */
  compute_location?: Maybe<Location>;
  /** execute function "compute_location" and query aggregates on result of table type "location" */
  compute_location_aggregate: Location_Aggregate;
  /** execute function "compute_questions" which returns "questions_and_answers" */
  compute_questions: Array<Questions_And_Answers>;
  /** execute function "compute_questions" and query aggregates on result of table type "questions_and_answers" */
  compute_questions_aggregate: Questions_And_Answers_Aggregate;
  /** execute function "compute_work_type" which returns "enum.work_type" */
  compute_work_type?: Maybe<Enum_Work_Type>;
  /** execute function "compute_work_type" and query aggregates on result of table type "enum.work_type" */
  compute_work_type_aggregate: Enum_Work_Type_Aggregate;
  /** fetch data from the table: "employer" */
  employer: Array<Employer>;
  /** fetch aggregated fields from the table: "employer" */
  employer_aggregate: Employer_Aggregate;
  /** fetch data from the table: "employer" using primary key columns */
  employer_by_pk?: Maybe<Employer>;
  /** fetch data from the table in a streaming manner: "employer" */
  employer_stream: Array<Employer>;
  /** fetch data from the table: "employer_user" */
  employer_user: Array<Employer_User>;
  /** fetch aggregated fields from the table: "employer_user" */
  employer_user_aggregate: Employer_User_Aggregate;
  /** execute function "employer_user_by_id" which returns "user" */
  employer_user_by_id?: Maybe<User>;
  /** execute function "employer_user_by_id" and query aggregates on result of table type "user" */
  employer_user_by_id_aggregate: User_Aggregate;
  /** fetch data from the table: "employer_user" using primary key columns */
  employer_user_by_pk?: Maybe<Employer_User>;
  /** fetch data from the table in a streaming manner: "employer_user" */
  employer_user_stream: Array<Employer_User>;
  /** fetch data from the table: "enum.access_level" */
  enum_access_level: Array<Enum_Access_Level>;
  /** fetch aggregated fields from the table: "enum.access_level" */
  enum_access_level_aggregate: Enum_Access_Level_Aggregate;
  /** fetch data from the table: "enum.access_level" using primary key columns */
  enum_access_level_by_pk?: Maybe<Enum_Access_Level>;
  /** fetch data from the table in a streaming manner: "enum.access_level" */
  enum_access_level_stream: Array<Enum_Access_Level>;
  /** fetch data from the table: "enum.application_status" */
  enum_application_status: Array<Enum_Application_Status>;
  /** fetch aggregated fields from the table: "enum.application_status" */
  enum_application_status_aggregate: Enum_Application_Status_Aggregate;
  /** fetch data from the table: "enum.application_status" using primary key columns */
  enum_application_status_by_pk?: Maybe<Enum_Application_Status>;
  /** fetch data from the table in a streaming manner: "enum.application_status" */
  enum_application_status_stream: Array<Enum_Application_Status>;
  /** fetch data from the table: "enum.billing" */
  enum_billing: Array<Enum_Billing>;
  /** fetch aggregated fields from the table: "enum.billing" */
  enum_billing_aggregate: Enum_Billing_Aggregate;
  /** fetch data from the table: "enum.billing" using primary key columns */
  enum_billing_by_pk?: Maybe<Enum_Billing>;
  /** fetch data from the table in a streaming manner: "enum.billing" */
  enum_billing_stream: Array<Enum_Billing>;
  /** fetch data from the table: "enum.category" */
  enum_category: Array<Enum_Category>;
  /** fetch aggregated fields from the table: "enum.category" */
  enum_category_aggregate: Enum_Category_Aggregate;
  /** fetch data from the table: "enum.category" using primary key columns */
  enum_category_by_pk?: Maybe<Enum_Category>;
  /** fetch data from the table in a streaming manner: "enum.category" */
  enum_category_stream: Array<Enum_Category>;
  /** fetch data from the table: "enum.dial_code" */
  enum_dial_code: Array<Enum_Dial_Code>;
  /** fetch aggregated fields from the table: "enum.dial_code" */
  enum_dial_code_aggregate: Enum_Dial_Code_Aggregate;
  /** fetch data from the table: "enum.dial_code" using primary key columns */
  enum_dial_code_by_pk?: Maybe<Enum_Dial_Code>;
  /** fetch data from the table in a streaming manner: "enum.dial_code" */
  enum_dial_code_stream: Array<Enum_Dial_Code>;
  /** fetch data from the table: "enum.integration_type" */
  enum_integration_type: Array<Enum_Integration_Type>;
  /** fetch aggregated fields from the table: "enum.integration_type" */
  enum_integration_type_aggregate: Enum_Integration_Type_Aggregate;
  /** fetch data from the table: "enum.integration_type" using primary key columns */
  enum_integration_type_by_pk?: Maybe<Enum_Integration_Type>;
  /** fetch data from the table in a streaming manner: "enum.integration_type" */
  enum_integration_type_stream: Array<Enum_Integration_Type>;
  /** fetch data from the table: "enum.job_status" */
  enum_job_status: Array<Enum_Job_Status>;
  /** fetch aggregated fields from the table: "enum.job_status" */
  enum_job_status_aggregate: Enum_Job_Status_Aggregate;
  /** fetch data from the table: "enum.job_status" using primary key columns */
  enum_job_status_by_pk?: Maybe<Enum_Job_Status>;
  /** fetch data from the table in a streaming manner: "enum.job_status" */
  enum_job_status_stream: Array<Enum_Job_Status>;
  /** fetch data from the table: "enum.listing" */
  enum_listing: Array<Enum_Listing>;
  /** fetch aggregated fields from the table: "enum.listing" */
  enum_listing_aggregate: Enum_Listing_Aggregate;
  /** fetch data from the table: "enum.listing" using primary key columns */
  enum_listing_by_pk?: Maybe<Enum_Listing>;
  /** fetch data from the table in a streaming manner: "enum.listing" */
  enum_listing_stream: Array<Enum_Listing>;
  /** fetch data from the table: "enum.organisation" */
  enum_organisation: Array<Enum_Organisation>;
  /** fetch aggregated fields from the table: "enum.organisation" */
  enum_organisation_aggregate: Enum_Organisation_Aggregate;
  /** fetch data from the table: "enum.organisation" using primary key columns */
  enum_organisation_by_pk?: Maybe<Enum_Organisation>;
  /** fetch data from the table in a streaming manner: "enum.organisation" */
  enum_organisation_stream: Array<Enum_Organisation>;
  /** fetch data from the table: "enum.pay_type" */
  enum_pay_type: Array<Enum_Pay_Type>;
  /** fetch aggregated fields from the table: "enum.pay_type" */
  enum_pay_type_aggregate: Enum_Pay_Type_Aggregate;
  /** fetch data from the table: "enum.pay_type" using primary key columns */
  enum_pay_type_by_pk?: Maybe<Enum_Pay_Type>;
  /** fetch data from the table in a streaming manner: "enum.pay_type" */
  enum_pay_type_stream: Array<Enum_Pay_Type>;
  /** fetch data from the table: "enum.question_type" */
  enum_question_type: Array<Enum_Question_Type>;
  /** fetch aggregated fields from the table: "enum.question_type" */
  enum_question_type_aggregate: Enum_Question_Type_Aggregate;
  /** fetch data from the table: "enum.question_type" using primary key columns */
  enum_question_type_by_pk?: Maybe<Enum_Question_Type>;
  /** fetch data from the table in a streaming manner: "enum.question_type" */
  enum_question_type_stream: Array<Enum_Question_Type>;
  /** fetch data from the table: "enum.role" */
  enum_role: Array<Enum_Role>;
  /** fetch aggregated fields from the table: "enum.role" */
  enum_role_aggregate: Enum_Role_Aggregate;
  /** fetch data from the table: "enum.role" using primary key columns */
  enum_role_by_pk?: Maybe<Enum_Role>;
  /** fetch data from the table in a streaming manner: "enum.role" */
  enum_role_stream: Array<Enum_Role>;
  /** fetch data from the table: "enum.work_type" */
  enum_work_type: Array<Enum_Work_Type>;
  /** fetch aggregated fields from the table: "enum.work_type" */
  enum_work_type_aggregate: Enum_Work_Type_Aggregate;
  /** fetch data from the table: "enum.work_type" using primary key columns */
  enum_work_type_by_pk?: Maybe<Enum_Work_Type>;
  /** fetch data from the table in a streaming manner: "enum.work_type" */
  enum_work_type_stream: Array<Enum_Work_Type>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner: "events" */
  events_stream: Array<Events>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "file" */
  file_stream: Array<File>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<Geography_Columns>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<Geometry_Columns>;
  /** fetch data from the table: "integration_employer" */
  integration_employer: Array<Integration_Employer>;
  /** fetch aggregated fields from the table: "integration_employer" */
  integration_employer_aggregate: Integration_Employer_Aggregate;
  /** fetch data from the table: "integration_employer" using primary key columns */
  integration_employer_by_pk?: Maybe<Integration_Employer>;
  /** fetch data from the table in a streaming manner: "integration_employer" */
  integration_employer_stream: Array<Integration_Employer>;
  /** fetch data from the table: "integration_job" */
  integration_job: Array<Integration_Job>;
  /** fetch aggregated fields from the table: "integration_job" */
  integration_job_aggregate: Integration_Job_Aggregate;
  /** fetch data from the table: "integration_job" using primary key columns */
  integration_job_by_pk?: Maybe<Integration_Job>;
  /** fetch data from the table in a streaming manner: "integration_job" */
  integration_job_stream: Array<Integration_Job>;
  /** fetch data from the table: "internal.raw_job_submission" */
  internal_raw_job_submission: Array<Internal_Raw_Job_Submission>;
  /** fetch aggregated fields from the table: "internal.raw_job_submission" */
  internal_raw_job_submission_aggregate: Internal_Raw_Job_Submission_Aggregate;
  /** fetch data from the table: "internal.raw_job_submission" using primary key columns */
  internal_raw_job_submission_by_pk?: Maybe<Internal_Raw_Job_Submission>;
  /** fetch data from the table in a streaming manner: "internal.raw_job_submission" */
  internal_raw_job_submission_stream: Array<Internal_Raw_Job_Submission>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch data from the table: "job_additional_category" */
  job_additional_category: Array<Job_Additional_Category>;
  /** fetch aggregated fields from the table: "job_additional_category" */
  job_additional_category_aggregate: Job_Additional_Category_Aggregate;
  /** fetch data from the table: "job_additional_category" using primary key columns */
  job_additional_category_by_pk?: Maybe<Job_Additional_Category>;
  /** fetch data from the table in a streaming manner: "job_additional_category" */
  job_additional_category_stream: Array<Job_Additional_Category>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "job_question" */
  job_question: Array<Job_Question>;
  /** fetch aggregated fields from the table: "job_question" */
  job_question_aggregate: Job_Question_Aggregate;
  /** fetch data from the table: "job_question" using primary key columns */
  job_question_by_pk?: Maybe<Job_Question>;
  /** fetch data from the table in a streaming manner: "job_question" */
  job_question_stream: Array<Job_Question>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** fetch data from the table: "job_views" */
  job_views: Array<Job_Views>;
  /** fetch aggregated fields from the table: "job_views" */
  job_views_aggregate: Job_Views_Aggregate;
  /** fetch data from the table: "job_views" using primary key columns */
  job_views_by_pk?: Maybe<Job_Views>;
  /** fetch data from the table in a streaming manner: "job_views" */
  job_views_stream: Array<Job_Views>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: Question_Aggregate;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table in a streaming manner: "question" */
  question_stream: Array<Question>;
  /** fetch data from the table: "questions_and_answers" */
  questions_and_answers: Array<Questions_And_Answers>;
  /** fetch aggregated fields from the table: "questions_and_answers" */
  questions_and_answers_aggregate: Questions_And_Answers_Aggregate;
  /** fetch data from the table in a streaming manner: "questions_and_answers" */
  questions_and_answers_stream: Array<Questions_And_Answers>;
  /** execute function "random_eoi" which returns "job" */
  random_eoi: Array<Job>;
  /** execute function "random_eoi" and query aggregates on result of table type "job" */
  random_eoi_aggregate: Job_Aggregate;
  /** execute function "search" which returns "job" */
  search: Array<Job>;
  /** execute function "search" and query aggregates on result of table type "job" */
  search_aggregate: Job_Aggregate;
  /** execute function "search_local_id" which returns "job" */
  search_local_id: Array<Job>;
  /** execute function "search_local_id" and query aggregates on result of table type "job" */
  search_local_id_aggregate: Job_Aggregate;
  /** execute function "search_local_params" which returns "job" */
  search_local_params: Array<Job>;
  /** execute function "search_local_params" and query aggregates on result of table type "job" */
  search_local_params_aggregate: Job_Aggregate;
  /** execute function "search_location" which returns "location" */
  search_location: Array<Location>;
  /** execute function "search_location" and query aggregates on result of table type "location" */
  search_location_aggregate: Location_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<Spatial_Ref_Sys>;
  /** fetch data from the table: "stripe.bundle" */
  stripe_bundle: Array<Stripe_Bundle>;
  /** fetch aggregated fields from the table: "stripe.bundle" */
  stripe_bundle_aggregate: Stripe_Bundle_Aggregate;
  /** fetch data from the table: "stripe.bundle" using primary key columns */
  stripe_bundle_by_pk?: Maybe<Stripe_Bundle>;
  /** fetch data from the table in a streaming manner: "stripe.bundle" */
  stripe_bundle_stream: Array<Stripe_Bundle>;
  /** fetch data from the table: "stripe.promotion" */
  stripe_promotion: Array<Stripe_Promotion>;
  /** fetch aggregated fields from the table: "stripe.promotion" */
  stripe_promotion_aggregate: Stripe_Promotion_Aggregate;
  /** fetch data from the table: "stripe.promotion" using primary key columns */
  stripe_promotion_by_pk?: Maybe<Stripe_Promotion>;
  /** fetch data from the table: "stripe.promotion_redemption" */
  stripe_promotion_redemption: Array<Stripe_Promotion_Redemption>;
  /** fetch aggregated fields from the table: "stripe.promotion_redemption" */
  stripe_promotion_redemption_aggregate: Stripe_Promotion_Redemption_Aggregate;
  /** fetch data from the table: "stripe.promotion_redemption" using primary key columns */
  stripe_promotion_redemption_by_pk?: Maybe<Stripe_Promotion_Redemption>;
  /** fetch data from the table in a streaming manner: "stripe.promotion_redemption" */
  stripe_promotion_redemption_stream: Array<Stripe_Promotion_Redemption>;
  /** fetch data from the table in a streaming manner: "stripe.promotion" */
  stripe_promotion_stream: Array<Stripe_Promotion>;
  /** fetch data from the table: "stripe.recovered_session" */
  stripe_recovered_session: Array<Stripe_Recovered_Session>;
  /** fetch aggregated fields from the table: "stripe.recovered_session" */
  stripe_recovered_session_aggregate: Stripe_Recovered_Session_Aggregate;
  /** fetch data from the table: "stripe.recovered_session" using primary key columns */
  stripe_recovered_session_by_pk?: Maybe<Stripe_Recovered_Session>;
  /** fetch data from the table: "stripe.recovered_session_status" */
  stripe_recovered_session_status: Array<Stripe_Recovered_Session_Status>;
  /** fetch aggregated fields from the table: "stripe.recovered_session_status" */
  stripe_recovered_session_status_aggregate: Stripe_Recovered_Session_Status_Aggregate;
  /** fetch data from the table: "stripe.recovered_session_status" using primary key columns */
  stripe_recovered_session_status_by_pk?: Maybe<Stripe_Recovered_Session_Status>;
  /** fetch data from the table in a streaming manner: "stripe.recovered_session_status" */
  stripe_recovered_session_status_stream: Array<Stripe_Recovered_Session_Status>;
  /** fetch data from the table in a streaming manner: "stripe.recovered_session" */
  stripe_recovered_session_stream: Array<Stripe_Recovered_Session>;
  /** execute function "unauth_user_search" which returns "user_search" */
  unauth_user_search: Array<User_Search>;
  /** execute function "unauth_user_search" and query aggregates on result of table type "user_search" */
  unauth_user_search_aggregate: User_Search_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user_answer" */
  user_answer: Array<User_Answer>;
  /** fetch aggregated fields from the table: "user_answer" */
  user_answer_aggregate: User_Answer_Aggregate;
  /** fetch data from the table: "user_answer" using primary key columns */
  user_answer_by_pk?: Maybe<User_Answer>;
  /** fetch data from the table in a streaming manner: "user_answer" */
  user_answer_stream: Array<User_Answer>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_education" */
  user_education: Array<User_Education>;
  /** fetch aggregated fields from the table: "user_education" */
  user_education_aggregate: User_Education_Aggregate;
  /** fetch data from the table: "user_education" using primary key columns */
  user_education_by_pk?: Maybe<User_Education>;
  /** fetch data from the table in a streaming manner: "user_education" */
  user_education_stream: Array<User_Education>;
  /** fetch data from the table: "user_experience" */
  user_experience: Array<User_Experience>;
  /** fetch aggregated fields from the table: "user_experience" */
  user_experience_aggregate: User_Experience_Aggregate;
  /** fetch data from the table: "user_experience" using primary key columns */
  user_experience_by_pk?: Maybe<User_Experience>;
  /** fetch data from the table in a streaming manner: "user_experience" */
  user_experience_stream: Array<User_Experience>;
  /** fetch data from the table: "user_licence" */
  user_licence: Array<User_Licence>;
  /** fetch aggregated fields from the table: "user_licence" */
  user_licence_aggregate: User_Licence_Aggregate;
  /** fetch data from the table: "user_licence" using primary key columns */
  user_licence_by_pk?: Maybe<User_Licence>;
  /** fetch data from the table in a streaming manner: "user_licence" */
  user_licence_stream: Array<User_Licence>;
  /** fetch data from the table: "user_search" */
  user_search: Array<User_Search>;
  /** fetch aggregated fields from the table: "user_search" */
  user_search_aggregate: User_Search_Aggregate;
  /** fetch data from the table: "user_search" using primary key columns */
  user_search_by_pk?: Maybe<User_Search>;
  /** fetch data from the table in a streaming manner: "user_search" */
  user_search_stream: Array<User_Search>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** An array relationship */
  watchlist: Array<Watchlist>;
  /** An aggregate relationship */
  watchlist_aggregate: Watchlist_Aggregate;
  /** fetch data from the table: "watchlist" using primary key columns */
  watchlist_by_pk?: Maybe<Watchlist>;
  /** fetch data from the table in a streaming manner: "watchlist" */
  watchlist_stream: Array<Watchlist>;
}


export interface Subscription_RootApplicationArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


export interface Subscription_RootApplication_AggregateArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


export interface Subscription_RootApplication_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootApplication_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Application_Stream_Cursor_Input>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


export interface Subscription_RootCompute_CategoryArgs {
  args: Compute_Category_Args;
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Subscription_RootCompute_Category_AggregateArgs {
  args: Compute_Category_Args;
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Subscription_RootCompute_Job_EmployerArgs {
  args: Compute_Job_Employer_Args;
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Subscription_RootCompute_Job_Employer_AggregateArgs {
  args: Compute_Job_Employer_Args;
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Subscription_RootCompute_Job_IntegrationArgs {
  args: Compute_Job_Integration_Args;
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Subscription_RootCompute_Job_Integration_AggregateArgs {
  args: Compute_Job_Integration_Args;
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Subscription_RootCompute_Listing_TypeArgs {
  args: Compute_Listing_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Subscription_RootCompute_Listing_Type_AggregateArgs {
  args: Compute_Listing_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Subscription_RootCompute_LocationArgs {
  args: Compute_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootCompute_Location_AggregateArgs {
  args: Compute_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootCompute_QuestionsArgs {
  args: Compute_Questions_Args;
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Subscription_RootCompute_Questions_AggregateArgs {
  args: Compute_Questions_Args;
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Subscription_RootCompute_Work_TypeArgs {
  args: Compute_Work_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Subscription_RootCompute_Work_Type_AggregateArgs {
  args: Compute_Work_Type_Args;
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Subscription_RootEmployerArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Subscription_RootEmployer_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Subscription_RootEmployer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootEmployer_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Employer_Stream_Cursor_Input>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


export interface Subscription_RootEmployer_UserArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


export interface Subscription_RootEmployer_User_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


export interface Subscription_RootEmployer_User_By_IdArgs {
  args: Employer_User_By_Id_Args;
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Subscription_RootEmployer_User_By_Id_AggregateArgs {
  args: Employer_User_By_Id_Args;
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Subscription_RootEmployer_User_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootEmployer_User_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Employer_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


export interface Subscription_RootEnum_Access_LevelArgs {
  distinct_on?: InputMaybe<Array<Enum_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Access_Level_Order_By>>;
  where?: InputMaybe<Enum_Access_Level_Bool_Exp>;
}


export interface Subscription_RootEnum_Access_Level_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Access_Level_Order_By>>;
  where?: InputMaybe<Enum_Access_Level_Bool_Exp>;
}


export interface Subscription_RootEnum_Access_Level_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Access_Level_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Access_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Access_Level_Bool_Exp>;
}


export interface Subscription_RootEnum_Application_StatusArgs {
  distinct_on?: InputMaybe<Array<Enum_Application_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Application_Status_Order_By>>;
  where?: InputMaybe<Enum_Application_Status_Bool_Exp>;
}


export interface Subscription_RootEnum_Application_Status_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Application_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Application_Status_Order_By>>;
  where?: InputMaybe<Enum_Application_Status_Bool_Exp>;
}


export interface Subscription_RootEnum_Application_Status_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Application_Status_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Application_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Application_Status_Bool_Exp>;
}


export interface Subscription_RootEnum_BillingArgs {
  distinct_on?: InputMaybe<Array<Enum_Billing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Billing_Order_By>>;
  where?: InputMaybe<Enum_Billing_Bool_Exp>;
}


export interface Subscription_RootEnum_Billing_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Billing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Billing_Order_By>>;
  where?: InputMaybe<Enum_Billing_Bool_Exp>;
}


export interface Subscription_RootEnum_Billing_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Billing_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Billing_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Billing_Bool_Exp>;
}


export interface Subscription_RootEnum_CategoryArgs {
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Subscription_RootEnum_Category_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Category_Order_By>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Subscription_RootEnum_Category_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Category_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Category_Bool_Exp>;
}


export interface Subscription_RootEnum_Dial_CodeArgs {
  distinct_on?: InputMaybe<Array<Enum_Dial_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Dial_Code_Order_By>>;
  where?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
}


export interface Subscription_RootEnum_Dial_Code_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Dial_Code_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Dial_Code_Order_By>>;
  where?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
}


export interface Subscription_RootEnum_Dial_Code_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Dial_Code_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Dial_Code_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Dial_Code_Bool_Exp>;
}


export interface Subscription_RootEnum_Integration_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Integration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Integration_Type_Order_By>>;
  where?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Integration_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Integration_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Integration_Type_Order_By>>;
  where?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Integration_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Integration_Type_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Integration_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Integration_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Job_StatusArgs {
  distinct_on?: InputMaybe<Array<Enum_Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Job_Status_Order_By>>;
  where?: InputMaybe<Enum_Job_Status_Bool_Exp>;
}


export interface Subscription_RootEnum_Job_Status_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Job_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Job_Status_Order_By>>;
  where?: InputMaybe<Enum_Job_Status_Bool_Exp>;
}


export interface Subscription_RootEnum_Job_Status_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Job_Status_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Job_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Job_Status_Bool_Exp>;
}


export interface Subscription_RootEnum_ListingArgs {
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Subscription_RootEnum_Listing_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Listing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Listing_Order_By>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Subscription_RootEnum_Listing_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Listing_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Listing_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Listing_Bool_Exp>;
}


export interface Subscription_RootEnum_OrganisationArgs {
  distinct_on?: InputMaybe<Array<Enum_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Organisation_Order_By>>;
  where?: InputMaybe<Enum_Organisation_Bool_Exp>;
}


export interface Subscription_RootEnum_Organisation_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Organisation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Organisation_Order_By>>;
  where?: InputMaybe<Enum_Organisation_Bool_Exp>;
}


export interface Subscription_RootEnum_Organisation_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Organisation_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Organisation_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Organisation_Bool_Exp>;
}


export interface Subscription_RootEnum_Pay_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Pay_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Pay_Type_Order_By>>;
  where?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Pay_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Pay_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Pay_Type_Order_By>>;
  where?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Pay_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Pay_Type_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Pay_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Pay_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Question_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Question_Type_Order_By>>;
  where?: InputMaybe<Enum_Question_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Question_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Question_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Question_Type_Order_By>>;
  where?: InputMaybe<Enum_Question_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Question_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Question_Type_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Question_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Question_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_RoleArgs {
  distinct_on?: InputMaybe<Array<Enum_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Role_Order_By>>;
  where?: InputMaybe<Enum_Role_Bool_Exp>;
}


export interface Subscription_RootEnum_Role_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Role_Order_By>>;
  where?: InputMaybe<Enum_Role_Bool_Exp>;
}


export interface Subscription_RootEnum_Role_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Role_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Role_Bool_Exp>;
}


export interface Subscription_RootEnum_Work_TypeArgs {
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Work_Type_AggregateArgs {
  distinct_on?: InputMaybe<Array<Enum_Work_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Work_Type_Order_By>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Subscription_RootEnum_Work_Type_By_PkArgs {
  value: Scalars['String']['input'];
}


export interface Subscription_RootEnum_Work_Type_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Enum_Work_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Work_Type_Bool_Exp>;
}


export interface Subscription_RootEventsArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


export interface Subscription_RootEvents_AggregateArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


export interface Subscription_RootEvents_By_PkArgs {
  message_id: Scalars['String']['input'];
}


export interface Subscription_RootEvents_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


export interface Subscription_RootFileArgs {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
}


export interface Subscription_RootFile_AggregateArgs {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
}


export interface Subscription_RootFile_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootFile_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<File_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Bool_Exp>;
}


export interface Subscription_RootGeography_ColumnsArgs {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
}


export interface Subscription_RootGeography_Columns_AggregateArgs {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
}


export interface Subscription_RootGeography_Columns_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Geography_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
}


export interface Subscription_RootGeometry_ColumnsArgs {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
}


export interface Subscription_RootGeometry_Columns_AggregateArgs {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
}


export interface Subscription_RootGeometry_Columns_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Geometry_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
}


export interface Subscription_RootIntegration_EmployerArgs {
  distinct_on?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Employer_Order_By>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


export interface Subscription_RootIntegration_Employer_AggregateArgs {
  distinct_on?: InputMaybe<Array<Integration_Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Employer_Order_By>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


export interface Subscription_RootIntegration_Employer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootIntegration_Employer_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integration_Employer_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Employer_Bool_Exp>;
}


export interface Subscription_RootIntegration_JobArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Subscription_RootIntegration_Job_AggregateArgs {
  distinct_on?: InputMaybe<Array<Integration_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Job_Order_By>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Subscription_RootIntegration_Job_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootIntegration_Job_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integration_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Job_Bool_Exp>;
}


export interface Subscription_RootInternal_Raw_Job_SubmissionArgs {
  distinct_on?: InputMaybe<Array<Internal_Raw_Job_Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Raw_Job_Submission_Order_By>>;
  where?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
}


export interface Subscription_RootInternal_Raw_Job_Submission_AggregateArgs {
  distinct_on?: InputMaybe<Array<Internal_Raw_Job_Submission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Raw_Job_Submission_Order_By>>;
  where?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
}


export interface Subscription_RootInternal_Raw_Job_Submission_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootInternal_Raw_Job_Submission_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Internal_Raw_Job_Submission_Stream_Cursor_Input>>;
  where?: InputMaybe<Internal_Raw_Job_Submission_Bool_Exp>;
}


export interface Subscription_RootJobArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootJob_Additional_CategoryArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


export interface Subscription_RootJob_Additional_Category_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Additional_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Additional_Category_Order_By>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


export interface Subscription_RootJob_Additional_Category_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootJob_Additional_Category_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Additional_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Additional_Category_Bool_Exp>;
}


export interface Subscription_RootJob_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootJob_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootJob_QuestionArgs {
  distinct_on?: InputMaybe<Array<Job_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Question_Order_By>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


export interface Subscription_RootJob_Question_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Question_Order_By>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


export interface Subscription_RootJob_Question_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootJob_Question_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Question_Bool_Exp>;
}


export interface Subscription_RootJob_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootJob_ViewsArgs {
  distinct_on?: InputMaybe<Array<Job_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Views_Order_By>>;
  where?: InputMaybe<Job_Views_Bool_Exp>;
}


export interface Subscription_RootJob_Views_AggregateArgs {
  distinct_on?: InputMaybe<Array<Job_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Views_Order_By>>;
  where?: InputMaybe<Job_Views_Bool_Exp>;
}


export interface Subscription_RootJob_Views_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootJob_Views_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Job_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Views_Bool_Exp>;
}


export interface Subscription_RootLocationArgs {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootLocation_AggregateArgs {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootLocation_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootLocation_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootQuestionArgs {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
}


export interface Subscription_RootQuestion_AggregateArgs {
  distinct_on?: InputMaybe<Array<Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Question_Order_By>>;
  where?: InputMaybe<Question_Bool_Exp>;
}


export interface Subscription_RootQuestion_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootQuestion_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Question_Bool_Exp>;
}


export interface Subscription_RootQuestions_And_AnswersArgs {
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Subscription_RootQuestions_And_Answers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Questions_And_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questions_And_Answers_Order_By>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Subscription_RootQuestions_And_Answers_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questions_And_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_And_Answers_Bool_Exp>;
}


export interface Subscription_RootRandom_EoiArgs {
  args: Random_Eoi_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootRandom_Eoi_AggregateArgs {
  args: Random_Eoi_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearchArgs {
  args: Search_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearch_AggregateArgs {
  args: Search_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearch_Local_IdArgs {
  args: Search_Local_Id_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearch_Local_Id_AggregateArgs {
  args: Search_Local_Id_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearch_Local_ParamsArgs {
  args: Search_Local_Params_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearch_Local_Params_AggregateArgs {
  args: Search_Local_Params_Args;
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
}


export interface Subscription_RootSearch_LocationArgs {
  args: Search_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootSearch_Location_AggregateArgs {
  args: Search_Location_Args;
  distinct_on?: InputMaybe<Array<Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Location_Order_By>>;
  where?: InputMaybe<Location_Bool_Exp>;
}


export interface Subscription_RootSpatial_Ref_SysArgs {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
}


export interface Subscription_RootSpatial_Ref_Sys_AggregateArgs {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
}


export interface Subscription_RootSpatial_Ref_Sys_By_PkArgs {
  srid: Scalars['Int']['input'];
}


export interface Subscription_RootSpatial_Ref_Sys_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Spatial_Ref_Sys_Stream_Cursor_Input>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
}


export interface Subscription_RootStripe_BundleArgs {
  distinct_on?: InputMaybe<Array<Stripe_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Bundle_Order_By>>;
  where?: InputMaybe<Stripe_Bundle_Bool_Exp>;
}


export interface Subscription_RootStripe_Bundle_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Bundle_Order_By>>;
  where?: InputMaybe<Stripe_Bundle_Bool_Exp>;
}


export interface Subscription_RootStripe_Bundle_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootStripe_Bundle_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Bundle_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Bundle_Bool_Exp>;
}


export interface Subscription_RootStripe_PromotionArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Bool_Exp>;
}


export interface Subscription_RootStripe_Promotion_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Bool_Exp>;
}


export interface Subscription_RootStripe_Promotion_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootStripe_Promotion_RedemptionArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


export interface Subscription_RootStripe_Promotion_Redemption_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Promotion_Redemption_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Promotion_Redemption_Order_By>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


export interface Subscription_RootStripe_Promotion_Redemption_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootStripe_Promotion_Redemption_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Promotion_Redemption_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Promotion_Redemption_Bool_Exp>;
}


export interface Subscription_RootStripe_Promotion_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Promotion_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Promotion_Bool_Exp>;
}


export interface Subscription_RootStripe_Recovered_SessionArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
}


export interface Subscription_RootStripe_Recovered_Session_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
}


export interface Subscription_RootStripe_Recovered_Session_By_PkArgs {
  session_id: Scalars['String']['input'];
}


export interface Subscription_RootStripe_Recovered_Session_StatusArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Status_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
}


export interface Subscription_RootStripe_Recovered_Session_Status_AggregateArgs {
  distinct_on?: InputMaybe<Array<Stripe_Recovered_Session_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Recovered_Session_Status_Order_By>>;
  where?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
}


export interface Subscription_RootStripe_Recovered_Session_Status_By_PkArgs {
  type: Scalars['String']['input'];
}


export interface Subscription_RootStripe_Recovered_Session_Status_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Recovered_Session_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Recovered_Session_Status_Bool_Exp>;
}


export interface Subscription_RootStripe_Recovered_Session_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Recovered_Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Recovered_Session_Bool_Exp>;
}


export interface Subscription_RootUnauth_User_SearchArgs {
  args: Unauth_User_Search_Args;
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Subscription_RootUnauth_User_Search_AggregateArgs {
  args: Unauth_User_Search_Args;
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Subscription_RootUserArgs {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Subscription_RootUser_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Subscription_RootUser_AnswerArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


export interface Subscription_RootUser_Answer_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


export interface Subscription_RootUser_Answer_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootUser_Answer_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Answer_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


export interface Subscription_RootUser_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootUser_EducationArgs {
  distinct_on?: InputMaybe<Array<User_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Education_Order_By>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


export interface Subscription_RootUser_Education_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Education_Order_By>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


export interface Subscription_RootUser_Education_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootUser_Education_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Education_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


export interface Subscription_RootUser_ExperienceArgs {
  distinct_on?: InputMaybe<Array<User_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Experience_Order_By>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


export interface Subscription_RootUser_Experience_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Experience_Order_By>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


export interface Subscription_RootUser_Experience_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootUser_Experience_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Experience_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


export interface Subscription_RootUser_LicenceArgs {
  distinct_on?: InputMaybe<Array<User_Licence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Licence_Order_By>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


export interface Subscription_RootUser_Licence_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Licence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Licence_Order_By>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


export interface Subscription_RootUser_Licence_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootUser_Licence_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Licence_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


export interface Subscription_RootUser_SearchArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Subscription_RootUser_Search_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Subscription_RootUser_Search_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootUser_Search_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Search_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


export interface Subscription_RootUser_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
}


export interface Subscription_RootWatchlistArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


export interface Subscription_RootWatchlist_AggregateArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


export interface Subscription_RootWatchlist_By_PkArgs {
  id: Scalars['uuid']['input'];
}


export interface Subscription_RootWatchlist_StreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Watchlist_Stream_Cursor_Input>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface Timestamptz_Comparison_Exp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

export interface Unauth_User_Search_Args {
  local_id?: InputMaybe<Scalars['uuid']['input']>;
}

export interface Update_User_Search_Created_By_Args {
  local_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** columns and relationships of "user" */
export interface User {
  __typename?: 'user';
  about?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  applications: Array<Application>;
  /** An array relationship */
  applicationsByUpdatedByUserId: Array<Application>;
  /** An aggregate relationship */
  applicationsByUpdatedByUserId_aggregate: Application_Aggregate;
  /** An aggregate relationship */
  applications_aggregate: Application_Aggregate;
  category?: Maybe<Scalars['jsonb']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  /** An array relationship */
  employer_users: Array<Employer_User>;
  /** An aggregate relationship */
  employer_users_aggregate: Employer_User_Aggregate;
  /** An array relationship */
  employers: Array<Employer>;
  /** An array relationship */
  employersByUpdatedByUserId: Array<Employer>;
  /** An aggregate relationship */
  employersByUpdatedByUserId_aggregate: Employer_Aggregate;
  /** An aggregate relationship */
  employers_aggregate: Employer_Aggregate;
  event: Array<Events>;
  event_aggregate: Events_Aggregate;
  /** An array relationship */
  files: Array<File>;
  /** An aggregate relationship */
  files_aggregate: File_Aggregate;
  first_name: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  last_name: Scalars['String']['output'];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  onboard_at?: Maybe<Scalars['timestamptz']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profile_photo?: Maybe<Scalars['String']['output']>;
  public_profile: Scalars['Boolean']['output'];
  role: Enum_Role_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_answers: Array<User_Answer>;
  /** An aggregate relationship */
  user_answers_aggregate: User_Answer_Aggregate;
  /** An array relationship */
  user_educations: Array<User_Education>;
  /** An aggregate relationship */
  user_educations_aggregate: User_Education_Aggregate;
  /** An array relationship */
  user_experiences: Array<User_Experience>;
  /** An aggregate relationship */
  user_experiences_aggregate: User_Experience_Aggregate;
  /** An array relationship */
  user_licences: Array<User_Licence>;
  /** An aggregate relationship */
  user_licences_aggregate: User_Licence_Aggregate;
  /** An object relationship */
  user_role: Enum_Role;
  /** An array relationship */
  user_searches: Array<User_Search>;
  /** An aggregate relationship */
  user_searches_aggregate: User_Search_Aggregate;
  /** An array relationship */
  watchlist: Array<Watchlist>;
  /** An aggregate relationship */
  watchlist_aggregate: Watchlist_Aggregate;
}


/** columns and relationships of "user" */
export interface UserApplicationsArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserApplicationsByUpdatedByUserIdArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserApplicationsByUpdatedByUserId_AggregateArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserApplications_AggregateArgs {
  distinct_on?: InputMaybe<Array<Application_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Application_Order_By>>;
  where?: InputMaybe<Application_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserCategoryArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}


/** columns and relationships of "user" */
export interface UserEmployer_UsersArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEmployer_Users_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_User_Order_By>>;
  where?: InputMaybe<Employer_User_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEmployersArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEmployersByUpdatedByUserIdArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEmployersByUpdatedByUserId_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEmployers_AggregateArgs {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEventArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserEvent_AggregateArgs {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserFilesArgs {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserFiles_AggregateArgs {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_AnswersArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_Answers_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Answer_Order_By>>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_EducationsArgs {
  distinct_on?: InputMaybe<Array<User_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Education_Order_By>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_Educations_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Education_Order_By>>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_ExperiencesArgs {
  distinct_on?: InputMaybe<Array<User_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Experience_Order_By>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_Experiences_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Experience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Experience_Order_By>>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_LicencesArgs {
  distinct_on?: InputMaybe<Array<User_Licence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Licence_Order_By>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_Licences_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Licence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Licence_Order_By>>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_SearchesArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserUser_Searches_AggregateArgs {
  distinct_on?: InputMaybe<Array<User_Search_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Search_Order_By>>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserWatchlistArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}


/** columns and relationships of "user" */
export interface UserWatchlist_AggregateArgs {
  distinct_on?: InputMaybe<Array<Watchlist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Watchlist_Order_By>>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}

export interface User_Aggregate {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
}

/** aggregate fields of "user" */
export interface User_Aggregate_Fields {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
}


/** aggregate fields of "user" */
export interface User_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** Responses from question */
export interface User_Answer {
  __typename?: 'user_answer';
  answer: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  question: Question;
  question_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
}


/** Responses from question */
export interface User_AnswerAnswerArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** aggregated selection of "user_answer" */
export interface User_Answer_Aggregate {
  __typename?: 'user_answer_aggregate';
  aggregate?: Maybe<User_Answer_Aggregate_Fields>;
  nodes: Array<User_Answer>;
}

export interface User_Answer_Aggregate_Bool_Exp {
  count?: InputMaybe<User_Answer_Aggregate_Bool_Exp_Count>;
}

export interface User_Answer_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<User_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Answer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "user_answer" */
export interface User_Answer_Aggregate_Fields {
  __typename?: 'user_answer_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Answer_Max_Fields>;
  min?: Maybe<User_Answer_Min_Fields>;
}


/** aggregate fields of "user_answer" */
export interface User_Answer_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<User_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "user_answer" */
export interface User_Answer_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Answer_Max_Order_By>;
  min?: InputMaybe<User_Answer_Min_Order_By>;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface User_Answer_Append_Input {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
}

/** input type for inserting array relation for remote table "user_answer" */
export interface User_Answer_Arr_Rel_Insert_Input {
  data: Array<User_Answer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Answer_On_Conflict>;
}

/** Boolean expression to filter rows from the table "user_answer". All fields are combined with a logical 'AND'. */
export interface User_Answer_Bool_Exp {
  _and?: InputMaybe<Array<User_Answer_Bool_Exp>>;
  _not?: InputMaybe<User_Answer_Bool_Exp>;
  _or?: InputMaybe<Array<User_Answer_Bool_Exp>>;
  answer?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Question_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "user_answer" */
export const User_Answer_Constraint = {
  /** unique or primary key constraint on columns "id" */
  UserAnswerPkey: 'user_answer_pkey',
  /** unique or primary key constraint on columns "question_id", "user_id" */
  UserAnswerUserIdQuestionIdKey: 'user_answer_user_id_question_id_key'
} as const;

export type User_Answer_Constraint = typeof User_Answer_Constraint[keyof typeof User_Answer_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface User_Answer_Delete_At_Path_Input {
  answer?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface User_Answer_Delete_Elem_Input {
  answer?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface User_Answer_Delete_Key_Input {
  answer?: InputMaybe<Scalars['String']['input']>;
}

/** input type for inserting data into table "user_answer" */
export interface User_Answer_Insert_Input {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question?: InputMaybe<Question_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface User_Answer_Max_Fields {
  __typename?: 'user_answer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "user_answer" */
export interface User_Answer_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface User_Answer_Min_Fields {
  __typename?: 'user_answer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "user_answer" */
export interface User_Answer_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "user_answer" */
export interface User_Answer_Mutation_Response {
  __typename?: 'user_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Answer>;
}

/** on_conflict condition type for table "user_answer" */
export interface User_Answer_On_Conflict {
  constraint: User_Answer_Constraint;
  update_columns?: Array<User_Answer_Update_Column>;
  where?: InputMaybe<User_Answer_Bool_Exp>;
}

/** Ordering options when selecting data from "user_answer". */
export interface User_Answer_Order_By {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Question_Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: user_answer */
export interface User_Answer_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface User_Answer_Prepend_Input {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
}

/** select columns of table "user_answer" */
export const User_Answer_Select_Column = {
  /** column name */
  Answer: 'answer',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Id: 'id',
  /** column name */
  QuestionId: 'question_id',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Answer_Select_Column = typeof User_Answer_Select_Column[keyof typeof User_Answer_Select_Column];
/** input type for updating data in table "user_answer" */
export interface User_Answer_Set_Input {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "user_answer" */
export interface User_Answer_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Answer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Answer_Stream_Cursor_Value_Input {
  answer?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "user_answer" */
export const User_Answer_Update_Column = {
  /** column name */
  Answer: 'answer',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Id: 'id',
  /** column name */
  QuestionId: 'question_id',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Answer_Update_Column = typeof User_Answer_Update_Column[keyof typeof User_Answer_Update_Column];
export interface User_Answer_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Answer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Answer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Answer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Answer_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Answer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Answer_Set_Input>;
  where: User_Answer_Bool_Exp;
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface User_Append_Input {
  category?: InputMaybe<Scalars['jsonb']['input']>;
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export interface User_Bool_Exp {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  applications?: InputMaybe<Application_Bool_Exp>;
  applicationsByUpdatedByUserId?: InputMaybe<Application_Bool_Exp>;
  applicationsByUpdatedByUserId_aggregate?: InputMaybe<Application_Aggregate_Bool_Exp>;
  applications_aggregate?: InputMaybe<Application_Aggregate_Bool_Exp>;
  category?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employer_users?: InputMaybe<Employer_User_Bool_Exp>;
  employer_users_aggregate?: InputMaybe<Employer_User_Aggregate_Bool_Exp>;
  employers?: InputMaybe<Employer_Bool_Exp>;
  employersByUpdatedByUserId?: InputMaybe<Employer_Bool_Exp>;
  employersByUpdatedByUserId_aggregate?: InputMaybe<Employer_Aggregate_Bool_Exp>;
  employers_aggregate?: InputMaybe<Employer_Aggregate_Bool_Exp>;
  files?: InputMaybe<File_Bool_Exp>;
  files_aggregate?: InputMaybe<File_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Location_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  onboard_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  profile_photo?: InputMaybe<String_Comparison_Exp>;
  public_profile?: InputMaybe<Boolean_Comparison_Exp>;
  role?: InputMaybe<Enum_Role_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_answers?: InputMaybe<User_Answer_Bool_Exp>;
  user_answers_aggregate?: InputMaybe<User_Answer_Aggregate_Bool_Exp>;
  user_educations?: InputMaybe<User_Education_Bool_Exp>;
  user_educations_aggregate?: InputMaybe<User_Education_Aggregate_Bool_Exp>;
  user_experiences?: InputMaybe<User_Experience_Bool_Exp>;
  user_experiences_aggregate?: InputMaybe<User_Experience_Aggregate_Bool_Exp>;
  user_licences?: InputMaybe<User_Licence_Bool_Exp>;
  user_licences_aggregate?: InputMaybe<User_Licence_Aggregate_Bool_Exp>;
  user_role?: InputMaybe<Enum_Role_Bool_Exp>;
  user_searches?: InputMaybe<User_Search_Bool_Exp>;
  user_searches_aggregate?: InputMaybe<User_Search_Aggregate_Bool_Exp>;
  watchlist?: InputMaybe<Watchlist_Bool_Exp>;
  watchlist_aggregate?: InputMaybe<Watchlist_Aggregate_Bool_Exp>;
}

/** unique or primary key constraints on table "user" */
export const User_Constraint = {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey: 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserIdKey: 'user_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey: 'user_pkey'
} as const;

export type User_Constraint = typeof User_Constraint[keyof typeof User_Constraint];
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface User_Delete_At_Path_Input {
  category?: InputMaybe<Array<Scalars['String']['input']>>;
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface User_Delete_Elem_Input {
  category?: InputMaybe<Scalars['Int']['input']>;
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface User_Delete_Key_Input {
  category?: InputMaybe<Scalars['String']['input']>;
}

/** columns and relationships of "user_education" */
export interface User_Education {
  __typename?: 'user_education';
  complete: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  institution: Scalars['String']['output'];
  issue_date?: Maybe<Scalars['date']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "user_education" */
export interface User_Education_Aggregate {
  __typename?: 'user_education_aggregate';
  aggregate?: Maybe<User_Education_Aggregate_Fields>;
  nodes: Array<User_Education>;
}

export interface User_Education_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<User_Education_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Education_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Education_Aggregate_Bool_Exp_Count>;
}

export interface User_Education_Aggregate_Bool_Exp_Bool_And {
  arguments: User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Education_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface User_Education_Aggregate_Bool_Exp_Bool_Or {
  arguments: User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Education_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface User_Education_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<User_Education_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Education_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "user_education" */
export interface User_Education_Aggregate_Fields {
  __typename?: 'user_education_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Education_Max_Fields>;
  min?: Maybe<User_Education_Min_Fields>;
}


/** aggregate fields of "user_education" */
export interface User_Education_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<User_Education_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "user_education" */
export interface User_Education_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Education_Max_Order_By>;
  min?: InputMaybe<User_Education_Min_Order_By>;
}

/** input type for inserting array relation for remote table "user_education" */
export interface User_Education_Arr_Rel_Insert_Input {
  data: Array<User_Education_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Education_On_Conflict>;
}

/** Boolean expression to filter rows from the table "user_education". All fields are combined with a logical 'AND'. */
export interface User_Education_Bool_Exp {
  _and?: InputMaybe<Array<User_Education_Bool_Exp>>;
  _not?: InputMaybe<User_Education_Bool_Exp>;
  _or?: InputMaybe<Array<User_Education_Bool_Exp>>;
  complete?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  institution?: InputMaybe<String_Comparison_Exp>;
  issue_date?: InputMaybe<Date_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "user_education" */
export const User_Education_Constraint = {
  /** unique or primary key constraint on columns "id" */
  UserEducationPkey: 'user_education_pkey'
} as const;

export type User_Education_Constraint = typeof User_Education_Constraint[keyof typeof User_Education_Constraint];
/** input type for inserting data into table "user_education" */
export interface User_Education_Insert_Input {
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface User_Education_Max_Fields {
  __typename?: 'user_education_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution?: Maybe<Scalars['String']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "user_education" */
export interface User_Education_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface User_Education_Min_Fields {
  __typename?: 'user_education_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  institution?: Maybe<Scalars['String']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "user_education" */
export interface User_Education_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "user_education" */
export interface User_Education_Mutation_Response {
  __typename?: 'user_education_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Education>;
}

/** on_conflict condition type for table "user_education" */
export interface User_Education_On_Conflict {
  constraint: User_Education_Constraint;
  update_columns?: Array<User_Education_Update_Column>;
  where?: InputMaybe<User_Education_Bool_Exp>;
}

/** Ordering options when selecting data from "user_education". */
export interface User_Education_Order_By {
  complete?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: user_education */
export interface User_Education_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "user_education" */
export const User_Education_Select_Column = {
  /** column name */
  Complete: 'complete',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  Institution: 'institution',
  /** column name */
  IssueDate: 'issue_date',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Education_Select_Column = typeof User_Education_Select_Column[keyof typeof User_Education_Select_Column];
/** select "user_education_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_education" */
export const User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  Complete: 'complete'
} as const;

export type User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "user_education_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_education" */
export const User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  Complete: 'complete'
} as const;

export type User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof User_Education_Select_Column_User_Education_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
/** input type for updating data in table "user_education" */
export interface User_Education_Set_Input {
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "user_education" */
export interface User_Education_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Education_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Education_Stream_Cursor_Value_Input {
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  institution?: InputMaybe<Scalars['String']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "user_education" */
export const User_Education_Update_Column = {
  /** column name */
  Complete: 'complete',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  Institution: 'institution',
  /** column name */
  IssueDate: 'issue_date',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Education_Update_Column = typeof User_Education_Update_Column[keyof typeof User_Education_Update_Column];
export interface User_Education_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Education_Set_Input>;
  where: User_Education_Bool_Exp;
}

/** columns and relationships of "user_experience" */
export interface User_Experience {
  __typename?: 'user_experience';
  company: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  current: Scalars['Boolean']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description: Scalars['String']['output'];
  end_date?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  start_date: Scalars['date']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "user_experience" */
export interface User_Experience_Aggregate {
  __typename?: 'user_experience_aggregate';
  aggregate?: Maybe<User_Experience_Aggregate_Fields>;
  nodes: Array<User_Experience>;
}

export interface User_Experience_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<User_Experience_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Experience_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Experience_Aggregate_Bool_Exp_Count>;
}

export interface User_Experience_Aggregate_Bool_Exp_Bool_And {
  arguments: User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Experience_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface User_Experience_Aggregate_Bool_Exp_Bool_Or {
  arguments: User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Experience_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface User_Experience_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<User_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Experience_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "user_experience" */
export interface User_Experience_Aggregate_Fields {
  __typename?: 'user_experience_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Experience_Max_Fields>;
  min?: Maybe<User_Experience_Min_Fields>;
}


/** aggregate fields of "user_experience" */
export interface User_Experience_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<User_Experience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "user_experience" */
export interface User_Experience_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Experience_Max_Order_By>;
  min?: InputMaybe<User_Experience_Min_Order_By>;
}

/** input type for inserting array relation for remote table "user_experience" */
export interface User_Experience_Arr_Rel_Insert_Input {
  data: Array<User_Experience_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Experience_On_Conflict>;
}

/** Boolean expression to filter rows from the table "user_experience". All fields are combined with a logical 'AND'. */
export interface User_Experience_Bool_Exp {
  _and?: InputMaybe<Array<User_Experience_Bool_Exp>>;
  _not?: InputMaybe<User_Experience_Bool_Exp>;
  _or?: InputMaybe<Array<User_Experience_Bool_Exp>>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current?: InputMaybe<Boolean_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "user_experience" */
export const User_Experience_Constraint = {
  /** unique or primary key constraint on columns "id" */
  UserExperiencePkey: 'user_experience_pkey'
} as const;

export type User_Experience_Constraint = typeof User_Experience_Constraint[keyof typeof User_Experience_Constraint];
/** input type for inserting data into table "user_experience" */
export interface User_Experience_Insert_Input {
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface User_Experience_Max_Fields {
  __typename?: 'user_experience_max_fields';
  company?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "user_experience" */
export interface User_Experience_Max_Order_By {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface User_Experience_Min_Fields {
  __typename?: 'user_experience_min_fields';
  company?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "user_experience" */
export interface User_Experience_Min_Order_By {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "user_experience" */
export interface User_Experience_Mutation_Response {
  __typename?: 'user_experience_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Experience>;
}

/** on_conflict condition type for table "user_experience" */
export interface User_Experience_On_Conflict {
  constraint: User_Experience_Constraint;
  update_columns?: Array<User_Experience_Update_Column>;
  where?: InputMaybe<User_Experience_Bool_Exp>;
}

/** Ordering options when selecting data from "user_experience". */
export interface User_Experience_Order_By {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: user_experience */
export interface User_Experience_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "user_experience" */
export const User_Experience_Select_Column = {
  /** column name */
  Company: 'company',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Current: 'current',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Description: 'description',
  /** column name */
  EndDate: 'end_date',
  /** column name */
  Id: 'id',
  /** column name */
  StartDate: 'start_date',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Experience_Select_Column = typeof User_Experience_Select_Column[keyof typeof User_Experience_Select_Column];
/** select "user_experience_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_experience" */
export const User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  Current: 'current'
} as const;

export type User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "user_experience_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_experience" */
export const User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  Current: 'current'
} as const;

export type User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof User_Experience_Select_Column_User_Experience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
/** input type for updating data in table "user_experience" */
export interface User_Experience_Set_Input {
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "user_experience" */
export interface User_Experience_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Experience_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Experience_Stream_Cursor_Value_Input {
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "user_experience" */
export const User_Experience_Update_Column = {
  /** column name */
  Company: 'company',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  Current: 'current',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Description: 'description',
  /** column name */
  EndDate: 'end_date',
  /** column name */
  Id: 'id',
  /** column name */
  StartDate: 'start_date',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Experience_Update_Column = typeof User_Experience_Update_Column[keyof typeof User_Experience_Update_Column];
export interface User_Experience_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Experience_Set_Input>;
  where: User_Experience_Bool_Exp;
}

/** input type for inserting data into table "user" */
export interface User_Insert_Input {
  about?: InputMaybe<Scalars['String']['input']>;
  applications?: InputMaybe<Application_Arr_Rel_Insert_Input>;
  applicationsByUpdatedByUserId?: InputMaybe<Application_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer_users?: InputMaybe<Employer_User_Arr_Rel_Insert_Input>;
  employers?: InputMaybe<Employer_Arr_Rel_Insert_Input>;
  employersByUpdatedByUserId?: InputMaybe<Employer_Arr_Rel_Insert_Input>;
  files?: InputMaybe<File_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  onboard_at?: InputMaybe<Scalars['timestamptz']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profile_photo?: InputMaybe<Scalars['String']['input']>;
  public_profile?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Enum_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_answers?: InputMaybe<User_Answer_Arr_Rel_Insert_Input>;
  user_educations?: InputMaybe<User_Education_Arr_Rel_Insert_Input>;
  user_experiences?: InputMaybe<User_Experience_Arr_Rel_Insert_Input>;
  user_licences?: InputMaybe<User_Licence_Arr_Rel_Insert_Input>;
  user_role?: InputMaybe<Enum_Role_Obj_Rel_Insert_Input>;
  user_searches?: InputMaybe<User_Search_Arr_Rel_Insert_Input>;
  watchlist?: InputMaybe<Watchlist_Arr_Rel_Insert_Input>;
}

/** columns and relationships of "user_licence" */
export interface User_Licence {
  __typename?: 'user_licence';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  expires: Scalars['Boolean']['output'];
  expiry_date?: Maybe<Scalars['date']['output']>;
  id: Scalars['uuid']['output'];
  issue_date: Scalars['date']['output'];
  organisation: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "user_licence" */
export interface User_Licence_Aggregate {
  __typename?: 'user_licence_aggregate';
  aggregate?: Maybe<User_Licence_Aggregate_Fields>;
  nodes: Array<User_Licence>;
}

export interface User_Licence_Aggregate_Bool_Exp {
  bool_and?: InputMaybe<User_Licence_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Licence_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Licence_Aggregate_Bool_Exp_Count>;
}

export interface User_Licence_Aggregate_Bool_Exp_Bool_And {
  arguments: User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Licence_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface User_Licence_Aggregate_Bool_Exp_Bool_Or {
  arguments: User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Licence_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
}

export interface User_Licence_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<User_Licence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Licence_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "user_licence" */
export interface User_Licence_Aggregate_Fields {
  __typename?: 'user_licence_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Licence_Max_Fields>;
  min?: Maybe<User_Licence_Min_Fields>;
}


/** aggregate fields of "user_licence" */
export interface User_Licence_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<User_Licence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "user_licence" */
export interface User_Licence_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Licence_Max_Order_By>;
  min?: InputMaybe<User_Licence_Min_Order_By>;
}

/** input type for inserting array relation for remote table "user_licence" */
export interface User_Licence_Arr_Rel_Insert_Input {
  data: Array<User_Licence_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Licence_On_Conflict>;
}

/** Boolean expression to filter rows from the table "user_licence". All fields are combined with a logical 'AND'. */
export interface User_Licence_Bool_Exp {
  _and?: InputMaybe<Array<User_Licence_Bool_Exp>>;
  _not?: InputMaybe<User_Licence_Bool_Exp>;
  _or?: InputMaybe<Array<User_Licence_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires?: InputMaybe<Boolean_Comparison_Exp>;
  expiry_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  issue_date?: InputMaybe<Date_Comparison_Exp>;
  organisation?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "user_licence" */
export const User_Licence_Constraint = {
  /** unique or primary key constraint on columns "id" */
  UserLicencePkey: 'user_licence_pkey'
} as const;

export type User_Licence_Constraint = typeof User_Licence_Constraint[keyof typeof User_Licence_Constraint];
/** input type for inserting data into table "user_licence" */
export interface User_Licence_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires?: InputMaybe<Scalars['Boolean']['input']>;
  expiry_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface User_Licence_Max_Fields {
  __typename?: 'user_licence_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  expiry_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  organisation?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "user_licence" */
export interface User_Licence_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface User_Licence_Min_Fields {
  __typename?: 'user_licence_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  expiry_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  issue_date?: Maybe<Scalars['date']['output']>;
  organisation?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "user_licence" */
export interface User_Licence_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "user_licence" */
export interface User_Licence_Mutation_Response {
  __typename?: 'user_licence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Licence>;
}

/** on_conflict condition type for table "user_licence" */
export interface User_Licence_On_Conflict {
  constraint: User_Licence_Constraint;
  update_columns?: Array<User_Licence_Update_Column>;
  where?: InputMaybe<User_Licence_Bool_Exp>;
}

/** Ordering options when selecting data from "user_licence". */
export interface User_Licence_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  expires?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issue_date?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: user_licence */
export interface User_Licence_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "user_licence" */
export const User_Licence_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Expires: 'expires',
  /** column name */
  ExpiryDate: 'expiry_date',
  /** column name */
  Id: 'id',
  /** column name */
  IssueDate: 'issue_date',
  /** column name */
  Organisation: 'organisation',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Licence_Select_Column = typeof User_Licence_Select_Column[keyof typeof User_Licence_Select_Column];
/** select "user_licence_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_licence" */
export const User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = {
  /** column name */
  Expires: 'expires'
} as const;

export type User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns = typeof User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns[keyof typeof User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns];
/** select "user_licence_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_licence" */
export const User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = {
  /** column name */
  Expires: 'expires'
} as const;

export type User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns = typeof User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns[keyof typeof User_Licence_Select_Column_User_Licence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns];
/** input type for updating data in table "user_licence" */
export interface User_Licence_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires?: InputMaybe<Scalars['Boolean']['input']>;
  expiry_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "user_licence" */
export interface User_Licence_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Licence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Licence_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires?: InputMaybe<Scalars['Boolean']['input']>;
  expiry_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  issue_date?: InputMaybe<Scalars['date']['input']>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "user_licence" */
export const User_Licence_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Expires: 'expires',
  /** column name */
  ExpiryDate: 'expiry_date',
  /** column name */
  Id: 'id',
  /** column name */
  IssueDate: 'issue_date',
  /** column name */
  Organisation: 'organisation',
  /** column name */
  Title: 'title',
  /** column name */
  UpdatedAt: 'updated_at',
  /** column name */
  UserId: 'user_id'
} as const;

export type User_Licence_Update_Column = typeof User_Licence_Update_Column[keyof typeof User_Licence_Update_Column];
export interface User_Licence_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Licence_Set_Input>;
  where: User_Licence_Bool_Exp;
}

/** aggregate max on columns */
export interface User_Max_Fields {
  __typename?: 'user_max_fields';
  about?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  onboard_at?: Maybe<Scalars['timestamptz']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profile_photo?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** aggregate min on columns */
export interface User_Min_Fields {
  __typename?: 'user_min_fields';
  about?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['uuid']['output']>;
  onboard_at?: Maybe<Scalars['timestamptz']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profile_photo?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
}

/** response of any mutation on the table "user" */
export interface User_Mutation_Response {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
}

/** input type for inserting object relation for remote table "user" */
export interface User_Obj_Rel_Insert_Input {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
}

/** on_conflict condition type for table "user" */
export interface User_On_Conflict {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
}

/** Ordering options when selecting data from "user". */
export interface User_Order_By {
  about?: InputMaybe<Order_By>;
  applicationsByUpdatedByUserId_aggregate?: InputMaybe<Application_Aggregate_Order_By>;
  applications_aggregate?: InputMaybe<Application_Aggregate_Order_By>;
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employer_users_aggregate?: InputMaybe<Employer_User_Aggregate_Order_By>;
  employersByUpdatedByUserId_aggregate?: InputMaybe<Employer_Aggregate_Order_By>;
  employers_aggregate?: InputMaybe<Employer_Aggregate_Order_By>;
  files_aggregate?: InputMaybe<File_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location?: InputMaybe<Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  onboard_at?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_photo?: InputMaybe<Order_By>;
  public_profile?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_answers_aggregate?: InputMaybe<User_Answer_Aggregate_Order_By>;
  user_educations_aggregate?: InputMaybe<User_Education_Aggregate_Order_By>;
  user_experiences_aggregate?: InputMaybe<User_Experience_Aggregate_Order_By>;
  user_licences_aggregate?: InputMaybe<User_Licence_Aggregate_Order_By>;
  user_role?: InputMaybe<Enum_Role_Order_By>;
  user_searches_aggregate?: InputMaybe<User_Search_Aggregate_Order_By>;
  watchlist_aggregate?: InputMaybe<Watchlist_Aggregate_Order_By>;
}

/** primary key columns input for table: user */
export interface User_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface User_Prepend_Input {
  category?: InputMaybe<Scalars['jsonb']['input']>;
}

/** columns and relationships of "user_search" */
export interface User_Search {
  __typename?: 'user_search';
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  employer?: Maybe<Employer>;
  employer_slug?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  job_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  unauth_id?: Maybe<Scalars['uuid']['output']>;
}

/** aggregated selection of "user_search" */
export interface User_Search_Aggregate {
  __typename?: 'user_search_aggregate';
  aggregate?: Maybe<User_Search_Aggregate_Fields>;
  nodes: Array<User_Search>;
}

export interface User_Search_Aggregate_Bool_Exp {
  count?: InputMaybe<User_Search_Aggregate_Bool_Exp_Count>;
}

export interface User_Search_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<User_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Search_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "user_search" */
export interface User_Search_Aggregate_Fields {
  __typename?: 'user_search_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Search_Max_Fields>;
  min?: Maybe<User_Search_Min_Fields>;
}


/** aggregate fields of "user_search" */
export interface User_Search_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<User_Search_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "user_search" */
export interface User_Search_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Search_Max_Order_By>;
  min?: InputMaybe<User_Search_Min_Order_By>;
}

/** input type for inserting array relation for remote table "user_search" */
export interface User_Search_Arr_Rel_Insert_Input {
  data: Array<User_Search_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Search_On_Conflict>;
}

/** Boolean expression to filter rows from the table "user_search". All fields are combined with a logical 'AND'. */
export interface User_Search_Bool_Exp {
  _and?: InputMaybe<Array<User_Search_Bool_Exp>>;
  _not?: InputMaybe<User_Search_Bool_Exp>;
  _or?: InputMaybe<Array<User_Search_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<User_Bool_Exp>;
  created_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_slug?: InputMaybe<String_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_type?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  search?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  unauth_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "user_search" */
export const User_Search_Constraint = {
  /** unique or primary key constraint on columns "id" */
  UserSearchPkey: 'user_search_pkey'
} as const;

export type User_Search_Constraint = typeof User_Search_Constraint[keyof typeof User_Search_Constraint];
/** input type for inserting data into table "user_search" */
export interface User_Search_Insert_Input {
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_slug?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_type?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  unauth_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface User_Search_Max_Fields {
  __typename?: 'user_search_max_fields';
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  employer_slug?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  unauth_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "user_search" */
export interface User_Search_Max_Order_By {
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer_slug?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  search?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  unauth_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface User_Search_Min_Fields {
  __typename?: 'user_search_min_fields';
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['uuid']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  employer_slug?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  search?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  unauth_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "user_search" */
export interface User_Search_Min_Order_By {
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer_slug?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  search?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  unauth_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "user_search" */
export interface User_Search_Mutation_Response {
  __typename?: 'user_search_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Search>;
}

/** on_conflict condition type for table "user_search" */
export interface User_Search_On_Conflict {
  constraint: User_Search_Constraint;
  update_columns?: Array<User_Search_Update_Column>;
  where?: InputMaybe<User_Search_Bool_Exp>;
}

/** Ordering options when selecting data from "user_search". */
export interface User_Search_Order_By {
  category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<User_Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_slug?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  search?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  unauth_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: user_search */
export interface User_Search_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "user_search" */
export const User_Search_Select_Column = {
  /** column name */
  Category: 'category',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  EmployerSlug: 'employer_slug',
  /** column name */
  From: 'from',
  /** column name */
  Id: 'id',
  /** column name */
  JobType: 'job_type',
  /** column name */
  Location: 'location',
  /** column name */
  Search: 'search',
  /** column name */
  To: 'to',
  /** column name */
  UnauthId: 'unauth_id'
} as const;

export type User_Search_Select_Column = typeof User_Search_Select_Column[keyof typeof User_Search_Select_Column];
/** input type for updating data in table "user_search" */
export interface User_Search_Set_Input {
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer_slug?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_type?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  unauth_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "user_search" */
export interface User_Search_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Search_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Search_Stream_Cursor_Value_Input {
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  employer_slug?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_type?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  unauth_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "user_search" */
export const User_Search_Update_Column = {
  /** column name */
  Category: 'category',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  CreatedByUserId: 'created_by_user_id',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  EmployerSlug: 'employer_slug',
  /** column name */
  From: 'from',
  /** column name */
  Id: 'id',
  /** column name */
  JobType: 'job_type',
  /** column name */
  Location: 'location',
  /** column name */
  Search: 'search',
  /** column name */
  To: 'to',
  /** column name */
  UnauthId: 'unauth_id'
} as const;

export type User_Search_Update_Column = typeof User_Search_Update_Column[keyof typeof User_Search_Update_Column];
export interface User_Search_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Search_Set_Input>;
  where: User_Search_Bool_Exp;
}

/** select columns of table "user" */
export const User_Select_Column = {
  /** column name */
  About: 'about',
  /** column name */
  Category: 'category',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Email: 'email',
  /** column name */
  FirstName: 'first_name',
  /** column name */
  Id: 'id',
  /** column name */
  LastName: 'last_name',
  /** column name */
  LocationId: 'location_id',
  /** column name */
  OnboardAt: 'onboard_at',
  /** column name */
  Phone: 'phone',
  /** column name */
  ProfilePhoto: 'profile_photo',
  /** column name */
  PublicProfile: 'public_profile',
  /** column name */
  Role: 'role',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type User_Select_Column = typeof User_Select_Column[keyof typeof User_Select_Column];
/** input type for updating data in table "user" */
export interface User_Set_Input {
  about?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  onboard_at?: InputMaybe<Scalars['timestamptz']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profile_photo?: InputMaybe<Scalars['String']['input']>;
  public_profile?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Enum_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** Streaming cursor of the table "user" */
export interface User_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface User_Stream_Cursor_Value_Input {
  about?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location_id?: InputMaybe<Scalars['uuid']['input']>;
  onboard_at?: InputMaybe<Scalars['timestamptz']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profile_photo?: InputMaybe<Scalars['String']['input']>;
  public_profile?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Enum_Role_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
}

/** update columns of table "user" */
export const User_Update_Column = {
  /** column name */
  About: 'about',
  /** column name */
  Category: 'category',
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Email: 'email',
  /** column name */
  FirstName: 'first_name',
  /** column name */
  Id: 'id',
  /** column name */
  LastName: 'last_name',
  /** column name */
  LocationId: 'location_id',
  /** column name */
  OnboardAt: 'onboard_at',
  /** column name */
  Phone: 'phone',
  /** column name */
  ProfilePhoto: 'profile_photo',
  /** column name */
  PublicProfile: 'public_profile',
  /** column name */
  Role: 'role',
  /** column name */
  UpdatedAt: 'updated_at'
} as const;

export type User_Update_Column = typeof User_Update_Column[keyof typeof User_Update_Column];
export interface User_Updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface Uuid_Comparison_Exp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

/** columns and relationships of "watchlist" */
export interface Watchlist {
  __typename?: 'watchlist';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  job: Job;
  job_id: Scalars['uuid']['output'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id: Scalars['uuid']['output'];
}

/** aggregated selection of "watchlist" */
export interface Watchlist_Aggregate {
  __typename?: 'watchlist_aggregate';
  aggregate?: Maybe<Watchlist_Aggregate_Fields>;
  nodes: Array<Watchlist>;
}

export interface Watchlist_Aggregate_Bool_Exp {
  count?: InputMaybe<Watchlist_Aggregate_Bool_Exp_Count>;
}

export interface Watchlist_Aggregate_Bool_Exp_Count {
  arguments?: InputMaybe<Array<Watchlist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Watchlist_Bool_Exp>;
  predicate: Int_Comparison_Exp;
}

/** aggregate fields of "watchlist" */
export interface Watchlist_Aggregate_Fields {
  __typename?: 'watchlist_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Watchlist_Max_Fields>;
  min?: Maybe<Watchlist_Min_Fields>;
}


/** aggregate fields of "watchlist" */
export interface Watchlist_Aggregate_FieldsCountArgs {
  columns?: InputMaybe<Array<Watchlist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "watchlist" */
export interface Watchlist_Aggregate_Order_By {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Watchlist_Max_Order_By>;
  min?: InputMaybe<Watchlist_Min_Order_By>;
}

/** input type for inserting array relation for remote table "watchlist" */
export interface Watchlist_Arr_Rel_Insert_Input {
  data: Array<Watchlist_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Watchlist_On_Conflict>;
}

/** Boolean expression to filter rows from the table "watchlist". All fields are combined with a logical 'AND'. */
export interface Watchlist_Bool_Exp {
  _and?: InputMaybe<Array<Watchlist_Bool_Exp>>;
  _not?: InputMaybe<Watchlist_Bool_Exp>;
  _or?: InputMaybe<Array<Watchlist_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}

/** unique or primary key constraints on table "watchlist" */
export const Watchlist_Constraint = {
  /** unique or primary key constraint on columns "id" */
  WatchlistPkey: 'watchlist_pkey'
} as const;

export type Watchlist_Constraint = typeof Watchlist_Constraint[keyof typeof Watchlist_Constraint];
/** input type for inserting data into table "watchlist" */
export interface Watchlist_Insert_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** aggregate max on columns */
export interface Watchlist_Max_Fields {
  __typename?: 'watchlist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "watchlist" */
export interface Watchlist_Max_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** aggregate min on columns */
export interface Watchlist_Min_Fields {
  __typename?: 'watchlist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  job_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "watchlist" */
export interface Watchlist_Min_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** response of any mutation on the table "watchlist" */
export interface Watchlist_Mutation_Response {
  __typename?: 'watchlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Watchlist>;
}

/** on_conflict condition type for table "watchlist" */
export interface Watchlist_On_Conflict {
  constraint: Watchlist_Constraint;
  update_columns?: Array<Watchlist_Update_Column>;
  where?: InputMaybe<Watchlist_Bool_Exp>;
}

/** Ordering options when selecting data from "watchlist". */
export interface Watchlist_Order_By {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
}

/** primary key columns input for table: watchlist */
export interface Watchlist_Pk_Columns_Input {
  id: Scalars['uuid']['input'];
}

/** select columns of table "watchlist" */
export const Watchlist_Select_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  UserId: 'user_id'
} as const;

export type Watchlist_Select_Column = typeof Watchlist_Select_Column[keyof typeof Watchlist_Select_Column];
/** input type for updating data in table "watchlist" */
export interface Watchlist_Set_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Streaming cursor of the table "watchlist" */
export interface Watchlist_Stream_Cursor_Input {
  /** Stream column input with initial value */
  initial_value: Watchlist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
}

/** Initial value of the column from where the streaming should start */
export interface Watchlist_Stream_Cursor_Value_Input {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  job_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
}

/** update columns of table "watchlist" */
export const Watchlist_Update_Column = {
  /** column name */
  CreatedAt: 'created_at',
  /** column name */
  DeletedAt: 'deleted_at',
  /** column name */
  Id: 'id',
  /** column name */
  JobId: 'job_id',
  /** column name */
  UserId: 'user_id'
} as const;

export type Watchlist_Update_Column = typeof Watchlist_Update_Column[keyof typeof Watchlist_Update_Column];
export interface Watchlist_Updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Watchlist_Set_Input>;
  where: Watchlist_Bool_Exp;
}
