import { RadioGroup as RadioGroupDefault } from '@headlessui/react';
import { RadioOption } from 'components/forms/RadioGroup/RadioOption';

const defaultOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export type RadioGroupProps = {
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  value?: boolean;
  onChange: (a: boolean) => void | Promise<void>;
};

export function RadioGroupBoolean({
  disabled,
  label,
  loading,
  value,
  onChange,
}: RadioGroupProps): JSX.Element {
  const options = defaultOptions;
  return (
    <RadioGroupDefault
      disabled={disabled || loading}
      value={value}
      className="mb-8"
      onChange={onChange}
    >
      {label && (
        <label className="mb-2 block text-sm -tracking-two-percent text-charcoal-500">
          {label}
        </label>
      )}
      {options?.map(({ value: v, label: l }) => (
        <RadioOption {...{ value: v, label: l }} key={v?.toString() ?? ''} />
      ))}
    </RadioGroupDefault>
  );
}
