import { useRouter } from 'next/router';
import { appRoutes } from 'components/AuthContext/constants';

export const useRouteCheck = () => {
  const { pathname } = useRouter();

  const isPortalSubPath = appRoutes.protected.PORTAL_SUBPATH.test(pathname);

  const isPortal = pathname === appRoutes.public.PORTAL || isPortalSubPath;

  const isPublic = Object.values(appRoutes.public).some(
    (value: string | RegExp) =>
      typeof value === 'string' ? value === pathname : value.test(pathname)
  );

  const isGenerate = ['/generate/cv/[user]', '/generate/og/[id]'].some(
    (s: string) => s === pathname
  );

  const isProtected =
    !isPublic &&
    Object.values(appRoutes.protected).some((value: string | RegExp) =>
      typeof value === 'string' ? value === pathname : value.test(pathname)
    );

  const notAllowed = Object.values(appRoutes['404']).some(
    (value: string | RegExp) =>
      typeof value === 'string' ? value === pathname : value.test(pathname)
  );

  // TODO: may use routes other than portal, is this resilient?
  const loginType = isPortal || isPortalSubPath ? 'employer' : 'employee';

  return {
    isPortalSubPath,
    isPortal,
    isPublic,
    isGenerate,
    isProtected,
    notAllowed,
    loginType: loginType as typeof loginType,
  };
};
