import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type SectionProps = PropsWithChildren<{
  className?: string;
  bleed?: boolean;
  dark?: boolean;
  id?: string;
}>;

export function Section({
  bleed = false,
  children,
  className = '',
  dark = false,
  id,
}: SectionProps): JSX.Element {
  return (
    <section
      id={id}
      className={twMerge(
        'relative max-w-screen',
        className,
        (bleed || dark) && '-mx-8 md:-mx-12 lg:-mx-24',
        dark && 'bg-charcoal-500 text-white px-8 md:px-12 lg:px-24'
      )}
    >
      {children}
    </section>
  );
}
