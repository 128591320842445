// Components
import { twJoin } from 'tailwind-merge';
import { EmployerText } from 'components/selfService/typography/EmployerText';

// Types
type CardComponentProps = {
  textReverse?: boolean;
  title: string;
  text: string;
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const CardDescription = ({
  textReverse,
  title,
  text,
}: CardComponentProps) => (
  <div
    className={twJoin(
      textReverse ? 'order-1 justify-start' : 'order-2 justify-end',
      'grow flex flex-col'
    )}
  >
    <EmployerText
      as="p"
      size="3xl"
      className="mb-2 mt-1 font-bold text-orange-500"
    >
      {title}
    </EmployerText>
    <EmployerText as="p" size="lg" className="text-lg text-charcoal-400">
      {text}
    </EmployerText>
  </div>
);
