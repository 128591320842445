import { SVGAttributes } from 'react';

type CrossIconProps = SVGAttributes<SVGSVGElement>;

const CrossIcon = (props: CrossIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 1V15M1 8H15"
      stroke={props.stroke || '#fff'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CrossIcon;
