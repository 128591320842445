import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC, PropsWithChildren } from 'react';
import { EmptyProps } from 'types/utils';

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
);

type Props = PropsWithChildren<EmptyProps>;

export const StripeElements: FC<Props> = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);
