import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { Maybe } from 'database/types';

type ActionButtonProps = PropsWithChildren<{
  className?: string;
  onClick: () => void;
  show?: boolean;
  title: string;
}>;

export function ActionButton({
  className = '',
  children,
  onClick,
  show = true,
  title,
}: ActionButtonProps): Maybe<JSX.Element> {
  if (!show) return null;
  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(
        'group gap-x-1.5 bg-none px-3 py-2 text-xs font-medium text-grey-dark hover:ring-1 ring-inset hover:bg-white hover:ring-grey-light focus:z-10 relative overflow-visible rounded-md',
        className
      )}
    >
      {children}
      <span className="absolute -bottom-full left-1/2 z-10 hidden w-fit -translate-x-1/2 rounded-lg bg-grey-dark p-2 text-center text-white group-hover:block">
        {title}
      </span>
    </button>
  );
}
