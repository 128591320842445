import { type EmployerTextProps } from './EmployerText';

export const getFromAs = (as: EmployerTextProps['as']) => {
  switch (as) {
    case 'h1':
      return 'font-bold';
    case 'h2':
      return 'font-bold';
    case 'h3':
      return 'font-bold';
    case 'h4':
      return 'font-bold';
    case 'p':
      return 'font-medium';
  }
};

export const getSize = (size: EmployerTextProps['size']) => {
  switch (size) {
    case '9xl':
      return 'text-[48px] leading-[57.6px] lg:text-[56px] lg:leading-[67.2px]';
    case '8xl':
      return 'text-[32px] leading-[41.6px] tracking-[-0.64px] md:text-[44px] md:leading-[57.2px] md:tracking-[-0.88px] 2xl:text-[52px] 2xl:leading-[67.6px] 2xl:tracking-[-1.04px]';
    case '7xl':
      return 'text-[36px] leading-[46.8px] tracking-[-0.72px]  md:text-[44px] md:leading-[52px] md:tracking-[-0.8px] 2xl:text-5xl 2xl:leading-[62.4px] 2xl:tracking-[-0.96px]';
    case '6xl':
      return 'text-[32px] leading-[41.6px] tracking-[-0.64px] md:text-[44px] md:leading-[57.2px] md:tracking-[-0.88px]';
    case '5xl':
      return 'text-[36px] leading-[46.8px] tracking-[-0.72px] md:text-[40px] md:leading-[52px] md:tracking-[-0.8px]';
    case '4xl':
      return 'text-[28px] md:leading-[44.8px] md:tracking-[-0.56px] md:text-4xl md:leading-[57.6px] md:tracking-[-0.72px]';
    case '3xl':
      return 'text-[24px] leading-[38.4px] tracking-[-0.48px] md:text-[26px] md:leading-[41.6px] md:tracking-[-0.52px]';
    case '2xl':
      return 'text-2xl leading-[33.6px] tracking-[-0.48px]';
    case 'xl':
      return 'text-xl leading-8 tracking-[-0.4px]';
    case 'lg':
      return 'text-lg leading-[28.8px] tracking-[-0.36px]';
    case 'base':
    default:
      return 'text-base leading-[25.6px] tracking-[-0.32px]';
    case 'sm':
      return 'leading-[21px] text-sm';
  }
};
