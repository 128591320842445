import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const ChevronLeft: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <path
      d="M8.25 0.625001L4.5 4.375L0.75 0.625"
      stroke="#F17732"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
