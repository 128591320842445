import { FC, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { useController } from 'react-hook-form';

import { UpdatedInputLabel } from 'components/forms/UpdatedFormInput';
import { UpdatedSelect } from 'components/forms/UpdatedFormSelect';
import { Errors } from '..';
import { months } from './options';
import { MonthPickerInput } from './MonthPickerInput';

export const dateRegEx = new RegExp(/^\d{4}-\d{2}-\d{2}$/g);

export type UpdatedMonthPickerProps = InputHTMLAttributes<Element> & {
  control: any;
  className?: string;
  defaultValue?: string;
  label?: string;
  name: string;
  value?: string;
};

export const UpdatedMonthPicker: FC<UpdatedMonthPickerProps> = ({
  control,
  className,
  defaultValue = '',
  required,
  label,
  ...props
}) => {
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({
    control,
    name: props?.name,
    defaultValue,
  });

  const [y, m] = typeof value === 'string' ? value.split('-') : [];

  return (
    <div className={twMerge('relative', error ? 'mb-2' : 'mb-8', className)}>
      <UpdatedInputLabel
        label={label}
        name={props?.name}
        required={required}
        customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
      />
      <div className="mt-1">
        <div className="grid grid-cols-2 space-x-4 sm:grid-cols-3">
          <UpdatedSelect
            className="col-span-1 mb-0"
            placeholder="Month"
            options={months}
            value={m}
            onChange={(a) => onChange([y, a, '01'].join('-'))}
          />
          <MonthPickerInput
            className="col-span-1"
            value={y}
            onChange={(b) => onChange([b, m, '01'].join('-'))}
            onBlur={field.onBlur}
          />
        </div>
      </div>
      <Errors error={error} />
    </div>
  );
};
