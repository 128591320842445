import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import { UpdatedInputLabel } from 'components/forms/UpdatedFormInput';
import { Errors } from '..';
import { UpdatedBooleanOption } from './UpdatedBooleanOption';

export type UpdatedRadioBooleanProps = Pick<
  Parameters<typeof RadioGroup>[0],
  'disabled' | 'className'
> & {
  control: any;
  defaultValue?: boolean;
  label?: string;
  name: string;
  required?: boolean; // Added required prop, default to false
};

export const UpdatedRadioBoolean = forwardRef<
  HTMLInputElement,
  UpdatedRadioBooleanProps
>(function RenderRadioBoolean(
  { control: c, className, defaultValue, disabled, label, name}, // Added required prop, default to false
  ref
) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control: c,
    name,
    defaultValue,
    rules: {
      validate: (value) => value === true || value === false || 'This field is required',
    },
  });

  return (
    <RadioGroup
      {...field}
      disabled={disabled}
      ref={ref}
      className={twMerge(
        'flex flex-row items-center justify-between mb-2',
        className
      )}
    >
      <UpdatedInputLabel
        label={label}
        name={name}
        required={true} // Updated to use the required prop
        customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
      />
      <div className="flex flex-col items-center justify-center">
        <div
          className={twMerge(
            'flex shrink flex-row items-end space-x-1 self-end',
            error ? 'mb-2' : 'mb-0'
          )}
        >
          <UpdatedBooleanOption
            className="w-[90px] text-center"
            value={true}
            label="Yes"
          />
          <UpdatedBooleanOption
            className="w-[90px] text-center"
            value={false}
            label="No"
          />
        </div>
        <Errors error={error} />
      </div>
    </RadioGroup>
  );
});
