import { FC, Fragment, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { MessageType, useMessages } from './Messages';
import { MessageIcon } from './MessageIcon';

export type MessageProps = { id: string } & Omit<MessageType, 'key'>;

export const Message: FC<MessageProps> = ({
  dismissable,
  duration = 3000,
  id,
  message,
  type,
  title,
}) => {
  const [show, setShow] = useState<boolean>(true);
  const { dismiss } = useMessages();

  useEffect(() => {
    if (!(duration && dismissable)) return;
    const m = setTimeout(() => setShow(false), duration);
    const n = setTimeout(() => dismiss(id), duration + 200);
    return () => {
      clearTimeout(m);
      clearTimeout(n);
    };
  }, [dismiss, dismissable, duration, id]);

  return (
    <Transition
      appear={true}
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
        <div className="p-4">
          <div className="flex items-center justify-between">
            {type && (
              <div className="shrink-0">
                <MessageIcon type={type} />
              </div>
            )}
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title && (
                <p
                  className={twMerge(
                    'text-sm font-medium text-gray-900',
                    message && 'mb-1'
                  )}
                >
                  {title}
                </p>
              )}
              {message && <p className="text-sm text-gray-500">{message}</p>}
            </div>
            {dismissable && (
              <div className="ml-4 flex shrink-0">
                <button
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => {
                    setShow(false);
                    setTimeout(() => dismiss(id), 200);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="size-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};
