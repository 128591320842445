import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useGetAnonEnumsQuery, Enum_Dial_Code, Maybe } from 'database/types';
import { useMessages } from 'components/Messages';
import { UpdatedSelect } from 'components/forms/UpdatedFormSelect';
import useIsBrowser from 'utils/hooks/useIsBrowser';
import { InputProps } from 'components/forms/Input';
import { Errors, useFieldError } from 'components/forms/Errors';
import {
  UpdatedInput,
  UpdatedInputLabel,
} from 'components/forms/UpdatedFormInput';

type UpdatedInputPhoneProps = Omit<InputProps, 'onChange' | 'defaultValue'> & {
  defaultValue?: Maybe<string>;
  onChange: (a: string) => void;
  customLabel?: string;
};

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export function UpdatedInputPhone({
  className,
  defaultValue,
  disabled,
  label,
  noValidation,
  required,
  onChange,
  placeholder = 'Enter a value',
  name,
  customLabel,
}: UpdatedInputPhoneProps): JSX.Element {
  const isBrowser = useIsBrowser();
  const [dc, setDialCode] = useState<Enum_Dial_Code['label']>('+64');
  const [phone, setPhone] = useState<string>('');
  const { data, loading } = useGetAnonEnumsQuery();
  const alert = useMessages();
  const { error } = useFieldError(name);

  useEffect(() => {
    if (loading || !defaultValue || !isBrowser) return;
    const a = data?.dial_code.find((i: any) =>
      defaultValue.startsWith(i.value)
    )?.value;
    if (!a) return;
    dc !== a && setDialCode(a);
    const b = defaultValue.slice(a.length);
    phone !== b && setPhone(b as string);
  }, [loading, defaultValue, isBrowser]);

  useEffect(() => {
    if (!isBrowser) return;
    const next = dc + phone;
    onChange(next);
  }, [dc, phone, isBrowser]);

  return (
    <div className={twMerge('relative z-10 ', className)}>
      <UpdatedInputLabel
        label={label}
        name={name}
        required={required}
        customLabel={customLabel}
      />
      <div className="grid w-full grid-cols-7 gap-2">
        {isBrowser && (
          <UpdatedSelect
            disabled={disabled}
            placeholder="+64"
            className="col-span-7 text-base sm:col-span-2"
            onChange={setDialCode}
            options={data?.dial_code}
            value={dc}
          />
        )}
        <UpdatedInput
          value={phone}
          type="tel"
          className="col-span-7 mb-4 sm:col-span-5"
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            if (e.target.value.startsWith('0'))
              return alert.alert({
                key: 'INPUT_PHONE',
                type: 'warning',
                message: 'leading 0s will be ignored',
                dismissable: true,
                duration: 3000,
              });
            setPhone(e.target.value);
          }}
        />
      </div>
      {!noValidation && <Errors error={error} />}
    </div>
  );
}
