import { captureException } from '@sentry/nextjs';
import { default as Pica } from 'pica';
import { MutableRefObject } from 'react';
import type { Area } from 'react-easy-crop/types';

const pica = new Pica();

const FILL_COLOR = '#333';
const CANVAS_MAX = 2048;

export const transform = async (
  file: File,
  resultRef: MutableRefObject<File | undefined>,
  cropPixels: Area,
  rotate: number
): Promise<void> => {
  if (!file || !cropPixels) return;
  const naturalImg = document.querySelector(
    '.reactEasyCrop_Image'
  ) as HTMLImageElement;
  const { naturalWidth, naturalHeight } = naturalImg;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  // create a max canvas to cover the source image after rotated
  const maxLen = Math.sqrt(
    Math.pow(naturalWidth, 2) + Math.pow(naturalHeight, 2)
  );
  canvas.width = maxLen;
  canvas.height = maxLen;

  // rotate the image
  if (rotate > 0 && rotate < 360) {
    const halfMax = maxLen / 2;
    ctx.translate(halfMax, halfMax);
    ctx.rotate((rotate * Math.PI) / 180);
    ctx.translate(-halfMax, -halfMax);
  }

  ctx.fillStyle = FILL_COLOR;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // draw the source image in the center of the max canvas
  const left = (maxLen - naturalWidth) / 2;
  const top = (maxLen - naturalHeight) / 2;

  const { width, height, x, y } = cropPixels ?? {};

  ctx.drawImage(naturalImg, left, top);

  // shrink the max canvas to the crop area size, then align two center points
  const maxImgData = ctx.getImageData(0, 0, maxLen, maxLen);
  canvas.width = width;
  canvas.height = height;

  // fill the background once again
  ctx.fillStyle = FILL_COLOR;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const putX = Math.round(-left - x);
  const putY = Math.round(-top - y);
  if (isNaN(putX) || isNaN(putY)) return;
  ctx.putImageData(maxImgData, putX, putY);

  // get the new image
  const { name } = file;

  return canvas.toBlob(
    async (blob) => {
      if (!blob) return;
      resultRef.current = new File([blob], name, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
    },
    'image/jpeg',
    0.7
  );
};

const loadImage = (img: HTMLImageElement) =>
  new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = () => reject(img);
  });

export const fileReduce = async (file: File): Promise<File | void> => {
  try {
    // load image
    const img = document.createElement('img');
    const image_url = URL.createObjectURL(file);
    img.src = image_url;

    // await load for dimensions
    await loadImage(img);

    // create natural size canvas
    const natural = document.createElement('canvas');
    const naturalctx = natural.getContext('2d') as CanvasRenderingContext2D;
    const { width, height } = img;
    natural.width = width;
    natural.height = height;
    naturalctx.drawImage(img, 0, 0, width, height);

    // get aspect ratio scaling resize canvas
    const isPortrait = height > width;
    const aspect = width / height;

    // paint resize canvas with correct aspect
    const resCanvas = document.createElement('canvas');
    resCanvas.width = CANVAS_MAX * (isPortrait ? aspect : 1);
    resCanvas.height = CANVAS_MAX / (isPortrait ? 1 : aspect);

    // Process resize event
    const resized = await pica.resize(natural, resCanvas);
    const blob = await pica.toBlob(resized, 'image/jpeg', 0.9);

    return new File([blob], file.name, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });
  } catch (err) {
    captureException(err);
  }
};
