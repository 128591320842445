import { twMerge } from 'tailwind-merge';
import React, { useState, VFC } from 'react';
import { Button, SubscriptionController, SubscriptionView } from 'components';
import { ButtonProps } from 'components/Button';
import { getPageSubscriptionPriceItem, useSubscriptions } from 'utils/stripe';

export const SubscribeButton: VFC<ButtonProps> = ({ className }) => {
  const [open, setOpen] = useState<SubscriptionView>();

  const { customerSubscriptions, loading } = useSubscriptions();

  // sanity check if customer is already subscribed to prevent duplicate
  const customerPlan = getPageSubscriptionPriceItem(
    customerSubscriptions.page
  )?.id;

  const onClose = () => setOpen(undefined);
  const openDrawer = () => setOpen(customerPlan ? 'warning' : 'drawer');
  const openModal = () => setOpen(customerPlan ? 'warning' : 'modal');

  return (
    <>
      <Button
        color="primary"
        size="small"
        className={twMerge(className, 'sm:hidden')}
        onClick={openDrawer}
        disabled={loading}
      >
        Subscribe to Enable
      </Button>
      <Button
        color="primary"
        size="small"
        className={twMerge(className, 'hidden sm:block')}
        onClick={openModal}
        disabled={loading}
      >
        Subscribe to Enable
      </Button>

      <SubscriptionController {...{ open, onClose }} />
    </>
  );
};
