import { UrlObject } from 'url';
import { useRouter } from 'next/router';
import { createContext, FC, useContext, useEffect, useState } from 'react';

type HistoryEntry = UrlObject;

type HistoryContext = {
  history: HistoryEntry[];
  // push: Dispatch<SetStateAction<HistoryEntry[]>>;
  // pop: Dispatch<SetStateAction<HistoryEntry[]>>;
  get: (n?: number) => HistoryEntry | undefined;
};

const HistoryContext = createContext<HistoryContext>({
  history: [],
  get: () => undefined,
});

export const useHistoryContext = () => useContext(HistoryContext);

export const HistoryProvider: FC = ({ children }) => {
  const router = useRouter();
  const { pathname, query } = router;
  const [history, setHistory] = useState<HistoryEntry[]>([]);

  // this is a very naive implementation, but it does the job of getting the previous route which is what we need for now
  const push = (entry: HistoryEntry) =>
    setHistory((current) => [...current, entry]);

  useEffect(() => {
    if (pathname) push({ pathname, query });
  }, [pathname, JSON.stringify(query)]);

  const get = (n = 0) => history[history.length - 1 - n];

  return (
    <HistoryContext.Provider value={{ history, get }}>
      {children}
    </HistoryContext.Provider>
  );
};
