import React from 'react';
import Link from 'next/link';

interface JobHeaderProps {
  title: string;
  pageActive: boolean;
  employerSlug: string;
  company?: string;
  category: { label: string };
  locationString: string;
  work: { label: string };
}

const JobHeader: React.FC<JobHeaderProps> = ({
  title,
  pageActive,
  employerSlug,
  company,
  category,
  locationString,
  work,
}) => {
  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <h2 className="m-0 p-0 text-4xl font-bold leading-[57.6px] text-charcoal-500">
          {title}
        </h2>
        {pageActive && (
          <Link
            href={`/directory/${employerSlug}`}
            className="mb-2 text-lg font-medium leading-[28.8px] -tracking-two-percent text-charcoal-400"
          >
            {company}
            <span className="px-2">•</span> {locationString}
          </Link>
        )}
        {!pageActive && (
          <h3 className="mb-2 text-lg font-medium leading-[28.8px] -tracking-two-percent text-charcoal-400">
            {company}
            <span className="px-2">•</span> {locationString}
          </h3>
        )}
      </div>
      <p className="text-base font-medium leading-[25.6px] -tracking-two-percent text-charcoal-300">
        {category.label}
        <span className="px-2 text-charcoal-500"> • </span>
        {work.label}
      </p>
    </div>
  );
};

export default JobHeader;
