// Framework
import { Fragment, useEffect, useState, useMemo } from 'react';

// Third Parties
import { Combobox, Transition } from '@headlessui/react';

// Components
import { CheckIcon } from '@heroicons/react/24/solid';
import { EnumType } from 'types/utils';
import { UpdatedInputLabel } from 'components/forms/UpdatedFormInput';
import CloseIcon from './CloseIcon';

// Types
type MultiSelectInputProps = {
  options: EnumType[];
  defaultValues?: MultiSelectInputProps['value'];
  label: string;
  name: string;
  onChange: (c: MultiSelectInputProps['value']) => void;
  className?: string;
  customLabel?: string;
  value: EnumType['value'][];
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function MultiSelect({
  options,
  label,
  name,
  onChange: updateParentForm,
  className,
  customLabel,
  value = [],
}: MultiSelectInputProps) {
  const [query, setQuery] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setQuery('');
    setInputValue('');
  }, [value]);

  const filteredOptions = useMemo(() => {
    if (!query) return options;
    return options.filter(({ label }) =>
      label.toLowerCase().includes(query.toLowerCase())
    );
  }, [query]);

  const handleRemoval = (category?: string) => {
    updateParentForm(value?.filter((c) => c !== category));
  };

  const selected = useMemo(() => {
    return value.map((v) => {
      return options.find(({ value: optValue }) => optValue === v);
    });
  }, [value]);

  return (
    <div className={className}>
      <UpdatedInputLabel
        label={label}
        name={name}
        required
        customLabel={customLabel}
      />
      <Combobox value={value} onChange={updateParentForm} multiple>
        <div className="relative z-10 mb-6">
          <ul className="relative z-0 mt-2 flex h-auto flex-wrap gap-2 rounded border border-light bg-white p-2 text-black transition-all duration-300 ease-out">
            {selected.map((item) => {
              if (!item) return null;
              return (
                <li
                  className="flex w-max gap-2 rounded bg-[#F8F8F8] px-2 py-1 text-[#0E0900]"
                  key={item?.value}
                >
                  {item?.label}
                  <span
                    className="cursor-pointer"
                    onClick={() => handleRemoval(item?.value)}
                  >
                    <CloseIcon />
                  </span>
                </li>
              );
            })}
            <li className="block w-max">
              <Combobox.Button as="div">
                <Combobox.Input
                  className="block h-8 w-full rounded border-none text-base text-black placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey"
                  name="categories"
                  id="categories"
                  placeholder="Start typing"
                  value={inputValue}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    setInputValue(event.target.value);
                  }}
                />
              </Combobox.Button>
            </li>
          </ul>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute top-full mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map((category) => (
                  <Combobox.Option
                    key={category.value}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-primary-400 text-white' : 'text-gray-900'
                      }`
                    }
                    value={category.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {category.label}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3${
                              active ? 'text-white' : 'text-primary-300'
                            }`}
                          >
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
