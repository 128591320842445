import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { ReactChild, VFC } from 'react';

type Value =
  | boolean
  | string
  | readonly string[]
  | number
  | Date
  | null
  | Record<string, unknown>;

export type RadioOption<V extends Value = Value> = {
  value?: V;
  label: ReactChild;
  className?: string;
};

export const RadioOption: VFC<RadioOption> = ({ value, label, className }) => (
  <RadioGroup.Option<'div', typeof value> {...{ value, className }}>
    {({ active, checked = false }) => (
      <div
        className={twMerge(
          'relative mt-2 inline-flex w-full items-center rounded border bg-white px-4 py-2 h-12',
          checked
            ? 'border-orange-500'
            : active
              ? 'border-charcoal-500'
              : 'border-charcoal-50'
        )}
      >
        <input
          type="radio"
          className={twMerge(
            'mr-2 rounded-full border border-charcoal-50 text-base text-orange-500 shadow-sm placeholder:text-charcoal-500 focus:ring-1 focus:ring-orange-500',
            checked
              ? 'focus:ring-orange-500'
              : active
                ? 'focus:ring-charcoal-500'
                : ''
          )}
          checked={checked}
          onChange={(e) => null}
        />
        <label
          className={twMerge(
            'w-full text-sm tracking-wider',
            checked ? 'text-orange-500' : 'text-charcoal-200'
          )}
        >
          {label}
        </label>
      </div>
    )}
  </RadioGroup.Option>
);
