import { twMerge } from 'tailwind-merge';
import React, {
  FC,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  SVGProps,
  VFC,
} from 'react';
import { AddIcon } from 'components/icons';

export type ActionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  icon?: VFC<SVGProps<SVGSVGElement>>;
  text: string;
};

export const ActionButton: FC<ActionButtonProps> = ({
  text,
  icon: Icon,
  className,
  onClick,
  ...props
}) => (
  <button
    {...props}
    onClick={(e) => {
      e.preventDefault();
      onClick && onClick(e);
    }}
    className={twMerge(
      'link-black flex items-center rounded px-4 uppercase',
      className
    )}
  >
    {Icon && <Icon className="mr-2 flex w-4 justify-center" />}
    {text}
  </button>
);

export const AddButton: VFC<Omit<ActionButtonProps, 'text' | 'icon'>> = (
  props
) => <ActionButton text="Add" icon={AddIcon} {...props} />;
