import { twMerge } from 'tailwind-merge';
import { forwardRef, ReactNode, TextareaHTMLAttributes } from 'react';
import { Errors, InputLabel, useFieldError } from '..';

export type TextAreaProps = TextareaHTMLAttributes<Element> & {
  className?: string;
  label?: ReactNode;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextAreaRef({ className, label, required, ...props }, ref) {
    const { error } = useFieldError(props?.name);
    return (
      <div className={twMerge('relative', className, error ? 'mb-2' : 'mb-8')}>
        <InputLabel label={label} name={props?.name} required={required} />
        <div className="mt-1">
          <textarea
            ref={ref as any}
            id={props?.name}
            className={twMerge(
              'block w-full rounded border border-light py-4 pl-6 pr-10 text-base shadow-sm placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey',
              error && 'border-red-500 focus:border-red-700 focus:ring-red-500'
            )}
            {...props}
          />
        </div>
        <Errors error={error} />
      </div>
    );
  }
);
