import * as yup from 'yup';
import { ApplicationQuestionProps } from 'components/forms/ApplicationQuestions/ApplicationQuestions';
import { phoneValidation } from 'utils/forms/validation';

const validBool = yup.boolean().required('please select one');

const makeValidation = (
  questions: ApplicationQuestionProps['questions'] = []
) =>
  yup.object({
    phone: phoneValidation,
    application: yup.object({
      id: yup.string().nullable(),
      job_id: yup.string().required(),
      answers: yup
        .object()
        .shape(
          questions.reduce((res, { id }) => ({ [id]: validBool, ...res }), {})
        ),
    }),
    user_experience: yup
      .object({
        title: yup.string().nullable(),
        company: yup.string().nullable(),
        start_date: yup.string().nullable(),
        end_date: yup.string().nullable(),
        current: yup.string().nullable(),
        description: yup.string().nullable(),
      })
      .nullable(),
  });

export default makeValidation;
