import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import { VFC } from 'react';
import { Pill } from 'components/Pill';
import { JobActionsDropdown } from 'components/pages/PortalEmployer/JobActionsDropdown';
import { Job } from 'database/types';

type JobPageActionsProps = {
  employerId: string;
  jobId: string;
  status: string;
  listing_type: Job['listing_type'];
};

export const PageActions: VFC<JobPageActionsProps> = ({
  status,
  employerId,
  jobId,
  listing_type,
}) => {
  const router = useRouter();
  const { pathname } = router;
  const isPreview = pathname.includes('/preview');

  const options =
    status === 'draft'
      ? [
          {
            disabled: true,
            label: (
              <div className="flex flex-row items-center">
                <span
                  className="inline-block size-2 shrink-0 rounded-full bg-gray-500"
                  aria-hidden="true"
                />
                <span className="ml-2 hidden text-base sm:block">Draft</span>
              </div>
            ),
            value: 'draft',
          },
        ]
      : status === 'expired'
        ? [
            {
              label: (
                <div className="flex flex-row items-center">
                  <span
                    className="inline-block size-2 shrink-0 rounded-full bg-red-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2 hidden text-base sm:block">
                    Expired
                  </span>
                </div>
              ),
              value: 'expired',
            },
          ]
        : [
            {
              label: (
                <div className="flex flex-row items-center">
                  <span
                    className="inline-block size-2 shrink-0 rounded-full bg-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2 hidden text-base sm:block">Active</span>
                </div>
              ),
              value: 'active',
            },
            {
              label: (
                <div className="flex flex-row items-center">
                  <span
                    className="inline-block size-2 shrink-0 rounded-full bg-yellow-500"
                    aria-hidden="true"
                  />
                  <span className="ml-2 hidden text-base sm:block">
                    Archived
                  </span>
                </div>
              ),
              value: 'archived',
            },
          ];
  return (
    <div className="inline-flex w-full flex-nowrap items-center">
      <Pill>{options.find(({ value }) => value === status)?.label}</Pill>
      <div className="-mr-4">
        <JobActionsDropdown
          listing_type={listing_type}
          className={twMerge(isPreview && status === 'expired' && 'hidden')}
          employerId={employerId}
          jobId={jobId}
          status={status}
          removeManager
        />
      </div>
    </div>
  );
};
