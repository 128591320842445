import { SubscriptionPrice } from 'utils/stripe';

export const makeLabel = ({ nickname, unit_amount }: SubscriptionPrice) => {
  const value = unit_amount && unit_amount / 100;
  const valueFormatted = value?.toLocaleString('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return `${nickname} - ${valueFormatted}`;
};
