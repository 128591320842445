import { captureException } from '@sentry/nextjs';
import { Stripe as StripeJS, StripeCardElement } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import Stripe from 'stripe';
import useSWR from 'swr';
import { useMessages } from 'components';
import { useAxiosSWR, useCustomer } from 'utils/stripe';

export const usePaymentMethods = () => {
  const router = useRouter();
  const { employerId } = router.query;

  const { alert } = useMessages();
  const customerEndpoint = `/api/stripe/customer/${employerId}`;

  //
  // DATA FROM CUSTOMER OBJECT
  //

  const {
    customer,
    update: updateCustomer,
    loading: customerLoading,
    mutate: mutateCustomer,
  } = useCustomer();

  // defaultId getter and setter
  const getDefault = () => customer?.invoice_settings?.default_payment_method;
  const [defaultId, setDefaultId] = useState<Stripe.PaymentMethod['id']>();
  useEffect(() => setDefaultId(getDefault()?.id), [getDefault()?.id]);

  const setDefault = useCallback(
    async (id?: string) => {
      await updateCustomer({
        invoice_settings: { default_payment_method: id },
      });
      alert({
        key: 'DefaultCardUpdateSuccess',
        title: 'Default Card Updated!',
        type: 'success',
        dismissable: true,
        duration: 3000,
      });
    },
    [updateCustomer]
  );

  //
  // DATA FROM PAYMENT_METHODS OBJECT
  //

  const axiosSWR = useAxiosSWR();
  const paymentMethodsEndpoint = `${customerEndpoint}/payment_methods`;

  const {
    data: paymentMethods,
    mutate,
    error,
  } = useSWR<Stripe.PaymentMethod[]>(
    employerId ? paymentMethodsEndpoint : null,
    axiosSWR.get
  );

  const loading = (!paymentMethods && !error) || customerLoading;

  const { data: setupIntent, mutate: refreshSecret } =
    useSWR<Stripe.SetupIntent>(
      employerId ? `${paymentMethodsEndpoint}/createSetupIntent` : null,
      axiosSWR.get
    );
  const clientSecret = setupIntent?.client_secret;

  const addCard = useCallback(
    async (
      stripeJS: StripeJS,
      card: StripeCardElement,
      shouldSetDefault?: boolean
    ) => {
      try {
        if (!clientSecret) {
          alert({
            key: 'ALERT_SecretNotReady',
            type: 'error',
            title: 'Please Try again',
          });
          await refreshSecret();
          return;
        }

        const response = await stripeJS.confirmCardSetup(clientSecret, {
          payment_method: { card },
        });

        refreshSecret(); // ready for next use

        if (response.setupIntent) {
          mutate(); // refresh and update hook state

          // set card as default if no other exists
          if (shouldSetDefault || !defaultId) {
            const newCardId = response.setupIntent.payment_method;
            typeof newCardId === 'string' && setDefault(newCardId);
          } else mutateCustomer();

          alert({
            key: 'AddCardSuccess',
            title: 'Card Added!',
            type: 'success',
            dismissable: true,
          });
        }

        return response;
      } catch (err) {
        console.error(err);
        alert({
          key: 'AddCardError',
          title: 'There was a problem saving your card.',
          type: 'warning',
        });
        captureException(err);
      }
    },
    [clientSecret, defaultId]
  );

  const detach = useCallback(
    async (id: string) => {
      if (!employerId) return;
      try {
        const newData = await axiosSWR.get<Stripe.PaymentMethod[]>(
          `${paymentMethodsEndpoint}/${id}/detach`
        );

        mutate();

        // if default was removed, set any other card as default if possible
        if (id === defaultId) {
          const newDefault = paymentMethods?.find((card) => card.id !== id);
          setDefault(newDefault?.id);
        } else mutateCustomer();

        alert({
          key: 'DetachCardSuccess',
          title: 'Card Removed!',
          type: 'success',
          dismissable: true,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [employerId, defaultId, paymentMethods]
  );

  return {
    paymentMethods,
    mutate,
    defaultId,
    defaultCard: getDefault(),
    setDefault,
    loading,
    addCard,
    detach,
  };
};
