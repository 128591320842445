import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useJitsu } from '@jitsu/nextjs';
import {
  InsertExperienceDocument,
  UpdateExperienceByPkDocument,
  User_Experience,
} from 'database/types';
import { DateISOString } from 'types/utils';
import { useFormSubmit } from 'utils';
import { ErrorProvider, ModalFormButtons } from '..';
import Validation from './Validation';

import { WorkExperienceBody } from './FormBody';

const DEFAULT_WORK_EXP = {
  id: undefined,
  title: '',
  company: '',
  start_date: '',
  end_date: null,
  current: undefined,
  description: null,
  updated_at: null,
};

export type WorkExpData = {
  id?: string;
  title: string;
  company: string;
  start_date: DateISOString;
  end_date?: DateISOString | null;
  current: boolean;
  description?: string | null;
  updated_at?: DateISOString | null;
};

const formatDefault = ({
  id,
  title,
  company,
  start_date,
  end_date,
  current,
  description,
}: User_Experience): WorkExpData => ({
  ...(id && {
    id,
    updated_at: new Date().toISOString(),
  }),
  title,
  company,
  start_date,
  end_date,
  current,
  description,
});

type WorkExperienceProps = {
  defaultValues?: User_Experience;
  onCancel: () => void;
};

export const WorkExperience: FC<WorkExperienceProps> = ({
  defaultValues = DEFAULT_WORK_EXP,
  onCancel,
}) => {
  const isUpdate = !!defaultValues?.id;
  const initialValues = formatDefault(defaultValues as User_Experience);
  const methods = useForm<WorkExpData>({
    defaultValues: initialValues,
    resolver: yupResolver(Validation),
  });
  const { track } = useJitsu();

  const onSubmit = useFormSubmit({
    formName: 'WorkExperience',
    mutation: isUpdate
      ? UpdateExperienceByPkDocument
      : InsertExperienceDocument,
    onSuccess: {
      message: `Work experience ${initialValues.id ? 'updated' : 'saved'}`,
      callback: onCancel,
    },
  });

  const { control, formState, handleSubmit, register, setValue } = methods;

  return (
    <FormProvider {...methods}>
      <form
        id="work_experience"
        onSubmit={handleSubmit((vars) => {
          onSubmit(vars);
          track('experience_added');
        })}
        className="my-6"
      >
        <ErrorProvider errors={formState.errors}>
          <WorkExperienceBody
            control={control}
            register={register}
            setValue={setValue}
          />
          <ModalFormButtons onCancel={onCancel} />
        </ErrorProvider>
      </form>
    </FormProvider>
  );
};
