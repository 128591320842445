import { useRouter } from 'next/router';
import { normalize } from 'normalizr';
import { useEffect, useState } from 'react';
import Stripe from 'stripe';
import useSWR from 'swr';
import { prices as priceSchema } from 'utils/schemas/prices';
import {
  AddonOptionsNormal,
  useAxiosSWR,
  ListingsMeta,
  PageSuscriptionWithInterval,
  StripePriceWithMeta,
  SubscriptionMeta,
  SubscriptionPlan,
} from 'utils/stripe';

type SubscriptionsNormalized = Record<
  Exclude<SubscriptionPlan, 'page'> | PageSuscriptionWithInterval,
  StripePriceWithMeta<SubscriptionMeta>
>;

export type StripePricesNormalized = {
  listings: Record<ListingsMeta['level'], StripePriceWithMeta<ListingsMeta>>;
  subscriptions: SubscriptionsNormalized;
  addons: AddonOptionsNormal;
  tiers: Record<string, Stripe.Price.Tier>;
};

type PricesHook = {
  prices?: StripePricesNormalized;
  raw?: StripePriceWithMeta[];
  loading: boolean;
};

export const usePrices = (): PricesHook => {
  const router = useRouter();
  const { employerId } = router.query;

  const axiosSWR = useAxiosSWR();
  const pricesEndpoint = '/api/stripe/fetch';

  const { data: raw, error } = useSWR<StripePriceWithMeta[]>(
    employerId ? [pricesEndpoint, employerId] : null,
    () => axiosSWR.post(pricesEndpoint, { employerId })
  );
  const loading = !raw && !error;

  const [prices, setPrices] = useState<StripePricesNormalized>(getNormals(raw));
  useEffect(() => setPrices(getNormals(raw)), [raw]);

  return { prices, raw, loading };
};

const getNormals = (raw?: StripePriceWithMeta[]) =>
  normalize(raw ?? [], priceSchema).entities as StripePricesNormalized;
