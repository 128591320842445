import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { Item } from './Item';

type CardProps = PropsWithChildren<{
  transparent?: boolean;
}>;

export const CardDefault: FC<CardProps> = ({
  children,
  transparent = false,
}) => (
  <div
    className={twMerge(
      'flex w-full flex-col space-y-2',
      transparent
        ? 'border-none bg-none px-8 py-2'
        : 'rounded-md border border-black-200 bg-white p-8'
    )}
  >
    {children}
  </div>
);

type CardInternalType = typeof CardDefault;
interface CardInterface extends CardInternalType {
  Item: typeof Item;
}
const Card = CardDefault as CardInterface;
Card.Item = Item;

export { Card };
