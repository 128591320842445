import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import Stripe from 'stripe';
import useSWR from 'swr';
import { useMessages } from 'components';
import { useAxiosSWR, SubscriptionPrice, useCustomer } from 'utils/stripe';

import {
  ALERT_CancelFailed,
  ALERT_CancelSuccess,
  ALERT_CreateFailed,
  ALERT_CreateSuccess,
  ALERT_RenewalFailed,
  ALERT_RenewalSuccess,
  ALERT_RequestError,
  ALERT_UpdateFailed,
  ALERT_UpdateSuccess,
} from './constants';
import { getPageItems, makeCustomerSubs } from './utils';

export const useSubscriptions = () => {
  const router = useRouter();
  const { employerId } = router.query;
  const { alert } = useMessages();

  const {
    customer,
    mutate: mutateCustomer,
    loading: customerLoading,
  } = useCustomer();

  const axiosSWR = useAxiosSWR();
  const subscriptionPricesEndpoint = '/api/stripe/subscriptions';
  const customerSubscriptionEndpoint = `/api/stripe/customer/${employerId}/subscriptions`;

  const { data: items, error } = useSWR<SubscriptionPrice[]>(
    employerId ? [subscriptionPricesEndpoint, employerId] : null,
    async (url: string) => axiosSWR.post(url, { employerId })
  );
  const loading = (!items && !error) || customerLoading;

  const create = useCallback(
    async (items: Stripe.SubscriptionCreateParams['items']) => {
      try {
        const newSub = await axiosSWR.post<Stripe.Subscription>(
          `${customerSubscriptionEndpoint}/create`,
          { items }
        );

        if (newSub.id) alert(ALERT_CreateSuccess);
        else alert(ALERT_CreateFailed);

        mutateCustomer();

        return newSub;
      } catch (err) {
        alert(ALERT_RequestError);
        captureException(err);
      }
    },
    [employerId]
  );

  const update = useCallback(
    async (
      subscriptionId: string,
      items: Stripe.SubscriptionUpdateParams['items']
    ) => {
      try {
        const updatedSub = await axiosSWR.post<Stripe.Subscription>(
          `${customerSubscriptionEndpoint}/${subscriptionId}/update`,
          { items }
        );
        mutateCustomer();

        // checks that the price returned is the same as passed
        // i.e. the update worked
        if (updatedSub.items.data[0].price.id === items?.[0].price)
          alert(ALERT_UpdateSuccess);
        else alert(ALERT_UpdateFailed);

        return updatedSub;
      } catch (err) {
        console.error(err);
        alert(ALERT_RequestError);
      }
    },
    [employerId]
  );

  const cancel = useCallback(
    async (subscriptionId: string) => {
      try {
        const updatedSub = await axiosSWR.post<Stripe.Subscription>(
          `${customerSubscriptionEndpoint}/${subscriptionId}/cancel`
        );
        mutateCustomer();

        if (updatedSub.cancel_at_period_end) alert(ALERT_CancelSuccess);
        else alert(ALERT_CancelFailed);

        return updatedSub;
      } catch (err) {
        console.error(err);
        alert(ALERT_RequestError);
      }
    },
    [employerId]
  );

  const renew = useCallback(
    async (subscriptionId: string) => {
      try {
        const updatedSub = await axiosSWR.post<Stripe.Subscription>(
          `${customerSubscriptionEndpoint}/${subscriptionId}/renew`
        );
        mutateCustomer();

        if (!updatedSub.cancel_at_period_end) alert(ALERT_RenewalSuccess);
        else alert(ALERT_RenewalFailed);

        return updatedSub;
      } catch (err) {
        alert(ALERT_RequestError);
      }
    },
    [employerId]
  );

  return {
    pageSubscriptionItems: getPageItems(items ?? []),
    customerSubscriptions: makeCustomerSubs(customer?.subscriptions?.data),
    items,
    fetch,
    create,
    update,
    renew,
    cancel,
    loading: loading || customerLoading,
  };
};
