import { captureException } from '@sentry/nextjs';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { confirmResetPassword, signIn } from 'aws-amplify/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from 'components/Alert';
import { ErrorProvider, Input, CodeInput } from '..';
import { Button } from '../..';

import Validation from './Validation';

export type VerifyResetData = {
  new_password: string;
  code: string;
};

type VerifyResetProps = {
  code: string;
  email: string;
  path?: string;
};

export const VerifyReset: FC<VerifyResetProps> = ({ code, email, path }) => {
  const router = useRouter();
  const [error, setError] = useState<string>('');
  const { control, formState, handleSubmit, register } =
    useForm<VerifyResetData>({
      defaultValues: {
        new_password: '',
        code,
      },
      resolver: yupResolver(Validation),
    });

  async function onSubmit({ code, new_password }: VerifyResetData) {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: new_password,
      });
      await signIn({ username: email, password: new_password });
      path && router.push(path);
    } catch (error) {
      if (error instanceof Error) {
        setError(error?.message);
      }
      captureException(error);
    }
  }
  return (
    <form
      id="reset_password"
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-4"
    >
      <p>
        Reset account: <b>{email}</b>
      </p>
      <Alert type="error" title={error} />
      <ErrorProvider errors={formState.errors}>
        <CodeInput
          {...register('code')}
          code={code}
          required
          control={control}
          type="text"
          name="code"
          label="Enter verification code"
          placeholder="Repeat password"
        />
        <Input
          {...register('new_password')}
          required
          autoCorrect="false"
          autoCapitalize="false"
          type="password"
          label="New password"
          name="new_password"
          placeholder="New password"
        />
        <Button type="submit" color="black" loading={formState.isSubmitting}>
          Submit
        </Button>
      </ErrorProvider>
    </form>
  );
};
