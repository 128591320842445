export const appRoutes = {
  protected: {
    EMPLOYEE: '/employee',
    EMPLOYEE_PROFILE: '/employee/profile',
    EMPLOYEE_SETTINGS: '/employee/settings',
    APPLY_ONE: '/job/[listing]/apply/1',
    APPLY_TWO: '/job/[listing]/apply/2',
    PORTAL_SUBPATH: /\/portal\/.+/,
  },
  public: {
    ROOT: '/',
    // Rest
    ABOUT: '/about',
    APPLY: '/job/[listing]/apply',
    CONTACT: '/contact',
    EMPLOYERS: '/employers',
    FORGOT: '/auth/forgot',
    JOBS: '/[...jobs]',
    JOB: '/job/[listing]',
    LOGIN: '/auth',
    PRIVACY: '/privacy',
    PORTAL_PRICING: '/portal/pricing',
    PORTAL: '/portal',
    CHECKOUT: '/portal/checkout',
    CHECKOUT_BUNDLE: '/portal/checkout/[bundle]',
    SURVEYS: '/portal/survey',
    SURVEY_2023: '/portal/survey/2023',
    PORTAL_AUTH: /\/portal\/(register|auth\/forgot|auth\/signout)/,
    REGISTER: '/register',
    TERMS: '/terms',
    TRAINING: '/training',
    TRAINING_CATEGORAY: '/training/[category]',
  },
  404: {
    ORGANISATION: '/organisation',
    ORGANISATION_APPLICATIONS: '/organisation/applications',
    ORGANISATION_PROFILE: '/organisation/profile',
    ORGANISATION_SETTINGS: '/organisation/settings',
  },
};
