import { twMerge } from 'tailwind-merge';
import { FC } from 'react';
import { ColorSubset } from 'styles/types';

export type LogoProps = {
  color?: ColorSubset<'black' | 'white'>;
  className?: string;
};

export const Logo: FC<LogoProps> = ({ color = 'white', className = '' }) => (
  <div
    className={twMerge(
      'h-full',
      color === 'black'
        ? 'text-black focus:ring-black'
        : color === 'white'
          ? 'text-white focus:ring-white'
          : '',
      className
    )}
  >
    <svg
      width="189"
      height="56"
      viewBox="0 0 189 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-auto"
    >
      <path
        d="M8.07703 25.3542V2.96015H0.00305176V0.233154H19.168V2.96015H11.094V25.3542H8.07703Z"
        fill={color}
      />
      <path
        d="M23.0031 25.3541V0.233054H33.16C34.5714 0.203894 35.9726 0.479137 37.268 1.04005C38.3937 1.52907 39.3581 2.32641 40.05 3.34006C40.7397 4.4012 41.0906 5.64694 41.056 6.91206V7.30705C41.1338 8.70957 40.7141 10.0945 39.871 11.2181C39.1502 12.1269 38.1757 12.8011 37.071 13.1551V13.6551C37.8645 13.6518 38.6289 13.9536 39.2061 14.4981C39.5055 14.8221 39.7349 15.2045 39.8799 15.6212C40.025 16.0379 40.0825 16.4801 40.049 16.92V25.3541H37.071V17.2801C37.0909 16.9785 37.0484 16.6761 36.9462 16.3917C36.844 16.1073 36.6843 15.847 36.4771 15.6271C35.9844 15.2103 35.3474 15.0044 34.704 15.0541H26.018V25.3541H23.0031ZM26.019 12.3271H32.873C34.2518 12.4178 35.6147 11.9898 36.694 11.1271C37.1504 10.7006 37.5076 10.179 37.7402 9.59925C37.9728 9.0195 38.0751 8.39573 38.04 7.77206V7.52006C38.0751 6.89785 37.9745 6.27545 37.7452 5.69598C37.5159 5.1165 37.1634 4.5938 36.712 4.16405C35.6281 3.29757 34.2585 2.86934 32.8741 2.96405H26.0201L26.019 12.3271Z"
        fill={color}
      />
      <path
        d="M43.6741 25.3542L51.4261 0.233154H56.7361L64.4881 25.3542H61.3641L59.4281 18.9992H48.7351L46.7961 25.3531L43.6741 25.3542ZM49.4871 16.2742H58.6381L54.3321 2.06216H53.8321L49.4871 16.2742Z"
        fill={color}
      />
      <path
        d="M67.072 25.3542V22.6632H70.584V2.92416H67.067V0.233154H76.867C80.167 0.233154 82.721 1.07082 84.529 2.74615C86.337 4.42149 87.24 6.99315 87.238 10.4612V15.1272C87.238 18.6185 86.335 21.1958 84.529 22.8592C82.723 24.5225 80.169 25.3558 76.867 25.3592L67.072 25.3542ZM73.604 22.6272H76.869C78.8757 22.7743 80.86 22.128 82.395 20.8272C83.6377 19.6325 84.259 17.7788 84.259 15.2662V10.3482C84.259 7.79015 83.6377 5.92349 82.395 4.74815C80.8461 3.47699 78.8673 2.85071 76.869 2.99915H73.604V22.6272Z"
        fill={color}
      />
      <path
        d="M92.048 25.3542V0.233154H107.515V2.96015H95.0641V11.3602H106.511V14.0872H95.0641V22.6292H107.696V25.3562L92.048 25.3542Z"
        fill={color}
      />
      <path
        d="M77.6731 55.3281C76.5955 55.4296 75.5089 55.2909 74.4914 54.9219C73.4739 54.5528 72.5509 53.9627 71.7889 53.1939C71.027 52.4252 70.4451 51.497 70.0851 50.4763C69.7251 49.4556 69.596 48.3677 69.7071 47.2911V45.3551H72.6851V47.2941C72.6264 48.6685 73.066 50.018 73.9231 51.0941C74.3906 51.6039 74.9676 52.0011 75.6107 52.2559C76.2538 52.5107 76.9463 52.6164 77.6361 52.5651C78.3024 52.6332 78.9752 52.5363 79.595 52.2827C80.2149 52.0291 80.7627 51.6267 81.1901 51.1111C81.931 49.9789 82.2954 48.6416 82.2311 47.2901V32.4331H77.8161V29.7061H87.9731V32.4331H85.2461V47.2911C85.3951 49.4577 84.7012 51.5981 83.3091 53.2651C82.5673 53.9855 81.682 54.5415 80.7109 54.8969C79.7398 55.2524 78.7048 55.3993 77.6731 55.3281V55.3281Z"
        fill={color}
      />
      <path
        d="M101.036 55.328C99.7299 55.3932 98.4237 55.1967 97.1943 54.7503C95.965 54.3038 94.8372 53.6163 93.8771 52.728C92.0965 50.9947 91.2051 48.465 91.2031 45.139V39.396C91.2031 36.0707 92.0945 33.5403 93.8771 31.805C95.891 30.1238 98.4312 29.2029 101.055 29.2029C103.678 29.2029 106.218 30.1238 108.232 31.805C110.013 33.5383 110.904 36.0687 110.906 39.396V45.139C110.906 48.4643 110.015 50.994 108.232 52.728C107.266 53.6184 106.132 54.307 104.896 54.7534C103.661 55.1999 102.348 55.3952 101.036 55.328ZM101.036 52.637C101.971 52.6933 102.906 52.548 103.779 52.2108C104.653 51.8737 105.443 51.3526 106.097 50.683C107.368 49.1663 108.01 47.2203 107.89 45.245V39.289C108.01 37.3144 107.368 35.3691 106.097 33.853C104.72 32.5942 102.921 31.8961 101.055 31.8961C99.1891 31.8961 97.3906 32.5942 96.0131 33.853C94.7399 35.3681 94.0957 37.3135 94.2131 39.289V45.245C94.0954 47.2212 94.7396 49.1673 96.0131 50.683C96.661 51.3502 97.445 51.87 98.3118 52.207C99.1785 52.544 100.108 52.6904 101.036 52.636V52.637Z"
        fill={color}
      />
      <path
        d="M113.849 54.8271V52.1361H117.365V32.3971H113.848V29.7061H126.443C127.783 29.6819 129.112 29.9516 130.337 30.4961C131.411 30.9697 132.33 31.7338 132.993 32.7021C133.639 33.6871 133.97 34.8447 133.944 36.0221V36.3091C133.991 37.2156 133.804 38.119 133.401 38.9325C132.998 39.746 132.393 40.4425 131.644 40.9551C131.08 41.3461 130.464 41.6545 129.813 41.8711V42.3711C130.478 42.5448 131.109 42.8298 131.679 43.2141C132.357 43.6678 132.917 44.2766 133.312 44.9901C133.786 45.884 134.016 46.887 133.978 47.8981V48.2581C134.02 49.483 133.691 50.6921 133.035 51.727C132.378 52.7619 131.424 53.5744 130.298 54.0581C129.07 54.5905 127.742 54.8538 126.404 54.8301L113.849 54.8271ZM120.379 40.7941H126.192C127.435 40.8577 128.659 40.4691 129.638 39.7001C130.059 39.349 130.395 38.9063 130.619 38.4058C130.843 37.9052 130.95 37.3601 130.931 36.8121V36.4521C130.953 35.8999 130.85 35.3499 130.629 34.8434C130.408 34.3368 130.076 33.8868 129.656 33.5271C128.673 32.7528 127.441 32.3638 126.192 32.4331H120.379V40.7941ZM120.379 52.0941H126.086C127.39 52.1831 128.681 51.7871 129.711 50.9821C130.129 50.6034 130.459 50.1369 130.676 49.616C130.893 49.0952 130.992 48.5328 130.966 47.9691V47.6451C130.992 47.0814 130.893 46.519 130.676 45.9981C130.459 45.4772 130.129 45.0108 129.711 44.6321C128.682 43.8264 127.39 43.4296 126.086 43.5181H120.379V52.0941Z"
        fill={color}
      />
      <path
        d="M147.115 55.3281C145.438 55.3574 143.774 55.0273 142.235 54.3601C140.837 53.7473 139.655 52.7282 138.844 51.4351C137.971 49.9539 137.54 48.2534 137.604 46.5351V45.7801H140.582V46.5351C140.515 47.3979 140.645 48.2647 140.963 49.0699C141.28 49.8751 141.776 50.5977 142.413 51.1831C143.747 52.2374 145.415 52.7782 147.113 52.7071C148.719 52.8185 150.312 52.347 151.598 51.3791C152.091 50.9754 152.486 50.4654 152.753 49.8873C153.021 49.3092 153.154 48.678 153.143 48.0411C153.186 47.2396 152.937 46.4497 152.443 45.8171C151.907 45.2188 151.236 44.7573 150.486 44.4711C149.531 44.0875 148.547 43.7811 147.543 43.5551L145.676 43.0871C144.342 42.7499 143.045 42.2809 141.803 41.6871C140.753 41.1993 139.845 40.4521 139.164 39.5161C138.493 38.4891 138.16 37.2778 138.213 36.0521C138.18 34.752 138.55 33.4735 139.272 32.3921C140.016 31.3321 141.05 30.5094 142.25 30.0231C143.656 29.4498 145.164 29.169 146.682 29.1981C148.252 29.1732 149.81 29.4728 151.258 30.0781C152.549 30.6138 153.657 31.5107 154.45 32.6611C155.269 33.9377 155.676 35.4347 155.617 36.9501V38.4571H152.639V36.9521C152.689 35.9155 152.42 34.8884 151.869 34.0091C151.344 33.2596 150.606 32.6848 149.751 32.3591C148.772 31.9851 147.73 31.8025 146.682 31.8211C145.271 31.7524 143.876 32.1357 142.698 32.9151C142.208 33.2652 141.814 33.7322 141.551 34.2736C141.288 34.815 141.164 35.4137 141.191 36.0151C141.156 36.7852 141.384 37.5444 141.837 38.1681C142.326 38.7616 142.954 39.2242 143.666 39.5141C144.583 39.9023 145.532 40.2089 146.503 40.4301L148.367 40.8951C149.721 41.1731 151.039 41.6 152.299 42.1681C153.393 42.6568 154.348 43.4097 155.078 44.3591C155.813 45.4139 156.179 46.6819 156.119 47.9661C156.15 49.3403 155.762 50.6914 155.007 51.8401C154.225 52.9753 153.136 53.8651 151.868 54.4061C150.368 55.0483 148.747 55.3627 147.115 55.3281V55.3281Z"
        fill={color}
      />
      <path
        d="M162.556 45.7471V29.7061H166.289L171.881 44.5321H172.181V29.7061H174.081V45.7471H170.346L164.777 30.8991H164.458V45.7471H162.556Z"
        fill="#D9750C"
      />
      <path
        d="M177.082 45.747V43.204L186.022 31.699V31.425H177.359V29.707H187.924V32.25L178.986 43.755V44.029H188.104V45.747H177.082Z"
        fill="#D9750C"
      />
    </svg>
  </div>
);
