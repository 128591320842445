import * as yup from 'yup';

const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

yup.addMethod(yup.string, 'phone', function () {
  return this.test('phone', 'Phone number is not valid', (value = '') =>
    phoneRegex.test(value)
  );
});

export const phoneValidation = yup
  .string()
  .phone('must be a valid phone number')
  .min(11, 'number appears too short')
  .required('a contact number is required');
