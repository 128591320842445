// Framwork
import Image from 'next/image';

// Third parties
import { twJoin } from 'tailwind-merge';

// Content
import {
  CAMPAIGN_IMAGE,
  CARDS_TEXT,
} from 'components/selfService/PackagesSection/content';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import PackageBackground from 'components/selfService/PackagesSection/icons/PackageBackground';
import { CardComponent } from 'components/selfService/PackagesSection/CardComponent';
import { LottieJobsWeek } from 'components/selfService/PackagesSection/LottieJobsWeek';
import { BelowVector } from './icons';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const PackagesSection = () => (
  <section className="relative z-0 m-0 mx-auto max-w-[1349px] py-24 md:px-6 md:py-36">
    <div className="absolute bottom-0 left-1/2 z-10 flex h-[1589px] w-full -translate-x-1/2 items-start justify-center overflow-hidden">
      <PackageBackground />
    </div>
    <div className="relative z-20">
      <div className="text-center">
        <EmployerText as="h2" size="xl" className="mb-8 text-orange-500">
          Packages
        </EmployerText>
        <EmployerText
          as="h3"
          size="7xl"
          className="mx-auto max-w-[1000px] text-charcoal-500 md:px-0"
        >
          Widen your search, reach more and hire faster with our proven
          packages.
        </EmployerText>
        {/* <div className="mx-auto my-8 block w-max">
          <Link href="/portal/register">
            <EmployerButton size="lg">
              Discover Packages & Pricing
            </EmployerButton>
          </Link>
        </div> */}
        <div className="relative z-0 mx-auto w-max">
          <EmployerText
            as="p"
            size="xl"
            className="mx-auto mb-8 max-w-[220px] font-medium text-orange-500 sm:max-w-none md:max-w-[480px]"
          >
            What's included in our Hi-Vis recruitment packages?
          </EmployerText>
          <span className="absolute -right-8 bottom-4 sm:bottom-0 md:-right-16">
            <BelowVector />
          </span>
        </div>
      </div>
      <div className="grid w-full grid-cols-1 gap-8 px-4 md:px-0 lg:grid-cols-12">
        <div className="col-span-1 lg:col-span-5">
          <CardComponent item={CARDS_TEXT[0]} textReverse>
            <Image
              alt="stats vector"
              src="/assets/images/portal/packages/stats/reach_50.png"
              width={76}
              height={76}
              className="absolute bottom-[17%] right-24 z-20 transition-transform duration-300 ease-in-out"
            />
            <div className="pt-6">
              <Image
                className="-mb-32 transition-transform duration-300 ease-in-out group-hover:scale-110"
                alt="featured listing image"
                width={197}
                height={400}
                src="/assets/images/portal/packages/featured_listing.png"
              />
            </div>
          </CardComponent>
        </div>
        <div className="col-span-1 lg:col-span-7">
          <CardComponent item={CARDS_TEXT[1]}>
            <Image
              alt="stats vector"
              src="/assets/images/portal/packages/stats/reach_vector.png"
              width={76}
              height={76}
              className="absolute left-4 top-[17%] z-20"
            />
            <div className="flex items-end space-x-2">
              {CAMPAIGN_IMAGE.map((image, index) => (
                <div
                  key={index}
                  className={twJoin(image.sizes, 'relative z-0')}
                >
                  <Image
                    alt=""
                    src={image.url}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 60vw, 33vw"
                    className={twJoin(
                      'z-0 object-cover transition-transform duration-300 ease-in-out border border-[#F1C6AD99] rounded-md',
                      index === 0 && 'group-hover:-translate-x-2',
                      index === 1 && 'group-hover:scale-110',
                      index === 2 && 'group-hover:translate-x-2'
                    )}
                    fill
                  />
                </div>
              ))}
            </div>
          </CardComponent>
        </div>
        <div className="col-span-1 lg:col-span-7">
          <CardComponent item={CARDS_TEXT[2]} textReverse>
            <div className="relative z-0 pt-6">
              <Image
                alt="stats vector"
                src="/assets/images/portal/packages/stats/reach_65.png"
                width={76}
                height={76}
                className="absolute bottom-[17%] left-4 z-20"
              />
              <Image
                alt="stats vector"
                src="/assets/images/portal/packages/stats/views_1200.png"
                width={76}
                height={76}
                className="absolute right-10 top-4 z-20"
              />
              <div className="relative -bottom-6 -right-16 z-0 h-[284px] w-[646px]">
                <Image
                  alt=""
                  src="/assets/images/portal/packages/homepage_feature.png"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 60vw, 33vw"
                  className="z-0 object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                  fill
                />
              </div>
            </div>
          </CardComponent>
        </div>
        <div className="col-span-1 lg:col-span-5">
          <CardComponent item={CARDS_TEXT[3]} textReverse>
            <div className="mx-auto pt-6">
              <Image
                className="-mb-32 transition-transform duration-300 ease-in-out hover:scale-110"
                alt="jobs of week"
                width={197}
                height={400}
                src="/assets/images/portal/packages/jobs_of_week.png"
              />
            </div>
          </CardComponent>
        </div>
        <div className="col-span-1 lg:col-span-5">
          <CardComponent item={CARDS_TEXT[4]} textReverse>
            <div className="pt-6">
              <LottieJobsWeek />
            </div>
          </CardComponent>
        </div>
        <div className="col-span-1 lg:col-span-7">
          <CardComponent item={CARDS_TEXT[5]} textReverse>
            <div className="relative -bottom-6 z-0 -mx-6 h-[230px] w-[534px]">
              <Image
                alt=""
                src="/assets/images/portal/radio-image.png"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 60vw, 33vw"
                className="z-0 object-cover transition-transform duration-300 ease-in-out hover:scale-110"
                fill
              />
            </div>
          </CardComponent>
        </div>
      </div>
    </div>
  </section>
);
