import { FC, PropsWithChildren } from 'react';
import { LayoutProps, NavbarEmployer } from 'components';
import { FallbackLoader } from 'components/FallbackLoader';
import { FooterEmployer } from 'components/Footer';
import { PageMeta } from 'components/PageMeta';
import { EmployerContextProvider } from 'components/pages/PortalEmployer';
// import { SurveyBanner } from 'components/SurveyBanner';

export const LayoutEmployer: FC<PropsWithChildren<LayoutProps>> = ({
  navbar = true,
  footer = true,
  children,
  meta,
}) => (
  <EmployerContextProvider>
    <PageMeta {...meta} />
    <div className="flex h-screen flex-col">
      {navbar && <NavbarEmployer />}

      <main className="flex flex-1 flex-col justify-start">
        {/* <SurveyBanner /> */}
        <FallbackLoader>{children}</FallbackLoader>
      </main>

      {footer && <FooterEmployer />}
    </div>
  </EmployerContextProvider>
);
