import { VFC, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import useIsBrowser from 'utils/hooks/useIsBrowser';

const STORAGE_KEY = '__profile_introduction';

export const ProfileIntroduction: VFC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const isBrowser = useIsBrowser();

  useEffect(() => {
    if (isBrowser) {
      const dismissed = localStorage.getItem(STORAGE_KEY);
      setVisible(dismissed !== 'true');
    }
  });
  return (
    <div
      className={twMerge('mb-4 rounded bg-secondary-500', !visible && 'hidden')}
    >
      <div className="mx-auto max-w-7xl p-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-start justify-between">
          <div className="flex w-0 flex-1 items-center">
            <div>
              <h3 className="text-lg font-medium text-white-500">
                Complete your profile
              </h3>
              <div className="mt-2 text-sm text-white-500">
                <ul className="list-inside space-y-1 pl-5 text-secondary-100">
                  <li>Upload an image</li>
                  <li>Check your personal information</li>
                  <li>Add your work experience, education, and licences</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="order-2 shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-secondary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={() => {
                setVisible(false);
                localStorage.setItem(STORAGE_KEY, 'true');
              }}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="size-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
