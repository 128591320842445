// @
import { Dispatch, FC, useState, SetStateAction } from 'react';
import { resetPassword } from 'aws-amplify/auth';
import { captureException } from '@sentry/nextjs';

// Forms
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { Alert } from 'components/Alert';
import { ErrorProvider, Input } from '..';
import { Button } from '../..';

import Validation from './Validation';

type ForgotInfoType = {
  username: string;
  complete: boolean;
  code: string;
};

export type ForgotPasswordData = {
  email: string;
};

type ForgotPasswordProps = {
  setUserInfo: Dispatch<SetStateAction<ForgotInfoType>>;
};

export const ForgotPassword: FC<ForgotPasswordProps> = ({ setUserInfo }) => {
  const [error, setError] = useState<string>('');
  const { formState, handleSubmit, register } = useForm<ForgotPasswordData>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(Validation),
  });

  const onSubmit = async ({ email }: ForgotPasswordData) => {
    try {
      await resetPassword({ username: email });
      setUserInfo({
        username: email,
        code: '',
        complete: true,
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error?.message);
      }
      captureException(error);
    }
  };

  return (
    <form
      id="forgot_password"
      onSubmit={handleSubmit(onSubmit)}
      className="my-6"
    >
      {error && <Alert type="error" title={error} />}
      <ErrorProvider errors={formState.errors}>
        <Input
          {...register('email')}
          required
          autoCapitalize="false"
          label="Email address"
          placeholder="Email address"
        />
        <div className="flex justify-end">
          <Button
            type="submit"
            color="primary"
            loading={formState.isSubmitting}
          >
            Submit
          </Button>
        </div>
      </ErrorProvider>
    </form>
  );
};
