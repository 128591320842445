import { FC, Fragment, PropsWithChildren } from 'react';
import { Menu as MenuDefault, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import { Item } from './Item';

const { Button, Items } = MenuDefault;

export type DropdownProps = PropsWithChildren<{
  className?: string;
  icon?: FC;
}>;

const DropdownInternal: FC<DropdownProps> = ({
  className,
  children,
  icon: Icon = EllipsisVerticalIcon,
}) => (
  <MenuDefault as="div" className="relative inline-block text-left">
    {({ open }) => (
      <>
        <Button
          className={twMerge(
            'flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-secondary-500',
            className
          )}
        >
          <span className="sr-only">Open options</span>
          <Icon className="size-5" aria-hidden="true" />
        </Button>

        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Items
            static
            className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <div className="py-1">{children}</div>
          </Items>
        </Transition>
      </>
    )}
  </MenuDefault>
);

type DropdownType = typeof DropdownInternal;
interface DropdownInterface extends DropdownType {
  Item: typeof Item;
}
const Dropdown = DropdownInternal as DropdownInterface;

Dropdown.Item = Item;

export { Dropdown };
