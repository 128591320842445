import { FC, Fragment, ReactChild, useRef, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { uploadData } from 'aws-amplify/storage';
import Cropper, { type CropperProps } from 'react-easy-crop';
import type { Area } from 'react-easy-crop/types';
import { RotateRight } from 'components/icons';
import PhotoIcon from 'components/register/PhotoIcon';
import CrossIcon from 'components/register/CrossIcon';
import { Button, useMessages } from '..';
import { fileReduce, transform } from './utils';

export type ImageUploadProps = {
  makePublic?: boolean;
  src: string | null;
  level?: 'private' | 'protected' | 'guest';
  handleUpdate?: (key: string) => Promise<any>;
  cropper?: Partial<CropperProps>;
  renderPreview: (previewSrc?: string) => ReactChild;
};

export const ImageUpload: FC<ImageUploadProps> = ({
  src,
  makePublic = false,
  level = 'guest',
  handleUpdate,
  cropper,
  renderPreview,
}) => {
  const { alert } = useMessages();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [image, setImage] = useState<ArrayBuffer | string | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [originalFile, setOriginal] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const fileRef = useRef<File>();
  const ref = useRef<any>();

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = async (e: any) => {
    if (!e.target.files || e.target.files.length < 1) return;
    setLoading(true);
    try {
      const file = await fileReduce(e.target.files[0]);
      if (!file) throw Error('Error reading file');
      const reader = new FileReader();
      reader.addEventListener('load', () => setImage(reader.result));
      reader.readAsDataURL(file);
      setOriginal(file);
    } catch (err) {
      captureException(err);
    } finally {
      setLoading(false);
    }
  };

  const updateRotation = (increment: number) => {
    let next = rotation + increment;
    if (increment === 90 && rotation === 270) {
      next = 0;
    }
    if (increment === -90 && rotation === 0) {
      next = 270;
    }
    setRotation(next);
  };

  const onCropComplete = async (_: Area, croppedPixels: Area) => {
    if (!originalFile) return;
    transform(originalFile, fileRef, croppedPixels, rotation);
  };

  const onSave = async (): Promise<void> => {
    setLoading(true);
    try {
      const upload = fileRef?.current;
      if (!upload) throw Error('Issue uploading file');

      const { key }: any = await uploadData({
        key: `${makePublic ? 'accessible/' : ''}${upload.name}+${upload.lastModified}`,
        data: upload,
        options: {
          accessLevel: level,
          contentType: upload.type,
        },
      }).result;

      alert({
        key: 'Upload',
        type: 'success',
        title: 'Image uploaded',
        duration: 3000,
      });

      handleUpdate && (await handleUpdate(key));

      setPreviewSrc(key);
      setImage(null);
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'Upload',
          type: 'error',
          title: 'Failed to upload image',
          message: err?.message,
          duration: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const [previewSrc, setPreviewSrc] = useState(src);

  return (
    <>
      {previewSrc ? (
        <button
          className="flex w-full cursor-pointer hover:opacity-80"
          onClick={() => inputRef?.current?.click()}
          type="button"
        >
          {renderPreview(previewSrc)}
        </button>
      ) : (
        <button
          onClick={() => inputRef?.current?.click()}
          className="relative z-0 flex size-20 items-center justify-center rounded-full border border-orange-200 bg-orange-50"
          type="button"
          aria-label="Upload a profile photo"
        >
          <PhotoIcon className="size-12" />
          <div className="absolute bottom-0 right-0 z-10 flex size-8 items-center justify-center rounded-full bg-orange-500">
            <CrossIcon className="size-[14px]" />
          </div>
        </button>
      )}
      <input
        ref={inputRef}
        className="hidden"
        accept="image/*"
        multiple={false}
        type="file"
        onChange={onChange}
      />
      <Transition.Root show={!!image} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          open={!!image}
          onClose={() => setImage(null)}
        >
          <div className="flex min-h-screen items-end justify-center p-0 text-center sm:block">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inset-0 inline-block size-full overflow-hidden bg-white p-0 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-3xl sm:rounded-lg sm:p-6 sm:align-middle">
                <div className="flex h-100vh flex-col p-4 md:h-75vh">
                  <div className="relative flex w-full max-w-full grow py-4">
                    <div ref={ref} className="absolute inset-0 block">
                      {!!image && (
                        <Cropper
                          crop={crop}
                          aspect={1}
                          cropShape="rect"
                          objectFit="contain"
                          cropSize={{ width: 280, height: 280 }}
                          image={image as string}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          rotation={rotation}
                          zoom={zoom}
                          onMediaLoaded={(mediaSize) => {
                            // Adapt zoom based on media size to fit max height
                            setZoom(
                              ref?.current?.clientHeight /
                                mediaSize.naturalHeight
                            );
                          }}
                          {...cropper}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 flex gap-4 px-2">
                    <div className="flex shrink flex-col justify-center">
                      <span className="text-3xl text-gray-500">-</span>
                    </div>
                    <div className="flex grow flex-col justify-center">
                      <input
                        className="align-middle"
                        type="range"
                        min="1"
                        max="100"
                        onChange={(e: any) => {
                          const next = 0.6 + (e.target.valueAsNumber / 100) * 2;
                          setZoom(next);
                        }}
                      />
                    </div>
                    <div className="flex shrink flex-col justify-center">
                      <span className="text-3xl text-gray-500">+</span>
                    </div>
                    <button
                      className="inline-flex items-center justify-start rounded-md bg-white text-grey hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      onClick={() => updateRotation(90)}
                    >
                      <RotateRight className="size-10" />
                    </button>
                  </div>
                  <div className="mt-5 flex justify-end gap-3 px-2">
                    <Button
                      color="white"
                      size="small"
                      onClick={() => setImage(null)}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={loading}
                      loading={loading}
                      color="black"
                      size="small"
                      onClick={onSave}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
