import { schema } from 'normalizr';
import Stripe from 'stripe';
import { AddonMeta, StripePriceWithMeta, SubscriptionMeta } from 'utils/stripe';

const subscription = new schema.Entity<StripePriceWithMeta<SubscriptionMeta>>(
  'subscriptions',
  {},
  {
    idAttribute: ({ metadata: { level, billing } }) =>
      level === 'page' ? `page_${billing}` : level,
  }
);

const listing = new schema.Entity<StripePriceWithMeta>(
  'listings',
  {},
  {
    idAttribute: ({ metadata }) => metadata.level,
  }
);

const addon = new schema.Entity<StripePriceWithMeta<AddonMeta>>(
  'addons',
  {},
  { idAttribute: ({ metadata: { subtype, level } }) => `${subtype}_${level}` }
);

export const prices = new schema.Array<StripePriceWithMeta[]>(
  {
    listings: listing,
    subscriptions: subscription,
    addons: addon,
  },
  ({ metadata }) => `${metadata.type}s`
);

export const price = new schema.Entity<StripePriceWithMeta>('prices');
export const coupon = new schema.Entity<Stripe.Coupon>('coupons');
