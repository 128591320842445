import { VFC } from 'react';

export const SkeletonCard: VFC = () => (
  <div className="mx-auto w-full rounded-md border border-gray-300 bg-white p-4 shadow">
    <div className="flex animate-pulse space-x-4">
      <div className="size-12 rounded-full bg-gray-400"></div>
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 w-3/4 rounded bg-gray-400"></div>
        <div className="space-y-2">
          <div className="h-4 rounded bg-gray-400"></div>
          <div className="h-4 w-5/6 rounded bg-gray-400"></div>
        </div>
      </div>
    </div>
  </div>
);
