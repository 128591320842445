import { FC } from 'react';
import Stripe from 'stripe';

import { useInvoice } from 'utils/stripe';

import { Card } from './Card';
import { CartLine } from './CartLine';
import { Discount } from './Discount';
import { DiscountLine } from './DiscountLine';
import { InvoiceItem } from './InvoiceItem';
import { TotalDiscounts } from './TotalDiscounts';

export const Cart: FC = () => {
  const { loading, invoice, promotion, processing } = useInvoice();

  if (!invoice || loading) return null;

  const { total, subtotal, tax, total_discount_amounts } = invoice;

  const discountIds = (invoice?.discounts as Stripe.Discount[])?.map(
    ({ id }) => id
  );
  const discount =
    total_discount_amounts
      ?.filter((td) => discountIds?.includes(td.discount as string))
      .reduce((r, { amount: n }) => (r += n), 0) ?? 0;

  return (
    <Card transparent>
      <Card.Item
        transparent
        title={
          <>
            <span className="text-base">Checkout</span>
            <br />
            <span className="text-xl font-semibold text-black">
              ${((total ?? 0) / 100).toFixed(2)}
            </span>
          </>
        }
      >
        <div className="flex flex-col">
          <div className="ml-8 space-y-2">
            {invoice?.lines?.data.map((lineItem) => {
              const product = lineItem.price?.product as Stripe.Product;
              return (
                <InvoiceItem
                  key={lineItem.id}
                  title={product.name as string}
                  subtitle={lineItem.price?.nickname as string}
                  {...lineItem}
                />
              );
            })}
            {(invoice?.discounts as Stripe.Discount[])?.map((discount, i) => (
              <DiscountLine
                key={i}
                {...discount}
                loading={processing}
                onDelete={() => promotion()}
              />
            ))}
            <div className="w-full border-t border-gray-300" />

            <Discount />
            <CartLine title="Subtotal" price={subtotal} />
            <TotalDiscounts title="Discounts" discount={discount} />
            <div className="w-full border-t border-gray-300" />

            <CartLine alt title="GST (15%)" price={tax} />
            <div className="w-full border-t border-gray-300" />

            <CartLine title="Total" price={total} />
          </div>
        </div>
      </Card.Item>
    </Card>
  );
};
