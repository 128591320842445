import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { captureException } from '@sentry/nextjs';
import { twMerge } from 'tailwind-merge';
import { FC, Fragment } from 'react';
import slugify from 'slugify';
import { Loading } from 'components/icons';
import { Location, useUpsertLocationMutation } from 'database/types';
import { useLocationAPI } from 'utils/useLocationAPI';
import { InputLabel } from 'components/forms/Input';
import { Errors, Input, useFieldError } from '..';

type InputLocationProps = {
  className?: string;
  name?: string;
  noValidation?: boolean;
  onChange: NonNullable<Parameters<typeof Listbox>['0']['onChange']>;
  required?: boolean;
  label: string;
  value?: Omit<Location, '__typename' | 'jobs' | 'jobs_aggregate'>;
  placeholder?: string;
  filterCountries?: boolean;
};

export const InputLocation: FC<InputLocationProps> = ({
  className,
  name,
  noValidation,
  required,
  label,
  onChange,
  placeholder,
  value,
  filterCountries = true,
}) => {
  const { error } = useFieldError(name);

  const { loading, data, searchTerm, setSearchTerm, MIN_TERM_LENGTH } =
    useLocationAPI({
      initialValue: value?.label ?? '',
      filterCountries,
    });

  const open =
    data &&
    data.length > 0 &&
    value?.label !== searchTerm &&
    !value &&
    searchTerm &&
    searchTerm.length >= MIN_TERM_LENGTH;

  const [upsertLocation] = useUpsertLocationMutation();

  // checks if an item with the same label already exists in the db
  // if so, returns the location id
  // else return the data from aws to be inserted as a new location
  const handleItemSelect = async (next: typeof value) => {
    try {
      const { data } = await upsertLocation({
        variables: { object: next as any },
      });

      const location = data?.insert_location_one;

      onChange(location);
    } catch (e: any) {
      onChange(next);
      captureException(e);
    } finally {
      setSearchTerm(next?.label ?? '');
    }
  };

  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <InputLabel label={label} name={name} required={required} />
      <div className={twMerge('relative', className)}>
        <Listbox value={value} onChange={handleItemSelect}>
          {() => (
            <>
              <Input
                name="__location_search"
                value={value?.label ?? searchTerm ?? ''}
                onChange={(e: any) => {
                  onChange(null);
                  setSearchTerm(e.target?.value ?? '');
                }}
                placeholder={placeholder ?? 'Type to search...'}
                noValidation
              />
              {loading && (
                <Loading className="absolute inset-y-0 right-2 m-4 align-middle" />
              )}
              {open && (
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute z-10 mt-16 max-h-60 w-full overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-orange-500 focus:outline-none"
                  >
                    {data.map((item) => (
                      <Listbox.Option
                        key={slugify(item.label ?? '')}
                        className={({ active }) =>
                          twMerge(
                            'relative cursor-default select-none py-2 pl-6 pr-10 text-base',
                            active ? 'bg-grey-light' : ''
                          )
                        }
                        value={item}
                        data-test-value={item}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={twMerge(
                                value ? 'font-medium' : 'font-normal',
                                'block truncate text-base'
                              )}
                              data-test-value={selected}
                            >
                              {item.label}
                            </span>

                            {value ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-base">
                                <CheckIcon
                                  className="size-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              )}
            </>
          )}
        </Listbox>
      </div>
      <Errors error={error} />
    </div>
  );
};
