import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import { Errors, InputLabel } from '..';
import { BooleanOption } from './BooleanOption';

export type RadioBooleanProps = Pick<
  Parameters<typeof RadioGroup>[0],
  'disabled' | 'className'
> & {
  control: any;
  defaultValue?: boolean;
  label?: string;
  name: string;
};

export const RadioBoolean = forwardRef<HTMLInputElement, RadioBooleanProps>(
  function RenderRadioBoolean(
    { control: c, className, defaultValue, disabled, label, name },
    ref
  ) {
    const {
      field,
      fieldState: { error },
    } = useController({ control: c, name, defaultValue });

    return (
      <RadioGroup
        {...field}
        disabled={disabled}
        ref={ref}
        className={twMerge(
          'flex flex-row items-center justify-between',
          className
        )}
      >
        <InputLabel label={label} name={name} required={false} />
        <div className="flex flex-col">
          <div
            className={twMerge(
              'flex shrink flex-row items-end space-x-1 self-end',
              error ? 'mb-2' : 'mb-8'
            )}
          >
            <BooleanOption
              className="w-[90px] text-center"
              value={true}
              label="Yes"
            />
            <BooleanOption
              className="w-[90px] text-center"
              value={false}
              label="No"
            />
          </div>
          <Errors error={error} />
        </div>
      </RadioGroup>
    );
  }
);
