import { captureException } from '@sentry/nextjs';
import { FC } from 'react';
import { useMessages } from 'components';
import {
  GetEmployeeJobsDocument,
  GetWatchlistDocument,
  UpsertWatchlistMutationVariables,
  useGetWatchlistQuery,
  useUpsertWatchlistMutation,
} from 'database/types';
import { SavedJobIcon } from 'components/icons';

type Props = {
  job_id?: string | undefined;
};

export const SaveAuth: FC<Props> = ({ job_id }) => {
  const { alert } = useMessages();

  // Here we use the collective query (instead of querying by job_id) as an optimisation technique.
  // Apollo will dedupe and run the query only once.
  const { data } = useGetWatchlistQuery();
  const watchlistRow = data?.watchlist.find((w) => w.job_id === job_id);

  const isSaved = watchlistRow && !watchlistRow?.deleted_at;
  const [upsertWatchlist] = useUpsertWatchlistMutation({
    refetchQueries: [
      { query: GetWatchlistDocument },
      { query: GetEmployeeJobsDocument },
    ],
  });

  const handleClick = async () => {
    if (job_id) {
      try {
        const variables: UpsertWatchlistMutationVariables = {
          id: watchlistRow?.id ?? self.crypto.randomUUID(),
          job_id,
          deleted_at: isSaved ? new Date().toISOString() : null,
        };
        await upsertWatchlist({
          variables,
        });
        alert({
          key: 'SaveButton',
          type: 'success',
          title: `${isSaved ? 'Removed from' : 'Saved to'} watchlist`,
          duration: 3000,
          dismissable: true,
        });
      } catch (err) {
        if (err instanceof Error) {
          alert({
            key: 'SaveButton',
            type: 'error',
            title: 'Issue saving item',
            message: err?.message,
            duration: 3000,
            dismissable: true,
          });
        }
        captureException(err);
      }
    }
  };

  return (
    <div className="flex shrink-0">
      <button
        className="inline-flex items-center justify-start rounded-lg bg-orange-50 px-4 py-2 text-orange"
        onClick={handleClick}
        aria-label="Save job"
      >
        <SavedJobIcon
          stroke="#F17732"
          fill={isSaved ? '#F17732' : 'transparent'}
          className="h-5"
        />
        <span className="ml-2 whitespace-nowrap">
          {isSaved ? 'Saved' : 'Save Job'}
        </span>
      </button>
    </div>
  );
};
