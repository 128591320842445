import { DOMAttributes, VFC, useState } from 'react';
import { PenIcon, TrashbinIcon } from 'components/icons';
import DeleteConfirm from 'components/InfoCard/DeleteConfirm';

export type BaseInfoCardProps = {
  id: string;
  title: string;
  description?: string;
  onEdit?: DOMAttributes<HTMLButtonElement>['onClick'];
  onDelete?: DOMAttributes<HTMLButtonElement>['onClick'];
  card?: boolean;
};

export type InfoCardProps = BaseInfoCardProps & {
  subtitle: string;
  corner_info: string;
};

export const InfoCard: VFC<InfoCardProps> = ({ card = true, ...props }) =>
  card ? (
    <div className="relative z-0 space-y-2 overflow-hidden rounded-lg border border-solid border-orange-200 bg-cream-500 px-5 py-4">
      <div className="absolute inset-0 h-2 w-full bg-orange" />
      <Info {...props} />
    </div>
  ) : (
    <Info {...props} />
  );

const Info: VFC<InfoCardProps> = ({
  title,
  subtitle,
  corner_info,
  description,
  onEdit,
  onDelete,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <div className="relative z-0 space-y-2 pt-4 font-satoshi font-medium">
      {confirmDelete && (
        <DeleteConfirm
          onDelete={onDelete}
          onCancel={() => setConfirmDelete(false)}
        />
      )}
      <h3 className="text-xl font-medium">{title}</h3>

      <div className="text-grey">{subtitle}</div>

      {corner_info && <div className="text-sm text-grey">{corner_info}</div>}

      {description && <p className="text-charcoal">{description}</p>}

      {(onEdit || onDelete) && (
        <div className="absolute right-0 top-0 flex justify-end gap-2">
          {onEdit && (
            <button
              className="flex items-center justify-center rounded-lg text-orange"
              onClick={onEdit}
            >
              <PenIcon className="m-2 flex w-5 justify-center fill-orange-500" />
            </button>
          )}
          {onDelete && (
            <button
              className="flex items-center justify-center rounded-lg text-orange"
              onClick={() => setConfirmDelete(true)}
            >
              <TrashbinIcon className="m-2 flex w-5 justify-center fill-orange-500" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
