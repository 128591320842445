import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useFieldError, Errors } from '..';
import { InputLabel } from './InputLabel';

export type InputTextAreaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  className?: string;
  label?: ReactNode;
  name?: string;
  noValidation?: boolean;
};

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export const InputTextArea = forwardRef<
  HTMLTextAreaElement,
  InputTextAreaProps
>(function InputRef(
  { className, disabled, label, noValidation, required, ...props },
  ref
) {
  const { error } = useFieldError(props?.name);
  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <InputLabel label={label} name={props?.name} required={required} />
      <textarea
        ref={ref}
        rows={3}
        cols={50}
        className={twMerge(
          'block w-full rounded border border-light py-4 pl-6 pr-10 text-base text-black shadow-sm placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey',
          error && 'border-red-500 focus:border-red-700 focus:ring-red-500',
          disabled && 'cursor-not-allowed bg-gray-200'
        )}
        disabled={disabled}
        {...props}
      />
      {!noValidation && <Errors error={error} />}
    </div>
  );
});
