import { ApolloProvider } from '@apollo/client';
// Auth
import { Amplify } from 'aws-amplify';
import { NextPage } from 'next';
import { AppProps } from 'next/dist/shared/lib/router/router';
// Next
import Script from 'next/script';
import { FC, Fragment, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';
import {
  // createClient,
  JitsuProvider,
  usePageView,
} from '@jitsu/nextjs';
import { jitsuAnalytics } from '@jitsu/js';
import getDBClient from 'database/DBClient';
import { AuthProvider, useAuthContext } from 'components/AuthContext';
import { Banner, HistoryProvider, Messages, ModalProvider } from 'components';
import 'styles/global.css';
import 'styles/quill.css';
import { useCheckHost } from 'utils';
import config from '../aws-config';

const GTM_ID = 'GTM-5WRN6F2';
const ENV = process.env.NODE_ENV;
// const isDev = process.env.NEXT_PUBLIC_HASURA_API_URL?.includes('dev-api');

const jitsuClient = jitsuAnalytics({
  host: 'https://event.tradejobsnz.co.nz',
  writeKey: 'ILLPOKs8J95e4i7hrBsD6PFuZ0QlXrh8:YJhWvxvpVfmKC0JXzFrTSvXPw7bvIti4',
});

// jitsuClient.set({ enviroment: isDev ? 'development' : 'production' });

export const ProviderWithUser: FC = ({ children }) => {
  const { user, setUser } = useAuthContext();

  useEffect(() => {
    if (user) {
      jitsuClient.identify(user?.sub, {
        given_name: user?.given_name,
        family_name: user?.family_name,
        role: user?.role,
        email: user?.email,
        id: user?.sub,
      });
    }
  }, [user]);

  return (
    <ApolloProvider
      client={getDBClient({
        token: user?.token,
        anonymous: !user?.token,
        setUser,
      })}
    >
      {children}
    </ApolloProvider>
  );
};

Amplify.configure(config, { ssr: true });

//[Basic Features: Layouts | Next.js](https://nextjs.org/docs/basic-features/layouts)
//[When using getLayout, the Layout component can't access parent Contexts provided in _app · Issue #36029 · vercel/next.js](https://github.com/vercel/next.js/issues/36029)

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  Layout?: FC;
};
type AppPropsWithLayout = AppProps & { Component: NextPageWithLayout };

const MyApp: React.FC<AppPropsWithLayout> = ({
  Component,
  pageProps,
  router,
}) => {
  // SUBDOMAIN REDIRECTION
  useCheckHost(router);
  usePageView(jitsuClient); // Track page views on the client with JITSU

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout ?? Fragment;

  return (
    <>
      {ENV !== 'development' && (
        <>
          <Script
            id={GTM_ID}
            dangerouslySetInnerHTML={{
              __html: `
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer', '${GTM_ID}');
                    `,
            }}
          />
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=DC-11199008"
          />
          <Script
            id="DC-11199008"
            dangerouslySetInnerHTML={{
              __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', 'DC-11199008');
                    `,
            }}
          />
        </>
      )}
      <Script src="https://staticcdn.co.nz/embed/embed.js" />
      <Script src="https://staticcdn.co.nz/embed/embed.js" />

      <Script
        id="__shielded"
        dangerouslySetInnerHTML={{
          __html: `
            (function () {
              window.onload = function(){
                  var frameName = new ds07o6pcmkorn({
                      openElementId: "#shielded-logo",
                      modalID: "modal",
                  });
                  frameName.init();
              }
            })();
          `,
        }}
      />
      <JitsuProvider client={jitsuClient}>
        <Messages>
          <AuthProvider>
            <ProviderWithUser>
              <HistoryProvider>
                <ModalProvider>
                  <Banner />
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </ModalProvider>
              </HistoryProvider>
            </ProviderWithUser>
          </AuthProvider>
        </Messages>
      </JitsuProvider>
      <Analytics />
    </>
  );
};

export default MyApp;
