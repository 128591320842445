import { useEffect } from 'react';
import {
  UpdatedMonthPicker,
  UpdatedMonthPickerProps,
} from 'components/forms/UpdatedMonthPicker';

type Props<T> = {
  current?: boolean;
  resetField: (a: keyof T) => void;
  prefix?: string;
} & UpdatedMonthPickerProps;

// Make sure we don't remove the name prop from the rest of our properties
export function CustomInputDate<T>({
  prefix = '',
  resetField,
  ...props
}: Props<T>) {
  const current = !!(props as any)[`${prefix}current`];
  useEffect(() => {
    current && resetField(props?.name as keyof T);
  }, [current, resetField, props?.name]);

  return !current ? <UpdatedMonthPicker {...props} /> : null;
}
