import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const Youtube: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  fill = 'black',
  ...props
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <g style={{ mixBlendMode: 'hard-light' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM12 13C10.8954 13 10 13.8954 10 15V25C10 26.1046 10.8954 27 12 27H28C29.1046 27 30 26.1046 30 25V15C30 13.8954 29.1046 13 28 13H12Z"
        fill={fill}
      />
    </g>
    <path
      d="M23.6315 20.0015L17.9208 23.4769L17.9071 16.5487L23.6315 20.0015Z"
      fill={fill}
    />
  </svg>
);
