import { VFC } from 'react';
import { Facebook, Instagram, Linkedin } from 'components/icons';
import { Link } from 'components/Link';
import { SocialIcon } from 'components/SocialIcon';

export const BottomBar: VFC = () => (
  <div className="default-padding mx-auto mt-8 max-w-7xl items-start space-y-6 py-6 pt-8 text-center md:flex md:justify-between md:space-y-0 md:text-left">
    <div className="flex-wrap justify-center space-x-3 lg:flex">
      <p className="text-base tracking-wider text-grey">
        © Trade Jobs NZ {new Date().getFullYear()}. All Rights Reserved
      </p>
      <div className="flex flex-nowrap justify-center gap-3 text-grey">
        <span className="hidden lg:block">•</span>
        <div className="link-grey">
          <Link showActive={true} href="/privacy">
            Privacy Policy
          </Link>
        </div>
        <span>•</span>
        <div className="link-grey">
          <Link showActive={true} href="/terms">
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
    <div className="flex items-start justify-center space-x-4">
      <SocialIcon
        aria-hidden="true"
        href="https://www.facebook.com/tradejobsnz/"
      >
        <span className="sr-only">Facebook</span>
        <Facebook />
      </SocialIcon>
      <SocialIcon
        aria-hidden="true"
        href="https://www.linkedin.com/company/trade-jobs-nz"
      >
        <span className="sr-only">LinkedIn</span>
        <Linkedin />
      </SocialIcon>
      <SocialIcon
        aria-hidden="true"
        href="https://www.instagram.com/tradejobsnz/?hl=en"
      >
        <span className="sr-only">Instagram</span>
        <Instagram />
      </SocialIcon>

      <div className="cursor-pointer" id="shielded-logo">
        <img
          alt="shielded"
          className="size-10"
          src="https://shielded.co.nz/img/custom-logo.png"
        />
      </div>
    </div>
  </div>
);
