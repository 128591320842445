import { Dispatch, SetStateAction } from 'react';
import { ImageProps } from 'next/image';
import { Maybe } from 'database/types';
import { ListingItem } from './ListingItem';

export interface Listing {
  id: string;
  price: number;
  description: string;
  subtitle?: Maybe<string>;
  title: string;
  info: Array<{
    id: string;
    title: string;
    description: string;
    image: [ImageProps];
  }>;
}

type ListingsProps = {
  listings: Array<Listing>;
  selectedId?: string;
  onSelect: Dispatch<SetStateAction<string>>;
};

export function Listings({
  listings,
  onSelect,
  selectedId,
}: ListingsProps): JSX.Element {
  return (
    <div className="space-y-16">
      <div className="isolate mx-auto grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:mx-0 lg:grid-cols-2">
        {listings.map(({ id, title, subtitle, price, description }) => (
          <ListingItem
            key={id}
            id={id}
            price={price}
            title={title}
            subtitle={subtitle}
            description={description}
            selected={id === selectedId}
            onClick={() => onSelect(id)}
          />
        ))}
      </div>
    </div>
  );
}
