import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

export type PageMeta = {
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: string;
};

export const PageMeta: FC<PageMeta> = ({
  metaTitle,
  metaDescription,
  metaImage,
}) => {
  const router = useRouter();
  const path = router.asPath
  const domain = `${process.env.NEXT_PUBLIC_SITE_URL}`
  const url = `${process.env.NEXT_PUBLIC_SITE_URL}/${path}`;
  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {/* {<!-- Open Graph-->} */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={
          metaImage ||
          `https://assets.tradejobsnz.co.nz/open-graph-trade-jobs.jpg`
        }
      />
     <meta
        property="og:image:url"
        content={
          metaImage ||
          `https://assets.tradejobsnz.co.nz/open-graph-trade-jobs.jpg`
        }
      />
      <meta
        property="og:image:secure_url"
        content={
          metaImage ||
          `https://assets.tradejobsnz.co.nz/open-graph-trade-jobs.jpg`
        }
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="Trade Jobs NZ" />
      {/* {<!-- Facebook -->}  */}
      <meta property="fb:app_id" content="618121462980883" />
      {/* {<!-- Twitter OG -->} */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={
          metaImage ||
          `https://assets.tradejobsnz.co.nz/open-graph-trade-jobs.jpg`
        }
      />

      <link rel="icon" href="/favicon.ico" />

      {/* {<!-- Start of HubSpot Embed Code -->} */}
      {process.env.NODE_ENV !== 'development' && (
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/20147764.js"
        ></script>
      )}
      {/* {<!-- End of HubSpot Embed Code -->} */}
    </Head>
  );
};
