import { SVGAttributes } from 'react';

type LocationIconProps = SVGAttributes<SVGSVGElement>;

const LocationIcon = (props: LocationIconProps) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.66667 10.8327C9.04738 10.8327 10.1667 9.71339 10.1667 8.33268C10.1667 6.95197 9.04738 5.83268 7.66667 5.83268C6.28595 5.83268 5.16667 6.95197 5.16667 8.33268C5.16667 9.71339 6.28595 10.8327 7.66667 10.8327Z"
      stroke="#F17732"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66667 18.3327C11 14.9993 14.3333 12.0146 14.3333 8.33268C14.3333 4.65078 11.3486 1.66602 7.66667 1.66602C3.98477 1.66602 1 4.65078 1 8.33268C1 12.0146 4.33333 14.9993 7.66667 18.3327Z"
      stroke="#F17732"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LocationIcon;
