import { Maybe } from 'database/types';
interface DescriptionHandleProps {
  tagline?: Maybe<string>;
  highlights?: string[];
}

const DescriptionHandle: React.FC<DescriptionHandleProps> = ({
  tagline,
  highlights,
}) => {
  return (
    <>
      {tagline && (
        <p className="mb-4 text-lg font-medium text-charcoal-400">{tagline}</p>
      )}

      {!tagline && !!highlights?.length && (
        <span className="font-medium text-orange">Highlights</span>
      )}

      {!!highlights?.length && (
        <ul className="mt-1 list-inside list-disc px-2">
          {/* {highlights.map((item, i) => ( */}
          {highlights.map((item, i) => (
            <li key={`${item}_${i}`} className="font-medium text-charcoal-400">
              {item}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default DescriptionHandle;
