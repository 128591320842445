import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props = PropsWithChildren<{
  id: string;
}>;

export function DNDListItem({ id, children }: Props): JSX.Element {
  const {
    active,
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="flex items-center align-middle"
      style={style}
    >
      {/* Handler */}
      <button
        {...listeners}
        {...attributes}
        type="button"
        className="mr-2 flex shrink cursor-move flex-row p-2 focus:outline-none sm:-mr-2"
        ref={setActivatorNodeRef}
      >
        <EllipsisVerticalIcon className="-mr-4 size-6 text-gray-500" />
        <EllipsisVerticalIcon className="size-6 text-gray-500" />
      </button>

      {/* Card */}
      <div
        className={twMerge(
          'mt-2 grow overflow-hidden rounded border bg-white p-4',
          active
            ? 'border-light bg-gray-100 shadow-md'
            : 'border-gray-100 shadow-sm'
        )}
      >
        {children}
      </div>
    </div>
  );
}
