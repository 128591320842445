import { FC, useState, useEffect } from 'react';

import { twMerge } from 'tailwind-merge';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';
import { signIn, signUp } from 'aws-amplify/auth';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useJitsu } from '@jitsu/nextjs';
import { useMessages } from 'components/Messages';
import { useGetEnumCategoriesQuery } from 'database/types';
import {
  MultiSelect,
  Checkbox,
  ErrorProvider,
  Input,
  InputPhone,
} from 'components/forms';
import { EnumType } from 'types/utils';
import { Button, Divider, Link, SocialButton } from '../..';
import Validation from './Validation';
import { updateCategory } from './utils';

export type RegisterData = {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone: string;
  terms: boolean;
};

export type EmployeeRegisterData = RegisterData & {
  category: string[];
};

type RegisterProps = {
  application?: boolean;
  callback?: () => void;
};

export const Register: FC<RegisterProps> = ({
  application = false,
  callback,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const { data } = useGetEnumCategoriesQuery();
  const { pathname, query } = useRouter();
  const { alert } = useMessages();
  const { track } = useJitsu();
  const { control, formState, handleSubmit, register, watch } =
    useForm<EmployeeRegisterData>({
      defaultValues: {
        category: [],
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        terms: false,
      },
      resolver: yupResolver(Validation) as any,
    });

  async function onSubmit({
    category,
    email,
    first_name,
    last_name,
    password,
    phone,
  }: EmployeeRegisterData) {
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            given_name: first_name,
            family_name: last_name,
            phone_number: phone,
          },
        },
      });

      track('tradie_register', {
        email,
        first_name,
        last_name,
        phone,
        category,
      });

      await signIn({
        username: email,
        password,
      });

      if (category && category.length > 0) await updateCategory(category);

      callback?.();
    } catch (error: any) {
      alert({
        key: 'Registration',
        type: 'error',
        dismissable: true,
        title: 'Issue with registration',
        message: error.message,
      });
      captureException(error);
    }
  }

  const termsAccepted = watch('terms');

  const categories = data?.enum_category;

  return (
    <div>
      <div className="my-6 grid grid-cols-1 justify-center gap-4 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
        <SocialButton signup variant="google" />
        <SocialButton signup variant="facebook" />
      </div>
      <Divider className="my-6" bg="bg-grey-light">
        OR
      </Divider>
      <form id="register" onSubmit={handleSubmit(onSubmit)} className="my-6">
        <ErrorProvider errors={formState.errors}>
          <div className="grid grid-cols-12 gap-x-4">
            <div className="col-span-12 md:col-span-5">
              <Input
                {...register('first_name')}
                autoFocus
                required
                label="First name"
                placeholder="First name"
              />
            </div>
            <div className="col-span-12 md:col-span-7">
              <Input
                {...register('last_name')}
                required
                label="Last name"
                placeholder="Last name"
              />
            </div>
          </div>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <InputPhone
                required
                value={field.value}
                label="Phone"
                onChange={(a: string) => field.onChange(a)}
              />
            )}
          />
          <Input
            {...register('email')}
            required
            autoCapitalize="false"
            label="Email address"
            placeholder="Email address"
          />
          <Input
            {...register('password')}
            required
            autoCorrect="false"
            autoCapitalize="false"
            type="password"
            label="Password"
            placeholder="Password"
          />
          {categories && isClient && (
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <MultiSelect
                  options={categories as EnumType[]}
                  label="Trade category"
                  name="category"
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          )}
          <Checkbox
            {...register('terms')}
            label={
              <>
                By clicking Register you are indicating that you have read and
                acknowledged the{' '}
                <Link
                  className="whitespace-nowrap text-gray-900 underline hover:text-gray-600"
                  href="/terms"
                >
                  "Terms of Service"
                </Link>{' '}
                and{' '}
                <Link
                  className="whitespace-nowrap text-gray-900 underline hover:text-gray-600"
                  href="/privacy"
                >
                  "Privacy Policy"
                </Link>
                .
              </>
            }
          />
          <div
            className={twMerge('mt-6 text-center', application && 'text-right')}
          >
            <Button
              id="__register_tradie"
              className="mt-6 text-center uppercase"
              type="submit"
              color="black"
              disabled={!termsAccepted || formState.isSubmitting}
              loading={formState.isSubmitting}
            >
              {application ? 'Apply' : 'Register'}
            </Button>
          </div>
        </ErrorProvider>
      </form>
      {!application && (
        <p className="mt-6 text-center text-gray-500">
          Already have an account?{' '}
          <Link
            href={{
              pathname,
              query,
              hash: 'login',
            }}
            className="text-primary underline hover:text-primary-600"
          >
            Sign in
          </Link>
        </p>
      )}
    </div>
  );
};
