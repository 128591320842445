import { useRouter } from 'next/router';
import Markdown from 'markdown-to-jsx';
import { Header, Link } from 'components';

interface JobCompanyInfoProps {
  hasDescription: boolean;
  pageActive: boolean;
  company?: string;
  descriptionTrimmed: string;
  expanded: boolean;
  descriptionText: string;
  isTrimmed: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  applying?: boolean;
  employerSlug: string;
}

const CompanyInfo: React.FC<JobCompanyInfoProps> = ({
  hasDescription,
  pageActive,
  company,
  descriptionTrimmed,
  expanded,
  descriptionText,
  isTrimmed,
  setExpanded,
  applying,
  employerSlug,
}) => {
  const router = useRouter();
  const { pathname, query } = router;

  return (
    <>
      {(hasDescription || pageActive) && (
        <div className="rounded-lg border border-orange-100 bg-orange-50 px-5 py-6">
          <div className="space-y-4 font-satoshi md:space-y-6">
            <Header>
              <p className="mb-3 text-2xl font-bold leading-[33.6px] -tracking-two-percent text-charcoal-500">
                About {company}
              </p>
              <Link
                className="text-lg font-medium leading-[27px] text-orange underline"
                href={{
                  pathname: '/jobs',
                  query: {
                    ...(pathname === '/[...jobs]' ? query : {}),
                    company: employerSlug,
                  },
                }}
              >
                More job listings from {company}
              </Link>
            </Header>
            {descriptionTrimmed && (
              <div className="prose">
                <Markdown options={{ disableParsingRawHTML: true }}>
                  {expanded ? descriptionText : descriptionTrimmed}
                </Markdown>
                {isTrimmed && !expanded && (
                  <button
                    type="button"
                    onClick={() => setExpanded(!expanded)}
                    className="text-base font-medium leading-6 text-charcoal-300"
                  >
                    Keep Reading
                  </button>
                )}
              </div>
            )}
            {!applying && pageActive && (
              <Link
                href={`/directory/${employerSlug}`}
                className="flex h-10 w-max items-center rounded-lg border border-solid border-orange-500 px-6"
              >
                <span className="text-lg font-medium leading-[27px] text-orange">
                  View Employer Page
                </span>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyInfo;
