import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import React, { VFC } from 'react';
import { Link } from 'components';
import { PaginationTile } from 'components/Pagination/PaginationTile';

export type PaginationProps = {
  currentPage?: number;
  totalPages?: number;
  scrollToHash?: string;
  scrollToTop?: boolean;
};

export const Pagination: VFC<PaginationProps> = ({
  currentPage = 1,
  totalPages = 1,
  scrollToHash: hash,
  scrollToTop = true,
}) => {
  const router = useRouter();
  const { pathname, query } = router;

  return (
    <div className="flex items-center justify-center">
      <nav className="relative inline-flex" aria-label="Pagination">
        {/* Previous Page Arrow */}
        {currentPage > 1 && (
          <Link
            href={{
              pathname,
              query: { ...query, page: (currentPage - 1).toString() },
              hash,
            }}
            scroll={scrollToTop}
            className="hidden sm:block"
          >
            <PaginationTile arrow>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="size-3 stroke-charcoal-500 stroke-2 sm:size-4"
                aria-hidden="true"
              />
            </PaginationTile>
          </Link>
        )}

        {/* First Page Number*/}
        {currentPage > 1 && (
          <Link
            href={{
              pathname,
              query: { ...query, page: 1 },
              hash,
            }}
            scroll={scrollToTop}
          >
            <PaginationTile>1</PaginationTile>
          </Link>
        )}

        {/* Leading ellipsis */}
        {currentPage > 3 && <PaginationTile disabled>...</PaginationTile>}

        {/* Previous Page Number */}
        {currentPage > 2 && (
          <Link
            href={{
              pathname,
              query: { ...query, page: (currentPage - 1).toString() },
              hash,
            }}
            scroll={scrollToTop}
          >
            <PaginationTile>{currentPage - 1}</PaginationTile>
          </Link>
        )}

        {/* Current Page */}
        <PaginationTile active>
          <span className="sr-only">Current</span>
          <span aria-hidden="true">{currentPage}</span>
        </PaginationTile>

        {/* Next Page Number */}
        {currentPage + 1 < totalPages && (
          <Link
            href={{
              pathname,
              query: { ...query, page: (currentPage + 1).toString() },
              hash,
            }}
            scroll={scrollToTop}
          >
            <PaginationTile>{currentPage + 1}</PaginationTile>
          </Link>
        )}

        {/* Trailing Ellipsis */}
        {currentPage < totalPages - 2 && (
          <PaginationTile disabled>...</PaginationTile>
        )}

        {/* Last Page Number */}
        {currentPage < totalPages && (
          <Link
            href={{
              pathname,
              query: { ...query, page: totalPages.toString() },
              hash,
            }}
            scroll={scrollToTop}
          >
            <PaginationTile>
              <span className="sr-only">Last</span>
              <span aria-hidden="true">{totalPages}</span>
            </PaginationTile>
          </Link>
        )}

        {/* Next Page Arrow */}
        {currentPage < totalPages && (
          <Link
            href={{
              pathname,
              query: { ...query, page: (currentPage + 1).toString() },
              hash,
            }}
            scroll={scrollToTop}
            className="hidden sm:block"
          >
            <PaginationTile arrow>
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="size-3 stroke-charcoal-500  stroke-2 sm:size-4"
                aria-hidden="true"
              />
            </PaginationTile>
          </Link>
        )}
      </nav>
    </div>
  );
};
