import { VFC } from 'react';
import { TableRow } from 'components/Table/Row';

const dummyCells = new Array(4).fill(undefined);
const dummyRows = new Array(5).fill({ data: dummyCells });

export const TableLoading: VFC = () => (
  <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
    <div className="min-w-full rounded border border-grey-light bg-white shadow">
      <table className="min-w-full divide-y divide-grey-light">
        <tbody className="divide-y divide-grey-light rounded bg-white">
          {dummyRows.map((row, i) => (
            <TableRow {...row} loading={true} key={i} />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
