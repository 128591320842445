import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const SavedJobIcon: VFC<SVGProps<SVGSVGElement>> = ({
  className,
  stroke = '#242222',
  ...props
}) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <path
      d="M4 1.7V18.0414C4 18.216 4.20812 18.3067 4.33609 18.1879L10.2912 12.6582C10.37 12.585 10.4926 12.5872 10.5687 12.6633L16.064 18.1586C16.19 18.2846 16.4054 18.1953 16.4054 18.0172V1.7C16.4054 1.58954 16.3159 1.5 16.2054 1.5H4.2C4.08954 1.5 4 1.58954 4 1.7Z"
      stroke={stroke}
      strokeWidth="1.5"
    />
  </svg>
);
