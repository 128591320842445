// Framework
import { RefObject, useRef } from 'react';
import { useInView } from 'framer-motion';

// Taken from framer motion
interface Options {
  root?: RefObject<Element>;
  once?: boolean;
  margin?: string;
  amount?: 'some' | 'all' | number;
}

/*--------------------------------------------------------------------*/

/**
 * Hook
 */

const useIntersectionObserver = (
  options?: Options
): [React.RefObject<HTMLDivElement>, boolean] => {
  const targetRef = useRef(null);
  const isInView = useInView(targetRef, options);

  return [targetRef, isInView];
};

export default useIntersectionObserver;
