import { uploadData } from 'aws-amplify/storage';
import { FC, ReactChild, useRef, useState } from 'react';
import { useJitsu } from '@jitsu/nextjs';
import { twMerge } from 'tailwind-merge';
import { Loading } from 'components/icons';
import { useAuthContext } from 'components/AuthContext';
import {
  useUploadDocumentsMutation,
  File_Insert_Input,
  GetDocumentsDocument,
} from 'database/types';
import { useMessages } from 'components';
import CrossIcon from 'components/register/CrossIcon';

type FileUploadProps = {
  src: string | null;
  renderPreview: (previewSrc?: string) => ReactChild;
  className?: string;
};

export type CallBack = {
  loaded: number;
  total: number;
};

type Uploads = {
  key: string;
  mimetype: string;
  name: string;
  user_id?: string;
};

export const DocumentUpload: FC<FileUploadProps> = ({ className }) => {
  const { alert } = useMessages();
  const { track } = useJitsu();
  const { user } = useAuthContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [updateDocuments] = useUploadDocumentsMutation({
    refetchQueries: [{ query: GetDocumentsDocument }],
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = async (e: any): Promise<void> => {
    if (!e.target.files || e.target.files.length < 1) return;
    if (e.target.files.length > 10) {
      alert({
        key: 'Upload',
        type: 'error',
        title: 'Failed to upload file',
        message: 'You can only upload 10 files at a time',
        duration: 3000,
      });
      return;
    }
    setLoading(true);
    const files = e.target.files;

    const fileUploads: Uploads[] = [];
    try {
      const filesArray = Array.from(files);
      const uploadFiles = filesArray.map(async (file: any) => {
        const fileKey = `${user?.sub}/${file.name}+${Date.now()}`;
        fileUploads.push({
          key: fileKey,
          mimetype: file?.type,
          name: file?.name,
        });
        const { key }: any = await uploadData({
          key: fileKey,
          data: file,
          options: {
            accessLevel: 'guest',
            contentType: file.type,
          },
        }).result;
        return key;
      });

      await Promise.all(uploadFiles);
      await updateDocuments({
        variables: { objects: fileUploads as File_Insert_Input[] },
      });
      track('supporting_document_uploaded');
      alert({
        key: 'Upload',
        type: 'success',
        title: 'Files uploaded',
        duration: 3000,
      });
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'Upload',
          type: 'error',
          title: 'Failed to upload file',
          message: err?.message,
          duration: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className={twMerge(
          'flex h-9 w-24 items-center justify-center gap-2 rounded-lg border border-orange-500',
          className
        )}
        onClick={() => inputRef?.current?.click()}
        type="button"
      >
        {loading ? (
          <Loading className="size-4" />
        ) : (
          <>
            <span className="font-medium text-orange-500">Add</span>
            <span>
              <CrossIcon
                strokeWidth={3}
                stroke="inherit"
                className="size-3 stroke-orange-500 text-orange-500"
              />
            </span>
          </>
        )}
      </button>

      <input
        ref={inputRef}
        className="hidden"
        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.pdf"
        multiple={true}
        type="file"
        onChange={onChange}
      />
    </>
  );
};
