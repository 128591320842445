// Third Parties
import { AnimatePresence, motion } from 'framer-motion';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

const variants = {
  initial: {
    y: 80,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -80,
    opacity: 0,
  },
};

export function MoveUpAnimation(): JSX.Element {
  const lines: string[] = [
    '19,000 tradies use our site every month',
    'Proven, better value job posts',
    "We don't just seek, we find, everyday",
  ];
  // const [activeLine, setActiveLine] = useState<number>(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setActiveLine((prevLine) => (prevLine + 1) % lines.length);
  //   }, 5000);

  //   return () => clearInterval(intervalId);
  // }, []);

  const activeContent = lines.map((line) => (
    <motion.p
      key={line}
      className="relative inset-0"
      variants={variants}
      transition={{ duration: 0.5 }}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <span className="block">{line}</span>
    </motion.p>
  ));

  const currentLine = activeContent[0];

  return (
    <div className="relative z-0 h-auto flex-1">
      <AnimatePresence onExitComplete={() => console.log(currentLine)}>
        {currentLine}
      </AnimatePresence>
    </div>
  );
}
