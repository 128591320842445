import React from 'react';
import { Link, Logo } from 'components';
import { NewsLetter } from 'components/forms';
import { makeSubdomainLink } from 'utils/makeSubdomainLink';
import { BottomBar } from 'components/Footer/BottomBar';
import { Heading } from './Heading';

const links = {
  explore: [
    { name: 'Jobs', href: '/jobs' },
    { name: 'Directory', href: '/directory' },
    { name: 'Employer site', href: makeSubdomainLink('/portal') },
    { name: 'Training', href: '/training' },
    { name: 'About', href: '/about' },
  ],
  login: [
    { name: 'Candidates', href: '/employee' },
    { name: 'Register', href: '/register' },
    {
      name: 'Employer site',
      href: makeSubdomainLink('/portal'),
    },
  ],
};

export const Footer: React.FC = () => (
  <footer className="bg-white" aria-labelledby="footerHeading">
    <h2 id="footerHeading" className="sr-only">
      Footer
    </h2>
    <div className="default-padding mx-auto max-w-7xl py-12 lg:py-16">
      <div className="gap-8 sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6">
        {/* Explore */}
        <div>
          <Heading>Explore</Heading>
          <ul className="mt-4 space-y-4">
            {links.explore.map((item) => (
              <li key={item.name}>
                {item.href ? (
                  <Link href={item.href} className="text-base hover:text-grey">
                    {item.name}
                  </Link>
                ) : (
                  item.name
                )}
              </li>
            ))}
            {/* <li>
              <a
                className="hover:text-grey"
                href="https://helpcentre.tradejobsnz.co.nz/knowledge"
                target="_blank"
                rel="noreferrer"
              >
                Help centre
              </a>
            </li> */}
          </ul>
        </div>

        {/* Log in */}
        <div className="mt-12 sm:mt-0">
          <Heading>Log in</Heading>
          <ul className="mt-4 space-y-4">
            {links.login.map((item, i) => (
              <li key={i}>
                {item.href ? (
                  <Link
                    showActive={true}
                    href={item.href}
                    className="text-base hover:text-grey"
                  >
                    {item.name}
                  </Link>
                ) : (
                  item.name
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Contact */}
        <div className="mt-12 sm:mt-0 md:col-span-2">
          <Heading>Contact</Heading>
          <ul className="mt-4 space-y-4">
            <li>
              <Link className="mt-4 hover:text-grey" href="/contact">
                Contact us
              </Link>
            </li>
            <li>
              <p className="mt-4 text-grey">Address</p>
              <a
                target="_blank"
                href="https://www.google.com/maps/place/B:HIVE/@-36.7845203,174.7520736,17z/data=!3m1!4b1!4m5!3m4!1s0x6d0d39c80a9cd8f9:0xe503dfde6c122f02!8m2!3d-36.7845203!4d174.7542623"
                rel="noreferrer"
                className="hover:text-grey"
              >
                <p>B:HIVE, SmalesFarm</p>
                <p>72 Taharoto Road, Takapuna</p>
                <p>Auckland 0622</p>
              </a>
            </li>
          </ul>
        </div>

        {/* Newsletter */}
        <div className="col-span-3 mt-12 sm:col-span-2 md:col-span-2 md:mt-0">
          <Heading>Updates</Heading>
          <p className="mt-3 text-base text-black md:mt-5">
            Sign up to our newsletter for weekly updates on jobs listed with
            Trade Jobs NZ
          </p>
          <div className="w-full items-end gap-1 space-y-4 align-baseline sm:mx-auto sm:inline-flex sm:gap-3 md:block lg:inline-flex">
            <NewsLetter hash="_footer" />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Logo color="black" />
      </div>
    </div>

    <BottomBar />
  </footer>
);
