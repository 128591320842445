import { FC, PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ItemProps = PropsWithChildren<{
  icon?: ReactNode;
  title: ReactNode;
  transparent?: boolean;
  subtitle?: string;
}>;

export const Item: FC<ItemProps> = ({
  title,
  transparent = false,
  subtitle,
  icon,
  children,
}) => (
  <div className="space-y-2">
    {title && (
      <div className="flex flex-row items-center">
        {icon && <span className="mr-2 shrink">{icon}</span>}
        <h3
          className={twMerge(
            'grow text-lg font-medium',
            transparent ? 'text-grey' : 'text-black'
          )}
        >
          {title}
        </h3>
      </div>
    )}
    {subtitle && (
      <h4 className="text-base font-light text-primary">{subtitle}</h4>
    )}
    {children}
  </div>
);
