import { FC, PropsWithChildren } from 'react';
import {
  CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';
import { ModalBase, ModalBaseProps } from 'components/Modal/ModalBase';
import { Button } from '..';

export type ModalProps = PropsWithChildren<
  ModalBaseProps & {
    description?: string;
    onCancel?: () => void;
    onConfirm?: () => void;
    type?: 'success' | 'error' | 'warning' | 'info' | 'form';
  }
>;

export const Modal: FC<ModalProps> = ({
  children,
  description,
  dismissable = true,
  title,
  type,
  onClose,
  onConfirm,
  onCancel,
  open = false,
}) => (
  <ModalBase
    sidebar={
      <>
        {type && type !== 'form' && (
          <div
            className={twMerge(
              'mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:mr-4 sm:h-10 sm:w-10',
              type === 'success'
                ? 'bg-green-100'
                : type === 'error'
                  ? 'bg-red-100'
                  : type === 'warning'
                    ? 'bg-yellow-100'
                    : type === 'info'
                      ? 'bg-blue-100'
                      : ''
            )}
          >
            {(() => {
              switch (type) {
                case 'success':
                  return <CheckIcon className="size-6 text-green-500" />;
                case 'error':
                case 'warning':
                  return (
                    <ExclamationTriangleIcon
                      className={twMerge(
                        'h-6 w-6',
                        type === 'error' ? 'text-red-500' : 'text-yellow-500'
                      )}
                    />
                  );
                case 'info':
                  return (
                    <InformationCircleIcon className="size-6 text-blue-500" />
                  );
              }
            })()}
          </div>
        )}
      </>
    }
    onClose={onClose ?? onCancel}
    {...{ title, open, dismissable }}
  >
    {description && (
      <div className="mt-2">
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    )}
    {children && <div className="mt-2">{children}</div>}
    <div className="mt-5 space-y-2 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0">
      {(onConfirm || type === 'form') && (
        <Button
          {...(type === 'form' ? { type: 'submit' } : { onClick: onConfirm })}
          className={twMerge(
            'w-full sm:w-auto',
            type === 'error' ? 'bg-red-500' : ''
          )}
          size="small"
          color="black"
        >
          {type === 'form' ? 'Submit' : 'Confirm'}
        </Button>
      )}
      {onCancel && (
        <Button
          className="mr-0 w-full sm:mr-2 sm:w-auto"
          size="small"
          color="white"
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
    </div>
  </ModalBase>
);
