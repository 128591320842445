import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, VFC } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { Link, Logo } from 'components';
import { NavbarItemProps } from 'components/Navbar/Navbar';
import { NavLink } from 'components/Navbar/NavLink';
import { UserMenuMObile } from 'components/Navbar/UserMenu';
import HambuerguerButton from 'components/Navbar/Mobile/HamburguerButton';
import { PopupMenu } from 'components/Navbar/Mobile/PopupMenu';

export const MobileNav: VFC<NavbarItemProps> = ({
  authenticated,
  className,
}) => (
  <Popover
    as="header"
    className={twMerge('relative z-20 w-full bg-cream', className)}
  >
    {({ open }) => (
      <>
        <div className="relative z-0 mx-auto flex h-16 items-center justify-between px-6 py-3 font-satoshi">
          {!authenticated && <HambuerguerButton open={open} />}
          <Link
            className={twJoin(
              'h-[26px]',
              !authenticated && 'absolute left-1/2 h-[26px] -translate-x-1/2',
              authenticated && 'relative'
            )}
            href="/"
            aria-label="Go to Home Page"
          >
            <Logo color="black" className="mx-auto" />
          </Link>
          <div className="flex items-center justify-end space-x-4">
            {!authenticated && (
              <NavLink
                className="rounded-lg bg-orange px-3 py-1.5 lg:text-base"
                href="/register"
                hover={false}
              >
                Sign Up
              </NavLink>
            )}
            {authenticated && <UserMenuMObile type="employee" />}
          </div>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            focus
            static
            className="absolute inset-x-0 top-16 z-10 origin-top-right transition"
          >
            <div className="fixed z-0 h-screen w-screen bg-black/40" />
            <div className="relative z-10 bg-cream-500">
              <PopupMenu />
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);
