const IMAGE_BASE_PATH = '/assets/images/portal/testimonials';

const FEEDBACK_DATA = [
  {
    text: "I wanted to thank you both for all your help with our recent recruitment campaign.  I'm pleased to share that we have just recruited a Qualified Builder on a contract basis as a result of this campaign.  As you can imagine, in this tight market, we are delighted with the outcome!  Your knowledge of both the recruitment and trade industry is invaluable and has been instrumental in the success of this campaign.",
    person: 'Jane Conway',
    position: 'Owner',
    company: 'Frontier Construction',
    logo: `${IMAGE_BASE_PATH}/FrontierConstruction_logo.png`,
    image: `${IMAGE_BASE_PATH}/FrontierConstruction_image.jpeg`,
  },
  {
    company: 'HRV',
    logo: `${IMAGE_BASE_PATH}/HRV_logo.png`,
    image: `${IMAGE_BASE_PATH}/HRV_image.jpeg`,
    person: 'Natalie James',
    position: 'Recruitment Partner',
    text: 'Advertising on Trade Jobs NZ is easy and efficient. Staff are always helpful, and quick to respond, and give great advice, and recommendation to tailor your advertising or social campaigns here in NZ and overseas. Good calibre of candidates would definitely recommend Trade Jobs NZ.',
  },
  {
    company: 'Flowfix Plumbing',
    logo: `${IMAGE_BASE_PATH}/FlowFixPlumbing_logo.png`,
    image: `${IMAGE_BASE_PATH}/FlowFixPlumbing_image.jpeg`,
    person: 'Colette Freel',
    position: 'Owner',
    text: "We are a small plumbing company and have always struggled with finding staff. We joined Trade Jobs NZ about a year ago and recently purchased their digital package to find the right person. It was amazing at how many quality CV's and very responsive. We have since hired someone from the campaign and they're doing well. Cameron and Trade Jobs NZ has been a pleasure to work and highly responsive to my questions. We have every intention to use them for any more upcoming job vacancies. I highly recommend Trade Jobs NZ.",
  },
  {
    company: 'Guaranteed Flow Systems',
    logo: `${IMAGE_BASE_PATH}/GuaranteedFlowSystems_logo.png`,
    image: `${IMAGE_BASE_PATH}/GuaranteedFlowSystems_image.jpeg`,
    person: 'Peter Leitch',
    position: 'Managing Director',
    text: "GFS engaged Trade Jobs NZ to assist with finding candidates for 3 roles - welder, fabricator, and apprentice. The experience was very positive and we were blown away by the number of quality applications we received from the campaign. We found this to be a very economical way of sourcing candidates and we'd definitely recommend working with Trade Jobs NZ.",
  },
  // {
  //   company: 'Thermawoord',
  //   logo: `${IMAGE_BASE_PATH}/FrontierConstruction_logo.png`,
  //   image: `${IMAGE_BASE_PATH}/FrontierConstruction_image.jpeg`,
  //   person: 'Craig Morley',
  //   position: 'Managing Director',
  //   text: 'Our experience working with Trade Jobs NZ was fantastic. They were instrumental in helping us find a double-glazed window installer! TJNZ invested a significant amount of time getting to know our specific needs, and as a result, crafted a tailored campaign that hit the mark. Thanks to their efforts, we successfully filled the role with an excellent candidate. We have no hesitation working with Trade Jobs NZ again in the future.',
  // },
  {
    company: 'StorePro Solutions Limited',
    logo: `${IMAGE_BASE_PATH}/StorePro_logo.png`,
    image: `${IMAGE_BASE_PATH}/StorePro_image.jpeg`,
    person: 'Jayne Reardon',
    position: 'Head of People & Culture',
    text: "We found the platform to be very valuable, reaching Kiwi's on the platforms they use. We received good quality applications and filled our roles off the back of the campaign. We would happily use Trade Jobs NZ again.",
  },
  {
    company: 'Vollmer Kitchens',
    logo: `${IMAGE_BASE_PATH}/VollmerKitchens_logo.png`,
    image: `${IMAGE_BASE_PATH}/VollmerKitchens_image.jpeg`,
    person: 'Robert Vollmer',
    position: 'Managing Director',
    text: 'I am delighted to provide a testimonial for Trade Jobs. Their friendly and prompt responsiveness, clear guidance, and invaluable assistance with job ads exceeded my expectations. They made the hiring process a pleasure, resulting in a remarkable amount of interest for the position. I wholeheartedly recommend Trade Jobs for their exceptional service',
  },
  {
    company: 'AK Electrical',
    logo: `${IMAGE_BASE_PATH}/AKElectrical_logo.png`,
    image: `${IMAGE_BASE_PATH}/AKElectrical_image.jpeg`,
    person: 'Robert (Bobby) Elliot',
    position: 'Managing Director',
    text: 'Working with the team at Trade Jobs NZ was great. The radio and social media were very effective at getting our listings in front of a targeted audience and really got people talking. We received a large number of quality applications and were able to fill 3 roles as a result.',
  },
];

export default FEEDBACK_DATA;
