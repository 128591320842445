import { twMerge } from 'tailwind-merge';
import { convert } from 'html-to-text';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Errors, useFieldError } from '..';

// import on client side only
// https://github.com/zenoamaro/react-quill/issues/122#issuecomment-873541247
const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

const quillOptions = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  },
  formats: [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ],
};

type RichTextProps = {
  control: any;
  defaultValue?: any;
  label: string;
  max?: number;
  name: string;
  onChange?: ReactQuillProps['onChange'];
  placeholder?: string;
};

export const RichText: FC<RichTextProps> = ({
  control,
  defaultValue,
  label,
  max = 2000,
  name,
  onChange,
  placeholder,
}) => {
  const { error } = useFieldError(name);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange: oc, ...field } }) => (
        <>
          <label
            htmlFor={name}
            className="mb-1 flex items-center justify-start gap-2 text-left text-sm font-bold leading-5 text-charcoal-500"
          >
            {label}
            <span className="text-xs text-charcoal-200">
              {/* count plaintext chars only */}
              {` (${
                convert((value as string) ?? '').length
              }/${max} characters)`}
            </span>
          </label>
          <ReactQuill
            {...quillOptions}
            {...field}
            value={value as string}
            className={twMerge(
              'mb-8 rounded-lg border border-charcoal-50 bg-white shadow-sm focus-within:border-orange-500 focus-within:ring-1 focus-within:ring-orange-200',
              error &&
                'border border-red-500 focus-within:border-red-700 focus-within:ring-red-500'
            )}
            theme="snow"
            onChange={onChange ?? oc}
            placeholder={`${placeholder} (max ${max} characters)`}
            preserveWhitespace={true}
          />
          <Errors error={error} />
        </>
      )}
    />
  );
};
