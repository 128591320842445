import React, { FC } from 'react';
import { Avatar, useMessages } from 'components';
import {
  ImageUpload,
  ImageUploadProps,
} from 'components/ProfileImage/ImageUpload';
import {
  GetUserByPkDocument,
  useUpdateProfileImageMutation,
} from 'database/types';

type Props = {
  id?: string;
} & Pick<ImageUploadProps, 'handleUpdate' | 'src'>;

export const ProfileImage: FC<Props> = ({ id, ...props }) => {
  const { alert } = useMessages();

  const [updateProfile] = useUpdateProfileImageMutation({
    refetchQueries: [{ query: GetUserByPkDocument, variables: { id } }],
  });

  const handleUpdate = async (key: string) => {
    if (!id) throw Error('Issue uploading file');

    await updateProfile({
      variables: {
        id,
        profile_photo: key as string,
      },
    });

    alert({
      key: 'Upload',
      type: 'success',
      title: 'Profile Image updated',
      duration: 3000,
    });
  };

  return (
    <ImageUpload
      {...{ handleUpdate, ...props }}
      level="guest"
      renderPreview={() => <Avatar />}
    />
  );
};
