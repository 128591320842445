import { UseMessageHook } from 'components';

const DEAFAULT_KEY = '__NOTICES_FACTORY_ALERTS';

type NoticeFactory = {
  error: (a: string) => void;
  processing: () => void;
  success: () => void;
};

type NoticeFactoryInput = Pick<UseMessageHook, 'alert' | 'update'> & {
  KEY?: string;
};

export const makeNoticeFactory = ({
  alert,
  update,
  KEY = DEAFAULT_KEY,
}: NoticeFactoryInput): NoticeFactory => ({
  error: function errorAlert(err = 'Error processing request') {
    update({
      key: KEY,
      title: err,
      dismissable: true,
      type: 'error',
    });
  },
  processing: function processingAlert() {
    alert({
      key: KEY,
      title: 'Processing',
      dismissable: false,
      type: 'processing',
    });
  },
  success: function successAlert() {
    update({
      key: KEY,
      title: 'Done',
      dismissable: true,
      duration: 3000,
      type: 'success',
    });
  },
});
