import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { FC, Fragment, PropsWithChildren, ReactChild, useRef } from 'react';

export type ModalBaseProps = PropsWithChildren<{
  dismissable?: boolean;
  onClose?: () => void;
  open?: boolean;
  title?: string;
  sidebar?: ReactChild;
  forceFocus?: boolean;
}>;

export const ModalBase: FC<ModalBaseProps> = ({
  dismissable = true,
  title,
  onClose,
  open = false,
  children,
  sidebar,
  forceFocus,
}) => {
  const fakeFocusRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => onClose && onClose()}
        initialFocus={fakeFocusRef}
      >
        {forceFocus && <button className="opacity-0" ref={fakeFocusRef} />}
        <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full rounded-lg bg-white px-4 pb-4 pt-5 align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                {sidebar}
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                  <div className="flex flex-row items-center justify-between">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {dismissable && (
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2"
                        onClick={() => onClose && onClose()}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="size-6" />
                      </button>
                    )}
                  </div>
                  {children && <div className="mt-2">{children}</div>}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
