import { ReactNode, FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { LABEL_CLASSES } from 'components/forms';

export type HeaderProps = PropsWithChildren<{
  action?: ReactNode;
  className?: string;
  wrapClasses?: string;
}>;

export const Header: FC<HeaderProps> = ({
  action,
  children,
  className,
  wrapClasses,
}) => (
  <div
    className={twMerge(
      'mb-4 flex flex-nowrap justify-between border-b border-light pb-2 sm:pb-3',
      wrapClasses
    )}
  >
    <div className={className ?? LABEL_CLASSES}>{children}</div>
    <div>{action}</div>
  </div>
);
