import { VFC } from 'react';
import dayjs from 'dayjs';
import { BaseInfoCardProps, InfoCard } from 'components';
import { User_Licence } from 'database/types';

export type LicenceCardProps = BaseInfoCardProps &
  Pick<User_Licence, 'organisation' | 'issue_date' | 'expires' | 'expiry_date'>;

export const LicenceCard: VFC<LicenceCardProps> = ({
  organisation: company,
  issue_date,
  expiry_date,
  expires,
  ...props
}) => {
  const start = dayjs(issue_date).format('MMM YYYY');
  const end = dayjs(expiry_date).format('MMM YYYY');
  const corner_info = `${start} - ${expires ? end : 'present'}`;

  return <InfoCard subtitle={company} corner_info={corner_info} {...props} />;
};
