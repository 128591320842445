import { Menu, Transition } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';
import React, { Fragment, FC } from 'react';
import { Avatar } from 'components';
import { useAuthContext } from 'components/AuthContext';
import {
  ChevronLeft,
  LogOutIcon,
  ProfileIcon,
  SavedJobIcon,
  SettingsIcon,
  JobIcon,
} from 'components/icons';
import { Item } from '.';

type UserMenuProps = {
  type: 'employee' | 'employer';
};

export const UserMenuMObile: FC<UserMenuProps> = ({ type }) => {
  const { user } = useAuthContext();

  const page = type === 'employer' ? 'portal' : 'employee';
  const subPage = type === 'employer' ? '/jobs' : '';

  return (
    <Menu as="div" className="relative z-0 ml-3">
      {({ open, close }) => (
        <>
          <Menu.Button>
            <span className="sr-only">Open user menu</span>
            <div className="relative z-10 flex items-center">
              <p className="mr-2 hidden font-satoshi text-lg font-medium leading-7 text-charcoal-500">
                {user?.given_name} {user?.family_name}
              </p>
              <Avatar size="tiny" alt="User Profile Picture" />
              <div className="absolute bottom-0 right-0 size-4 rounded-full bg-white p-1">
                <ChevronLeft
                  className={twJoin(
                    'size-2 transition-transform duration-200 ease-in-out',
                    open ? 'transform rotate-180' : 'transform rotate-0'
                  )}
                />
              </div>
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity" />
          </Transition>
          <Transition
            show={open}
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Menu.Items
              static
              className="fixed right-0 top-0 z-20 h-screen w-max"
            >
              <div className="flex h-full origin-top-right flex-col divide-y divide-charcoal-50 bg-cream-500 p-4 py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="relative z-0 px-4 py-3 font-satoshi">
                  <ChevronLeft
                    onClick={() => close()}
                    className="absolute -left-3 top-6 size-5 rotate-90 cursor-pointer"
                    aria-label="Close user menu"
                  />
                  <Avatar size="tiny" alt="User Profile Picture" />
                  <p className="text-lg font-bold leading-7 text-charcoal-500">
                    {user?.given_name} {user?.family_name}
                  </p>
                  <p className="text-xs font-medium leading-5 text-charcoal-300">
                    {user?.email}
                  </p>
                </div>
                {(() => {
                  return (
                    <div className="grow py-1">
                      <Item
                        href={`/jobs`}
                        text="Find a Job"
                        icon={<JobIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href={`/${page}${subPage}`}
                        text="Saved Jobs"
                        icon={<SavedJobIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href={`/${page}/profile`}
                        text="Profile"
                        icon={<ProfileIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href="/employee/settings"
                        text="Settings"
                        icon={<SettingsIcon className="mr-2 size-5" />}
                      />
                      <Item
                        href={`${type === 'employer' ? '/portal' : ''}/auth/signout`}
                        text="Sign out"
                        icon={<LogOutIcon className="mr-2 size-5" />}
                      />
                    </div>
                  );
                })()}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
