import { cloneElement, ReactElement } from 'react';

import { Control, Path, useWatch } from 'react-hook-form';

export interface WatchValueProps<T> {
  control: Control<any>;
  el: ReactElement;
  name: Path<T>;
}

export function WatchValue<FieldData>({
  control,
  el,
  name,
}: WatchValueProps<FieldData>): ReactElement {
  const value = useWatch<any>({ control, name });

  return cloneElement(el, { [name]: value });
}
