import dayjs from 'dayjs';
import { VFC } from 'react';
import { BaseInfoCardProps, InfoCard } from 'components';
import { User_Experience } from 'database/types';

export type ExperienceCardProps = BaseInfoCardProps &
  Pick<User_Experience, 'company' | 'start_date' | 'end_date' | 'current'>;

export const ExperienceCard: VFC<ExperienceCardProps> = ({
  company,
  start_date,
  end_date,
  current,
  ...props
}) => {
  const start = dayjs(start_date).format('MMM YYYY');
  const end = dayjs(end_date).format('MMM YYYY');
  const corner_info = `${start} - ${current ? 'present' : end}`;

  return <InfoCard subtitle={company} corner_info={corner_info} {...props} />;
};
