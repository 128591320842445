import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAuthContext } from 'components/AuthContext';

export type AuthAxios = Pick<AxiosInstance, 'get' | 'post' | 'put' | 'delete'>;

export const useAuthenticatedAxios = (): AuthAxios => {
  const { user } = useAuthContext();
  const headers = user ? { Authorization: `Bearer ${user?.token}` } : undefined;

  return {
    delete: (url, config) => axios.delete(url, mergeConfig(config, headers)),
    get: (url, config) => axios.get(url, mergeConfig(config, headers)),
    post: (url, data, config) =>
      axios.post(url, data, mergeConfig(config, headers)),
    put: (url, data, config) =>
      axios.put(url, data, mergeConfig(config, headers)),
  };
};

export const useAxiosSWR = () => {
  const authenticatedAxios = useAuthenticatedAxios();

  return {
    delete: async <T = any>(...args: Parameters<AuthAxios['delete']>) =>
      (await authenticatedAxios.delete<T>(...args)).data,
    get: async <T = any>(...args: Parameters<AuthAxios['get']>) =>
      (await authenticatedAxios.get<T>(...args)).data,
    post: async <T = any>(...args: Parameters<AuthAxios['post']>) =>
      (await authenticatedAxios.post<T>(...args)).data,
    put: async <T = any>(...args: Parameters<AuthAxios['put']>) =>
      (await authenticatedAxios.put<T>(...args)).data,
  };
};

const mergeConfig = (
  config?: AxiosRequestConfig,
  headers?: AxiosRequestConfig['headers']
) => ({ ...config, headers: { ...config?.headers, ...headers } });
