import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import { useMessages } from 'components';

type State = {
  loading: boolean;
  error?: Error | null;
};

type Props = {
  employerId?: string;
  jobId?: string;
};

const ALERT_KEY = 'RELIST_ALERT';

export const useRelist = () => {
  const [state, setState] = useState<State>({
    loading: false,
    error: undefined,
  });
  const router = useRouter();
  const { alert, update } = useMessages();
  const { query } = router;
  const { loading } = state;

  const relist = useCallback(
    async (args: Props = {}) => {
      if (loading) return;
      setState({ loading: true });
      alert({
        key: ALERT_KEY,
        type: 'processing',
        title: 'Processing request',
        dismissable: false,
        duration: null,
      });
      const { employerId, jobId } = args;
      const eId = employerId ?? query?.employerId;
      const jId = jobId ?? query?.jobId;
      if (!(eId && jId)) throw Error('Missing id');
      try {
        const {
          data: { id },
        } = await axios.post(`/api/jobs/${jId}/relist`);

        router.push({
          pathname: '/portal/[employerId]/job/[jobId]/post/1',
          query: {
            employerId: eId,
            jobId: id,
          },
        });
        update({
          key: ALERT_KEY,
          type: 'success',
          title: 'Created new listing',
          duration: 3000,
          dismissable: true,
        });
      } catch (err) {
        if (err instanceof Error) {
          alert({
            key: ALERT_KEY,
            type: 'error',
            title: 'Issue relisting job',
            message: err.message,
            dismissable: true,
          });
          setState({ ...state, error: err });
        }
        captureException(err);
      } finally {
        setState({ ...state, loading: false });
      }
    },
    [query.employerId]
  );

  return {
    ...state,
    relist,
  };
};
