import React, { VFC } from 'react';
import { NewsLetter } from 'components/forms';

export type CTAProps = {
  ctaTitle: string;
  ctaDescription: string;
};

export const NewsletterCTA: VFC<CTAProps> = ({ ctaTitle, ctaDescription }) => (
  <div className="bg-grey-dark">
    <div
      className="custom-container relative mx-auto grid size-full min-h-[400px] justify-center gap-x-16 gap-y-8 py-20 text-left md:grid-cols-2"
      style={{ minHeight: 'fit-content' }}
    >
      <div className="self-center">
        <h1 className="text-2xl font-medium text-white">{ctaTitle}</h1>
        <p className="mt-3 text-white md:mt-5">{ctaDescription}</p>
      </div>

      {/* Form */}
      <NewsLetter hash="_cta" />
    </div>
  </div>
);
