import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { ControllerRenderProps } from 'react-hook-form';
import { Errors, useFieldError } from 'components/forms/Errors';
import { Maybe } from 'database/types';
import useIsBrowser from 'utils/hooks/useIsBrowser';

type SelectOption<T = string | number> = {
  disabled?: boolean;
  value: T;
  label: ReactNode;
};

type SelectProps<T = string | number> = {
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  loading?: boolean;
  name?: string;
  onChange: ControllerRenderProps['onChange'];
  options?: SelectOption<T>[];
  value?: SelectOption<T[]>['value'];
};

export function SelectCloud<T = string>({
  className,
  disabled,
  label,
  name,
  onChange,
  options,
  value = [],
}: SelectProps<T>): Maybe<JSX.Element> {
  const { error } = useFieldError(name);
  const isBrowser = useIsBrowser();

  if (!isBrowser) return null;

  const handleChange = (next: T): void => {
    if (disabled) return;
    const idx = value.indexOf(next);
    const res = [...value];

    if (idx > -1) {
      res.splice(idx, 1);
    } else {
      res.push(next);
    }
    onChange(res);
  };

  return (
    <div className={twMerge('relative', error ? 'mb-2' : 'mb-8', className)}>
      <fieldset>
        <legend className="mb-1 block text-base font-normal tracking-wider text-black">
          {label}
        </legend>
        <div className="flex flex-wrap space-x-2">
          {options?.map((option, idx) => (
            <label
              key={`${option.value}_${idx}`}
              htmlFor={`option-${option.value}`}
              className={twMerge(
                'relative m-2 flex cursor-pointer items-center rounded-full border p-1 px-2 align-middle text-sm hover:border-primary',
                value.includes(option.value)
                  ? 'border-primary bg-primary-500 text-white'
                  : 'border-gray-500 text-gray-500'
              )}
            >
              <input
                id={`option-${option.value}`}
                checked={value?.includes(option.value) ?? false}
                onChange={() => null}
                onClick={() => handleChange(option.value)}
                name={`name[${idx}]`}
                type="checkbox"
                className="mr-2 size-4 cursor-pointer rounded border-gray-300 text-primary focus:ring-primary"
              />
              {option.label}
            </label>
          ))}
        </div>
      </fieldset>
      <Errors error={error} />
    </div>
  );
}
