import { LinkProps } from 'next/link';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'components/Link';

export type TileProps = PropsWithChildren<{
  img?: string;
}>;

export type CategoryTileProps = TileProps & LinkProps;

const Tile: FC<TileProps> = ({ img, children }) => (
  <div className="w-full">
    <div className="aspect-h-1 aspect-w-1">
      {/* Background image */}
      {img ? (
        <img className="object-cover" src={img} alt="Tools on a table" />
      ) : (
        <img
          className="object-cover"
          src={'/assets/images/tools-bg.png'}
          alt="Tools on a table"
        />
      )}

      {/* Overlay*/}
      <div className="inset-0 bg-black opacity-30" />
      {/* Contents */}
      <div className="absolute inset-0 flex place-items-center px-5 py-6">
        <span className="w-full text-center font-medium text-white">
          {children}
        </span>
      </div>
    </div>
  </div>
);

export const CategoryTile: FC<CategoryTileProps> = ({
  img,
  children,
  ...props
}) =>
  props ? (
    <Link {...props}>
      <Tile img={img}>{children}</Tile>
    </Link>
  ) : (
    <Tile img={img}>{children}</Tile>
  );
