import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useJitsu } from '@jitsu/nextjs';
import {
  InsertLicenceDocument,
  UpdateLicenceByPkDocument,
  User_Licence,
} from 'database/types';
import { DateISOString } from 'types/utils';
import { useFormSubmit } from 'utils';
import { UpdatedInput } from 'components/forms/UpdatedFormInput';
import { UpdatedMonthPicker } from 'components/forms/UpdatedMonthPicker';
import { UpdatedRadioBoolean } from 'components/forms/UpdatedRadioBoolean';
import { ErrorProvider, ModalFormButtons, WatchValue } from '..';
import { CustomInputDate } from './CustomInputDate';
import Validation from './Validation';

const DEFAULT_LICENCE = {
  id: null,
  title: '',
  organisation: '',
  issue_date: undefined,
  expires: undefined,
  expiry_date: undefined,
  updated_at: null,
};

const formatDefault = ({
  id,
  title,
  organisation,
  issue_date,
  expires,
  expiry_date,
}: User_Licence): LicenceData => ({
  ...(id && {
    id,
    updated_at: new Date().toISOString(),
  }),
  title,
  organisation,
  expires,
  expiry_date,
  issue_date,
});

export type LicenceData = {
  id?: string;
  title: string;
  organisation: string;
  issue_date: DateISOString;
  expires: boolean;
  expiry_date?: DateISOString | null;
  updated_at?: DateISOString | null;
};

type LicenceProps = {
  defaultValues?: User_Licence;
  onCancel: () => void;
};

export const Licence: FC<LicenceProps> = ({
  defaultValues = DEFAULT_LICENCE,
  onCancel,
}) => {
  const isUpdate = !!defaultValues?.id;
  const initialValues = formatDefault(defaultValues as User_Licence);
  const methods = useForm<LicenceData>({
    defaultValues: formatDefault(defaultValues as User_Licence),
    resolver: yupResolver(Validation),
  });
  const { control, formState, handleSubmit, register, setValue } = methods;

  const resetField = (a: keyof LicenceData) => setValue(a, DEFAULT_LICENCE[a]);
  const { track } = useJitsu();

  const onSubmit = useFormSubmit({
    formName: 'Licence',
    mutation: isUpdate ? UpdateLicenceByPkDocument : InsertLicenceDocument,
    onSuccess: {
      message: `Licence ${initialValues.id ? 'updated' : 'saved'}`,
      callback: onCancel,
    },
  });

  return (
    <FormProvider {...methods}>
      <form
        id="licence"
        onSubmit={handleSubmit((vars) => {
          onSubmit(vars);
          track('licence_added');
        })}
        className="my-6"
      >
        <ErrorProvider errors={formState.errors}>
          <UpdatedInput
            {...register('title')}
            required
            autoCapitalize="false"
            label="Licence name"
            placeholder="Licence name"
            customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
          />
          <UpdatedInput
            {...register('organisation')}
            required
            label="Issuing organisation"
            placeholder="Organisation name"
            customLabel="text-charcoal-500 font-bold text-sm leading-5 mb-1 text-left"
          />
          {/**  TODO: Custom MonthPicker */}
          <UpdatedMonthPicker
            required
            name="issue_date"
            control={control}
            label="Issue date"
            placeholder="Issue date"
          />
          <UpdatedRadioBoolean
            name="expires"
            control={control}
            label="Does this Licence expire?"
          />
          <WatchValue<LicenceData>
            control={control}
            name="expires"
            el={
              <CustomInputDate
                required
                name="expiry_date"
                control={control}
                resetField={resetField}
                type="date"
                label="Expiry date"
                placeholder="Expiry date"
              />
            }
          />

          <ModalFormButtons onCancel={onCancel} />
        </ErrorProvider>
      </form>
    </FormProvider>
  );
};
