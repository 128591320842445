import {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  FC,
  PropsWithChildren,
} from 'react';
import LinkDefault, { LinkProps as LinkPropsDefault } from 'next/link';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';

export type LinkProps = {
  showActive?: boolean;
  hover?: boolean;
} & PropsWithChildren<
  Omit<LinkPropsDefault, 'href'> & {
    href?: LinkPropsDefault['href'];
    className?: string;
    htmlProps?: Pick<
      DetailedHTMLProps<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >,
      'target' | 'onClick' | 'rel'
    >;
  }
>;

export const Link: FC<LinkProps> = ({
  showActive = false,
  className,
  href = '',
  children,
  htmlProps,
  scroll,
  ...props
}) => {
  const { asPath } = useRouter();
  const [path] = asPath.split(/[?#]+/g);
  const active =
    typeof href === 'string' ? href === path : href?.pathname === path;
  return (
    <LinkDefault
      href={href}
      {...props}
      {...htmlProps}
      scroll={scroll ?? !!href}
      passHref
      className={twMerge(
        className,
        showActive && active && 'border-b-2 border-primary'
      )}
    >
      {children}
    </LinkDefault>
  );
};
