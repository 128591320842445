import dayjs from 'dayjs';
import React, { VFC } from 'react';
import { Dropdown, FullPageLoader, useModalContext } from 'components';
import { Item } from 'components/Dropdown/Item';
import { ModalProps } from 'components/Modal';
import { makeLabel } from 'components/SubscriptionController/helpers';
import { NoSubs } from 'components/Subscriptions/NoSubs';
import {
  BillingInterval,
  PageSuscriptionWithInterval,
  SubscriptionPrice,
  usePaymentMethods,
  usePrices,
  useSubscriptions,
} from 'utils/stripe';

export const Subscriptions: VFC = () => {
  const {
    customerSubscriptions: { page: currentSub },
    update,
    cancel,
    renew,
    loading,
  } = useSubscriptions();

  const { prices, loading: loadingPrices } = usePrices();
  const { defaultId } = usePaymentMethods();
  const { modal, dismiss } = useModalContext();

  if (loading || loadingPrices) return <FullPageLoader />;
  if (!currentSub) return <NoSubs />;

  const { cancel_at_period_end, current_period_end, items } = currentSub;

  const endDate = dayjs(current_period_end * 1000).format('DD/MM/YYYY');
  const subscriptionItem = items.data[0];
  const price = subscriptionItem.price as unknown as SubscriptionPrice;
  const label = makeLabel(price);

  // pick opposite price (monthly/annually) for switching
  const currentInterval = price.metadata.billing;
  const oppositeInterval: BillingInterval =
    currentInterval === 'monthly' ? 'yearly' : 'monthly';
  const key: PageSuscriptionWithInterval = `page_${oppositeInterval}`;
  const oppositePrice = prices?.subscriptions?.[key];

  const handleSwitch = async () => {
    if (!currentSub.id || !oppositePrice?.id) return;
    if (!defaultId) return modal(MODAL_NoDefaultCard);

    const onConfirm = async () => {
      modal(MODAL_SwitchProcessing);
      await update(currentSub.id, [
        { id: subscriptionItem.id, price: oppositePrice?.id },
      ]);
      dismiss();
    };

    modal({
      title: 'Change Billing Interval',
      description: `Confirm switching to ${oppositeInterval} billing? The amount you already paid for the current plan will be used as credit for the next charges going forward.`,
      onConfirm,
    });
  };

  const handleCancel = async () => {
    if (!currentSub.id) return;

    const onConfirm = async () => {
      modal(MODAL_CancelProcessing);
      await cancel(currentSub.id);
      dismiss();
    };

    modal({ ...MODAL_CancelConfirm, onConfirm });
  };

  const handleRenew = async () => {
    if (!currentSub.id) return;
    if (!defaultId) return modal(MODAL_NoDefaultCard);

    const onConfirm = async () => {
      modal(MODAL_RenewProcessing);
      await renew(currentSub.id);
      dismiss();
    };

    modal({ ...MODAL_RenewConfirm, onConfirm });
  };

  return (
    <div className="flex flex-nowrap justify-between rounded border border-light bg-white p-4">
      <div className="flex flex-col">
        <div>
          <div className="mr-2 font-medium">Employer Page</div>{' '}
          {!cancel_at_period_end && (
            <span className="whitespace-nowrap text-sm text-grey">{label}</span>
          )}
        </div>
        <div className="text-xs text-grey-dark">
          {cancel_at_period_end ? 'Ends on ' : 'Renews on '}
          {endDate}
        </div>
      </div>
      <Dropdown>
        <Item title={`Switch to ${oppositeInterval}`} onClick={handleSwitch} />
        {cancel_at_period_end ? (
          <Item title="Set auto-renewal" onClick={handleRenew} />
        ) : (
          <Item title="Cancel" onClick={handleCancel} />
        )}
      </Dropdown>
    </div>
  );
};

const MODAL_NoDefaultCard: ModalProps = {
  title: 'Card Missing',
  description:
    'You have no registered cards. Please add a card on the page below and try again.',
  type: 'warning',
};
const MODAL_SwitchProcessing: ModalProps = {
  title: 'Change Billing Interval',
  description: `Processing...`,
};
const MODAL_CancelProcessing: ModalProps = {
  title: 'Cancel Subscription',
  description: `Processing...`,
};
const MODAL_RenewProcessing: ModalProps = {
  title: 'Set up Auto Renewal',
  description: `Processing...`,
};
const MODAL_RenewConfirm: ModalProps = {
  title: 'Set up Auto Renewal',
  description: `Confirm setting up automatic renewal? Your default card will be charged at the end of the current subscription period.`,
};
const MODAL_CancelConfirm: ModalProps = {
  title: 'Cancel Subscription',
  description: `Confirm cancelling your subscription? Your page will stay active until the end of the current subscription period.`,
};
