// Framework
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'framer-motion';
import Image from 'next/image';

// Third Parties
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import { EmployerText } from 'components/selfService/typography/EmployerText';

// Content
import { Maybe } from 'database/types';
import BENEFITS_ITEMS from './content';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function WhatWeDo(): JSX.Element {
  const sectionRef = useRef<Maybe<HTMLDivElement>>(null);
  const sizeRef = useRef<Maybe<HTMLDivElement>>(null);
  const frameInView = useInView(sectionRef);
  const [height, setHeight] = useState<number>(0);
  const { asPath } = useRouter();

  const [, hash] = asPath.split('#');

  const contactFormScroll = hash === '__contact_employer';

  useEffect(() => {
    const scroller = document?.getElementById('__scroll_target');

    // We can kill the event listener when the frame is out of view
    if (!frameInView || contactFormScroll) return;

    // Event just needs to get top scroll when it becomes negative i.e. top of div is off top of screen
    function onScroll() {
      const top = sectionRef.current?.getBoundingClientRect()?.top;
      if (!top || scroller === null) return;
      scroller.scrollLeft = -top;
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [frameInView, hash]);

  // Creating an oversized square so that we can continue vertical scrolling while creating horizontal offset
  useEffect(() => {
    if (sectionRef.current === null || sizeRef.current === null || !window)
      return;
    setHeight(sizeRef.current.scrollWidth - sizeRef.current.offsetWidth);
  }, [sizeRef, sectionRef]);

  return (
    <section className="relative flex h-full flex-col" ref={sectionRef}>
      <div className="sticky top-0 mx-auto w-full max-w-screen-2xl shrink md:pr-0">
        <div
          className={twMerge(
            'flex flex-col justify-center',
            contactFormScroll ? 'h-full py-16' : 'h-[calc(100vh_-_68px)]'
          )}
        >
          <EmployerText as="h2" size="xl" className="mb-2 mt-8 text-orange-500">
            What we do
          </EmployerText>
          <EmployerText
            as="h2"
            size="5xl"
            className="mb-8 text-charcoal-500 md:mb-12"
          >
            How we can help you fill your trade vacancy.
          </EmployerText>
          <div className="-mx-8 md:-mx-12 lg:-mx-24">
            <div
              ref={sizeRef}
              id="__scroll_target"
              className="no-scrollbar flex w-full space-x-8 overflow-x-scroll px-8 md:px-12 lg:px-24"
            >
              {BENEFITS_ITEMS.map((item, index) => (
                <div key={index}>
                  <div className="relative z-0 mb-6 aspect-[527/300] h-auto w-[360px] md:w-[527px]">
                    <Image
                      className="rounded-2xl border border-solid border-orange-400 object-cover"
                      src={item.image}
                      alt=""
                      fill
                      sizes="(max-width: 768px) 95vw, (max-width: 1200px) 50vw, 33vw"
                    />
                  </div>
                  <EmployerText
                    as="h4"
                    size="3xl"
                    className="mb-2 text-orange-500"
                  >
                    {item.title}
                  </EmployerText>
                  <EmployerText as="p" size="lg" className="text-charcoal-400">
                    {item.description}
                  </EmployerText>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ minHeight: height }}></div>
    </section>
  );
}
