const BelowVector = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.9039 19.8335C11.4436 20.1386 10.823 20.0129 10.5179 19.5525L5.54503 12.0511C5.23987 11.5908 5.36566 10.9703 5.82598 10.6651C6.2863 10.36 6.90685 10.4857 7.21201 10.9461L11.6323 17.614L18.3002 13.1937C18.7605 12.8885 19.3811 13.0143 19.6862 13.4746C19.9914 13.935 19.8656 14.5555 19.4053 14.8607L11.9039 19.8335ZM0 0H5.21568V2H0V0ZM14.0363 10.788L12.3314 19.1987L10.3713 18.8013L12.0762 10.3906L14.0363 10.788ZM5.21568 0C10.9046 0 15.1665 5.2124 14.0363 10.788L12.0762 10.3906C12.9552 6.05409 9.64042 2 5.21568 2V0Z"
      fill="#F17732"
    />
  </svg>
);

export default BelowVector;
