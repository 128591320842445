import { VFC, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { twMerge } from 'tailwind-merge';
import isMobile from 'is-mobile';
import dynamic from 'next/dynamic';
const ApplyingComponent = dynamic(
  () => import('components/JobListing/ApplyingComponent')
);
const AboutJob = dynamic(() => import('components/JobListing/AboutJob'));
const JobRequirement = dynamic(
  () => import('components/JobListing/JobRequirement')
);
const JobQuestions = dynamic(
  () => import('components/JobListing/JobQuestions')
);
const RateComponent = dynamic(
  () => import('components/JobListing/RateComponent')
);
const JobVideo = dynamic(() => import('components/JobListing/JobVideo'));
import { useRouter } from 'next/router';
import {
  Enum_Category,
  Enum_Work_Type,
  Integration_Job,
  Job,
  JobWithEmployerFragment,
  Job_Question,
  Maybe,
} from 'database/types';
import { useMessages } from 'components';
import { LocationObject } from 'utils';
import JobTopLogo from 'components/JobListing/JobTopLogo';
import { initJobListVariables } from 'components/JobListing/helpers';
import JobHeader from 'components/JobListing/JobHeader';
import JobCompanyInfo from 'components/JobListing/JobCompanyInfo';
import ExpressionOfInterest from 'components/JobListing/ExpressionOfInterest';

export type JobListingProps = Pick<
  JobWithEmployerFragment,
  | 'id'
  | 'slug'
  | 'about'
  | 'title'
  | 'pay_low'
  | 'pay_high'
  | 'pay_type'
  | 'show_pay'
  | 'listing_type'
  | 'show_banner'
  | 'apply_url'
> & {
  category: Pick<Enum_Category, 'label'>;
  work: Pick<Enum_Work_Type, 'label'>;
  employer?: NonNullable<JobWithEmployerFragment['employer_computed']>[number];
  location?: LocationObject | null;
  requirements?: string[];
  isSaved?: boolean;
  isNew?: boolean;
  job_questions?: Pick<Job_Question, 'id' | 'question' | 'type' | 'job_id'>[];
  integration?: Pick<
    Integration_Job,
    'integration_type' | 'integration_fkey' | 'data' | 'application_url'
  >;
  created_by_user_id?: Maybe<string>;
  metadata?: {
    image?: string;
  };
};

type JobListingCardProps = JobListingProps & {
  applying?: boolean;
};

export const JobListing: VFC<JobListingCardProps> = ({
  applying,
  apply_url,
  id,
  listing_type,
  slug,
  title,
  location,
  requirements,
  pay_low,
  pay_high,
  category,
  pay_type,
  show_banner,
  show_pay,
  about,
  employer,
  work,
  integration,
  job_questions,
  metadata,
}) => {
  const [expanded, setExpanded] = useState(false);
  const router = useRouter();
  const { query } = router;
  const { alert } = useMessages();

  useEffect(() => {
    setExpanded(false);
  }, [query.jobSlug]);

  const {
    name: company,
    description: company_description,
    slug: employer_slug,
    cover_photo,
    video,
    page_active,
    parent,
  } = employer ?? {};

  const {
    isTrimmed,
    descriptionTrimmed,
    externalApplicationLink,
    employerSlug,
    pageActive,
    applyExternally,
    descriptionText,
    locationString,
    hasDescription,
    noPaddingTop,
  } = initJobListVariables({
    location,
    company_description,
    integration,
    apply_url,
    id,
    listing_type,
    show_banner,
    cover_photo,
    page_active,
    parent,
    employer_slug,
  });

  const handleShare = async () => {
    const url = `${process.env.NEXT_PUBLIC_SITE_URL}/job/${slug}`;
    const shareData: ShareData = {
      title,
      text: `View this ${title} job on TradeJobsNZ`,
      url,
    };

    if (isMobile() && navigator.share) {
      navigator.share(shareData);
      return;
    }
    try {
      await navigator.clipboard.writeText(url);
      alert({
        key: 'SHARE_OK',
        title: 'Link copied to clipboard!',
        type: 'success',
      });
    } catch (e) {
      captureException(e);
    }
  };

  if (listing_type === ('expression_of_interest' as Job['listing_type'])) {
    return (
      <ExpressionOfInterest
        id={id}
        title={title}
        about={about}
        slug={slug}
        metadata={metadata as any}
        applying={applying}
      />
    );
  }

  return (
    <div
      className={twMerge(
        'relative default-padding space-y-6 overflow-hidden',
        'text-clip rounded-lg bg-white p-4 sm:p-8 font-satoshi',
        'border border-solid border-charcoal-50',
        noPaddingTop && '!pt-0'
      )}
    >
      <JobTopLogo logo={employer?.logo} cover_photo={employer?.cover_photo} />
      <div className="relative pt-36">
        <div>
          <JobHeader
            title={title}
            pageActive={pageActive}
            employerSlug={employerSlug}
            company={company}
            category={{ label: category.label }}
            locationString={locationString}
            work={{ label: work.label }}
          />
          {pay_high && show_pay && (
            <RateComponent
              payLow={pay_low}
              payType={pay_type}
              payHigh={pay_high}
            />
          )}
          <ApplyingComponent
            applying={applying}
            slug={slug}
            id={id}
            listing_type={listing_type as Job['listing_type']}
            externalApplicationLink={externalApplicationLink}
            applyExternally={applyExternally}
            handleShare={handleShare}
          />
          <div className="!mt-10 space-y-10">
            {about && <AboutJob about={about} />}
            {requirements && requirements?.length > 0 && (
              <JobRequirement requirements={requirements} />
            )}
            {job_questions && job_questions.length > 0 && (
              <JobQuestions jobQuestions={job_questions} />
            )}
            {video && <JobVideo video={video} />}
          </div>
        </div>
      </div>
      <JobCompanyInfo
        hasDescription={hasDescription}
        pageActive={pageActive}
        company={company}
        descriptionTrimmed={descriptionTrimmed}
        expanded={expanded}
        descriptionText={descriptionText}
        isTrimmed={isTrimmed}
        setExpanded={setExpanded}
        applying={applying}
        employerSlug={employerSlug}
      />
    </div>
  );
};
