import { UseFormSetValue } from 'react-hook-form';
import { useEffect } from 'react';
import { useGetEmployerSubscriptionQuery } from 'database/types';
import { Select } from 'components/forms';
import type { SelectProps } from 'components/forms';

type Props = Omit<SelectProps, 'options' | 'name'> & {
  name: string;
  setValue: UseFormSetValue<any>;
};

export function SelectEmployer({
  setValue,
  ..._props
}: Props): JSX.Element | null {
  const { data, loading } = useGetEmployerSubscriptionQuery({
    fetchPolicy: 'cache-and-network',
  });

  const options = !loading
    ? data?.employer.map(({ id, name }) => ({ value: id, label: name }))
    : [];

  useEffect(() => {
    if (!options) return;
    if (options.length === 1) setValue(_props.name, options[0].value);
  }, [options]);

  if (loading || !options || options.length === 1) return null;

  return <Select {..._props} options={options} />;
}
