import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import ApplicantSideBar from 'components/pages/PortalEmployer/ApplicantSideBar';
import { useGetJobApplicationsByJobIdQuery } from 'database/types';

type ManageJobsSelectedProps = {
  jobId: string;
  setJobSelectedLoading: (loading: boolean) => void;
};

const ManageJobsSelected: React.FC<ManageJobsSelectedProps> = ({
  jobId,
  setJobSelectedLoading,
}) => {
  const [applicationId, setApplicationId] = useState<string | null>(null);
  const [allApplicantsIds, setAllApplicantsIds] = useState<string[]>([]);
  const { data, loading } = useGetJobApplicationsByJobIdQuery({
    variables: { id: jobId },
    pollInterval: 30000,
  });

  useEffect(() => {
    setApplicationId(null);
    if (data && data?.job?.applications[0].id) {
      setApplicationId(data?.job?.applications[0].id);
      setAllApplicantsIds(data?.job?.applications.map(({ id }) => id) ?? []);
    }
  }, [data]);

  useEffect(() => {
    if (applicationId) {
      setJobSelectedLoading(false);
    }
  }, [applicationId]);

  return (
    <AnimatePresence>
      <ApplicantSideBar
        applicationId={applicationId}
        setApplicationId={setApplicationId}
        allApplicantsIds={allApplicantsIds}
      />
    </AnimatePresence>
  );
};

export default ManageJobsSelected;
