import { FC } from 'react';
import Stripe from 'stripe';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Loading } from 'components/icons';

type Props = Stripe.Discount & {
  onDelete?: () => void;
  loading?: boolean;
};

export const DiscountLine: FC<Props> = ({
  coupon: { name },
  onDelete,
  loading = false,
}) => (
  <span className="flex flex-row items-center justify-between text-sm font-medium text-black">
    <div className="flex flex-row items-center">
      <div>
        COUPON:
        <span className="ml-2 rounded border border-green-500 bg-green-100 px-1 text-green-500">
          {name}
        </span>
      </div>
      {onDelete ? (
        loading ? (
          <Loading className="ml-2 size-4" />
        ) : (
          <button
            type="button"
            className="ml-2 inline-flex rounded-md bg-transparent p-1 text-gray-500 hover:bg-gray-200 hover:ring-2 hover:ring-gray-600 hover:ring-offset-gray-50 focus:outline-none"
            onClick={onDelete}
          >
            <XMarkIcon className="size-4" aria-hidden="true" />
          </button>
        )
      ) : null}
    </div>
  </span>
);
