// Framework
import { useState } from 'react';

// Third Parties
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { useMessages } from 'components/Messages';
import { ErrorProvider } from 'components/forms';
import { schema, onSubmit, ContactFormValues } from '../onSubmit';
import { Input } from './Input';

// Preps
const MESSAGE_KEY = 'CONTACT_FORM_SLACK_N8N';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function NewContactForm() {
  const { alert, update } = useMessages();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      company: '',
      role: '',
      location: '',
      integration: '',
      requirements: [],
      email: '',
      phoneNumber: '',
    },
    resolver: yupResolver(schema) as any,
  });

  const submit = async (a: ContactFormValues): Promise<void> => {
    if (loading) return;
    alert({
      key: MESSAGE_KEY,
      title: 'Processing request',
      type: 'processing',
      dismissable: false,
    });
    setLoading(true);
    try {
      await onSubmit(a);

      update({
        key: MESSAGE_KEY,
        title: 'Message sent',
        type: 'success',
        duration: 3000,
        dismissable: true,
      });
      reset();
    } catch (err) {
      update({
        key: MESSAGE_KEY,
        title: 'Something went wrong',
        message: (err as any)?.message,
        type: 'error',
        duration: 3000,
        dismissable: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      id="__contact_employer"
      onSubmit={handleSubmit((a) => submit(a))}
      className="max-w-[427px]"
    >
      <EmployerText as="p" size="2xl" className="mb-6 font-bold text-white">
        Request a call back
      </EmployerText>
      <EmployerText as="p" size="lg" className="mb-6 text-orange-50">
        We aim to respond to requests within 20 minutes during office hours.
      </EmployerText>
      <ErrorProvider errors={errors as any}>
        <Input
          className="text-white"
          {...register('firstName')}
          label="Your name"
          placeholder="Name"
          required
        />
        <Input
          {...register('phoneNumber')}
          label="Your number"
          placeholder="Phone number"
          required
        />
        <Input
          {...register('company')}
          label="Company name"
          placeholder="Company name"
          required
        />
        <Input
          label="Email address"
          placeholder="Email address"
          required
          {...register('email')}
        />
        <div className="w-full">
          <button
            type="submit"
            className="h-12 w-full rounded-full border border-solid border-orange-500 bg-orange"
          >
            <p className="font-medium leading-6 text-white">
              Request Call Back
            </p>
          </button>
        </div>
      </ErrorProvider>
    </form>
  );
}
