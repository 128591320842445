import { twMerge } from 'tailwind-merge';
import { SVGProps, VFC } from 'react';

export const ProfileIcon: VFC<SVGProps<SVGSVGElement>> = ({
  stroke = '#242222',
  className,
  ...props
}) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twMerge('h-full w-full', className)}
    {...props}
  >
    <path
      d="M8.00004 11.5C5.35831 11.5 3.00904 12.7755 1.51337 14.755C1.19146 15.181 1.0305 15.394 1.03577 15.6819C1.03984 15.9043 1.17951 16.1849 1.35451 16.3222C1.58103 16.5 1.89493 16.5 2.52273 16.5H13.4773C14.1051 16.5 14.419 16.5 14.6456 16.3222C14.8206 16.1849 14.9602 15.9043 14.9643 15.6819C14.9696 15.394 14.8086 15.181 14.4867 14.755C12.991 12.7755 10.6418 11.5 8.00004 11.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00004 9C10.0711 9 11.75 7.32107 11.75 5.25C11.75 3.17893 10.0711 1.5 8.00004 1.5C5.92897 1.5 4.25004 3.17893 4.25004 5.25C4.25004 7.32107 5.92897 9 8.00004 9Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
