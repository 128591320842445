import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { normalize } from 'normalizr';
import { useCallback, useEffect, useState } from 'react';
import { coupon } from 'utils/schemas/coupons';
import { useAuthenticatedAxios, StripeCouponMapped } from 'utils/stripe';

type StripeCouponsNormalized = {
  60: StripeCouponMapped;
  basic: StripeCouponMapped;
  standard: StripeCouponMapped;
};

type UseCouponsHook = {
  loading: boolean;
  coupons?: StripeCouponsNormalized;
  fetch: () => Promise<void>;
};

export const useCoupons = (): UseCouponsHook => {
  const router = useRouter();
  const { employerId } = router.query;

  const [coupons, setCoupons] = useState<StripeCouponsNormalized | undefined>();
  const [loading, setLoading] = useState(true);

  const axios = useAuthenticatedAxios();
  const fetch = useCallback(async () => {
    if (!employerId) return;
    try {
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_SITE_URL}/api/stripe/fetch/coupons`,
        {
          employerId,
        }
      );
      const { entities } = normalize(data, [coupon]);

      setCoupons(entities.coupons as any);
    } catch (err) {
      console.error(err);
      captureException(err);
    } finally {
      setLoading(false);
    }
  }, [employerId]);

  useEffect(() => {
    fetch();
  }, [employerId]);

  return { coupons, loading, fetch };
};
