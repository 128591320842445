import { captureException } from '@sentry/nextjs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { uploadData } from 'aws-amplify/storage';
import { FC, ReactChild, useRef, useState } from 'react';
import { fileReduce } from 'components/ProfileImage/utils';
import { Loading } from 'components/icons';
import { useMessages } from '../..';

export type FileUploadProps = {
  makePublic?: boolean;
  src: string | null;
  level?: 'private' | 'protected' | 'guest';
  handleUpdate?: (key: string) => Promise<any>;
  renderPreview: (previewSrc?: string) => ReactChild;
};

export const FileUpload: FC<FileUploadProps> = ({
  src,
  makePublic = false,
  level = 'guest',
  handleUpdate,
  renderPreview,
}) => {
  const { alert } = useMessages();

  const [previewSrc, setPreviewSrc] = useState(src);
  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = async (e: any): Promise<void> => {
    if (!e.target.files || e.target.files.length < 1) return;
    setLoading(true);
    try {
      const file = await fileReduce(e.target.files[0]);
      if (!file) throw Error('Error reading file');
      const { key }: any = await uploadData({
        key: `${makePublic ? 'accessible/' : ''}${file.name}+${file.lastModified}`,
        data: file,
        options: {
          accessLevel: level as any,
          contentType: file.type,
        },
      }).result;

      alert({
        key: 'Upload',
        type: 'success',
        title: 'File uploaded',
        duration: 3000,
      });
      handleUpdate && (await handleUpdate(key));
      setPreviewSrc(key);
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'Upload',
          type: 'error',
          title: 'Failed to upload file',
          message: err.message,
          duration: 3000,
        });
      }
      captureException(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {previewSrc ? (
        <button
          className="flex w-full cursor-pointer hover:opacity-80"
          onClick={() => inputRef?.current?.click()}
          type="button"
        >
          {loading ? (
            <Loading className="size-12" />
          ) : (
            renderPreview(previewSrc)
          )}
        </button>
      ) : (
        <button
          onClick={() => inputRef?.current?.click()}
          className="inline-block rounded border-2 border-dashed border-gray-400 p-8 text-gray-400 hover:border-gray-300 hover:text-gray-300"
          type="button"
        >
          {loading ? (
            <Loading className="size-12" />
          ) : (
            <PlusIcon className="size-12" />
          )}
        </button>
      )}
      <input
        ref={inputRef}
        className="hidden"
        accept="image/*"
        multiple={false}
        type="file"
        onChange={onChange}
      />
    </>
  );
};
