import { useRouter } from 'next/router';
import { FC } from 'react';
import { Tabs, useEmployerContext } from 'components/pages/PortalEmployer';

export const ProfileTabs: FC = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const route = pathname.split('/').slice(-1)[0];

  const { currentEmployer, firstId, hasNoEmployers } = useEmployerContext();

  const handleTabChange = (name: string) => {
    if (name === pathname) return;

    switch (name) {
      case 'profile':
        return router.push('/portal/profile');
      case 'directory':
      case 'company': {
        const employerId = currentEmployer ?? firstId;
        return router.push({
          pathname: `/portal/[employerId]/${name}`,
          query: { ...query, employerId },
        });
      }
    }
  };

  const tabs = [
    // let's use pathnames as names for simplicity
    ...(hasNoEmployers
      ? []
      : [
          { name: 'company', label: 'Company Info' },
          { name: 'directory', label: 'Directory info' },
        ]),
    { name: 'profile', label: 'My Info' },
  ];

  return <Tabs value={route} tabs={tabs} onChange={handleTabChange} />;
};
