// components/RangeSlider.tsx
import ReactSlider, { ReactSliderProps } from 'react-slider';
import { twMerge } from 'tailwind-merge';
import { InputLabel } from 'components/forms';
import { useFieldError } from 'components/forms/Errors';

type InputRangeSliderProps<T extends number | readonly number[]> =
  ReactSliderProps<T> & {
    className?: string;
    label: string;
    multi?: boolean;
    name: string;
    noValidation?: boolean;
    prefix?: string;
    required?: boolean;
    suffix?: string;
    max: number;
  };

export function InputRangeSlider<T extends number | readonly number[]>({
  className,
  label,
  multi = true,
  name,
  noValidation,
  prefix = '',
  required,
  suffix,
  ..._props
}: InputRangeSliderProps<T>): JSX.Element {
  const { error } = useFieldError(name);
  const values: any = _props.value ?? [];
  const value = multi ? values[1] : _props.value;

  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <InputLabel label={label} name={name} required={required} />
      <div
        className={twMerge(
          'my-2 flex flex-row items-center',
          multi ? 'justify-between' : 'justify-end'
        )}
      >
        {multi && (
          <span className="shrink rounded-md bg-gray-200 px-2 text-base">
            {prefix}
            {values[0]}
            {suffix}
          </span>
        )}
        <span className="shrink rounded-sm bg-gray-200 px-2 text-base">
          {prefix}
          {value}
          {suffix}
          {value >= +_props.max ? '+' : ''}
        </span>
      </div>
      <ReactSlider
        {..._props}
        step={1}
        className="h-4 w-full"
        renderTrack={(props, state) => {
          return (
            <div
              {...props}
              className={twMerge(
                'h-1/4 top-1/2 -translate-y-1/2 rounded-full',
                multi && state.index !== 1 && 'bg-gray-200',
                (multi ? state.index === 1 : state.index === 0) &&
                  'bg-primary-500'
              )}
            ></div>
          );
        }}
        renderThumb={(props) => (
          <div
            {...props}
            className="flex aspect-1 h-4 cursor-grab items-center justify-center rounded-full bg-primary-500 px-2 text-xs text-white"
          />
        )}
      />
    </div>
  );
}
