import { FC, ReactNode, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';

import getDBClient from 'database/DBClient';
import { ErrorProvider, Input } from '..';
import { Alert, Button, Link } from '../..';
import { LoginData, LoginProps } from './Login';

import Validation from './Validation';

type LoginEmployerProps = Omit<LoginProps, 'bg'>;

export const LoginEmployer: FC<LoginEmployerProps> = ({ onCancel }) => {
  const [error, setError] = useState<ReactNode>('');
  const { query, ...router } = useRouter();
  const email = query.e ?? '';
  const [, hash] = router.asPath.split('#');

  useEffect(() => {
    (async () => {
      const session = await fetchAuthSession();
      if (session.userSub !== undefined) {
        router.replace({
          pathname: '/portal/jobs',
          hash: '',
        });
      }
    })();
  }, []);

  const { formState, handleSubmit, register } = useForm<LoginData>({
    defaultValues: {
      username: email as string,
      password: '',
    },
    resolver: yupResolver(Validation),
  });

  async function onSubmit({ username, password }: LoginData) {
    try {
      //cleanup
      localStorage.clear();
      await getDBClient().clearStore();
      setError('');

      //sign in
      await signIn({ username, password });
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.payload as any;
      const isEmployer = !!token['cognito:groups']?.includes('employer-portal');
      if (!isEmployer) {
        await signOut();
        setError(
          <span>
            You tried logging in with a non-employer account. Please use
            <Link className="mx-1 text-primary underline" href="/auth">
              this page
            </Link>
            instead
          </span>
        );
        return;
      }
      if (hash && hash === 'login') {
        router.replace(
          {
            pathname: '/portal/jobs',
            query,
          },
          undefined,
          { shallow: true }
        );
      }
    } catch (error: any) {
      setError(error.message);
      captureException(error);
    }
  }

  return (
    <div>
      {error && <Alert type="error" title={error} />}
      <ErrorProvider errors={formState.errors}>
        <form id="login" onSubmit={handleSubmit(onSubmit)} className="my-6">
          <Input
            {...register('username')}
            required
            autoCapitalize="false"
            label="Email address"
            placeholder="Email address"
          />
          <Input
            {...register('password')}
            required
            autoCorrect="false"
            autoCapitalize="false"
            type="password"
            label="Password"
            placeholder="Password"
          />
          <Link
            href="/portal/auth/forgot"
            className="text-primary-500 underline hover:text-primary-700"
          >
            Forgot password?
          </Link>
          <div className="my-4 flex flex-wrap justify-center gap-4">
            <Button
              size="small"
              className="w-full whitespace-nowrap px-12 sm:order-2 sm:w-auto"
              type="submit"
              color="black"
              loading={formState.isSubmitting}
            >
              Login
            </Button>
            {onCancel && (
              <Button
                onClick={onCancel}
                className="w-full whitespace-nowrap px-12 sm:order-1 sm:w-auto"
                color="white"
                size="small"
              >
                Cancel
              </Button>
            )}
          </div>
          <span className="block w-full text-center text-gray-500">
            Don't have an account?{' '}
            <Link
              href="/portal/register"
              className="text-primary-500 underline hover:text-primary-700"
            >
              Sign up
            </Link>
          </span>
        </form>
      </ErrorProvider>
    </div>
  );
};
