import React, { VFC } from 'react';
import { Dropdown } from 'components';
import { useAuthContext } from 'components/AuthContext';
import { Item } from 'components/Dropdown/Item';
import { TableEmployerUsersProps } from 'components/pages/PortalEmployer';
import {
  // Enum_Access_Level_Enum,
  GetEmployerUsersDocument,
  useDeleteEmployerUserMutation,
  // useUpdateEmployerUserAccessLevelMutation,
} from 'database/types';

type EmployerUser = NonNullable<TableEmployerUsersProps['users']>[number];
export type EmployerUsersMenuProps = {
  id: EmployerUser['id'];
  email?: EmployerUser['user']['email'];
};

export const EmployerUsersMenu: VFC<EmployerUsersMenuProps> = ({
  id,
  email,
}) => {
  const { user } = useAuthContext();

  // const [update] = useUpdateEmployerUserAccessLevelMutation({
  //   refetchQueries: [GetEmployerUsersDocument],
  // });
  // const setAccessLevel = (access_id: Enum_Access_Level_Enum) =>
  //   update({ variables: { id, access_id } });
  // const setRead = () => setAccessLevel('read');
  // const setWrite = () => setAccessLevel('write');
  // const setFull = () => setAccessLevel('full');

  const [remove] = useDeleteEmployerUserMutation({
    variables: { id },
    refetchQueries: [GetEmployerUsersDocument],
  });

  const isPortalUser = email === user?.email;

  if (isPortalUser) return null;

  return (
    <Dropdown className="z-20">
      {/* <Item title="Set Read Access" onClick={setRead} /> */}
      {/* <Item title="Set Write Access" onClick={setWrite} /> */}
      {/* <Item title="Set Full Access" onClick={setFull} /> */}
      <Item className="!text-red-500" title="Remove Access" onClick={remove} />
    </Dropdown>
  );
};
