import { captureException } from '@sentry/nextjs';
import { getUrl } from 'aws-amplify/storage';
import { useEffect, useState, VFC } from 'react';

export type ProfilePhotoProps = {
  alt?: string;
  imgKey: string;
};

export const ProfilePhoto: VFC<ProfilePhotoProps> = ({ alt = '', imgKey }) => {
  const [url, setUrl] = useState<string>('');
  const [curr, setKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading || !imgKey || curr === imgKey) return;
    setLoading(true);
    (async () => {
      try {
        const res = await getUrl({
          key: imgKey,
          options: { accessLevel: 'guest' },
        });
        setUrl(res.url.toString());
      } catch (err) {
        setUrl('');
        captureException(err);
      } finally {
        setKey(imgKey);
        setLoading(false);
      }
    })();
  }, [setUrl, loading, setLoading, url, imgKey]);

  return (
    <img
      className="inline-block size-24 rounded-full ring-2 ring-white"
      src={url}
      alt={alt}
    />
  );
};
