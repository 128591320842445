import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { AlertProps } from './Alert';

export type ActionsProps = {
  confirm: () => void;
  dismiss: () => void;
  confirmText?: string;
  dismissText?: string;
} & Pick<AlertProps, 'type'>;

export const Actions: FC<ActionsProps> = ({
  confirm,
  confirmText = 'Confirm',
  dismiss,
  dismissText = 'Dismiss',
  type,
}) => (
  <div className="mt-4">
    <div className="-mx-2 -my-1.5 flex">
      <button
        onClick={confirm}
        type="button"
        className={twMerge(
          'rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
          type === 'success'
            ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
            : type === 'error'
              ? 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50'
              : type === 'warning'
                ? 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50'
                : type === 'info'
                  ? 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50'
                  : 'bg-gray-50 text-gray-500 hover:bg-gray-100 focus:ring-gray-600 focus:ring-offset-gray-50'
        )}
      >
        {confirmText}
      </button>
      <button
        type="button"
        onClick={dismiss}
        className={twMerge(
          'ml-3 rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
          type === 'success'
            ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
            : type === 'error'
              ? 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50'
              : type === 'warning'
                ? 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50'
                : type === 'info'
                  ? 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50'
                  : 'bg-gray-50 text-gray-500 hover:bg-gray-100 focus:ring-gray-600 focus:ring-offset-gray-50'
        )}
      >
        {dismissText}
      </button>
    </div>
  </div>
);
