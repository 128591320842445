import { FC } from 'react';
import { Alert, Button } from 'components';
import { useRelist } from 'utils/useRelist';

export const AlertRelist: FC = () => {
  const { relist } = useRelist();
  return (
    <Alert
      type="primary"
      title="Relist your job"
      message="Your listing has expired. Click the button to run it again."
      dismissable={false}
      actions={
        <Button
          className="py-2 uppercase"
          onClick={() => relist()}
          color="white"
          size="small"
        >
          Relist
        </Button>
      }
    />
  );
};
