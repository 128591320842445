import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import slugify from 'slugify';
import { LocationPath } from 'utils';

export type JobsPaths = {
  keywords?: string;
  category?: string;
  location?: LocationPath;
};

// Longest valid path is [keywords, category, region, district, municipality, neighbourhood]
export const MAX_PATH_LENGTH = 6;

/*
   Generates path as /[keywords-]jobs[/category][/location]
 */

// defaults to fill path if no filter used
// e.g. if user only filters for location, path will be /jobs/all/[location]
const defaultKeyword = 'jobs';
const defaultCategory = 'all';

// helpers to generate path
const makeKeywordsPath = (keywords?: string) =>
  keywords ? `${encodeURIComponent(keywords)}-jobs` : 'jobs';

const makeCategoryPath = (category?: string, location?: string[]) =>
  category ? `${slugify(category)}` : location?.length ? 'all' : '';

const makeLocationPath = (location = [] as string[]) =>
  location
    .filter(Boolean)
    .map((part) => encodeURIComponent(part.trim().replace(/ +/g, '-')));
// #TODO decide whether to keep maori characters for location
// slugify strips maori characters
// const makeLocationPath = (location?: string) =>
//   location ? `/${slugify(location, { locale: 'mi', strict: true })}` : '';

export const makeJobsPath = ({
  keywords,
  category,
  location,
}: JobsPaths): string[] => {
  const k = makeKeywordsPath(keywords);
  const c = makeCategoryPath(category, location);
  const l = makeLocationPath(location);

  return [k, c, ...l].filter(Boolean);
};

const parseKeywordsFromPath = (keywords = '') =>
  keywords === defaultKeyword
    ? ''
    : keywords.trim().replace(/-jobs/, '').replace(/-+/g, ' ');

const parseCategoryFromPath = (category = '') =>
  category === defaultCategory ? '' : category.trim().replace(/-+/g, ' ');

const parseLocationFromPath = (location = [] as string[]) =>
  location.filter(Boolean).map((part) => part.trim().replace(/-+/g, ' '));

export const parseJobsPath = (
  jobs: ParsedUrlQuery[number]
): Required<JobsPaths> => {
  // nextjs guarantees that `jobs` is a string[]
  const [keywords, category, ...location] = jobs as string[];

  return {
    keywords: parseKeywordsFromPath(keywords),
    category: parseCategoryFromPath(category),
    location: parseLocationFromPath(location),
  };
};

export const useJobsPath = (): Required<JobsPaths> => {
  const router = useRouter();
  const { query } = router;
  const { jobs = [] } = query;

  const { keywords, category, location } = parseJobsPath(jobs);

  return { keywords: decodeURIComponent(keywords), category, location };
};
