import { Transition } from '@headlessui/react';
import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState, VFC } from 'react';
import { useRouteCheck } from 'components/AuthContext';
import useIsBrowser from 'utils/hooks/useIsBrowser';

type Props = {
  banner_enabled: boolean;
  banner_key?: string;
  banner_title?: string;
  banner_title_short?: string;
};

export const Banner: VFC = () => {
  const [props, setProps] = useState<Props>({
    banner_enabled: false,
  });
  const isBrowser = useIsBrowser();

  useEffect(() => {
    if (!isBrowser) return;
    async function getEdgeConfig() {
      try {
        const response = await fetch('/api/edgeConfig');
        const json = await response.json();
        const dismissed = localStorage.getItem(json?.banner_key);
        if (!json?.banner_enabled || dismissed) return;
        setProps(json as Props);
      } catch (error) {
        // console.error(error);
      }
    }

    getEdgeConfig();
  }, [isBrowser]);

  const { isPortal, isGenerate } = useRouteCheck();

  if (isPortal || isGenerate) return null;

  return (
    <Transition
      appear={props?.banner_enabled}
      show={props?.banner_enabled}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0 sm:-translate-y-4"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-primary-500">
        <div className="mx-auto max-w-7xl p-3 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="hidden rounded-lg bg-primary-800 p-2 md:inline">
                <MegaphoneIcon
                  className="size-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-medium text-white">
                <span className="md:hidden">{props?.banner_title_short}</span>
                <span className="hidden md:inline">{props?.banner_title}</span>
              </p>
            </div>
            {/* <div className="shrink-0 order-3 mt-2 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                href="/portal/register"
                className=" flex justify-center items-center py-2 px-4 text-sm font-medium text-primary-500 bg-white hover:bg-white-600 rounded-md border border-transparent shadow-sm"
              >
                Post a job
              </Link>
            </div> */}
            <div className="order-2 shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                onClick={() => {
                  localStorage.setItem(props?.banner_key as string, 'true');
                  setProps({ ...props, banner_enabled: false });
                }}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="size-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
