import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const JobWithoutComputedFragmentDoc = gql`
    fragment jobWithoutComputed on job {
  id
  title
  slug
  requirements
  pay_type
  pay_low
  pay_high
  about
  tagline
  job_status
  job_category
  listing_type
  highlights
  created_at
  show_pay
  work_type
  list_date
  expiry_date
  employer_id
  location_id
  created_by_user_id
  apply_url
}
    `;
export const Created_By_UserFragmentDoc = gql`
    fragment created_by_user on user {
  first_name
  last_name
  profile_photo
  email
  phone
}
    `;
export const JobWithoutEmployerFragmentDoc = gql`
    fragment jobWithoutEmployer on job {
  id
  title
  slug
  requirements
  pay_type
  pay_low
  pay_high
  about
  tagline
  job_category
  highlights
  created_at
  show_pay
  list_date
  expiry_date
  updated_at
  listing_type
  show_banner
  category_computed {
    label
  }
  work_type_computed {
    label
  }
  location_computed {
    region
    district
    municipality
    neighbourhood
  }
  integration_computed {
    integration_type
    integration_fkey
    data
    application_url
  }
  additional_categories_computed {
    category
  }
}
    `;
export const JobWithQuestionsFragmentDoc = gql`
    fragment jobWithQuestions on job {
  ...jobWithoutEmployer
  job_questions(order_by: {order: desc}) {
    id
    question
    type
    job_id
    order
  }
}
    ${JobWithoutEmployerFragmentDoc}`;
export const JobWithEmployerFragmentDoc = gql`
    fragment jobWithEmployer on job {
  ...jobWithQuestions
  apply_url
  listing_type
  metadata
  employer_computed {
    name
    slug
    description
    logo
    page_active
    cover_photo
    video
    managed_by_parent
    parent {
      name
      slug
      description
      page_active
    }
  }
}
    ${JobWithQuestionsFragmentDoc}`;
export const UserDefaultsFragmentDoc = gql`
    fragment userDefaults on user {
  id
  email
  first_name
  last_name
  phone
  about
  profile_photo
  role
  created_at
  updated_at
  deleted_at
  category
}
    `;
export const UserEducationPublicFragmentDoc = gql`
    fragment userEducationPublic on user_education {
  id
  complete
  institution
  issue_date
  title
}
    `;
export const UserEducationFragmentDoc = gql`
    fragment userEducation on user_education {
  ...userEducationPublic
  created_at
  updated_at
  deleted_at
}
    ${UserEducationPublicFragmentDoc}`;
export const UserExperiencePublicFragmentDoc = gql`
    fragment userExperiencePublic on user_experience {
  id
  company
  current
  start_date
  end_date
  title
  description
}
    `;
export const UserExperienceFragmentDoc = gql`
    fragment userExperience on user_experience {
  ...userExperiencePublic
  created_at
  updated_at
  deleted_at
}
    ${UserExperiencePublicFragmentDoc}`;
export const UserLicencePublicFragmentDoc = gql`
    fragment userLicencePublic on user_licence {
  id
  organisation
  expires
  expiry_date
  issue_date
  title
}
    `;
export const UserLicenceFragmentDoc = gql`
    fragment userLicence on user_licence {
  ...userLicencePublic
  created_at
  updated_at
  deleted_at
}
    ${UserLicencePublicFragmentDoc}`;
export const UserHistoryFragmentDoc = gql`
    fragment userHistory on user {
  user_educations(
    order_by: {issue_date: desc}
    where: {deleted_at: {_is_null: true}}
  ) {
    ...userEducation
  }
  user_experiences(
    order_by: {start_date: desc}
    where: {deleted_at: {_is_null: true}}
  ) {
    ...userExperience
  }
  user_licences(
    order_by: {issue_date: desc}
    where: {deleted_at: {_is_null: true}}
  ) {
    ...userLicence
  }
}
    ${UserEducationFragmentDoc}
${UserExperienceFragmentDoc}
${UserLicenceFragmentDoc}`;
export const JobApplicationFragmentDoc = gql`
    fragment jobApplication on application {
  id
  answers
  status
  created_at
  updated_at
  deleted_at
  job_id
}
    `;
export const UserApplicationInfoFragmentDoc = gql`
    fragment userApplicationInfo on user {
  id
  email
  first_name
  last_name
  profile_photo
  phone
  about
}
    `;
export const UserHistoryPublicFragmentDoc = gql`
    fragment userHistoryPublic on user {
  user_educations(order_by: {issue_date: desc}) {
    ...userEducationPublic
  }
  user_experiences(order_by: {start_date: desc}) {
    ...userExperiencePublic
  }
  user_licences(order_by: {issue_date: desc}) {
    ...userLicencePublic
  }
}
    ${UserEducationPublicFragmentDoc}
${UserExperiencePublicFragmentDoc}
${UserLicencePublicFragmentDoc}`;
export const ApplicationInfoFragmentDoc = gql`
    fragment applicationInfo on application {
  id
  created_at
  application_status {
    label
    value
  }
  answers
  questions {
    question
    type
    answers
  }
  user {
    ...userApplicationInfo
    ...userHistoryPublic
  }
}
    ${UserApplicationInfoFragmentDoc}
${UserHistoryPublicFragmentDoc}`;
export const EmployerPageFragmentDoc = gql`
    fragment employerPage on employer {
  slug
  name
  category
  cover_photo
  description
  employees
  founded
  logo
  website
  facebook
  instagram
  linkedin
  testimonial
  testimonial_from
  video
  managed_by_parent
  employer_category {
    label
  }
  location {
    label
    region
    district
  }
}
    `;
export const CompanyFragmentDoc = gql`
    fragment company on employer {
  id
  name
  description
  founded
  employees
  category
  website
  instagram
  facebook
  linkedin
  testimonial
  testimonial_from
  page_active
  logo
  cover_photo
  slug
  billing_type
  video
  organisation_type
  parent_id
  managed_by_parent
  location {
    id
    geometry
    label
    region
    district
    municipality
    neighbourhood
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment location on location {
  id
  geometry
  label
  region
  district
  municipality
  neighbourhood
}
    `;
export const UpdateApplicationStatusDocument = gql`
    mutation UpdateApplicationStatus($id: uuid!, $status: enum_application_status_enum!, $updated_at: timestamptz!) {
  update_application_by_pk(
    pk_columns: {id: $id}
    _set: {status: $status, updated_at: $updated_at}
  ) {
    id
    status
    updated_at
  }
}
    `;
export type UpdateApplicationStatusMutationFn = Apollo.MutationFunction<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>;

/**
 * __useUpdateApplicationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationStatusMutation, { data, loading, error }] = useUpdateApplicationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useUpdateApplicationStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>(UpdateApplicationStatusDocument, options);
      }
export type UpdateApplicationStatusMutationHookResult = ReturnType<typeof useUpdateApplicationStatusMutation>;
export type UpdateApplicationStatusMutationResult = Apollo.MutationResult<Types.UpdateApplicationStatusMutation>;
export type UpdateApplicationStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicationStatusMutation, Types.UpdateApplicationStatusMutationVariables>;
export const InsertApplicationDocument = gql`
    mutation InsertApplication($job_id: uuid!, $answers: jsonb! = {}) {
  insert_application(objects: {job_id: $job_id, answers: $answers}) {
    returning {
      ...jobApplication
    }
  }
}
    ${JobApplicationFragmentDoc}`;
export type InsertApplicationMutationFn = Apollo.MutationFunction<Types.InsertApplicationMutation, Types.InsertApplicationMutationVariables>;

/**
 * __useInsertApplicationMutation__
 *
 * To run a mutation, you first call `useInsertApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertApplicationMutation, { data, loading, error }] = useInsertApplicationMutation({
 *   variables: {
 *      job_id: // value for 'job_id'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useInsertApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertApplicationMutation, Types.InsertApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertApplicationMutation, Types.InsertApplicationMutationVariables>(InsertApplicationDocument, options);
      }
export type InsertApplicationMutationHookResult = ReturnType<typeof useInsertApplicationMutation>;
export type InsertApplicationMutationResult = Apollo.MutationResult<Types.InsertApplicationMutation>;
export type InsertApplicationMutationOptions = Apollo.BaseMutationOptions<Types.InsertApplicationMutation, Types.InsertApplicationMutationVariables>;
export const UpdateApplicationDocument = gql`
    mutation UpdateApplication($id: uuid!, $answers: jsonb!, $updated_at: timestamptz!) {
  update_application_by_pk(
    pk_columns: {id: $id}
    _set: {answers: $answers, updated_at: $updated_at}
  ) {
    ...jobApplication
  }
}
    ${JobApplicationFragmentDoc}`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<Types.UpdateApplicationMutation, Types.UpdateApplicationMutationVariables>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      answers: // value for 'answers'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useUpdateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicationMutation, Types.UpdateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicationMutation, Types.UpdateApplicationMutationVariables>(UpdateApplicationDocument, options);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<Types.UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicationMutation, Types.UpdateApplicationMutationVariables>;
export const GetApplicationByJobIdDocument = gql`
    query GetApplicationByJobID($job_id: uuid) {
  application(where: {job_id: {_eq: $job_id}, deleted_at: {_is_null: true}}) {
    ...applicationInfo
  }
}
    ${ApplicationInfoFragmentDoc}`;

/**
 * __useGetApplicationByJobIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationByJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationByJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationByJobIdQuery({
 *   variables: {
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useGetApplicationByJobIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>(GetApplicationByJobIdDocument, options);
      }
export function useGetApplicationByJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>(GetApplicationByJobIdDocument, options);
        }
export function useGetApplicationByJobIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>(GetApplicationByJobIdDocument, options);
        }
export type GetApplicationByJobIdQueryHookResult = ReturnType<typeof useGetApplicationByJobIdQuery>;
export type GetApplicationByJobIdLazyQueryHookResult = ReturnType<typeof useGetApplicationByJobIdLazyQuery>;
export type GetApplicationByJobIdSuspenseQueryHookResult = ReturnType<typeof useGetApplicationByJobIdSuspenseQuery>;
export type GetApplicationByJobIdQueryResult = Apollo.QueryResult<Types.GetApplicationByJobIdQuery, Types.GetApplicationByJobIdQueryVariables>;
export function refetchGetApplicationByJobIdQuery(variables?: Types.GetApplicationByJobIdQueryVariables) {
      return { query: GetApplicationByJobIdDocument, variables: variables }
    }
export const GetApplicationsDocument = gql`
    query GetApplications {
  application {
    id
    status
    job_id
  }
}
    `;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a React component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>(GetApplicationsDocument, options);
      }
export function useGetApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>(GetApplicationsDocument, options);
        }
export function useGetApplicationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>(GetApplicationsDocument, options);
        }
export type GetApplicationsQueryHookResult = ReturnType<typeof useGetApplicationsQuery>;
export type GetApplicationsLazyQueryHookResult = ReturnType<typeof useGetApplicationsLazyQuery>;
export type GetApplicationsSuspenseQueryHookResult = ReturnType<typeof useGetApplicationsSuspenseQuery>;
export type GetApplicationsQueryResult = Apollo.QueryResult<Types.GetApplicationsQuery, Types.GetApplicationsQueryVariables>;
export function refetchGetApplicationsQuery(variables?: Types.GetApplicationsQueryVariables) {
      return { query: GetApplicationsDocument, variables: variables }
    }
export const GetApplicationByIdDocument = gql`
    query GetApplicationByID($id: uuid!) {
  application_by_pk(id: $id) {
    ...applicationInfo
    job {
      id
      title
      employer_id
    }
  }
}
    ${ApplicationInfoFragmentDoc}`;

/**
 * __useGetApplicationByIdQuery__
 *
 * To run a query within a React component, call `useGetApplicationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables> & ({ variables: Types.GetApplicationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables>(GetApplicationByIdDocument, options);
      }
export function useGetApplicationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables>(GetApplicationByIdDocument, options);
        }
export function useGetApplicationByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables>(GetApplicationByIdDocument, options);
        }
export type GetApplicationByIdQueryHookResult = ReturnType<typeof useGetApplicationByIdQuery>;
export type GetApplicationByIdLazyQueryHookResult = ReturnType<typeof useGetApplicationByIdLazyQuery>;
export type GetApplicationByIdSuspenseQueryHookResult = ReturnType<typeof useGetApplicationByIdSuspenseQuery>;
export type GetApplicationByIdQueryResult = Apollo.QueryResult<Types.GetApplicationByIdQuery, Types.GetApplicationByIdQueryVariables>;
export function refetchGetApplicationByIdQuery(variables: Types.GetApplicationByIdQueryVariables) {
      return { query: GetApplicationByIdDocument, variables: variables }
    }
export const GetUserApplicationByJobIdDocument = gql`
    query GetUserApplicationByJobID($job_id: uuid!) {
  application(where: {job_id: {_eq: $job_id}}) {
    ...applicationInfo
    job {
      id
      title
      employer_id
      applications {
        id
        created_at
        user {
          first_name
          last_name
        }
      }
    }
  }
}
    ${ApplicationInfoFragmentDoc}`;

/**
 * __useGetUserApplicationByJobIdQuery__
 *
 * To run a query within a React component, call `useGetUserApplicationByJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserApplicationByJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserApplicationByJobIdQuery({
 *   variables: {
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useGetUserApplicationByJobIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables> & ({ variables: Types.GetUserApplicationByJobIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables>(GetUserApplicationByJobIdDocument, options);
      }
export function useGetUserApplicationByJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables>(GetUserApplicationByJobIdDocument, options);
        }
export function useGetUserApplicationByJobIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables>(GetUserApplicationByJobIdDocument, options);
        }
export type GetUserApplicationByJobIdQueryHookResult = ReturnType<typeof useGetUserApplicationByJobIdQuery>;
export type GetUserApplicationByJobIdLazyQueryHookResult = ReturnType<typeof useGetUserApplicationByJobIdLazyQuery>;
export type GetUserApplicationByJobIdSuspenseQueryHookResult = ReturnType<typeof useGetUserApplicationByJobIdSuspenseQuery>;
export type GetUserApplicationByJobIdQueryResult = Apollo.QueryResult<Types.GetUserApplicationByJobIdQuery, Types.GetUserApplicationByJobIdQueryVariables>;
export function refetchGetUserApplicationByJobIdQuery(variables: Types.GetUserApplicationByJobIdQueryVariables) {
      return { query: GetUserApplicationByJobIdDocument, variables: variables }
    }
export const GetJobApplicationsByJobIdDocument = gql`
    query GetJobApplicationsByJobId($id: uuid!) {
  job: job_by_pk(id: $id) {
    id
    title
    slug
    job_status
    employer_id
    listing_type
    job_questions(where: {deleted_at: {_is_null: true}}) {
      id
      question
      type
      order
    }
    applications {
      id
      created_at
      application_status {
        label
        value
      }
      job_id
      answers
      job {
        id
        title
        slug
        employer_id
        job_views {
          views
        }
        location {
          label
          region
        }
      }
      user {
        user_experiences_aggregate {
          aggregate {
            count
          }
        }
        user_licences_aggregate {
          aggregate {
            count
          }
        }
      }
      user {
        email
        user_experiences {
          ...userExperience
        }
        user_educations {
          ...userEducation
        }
        user_licences {
          ...userLicence
        }
        first_name
        last_name
      }
    }
  }
}
    ${UserExperienceFragmentDoc}
${UserEducationFragmentDoc}
${UserLicenceFragmentDoc}`;

/**
 * __useGetJobApplicationsByJobIdQuery__
 *
 * To run a query within a React component, call `useGetJobApplicationsByJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobApplicationsByJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobApplicationsByJobIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobApplicationsByJobIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables> & ({ variables: Types.GetJobApplicationsByJobIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables>(GetJobApplicationsByJobIdDocument, options);
      }
export function useGetJobApplicationsByJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables>(GetJobApplicationsByJobIdDocument, options);
        }
export function useGetJobApplicationsByJobIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables>(GetJobApplicationsByJobIdDocument, options);
        }
export type GetJobApplicationsByJobIdQueryHookResult = ReturnType<typeof useGetJobApplicationsByJobIdQuery>;
export type GetJobApplicationsByJobIdLazyQueryHookResult = ReturnType<typeof useGetJobApplicationsByJobIdLazyQuery>;
export type GetJobApplicationsByJobIdSuspenseQueryHookResult = ReturnType<typeof useGetJobApplicationsByJobIdSuspenseQuery>;
export type GetJobApplicationsByJobIdQueryResult = Apollo.QueryResult<Types.GetJobApplicationsByJobIdQuery, Types.GetJobApplicationsByJobIdQueryVariables>;
export function refetchGetJobApplicationsByJobIdQuery(variables: Types.GetJobApplicationsByJobIdQueryVariables) {
      return { query: GetJobApplicationsByJobIdDocument, variables: variables }
    }
export const GetQuickApplicationByJobIdDocument = gql`
    query GetQuickApplicationByJobID($job_id: uuid!, $user_id: uuid!) {
  application(
    where: {job_id: {_eq: $job_id}, deleted_at: {_is_null: true}}
    limit: 1
  ) {
    id
    job_id
    answers
    status
    created_at
  }
  user_experience(
    where: {deleted_at: {_is_null: true}}
    order_by: {start_date: desc, end_date: desc_nulls_first}
    limit: 1
  ) {
    id
    title
    description
    company
    current
    start_date
    end_date
  }
  user_by_pk(id: $user_id) {
    id
    phone
  }
}
    `;

/**
 * __useGetQuickApplicationByJobIdQuery__
 *
 * To run a query within a React component, call `useGetQuickApplicationByJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickApplicationByJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickApplicationByJobIdQuery({
 *   variables: {
 *      job_id: // value for 'job_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetQuickApplicationByJobIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables> & ({ variables: Types.GetQuickApplicationByJobIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables>(GetQuickApplicationByJobIdDocument, options);
      }
export function useGetQuickApplicationByJobIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables>(GetQuickApplicationByJobIdDocument, options);
        }
export function useGetQuickApplicationByJobIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables>(GetQuickApplicationByJobIdDocument, options);
        }
export type GetQuickApplicationByJobIdQueryHookResult = ReturnType<typeof useGetQuickApplicationByJobIdQuery>;
export type GetQuickApplicationByJobIdLazyQueryHookResult = ReturnType<typeof useGetQuickApplicationByJobIdLazyQuery>;
export type GetQuickApplicationByJobIdSuspenseQueryHookResult = ReturnType<typeof useGetQuickApplicationByJobIdSuspenseQuery>;
export type GetQuickApplicationByJobIdQueryResult = Apollo.QueryResult<Types.GetQuickApplicationByJobIdQuery, Types.GetQuickApplicationByJobIdQueryVariables>;
export function refetchGetQuickApplicationByJobIdQuery(variables: Types.GetQuickApplicationByJobIdQueryVariables) {
      return { query: GetQuickApplicationByJobIdDocument, variables: variables }
    }
export const ApplyForJobDocument = gql`
    mutation ApplyForJob($application: application_insert_input!, $user_experience: user_experience_insert_input!, $user_id: uuid!, $phone: String!) {
  insert_application_one(
    object: $application
    on_conflict: {constraint: application_pkey, update_columns: [answers, status]}
  ) {
    id
    answers
    created_at
  }
  insert_user_experience_one(
    object: $user_experience
    on_conflict: {constraint: user_experience_pkey, update_columns: [title, description, current, start_date, end_date]}
  ) {
    id
    title
    description
    company
    current
    start_date
    end_date
  }
  update_user_by_pk(pk_columns: {id: $user_id}, _set: {phone: $phone}) {
    id
    phone
  }
}
    `;
export type ApplyForJobMutationFn = Apollo.MutationFunction<Types.ApplyForJobMutation, Types.ApplyForJobMutationVariables>;

/**
 * __useApplyForJobMutation__
 *
 * To run a mutation, you first call `useApplyForJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForJobMutation, { data, loading, error }] = useApplyForJobMutation({
 *   variables: {
 *      application: // value for 'application'
 *      user_experience: // value for 'user_experience'
 *      user_id: // value for 'user_id'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useApplyForJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.ApplyForJobMutation, Types.ApplyForJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ApplyForJobMutation, Types.ApplyForJobMutationVariables>(ApplyForJobDocument, options);
      }
export type ApplyForJobMutationHookResult = ReturnType<typeof useApplyForJobMutation>;
export type ApplyForJobMutationResult = Apollo.MutationResult<Types.ApplyForJobMutation>;
export type ApplyForJobMutationOptions = Apollo.BaseMutationOptions<Types.ApplyForJobMutation, Types.ApplyForJobMutationVariables>;
export const ApplyForJobWithOnlyWithIdDocument = gql`
    mutation ApplyForJobWithOnlyWithId($application: application_insert_input!, $user_id: uuid!) {
  insert_application_one(
    object: $application
    on_conflict: {constraint: application_user_id_job_id_key, update_columns: [answers, status]}
  ) {
    id
    answers
    created_at
  }
  update_user_by_pk(pk_columns: {id: $user_id}) {
    id
  }
}
    `;
export type ApplyForJobWithOnlyWithIdMutationFn = Apollo.MutationFunction<Types.ApplyForJobWithOnlyWithIdMutation, Types.ApplyForJobWithOnlyWithIdMutationVariables>;

/**
 * __useApplyForJobWithOnlyWithIdMutation__
 *
 * To run a mutation, you first call `useApplyForJobWithOnlyWithIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForJobWithOnlyWithIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForJobWithOnlyWithIdMutation, { data, loading, error }] = useApplyForJobWithOnlyWithIdMutation({
 *   variables: {
 *      application: // value for 'application'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useApplyForJobWithOnlyWithIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.ApplyForJobWithOnlyWithIdMutation, Types.ApplyForJobWithOnlyWithIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ApplyForJobWithOnlyWithIdMutation, Types.ApplyForJobWithOnlyWithIdMutationVariables>(ApplyForJobWithOnlyWithIdDocument, options);
      }
export type ApplyForJobWithOnlyWithIdMutationHookResult = ReturnType<typeof useApplyForJobWithOnlyWithIdMutation>;
export type ApplyForJobWithOnlyWithIdMutationResult = Apollo.MutationResult<Types.ApplyForJobWithOnlyWithIdMutation>;
export type ApplyForJobWithOnlyWithIdMutationOptions = Apollo.BaseMutationOptions<Types.ApplyForJobWithOnlyWithIdMutation, Types.ApplyForJobWithOnlyWithIdMutationVariables>;
export const UploadDocumentsDocument = gql`
    mutation UploadDocuments($objects: [file_insert_input!]!) {
  insert_file(objects: $objects) {
    affected_rows
  }
}
    `;
export type UploadDocumentsMutationFn = Apollo.MutationFunction<Types.UploadDocumentsMutation, Types.UploadDocumentsMutationVariables>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUploadDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadDocumentsMutation, Types.UploadDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadDocumentsMutation, Types.UploadDocumentsMutationVariables>(UploadDocumentsDocument, options);
      }
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = Apollo.MutationResult<Types.UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.UploadDocumentsMutation, Types.UploadDocumentsMutationVariables>;
export const GetDocumentsDocument = gql`
    query GetDocuments {
  file(where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
    id
    key
    name
    mimetype
    created_at
  }
}
    `;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>(GetDocumentsDocument, options);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>(GetDocumentsDocument, options);
        }
export function useGetDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>(GetDocumentsDocument, options);
        }
export type GetDocumentsQueryHookResult = ReturnType<typeof useGetDocumentsQuery>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsSuspenseQueryHookResult = ReturnType<typeof useGetDocumentsSuspenseQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<Types.GetDocumentsQuery, Types.GetDocumentsQueryVariables>;
export function refetchGetDocumentsQuery(variables?: Types.GetDocumentsQueryVariables) {
      return { query: GetDocumentsDocument, variables: variables }
    }
export const GetDocumentsByUserIdDocument = gql`
    query GetDocumentsByUserId($user_id: uuid!) {
  file(
    where: {user_id: {_eq: $user_id}, deleted_at: {_is_null: true}}
    order_by: {created_at: desc}
  ) {
    id
    key
    name
    mimetype
    created_at
  }
}
    `;

/**
 * __useGetDocumentsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsByUserIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetDocumentsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables> & ({ variables: Types.GetDocumentsByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables>(GetDocumentsByUserIdDocument, options);
      }
export function useGetDocumentsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables>(GetDocumentsByUserIdDocument, options);
        }
export function useGetDocumentsByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables>(GetDocumentsByUserIdDocument, options);
        }
export type GetDocumentsByUserIdQueryHookResult = ReturnType<typeof useGetDocumentsByUserIdQuery>;
export type GetDocumentsByUserIdLazyQueryHookResult = ReturnType<typeof useGetDocumentsByUserIdLazyQuery>;
export type GetDocumentsByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetDocumentsByUserIdSuspenseQuery>;
export type GetDocumentsByUserIdQueryResult = Apollo.QueryResult<Types.GetDocumentsByUserIdQuery, Types.GetDocumentsByUserIdQueryVariables>;
export function refetchGetDocumentsByUserIdQuery(variables: Types.GetDocumentsByUserIdQueryVariables) {
      return { query: GetDocumentsByUserIdDocument, variables: variables }
    }
export const DeleteDocumentByIdDocument = gql`
    mutation DeleteDocumentById($id: uuid!) {
  delete_file_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentByIdMutationFn = Apollo.MutationFunction<Types.DeleteDocumentByIdMutation, Types.DeleteDocumentByIdMutationVariables>;

/**
 * __useDeleteDocumentByIdMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentByIdMutation, { data, loading, error }] = useDeleteDocumentByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentByIdMutation, Types.DeleteDocumentByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDocumentByIdMutation, Types.DeleteDocumentByIdMutationVariables>(DeleteDocumentByIdDocument, options);
      }
export type DeleteDocumentByIdMutationHookResult = ReturnType<typeof useDeleteDocumentByIdMutation>;
export type DeleteDocumentByIdMutationResult = Apollo.MutationResult<Types.DeleteDocumentByIdMutation>;
export type DeleteDocumentByIdMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentByIdMutation, Types.DeleteDocumentByIdMutationVariables>;
export const GetJobsOfTheWeekDocument = gql`
    query GetJobsOfTheWeek($date: timestamptz) {
  job_count: job_aggregate(
    where: {created_at: {_gte: $date}, status: {value: {_eq: "active"}}}
  ) {
    aggregate {
      count
    }
  }
  job(
    where: {created_at: {_gte: $date}, status: {value: {_eq: "active"}}}
    order_by: {created_at: desc}
  ) {
    title
    slug
    employer {
      name
      logo
    }
    category {
      value
      label
    }
    location {
      region
    }
    listing_type
  }
  categories: enum_category {
    label
    value
  }
}
    `;

/**
 * __useGetJobsOfTheWeekQuery__
 *
 * To run a query within a React component, call `useGetJobsOfTheWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsOfTheWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsOfTheWeekQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetJobsOfTheWeekQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>(GetJobsOfTheWeekDocument, options);
      }
export function useGetJobsOfTheWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>(GetJobsOfTheWeekDocument, options);
        }
export function useGetJobsOfTheWeekSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>(GetJobsOfTheWeekDocument, options);
        }
export type GetJobsOfTheWeekQueryHookResult = ReturnType<typeof useGetJobsOfTheWeekQuery>;
export type GetJobsOfTheWeekLazyQueryHookResult = ReturnType<typeof useGetJobsOfTheWeekLazyQuery>;
export type GetJobsOfTheWeekSuspenseQueryHookResult = ReturnType<typeof useGetJobsOfTheWeekSuspenseQuery>;
export type GetJobsOfTheWeekQueryResult = Apollo.QueryResult<Types.GetJobsOfTheWeekQuery, Types.GetJobsOfTheWeekQueryVariables>;
export function refetchGetJobsOfTheWeekQuery(variables?: Types.GetJobsOfTheWeekQueryVariables) {
      return { query: GetJobsOfTheWeekDocument, variables: variables }
    }
export const GetUserCategoriesDocument = gql`
    query GetUserCategories($emails: [String!]!) {
  user(where: {email: {_in: $emails}}) {
    id
    email
    category
  }
}
    `;

/**
 * __useGetUserCategoriesQuery__
 *
 * To run a query within a React component, call `useGetUserCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCategoriesQuery({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useGetUserCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables> & ({ variables: Types.GetUserCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables>(GetUserCategoriesDocument, options);
      }
export function useGetUserCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables>(GetUserCategoriesDocument, options);
        }
export function useGetUserCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables>(GetUserCategoriesDocument, options);
        }
export type GetUserCategoriesQueryHookResult = ReturnType<typeof useGetUserCategoriesQuery>;
export type GetUserCategoriesLazyQueryHookResult = ReturnType<typeof useGetUserCategoriesLazyQuery>;
export type GetUserCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetUserCategoriesSuspenseQuery>;
export type GetUserCategoriesQueryResult = Apollo.QueryResult<Types.GetUserCategoriesQuery, Types.GetUserCategoriesQueryVariables>;
export function refetchGetUserCategoriesQuery(variables: Types.GetUserCategoriesQueryVariables) {
      return { query: GetUserCategoriesDocument, variables: variables }
    }
export const GetEmployersForDirectoryDocument = gql`
    query GetEmployersForDirectory($where: employer_bool_exp = {page_active: {_eq: true}}) {
  employer(where: $where, order_by: {name: asc}) {
    slug
    name
    logo
    category
    jobs_aggregate(where: {job_status: {_eq: active}}) {
      aggregate {
        count
      }
    }
    location {
      region
      municipality
    }
  }
}
    `;

/**
 * __useGetEmployersForDirectoryQuery__
 *
 * To run a query within a React component, call `useGetEmployersForDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployersForDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployersForDirectoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmployersForDirectoryQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>(GetEmployersForDirectoryDocument, options);
      }
export function useGetEmployersForDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>(GetEmployersForDirectoryDocument, options);
        }
export function useGetEmployersForDirectorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>(GetEmployersForDirectoryDocument, options);
        }
export type GetEmployersForDirectoryQueryHookResult = ReturnType<typeof useGetEmployersForDirectoryQuery>;
export type GetEmployersForDirectoryLazyQueryHookResult = ReturnType<typeof useGetEmployersForDirectoryLazyQuery>;
export type GetEmployersForDirectorySuspenseQueryHookResult = ReturnType<typeof useGetEmployersForDirectorySuspenseQuery>;
export type GetEmployersForDirectoryQueryResult = Apollo.QueryResult<Types.GetEmployersForDirectoryQuery, Types.GetEmployersForDirectoryQueryVariables>;
export function refetchGetEmployersForDirectoryQuery(variables?: Types.GetEmployersForDirectoryQueryVariables) {
      return { query: GetEmployersForDirectoryDocument, variables: variables }
    }
export const GetAllEmployersWithPagesSlugsDocument = gql`
    query GetAllEmployersWithPagesSlugs {
  employer(where: {page_active: {_eq: true}}) {
    slug
  }
}
    `;

/**
 * __useGetAllEmployersWithPagesSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllEmployersWithPagesSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployersWithPagesSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployersWithPagesSlugsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEmployersWithPagesSlugsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>(GetAllEmployersWithPagesSlugsDocument, options);
      }
export function useGetAllEmployersWithPagesSlugsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>(GetAllEmployersWithPagesSlugsDocument, options);
        }
export function useGetAllEmployersWithPagesSlugsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>(GetAllEmployersWithPagesSlugsDocument, options);
        }
export type GetAllEmployersWithPagesSlugsQueryHookResult = ReturnType<typeof useGetAllEmployersWithPagesSlugsQuery>;
export type GetAllEmployersWithPagesSlugsLazyQueryHookResult = ReturnType<typeof useGetAllEmployersWithPagesSlugsLazyQuery>;
export type GetAllEmployersWithPagesSlugsSuspenseQueryHookResult = ReturnType<typeof useGetAllEmployersWithPagesSlugsSuspenseQuery>;
export type GetAllEmployersWithPagesSlugsQueryResult = Apollo.QueryResult<Types.GetAllEmployersWithPagesSlugsQuery, Types.GetAllEmployersWithPagesSlugsQueryVariables>;
export function refetchGetAllEmployersWithPagesSlugsQuery(variables?: Types.GetAllEmployersWithPagesSlugsQueryVariables) {
      return { query: GetAllEmployersWithPagesSlugsDocument, variables: variables }
    }
export const GetAllEmployersSlugsDocument = gql`
    query GetAllEmployersSlugs {
  employer(order_by: {name: asc}) {
    slug
    name
  }
}
    `;

/**
 * __useGetAllEmployersSlugsQuery__
 *
 * To run a query within a React component, call `useGetAllEmployersSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployersSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployersSlugsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEmployersSlugsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>(GetAllEmployersSlugsDocument, options);
      }
export function useGetAllEmployersSlugsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>(GetAllEmployersSlugsDocument, options);
        }
export function useGetAllEmployersSlugsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>(GetAllEmployersSlugsDocument, options);
        }
export type GetAllEmployersSlugsQueryHookResult = ReturnType<typeof useGetAllEmployersSlugsQuery>;
export type GetAllEmployersSlugsLazyQueryHookResult = ReturnType<typeof useGetAllEmployersSlugsLazyQuery>;
export type GetAllEmployersSlugsSuspenseQueryHookResult = ReturnType<typeof useGetAllEmployersSlugsSuspenseQuery>;
export type GetAllEmployersSlugsQueryResult = Apollo.QueryResult<Types.GetAllEmployersSlugsQuery, Types.GetAllEmployersSlugsQueryVariables>;
export function refetchGetAllEmployersSlugsQuery(variables?: Types.GetAllEmployersSlugsQueryVariables) {
      return { query: GetAllEmployersSlugsDocument, variables: variables }
    }
export const GetEmployerBySlugDocument = gql`
    query GetEmployerBySlug($slug: String!) {
  employer(where: {slug: {_eq: $slug}, page_active: {_eq: true}}) {
    ...employerPage
    jobs_aggregate(where: {job_status: {_eq: active}}) {
      aggregate {
        count
      }
    }
  }
}
    ${EmployerPageFragmentDoc}`;

/**
 * __useGetEmployerBySlugQuery__
 *
 * To run a query within a React component, call `useGetEmployerBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetEmployerBySlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables> & ({ variables: Types.GetEmployerBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables>(GetEmployerBySlugDocument, options);
      }
export function useGetEmployerBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables>(GetEmployerBySlugDocument, options);
        }
export function useGetEmployerBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables>(GetEmployerBySlugDocument, options);
        }
export type GetEmployerBySlugQueryHookResult = ReturnType<typeof useGetEmployerBySlugQuery>;
export type GetEmployerBySlugLazyQueryHookResult = ReturnType<typeof useGetEmployerBySlugLazyQuery>;
export type GetEmployerBySlugSuspenseQueryHookResult = ReturnType<typeof useGetEmployerBySlugSuspenseQuery>;
export type GetEmployerBySlugQueryResult = Apollo.QueryResult<Types.GetEmployerBySlugQuery, Types.GetEmployerBySlugQueryVariables>;
export function refetchGetEmployerBySlugQuery(variables: Types.GetEmployerBySlugQueryVariables) {
      return { query: GetEmployerBySlugDocument, variables: variables }
    }
export const GetEmployerPreviewDataByPkDocument = gql`
    query GetEmployerPreviewDataByPK($id: uuid!) {
  employer_by_pk(id: $id) {
    ...employerPage
  }
}
    ${EmployerPageFragmentDoc}`;

/**
 * __useGetEmployerPreviewDataByPkQuery__
 *
 * To run a query within a React component, call `useGetEmployerPreviewDataByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerPreviewDataByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerPreviewDataByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployerPreviewDataByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables> & ({ variables: Types.GetEmployerPreviewDataByPkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables>(GetEmployerPreviewDataByPkDocument, options);
      }
export function useGetEmployerPreviewDataByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables>(GetEmployerPreviewDataByPkDocument, options);
        }
export function useGetEmployerPreviewDataByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables>(GetEmployerPreviewDataByPkDocument, options);
        }
export type GetEmployerPreviewDataByPkQueryHookResult = ReturnType<typeof useGetEmployerPreviewDataByPkQuery>;
export type GetEmployerPreviewDataByPkLazyQueryHookResult = ReturnType<typeof useGetEmployerPreviewDataByPkLazyQuery>;
export type GetEmployerPreviewDataByPkSuspenseQueryHookResult = ReturnType<typeof useGetEmployerPreviewDataByPkSuspenseQuery>;
export type GetEmployerPreviewDataByPkQueryResult = Apollo.QueryResult<Types.GetEmployerPreviewDataByPkQuery, Types.GetEmployerPreviewDataByPkQueryVariables>;
export function refetchGetEmployerPreviewDataByPkQuery(variables: Types.GetEmployerPreviewDataByPkQueryVariables) {
      return { query: GetEmployerPreviewDataByPkDocument, variables: variables }
    }
export const GetEmployerByPkDocument = gql`
    query GetEmployerByPK($id: uuid!) {
  employer_by_pk(id: $id) {
    ...company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useGetEmployerByPkQuery__
 *
 * To run a query within a React component, call `useGetEmployerByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployerByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables> & ({ variables: Types.GetEmployerByPkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables>(GetEmployerByPkDocument, options);
      }
export function useGetEmployerByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables>(GetEmployerByPkDocument, options);
        }
export function useGetEmployerByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables>(GetEmployerByPkDocument, options);
        }
export type GetEmployerByPkQueryHookResult = ReturnType<typeof useGetEmployerByPkQuery>;
export type GetEmployerByPkLazyQueryHookResult = ReturnType<typeof useGetEmployerByPkLazyQuery>;
export type GetEmployerByPkSuspenseQueryHookResult = ReturnType<typeof useGetEmployerByPkSuspenseQuery>;
export type GetEmployerByPkQueryResult = Apollo.QueryResult<Types.GetEmployerByPkQuery, Types.GetEmployerByPkQueryVariables>;
export function refetchGetEmployerByPkQuery(variables: Types.GetEmployerByPkQueryVariables) {
      return { query: GetEmployerByPkDocument, variables: variables }
    }
export const GetEmployerIdBySlugDocument = gql`
    query GetEmployerIdBySlug($_eq: String = "") {
  employer(where: {slug: {_eq: $_eq}}) {
    id
  }
}
    `;

/**
 * __useGetEmployerIdBySlugQuery__
 *
 * To run a query within a React component, call `useGetEmployerIdBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerIdBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerIdBySlugQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGetEmployerIdBySlugQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>(GetEmployerIdBySlugDocument, options);
      }
export function useGetEmployerIdBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>(GetEmployerIdBySlugDocument, options);
        }
export function useGetEmployerIdBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>(GetEmployerIdBySlugDocument, options);
        }
export type GetEmployerIdBySlugQueryHookResult = ReturnType<typeof useGetEmployerIdBySlugQuery>;
export type GetEmployerIdBySlugLazyQueryHookResult = ReturnType<typeof useGetEmployerIdBySlugLazyQuery>;
export type GetEmployerIdBySlugSuspenseQueryHookResult = ReturnType<typeof useGetEmployerIdBySlugSuspenseQuery>;
export type GetEmployerIdBySlugQueryResult = Apollo.QueryResult<Types.GetEmployerIdBySlugQuery, Types.GetEmployerIdBySlugQueryVariables>;
export function refetchGetEmployerIdBySlugQuery(variables?: Types.GetEmployerIdBySlugQueryVariables) {
      return { query: GetEmployerIdBySlugDocument, variables: variables }
    }
export const GetEmployerSubscriptionDocument = gql`
    query GetEmployerSubscription {
  employer {
    ...company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useGetEmployerSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetEmployerSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployerSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>(GetEmployerSubscriptionDocument, options);
      }
export function useGetEmployerSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>(GetEmployerSubscriptionDocument, options);
        }
export function useGetEmployerSubscriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>(GetEmployerSubscriptionDocument, options);
        }
export type GetEmployerSubscriptionQueryHookResult = ReturnType<typeof useGetEmployerSubscriptionQuery>;
export type GetEmployerSubscriptionLazyQueryHookResult = ReturnType<typeof useGetEmployerSubscriptionLazyQuery>;
export type GetEmployerSubscriptionSuspenseQueryHookResult = ReturnType<typeof useGetEmployerSubscriptionSuspenseQuery>;
export type GetEmployerSubscriptionQueryResult = Apollo.QueryResult<Types.GetEmployerSubscriptionQuery, Types.GetEmployerSubscriptionQueryVariables>;
export function refetchGetEmployerSubscriptionQuery(variables?: Types.GetEmployerSubscriptionQueryVariables) {
      return { query: GetEmployerSubscriptionDocument, variables: variables }
    }
export const UpdateEmployerByPkDocument = gql`
    mutation UpdateEmployerByPK($id: uuid!, $_set: employer_set_input = {}) {
  update_employer_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
  }
}
    `;
export type UpdateEmployerByPkMutationFn = Apollo.MutationFunction<Types.UpdateEmployerByPkMutation, Types.UpdateEmployerByPkMutationVariables>;

/**
 * __useUpdateEmployerByPkMutation__
 *
 * To run a mutation, you first call `useUpdateEmployerByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployerByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployerByPkMutation, { data, loading, error }] = useUpdateEmployerByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateEmployerByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmployerByPkMutation, Types.UpdateEmployerByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmployerByPkMutation, Types.UpdateEmployerByPkMutationVariables>(UpdateEmployerByPkDocument, options);
      }
export type UpdateEmployerByPkMutationHookResult = ReturnType<typeof useUpdateEmployerByPkMutation>;
export type UpdateEmployerByPkMutationResult = Apollo.MutationResult<Types.UpdateEmployerByPkMutation>;
export type UpdateEmployerByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmployerByPkMutation, Types.UpdateEmployerByPkMutationVariables>;
export const GetEmployerUsersDocument = gql`
    query GetEmployerUsers($id: uuid!) {
  employer_user(
    where: {employer_id: {_eq: $id}}
    order_by: {user: {first_name: asc_nulls_last, last_name: asc_nulls_last, email: asc}}
  ) {
    id
    employer_id
    access_level {
      label
    }
    user {
      id
      first_name
      last_name
      email
      phone
    }
  }
}
    `;

/**
 * __useGetEmployerUsersQuery__
 *
 * To run a query within a React component, call `useGetEmployerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployerUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables> & ({ variables: Types.GetEmployerUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables>(GetEmployerUsersDocument, options);
      }
export function useGetEmployerUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables>(GetEmployerUsersDocument, options);
        }
export function useGetEmployerUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables>(GetEmployerUsersDocument, options);
        }
export type GetEmployerUsersQueryHookResult = ReturnType<typeof useGetEmployerUsersQuery>;
export type GetEmployerUsersLazyQueryHookResult = ReturnType<typeof useGetEmployerUsersLazyQuery>;
export type GetEmployerUsersSuspenseQueryHookResult = ReturnType<typeof useGetEmployerUsersSuspenseQuery>;
export type GetEmployerUsersQueryResult = Apollo.QueryResult<Types.GetEmployerUsersQuery, Types.GetEmployerUsersQueryVariables>;
export function refetchGetEmployerUsersQuery(variables: Types.GetEmployerUsersQueryVariables) {
      return { query: GetEmployerUsersDocument, variables: variables }
    }
export const GetUserRoleByEmailDocument = gql`
    query GetUserRoleByEmail($email: String!) {
  user(where: {email: {_eq: $email}}) {
    id
    role
    employer_users {
      employer_id
    }
  }
}
    `;

/**
 * __useGetUserRoleByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserRoleByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserRoleByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables> & ({ variables: Types.GetUserRoleByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables>(GetUserRoleByEmailDocument, options);
      }
export function useGetUserRoleByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables>(GetUserRoleByEmailDocument, options);
        }
export function useGetUserRoleByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables>(GetUserRoleByEmailDocument, options);
        }
export type GetUserRoleByEmailQueryHookResult = ReturnType<typeof useGetUserRoleByEmailQuery>;
export type GetUserRoleByEmailLazyQueryHookResult = ReturnType<typeof useGetUserRoleByEmailLazyQuery>;
export type GetUserRoleByEmailSuspenseQueryHookResult = ReturnType<typeof useGetUserRoleByEmailSuspenseQuery>;
export type GetUserRoleByEmailQueryResult = Apollo.QueryResult<Types.GetUserRoleByEmailQuery, Types.GetUserRoleByEmailQueryVariables>;
export function refetchGetUserRoleByEmailQuery(variables: Types.GetUserRoleByEmailQueryVariables) {
      return { query: GetUserRoleByEmailDocument, variables: variables }
    }
export const UpdateEmployerUserAccessLevelDocument = gql`
    mutation UpdateEmployerUserAccessLevel($access_id: enum_access_level_enum!, $id: uuid!) {
  update_employer_user_by_pk(_set: {access_id: $access_id}, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateEmployerUserAccessLevelMutationFn = Apollo.MutationFunction<Types.UpdateEmployerUserAccessLevelMutation, Types.UpdateEmployerUserAccessLevelMutationVariables>;

/**
 * __useUpdateEmployerUserAccessLevelMutation__
 *
 * To run a mutation, you first call `useUpdateEmployerUserAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployerUserAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployerUserAccessLevelMutation, { data, loading, error }] = useUpdateEmployerUserAccessLevelMutation({
 *   variables: {
 *      access_id: // value for 'access_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEmployerUserAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmployerUserAccessLevelMutation, Types.UpdateEmployerUserAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmployerUserAccessLevelMutation, Types.UpdateEmployerUserAccessLevelMutationVariables>(UpdateEmployerUserAccessLevelDocument, options);
      }
export type UpdateEmployerUserAccessLevelMutationHookResult = ReturnType<typeof useUpdateEmployerUserAccessLevelMutation>;
export type UpdateEmployerUserAccessLevelMutationResult = Apollo.MutationResult<Types.UpdateEmployerUserAccessLevelMutation>;
export type UpdateEmployerUserAccessLevelMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmployerUserAccessLevelMutation, Types.UpdateEmployerUserAccessLevelMutationVariables>;
export const InsertEmployerUserDocument = gql`
    mutation InsertEmployerUser($employer_id: uuid!, $user_id: uuid!, $access_id: enum_access_level_enum!) {
  insert_employer_user_one(
    object: {employer_id: $employer_id, user_id: $user_id, access_id: $access_id}
  ) {
    id
  }
}
    `;
export type InsertEmployerUserMutationFn = Apollo.MutationFunction<Types.InsertEmployerUserMutation, Types.InsertEmployerUserMutationVariables>;

/**
 * __useInsertEmployerUserMutation__
 *
 * To run a mutation, you first call `useInsertEmployerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmployerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmployerUserMutation, { data, loading, error }] = useInsertEmployerUserMutation({
 *   variables: {
 *      employer_id: // value for 'employer_id'
 *      user_id: // value for 'user_id'
 *      access_id: // value for 'access_id'
 *   },
 * });
 */
export function useInsertEmployerUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertEmployerUserMutation, Types.InsertEmployerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertEmployerUserMutation, Types.InsertEmployerUserMutationVariables>(InsertEmployerUserDocument, options);
      }
export type InsertEmployerUserMutationHookResult = ReturnType<typeof useInsertEmployerUserMutation>;
export type InsertEmployerUserMutationResult = Apollo.MutationResult<Types.InsertEmployerUserMutation>;
export type InsertEmployerUserMutationOptions = Apollo.BaseMutationOptions<Types.InsertEmployerUserMutation, Types.InsertEmployerUserMutationVariables>;
export const DeleteEmployerUserDocument = gql`
    mutation DeleteEmployerUser($id: uuid!) {
  delete_employer_user_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteEmployerUserMutationFn = Apollo.MutationFunction<Types.DeleteEmployerUserMutation, Types.DeleteEmployerUserMutationVariables>;

/**
 * __useDeleteEmployerUserMutation__
 *
 * To run a mutation, you first call `useDeleteEmployerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployerUserMutation, { data, loading, error }] = useDeleteEmployerUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployerUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEmployerUserMutation, Types.DeleteEmployerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEmployerUserMutation, Types.DeleteEmployerUserMutationVariables>(DeleteEmployerUserDocument, options);
      }
export type DeleteEmployerUserMutationHookResult = ReturnType<typeof useDeleteEmployerUserMutation>;
export type DeleteEmployerUserMutationResult = Apollo.MutationResult<Types.DeleteEmployerUserMutation>;
export type DeleteEmployerUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEmployerUserMutation, Types.DeleteEmployerUserMutationVariables>;
export const GetJobsBySlugDocument = gql`
    query GetJobsBySlug($slug: String!, $today: date = today) {
  job(
    where: {employer: {_or: [{slug: {_eq: $slug}}, {parent: {slug: {_eq: $slug}}, managed_by_parent: {_eq: true}}]}, list_date: {_lte: $today}, job_status: {_eq: active}}
    order_by: {list_date: desc}
    limit: 10
  ) {
    ...jobWithEmployer
  }
  job_aggregate(
    where: {employer: {_or: [{slug: {_eq: $slug}}, {parent: {slug: {_eq: $slug}}, managed_by_parent: {_eq: true}}]}, list_date: {_lte: $today}, job_status: {_eq: active}}
  ) {
    aggregate {
      count
    }
  }
}
    ${JobWithEmployerFragmentDoc}`;

/**
 * __useGetJobsBySlugQuery__
 *
 * To run a query within a React component, call `useGetJobsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      today: // value for 'today'
 *   },
 * });
 */
export function useGetJobsBySlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables> & ({ variables: Types.GetJobsBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables>(GetJobsBySlugDocument, options);
      }
export function useGetJobsBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables>(GetJobsBySlugDocument, options);
        }
export function useGetJobsBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables>(GetJobsBySlugDocument, options);
        }
export type GetJobsBySlugQueryHookResult = ReturnType<typeof useGetJobsBySlugQuery>;
export type GetJobsBySlugLazyQueryHookResult = ReturnType<typeof useGetJobsBySlugLazyQuery>;
export type GetJobsBySlugSuspenseQueryHookResult = ReturnType<typeof useGetJobsBySlugSuspenseQuery>;
export type GetJobsBySlugQueryResult = Apollo.QueryResult<Types.GetJobsBySlugQuery, Types.GetJobsBySlugQueryVariables>;
export function refetchGetJobsBySlugQuery(variables: Types.GetJobsBySlugQueryVariables) {
      return { query: GetJobsBySlugDocument, variables: variables }
    }
export const GetDraftJobsDocument = gql`
    query GetDraftJobs($slug: String) {
  job(where: {slug: {_eq: $slug}}) {
    ...jobWithEmployer
  }
  job_aggregate(where: {slug: {_eq: $slug}}) {
    aggregate {
      count
    }
  }
}
    ${JobWithEmployerFragmentDoc}`;

/**
 * __useGetDraftJobsQuery__
 *
 * To run a query within a React component, call `useGetDraftJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftJobsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetDraftJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>(GetDraftJobsDocument, options);
      }
export function useGetDraftJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>(GetDraftJobsDocument, options);
        }
export function useGetDraftJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>(GetDraftJobsDocument, options);
        }
export type GetDraftJobsQueryHookResult = ReturnType<typeof useGetDraftJobsQuery>;
export type GetDraftJobsLazyQueryHookResult = ReturnType<typeof useGetDraftJobsLazyQuery>;
export type GetDraftJobsSuspenseQueryHookResult = ReturnType<typeof useGetDraftJobsSuspenseQuery>;
export type GetDraftJobsQueryResult = Apollo.QueryResult<Types.GetDraftJobsQuery, Types.GetDraftJobsQueryVariables>;
export function refetchGetDraftJobsQuery(variables?: Types.GetDraftJobsQueryVariables) {
      return { query: GetDraftJobsDocument, variables: variables }
    }
export const InsertEmployerByNameDocument = gql`
    mutation InsertEmployerByName($name: String!) {
  insert_employer_one(object: {name: $name}) {
    id
    name
    created_at
  }
}
    `;
export type InsertEmployerByNameMutationFn = Apollo.MutationFunction<Types.InsertEmployerByNameMutation, Types.InsertEmployerByNameMutationVariables>;

/**
 * __useInsertEmployerByNameMutation__
 *
 * To run a mutation, you first call `useInsertEmployerByNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmployerByNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmployerByNameMutation, { data, loading, error }] = useInsertEmployerByNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertEmployerByNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertEmployerByNameMutation, Types.InsertEmployerByNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertEmployerByNameMutation, Types.InsertEmployerByNameMutationVariables>(InsertEmployerByNameDocument, options);
      }
export type InsertEmployerByNameMutationHookResult = ReturnType<typeof useInsertEmployerByNameMutation>;
export type InsertEmployerByNameMutationResult = Apollo.MutationResult<Types.InsertEmployerByNameMutation>;
export type InsertEmployerByNameMutationOptions = Apollo.BaseMutationOptions<Types.InsertEmployerByNameMutation, Types.InsertEmployerByNameMutationVariables>;
export const GetEnumsDocument = gql`
    query GetEnums {
  access_level: enum_access_level(order_by: {value: asc}) {
    label
    value
  }
  application_status: enum_application_status(order_by: {value: asc}) {
    label
    value
  }
  category: enum_category(order_by: {value: asc}) {
    label
    value
  }
  dial_code: enum_dial_code(order_by: {label: asc}) {
    label
    value
  }
  job_status: enum_job_status(order_by: {value: asc}) {
    label
    value
  }
  pay_type: enum_pay_type(order_by: {value: asc}) {
    label
    value
  }
  question_type: enum_question_type(order_by: {value: asc}) {
    label
    value
  }
  role: enum_role(order_by: {value: asc}) {
    label
    value
  }
  work_type: enum_work_type(order_by: {value: asc}) {
    label
    value
  }
  listing: enum_listing(order_by: {value: asc}) {
    label
    value
  }
}
    `;

/**
 * __useGetEnumsQuery__
 *
 * To run a query within a React component, call `useGetEnumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnumsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>(GetEnumsDocument, options);
      }
export function useGetEnumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>(GetEnumsDocument, options);
        }
export function useGetEnumsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>(GetEnumsDocument, options);
        }
export type GetEnumsQueryHookResult = ReturnType<typeof useGetEnumsQuery>;
export type GetEnumsLazyQueryHookResult = ReturnType<typeof useGetEnumsLazyQuery>;
export type GetEnumsSuspenseQueryHookResult = ReturnType<typeof useGetEnumsSuspenseQuery>;
export type GetEnumsQueryResult = Apollo.QueryResult<Types.GetEnumsQuery, Types.GetEnumsQueryVariables>;
export function refetchGetEnumsQuery(variables?: Types.GetEnumsQueryVariables) {
      return { query: GetEnumsDocument, variables: variables }
    }
export const GetAnonEnumsDocument = gql`
    query GetAnonEnums {
  category: enum_category(order_by: {value: asc}) {
    label
    value
  }
  dial_code: enum_dial_code(order_by: {label: asc}) {
    label
    value
  }
}
    `;

/**
 * __useGetAnonEnumsQuery__
 *
 * To run a query within a React component, call `useGetAnonEnumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonEnumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonEnumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnonEnumsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>(GetAnonEnumsDocument, options);
      }
export function useGetAnonEnumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>(GetAnonEnumsDocument, options);
        }
export function useGetAnonEnumsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>(GetAnonEnumsDocument, options);
        }
export type GetAnonEnumsQueryHookResult = ReturnType<typeof useGetAnonEnumsQuery>;
export type GetAnonEnumsLazyQueryHookResult = ReturnType<typeof useGetAnonEnumsLazyQuery>;
export type GetAnonEnumsSuspenseQueryHookResult = ReturnType<typeof useGetAnonEnumsSuspenseQuery>;
export type GetAnonEnumsQueryResult = Apollo.QueryResult<Types.GetAnonEnumsQuery, Types.GetAnonEnumsQueryVariables>;
export function refetchGetAnonEnumsQuery(variables?: Types.GetAnonEnumsQueryVariables) {
      return { query: GetAnonEnumsDocument, variables: variables }
    }
export const GetEnumCategoriesDocument = gql`
    query GetEnumCategories {
  enum_category(order_by: {value: asc}) {
    label
    value
  }
}
    `;

/**
 * __useGetEnumCategoriesQuery__
 *
 * To run a query within a React component, call `useGetEnumCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnumCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnumCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnumCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>(GetEnumCategoriesDocument, options);
      }
export function useGetEnumCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>(GetEnumCategoriesDocument, options);
        }
export function useGetEnumCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>(GetEnumCategoriesDocument, options);
        }
export type GetEnumCategoriesQueryHookResult = ReturnType<typeof useGetEnumCategoriesQuery>;
export type GetEnumCategoriesLazyQueryHookResult = ReturnType<typeof useGetEnumCategoriesLazyQuery>;
export type GetEnumCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetEnumCategoriesSuspenseQuery>;
export type GetEnumCategoriesQueryResult = Apollo.QueryResult<Types.GetEnumCategoriesQuery, Types.GetEnumCategoriesQueryVariables>;
export function refetchGetEnumCategoriesQuery(variables?: Types.GetEnumCategoriesQueryVariables) {
      return { query: GetEnumCategoriesDocument, variables: variables }
    }
export const HomePageJobsDocument = gql`
    query HomePageJobs {
  category: enum_category(
    where: {value: {_neq: "other"}}
    order_by: {jobs_aggregate: {count: desc}}
  ) {
    label
    value
    jobs_aggregate(where: {job_status: {_eq: active}}) {
      aggregate {
        count
      }
    }
  }
  employer_list: employer(
    limit: 80
    where: {_and: [{logo: {_is_null: false}}, {logo: {_neq: ""}}, {page_active: {_eq: true}}, {jobs: {status: {value: {_eq: "active"}}}}]}
  ) {
    name
    logo
    id
    slug
    jobs_aggregate(where: {job_status: {_eq: active}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useHomePageJobsQuery__
 *
 * To run a query within a React component, call `useHomePageJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>(HomePageJobsDocument, options);
      }
export function useHomePageJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>(HomePageJobsDocument, options);
        }
export function useHomePageJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>(HomePageJobsDocument, options);
        }
export type HomePageJobsQueryHookResult = ReturnType<typeof useHomePageJobsQuery>;
export type HomePageJobsLazyQueryHookResult = ReturnType<typeof useHomePageJobsLazyQuery>;
export type HomePageJobsSuspenseQueryHookResult = ReturnType<typeof useHomePageJobsSuspenseQuery>;
export type HomePageJobsQueryResult = Apollo.QueryResult<Types.HomePageJobsQuery, Types.HomePageJobsQueryVariables>;
export function refetchHomePageJobsQuery(variables?: Types.HomePageJobsQueryVariables) {
      return { query: HomePageJobsDocument, variables: variables }
    }
export const RegionalActiveJobsDocument = gql`
    query RegionalActiveJobs {
  auckland: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Auckland"}}}
  ) {
    aggregate {
      count
    }
  }
  bay_of_plenty: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Bay of Plenty"}}}
  ) {
    aggregate {
      count
    }
  }
  canterbury: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Canterbury"}}}
  ) {
    aggregate {
      count
    }
  }
  gisborne: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Gisborne"}}}
  ) {
    aggregate {
      count
    }
  }
  hawkes_bay: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Hawke's Bay"}}}
  ) {
    aggregate {
      count
    }
  }
  manawatu_whanganui: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_in: ["Manawatu Wanganui", "Manawatu Whanganui"]}}}
  ) {
    aggregate {
      count
    }
  }
  marlborough: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Marlborough"}}}
  ) {
    aggregate {
      count
    }
  }
  nelson: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Nelson"}}}
  ) {
    aggregate {
      count
    }
  }
  northland: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Northland"}}}
  ) {
    aggregate {
      count
    }
  }
  otago: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Otago"}}}
  ) {
    aggregate {
      count
    }
  }
  southland: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Southland"}}}
  ) {
    aggregate {
      count
    }
  }
  taranaki: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Taranaki"}}}
  ) {
    aggregate {
      count
    }
  }
  tasman: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Tasman"}}}
  ) {
    aggregate {
      count
    }
  }
  waikato: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Waikato"}}}
  ) {
    aggregate {
      count
    }
  }
  wellington: job_aggregate(
    where: {job_status: {_eq: active}, location: {region: {_eq: "Wellington"}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useRegionalActiveJobsQuery__
 *
 * To run a query within a React component, call `useRegionalActiveJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionalActiveJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionalActiveJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionalActiveJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>(RegionalActiveJobsDocument, options);
      }
export function useRegionalActiveJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>(RegionalActiveJobsDocument, options);
        }
export function useRegionalActiveJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>(RegionalActiveJobsDocument, options);
        }
export type RegionalActiveJobsQueryHookResult = ReturnType<typeof useRegionalActiveJobsQuery>;
export type RegionalActiveJobsLazyQueryHookResult = ReturnType<typeof useRegionalActiveJobsLazyQuery>;
export type RegionalActiveJobsSuspenseQueryHookResult = ReturnType<typeof useRegionalActiveJobsSuspenseQuery>;
export type RegionalActiveJobsQueryResult = Apollo.QueryResult<Types.RegionalActiveJobsQuery, Types.RegionalActiveJobsQueryVariables>;
export function refetchRegionalActiveJobsQuery(variables?: Types.RegionalActiveJobsQueryVariables) {
      return { query: RegionalActiveJobsDocument, variables: variables }
    }
export const LatestActiveJobsDocument = gql`
    query LatestActiveJobs {
  job(where: {job_status: {_eq: active}}, order_by: {list_date: desc}, limit: 18) {
    created_at
    employer {
      name
    }
    location {
      region
    }
    title
    slug
    category {
      value
    }
  }
}
    `;

/**
 * __useLatestActiveJobsQuery__
 *
 * To run a query within a React component, call `useLatestActiveJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestActiveJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestActiveJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestActiveJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>(LatestActiveJobsDocument, options);
      }
export function useLatestActiveJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>(LatestActiveJobsDocument, options);
        }
export function useLatestActiveJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>(LatestActiveJobsDocument, options);
        }
export type LatestActiveJobsQueryHookResult = ReturnType<typeof useLatestActiveJobsQuery>;
export type LatestActiveJobsLazyQueryHookResult = ReturnType<typeof useLatestActiveJobsLazyQuery>;
export type LatestActiveJobsSuspenseQueryHookResult = ReturnType<typeof useLatestActiveJobsSuspenseQuery>;
export type LatestActiveJobsQueryResult = Apollo.QueryResult<Types.LatestActiveJobsQuery, Types.LatestActiveJobsQueryVariables>;
export function refetchLatestActiveJobsQuery(variables?: Types.LatestActiveJobsQueryVariables) {
      return { query: LatestActiveJobsDocument, variables: variables }
    }
export const GetIntegrationByIdDocument = gql`
    query GetIntegrationById($integration: String!, $api_key: String!) {
  enum_integration_type(
    where: {value: {_eq: $integration}, api_key: {_eq: $api_key, _is_null: false}}
  ) {
    value
    label
    api_key
  }
}
    `;

/**
 * __useGetIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationByIdQuery({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *   },
 * });
 */
export function useGetIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables> & ({ variables: Types.GetIntegrationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables>(GetIntegrationByIdDocument, options);
      }
export function useGetIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables>(GetIntegrationByIdDocument, options);
        }
export function useGetIntegrationByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables>(GetIntegrationByIdDocument, options);
        }
export type GetIntegrationByIdQueryHookResult = ReturnType<typeof useGetIntegrationByIdQuery>;
export type GetIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetIntegrationByIdLazyQuery>;
export type GetIntegrationByIdSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationByIdSuspenseQuery>;
export type GetIntegrationByIdQueryResult = Apollo.QueryResult<Types.GetIntegrationByIdQuery, Types.GetIntegrationByIdQueryVariables>;
export function refetchGetIntegrationByIdQuery(variables: Types.GetIntegrationByIdQueryVariables) {
      return { query: GetIntegrationByIdDocument, variables: variables }
    }
export const GetEmployersByIntegrationDocument = gql`
    query GetEmployersByIntegration($integration: String!, $api_key: String!, $limit: Int = 10, $offset: Int = 0, $order_by: employer_order_by! = {created_at: desc}) {
  integration_employer(
    where: {integration: {value: {_eq: $integration}, api_key: {_eq: $api_key}}, employer: {deleted_at: {_is_null: true}}}
    limit: $limit
    offset: $offset
    order_by: {employer: $order_by}
  ) {
    id
    integration_fkey
    employer {
      id
      category
      description
      deleted_at
      created_at
      instagram
      name
      slug
      parent_id
      organisation_type
    }
  }
}
    `;

/**
 * __useGetEmployersByIntegrationQuery__
 *
 * To run a query within a React component, call `useGetEmployersByIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployersByIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployersByIntegrationQuery({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetEmployersByIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables> & ({ variables: Types.GetEmployersByIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables>(GetEmployersByIntegrationDocument, options);
      }
export function useGetEmployersByIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables>(GetEmployersByIntegrationDocument, options);
        }
export function useGetEmployersByIntegrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables>(GetEmployersByIntegrationDocument, options);
        }
export type GetEmployersByIntegrationQueryHookResult = ReturnType<typeof useGetEmployersByIntegrationQuery>;
export type GetEmployersByIntegrationLazyQueryHookResult = ReturnType<typeof useGetEmployersByIntegrationLazyQuery>;
export type GetEmployersByIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetEmployersByIntegrationSuspenseQuery>;
export type GetEmployersByIntegrationQueryResult = Apollo.QueryResult<Types.GetEmployersByIntegrationQuery, Types.GetEmployersByIntegrationQueryVariables>;
export function refetchGetEmployersByIntegrationQuery(variables: Types.GetEmployersByIntegrationQueryVariables) {
      return { query: GetEmployersByIntegrationDocument, variables: variables }
    }
export const GetEmployerByIntegrationByIdDocument = gql`
    query GetEmployerByIntegrationById($integration: String!, $api_key: String!, $id: uuid!) {
  employer(
    where: {id: {_eq: $id}, integration_employers: {integration: {value: {_eq: $integration}, api_key: {_eq: $api_key}}}, deleted_at: {_is_null: true}}
    limit: 1
  ) {
    id
    category
    description
    deleted_at
    created_at
    instagram
    name
    slug
    parent_id
    organisation_type
    integration_employers(where: {integration_type: {_eq: $integration}}) {
      id
      integration_fkey
    }
  }
}
    `;

/**
 * __useGetEmployerByIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetEmployerByIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerByIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerByIntegrationByIdQuery({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployerByIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables> & ({ variables: Types.GetEmployerByIntegrationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables>(GetEmployerByIntegrationByIdDocument, options);
      }
export function useGetEmployerByIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables>(GetEmployerByIntegrationByIdDocument, options);
        }
export function useGetEmployerByIntegrationByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables>(GetEmployerByIntegrationByIdDocument, options);
        }
export type GetEmployerByIntegrationByIdQueryHookResult = ReturnType<typeof useGetEmployerByIntegrationByIdQuery>;
export type GetEmployerByIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetEmployerByIntegrationByIdLazyQuery>;
export type GetEmployerByIntegrationByIdSuspenseQueryHookResult = ReturnType<typeof useGetEmployerByIntegrationByIdSuspenseQuery>;
export type GetEmployerByIntegrationByIdQueryResult = Apollo.QueryResult<Types.GetEmployerByIntegrationByIdQuery, Types.GetEmployerByIntegrationByIdQueryVariables>;
export function refetchGetEmployerByIntegrationByIdQuery(variables: Types.GetEmployerByIntegrationByIdQueryVariables) {
      return { query: GetEmployerByIntegrationByIdDocument, variables: variables }
    }
export const GetJobsByIntegrationDocument = gql`
    query GetJobsByIntegration($integration: String!, $api_key: String!, $limit: Int = 100, $offset: Int = 0, $order_by: [job_order_by!]! = {created_at: desc}) {
  job(
    where: {employer: {integration_employers: {integration: {value: {_eq: $integration}, api_key: {_eq: $api_key}}}, deleted_at: {_is_null: true}}, deleted_at: {_is_null: true}}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...jobWithoutComputed
  }
}
    ${JobWithoutComputedFragmentDoc}`;

/**
 * __useGetJobsByIntegrationQuery__
 *
 * To run a query within a React component, call `useGetJobsByIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsByIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsByIntegrationQuery({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetJobsByIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables> & ({ variables: Types.GetJobsByIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables>(GetJobsByIntegrationDocument, options);
      }
export function useGetJobsByIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables>(GetJobsByIntegrationDocument, options);
        }
export function useGetJobsByIntegrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables>(GetJobsByIntegrationDocument, options);
        }
export type GetJobsByIntegrationQueryHookResult = ReturnType<typeof useGetJobsByIntegrationQuery>;
export type GetJobsByIntegrationLazyQueryHookResult = ReturnType<typeof useGetJobsByIntegrationLazyQuery>;
export type GetJobsByIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetJobsByIntegrationSuspenseQuery>;
export type GetJobsByIntegrationQueryResult = Apollo.QueryResult<Types.GetJobsByIntegrationQuery, Types.GetJobsByIntegrationQueryVariables>;
export function refetchGetJobsByIntegrationQuery(variables: Types.GetJobsByIntegrationQueryVariables) {
      return { query: GetJobsByIntegrationDocument, variables: variables }
    }
export const GetJobByIntegrationByIdDocument = gql`
    query GetJobByIntegrationByID($integration: String!, $api_key: String!, $id: uuid!) {
  job(
    where: {id: {_eq: $id}, employer: {integration_employers: {integration: {value: {_eq: $integration}, api_key: {_eq: $api_key}}}, deleted_at: {_is_null: true}}, deleted_at: {_is_null: true}}
  ) {
    ...jobWithoutComputed
  }
}
    ${JobWithoutComputedFragmentDoc}`;

/**
 * __useGetJobByIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useGetJobByIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobByIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobByIntegrationByIdQuery({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobByIntegrationByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables> & ({ variables: Types.GetJobByIntegrationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables>(GetJobByIntegrationByIdDocument, options);
      }
export function useGetJobByIntegrationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables>(GetJobByIntegrationByIdDocument, options);
        }
export function useGetJobByIntegrationByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables>(GetJobByIntegrationByIdDocument, options);
        }
export type GetJobByIntegrationByIdQueryHookResult = ReturnType<typeof useGetJobByIntegrationByIdQuery>;
export type GetJobByIntegrationByIdLazyQueryHookResult = ReturnType<typeof useGetJobByIntegrationByIdLazyQuery>;
export type GetJobByIntegrationByIdSuspenseQueryHookResult = ReturnType<typeof useGetJobByIntegrationByIdSuspenseQuery>;
export type GetJobByIntegrationByIdQueryResult = Apollo.QueryResult<Types.GetJobByIntegrationByIdQuery, Types.GetJobByIntegrationByIdQueryVariables>;
export function refetchGetJobByIntegrationByIdQuery(variables: Types.GetJobByIntegrationByIdQueryVariables) {
      return { query: GetJobByIntegrationByIdDocument, variables: variables }
    }
export const DeleteJobByIntegrationByIdDocument = gql`
    mutation DeleteJobByIntegrationByID($integration: String!, $api_key: String!, $id: uuid!, $deleted_at: timestamptz!) {
  update_job(
    where: {id: {_eq: $id}, employer: {integration_employers: {integration: {value: {_eq: $integration}, api_key: {_eq: $api_key}}}, deleted_at: {_is_null: true}}, deleted_at: {_is_null: true}}
    _set: {deleted_at: $deleted_at}
  ) {
    affected_rows
  }
  update_integration_job(
    where: {job_id: {_eq: $id}}
    _set: {deleted_at: $deleted_at}
  ) {
    affected_rows
  }
}
    `;
export type DeleteJobByIntegrationByIdMutationFn = Apollo.MutationFunction<Types.DeleteJobByIntegrationByIdMutation, Types.DeleteJobByIntegrationByIdMutationVariables>;

/**
 * __useDeleteJobByIntegrationByIdMutation__
 *
 * To run a mutation, you first call `useDeleteJobByIntegrationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobByIntegrationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobByIntegrationByIdMutation, { data, loading, error }] = useDeleteJobByIntegrationByIdMutation({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useDeleteJobByIntegrationByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteJobByIntegrationByIdMutation, Types.DeleteJobByIntegrationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteJobByIntegrationByIdMutation, Types.DeleteJobByIntegrationByIdMutationVariables>(DeleteJobByIntegrationByIdDocument, options);
      }
export type DeleteJobByIntegrationByIdMutationHookResult = ReturnType<typeof useDeleteJobByIntegrationByIdMutation>;
export type DeleteJobByIntegrationByIdMutationResult = Apollo.MutationResult<Types.DeleteJobByIntegrationByIdMutation>;
export type DeleteJobByIntegrationByIdMutationOptions = Apollo.BaseMutationOptions<Types.DeleteJobByIntegrationByIdMutation, Types.DeleteJobByIntegrationByIdMutationVariables>;
export const UpdateJobByIntegrationByIdDocument = gql`
    mutation UpdateJobByIntegrationById($integration: String!, $api_key: String!, $id: uuid!, $job: job_set_input!, $data: jsonb!) {
  update_job(
    where: {id: {_eq: $id}, employer: {integration_employers: {integration: {value: {_eq: $integration}, api_key: {_eq: $api_key}}}, deleted_at: {_is_null: true}}, deleted_at: {_is_null: true}}
    _set: $job
  ) {
    returning {
      ...jobWithoutComputed
    }
  }
  update_integration_job(
    where: {job_id: {_eq: $id}, deleted_at: {_is_null: true}}
    _set: {data: $data}
  ) {
    affected_rows
  }
}
    ${JobWithoutComputedFragmentDoc}`;
export type UpdateJobByIntegrationByIdMutationFn = Apollo.MutationFunction<Types.UpdateJobByIntegrationByIdMutation, Types.UpdateJobByIntegrationByIdMutationVariables>;

/**
 * __useUpdateJobByIntegrationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateJobByIntegrationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobByIntegrationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobByIntegrationByIdMutation, { data, loading, error }] = useUpdateJobByIntegrationByIdMutation({
 *   variables: {
 *      integration: // value for 'integration'
 *      api_key: // value for 'api_key'
 *      id: // value for 'id'
 *      job: // value for 'job'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateJobByIntegrationByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobByIntegrationByIdMutation, Types.UpdateJobByIntegrationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobByIntegrationByIdMutation, Types.UpdateJobByIntegrationByIdMutationVariables>(UpdateJobByIntegrationByIdDocument, options);
      }
export type UpdateJobByIntegrationByIdMutationHookResult = ReturnType<typeof useUpdateJobByIntegrationByIdMutation>;
export type UpdateJobByIntegrationByIdMutationResult = Apollo.MutationResult<Types.UpdateJobByIntegrationByIdMutation>;
export type UpdateJobByIntegrationByIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobByIntegrationByIdMutation, Types.UpdateJobByIntegrationByIdMutationVariables>;
export const PostJobByIntegrationDocument = gql`
    mutation PostJobByIntegration($integration_job: integration_job_insert_input!) {
  insert_integration_job_one(object: $integration_job) {
    id
    job {
      ...jobWithoutComputed
    }
  }
}
    ${JobWithoutComputedFragmentDoc}`;
export type PostJobByIntegrationMutationFn = Apollo.MutationFunction<Types.PostJobByIntegrationMutation, Types.PostJobByIntegrationMutationVariables>;

/**
 * __usePostJobByIntegrationMutation__
 *
 * To run a mutation, you first call `usePostJobByIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostJobByIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postJobByIntegrationMutation, { data, loading, error }] = usePostJobByIntegrationMutation({
 *   variables: {
 *      integration_job: // value for 'integration_job'
 *   },
 * });
 */
export function usePostJobByIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.PostJobByIntegrationMutation, Types.PostJobByIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PostJobByIntegrationMutation, Types.PostJobByIntegrationMutationVariables>(PostJobByIntegrationDocument, options);
      }
export type PostJobByIntegrationMutationHookResult = ReturnType<typeof usePostJobByIntegrationMutation>;
export type PostJobByIntegrationMutationResult = Apollo.MutationResult<Types.PostJobByIntegrationMutation>;
export type PostJobByIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.PostJobByIntegrationMutation, Types.PostJobByIntegrationMutationVariables>;
export const BulkPostJobByIntegrationDocument = gql`
    mutation BulkPostJobByIntegration($job_insert: [job_insert_input!]!) {
  insert_job(
    objects: $job_insert
    on_conflict: {constraint: job_slug_key, update_columns: [employer_id, title, about, tagline, list_date, expiry_date, job_status, requirements, highlights, job_category]}
  ) {
    returning {
      ...jobWithoutComputed
    }
  }
}
    ${JobWithoutComputedFragmentDoc}`;
export type BulkPostJobByIntegrationMutationFn = Apollo.MutationFunction<Types.BulkPostJobByIntegrationMutation, Types.BulkPostJobByIntegrationMutationVariables>;

/**
 * __useBulkPostJobByIntegrationMutation__
 *
 * To run a mutation, you first call `useBulkPostJobByIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkPostJobByIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkPostJobByIntegrationMutation, { data, loading, error }] = useBulkPostJobByIntegrationMutation({
 *   variables: {
 *      job_insert: // value for 'job_insert'
 *   },
 * });
 */
export function useBulkPostJobByIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkPostJobByIntegrationMutation, Types.BulkPostJobByIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkPostJobByIntegrationMutation, Types.BulkPostJobByIntegrationMutationVariables>(BulkPostJobByIntegrationDocument, options);
      }
export type BulkPostJobByIntegrationMutationHookResult = ReturnType<typeof useBulkPostJobByIntegrationMutation>;
export type BulkPostJobByIntegrationMutationResult = Apollo.MutationResult<Types.BulkPostJobByIntegrationMutation>;
export type BulkPostJobByIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.BulkPostJobByIntegrationMutation, Types.BulkPostJobByIntegrationMutationVariables>;
export const GetIntegrationArgsDocument = gql`
    query GetIntegrationArgs {
  category: enum_category {
    value
    label
  }
  job_status: enum_job_status {
    value
    label
  }
  listing_type: enum_listing {
    value
    label
  }
  pay_type: enum_pay_type {
    value
    label
  }
  work_type: enum_work_type {
    value
    label
  }
}
    `;

/**
 * __useGetIntegrationArgsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationArgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationArgsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>(GetIntegrationArgsDocument, options);
      }
export function useGetIntegrationArgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>(GetIntegrationArgsDocument, options);
        }
export function useGetIntegrationArgsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>(GetIntegrationArgsDocument, options);
        }
export type GetIntegrationArgsQueryHookResult = ReturnType<typeof useGetIntegrationArgsQuery>;
export type GetIntegrationArgsLazyQueryHookResult = ReturnType<typeof useGetIntegrationArgsLazyQuery>;
export type GetIntegrationArgsSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationArgsSuspenseQuery>;
export type GetIntegrationArgsQueryResult = Apollo.QueryResult<Types.GetIntegrationArgsQuery, Types.GetIntegrationArgsQueryVariables>;
export function refetchGetIntegrationArgsQuery(variables?: Types.GetIntegrationArgsQueryVariables) {
      return { query: GetIntegrationArgsDocument, variables: variables }
    }
export const GetIntegrationLocationDocument = gql`
    query GetIntegrationLocation($search: String = "", $limit: Int = 10, $offset: Int = 0) {
  search_location(args: {search: $search}, limit: $limit, offset: $offset) {
    id
    label
    number
    neighbourhood
    municipality
    postal_code
    region
    street
    country
    district
    geometry
  }
}
    `;

/**
 * __useGetIntegrationLocationQuery__
 *
 * To run a query within a React component, call `useGetIntegrationLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationLocationQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetIntegrationLocationQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>(GetIntegrationLocationDocument, options);
      }
export function useGetIntegrationLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>(GetIntegrationLocationDocument, options);
        }
export function useGetIntegrationLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>(GetIntegrationLocationDocument, options);
        }
export type GetIntegrationLocationQueryHookResult = ReturnType<typeof useGetIntegrationLocationQuery>;
export type GetIntegrationLocationLazyQueryHookResult = ReturnType<typeof useGetIntegrationLocationLazyQuery>;
export type GetIntegrationLocationSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationLocationSuspenseQuery>;
export type GetIntegrationLocationQueryResult = Apollo.QueryResult<Types.GetIntegrationLocationQuery, Types.GetIntegrationLocationQueryVariables>;
export function refetchGetIntegrationLocationQuery(variables?: Types.GetIntegrationLocationQueryVariables) {
      return { query: GetIntegrationLocationDocument, variables: variables }
    }
export const GetDownerIntegrationDocument = gql`
    query GetDownerIntegration($api_key: String!) {
  integration_employer(
    where: {integration_type: {_eq: "pageup_downer"}, integration: {api_key: {_eq: $api_key}}}
  ) {
    id
    employer_id
    integration_fkey
  }
}
    `;

/**
 * __useGetDownerIntegrationQuery__
 *
 * To run a query within a React component, call `useGetDownerIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownerIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownerIntegrationQuery({
 *   variables: {
 *      api_key: // value for 'api_key'
 *   },
 * });
 */
export function useGetDownerIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables> & ({ variables: Types.GetDownerIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables>(GetDownerIntegrationDocument, options);
      }
export function useGetDownerIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables>(GetDownerIntegrationDocument, options);
        }
export function useGetDownerIntegrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables>(GetDownerIntegrationDocument, options);
        }
export type GetDownerIntegrationQueryHookResult = ReturnType<typeof useGetDownerIntegrationQuery>;
export type GetDownerIntegrationLazyQueryHookResult = ReturnType<typeof useGetDownerIntegrationLazyQuery>;
export type GetDownerIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetDownerIntegrationSuspenseQuery>;
export type GetDownerIntegrationQueryResult = Apollo.QueryResult<Types.GetDownerIntegrationQuery, Types.GetDownerIntegrationQueryVariables>;
export function refetchGetDownerIntegrationQuery(variables: Types.GetDownerIntegrationQueryVariables) {
      return { query: GetDownerIntegrationDocument, variables: variables }
    }
export const InsertRawJobSubmissionDocument = gql`
    mutation InsertRawJobSubmission($object: internal_raw_job_submission_insert_input!) {
  insert_internal_raw_job_submission_one(object: $object) {
    id
  }
}
    `;
export type InsertRawJobSubmissionMutationFn = Apollo.MutationFunction<Types.InsertRawJobSubmissionMutation, Types.InsertRawJobSubmissionMutationVariables>;

/**
 * __useInsertRawJobSubmissionMutation__
 *
 * To run a mutation, you first call `useInsertRawJobSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRawJobSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRawJobSubmissionMutation, { data, loading, error }] = useInsertRawJobSubmissionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertRawJobSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertRawJobSubmissionMutation, Types.InsertRawJobSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertRawJobSubmissionMutation, Types.InsertRawJobSubmissionMutationVariables>(InsertRawJobSubmissionDocument, options);
      }
export type InsertRawJobSubmissionMutationHookResult = ReturnType<typeof useInsertRawJobSubmissionMutation>;
export type InsertRawJobSubmissionMutationResult = Apollo.MutationResult<Types.InsertRawJobSubmissionMutation>;
export type InsertRawJobSubmissionMutationOptions = Apollo.BaseMutationOptions<Types.InsertRawJobSubmissionMutation, Types.InsertRawJobSubmissionMutationVariables>;
export const GetEmployerIdByJobAdderAccountDocument = gql`
    query GetEmployerIdByJobAdderAccount($integration_fkey: String!) {
  integration_employer(
    where: {integration_type: {_eq: "jobadder"}, integration_fkey: {_eq: $integration_fkey}}
  ) {
    employer_id
  }
}
    `;

/**
 * __useGetEmployerIdByJobAdderAccountQuery__
 *
 * To run a query within a React component, call `useGetEmployerIdByJobAdderAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerIdByJobAdderAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerIdByJobAdderAccountQuery({
 *   variables: {
 *      integration_fkey: // value for 'integration_fkey'
 *   },
 * });
 */
export function useGetEmployerIdByJobAdderAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables> & ({ variables: Types.GetEmployerIdByJobAdderAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables>(GetEmployerIdByJobAdderAccountDocument, options);
      }
export function useGetEmployerIdByJobAdderAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables>(GetEmployerIdByJobAdderAccountDocument, options);
        }
export function useGetEmployerIdByJobAdderAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables>(GetEmployerIdByJobAdderAccountDocument, options);
        }
export type GetEmployerIdByJobAdderAccountQueryHookResult = ReturnType<typeof useGetEmployerIdByJobAdderAccountQuery>;
export type GetEmployerIdByJobAdderAccountLazyQueryHookResult = ReturnType<typeof useGetEmployerIdByJobAdderAccountLazyQuery>;
export type GetEmployerIdByJobAdderAccountSuspenseQueryHookResult = ReturnType<typeof useGetEmployerIdByJobAdderAccountSuspenseQuery>;
export type GetEmployerIdByJobAdderAccountQueryResult = Apollo.QueryResult<Types.GetEmployerIdByJobAdderAccountQuery, Types.GetEmployerIdByJobAdderAccountQueryVariables>;
export function refetchGetEmployerIdByJobAdderAccountQuery(variables: Types.GetEmployerIdByJobAdderAccountQueryVariables) {
      return { query: GetEmployerIdByJobAdderAccountDocument, variables: variables }
    }
export const UpsertIntegrationsEmployersJobAdderDocument = gql`
    mutation UpsertIntegrationsEmployersJobAdder($integration_fkey: String!, $employer_id: uuid!) {
  insert_integration_employer_one(
    object: {integration_fkey: $integration_fkey, employer_id: $employer_id, integration_type: "jobadder"}
    on_conflict: {constraint: integrations_employers_integration_fkey_key, update_columns: [integration_fkey]}
  ) {
    id
    employer_id
  }
}
    `;
export type UpsertIntegrationsEmployersJobAdderMutationFn = Apollo.MutationFunction<Types.UpsertIntegrationsEmployersJobAdderMutation, Types.UpsertIntegrationsEmployersJobAdderMutationVariables>;

/**
 * __useUpsertIntegrationsEmployersJobAdderMutation__
 *
 * To run a mutation, you first call `useUpsertIntegrationsEmployersJobAdderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertIntegrationsEmployersJobAdderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertIntegrationsEmployersJobAdderMutation, { data, loading, error }] = useUpsertIntegrationsEmployersJobAdderMutation({
 *   variables: {
 *      integration_fkey: // value for 'integration_fkey'
 *      employer_id: // value for 'employer_id'
 *   },
 * });
 */
export function useUpsertIntegrationsEmployersJobAdderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertIntegrationsEmployersJobAdderMutation, Types.UpsertIntegrationsEmployersJobAdderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertIntegrationsEmployersJobAdderMutation, Types.UpsertIntegrationsEmployersJobAdderMutationVariables>(UpsertIntegrationsEmployersJobAdderDocument, options);
      }
export type UpsertIntegrationsEmployersJobAdderMutationHookResult = ReturnType<typeof useUpsertIntegrationsEmployersJobAdderMutation>;
export type UpsertIntegrationsEmployersJobAdderMutationResult = Apollo.MutationResult<Types.UpsertIntegrationsEmployersJobAdderMutation>;
export type UpsertIntegrationsEmployersJobAdderMutationOptions = Apollo.BaseMutationOptions<Types.UpsertIntegrationsEmployersJobAdderMutation, Types.UpsertIntegrationsEmployersJobAdderMutationVariables>;
export const UpsertJobIntegrationDocument = gql`
    mutation UpsertJobIntegration($object: job_insert_input!, $update_columns: [job_update_column!]!) {
  insert_job_one(
    object: $object
    on_conflict: {constraint: job_integration_id_key, update_columns: $update_columns}
  ) {
    id
    title
    slug
    tagline
    about
    job_status
    list_date
    expiry_date
    job_category
    work_type
    highlights
    location {
      label
    }
    integration {
      id
      integration_fkey
      application_url
      job_id
    }
  }
}
    `;
export type UpsertJobIntegrationMutationFn = Apollo.MutationFunction<Types.UpsertJobIntegrationMutation, Types.UpsertJobIntegrationMutationVariables>;

/**
 * __useUpsertJobIntegrationMutation__
 *
 * To run a mutation, you first call `useUpsertJobIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobIntegrationMutation, { data, loading, error }] = useUpsertJobIntegrationMutation({
 *   variables: {
 *      object: // value for 'object'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useUpsertJobIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertJobIntegrationMutation, Types.UpsertJobIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertJobIntegrationMutation, Types.UpsertJobIntegrationMutationVariables>(UpsertJobIntegrationDocument, options);
      }
export type UpsertJobIntegrationMutationHookResult = ReturnType<typeof useUpsertJobIntegrationMutation>;
export type UpsertJobIntegrationMutationResult = Apollo.MutationResult<Types.UpsertJobIntegrationMutation>;
export type UpsertJobIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.UpsertJobIntegrationMutation, Types.UpsertJobIntegrationMutationVariables>;
export const SearchLocalJobsDocument = gql`
    query SearchLocalJobs($keywords: String, $user_region: String, $user_district: String, $user_municipality: String, $user_neighbourhood: String, $where: job_bool_exp = {}, $order_by: [job_order_by!] = {}, $offset: Int = 0, $limit: Int = 10) {
  jobs: search_local_params(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    ...jobWithEmployer
    created_by_user_id
  }
  jobs_aggregate: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: $where
  ) {
    aggregate {
      count
    }
  }
}
    ${JobWithEmployerFragmentDoc}`;

/**
 * __useSearchLocalJobsQuery__
 *
 * To run a query within a React component, call `useSearchLocalJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocalJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocalJobsQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *      user_region: // value for 'user_region'
 *      user_district: // value for 'user_district'
 *      user_municipality: // value for 'user_municipality'
 *      user_neighbourhood: // value for 'user_neighbourhood'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchLocalJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>(SearchLocalJobsDocument, options);
      }
export function useSearchLocalJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>(SearchLocalJobsDocument, options);
        }
export function useSearchLocalJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>(SearchLocalJobsDocument, options);
        }
export type SearchLocalJobsQueryHookResult = ReturnType<typeof useSearchLocalJobsQuery>;
export type SearchLocalJobsLazyQueryHookResult = ReturnType<typeof useSearchLocalJobsLazyQuery>;
export type SearchLocalJobsSuspenseQueryHookResult = ReturnType<typeof useSearchLocalJobsSuspenseQuery>;
export type SearchLocalJobsQueryResult = Apollo.QueryResult<Types.SearchLocalJobsQuery, Types.SearchLocalJobsQueryVariables>;
export function refetchSearchLocalJobsQuery(variables?: Types.SearchLocalJobsQueryVariables) {
      return { query: SearchLocalJobsDocument, variables: variables }
    }
export const SearchJobsServersideDocument = gql`
    query SearchJobsServerside {
  enum_category(order_by: {value: asc}) {
    label
    value
  }
  enum_work_type(order_by: {value: asc}) {
    label
    value
  }
  employer(
    where: {_or: [{jobs: {job_status: {_eq: active, _is_null: false}}}, {children: {jobs: {job_status: {_eq: active, _is_null: false}}}}], managed_by_parent: {_eq: false}}
    order_by: {name: asc}
  ) {
    label: name
    value: slug
  }
}
    `;

/**
 * __useSearchJobsServersideQuery__
 *
 * To run a query within a React component, call `useSearchJobsServersideQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJobsServersideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJobsServersideQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchJobsServersideQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>(SearchJobsServersideDocument, options);
      }
export function useSearchJobsServersideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>(SearchJobsServersideDocument, options);
        }
export function useSearchJobsServersideSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>(SearchJobsServersideDocument, options);
        }
export type SearchJobsServersideQueryHookResult = ReturnType<typeof useSearchJobsServersideQuery>;
export type SearchJobsServersideLazyQueryHookResult = ReturnType<typeof useSearchJobsServersideLazyQuery>;
export type SearchJobsServersideSuspenseQueryHookResult = ReturnType<typeof useSearchJobsServersideSuspenseQuery>;
export type SearchJobsServersideQueryResult = Apollo.QueryResult<Types.SearchJobsServersideQuery, Types.SearchJobsServersideQueryVariables>;
export function refetchSearchJobsServersideQuery(variables?: Types.SearchJobsServersideQueryVariables) {
      return { query: SearchJobsServersideDocument, variables: variables }
    }
export const GetJobBySlugDocument = gql`
    query GetJobBySlug($slug: String!) {
  job(where: {slug: {_eq: $slug}}) {
    ...jobWithEmployer
  }
}
    ${JobWithEmployerFragmentDoc}`;

/**
 * __useGetJobBySlugQuery__
 *
 * To run a query within a React component, call `useGetJobBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetJobBySlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables> & ({ variables: Types.GetJobBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables>(GetJobBySlugDocument, options);
      }
export function useGetJobBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables>(GetJobBySlugDocument, options);
        }
export function useGetJobBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables>(GetJobBySlugDocument, options);
        }
export type GetJobBySlugQueryHookResult = ReturnType<typeof useGetJobBySlugQuery>;
export type GetJobBySlugLazyQueryHookResult = ReturnType<typeof useGetJobBySlugLazyQuery>;
export type GetJobBySlugSuspenseQueryHookResult = ReturnType<typeof useGetJobBySlugSuspenseQuery>;
export type GetJobBySlugQueryResult = Apollo.QueryResult<Types.GetJobBySlugQuery, Types.GetJobBySlugQueryVariables>;
export function refetchGetJobBySlugQuery(variables: Types.GetJobBySlugQueryVariables) {
      return { query: GetJobBySlugDocument, variables: variables }
    }
export const GetJobByPkDocument = gql`
    query GetJobByPk($id: uuid!) {
  job_by_pk(id: $id) {
    ...jobWithoutComputed
    location {
      id
      geometry
      label
      region
      district
      municipality
      neighbourhood
    }
    job_questions(order_by: {order: desc}) {
      id
      question
      type
      job_id
      order
    }
  }
}
    ${JobWithoutComputedFragmentDoc}`;

/**
 * __useGetJobByPkQuery__
 *
 * To run a query within a React component, call `useGetJobByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables> & ({ variables: Types.GetJobByPkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables>(GetJobByPkDocument, options);
      }
export function useGetJobByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables>(GetJobByPkDocument, options);
        }
export function useGetJobByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables>(GetJobByPkDocument, options);
        }
export type GetJobByPkQueryHookResult = ReturnType<typeof useGetJobByPkQuery>;
export type GetJobByPkLazyQueryHookResult = ReturnType<typeof useGetJobByPkLazyQuery>;
export type GetJobByPkSuspenseQueryHookResult = ReturnType<typeof useGetJobByPkSuspenseQuery>;
export type GetJobByPkQueryResult = Apollo.QueryResult<Types.GetJobByPkQuery, Types.GetJobByPkQueryVariables>;
export function refetchGetJobByPkQuery(variables: Types.GetJobByPkQueryVariables) {
      return { query: GetJobByPkDocument, variables: variables }
    }
export const GetJobPosterUserByIdDocument = gql`
    query GetJobPosterUserById($id: uuid) {
  user(where: {id: {_eq: $id}}) {
    first_name
    last_name
    phone
    email
    profile_photo
  }
}
    `;

/**
 * __useGetJobPosterUserByIdQuery__
 *
 * To run a query within a React component, call `useGetJobPosterUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobPosterUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobPosterUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobPosterUserByIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>(GetJobPosterUserByIdDocument, options);
      }
export function useGetJobPosterUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>(GetJobPosterUserByIdDocument, options);
        }
export function useGetJobPosterUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>(GetJobPosterUserByIdDocument, options);
        }
export type GetJobPosterUserByIdQueryHookResult = ReturnType<typeof useGetJobPosterUserByIdQuery>;
export type GetJobPosterUserByIdLazyQueryHookResult = ReturnType<typeof useGetJobPosterUserByIdLazyQuery>;
export type GetJobPosterUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetJobPosterUserByIdSuspenseQuery>;
export type GetJobPosterUserByIdQueryResult = Apollo.QueryResult<Types.GetJobPosterUserByIdQuery, Types.GetJobPosterUserByIdQueryVariables>;
export function refetchGetJobPosterUserByIdQuery(variables?: Types.GetJobPosterUserByIdQueryVariables) {
      return { query: GetJobPosterUserByIdDocument, variables: variables }
    }
export const GetJobWithEmployerByPkDocument = gql`
    query GetJobWithEmployerByPK($id: uuid!) {
  job_by_pk(id: $id) {
    ...jobWithoutComputed
    category {
      label
    }
    additional_categories {
      categoryObject {
        value
        label
      }
    }
    employer {
      logo
      name
      description
      slug
      billing_type
    }
    job_questions(order_by: {order: desc}) {
      id
      question
      type
      job_id
      order
    }
    location {
      id
      geometry
      label
      region
      district
      municipality
      neighbourhood
    }
    listing_type
    pay {
      label
    }
    work {
      label
    }
  }
}
    ${JobWithoutComputedFragmentDoc}`;

/**
 * __useGetJobWithEmployerByPkQuery__
 *
 * To run a query within a React component, call `useGetJobWithEmployerByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobWithEmployerByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobWithEmployerByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobWithEmployerByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables> & ({ variables: Types.GetJobWithEmployerByPkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables>(GetJobWithEmployerByPkDocument, options);
      }
export function useGetJobWithEmployerByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables>(GetJobWithEmployerByPkDocument, options);
        }
export function useGetJobWithEmployerByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables>(GetJobWithEmployerByPkDocument, options);
        }
export type GetJobWithEmployerByPkQueryHookResult = ReturnType<typeof useGetJobWithEmployerByPkQuery>;
export type GetJobWithEmployerByPkLazyQueryHookResult = ReturnType<typeof useGetJobWithEmployerByPkLazyQuery>;
export type GetJobWithEmployerByPkSuspenseQueryHookResult = ReturnType<typeof useGetJobWithEmployerByPkSuspenseQuery>;
export type GetJobWithEmployerByPkQueryResult = Apollo.QueryResult<Types.GetJobWithEmployerByPkQuery, Types.GetJobWithEmployerByPkQueryVariables>;
export function refetchGetJobWithEmployerByPkQuery(variables: Types.GetJobWithEmployerByPkQueryVariables) {
      return { query: GetJobWithEmployerByPkDocument, variables: variables }
    }
export const GetJobsByEmployerDocument = gql`
    query GetJobsByEmployer($employer_id: uuid = "") {
  job(where: {employer_id: {_eq: $employer_id}}) {
    id
    title
    status {
      value
    }
    created_at
    list_date
    location {
      label
    }
    applications_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetJobsByEmployerQuery__
 *
 * To run a query within a React component, call `useGetJobsByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsByEmployerQuery({
 *   variables: {
 *      employer_id: // value for 'employer_id'
 *   },
 * });
 */
export function useGetJobsByEmployerQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>(GetJobsByEmployerDocument, options);
      }
export function useGetJobsByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>(GetJobsByEmployerDocument, options);
        }
export function useGetJobsByEmployerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>(GetJobsByEmployerDocument, options);
        }
export type GetJobsByEmployerQueryHookResult = ReturnType<typeof useGetJobsByEmployerQuery>;
export type GetJobsByEmployerLazyQueryHookResult = ReturnType<typeof useGetJobsByEmployerLazyQuery>;
export type GetJobsByEmployerSuspenseQueryHookResult = ReturnType<typeof useGetJobsByEmployerSuspenseQuery>;
export type GetJobsByEmployerQueryResult = Apollo.QueryResult<Types.GetJobsByEmployerQuery, Types.GetJobsByEmployerQueryVariables>;
export function refetchGetJobsByEmployerQuery(variables?: Types.GetJobsByEmployerQueryVariables) {
      return { query: GetJobsByEmployerDocument, variables: variables }
    }
export const UpdateJobStatusDocument = gql`
    mutation UpdateJobStatus($job_status: enum_job_status_enum = active, $id: uuid!, $updated_at: timestamptz!) {
  update_job_by_pk(
    pk_columns: {id: $id}
    _set: {job_status: $job_status, updated_at: $updated_at}
  ) {
    id
    job_status
    updated_at
  }
}
    `;
export type UpdateJobStatusMutationFn = Apollo.MutationFunction<Types.UpdateJobStatusMutation, Types.UpdateJobStatusMutationVariables>;

/**
 * __useUpdateJobStatusMutation__
 *
 * To run a mutation, you first call `useUpdateJobStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobStatusMutation, { data, loading, error }] = useUpdateJobStatusMutation({
 *   variables: {
 *      job_status: // value for 'job_status'
 *      id: // value for 'id'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useUpdateJobStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobStatusMutation, Types.UpdateJobStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobStatusMutation, Types.UpdateJobStatusMutationVariables>(UpdateJobStatusDocument, options);
      }
export type UpdateJobStatusMutationHookResult = ReturnType<typeof useUpdateJobStatusMutation>;
export type UpdateJobStatusMutationResult = Apollo.MutationResult<Types.UpdateJobStatusMutation>;
export type UpdateJobStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobStatusMutation, Types.UpdateJobStatusMutationVariables>;
export const InsertJobDocument = gql`
    mutation InsertJob($object: job_insert_input!) {
  insert_job_one(
    object: $object
    on_conflict: {constraint: job_pkey, update_columns: [about, listing_type, highlights, requirements, location_id, pay_high, pay_low, pay_type, show_pay, tagline, title, work_type]}
  ) {
    id
  }
}
    `;
export type InsertJobMutationFn = Apollo.MutationFunction<Types.InsertJobMutation, Types.InsertJobMutationVariables>;

/**
 * __useInsertJobMutation__
 *
 * To run a mutation, you first call `useInsertJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertJobMutation, { data, loading, error }] = useInsertJobMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertJobMutation, Types.InsertJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertJobMutation, Types.InsertJobMutationVariables>(InsertJobDocument, options);
      }
export type InsertJobMutationHookResult = ReturnType<typeof useInsertJobMutation>;
export type InsertJobMutationResult = Apollo.MutationResult<Types.InsertJobMutation>;
export type InsertJobMutationOptions = Apollo.BaseMutationOptions<Types.InsertJobMutation, Types.InsertJobMutationVariables>;
export const UpdateJobExpiryDocument = gql`
    mutation UpdateJobExpiry($job_id: uuid!) {
  set_expiry_date(args: {job_id: $job_id}) {
    id
    list_date
    expiry_date
  }
}
    `;
export type UpdateJobExpiryMutationFn = Apollo.MutationFunction<Types.UpdateJobExpiryMutation, Types.UpdateJobExpiryMutationVariables>;

/**
 * __useUpdateJobExpiryMutation__
 *
 * To run a mutation, you first call `useUpdateJobExpiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobExpiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobExpiryMutation, { data, loading, error }] = useUpdateJobExpiryMutation({
 *   variables: {
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useUpdateJobExpiryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobExpiryMutation, Types.UpdateJobExpiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobExpiryMutation, Types.UpdateJobExpiryMutationVariables>(UpdateJobExpiryDocument, options);
      }
export type UpdateJobExpiryMutationHookResult = ReturnType<typeof useUpdateJobExpiryMutation>;
export type UpdateJobExpiryMutationResult = Apollo.MutationResult<Types.UpdateJobExpiryMutation>;
export type UpdateJobExpiryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobExpiryMutation, Types.UpdateJobExpiryMutationVariables>;
export const UpsertJobDocument = gql`
    mutation UpsertJob($object: job_insert_input!, $update_columns: [job_update_column!]!) {
  insert_job_one(
    object: $object
    on_conflict: {constraint: job_pkey, update_columns: $update_columns}
  ) {
    id
  }
}
    `;
export type UpsertJobMutationFn = Apollo.MutationFunction<Types.UpsertJobMutation, Types.UpsertJobMutationVariables>;

/**
 * __useUpsertJobMutation__
 *
 * To run a mutation, you first call `useUpsertJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobMutation, { data, loading, error }] = useUpsertJobMutation({
 *   variables: {
 *      object: // value for 'object'
 *      update_columns: // value for 'update_columns'
 *   },
 * });
 */
export function useUpsertJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertJobMutation, Types.UpsertJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertJobMutation, Types.UpsertJobMutationVariables>(UpsertJobDocument, options);
      }
export type UpsertJobMutationHookResult = ReturnType<typeof useUpsertJobMutation>;
export type UpsertJobMutationResult = Apollo.MutationResult<Types.UpsertJobMutation>;
export type UpsertJobMutationOptions = Apollo.BaseMutationOptions<Types.UpsertJobMutation, Types.UpsertJobMutationVariables>;
export const UpdateAdditionalCategoriesByJobIdDocument = gql`
    mutation UpdateAdditionalCategoriesByJobId($job_id: uuid!, $additional_categories: [job_additional_category_insert_input!] = []) {
  delete_job_additional_category(where: {job_id: {_eq: $job_id}}) {
    affected_rows
  }
  insert_job_additional_category(
    objects: $additional_categories
    on_conflict: {constraint: job_additional_categories_job_id_category_key, update_columns: [category, job_id]}
  ) {
    returning {
      id
      categoryObject {
        label
        value
      }
      job_id
    }
  }
}
    `;
export type UpdateAdditionalCategoriesByJobIdMutationFn = Apollo.MutationFunction<Types.UpdateAdditionalCategoriesByJobIdMutation, Types.UpdateAdditionalCategoriesByJobIdMutationVariables>;

/**
 * __useUpdateAdditionalCategoriesByJobIdMutation__
 *
 * To run a mutation, you first call `useUpdateAdditionalCategoriesByJobIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdditionalCategoriesByJobIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdditionalCategoriesByJobIdMutation, { data, loading, error }] = useUpdateAdditionalCategoriesByJobIdMutation({
 *   variables: {
 *      job_id: // value for 'job_id'
 *      additional_categories: // value for 'additional_categories'
 *   },
 * });
 */
export function useUpdateAdditionalCategoriesByJobIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAdditionalCategoriesByJobIdMutation, Types.UpdateAdditionalCategoriesByJobIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAdditionalCategoriesByJobIdMutation, Types.UpdateAdditionalCategoriesByJobIdMutationVariables>(UpdateAdditionalCategoriesByJobIdDocument, options);
      }
export type UpdateAdditionalCategoriesByJobIdMutationHookResult = ReturnType<typeof useUpdateAdditionalCategoriesByJobIdMutation>;
export type UpdateAdditionalCategoriesByJobIdMutationResult = Apollo.MutationResult<Types.UpdateAdditionalCategoriesByJobIdMutation>;
export type UpdateAdditionalCategoriesByJobIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAdditionalCategoriesByJobIdMutation, Types.UpdateAdditionalCategoriesByJobIdMutationVariables>;
export const UpdateJobByPkDocument = gql`
    mutation UpdateJobByPK($id: uuid!, $_set: job_set_input!) {
  update_job_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...jobWithoutComputed
  }
}
    ${JobWithoutComputedFragmentDoc}`;
export type UpdateJobByPkMutationFn = Apollo.MutationFunction<Types.UpdateJobByPkMutation, Types.UpdateJobByPkMutationVariables>;

/**
 * __useUpdateJobByPkMutation__
 *
 * To run a mutation, you first call `useUpdateJobByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobByPkMutation, { data, loading, error }] = useUpdateJobByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateJobByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobByPkMutation, Types.UpdateJobByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobByPkMutation, Types.UpdateJobByPkMutationVariables>(UpdateJobByPkDocument, options);
      }
export type UpdateJobByPkMutationHookResult = ReturnType<typeof useUpdateJobByPkMutation>;
export type UpdateJobByPkMutationResult = Apollo.MutationResult<Types.UpdateJobByPkMutation>;
export type UpdateJobByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobByPkMutation, Types.UpdateJobByPkMutationVariables>;
export const GetJobQuestionByQuestionDocument = gql`
    query GetJobQuestionByQuestion($question: String) {
  job_question(where: {question: {_ilike: $question}}, distinct_on: question) {
    question
    type
  }
}
    `;

/**
 * __useGetJobQuestionByQuestionQuery__
 *
 * To run a query within a React component, call `useGetJobQuestionByQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuestionByQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuestionByQuestionQuery({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useGetJobQuestionByQuestionQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>(GetJobQuestionByQuestionDocument, options);
      }
export function useGetJobQuestionByQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>(GetJobQuestionByQuestionDocument, options);
        }
export function useGetJobQuestionByQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>(GetJobQuestionByQuestionDocument, options);
        }
export type GetJobQuestionByQuestionQueryHookResult = ReturnType<typeof useGetJobQuestionByQuestionQuery>;
export type GetJobQuestionByQuestionLazyQueryHookResult = ReturnType<typeof useGetJobQuestionByQuestionLazyQuery>;
export type GetJobQuestionByQuestionSuspenseQueryHookResult = ReturnType<typeof useGetJobQuestionByQuestionSuspenseQuery>;
export type GetJobQuestionByQuestionQueryResult = Apollo.QueryResult<Types.GetJobQuestionByQuestionQuery, Types.GetJobQuestionByQuestionQueryVariables>;
export function refetchGetJobQuestionByQuestionQuery(variables?: Types.GetJobQuestionByQuestionQueryVariables) {
      return { query: GetJobQuestionByQuestionDocument, variables: variables }
    }
export const UpdateJobByPkStepOneDocument = gql`
    mutation UpdateJobByPKStepOne($id: uuid!, $employer_id: uuid!, $title: String, $location_id: uuid, $location: location_insert_input!, $job_category: enum_category_enum!, $work_type: enum_work_type_enum!, $pay_low: numeric!, $pay_high: numeric!, $pay_type: enum_pay_type_enum!, $show_pay: Boolean!) {
  update_job_by_pk(
    pk_columns: {id: $id}
    _set: {employer_id: $employer_id, title: $title, location_id: $location_id, job_category: $job_category, work_type: $work_type, pay_low: $pay_low, pay_high: $pay_high, pay_type: $pay_type, show_pay: $show_pay}
  ) {
    id
  }
  insert_location_one(object: $location) {
    id
  }
}
    `;
export type UpdateJobByPkStepOneMutationFn = Apollo.MutationFunction<Types.UpdateJobByPkStepOneMutation, Types.UpdateJobByPkStepOneMutationVariables>;

/**
 * __useUpdateJobByPkStepOneMutation__
 *
 * To run a mutation, you first call `useUpdateJobByPkStepOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobByPkStepOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobByPkStepOneMutation, { data, loading, error }] = useUpdateJobByPkStepOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      employer_id: // value for 'employer_id'
 *      title: // value for 'title'
 *      location_id: // value for 'location_id'
 *      location: // value for 'location'
 *      job_category: // value for 'job_category'
 *      work_type: // value for 'work_type'
 *      pay_low: // value for 'pay_low'
 *      pay_high: // value for 'pay_high'
 *      pay_type: // value for 'pay_type'
 *      show_pay: // value for 'show_pay'
 *   },
 * });
 */
export function useUpdateJobByPkStepOneMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobByPkStepOneMutation, Types.UpdateJobByPkStepOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobByPkStepOneMutation, Types.UpdateJobByPkStepOneMutationVariables>(UpdateJobByPkStepOneDocument, options);
      }
export type UpdateJobByPkStepOneMutationHookResult = ReturnType<typeof useUpdateJobByPkStepOneMutation>;
export type UpdateJobByPkStepOneMutationResult = Apollo.MutationResult<Types.UpdateJobByPkStepOneMutation>;
export type UpdateJobByPkStepOneMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobByPkStepOneMutation, Types.UpdateJobByPkStepOneMutationVariables>;
export const UpdateJobByPkWithQuestionsDocument = gql`
    mutation UpdateJobByPKWithQuestions($id: uuid!, $about: String, $apply_url: String, $tagline: String, $highlights: jsonb, $requirements: jsonb, $job_questions: [job_question_insert_input!]!) {
  update_job_by_pk(
    pk_columns: {id: $id}
    _set: {about: $about, apply_url: $apply_url, tagline: $tagline, highlights: $highlights, requirements: $requirements}
  ) {
    ...jobWithoutComputed
  }
  insert_job_question(
    objects: $job_questions
    on_conflict: {constraint: job_questions_pkey, update_columns: [order, question]}
  ) {
    returning {
      id
      job_id
      question
      type
    }
  }
}
    ${JobWithoutComputedFragmentDoc}`;
export type UpdateJobByPkWithQuestionsMutationFn = Apollo.MutationFunction<Types.UpdateJobByPkWithQuestionsMutation, Types.UpdateJobByPkWithQuestionsMutationVariables>;

/**
 * __useUpdateJobByPkWithQuestionsMutation__
 *
 * To run a mutation, you first call `useUpdateJobByPkWithQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobByPkWithQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobByPkWithQuestionsMutation, { data, loading, error }] = useUpdateJobByPkWithQuestionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      about: // value for 'about'
 *      apply_url: // value for 'apply_url'
 *      tagline: // value for 'tagline'
 *      highlights: // value for 'highlights'
 *      requirements: // value for 'requirements'
 *      job_questions: // value for 'job_questions'
 *   },
 * });
 */
export function useUpdateJobByPkWithQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobByPkWithQuestionsMutation, Types.UpdateJobByPkWithQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobByPkWithQuestionsMutation, Types.UpdateJobByPkWithQuestionsMutationVariables>(UpdateJobByPkWithQuestionsDocument, options);
      }
export type UpdateJobByPkWithQuestionsMutationHookResult = ReturnType<typeof useUpdateJobByPkWithQuestionsMutation>;
export type UpdateJobByPkWithQuestionsMutationResult = Apollo.MutationResult<Types.UpdateJobByPkWithQuestionsMutation>;
export type UpdateJobByPkWithQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobByPkWithQuestionsMutation, Types.UpdateJobByPkWithQuestionsMutationVariables>;
export const GetJobForRelistDocument = gql`
    query GetJobForRelist($jobId: uuid!) {
  job_by_pk(id: $jobId) {
    about
    employer_id
    highlights
    job_category
    job_status
    listing_type
    location_id
    pay_high
    pay_low
    pay_type
    requirements
    show_pay
    slug
    tagline
    title
    work_type
    job_questions {
      order
      question
      type
    }
  }
}
    `;

/**
 * __useGetJobForRelistQuery__
 *
 * To run a query within a React component, call `useGetJobForRelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobForRelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobForRelistQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobForRelistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables> & ({ variables: Types.GetJobForRelistQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables>(GetJobForRelistDocument, options);
      }
export function useGetJobForRelistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables>(GetJobForRelistDocument, options);
        }
export function useGetJobForRelistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables>(GetJobForRelistDocument, options);
        }
export type GetJobForRelistQueryHookResult = ReturnType<typeof useGetJobForRelistQuery>;
export type GetJobForRelistLazyQueryHookResult = ReturnType<typeof useGetJobForRelistLazyQuery>;
export type GetJobForRelistSuspenseQueryHookResult = ReturnType<typeof useGetJobForRelistSuspenseQuery>;
export type GetJobForRelistQueryResult = Apollo.QueryResult<Types.GetJobForRelistQuery, Types.GetJobForRelistQueryVariables>;
export function refetchGetJobForRelistQuery(variables: Types.GetJobForRelistQueryVariables) {
      return { query: GetJobForRelistDocument, variables: variables }
    }
export const DeleteJobByPkDocument = gql`
    mutation DeleteJobByPK($jobId: uuid!, $deleted_at: timestamptz!) {
  update_job_by_pk(pk_columns: {id: $jobId}, _set: {deleted_at: $deleted_at}) {
    id
  }
}
    `;
export type DeleteJobByPkMutationFn = Apollo.MutationFunction<Types.DeleteJobByPkMutation, Types.DeleteJobByPkMutationVariables>;

/**
 * __useDeleteJobByPkMutation__
 *
 * To run a mutation, you first call `useDeleteJobByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobByPkMutation, { data, loading, error }] = useDeleteJobByPkMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useDeleteJobByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteJobByPkMutation, Types.DeleteJobByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteJobByPkMutation, Types.DeleteJobByPkMutationVariables>(DeleteJobByPkDocument, options);
      }
export type DeleteJobByPkMutationHookResult = ReturnType<typeof useDeleteJobByPkMutation>;
export type DeleteJobByPkMutationResult = Apollo.MutationResult<Types.DeleteJobByPkMutation>;
export type DeleteJobByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteJobByPkMutation, Types.DeleteJobByPkMutationVariables>;
export const InsertJobForRelistDocument = gql`
    mutation InsertJobForRelist($object: job_insert_input!) {
  insert_job_one(object: $object) {
    id
  }
}
    `;
export type InsertJobForRelistMutationFn = Apollo.MutationFunction<Types.InsertJobForRelistMutation, Types.InsertJobForRelistMutationVariables>;

/**
 * __useInsertJobForRelistMutation__
 *
 * To run a mutation, you first call `useInsertJobForRelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertJobForRelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertJobForRelistMutation, { data, loading, error }] = useInsertJobForRelistMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertJobForRelistMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertJobForRelistMutation, Types.InsertJobForRelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertJobForRelistMutation, Types.InsertJobForRelistMutationVariables>(InsertJobForRelistDocument, options);
      }
export type InsertJobForRelistMutationHookResult = ReturnType<typeof useInsertJobForRelistMutation>;
export type InsertJobForRelistMutationResult = Apollo.MutationResult<Types.InsertJobForRelistMutation>;
export type InsertJobForRelistMutationOptions = Apollo.BaseMutationOptions<Types.InsertJobForRelistMutation, Types.InsertJobForRelistMutationVariables>;
export const GetJobsForThisUserDocument = gql`
    query GetJobsForThisUser($where: job_bool_exp = {}) {
  job(where: $where) {
    id
    title
    created_at
    employer_id
    expiry_date
    list_date
    listing_type
    integration_job {
      integration {
        label
        url
      }
    }
    status {
      value
      label
    }
    location {
      label
      region
    }
    applications_aggregate {
      aggregate {
        count
      }
    }
    job_views {
      views
    }
    employer {
      name
    }
  }
}
    `;

/**
 * __useGetJobsForThisUserQuery__
 *
 * To run a query within a React component, call `useGetJobsForThisUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsForThisUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsForThisUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetJobsForThisUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>(GetJobsForThisUserDocument, options);
      }
export function useGetJobsForThisUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>(GetJobsForThisUserDocument, options);
        }
export function useGetJobsForThisUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>(GetJobsForThisUserDocument, options);
        }
export type GetJobsForThisUserQueryHookResult = ReturnType<typeof useGetJobsForThisUserQuery>;
export type GetJobsForThisUserLazyQueryHookResult = ReturnType<typeof useGetJobsForThisUserLazyQuery>;
export type GetJobsForThisUserSuspenseQueryHookResult = ReturnType<typeof useGetJobsForThisUserSuspenseQuery>;
export type GetJobsForThisUserQueryResult = Apollo.QueryResult<Types.GetJobsForThisUserQuery, Types.GetJobsForThisUserQueryVariables>;
export function refetchGetJobsForThisUserQuery(variables?: Types.GetJobsForThisUserQueryVariables) {
      return { query: GetJobsForThisUserDocument, variables: variables }
    }
export const GetJobApplicationsByEmployerIdDocument = gql`
    query GetJobApplicationsByEmployerId($employer_id: uuid) {
  application(where: {job: {employer_id: {_eq: $employer_id}}}) {
    id
    created_at
    application_status {
      label
      value
    }
    job_id
    answers
    job {
      id
      title
      slug
      employer_id
      job_views {
        views
      }
      location {
        label
        region
      }
    }
    user {
      user_experiences_aggregate {
        aggregate {
          count
        }
      }
      user_licences_aggregate {
        aggregate {
          count
        }
      }
    }
    user {
      email
      user_experiences {
        ...userExperience
      }
      user_educations {
        ...userEducation
      }
      user_licences {
        ...userLicence
      }
      first_name
      last_name
    }
  }
}
    ${UserExperienceFragmentDoc}
${UserEducationFragmentDoc}
${UserLicenceFragmentDoc}`;

/**
 * __useGetJobApplicationsByEmployerIdQuery__
 *
 * To run a query within a React component, call `useGetJobApplicationsByEmployerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobApplicationsByEmployerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobApplicationsByEmployerIdQuery({
 *   variables: {
 *      employer_id: // value for 'employer_id'
 *   },
 * });
 */
export function useGetJobApplicationsByEmployerIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>(GetJobApplicationsByEmployerIdDocument, options);
      }
export function useGetJobApplicationsByEmployerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>(GetJobApplicationsByEmployerIdDocument, options);
        }
export function useGetJobApplicationsByEmployerIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>(GetJobApplicationsByEmployerIdDocument, options);
        }
export type GetJobApplicationsByEmployerIdQueryHookResult = ReturnType<typeof useGetJobApplicationsByEmployerIdQuery>;
export type GetJobApplicationsByEmployerIdLazyQueryHookResult = ReturnType<typeof useGetJobApplicationsByEmployerIdLazyQuery>;
export type GetJobApplicationsByEmployerIdSuspenseQueryHookResult = ReturnType<typeof useGetJobApplicationsByEmployerIdSuspenseQuery>;
export type GetJobApplicationsByEmployerIdQueryResult = Apollo.QueryResult<Types.GetJobApplicationsByEmployerIdQuery, Types.GetJobApplicationsByEmployerIdQueryVariables>;
export function refetchGetJobApplicationsByEmployerIdQuery(variables?: Types.GetJobApplicationsByEmployerIdQueryVariables) {
      return { query: GetJobApplicationsByEmployerIdDocument, variables: variables }
    }
export const GetOgJobByIdDocument = gql`
    query GetOgJobById($id: uuid!) {
  job_by_pk(id: $id) {
    id
    title
    category {
      value
      label
    }
    employer {
      logo
      name
    }
    location {
      id
      municipality
      region
      district
      neighbourhood
    }
  }
}
    `;

/**
 * __useGetOgJobByIdQuery__
 *
 * To run a query within a React component, call `useGetOgJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOgJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOgJobByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOgJobByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables> & ({ variables: Types.GetOgJobByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables>(GetOgJobByIdDocument, options);
      }
export function useGetOgJobByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables>(GetOgJobByIdDocument, options);
        }
export function useGetOgJobByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables>(GetOgJobByIdDocument, options);
        }
export type GetOgJobByIdQueryHookResult = ReturnType<typeof useGetOgJobByIdQuery>;
export type GetOgJobByIdLazyQueryHookResult = ReturnType<typeof useGetOgJobByIdLazyQuery>;
export type GetOgJobByIdSuspenseQueryHookResult = ReturnType<typeof useGetOgJobByIdSuspenseQuery>;
export type GetOgJobByIdQueryResult = Apollo.QueryResult<Types.GetOgJobByIdQuery, Types.GetOgJobByIdQueryVariables>;
export function refetchGetOgJobByIdQuery(variables: Types.GetOgJobByIdQueryVariables) {
      return { query: GetOgJobByIdDocument, variables: variables }
    }
export const SimilarJobsDocument = gql`
    query SimilarJobs($category: String, $slug: String) {
  job_match: job(
    limit: 5
    where: {category: {value: {_eq: $category}}, status: {value: {_eq: "active"}}, listing_type: {_neq: expression_of_interest}, slug: {_neq: $slug}}
    order_by: {created_at: desc, listing_type: asc}
  ) {
    ...jobWithEmployer
    work_type
    list_date
    slug
    tagline
    created_by_user_id
    w: work {
      label
      value
    }
    work_type_computed {
      label
      value
    }
    category {
      value
      label
    }
    employer {
      name
      logo
    }
    location {
      label
      region
      district
      municipality
      neighbourhood
    }
  }
  latest_jobs: job(
    limit: 5
    where: {status: {value: {_eq: "active"}}, listing_type: {_neq: expression_of_interest}}
    order_by: {created_at: desc, listing_type: asc}
  ) {
    ...jobWithEmployer
    work_type
    list_date
    slug
    tagline
    created_by_user_id
    w: work {
      label
      value
    }
    category {
      value
      label
    }
    employer {
      name
      logo
    }
    location {
      label
      region
      district
      municipality
      neighbourhood
    }
  }
}
    ${JobWithEmployerFragmentDoc}`;

/**
 * __useSimilarJobsQuery__
 *
 * To run a query within a React component, call `useSimilarJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarJobsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSimilarJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>(SimilarJobsDocument, options);
      }
export function useSimilarJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>(SimilarJobsDocument, options);
        }
export function useSimilarJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>(SimilarJobsDocument, options);
        }
export type SimilarJobsQueryHookResult = ReturnType<typeof useSimilarJobsQuery>;
export type SimilarJobsLazyQueryHookResult = ReturnType<typeof useSimilarJobsLazyQuery>;
export type SimilarJobsSuspenseQueryHookResult = ReturnType<typeof useSimilarJobsSuspenseQuery>;
export type SimilarJobsQueryResult = Apollo.QueryResult<Types.SimilarJobsQuery, Types.SimilarJobsQueryVariables>;
export function refetchSimilarJobsQuery(variables?: Types.SimilarJobsQueryVariables) {
      return { query: SimilarJobsDocument, variables: variables }
    }
export const RandomEoiDocument = gql`
    query RandomEOI($category: String = "") {
  random_eoi(args: {eoi_category: $category}) {
    id
    title
    about
    metadata
    slug
    employer {
      name
      logo
    }
  }
}
    `;

/**
 * __useRandomEoiQuery__
 *
 * To run a query within a React component, call `useRandomEoiQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomEoiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomEoiQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useRandomEoiQuery(baseOptions?: Apollo.QueryHookOptions<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>(RandomEoiDocument, options);
      }
export function useRandomEoiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>(RandomEoiDocument, options);
        }
export function useRandomEoiSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>(RandomEoiDocument, options);
        }
export type RandomEoiQueryHookResult = ReturnType<typeof useRandomEoiQuery>;
export type RandomEoiLazyQueryHookResult = ReturnType<typeof useRandomEoiLazyQuery>;
export type RandomEoiSuspenseQueryHookResult = ReturnType<typeof useRandomEoiSuspenseQuery>;
export type RandomEoiQueryResult = Apollo.QueryResult<Types.RandomEoiQuery, Types.RandomEoiQueryVariables>;
export function refetchRandomEoiQuery(variables?: Types.RandomEoiQueryVariables) {
      return { query: RandomEoiDocument, variables: variables }
    }
export const CountSearchLocalJobsDocument = gql`
    query CountSearchLocalJobs($keywords: String, $user_region: String, $user_district: String, $user_municipality: String, $user_neighbourhood: String, $where: job_bool_exp = {}) {
  agriculture: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: agriculture}}, {additional_categories: {category: {_eq: agriculture}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  automotive: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: automotive}}, {additional_categories: {category: {_eq: automotive}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  civil: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: civil}}, {additional_categories: {category: {_eq: civil}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  construction: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: construction}}, {additional_categories: {category: {_eq: construction}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  electrical: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: electrical}}, {additional_categories: {category: {_eq: electrical}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  engineering: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: engineering}}, {additional_categories: {category: {_eq: engineering}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  entry: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: entry}}, {additional_categories: {category: {_eq: entry}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  fabrication: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: fabrication}}, {additional_categories: {category: {_eq: fabrication}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  facilities: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: facilities}}, {additional_categories: {category: {_eq: facilities}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  joinery: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: joinery}}, {additional_categories: {category: {_eq: joinery}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  landscaping: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: landscaping}}, {additional_categories: {category: {_eq: landscaping}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  management: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: management}}, {additional_categories: {category: {_eq: management}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  manufacturing: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: manufacturing}}, {additional_categories: {category: {_eq: manufacturing}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  marine: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: marine}}, {additional_categories: {category: {_eq: marine}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  mining: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: mining}}, {additional_categories: {category: {_eq: mining}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  networking: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: networking}}, {additional_categories: {category: {_eq: networking}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  other: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: other}}, {additional_categories: {category: {_eq: other}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  painting: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: painting}}, {additional_categories: {category: {_eq: painting}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  plumbing: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: plumbing}}, {additional_categories: {category: {_eq: plumbing}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  roading: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: roading}}, {additional_categories: {category: {_eq: roading}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  roofing: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: roofing}}, {additional_categories: {category: {_eq: roofing}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  supply: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: supply}}, {additional_categories: {category: {_eq: supply}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  support: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: support}}, {additional_categories: {category: {_eq: support}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  warehousing: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: warehousing}}, {additional_categories: {category: {_eq: warehousing}}}]}]}
  ) {
    aggregate {
      count
    }
  }
  water: search_local_params_aggregate(
    args: {search: $keywords, user_region: $user_region, user_district: $user_district, user_municipality: $user_municipality, user_neighbourhood: $user_neighbourhood}
    where: {_and: [$where, {_or: [{job_category: {_eq: water}}, {additional_categories: {category: {_eq: water}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountSearchLocalJobsQuery__
 *
 * To run a query within a React component, call `useCountSearchLocalJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountSearchLocalJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSearchLocalJobsQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *      user_region: // value for 'user_region'
 *      user_district: // value for 'user_district'
 *      user_municipality: // value for 'user_municipality'
 *      user_neighbourhood: // value for 'user_neighbourhood'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountSearchLocalJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>(CountSearchLocalJobsDocument, options);
      }
export function useCountSearchLocalJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>(CountSearchLocalJobsDocument, options);
        }
export function useCountSearchLocalJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>(CountSearchLocalJobsDocument, options);
        }
export type CountSearchLocalJobsQueryHookResult = ReturnType<typeof useCountSearchLocalJobsQuery>;
export type CountSearchLocalJobsLazyQueryHookResult = ReturnType<typeof useCountSearchLocalJobsLazyQuery>;
export type CountSearchLocalJobsSuspenseQueryHookResult = ReturnType<typeof useCountSearchLocalJobsSuspenseQuery>;
export type CountSearchLocalJobsQueryResult = Apollo.QueryResult<Types.CountSearchLocalJobsQuery, Types.CountSearchLocalJobsQueryVariables>;
export function refetchCountSearchLocalJobsQuery(variables?: Types.CountSearchLocalJobsQueryVariables) {
      return { query: CountSearchLocalJobsDocument, variables: variables }
    }
export const GetAllRegionsDocument = gql`
    query GetAllRegions {
  location(distinct_on: region) {
    region
  }
}
    `;

/**
 * __useGetAllRegionsQuery__
 *
 * To run a query within a React component, call `useGetAllRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>(GetAllRegionsDocument, options);
      }
export function useGetAllRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>(GetAllRegionsDocument, options);
        }
export function useGetAllRegionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>(GetAllRegionsDocument, options);
        }
export type GetAllRegionsQueryHookResult = ReturnType<typeof useGetAllRegionsQuery>;
export type GetAllRegionsLazyQueryHookResult = ReturnType<typeof useGetAllRegionsLazyQuery>;
export type GetAllRegionsSuspenseQueryHookResult = ReturnType<typeof useGetAllRegionsSuspenseQuery>;
export type GetAllRegionsQueryResult = Apollo.QueryResult<Types.GetAllRegionsQuery, Types.GetAllRegionsQueryVariables>;
export function refetchGetAllRegionsQuery(variables?: Types.GetAllRegionsQueryVariables) {
      return { query: GetAllRegionsDocument, variables: variables }
    }
export const SearchLocationDocument = gql`
    query SearchLocation($search: String!) {
  search_location(args: {search: $search}) {
    label
    id
  }
}
    `;

/**
 * __useSearchLocationQuery__
 *
 * To run a query within a React component, call `useSearchLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocationQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchLocationQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchLocationQuery, Types.SearchLocationQueryVariables> & ({ variables: Types.SearchLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>(SearchLocationDocument, options);
      }
export function useSearchLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>(SearchLocationDocument, options);
        }
export function useSearchLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>(SearchLocationDocument, options);
        }
export type SearchLocationQueryHookResult = ReturnType<typeof useSearchLocationQuery>;
export type SearchLocationLazyQueryHookResult = ReturnType<typeof useSearchLocationLazyQuery>;
export type SearchLocationSuspenseQueryHookResult = ReturnType<typeof useSearchLocationSuspenseQuery>;
export type SearchLocationQueryResult = Apollo.QueryResult<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>;
export function refetchSearchLocationQuery(variables: Types.SearchLocationQueryVariables) {
      return { query: SearchLocationDocument, variables: variables }
    }
export const GetLocationsByLabelDocument = gql`
    query GetLocationsByLabel($label: String = "") {
  location(where: {label: {_eq: $label}}) {
    id
    label
  }
}
    `;

/**
 * __useGetLocationsByLabelQuery__
 *
 * To run a query within a React component, call `useGetLocationsByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsByLabelQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useGetLocationsByLabelQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>(GetLocationsByLabelDocument, options);
      }
export function useGetLocationsByLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>(GetLocationsByLabelDocument, options);
        }
export function useGetLocationsByLabelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>(GetLocationsByLabelDocument, options);
        }
export type GetLocationsByLabelQueryHookResult = ReturnType<typeof useGetLocationsByLabelQuery>;
export type GetLocationsByLabelLazyQueryHookResult = ReturnType<typeof useGetLocationsByLabelLazyQuery>;
export type GetLocationsByLabelSuspenseQueryHookResult = ReturnType<typeof useGetLocationsByLabelSuspenseQuery>;
export type GetLocationsByLabelQueryResult = Apollo.QueryResult<Types.GetLocationsByLabelQuery, Types.GetLocationsByLabelQueryVariables>;
export function refetchGetLocationsByLabelQuery(variables?: Types.GetLocationsByLabelQueryVariables) {
      return { query: GetLocationsByLabelDocument, variables: variables }
    }
export const UpsertLocationDocument = gql`
    mutation UpsertLocation($object: location_insert_input = {}) {
  insert_location_one(
    object: $object
    on_conflict: {constraint: location_label_key, update_columns: country}
  ) {
    ...location
  }
}
    ${LocationFragmentDoc}`;
export type UpsertLocationMutationFn = Apollo.MutationFunction<Types.UpsertLocationMutation, Types.UpsertLocationMutationVariables>;

/**
 * __useUpsertLocationMutation__
 *
 * To run a mutation, you first call `useUpsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationMutation, { data, loading, error }] = useUpsertLocationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertLocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertLocationMutation, Types.UpsertLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertLocationMutation, Types.UpsertLocationMutationVariables>(UpsertLocationDocument, options);
      }
export type UpsertLocationMutationHookResult = ReturnType<typeof useUpsertLocationMutation>;
export type UpsertLocationMutationResult = Apollo.MutationResult<Types.UpsertLocationMutation>;
export type UpsertLocationMutationOptions = Apollo.BaseMutationOptions<Types.UpsertLocationMutation, Types.UpsertLocationMutationVariables>;
export const GetCvByIdDocument = gql`
    query GetCvById($id: uuid!) {
  user_by_pk(id: $id) {
    first_name
    last_name
    email
    phone
    about
    user_experiences {
      id
      title
      company
      description
      start_date
      end_date
    }
    user_educations {
      id
      title
      institution
      issue_date
    }
    user_licences {
      id
      title
      organisation
      expires
      issue_date
      expiry_date
    }
  }
}
    `;

/**
 * __useGetCvByIdQuery__
 *
 * To run a query within a React component, call `useGetCvByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCvByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCvByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCvByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables> & ({ variables: Types.GetCvByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables>(GetCvByIdDocument, options);
      }
export function useGetCvByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables>(GetCvByIdDocument, options);
        }
export function useGetCvByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables>(GetCvByIdDocument, options);
        }
export type GetCvByIdQueryHookResult = ReturnType<typeof useGetCvByIdQuery>;
export type GetCvByIdLazyQueryHookResult = ReturnType<typeof useGetCvByIdLazyQuery>;
export type GetCvByIdSuspenseQueryHookResult = ReturnType<typeof useGetCvByIdSuspenseQuery>;
export type GetCvByIdQueryResult = Apollo.QueryResult<Types.GetCvByIdQuery, Types.GetCvByIdQueryVariables>;
export function refetchGetCvByIdQuery(variables: Types.GetCvByIdQueryVariables) {
      return { query: GetCvByIdDocument, variables: variables }
    }
export const IncrementJobViewsDocument = gql`
    mutation IncrementJobViews($job_id: uuid!) {
  update_job_views(where: {job_id: {_eq: $job_id}}, _inc: {views: 1}) {
    returning {
      job_id
    }
  }
}
    `;
export type IncrementJobViewsMutationFn = Apollo.MutationFunction<Types.IncrementJobViewsMutation, Types.IncrementJobViewsMutationVariables>;

/**
 * __useIncrementJobViewsMutation__
 *
 * To run a mutation, you first call `useIncrementJobViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementJobViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementJobViewsMutation, { data, loading, error }] = useIncrementJobViewsMutation({
 *   variables: {
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useIncrementJobViewsMutation(baseOptions?: Apollo.MutationHookOptions<Types.IncrementJobViewsMutation, Types.IncrementJobViewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.IncrementJobViewsMutation, Types.IncrementJobViewsMutationVariables>(IncrementJobViewsDocument, options);
      }
export type IncrementJobViewsMutationHookResult = ReturnType<typeof useIncrementJobViewsMutation>;
export type IncrementJobViewsMutationResult = Apollo.MutationResult<Types.IncrementJobViewsMutation>;
export type IncrementJobViewsMutationOptions = Apollo.BaseMutationOptions<Types.IncrementJobViewsMutation, Types.IncrementJobViewsMutationVariables>;
export const GetUserCvByIdDocument = gql`
    query GetUserCVByID($id: uuid!) {
  user_by_pk(id: $id) {
    first_name
    last_name
    email
    phone
    profile_photo
    about
    user_experiences(
      where: {deleted_at: {_is_null: true}}
      order_by: {start_date: desc, end_date: desc_nulls_first}
    ) {
      id
      title
      company
      description
      start_date
      end_date
      current
      deleted_at
    }
    user_educations(
      where: {deleted_at: {_is_null: true}}
      order_by: {issue_date: desc_nulls_first}
    ) {
      id
      title
      institution
      issue_date
      complete
      deleted_at
    }
    user_licences(
      where: {deleted_at: {_is_null: true}}
      order_by: {issue_date: desc_nulls_first, expiry_date: desc}
    ) {
      id
      title
      organisation
      expires
      issue_date
      expiry_date
      deleted_at
    }
  }
}
    `;

/**
 * __useGetUserCvByIdQuery__
 *
 * To run a query within a React component, call `useGetUserCvByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCvByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCvByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserCvByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables> & ({ variables: Types.GetUserCvByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables>(GetUserCvByIdDocument, options);
      }
export function useGetUserCvByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables>(GetUserCvByIdDocument, options);
        }
export function useGetUserCvByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables>(GetUserCvByIdDocument, options);
        }
export type GetUserCvByIdQueryHookResult = ReturnType<typeof useGetUserCvByIdQuery>;
export type GetUserCvByIdLazyQueryHookResult = ReturnType<typeof useGetUserCvByIdLazyQuery>;
export type GetUserCvByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserCvByIdSuspenseQuery>;
export type GetUserCvByIdQueryResult = Apollo.QueryResult<Types.GetUserCvByIdQuery, Types.GetUserCvByIdQueryVariables>;
export function refetchGetUserCvByIdQuery(variables: Types.GetUserCvByIdQueryVariables) {
      return { query: GetUserCvByIdDocument, variables: variables }
    }
export const GetJobSummaryForEmployerByDateDocument = gql`
    query GetJobSummaryForEmployerByDate($employer_id: uuid!, $start_date: date, $end_date: date) {
  employer_by_pk(id: $employer_id) {
    name
    integration_employers_aggregate {
      aggregate {
        count
      }
    }
    parent {
      integration_employers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  job(
    where: {employer_id: {_eq: $employer_id}, list_date: {_gte: $start_date, _lte: $end_date}, expiry_date: {_is_null: false}}
  ) {
    id
    title
    list_date
    expiry_date
    listing_type
    integration_job {
      integration {
        label
      }
    }
    applications_aggregate(
      where: {status: {_in: [applied, withdrawn, shortlisted, rejected]}}
    ) {
      aggregate {
        count
      }
    }
    job_views {
      views
    }
  }
}
    `;

/**
 * __useGetJobSummaryForEmployerByDateQuery__
 *
 * To run a query within a React component, call `useGetJobSummaryForEmployerByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobSummaryForEmployerByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobSummaryForEmployerByDateQuery({
 *   variables: {
 *      employer_id: // value for 'employer_id'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useGetJobSummaryForEmployerByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables> & ({ variables: Types.GetJobSummaryForEmployerByDateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables>(GetJobSummaryForEmployerByDateDocument, options);
      }
export function useGetJobSummaryForEmployerByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables>(GetJobSummaryForEmployerByDateDocument, options);
        }
export function useGetJobSummaryForEmployerByDateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables>(GetJobSummaryForEmployerByDateDocument, options);
        }
export type GetJobSummaryForEmployerByDateQueryHookResult = ReturnType<typeof useGetJobSummaryForEmployerByDateQuery>;
export type GetJobSummaryForEmployerByDateLazyQueryHookResult = ReturnType<typeof useGetJobSummaryForEmployerByDateLazyQuery>;
export type GetJobSummaryForEmployerByDateSuspenseQueryHookResult = ReturnType<typeof useGetJobSummaryForEmployerByDateSuspenseQuery>;
export type GetJobSummaryForEmployerByDateQueryResult = Apollo.QueryResult<Types.GetJobSummaryForEmployerByDateQuery, Types.GetJobSummaryForEmployerByDateQueryVariables>;
export function refetchGetJobSummaryForEmployerByDateQuery(variables: Types.GetJobSummaryForEmployerByDateQueryVariables) {
      return { query: GetJobSummaryForEmployerByDateDocument, variables: variables }
    }
export const UserSearchDocument = gql`
    query UserSearch {
  user_search(
    limit: 5
    order_by: {created_at: desc}
    where: {deleted_at: {_is_null: true}, _or: [{search: {_is_null: false}, category: {_is_null: false}}, {search: {_is_null: false}, category: {_neq: ""}}, {search: {_neq: ""}, category: {_is_null: false}}, {search: {_neq: ""}, category: {_neq: ""}}]}
  ) {
    search
    category
    employer_slug
    location
    job_type
    from
    to
  }
}
    `;

/**
 * __useUserSearchQuery__
 *
 * To run a query within a React component, call `useUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.UserSearchQuery, Types.UserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserSearchQuery, Types.UserSearchQueryVariables>(UserSearchDocument, options);
      }
export function useUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserSearchQuery, Types.UserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserSearchQuery, Types.UserSearchQueryVariables>(UserSearchDocument, options);
        }
export function useUserSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UserSearchQuery, Types.UserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UserSearchQuery, Types.UserSearchQueryVariables>(UserSearchDocument, options);
        }
export type UserSearchQueryHookResult = ReturnType<typeof useUserSearchQuery>;
export type UserSearchLazyQueryHookResult = ReturnType<typeof useUserSearchLazyQuery>;
export type UserSearchSuspenseQueryHookResult = ReturnType<typeof useUserSearchSuspenseQuery>;
export type UserSearchQueryResult = Apollo.QueryResult<Types.UserSearchQuery, Types.UserSearchQueryVariables>;
export function refetchUserSearchQuery(variables?: Types.UserSearchQueryVariables) {
      return { query: UserSearchDocument, variables: variables }
    }
export const UnauthUserSearchDocument = gql`
    query UnauthUserSearch($local_id: uuid!) {
  user_search: unauth_user_search(args: {local_id: $local_id}) {
    unauth_id
    search
    category
    employer_slug
    location
    job_type
    from
    to
  }
}
    `;

/**
 * __useUnauthUserSearchQuery__
 *
 * To run a query within a React component, call `useUnauthUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnauthUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnauthUserSearchQuery({
 *   variables: {
 *      local_id: // value for 'local_id'
 *   },
 * });
 */
export function useUnauthUserSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables> & ({ variables: Types.UnauthUserSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables>(UnauthUserSearchDocument, options);
      }
export function useUnauthUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables>(UnauthUserSearchDocument, options);
        }
export function useUnauthUserSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables>(UnauthUserSearchDocument, options);
        }
export type UnauthUserSearchQueryHookResult = ReturnType<typeof useUnauthUserSearchQuery>;
export type UnauthUserSearchLazyQueryHookResult = ReturnType<typeof useUnauthUserSearchLazyQuery>;
export type UnauthUserSearchSuspenseQueryHookResult = ReturnType<typeof useUnauthUserSearchSuspenseQuery>;
export type UnauthUserSearchQueryResult = Apollo.QueryResult<Types.UnauthUserSearchQuery, Types.UnauthUserSearchQueryVariables>;
export function refetchUnauthUserSearchQuery(variables: Types.UnauthUserSearchQueryVariables) {
      return { query: UnauthUserSearchDocument, variables: variables }
    }
export const CreateUserSearchDocument = gql`
    mutation CreateUserSearch($data: user_search_insert_input!) {
  insert_user_search_one(object: $data) {
    id
    search
    category
    employer_slug
    location
    job_type
    from
    to
    created_at
    created_by_user_id
  }
}
    `;
export type CreateUserSearchMutationFn = Apollo.MutationFunction<Types.CreateUserSearchMutation, Types.CreateUserSearchMutationVariables>;

/**
 * __useCreateUserSearchMutation__
 *
 * To run a mutation, you first call `useCreateUserSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSearchMutation, { data, loading, error }] = useCreateUserSearchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSearchMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserSearchMutation, Types.CreateUserSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserSearchMutation, Types.CreateUserSearchMutationVariables>(CreateUserSearchDocument, options);
      }
export type CreateUserSearchMutationHookResult = ReturnType<typeof useCreateUserSearchMutation>;
export type CreateUserSearchMutationResult = Apollo.MutationResult<Types.CreateUserSearchMutation>;
export type CreateUserSearchMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserSearchMutation, Types.CreateUserSearchMutationVariables>;
export const UpdateUnauthSearchUserDocument = gql`
    mutation UpdateUnauthSearchUser($user_id: uuid!, $local_id: uuid) {
  update_user_search_created_by(args: {user_id: $user_id, local_id: $local_id}) {
    id
    search
    category
    employer_slug
    location
    job_type
    from
    to
    created_at
    created_by_user_id
  }
}
    `;
export type UpdateUnauthSearchUserMutationFn = Apollo.MutationFunction<Types.UpdateUnauthSearchUserMutation, Types.UpdateUnauthSearchUserMutationVariables>;

/**
 * __useUpdateUnauthSearchUserMutation__
 *
 * To run a mutation, you first call `useUpdateUnauthSearchUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnauthSearchUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnauthSearchUserMutation, { data, loading, error }] = useUpdateUnauthSearchUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      local_id: // value for 'local_id'
 *   },
 * });
 */
export function useUpdateUnauthSearchUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUnauthSearchUserMutation, Types.UpdateUnauthSearchUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUnauthSearchUserMutation, Types.UpdateUnauthSearchUserMutationVariables>(UpdateUnauthSearchUserDocument, options);
      }
export type UpdateUnauthSearchUserMutationHookResult = ReturnType<typeof useUpdateUnauthSearchUserMutation>;
export type UpdateUnauthSearchUserMutationResult = Apollo.MutationResult<Types.UpdateUnauthSearchUserMutation>;
export type UpdateUnauthSearchUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUnauthSearchUserMutation, Types.UpdateUnauthSearchUserMutationVariables>;
export const InsertIntegrationSnaphireDocument = gql`
    mutation InsertIntegrationSnaphire($integration_fkey: String!) {
  insert_integration_employer(
    objects: {integration_fkey: $integration_fkey, integration_type: "snaphire"}
  ) {
    affected_rows
  }
}
    `;
export type InsertIntegrationSnaphireMutationFn = Apollo.MutationFunction<Types.InsertIntegrationSnaphireMutation, Types.InsertIntegrationSnaphireMutationVariables>;

/**
 * __useInsertIntegrationSnaphireMutation__
 *
 * To run a mutation, you first call `useInsertIntegrationSnaphireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIntegrationSnaphireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIntegrationSnaphireMutation, { data, loading, error }] = useInsertIntegrationSnaphireMutation({
 *   variables: {
 *      integration_fkey: // value for 'integration_fkey'
 *   },
 * });
 */
export function useInsertIntegrationSnaphireMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertIntegrationSnaphireMutation, Types.InsertIntegrationSnaphireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertIntegrationSnaphireMutation, Types.InsertIntegrationSnaphireMutationVariables>(InsertIntegrationSnaphireDocument, options);
      }
export type InsertIntegrationSnaphireMutationHookResult = ReturnType<typeof useInsertIntegrationSnaphireMutation>;
export type InsertIntegrationSnaphireMutationResult = Apollo.MutationResult<Types.InsertIntegrationSnaphireMutation>;
export type InsertIntegrationSnaphireMutationOptions = Apollo.BaseMutationOptions<Types.InsertIntegrationSnaphireMutation, Types.InsertIntegrationSnaphireMutationVariables>;
export const FetchSnaphireEmployerIdDocument = gql`
    query FetchSnaphireEmployerId($integration_fkey: String!) {
  integration_employer(where: {integration_fkey: {_eq: $integration_fkey}}) {
    employer_id
  }
}
    `;

/**
 * __useFetchSnaphireEmployerIdQuery__
 *
 * To run a query within a React component, call `useFetchSnaphireEmployerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSnaphireEmployerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSnaphireEmployerIdQuery({
 *   variables: {
 *      integration_fkey: // value for 'integration_fkey'
 *   },
 * });
 */
export function useFetchSnaphireEmployerIdQuery(baseOptions: Apollo.QueryHookOptions<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables> & ({ variables: Types.FetchSnaphireEmployerIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables>(FetchSnaphireEmployerIdDocument, options);
      }
export function useFetchSnaphireEmployerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables>(FetchSnaphireEmployerIdDocument, options);
        }
export function useFetchSnaphireEmployerIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables>(FetchSnaphireEmployerIdDocument, options);
        }
export type FetchSnaphireEmployerIdQueryHookResult = ReturnType<typeof useFetchSnaphireEmployerIdQuery>;
export type FetchSnaphireEmployerIdLazyQueryHookResult = ReturnType<typeof useFetchSnaphireEmployerIdLazyQuery>;
export type FetchSnaphireEmployerIdSuspenseQueryHookResult = ReturnType<typeof useFetchSnaphireEmployerIdSuspenseQuery>;
export type FetchSnaphireEmployerIdQueryResult = Apollo.QueryResult<Types.FetchSnaphireEmployerIdQuery, Types.FetchSnaphireEmployerIdQueryVariables>;
export function refetchFetchSnaphireEmployerIdQuery(variables: Types.FetchSnaphireEmployerIdQueryVariables) {
      return { query: FetchSnaphireEmployerIdDocument, variables: variables }
    }
export const GetJobCategoriesDocument = gql`
    query GetJobCategories {
  enum_category(order_by: {label: asc}) {
    label
    value
  }
  enum_pay_type(order_by: {label: asc}) {
    label
    value
  }
  enum_work_type(order_by: {label: asc}) {
    label
    value
  }
}
    `;

/**
 * __useGetJobCategoriesQuery__
 *
 * To run a query within a React component, call `useGetJobCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>(GetJobCategoriesDocument, options);
      }
export function useGetJobCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>(GetJobCategoriesDocument, options);
        }
export function useGetJobCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>(GetJobCategoriesDocument, options);
        }
export type GetJobCategoriesQueryHookResult = ReturnType<typeof useGetJobCategoriesQuery>;
export type GetJobCategoriesLazyQueryHookResult = ReturnType<typeof useGetJobCategoriesLazyQuery>;
export type GetJobCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetJobCategoriesSuspenseQuery>;
export type GetJobCategoriesQueryResult = Apollo.QueryResult<Types.GetJobCategoriesQuery, Types.GetJobCategoriesQueryVariables>;
export function refetchGetJobCategoriesQuery(variables?: Types.GetJobCategoriesQueryVariables) {
      return { query: GetJobCategoriesDocument, variables: variables }
    }
export const InsertExpireJobsDocument = gql`
    mutation InsertExpireJobs($job_id: uuid!, $expiry: date!, $job_status: enum_job_status_enum!) {
  update_job(
    where: {id: {_eq: $job_id}}
    _set: {expiry_date: $expiry, job_status: $job_status}
  ) {
    affected_rows
  }
}
    `;
export type InsertExpireJobsMutationFn = Apollo.MutationFunction<Types.InsertExpireJobsMutation, Types.InsertExpireJobsMutationVariables>;

/**
 * __useInsertExpireJobsMutation__
 *
 * To run a mutation, you first call `useInsertExpireJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExpireJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExpireJobsMutation, { data, loading, error }] = useInsertExpireJobsMutation({
 *   variables: {
 *      job_id: // value for 'job_id'
 *      expiry: // value for 'expiry'
 *      job_status: // value for 'job_status'
 *   },
 * });
 */
export function useInsertExpireJobsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertExpireJobsMutation, Types.InsertExpireJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertExpireJobsMutation, Types.InsertExpireJobsMutationVariables>(InsertExpireJobsDocument, options);
      }
export type InsertExpireJobsMutationHookResult = ReturnType<typeof useInsertExpireJobsMutation>;
export type InsertExpireJobsMutationResult = Apollo.MutationResult<Types.InsertExpireJobsMutation>;
export type InsertExpireJobsMutationOptions = Apollo.BaseMutationOptions<Types.InsertExpireJobsMutation, Types.InsertExpireJobsMutationVariables>;
export const GetJobByIdDocument = gql`
    query GetJobById($id: uuid!) {
  job: job_by_pk(id: $id) {
    id
    slug
    title
    tagline
    about
    requirements
    highlights
    pay_low
    pay_high
    show_pay
    pay_type
    list_date
    expiry_date
    deleted_at
    job_category
    listing_type
    job_status
    work_type
    employer_id
    location_id
    integration_job {
      application_url
      data
    }
  }
}
    `;

/**
 * __useGetJobByIdQuery__
 *
 * To run a query within a React component, call `useGetJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables> & ({ variables: Types.GetJobByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables>(GetJobByIdDocument, options);
      }
export function useGetJobByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables>(GetJobByIdDocument, options);
        }
export function useGetJobByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables>(GetJobByIdDocument, options);
        }
export type GetJobByIdQueryHookResult = ReturnType<typeof useGetJobByIdQuery>;
export type GetJobByIdLazyQueryHookResult = ReturnType<typeof useGetJobByIdLazyQuery>;
export type GetJobByIdSuspenseQueryHookResult = ReturnType<typeof useGetJobByIdSuspenseQuery>;
export type GetJobByIdQueryResult = Apollo.QueryResult<Types.GetJobByIdQuery, Types.GetJobByIdQueryVariables>;
export function refetchGetJobByIdQuery(variables: Types.GetJobByIdQueryVariables) {
      return { query: GetJobByIdDocument, variables: variables }
    }
export const GetStripeIdDocument = gql`
    query GetStripeId($id: uuid!) {
  employer_by_pk(id: $id) {
    stripe_id
    name
  }
}
    `;

/**
 * __useGetStripeIdQuery__
 *
 * To run a query within a React component, call `useGetStripeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStripeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables> & ({ variables: Types.GetStripeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables>(GetStripeIdDocument, options);
      }
export function useGetStripeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables>(GetStripeIdDocument, options);
        }
export function useGetStripeIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables>(GetStripeIdDocument, options);
        }
export type GetStripeIdQueryHookResult = ReturnType<typeof useGetStripeIdQuery>;
export type GetStripeIdLazyQueryHookResult = ReturnType<typeof useGetStripeIdLazyQuery>;
export type GetStripeIdSuspenseQueryHookResult = ReturnType<typeof useGetStripeIdSuspenseQuery>;
export type GetStripeIdQueryResult = Apollo.QueryResult<Types.GetStripeIdQuery, Types.GetStripeIdQueryVariables>;
export function refetchGetStripeIdQuery(variables: Types.GetStripeIdQueryVariables) {
      return { query: GetStripeIdDocument, variables: variables }
    }
export const UpdateStripeIdDocument = gql`
    mutation UpdateStripeId($id: uuid!, $stripe_id: String!) {
  update_employer_by_pk(pk_columns: {id: $id}, _set: {stripe_id: $stripe_id}) {
    stripe_id
  }
}
    `;
export type UpdateStripeIdMutationFn = Apollo.MutationFunction<Types.UpdateStripeIdMutation, Types.UpdateStripeIdMutationVariables>;

/**
 * __useUpdateStripeIdMutation__
 *
 * To run a mutation, you first call `useUpdateStripeIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeIdMutation, { data, loading, error }] = useUpdateStripeIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stripe_id: // value for 'stripe_id'
 *   },
 * });
 */
export function useUpdateStripeIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStripeIdMutation, Types.UpdateStripeIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStripeIdMutation, Types.UpdateStripeIdMutationVariables>(UpdateStripeIdDocument, options);
      }
export type UpdateStripeIdMutationHookResult = ReturnType<typeof useUpdateStripeIdMutation>;
export type UpdateStripeIdMutationResult = Apollo.MutationResult<Types.UpdateStripeIdMutation>;
export type UpdateStripeIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStripeIdMutation, Types.UpdateStripeIdMutationVariables>;
export const UpdateJobAfterPaymentDocument = gql`
    mutation UpdateJobAfterPayment($id: uuid!, $_set: job_set_input = {}) {
  update_job_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    job_status
    listing_type
    list_date
    expiry_date
  }
}
    `;
export type UpdateJobAfterPaymentMutationFn = Apollo.MutationFunction<Types.UpdateJobAfterPaymentMutation, Types.UpdateJobAfterPaymentMutationVariables>;

/**
 * __useUpdateJobAfterPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateJobAfterPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobAfterPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobAfterPaymentMutation, { data, loading, error }] = useUpdateJobAfterPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateJobAfterPaymentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobAfterPaymentMutation, Types.UpdateJobAfterPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobAfterPaymentMutation, Types.UpdateJobAfterPaymentMutationVariables>(UpdateJobAfterPaymentDocument, options);
      }
export type UpdateJobAfterPaymentMutationHookResult = ReturnType<typeof useUpdateJobAfterPaymentMutation>;
export type UpdateJobAfterPaymentMutationResult = Apollo.MutationResult<Types.UpdateJobAfterPaymentMutation>;
export type UpdateJobAfterPaymentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobAfterPaymentMutation, Types.UpdateJobAfterPaymentMutationVariables>;
export const UpdatePageActiveDocument = gql`
    mutation UpdatePageActive($id: uuid!, $page_active: Boolean!) {
  update_employer_by_pk(pk_columns: {id: $id}, _set: {page_active: $page_active}) {
    page_active
  }
}
    `;
export type UpdatePageActiveMutationFn = Apollo.MutationFunction<Types.UpdatePageActiveMutation, Types.UpdatePageActiveMutationVariables>;

/**
 * __useUpdatePageActiveMutation__
 *
 * To run a mutation, you first call `useUpdatePageActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageActiveMutation, { data, loading, error }] = useUpdatePageActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      page_active: // value for 'page_active'
 *   },
 * });
 */
export function useUpdatePageActiveMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePageActiveMutation, Types.UpdatePageActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePageActiveMutation, Types.UpdatePageActiveMutationVariables>(UpdatePageActiveDocument, options);
      }
export type UpdatePageActiveMutationHookResult = ReturnType<typeof useUpdatePageActiveMutation>;
export type UpdatePageActiveMutationResult = Apollo.MutationResult<Types.UpdatePageActiveMutation>;
export type UpdatePageActiveMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePageActiveMutation, Types.UpdatePageActiveMutationVariables>;
export const GetPromotionByCodeDocument = gql`
    query GetPromotionByCode($code: String!) {
  stripe_promotion(where: {active: {_eq: true}, code: {_ilike: $code}}) {
    id
    code
    coupon_fkey
    expires_at
    max_redemptions
    one_per_customer
    active
    minimum_order
    employer_id
  }
}
    `;

/**
 * __useGetPromotionByCodeQuery__
 *
 * To run a query within a React component, call `useGetPromotionByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetPromotionByCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables> & ({ variables: Types.GetPromotionByCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables>(GetPromotionByCodeDocument, options);
      }
export function useGetPromotionByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables>(GetPromotionByCodeDocument, options);
        }
export function useGetPromotionByCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables>(GetPromotionByCodeDocument, options);
        }
export type GetPromotionByCodeQueryHookResult = ReturnType<typeof useGetPromotionByCodeQuery>;
export type GetPromotionByCodeLazyQueryHookResult = ReturnType<typeof useGetPromotionByCodeLazyQuery>;
export type GetPromotionByCodeSuspenseQueryHookResult = ReturnType<typeof useGetPromotionByCodeSuspenseQuery>;
export type GetPromotionByCodeQueryResult = Apollo.QueryResult<Types.GetPromotionByCodeQuery, Types.GetPromotionByCodeQueryVariables>;
export function refetchGetPromotionByCodeQuery(variables: Types.GetPromotionByCodeQueryVariables) {
      return { query: GetPromotionByCodeDocument, variables: variables }
    }
export const GetPromotionRedemptionsCountDocument = gql`
    query GetPromotionRedemptionsCount($code: String!) {
  stripe_promotion_redemption_aggregate(
    where: {promotion: {code: {_ilike: $code}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPromotionRedemptionsCountQuery__
 *
 * To run a query within a React component, call `useGetPromotionRedemptionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionRedemptionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionRedemptionsCountQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetPromotionRedemptionsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables> & ({ variables: Types.GetPromotionRedemptionsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables>(GetPromotionRedemptionsCountDocument, options);
      }
export function useGetPromotionRedemptionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables>(GetPromotionRedemptionsCountDocument, options);
        }
export function useGetPromotionRedemptionsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables>(GetPromotionRedemptionsCountDocument, options);
        }
export type GetPromotionRedemptionsCountQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsCountQuery>;
export type GetPromotionRedemptionsCountLazyQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsCountLazyQuery>;
export type GetPromotionRedemptionsCountSuspenseQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsCountSuspenseQuery>;
export type GetPromotionRedemptionsCountQueryResult = Apollo.QueryResult<Types.GetPromotionRedemptionsCountQuery, Types.GetPromotionRedemptionsCountQueryVariables>;
export function refetchGetPromotionRedemptionsCountQuery(variables: Types.GetPromotionRedemptionsCountQueryVariables) {
      return { query: GetPromotionRedemptionsCountDocument, variables: variables }
    }
export const GetPromotionRedemptionsByEmployerDocument = gql`
    query GetPromotionRedemptionsByEmployer($promotion_id: uuid!, $employer_id: uuid!) {
  stripe_promotion_redemption(
    where: {promotion_id: {_eq: $promotion_id}, job: {employer_id: {_eq: $employer_id}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetPromotionRedemptionsByEmployerQuery__
 *
 * To run a query within a React component, call `useGetPromotionRedemptionsByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionRedemptionsByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionRedemptionsByEmployerQuery({
 *   variables: {
 *      promotion_id: // value for 'promotion_id'
 *      employer_id: // value for 'employer_id'
 *   },
 * });
 */
export function useGetPromotionRedemptionsByEmployerQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables> & ({ variables: Types.GetPromotionRedemptionsByEmployerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables>(GetPromotionRedemptionsByEmployerDocument, options);
      }
export function useGetPromotionRedemptionsByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables>(GetPromotionRedemptionsByEmployerDocument, options);
        }
export function useGetPromotionRedemptionsByEmployerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables>(GetPromotionRedemptionsByEmployerDocument, options);
        }
export type GetPromotionRedemptionsByEmployerQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsByEmployerQuery>;
export type GetPromotionRedemptionsByEmployerLazyQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsByEmployerLazyQuery>;
export type GetPromotionRedemptionsByEmployerSuspenseQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsByEmployerSuspenseQuery>;
export type GetPromotionRedemptionsByEmployerQueryResult = Apollo.QueryResult<Types.GetPromotionRedemptionsByEmployerQuery, Types.GetPromotionRedemptionsByEmployerQueryVariables>;
export function refetchGetPromotionRedemptionsByEmployerQuery(variables: Types.GetPromotionRedemptionsByEmployerQueryVariables) {
      return { query: GetPromotionRedemptionsByEmployerDocument, variables: variables }
    }
export const GetPromotionRedemptionsByEmployerCountDocument = gql`
    query GetPromotionRedemptionsByEmployerCount($promotion_id: uuid!, $employer_id: uuid!) {
  stripe_promotion_redemption_aggregate(
    where: {promotion_id: {_eq: $promotion_id}, job: {employer_id: {_eq: $employer_id}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPromotionRedemptionsByEmployerCountQuery__
 *
 * To run a query within a React component, call `useGetPromotionRedemptionsByEmployerCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionRedemptionsByEmployerCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionRedemptionsByEmployerCountQuery({
 *   variables: {
 *      promotion_id: // value for 'promotion_id'
 *      employer_id: // value for 'employer_id'
 *   },
 * });
 */
export function useGetPromotionRedemptionsByEmployerCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables> & ({ variables: Types.GetPromotionRedemptionsByEmployerCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables>(GetPromotionRedemptionsByEmployerCountDocument, options);
      }
export function useGetPromotionRedemptionsByEmployerCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables>(GetPromotionRedemptionsByEmployerCountDocument, options);
        }
export function useGetPromotionRedemptionsByEmployerCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables>(GetPromotionRedemptionsByEmployerCountDocument, options);
        }
export type GetPromotionRedemptionsByEmployerCountQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsByEmployerCountQuery>;
export type GetPromotionRedemptionsByEmployerCountLazyQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsByEmployerCountLazyQuery>;
export type GetPromotionRedemptionsByEmployerCountSuspenseQueryHookResult = ReturnType<typeof useGetPromotionRedemptionsByEmployerCountSuspenseQuery>;
export type GetPromotionRedemptionsByEmployerCountQueryResult = Apollo.QueryResult<Types.GetPromotionRedemptionsByEmployerCountQuery, Types.GetPromotionRedemptionsByEmployerCountQueryVariables>;
export function refetchGetPromotionRedemptionsByEmployerCountQuery(variables: Types.GetPromotionRedemptionsByEmployerCountQueryVariables) {
      return { query: GetPromotionRedemptionsByEmployerCountDocument, variables: variables }
    }
export const InsertPromotionRedemptionDocument = gql`
    mutation InsertPromotionRedemption($promotion_id: uuid!, $job_id: uuid!) {
  insert_stripe_promotion_redemption_one(
    object: {promotion_id: $promotion_id, job_id: $job_id}
  ) {
    id
    promotion_id
    job_id
    created_at
  }
}
    `;
export type InsertPromotionRedemptionMutationFn = Apollo.MutationFunction<Types.InsertPromotionRedemptionMutation, Types.InsertPromotionRedemptionMutationVariables>;

/**
 * __useInsertPromotionRedemptionMutation__
 *
 * To run a mutation, you first call `useInsertPromotionRedemptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPromotionRedemptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPromotionRedemptionMutation, { data, loading, error }] = useInsertPromotionRedemptionMutation({
 *   variables: {
 *      promotion_id: // value for 'promotion_id'
 *      job_id: // value for 'job_id'
 *   },
 * });
 */
export function useInsertPromotionRedemptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertPromotionRedemptionMutation, Types.InsertPromotionRedemptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertPromotionRedemptionMutation, Types.InsertPromotionRedemptionMutationVariables>(InsertPromotionRedemptionDocument, options);
      }
export type InsertPromotionRedemptionMutationHookResult = ReturnType<typeof useInsertPromotionRedemptionMutation>;
export type InsertPromotionRedemptionMutationResult = Apollo.MutationResult<Types.InsertPromotionRedemptionMutation>;
export type InsertPromotionRedemptionMutationOptions = Apollo.BaseMutationOptions<Types.InsertPromotionRedemptionMutation, Types.InsertPromotionRedemptionMutationVariables>;
export const InsertRecoveredSessionDocument = gql`
    mutation InsertRecoveredSession($session_id: String!, $submission_id: uuid, $job_id: uuid, $recovered_session_url: String, $status: String) {
  insert_stripe_recovered_session_one(
    on_conflict: {constraint: recovered_session_pkey, update_columns: [session_status]}
    object: {session_id: $session_id, recovered_session_url: $recovered_session_url, submission_id: $submission_id, job_id: $job_id, session_status: $status}
  ) {
    session_id
    recovered_session_url
    submission_id
    job_id
    created_at
  }
}
    `;
export type InsertRecoveredSessionMutationFn = Apollo.MutationFunction<Types.InsertRecoveredSessionMutation, Types.InsertRecoveredSessionMutationVariables>;

/**
 * __useInsertRecoveredSessionMutation__
 *
 * To run a mutation, you first call `useInsertRecoveredSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRecoveredSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRecoveredSessionMutation, { data, loading, error }] = useInsertRecoveredSessionMutation({
 *   variables: {
 *      session_id: // value for 'session_id'
 *      submission_id: // value for 'submission_id'
 *      job_id: // value for 'job_id'
 *      recovered_session_url: // value for 'recovered_session_url'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useInsertRecoveredSessionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertRecoveredSessionMutation, Types.InsertRecoveredSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertRecoveredSessionMutation, Types.InsertRecoveredSessionMutationVariables>(InsertRecoveredSessionDocument, options);
      }
export type InsertRecoveredSessionMutationHookResult = ReturnType<typeof useInsertRecoveredSessionMutation>;
export type InsertRecoveredSessionMutationResult = Apollo.MutationResult<Types.InsertRecoveredSessionMutation>;
export type InsertRecoveredSessionMutationOptions = Apollo.BaseMutationOptions<Types.InsertRecoveredSessionMutation, Types.InsertRecoveredSessionMutationVariables>;
export const FetchRecoverySessionsCustomerIdDocument = gql`
    query FetchRecoverySessionsCustomerId($customer_id: String!, $job_id: uuid!, $timestamp: timestamptz!) {
  stripe_recovered_session_aggregate(
    where: {job: {employer: {stripe_id: {_eq: $customer_id}}}, session_status: {_in: ["sent", "failed"]}, created_at: {_gt: $timestamp}}
  ) {
    aggregate {
      count
    }
  }
  job_by_pk(id: $job_id) {
    created_by {
      first_name
      email
    }
  }
}
    `;

/**
 * __useFetchRecoverySessionsCustomerIdQuery__
 *
 * To run a query within a React component, call `useFetchRecoverySessionsCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecoverySessionsCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecoverySessionsCustomerIdQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      job_id: // value for 'job_id'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useFetchRecoverySessionsCustomerIdQuery(baseOptions: Apollo.QueryHookOptions<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables> & ({ variables: Types.FetchRecoverySessionsCustomerIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables>(FetchRecoverySessionsCustomerIdDocument, options);
      }
export function useFetchRecoverySessionsCustomerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables>(FetchRecoverySessionsCustomerIdDocument, options);
        }
export function useFetchRecoverySessionsCustomerIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables>(FetchRecoverySessionsCustomerIdDocument, options);
        }
export type FetchRecoverySessionsCustomerIdQueryHookResult = ReturnType<typeof useFetchRecoverySessionsCustomerIdQuery>;
export type FetchRecoverySessionsCustomerIdLazyQueryHookResult = ReturnType<typeof useFetchRecoverySessionsCustomerIdLazyQuery>;
export type FetchRecoverySessionsCustomerIdSuspenseQueryHookResult = ReturnType<typeof useFetchRecoverySessionsCustomerIdSuspenseQuery>;
export type FetchRecoverySessionsCustomerIdQueryResult = Apollo.QueryResult<Types.FetchRecoverySessionsCustomerIdQuery, Types.FetchRecoverySessionsCustomerIdQueryVariables>;
export function refetchFetchRecoverySessionsCustomerIdQuery(variables: Types.FetchRecoverySessionsCustomerIdQueryVariables) {
      return { query: FetchRecoverySessionsCustomerIdDocument, variables: variables }
    }
export const FetchBundlesDocument = gql`
    query FetchBundles {
  bundles: stripe_bundle(where: {package: {_eq: true}}, order_by: {price: desc}) {
    id
    title
    subtitle
    description
    features
    package
    price
    listing_type
  }
  listings: stripe_bundle(where: {package: {_eq: false}}, order_by: {price: desc}) {
    id
    title
    subtitle
    description
    features
    package
    price
    listing_type
  }
}
    `;

/**
 * __useFetchBundlesQuery__
 *
 * To run a query within a React component, call `useFetchBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBundlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBundlesQuery(baseOptions?: Apollo.QueryHookOptions<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>(FetchBundlesDocument, options);
      }
export function useFetchBundlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>(FetchBundlesDocument, options);
        }
export function useFetchBundlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>(FetchBundlesDocument, options);
        }
export type FetchBundlesQueryHookResult = ReturnType<typeof useFetchBundlesQuery>;
export type FetchBundlesLazyQueryHookResult = ReturnType<typeof useFetchBundlesLazyQuery>;
export type FetchBundlesSuspenseQueryHookResult = ReturnType<typeof useFetchBundlesSuspenseQuery>;
export type FetchBundlesQueryResult = Apollo.QueryResult<Types.FetchBundlesQuery, Types.FetchBundlesQueryVariables>;
export function refetchFetchBundlesQuery(variables?: Types.FetchBundlesQueryVariables) {
      return { query: FetchBundlesDocument, variables: variables }
    }
export const GetBundleByIdDocument = gql`
    query GetBundleById($id: uuid!) {
  stripe_bundle_by_pk(id: $id) {
    id
    bundle
    title
    subtitle
    price
    description
    features
  }
}
    `;

/**
 * __useGetBundleByIdQuery__
 *
 * To run a query within a React component, call `useGetBundleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBundleByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables> & ({ variables: Types.GetBundleByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables>(GetBundleByIdDocument, options);
      }
export function useGetBundleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables>(GetBundleByIdDocument, options);
        }
export function useGetBundleByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables>(GetBundleByIdDocument, options);
        }
export type GetBundleByIdQueryHookResult = ReturnType<typeof useGetBundleByIdQuery>;
export type GetBundleByIdLazyQueryHookResult = ReturnType<typeof useGetBundleByIdLazyQuery>;
export type GetBundleByIdSuspenseQueryHookResult = ReturnType<typeof useGetBundleByIdSuspenseQuery>;
export type GetBundleByIdQueryResult = Apollo.QueryResult<Types.GetBundleByIdQuery, Types.GetBundleByIdQueryVariables>;
export function refetchGetBundleByIdQuery(variables: Types.GetBundleByIdQueryVariables) {
      return { query: GetBundleByIdDocument, variables: variables }
    }
export const RecoverySessionAggregateDocument = gql`
    query RecoverySessionAggregate($email: String!, $created: timestamptz!) {
  stripe_recovered_session_aggregate(
    where: {job: {created_by: {email: {_eq: $email}}}, created_at: {_gte: $created}, session_status: {_in: ["sent", "failed"]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useRecoverySessionAggregateQuery__
 *
 * To run a query within a React component, call `useRecoverySessionAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecoverySessionAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecoverySessionAggregateQuery({
 *   variables: {
 *      email: // value for 'email'
 *      created: // value for 'created'
 *   },
 * });
 */
export function useRecoverySessionAggregateQuery(baseOptions: Apollo.QueryHookOptions<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables> & ({ variables: Types.RecoverySessionAggregateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables>(RecoverySessionAggregateDocument, options);
      }
export function useRecoverySessionAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables>(RecoverySessionAggregateDocument, options);
        }
export function useRecoverySessionAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables>(RecoverySessionAggregateDocument, options);
        }
export type RecoverySessionAggregateQueryHookResult = ReturnType<typeof useRecoverySessionAggregateQuery>;
export type RecoverySessionAggregateLazyQueryHookResult = ReturnType<typeof useRecoverySessionAggregateLazyQuery>;
export type RecoverySessionAggregateSuspenseQueryHookResult = ReturnType<typeof useRecoverySessionAggregateSuspenseQuery>;
export type RecoverySessionAggregateQueryResult = Apollo.QueryResult<Types.RecoverySessionAggregateQuery, Types.RecoverySessionAggregateQueryVariables>;
export function refetchRecoverySessionAggregateQuery(variables: Types.RecoverySessionAggregateQueryVariables) {
      return { query: RecoverySessionAggregateDocument, variables: variables }
    }
export const UpdateInternalBySubmissionIdDocument = gql`
    mutation UpdateInternalBySubmissionId($submission_id: uuid!, $stripe_checkout_id: String!) {
  update_internal_raw_job_submission_by_pk(
    pk_columns: {id: $submission_id}
    _set: {stripe_checkout_id: $stripe_checkout_id}
  ) {
    id
    stripe_checkout_id
  }
}
    `;
export type UpdateInternalBySubmissionIdMutationFn = Apollo.MutationFunction<Types.UpdateInternalBySubmissionIdMutation, Types.UpdateInternalBySubmissionIdMutationVariables>;

/**
 * __useUpdateInternalBySubmissionIdMutation__
 *
 * To run a mutation, you first call `useUpdateInternalBySubmissionIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalBySubmissionIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalBySubmissionIdMutation, { data, loading, error }] = useUpdateInternalBySubmissionIdMutation({
 *   variables: {
 *      submission_id: // value for 'submission_id'
 *      stripe_checkout_id: // value for 'stripe_checkout_id'
 *   },
 * });
 */
export function useUpdateInternalBySubmissionIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateInternalBySubmissionIdMutation, Types.UpdateInternalBySubmissionIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateInternalBySubmissionIdMutation, Types.UpdateInternalBySubmissionIdMutationVariables>(UpdateInternalBySubmissionIdDocument, options);
      }
export type UpdateInternalBySubmissionIdMutationHookResult = ReturnType<typeof useUpdateInternalBySubmissionIdMutation>;
export type UpdateInternalBySubmissionIdMutationResult = Apollo.MutationResult<Types.UpdateInternalBySubmissionIdMutation>;
export type UpdateInternalBySubmissionIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateInternalBySubmissionIdMutation, Types.UpdateInternalBySubmissionIdMutationVariables>;
export const GetEmployerUserDocument = gql`
    query GetEmployerUser {
  employer(distinct_on: id) {
    id
    name
    organisation_type
  }
}
    `;

/**
 * __useGetEmployerUserQuery__
 *
 * To run a query within a React component, call `useGetEmployerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployerUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>(GetEmployerUserDocument, options);
      }
export function useGetEmployerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>(GetEmployerUserDocument, options);
        }
export function useGetEmployerUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>(GetEmployerUserDocument, options);
        }
export type GetEmployerUserQueryHookResult = ReturnType<typeof useGetEmployerUserQuery>;
export type GetEmployerUserLazyQueryHookResult = ReturnType<typeof useGetEmployerUserLazyQuery>;
export type GetEmployerUserSuspenseQueryHookResult = ReturnType<typeof useGetEmployerUserSuspenseQuery>;
export type GetEmployerUserQueryResult = Apollo.QueryResult<Types.GetEmployerUserQuery, Types.GetEmployerUserQueryVariables>;
export function refetchGetEmployerUserQuery(variables?: Types.GetEmployerUserQueryVariables) {
      return { query: GetEmployerUserDocument, variables: variables }
    }
export const GetUserByPkDocument = gql`
    query GetUserByPK($id: uuid!) {
  user_by_pk(id: $id) {
    ...userDefaults
  }
}
    ${UserDefaultsFragmentDoc}`;

/**
 * __useGetUserByPkQuery__
 *
 * To run a query within a React component, call `useGetUserByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables> & ({ variables: Types.GetUserByPkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables>(GetUserByPkDocument, options);
      }
export function useGetUserByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables>(GetUserByPkDocument, options);
        }
export function useGetUserByPkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables>(GetUserByPkDocument, options);
        }
export type GetUserByPkQueryHookResult = ReturnType<typeof useGetUserByPkQuery>;
export type GetUserByPkLazyQueryHookResult = ReturnType<typeof useGetUserByPkLazyQuery>;
export type GetUserByPkSuspenseQueryHookResult = ReturnType<typeof useGetUserByPkSuspenseQuery>;
export type GetUserByPkQueryResult = Apollo.QueryResult<Types.GetUserByPkQuery, Types.GetUserByPkQueryVariables>;
export function refetchGetUserByPkQuery(variables: Types.GetUserByPkQueryVariables) {
      return { query: GetUserByPkDocument, variables: variables }
    }
export const GetEmployeeEducationDocument = gql`
    query GetEmployeeEducation {
  user_education(
    order_by: {issue_date: desc}
    where: {deleted_at: {_is_null: true}}
  ) {
    ...userEducation
  }
}
    ${UserEducationFragmentDoc}`;

/**
 * __useGetEmployeeEducationQuery__
 *
 * To run a query within a React component, call `useGetEmployeeEducationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeEducationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeEducationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeEducationQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>(GetEmployeeEducationDocument, options);
      }
export function useGetEmployeeEducationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>(GetEmployeeEducationDocument, options);
        }
export function useGetEmployeeEducationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>(GetEmployeeEducationDocument, options);
        }
export type GetEmployeeEducationQueryHookResult = ReturnType<typeof useGetEmployeeEducationQuery>;
export type GetEmployeeEducationLazyQueryHookResult = ReturnType<typeof useGetEmployeeEducationLazyQuery>;
export type GetEmployeeEducationSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeEducationSuspenseQuery>;
export type GetEmployeeEducationQueryResult = Apollo.QueryResult<Types.GetEmployeeEducationQuery, Types.GetEmployeeEducationQueryVariables>;
export function refetchGetEmployeeEducationQuery(variables?: Types.GetEmployeeEducationQueryVariables) {
      return { query: GetEmployeeEducationDocument, variables: variables }
    }
export const InsertEducationDocument = gql`
    mutation InsertEducation($institution: String!, $issue_date: date, $complete: Boolean!, $title: String!) {
  insert_user_education(
    objects: {complete: $complete, institution: $institution, issue_date: $issue_date, title: $title}
  ) {
    returning {
      ...userEducation
    }
  }
}
    ${UserEducationFragmentDoc}`;
export type InsertEducationMutationFn = Apollo.MutationFunction<Types.InsertEducationMutation, Types.InsertEducationMutationVariables>;

/**
 * __useInsertEducationMutation__
 *
 * To run a mutation, you first call `useInsertEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEducationMutation, { data, loading, error }] = useInsertEducationMutation({
 *   variables: {
 *      institution: // value for 'institution'
 *      issue_date: // value for 'issue_date'
 *      complete: // value for 'complete'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useInsertEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertEducationMutation, Types.InsertEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertEducationMutation, Types.InsertEducationMutationVariables>(InsertEducationDocument, options);
      }
export type InsertEducationMutationHookResult = ReturnType<typeof useInsertEducationMutation>;
export type InsertEducationMutationResult = Apollo.MutationResult<Types.InsertEducationMutation>;
export type InsertEducationMutationOptions = Apollo.BaseMutationOptions<Types.InsertEducationMutation, Types.InsertEducationMutationVariables>;
export const UpdateEducationByPkDocument = gql`
    mutation UpdateEducationByPK($id: uuid!, $institution: String!, $issue_date: date, $complete: Boolean!, $title: String!, $updated_at: timestamptz) {
  update_user_education_by_pk(
    pk_columns: {id: $id}
    _set: {complete: $complete, institution: $institution, issue_date: $issue_date, title: $title, updated_at: $updated_at}
  ) {
    ...userEducation
  }
}
    ${UserEducationFragmentDoc}`;
export type UpdateEducationByPkMutationFn = Apollo.MutationFunction<Types.UpdateEducationByPkMutation, Types.UpdateEducationByPkMutationVariables>;

/**
 * __useUpdateEducationByPkMutation__
 *
 * To run a mutation, you first call `useUpdateEducationByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationByPkMutation, { data, loading, error }] = useUpdateEducationByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      institution: // value for 'institution'
 *      issue_date: // value for 'issue_date'
 *      complete: // value for 'complete'
 *      title: // value for 'title'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useUpdateEducationByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEducationByPkMutation, Types.UpdateEducationByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEducationByPkMutation, Types.UpdateEducationByPkMutationVariables>(UpdateEducationByPkDocument, options);
      }
export type UpdateEducationByPkMutationHookResult = ReturnType<typeof useUpdateEducationByPkMutation>;
export type UpdateEducationByPkMutationResult = Apollo.MutationResult<Types.UpdateEducationByPkMutation>;
export type UpdateEducationByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEducationByPkMutation, Types.UpdateEducationByPkMutationVariables>;
export const DeleteEducationByPkDocument = gql`
    mutation DeleteEducationByPK($id: uuid!, $deleted_at: timestamptz!) {
  update_user_education_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at, updated_at: $deleted_at}
  ) {
    id
    deleted_at
  }
}
    `;
export type DeleteEducationByPkMutationFn = Apollo.MutationFunction<Types.DeleteEducationByPkMutation, Types.DeleteEducationByPkMutationVariables>;

/**
 * __useDeleteEducationByPkMutation__
 *
 * To run a mutation, you first call `useDeleteEducationByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationByPkMutation, { data, loading, error }] = useDeleteEducationByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useDeleteEducationByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEducationByPkMutation, Types.DeleteEducationByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEducationByPkMutation, Types.DeleteEducationByPkMutationVariables>(DeleteEducationByPkDocument, options);
      }
export type DeleteEducationByPkMutationHookResult = ReturnType<typeof useDeleteEducationByPkMutation>;
export type DeleteEducationByPkMutationResult = Apollo.MutationResult<Types.DeleteEducationByPkMutation>;
export type DeleteEducationByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEducationByPkMutation, Types.DeleteEducationByPkMutationVariables>;
export const GetEmployeeLicenceDocument = gql`
    query GetEmployeeLicence {
  user_licence(
    order_by: {issue_date: desc}
    where: {deleted_at: {_is_null: true}}
  ) {
    ...userLicence
  }
}
    ${UserLicenceFragmentDoc}`;

/**
 * __useGetEmployeeLicenceQuery__
 *
 * To run a query within a React component, call `useGetEmployeeLicenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeLicenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeLicenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeLicenceQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>(GetEmployeeLicenceDocument, options);
      }
export function useGetEmployeeLicenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>(GetEmployeeLicenceDocument, options);
        }
export function useGetEmployeeLicenceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>(GetEmployeeLicenceDocument, options);
        }
export type GetEmployeeLicenceQueryHookResult = ReturnType<typeof useGetEmployeeLicenceQuery>;
export type GetEmployeeLicenceLazyQueryHookResult = ReturnType<typeof useGetEmployeeLicenceLazyQuery>;
export type GetEmployeeLicenceSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeLicenceSuspenseQuery>;
export type GetEmployeeLicenceQueryResult = Apollo.QueryResult<Types.GetEmployeeLicenceQuery, Types.GetEmployeeLicenceQueryVariables>;
export function refetchGetEmployeeLicenceQuery(variables?: Types.GetEmployeeLicenceQueryVariables) {
      return { query: GetEmployeeLicenceDocument, variables: variables }
    }
export const InsertLicenceDocument = gql`
    mutation InsertLicence($organisation: String!, $issue_date: date!, $expires: Boolean!, $expiry_date: date, $title: String!) {
  insert_user_licence(
    objects: {organisation: $organisation, expires: $expires, expiry_date: $expiry_date, issue_date: $issue_date, title: $title}
  ) {
    returning {
      ...userLicence
    }
  }
}
    ${UserLicenceFragmentDoc}`;
export type InsertLicenceMutationFn = Apollo.MutationFunction<Types.InsertLicenceMutation, Types.InsertLicenceMutationVariables>;

/**
 * __useInsertLicenceMutation__
 *
 * To run a mutation, you first call `useInsertLicenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLicenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLicenceMutation, { data, loading, error }] = useInsertLicenceMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      issue_date: // value for 'issue_date'
 *      expires: // value for 'expires'
 *      expiry_date: // value for 'expiry_date'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useInsertLicenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLicenceMutation, Types.InsertLicenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLicenceMutation, Types.InsertLicenceMutationVariables>(InsertLicenceDocument, options);
      }
export type InsertLicenceMutationHookResult = ReturnType<typeof useInsertLicenceMutation>;
export type InsertLicenceMutationResult = Apollo.MutationResult<Types.InsertLicenceMutation>;
export type InsertLicenceMutationOptions = Apollo.BaseMutationOptions<Types.InsertLicenceMutation, Types.InsertLicenceMutationVariables>;
export const UpdateLicenceByPkDocument = gql`
    mutation UpdateLicenceByPK($id: uuid!, $organisation: String!, $issue_date: date!, $expires: Boolean!, $expiry_date: date, $title: String!, $updated_at: timestamptz!) {
  update_user_licence_by_pk(
    pk_columns: {id: $id}
    _set: {organisation: $organisation, expires: $expires, expiry_date: $expiry_date, issue_date: $issue_date, title: $title, updated_at: $updated_at}
  ) {
    ...userLicence
  }
}
    ${UserLicenceFragmentDoc}`;
export type UpdateLicenceByPkMutationFn = Apollo.MutationFunction<Types.UpdateLicenceByPkMutation, Types.UpdateLicenceByPkMutationVariables>;

/**
 * __useUpdateLicenceByPkMutation__
 *
 * To run a mutation, you first call `useUpdateLicenceByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenceByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenceByPkMutation, { data, loading, error }] = useUpdateLicenceByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisation: // value for 'organisation'
 *      issue_date: // value for 'issue_date'
 *      expires: // value for 'expires'
 *      expiry_date: // value for 'expiry_date'
 *      title: // value for 'title'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useUpdateLicenceByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLicenceByPkMutation, Types.UpdateLicenceByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLicenceByPkMutation, Types.UpdateLicenceByPkMutationVariables>(UpdateLicenceByPkDocument, options);
      }
export type UpdateLicenceByPkMutationHookResult = ReturnType<typeof useUpdateLicenceByPkMutation>;
export type UpdateLicenceByPkMutationResult = Apollo.MutationResult<Types.UpdateLicenceByPkMutation>;
export type UpdateLicenceByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLicenceByPkMutation, Types.UpdateLicenceByPkMutationVariables>;
export const DeleteLicenceByPkDocument = gql`
    mutation DeleteLicenceByPK($id: uuid!, $deleted_at: timestamptz!) {
  update_user_licence_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at, updated_at: $deleted_at}
  ) {
    id
    deleted_at
  }
}
    `;
export type DeleteLicenceByPkMutationFn = Apollo.MutationFunction<Types.DeleteLicenceByPkMutation, Types.DeleteLicenceByPkMutationVariables>;

/**
 * __useDeleteLicenceByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLicenceByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenceByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenceByPkMutation, { data, loading, error }] = useDeleteLicenceByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useDeleteLicenceByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLicenceByPkMutation, Types.DeleteLicenceByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLicenceByPkMutation, Types.DeleteLicenceByPkMutationVariables>(DeleteLicenceByPkDocument, options);
      }
export type DeleteLicenceByPkMutationHookResult = ReturnType<typeof useDeleteLicenceByPkMutation>;
export type DeleteLicenceByPkMutationResult = Apollo.MutationResult<Types.DeleteLicenceByPkMutation>;
export type DeleteLicenceByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLicenceByPkMutation, Types.DeleteLicenceByPkMutationVariables>;
export const GetEmployeeExperienceDocument = gql`
    query GetEmployeeExperience {
  user_experience(
    order_by: {start_date: desc}
    where: {deleted_at: {_is_null: true}}
  ) {
    ...userExperience
  }
}
    ${UserExperienceFragmentDoc}`;

/**
 * __useGetEmployeeExperienceQuery__
 *
 * To run a query within a React component, call `useGetEmployeeExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeExperienceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeExperienceQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>(GetEmployeeExperienceDocument, options);
      }
export function useGetEmployeeExperienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>(GetEmployeeExperienceDocument, options);
        }
export function useGetEmployeeExperienceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>(GetEmployeeExperienceDocument, options);
        }
export type GetEmployeeExperienceQueryHookResult = ReturnType<typeof useGetEmployeeExperienceQuery>;
export type GetEmployeeExperienceLazyQueryHookResult = ReturnType<typeof useGetEmployeeExperienceLazyQuery>;
export type GetEmployeeExperienceSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeExperienceSuspenseQuery>;
export type GetEmployeeExperienceQueryResult = Apollo.QueryResult<Types.GetEmployeeExperienceQuery, Types.GetEmployeeExperienceQueryVariables>;
export function refetchGetEmployeeExperienceQuery(variables?: Types.GetEmployeeExperienceQueryVariables) {
      return { query: GetEmployeeExperienceDocument, variables: variables }
    }
export const InsertExperienceDocument = gql`
    mutation InsertExperience($company: String!, $current: Boolean!, $start_date: date!, $end_date: date, $title: String!, $description: String!) {
  insert_user_experience(
    objects: {company: $company, current: $current, start_date: $start_date, end_date: $end_date, title: $title, description: $description}
  ) {
    returning {
      ...userExperience
    }
  }
}
    ${UserExperienceFragmentDoc}`;
export type InsertExperienceMutationFn = Apollo.MutationFunction<Types.InsertExperienceMutation, Types.InsertExperienceMutationVariables>;

/**
 * __useInsertExperienceMutation__
 *
 * To run a mutation, you first call `useInsertExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertExperienceMutation, { data, loading, error }] = useInsertExperienceMutation({
 *   variables: {
 *      company: // value for 'company'
 *      current: // value for 'current'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useInsertExperienceMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertExperienceMutation, Types.InsertExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertExperienceMutation, Types.InsertExperienceMutationVariables>(InsertExperienceDocument, options);
      }
export type InsertExperienceMutationHookResult = ReturnType<typeof useInsertExperienceMutation>;
export type InsertExperienceMutationResult = Apollo.MutationResult<Types.InsertExperienceMutation>;
export type InsertExperienceMutationOptions = Apollo.BaseMutationOptions<Types.InsertExperienceMutation, Types.InsertExperienceMutationVariables>;
export const UpdateExperienceByPkDocument = gql`
    mutation UpdateExperienceByPK($id: uuid!, $company: String!, $current: Boolean!, $start_date: date!, $end_date: date, $title: String!, $description: String!, $updated_at: timestamptz!) {
  update_user_experience_by_pk(
    pk_columns: {id: $id}
    _set: {company: $company, current: $current, start_date: $start_date, end_date: $end_date, title: $title, description: $description, updated_at: $updated_at}
  ) {
    ...userExperience
  }
}
    ${UserExperienceFragmentDoc}`;
export type UpdateExperienceByPkMutationFn = Apollo.MutationFunction<Types.UpdateExperienceByPkMutation, Types.UpdateExperienceByPkMutationVariables>;

/**
 * __useUpdateExperienceByPkMutation__
 *
 * To run a mutation, you first call `useUpdateExperienceByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperienceByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperienceByPkMutation, { data, loading, error }] = useUpdateExperienceByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *      current: // value for 'current'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      updated_at: // value for 'updated_at'
 *   },
 * });
 */
export function useUpdateExperienceByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateExperienceByPkMutation, Types.UpdateExperienceByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateExperienceByPkMutation, Types.UpdateExperienceByPkMutationVariables>(UpdateExperienceByPkDocument, options);
      }
export type UpdateExperienceByPkMutationHookResult = ReturnType<typeof useUpdateExperienceByPkMutation>;
export type UpdateExperienceByPkMutationResult = Apollo.MutationResult<Types.UpdateExperienceByPkMutation>;
export type UpdateExperienceByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateExperienceByPkMutation, Types.UpdateExperienceByPkMutationVariables>;
export const DeleteExperienceByPkDocument = gql`
    mutation DeleteExperienceByPK($id: uuid!, $deleted_at: timestamptz!) {
  update_user_experience_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at, updated_at: $deleted_at}
  ) {
    id
    deleted_at
  }
}
    `;
export type DeleteExperienceByPkMutationFn = Apollo.MutationFunction<Types.DeleteExperienceByPkMutation, Types.DeleteExperienceByPkMutationVariables>;

/**
 * __useDeleteExperienceByPkMutation__
 *
 * To run a mutation, you first call `useDeleteExperienceByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExperienceByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExperienceByPkMutation, { data, loading, error }] = useDeleteExperienceByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useDeleteExperienceByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteExperienceByPkMutation, Types.DeleteExperienceByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteExperienceByPkMutation, Types.DeleteExperienceByPkMutationVariables>(DeleteExperienceByPkDocument, options);
      }
export type DeleteExperienceByPkMutationHookResult = ReturnType<typeof useDeleteExperienceByPkMutation>;
export type DeleteExperienceByPkMutationResult = Apollo.MutationResult<Types.DeleteExperienceByPkMutation>;
export type DeleteExperienceByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteExperienceByPkMutation, Types.DeleteExperienceByPkMutationVariables>;
export const UpdateEmployeeByPkDocument = gql`
    mutation UpdateEmployeeByPk($id: uuid!, $first_name: String!, $last_name: String!, $phone: String!, $about: String, $category: jsonb) {
  update_user_by_pk(
    pk_columns: {id: $id}
    _set: {first_name: $first_name, last_name: $last_name, phone: $phone, about: $about, category: $category}
  ) {
    ...userDefaults
  }
}
    ${UserDefaultsFragmentDoc}`;
export type UpdateEmployeeByPkMutationFn = Apollo.MutationFunction<Types.UpdateEmployeeByPkMutation, Types.UpdateEmployeeByPkMutationVariables>;

/**
 * __useUpdateEmployeeByPkMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeByPkMutation, { data, loading, error }] = useUpdateEmployeeByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      phone: // value for 'phone'
 *      about: // value for 'about'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateEmployeeByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmployeeByPkMutation, Types.UpdateEmployeeByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmployeeByPkMutation, Types.UpdateEmployeeByPkMutationVariables>(UpdateEmployeeByPkDocument, options);
      }
export type UpdateEmployeeByPkMutationHookResult = ReturnType<typeof useUpdateEmployeeByPkMutation>;
export type UpdateEmployeeByPkMutationResult = Apollo.MutationResult<Types.UpdateEmployeeByPkMutation>;
export type UpdateEmployeeByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmployeeByPkMutation, Types.UpdateEmployeeByPkMutationVariables>;
export const UpdateEmployeeCategoryDocument = gql`
    mutation UpdateEmployeeCategory($id: uuid!, $category: jsonb!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {category: $category}) {
    ...userDefaults
  }
}
    ${UserDefaultsFragmentDoc}`;
export type UpdateEmployeeCategoryMutationFn = Apollo.MutationFunction<Types.UpdateEmployeeCategoryMutation, Types.UpdateEmployeeCategoryMutationVariables>;

/**
 * __useUpdateEmployeeCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeCategoryMutation, { data, loading, error }] = useUpdateEmployeeCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateEmployeeCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEmployeeCategoryMutation, Types.UpdateEmployeeCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEmployeeCategoryMutation, Types.UpdateEmployeeCategoryMutationVariables>(UpdateEmployeeCategoryDocument, options);
      }
export type UpdateEmployeeCategoryMutationHookResult = ReturnType<typeof useUpdateEmployeeCategoryMutation>;
export type UpdateEmployeeCategoryMutationResult = Apollo.MutationResult<Types.UpdateEmployeeCategoryMutation>;
export type UpdateEmployeeCategoryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEmployeeCategoryMutation, Types.UpdateEmployeeCategoryMutationVariables>;
export const GetEmployeeCategoryDocument = gql`
    query GetEmployeeCategory($id: uuid!) {
  user_by_pk(id: $id) {
    category
  }
}
    `;

/**
 * __useGetEmployeeCategoryQuery__
 *
 * To run a query within a React component, call `useGetEmployeeCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables> & ({ variables: Types.GetEmployeeCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables>(GetEmployeeCategoryDocument, options);
      }
export function useGetEmployeeCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables>(GetEmployeeCategoryDocument, options);
        }
export function useGetEmployeeCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables>(GetEmployeeCategoryDocument, options);
        }
export type GetEmployeeCategoryQueryHookResult = ReturnType<typeof useGetEmployeeCategoryQuery>;
export type GetEmployeeCategoryLazyQueryHookResult = ReturnType<typeof useGetEmployeeCategoryLazyQuery>;
export type GetEmployeeCategorySuspenseQueryHookResult = ReturnType<typeof useGetEmployeeCategorySuspenseQuery>;
export type GetEmployeeCategoryQueryResult = Apollo.QueryResult<Types.GetEmployeeCategoryQuery, Types.GetEmployeeCategoryQueryVariables>;
export function refetchGetEmployeeCategoryQuery(variables: Types.GetEmployeeCategoryQueryVariables) {
      return { query: GetEmployeeCategoryDocument, variables: variables }
    }
export const GetEmployeeProfileDocument = gql`
    query GetEmployeeProfile($id: uuid!) {
  user_by_pk(id: $id) {
    ...userDefaults
    ...userHistory
    category
    location {
      neighbourhood
      region
    }
  }
}
    ${UserDefaultsFragmentDoc}
${UserHistoryFragmentDoc}`;

/**
 * __useGetEmployeeProfileQuery__
 *
 * To run a query within a React component, call `useGetEmployeeProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables> & ({ variables: Types.GetEmployeeProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables>(GetEmployeeProfileDocument, options);
      }
export function useGetEmployeeProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables>(GetEmployeeProfileDocument, options);
        }
export function useGetEmployeeProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables>(GetEmployeeProfileDocument, options);
        }
export type GetEmployeeProfileQueryHookResult = ReturnType<typeof useGetEmployeeProfileQuery>;
export type GetEmployeeProfileLazyQueryHookResult = ReturnType<typeof useGetEmployeeProfileLazyQuery>;
export type GetEmployeeProfileSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeProfileSuspenseQuery>;
export type GetEmployeeProfileQueryResult = Apollo.QueryResult<Types.GetEmployeeProfileQuery, Types.GetEmployeeProfileQueryVariables>;
export function refetchGetEmployeeProfileQuery(variables: Types.GetEmployeeProfileQueryVariables) {
      return { query: GetEmployeeProfileDocument, variables: variables }
    }
export const GetWatchlistDocument = gql`
    query GetWatchlist {
  watchlist {
    id
    job_id
    deleted_at
  }
}
    `;

/**
 * __useGetWatchlistQuery__
 *
 * To run a query within a React component, call `useGetWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWatchlistQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>(GetWatchlistDocument, options);
      }
export function useGetWatchlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>(GetWatchlistDocument, options);
        }
export function useGetWatchlistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>(GetWatchlistDocument, options);
        }
export type GetWatchlistQueryHookResult = ReturnType<typeof useGetWatchlistQuery>;
export type GetWatchlistLazyQueryHookResult = ReturnType<typeof useGetWatchlistLazyQuery>;
export type GetWatchlistSuspenseQueryHookResult = ReturnType<typeof useGetWatchlistSuspenseQuery>;
export type GetWatchlistQueryResult = Apollo.QueryResult<Types.GetWatchlistQuery, Types.GetWatchlistQueryVariables>;
export function refetchGetWatchlistQuery(variables?: Types.GetWatchlistQueryVariables) {
      return { query: GetWatchlistDocument, variables: variables }
    }
export const UpsertWatchlistDocument = gql`
    mutation UpsertWatchlist($job_id: uuid!, $id: uuid!, $deleted_at: timestamptz) {
  insert_watchlist(
    objects: {id: $id, job_id: $job_id, deleted_at: $deleted_at}
    on_conflict: {constraint: watchlist_pkey, update_columns: deleted_at}
  ) {
    affected_rows
  }
}
    `;
export type UpsertWatchlistMutationFn = Apollo.MutationFunction<Types.UpsertWatchlistMutation, Types.UpsertWatchlistMutationVariables>;

/**
 * __useUpsertWatchlistMutation__
 *
 * To run a mutation, you first call `useUpsertWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertWatchlistMutation, { data, loading, error }] = useUpsertWatchlistMutation({
 *   variables: {
 *      job_id: // value for 'job_id'
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useUpsertWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertWatchlistMutation, Types.UpsertWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertWatchlistMutation, Types.UpsertWatchlistMutationVariables>(UpsertWatchlistDocument, options);
      }
export type UpsertWatchlistMutationHookResult = ReturnType<typeof useUpsertWatchlistMutation>;
export type UpsertWatchlistMutationResult = Apollo.MutationResult<Types.UpsertWatchlistMutation>;
export type UpsertWatchlistMutationOptions = Apollo.BaseMutationOptions<Types.UpsertWatchlistMutation, Types.UpsertWatchlistMutationVariables>;
export const GetEmployeeJobsDocument = gql`
    query GetEmployeeJobs {
  watchlisted: job(
    where: {watchlists: {id: {_is_null: false}, deleted_at: {_is_null: true}}}
    order_by: {updated_at: desc}
  ) {
    ...jobWithEmployer
    watchlists {
      id
      deleted_at
    }
  }
  applied: job(
    where: {applications: {id: {_is_null: false}, deleted_at: {_is_null: true}}}
    order_by: {updated_at: desc}
  ) {
    ...jobWithEmployer
    applications(order_by: {created_at: desc}) {
      ...jobApplication
    }
  }
}
    ${JobWithEmployerFragmentDoc}
${JobApplicationFragmentDoc}`;

/**
 * __useGetEmployeeJobsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>(GetEmployeeJobsDocument, options);
      }
export function useGetEmployeeJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>(GetEmployeeJobsDocument, options);
        }
export function useGetEmployeeJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>(GetEmployeeJobsDocument, options);
        }
export type GetEmployeeJobsQueryHookResult = ReturnType<typeof useGetEmployeeJobsQuery>;
export type GetEmployeeJobsLazyQueryHookResult = ReturnType<typeof useGetEmployeeJobsLazyQuery>;
export type GetEmployeeJobsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeJobsSuspenseQuery>;
export type GetEmployeeJobsQueryResult = Apollo.QueryResult<Types.GetEmployeeJobsQuery, Types.GetEmployeeJobsQueryVariables>;
export function refetchGetEmployeeJobsQuery(variables?: Types.GetEmployeeJobsQueryVariables) {
      return { query: GetEmployeeJobsDocument, variables: variables }
    }
export const UpdateProfileImageDocument = gql`
    mutation UpdateProfileImage($id: uuid!, $profile_photo: String!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {profile_photo: $profile_photo}) {
    id
  }
}
    `;
export type UpdateProfileImageMutationFn = Apollo.MutationFunction<Types.UpdateProfileImageMutation, Types.UpdateProfileImageMutationVariables>;

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profile_photo: // value for 'profile_photo'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileImageMutation, Types.UpdateProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileImageMutation, Types.UpdateProfileImageMutationVariables>(UpdateProfileImageDocument, options);
      }
export type UpdateProfileImageMutationHookResult = ReturnType<typeof useUpdateProfileImageMutation>;
export type UpdateProfileImageMutationResult = Apollo.MutationResult<Types.UpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileImageMutation, Types.UpdateProfileImageMutationVariables>;
export const GetEmployerUserByIdDocument = gql`
    query GetEmployerUserById($id: uuid) {
  employer_user_by_id(args: {user_id: $id}) {
    id
    email
    phone
    first_name
    last_name
    profile_photo
  }
}
    `;

/**
 * __useGetEmployerUserByIdQuery__
 *
 * To run a query within a React component, call `useGetEmployerUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployerUserByIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>(GetEmployerUserByIdDocument, options);
      }
export function useGetEmployerUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>(GetEmployerUserByIdDocument, options);
        }
export function useGetEmployerUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>(GetEmployerUserByIdDocument, options);
        }
export type GetEmployerUserByIdQueryHookResult = ReturnType<typeof useGetEmployerUserByIdQuery>;
export type GetEmployerUserByIdLazyQueryHookResult = ReturnType<typeof useGetEmployerUserByIdLazyQuery>;
export type GetEmployerUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetEmployerUserByIdSuspenseQuery>;
export type GetEmployerUserByIdQueryResult = Apollo.QueryResult<Types.GetEmployerUserByIdQuery, Types.GetEmployerUserByIdQueryVariables>;
export function refetchGetEmployerUserByIdQuery(variables?: Types.GetEmployerUserByIdQueryVariables) {
      return { query: GetEmployerUserByIdDocument, variables: variables }
    }
export const GetEmployerUserByEmailDocument = gql`
    query GetEmployerUserByEmail($email: String!) {
  user(where: {email: {_eq: $email}}) {
    id
    email
    phone
    first_name
    last_name
    profile_photo
  }
}
    `;

/**
 * __useGetEmployerUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetEmployerUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetEmployerUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables> & ({ variables: Types.GetEmployerUserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables>(GetEmployerUserByEmailDocument, options);
      }
export function useGetEmployerUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables>(GetEmployerUserByEmailDocument, options);
        }
export function useGetEmployerUserByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables>(GetEmployerUserByEmailDocument, options);
        }
export type GetEmployerUserByEmailQueryHookResult = ReturnType<typeof useGetEmployerUserByEmailQuery>;
export type GetEmployerUserByEmailLazyQueryHookResult = ReturnType<typeof useGetEmployerUserByEmailLazyQuery>;
export type GetEmployerUserByEmailSuspenseQueryHookResult = ReturnType<typeof useGetEmployerUserByEmailSuspenseQuery>;
export type GetEmployerUserByEmailQueryResult = Apollo.QueryResult<Types.GetEmployerUserByEmailQuery, Types.GetEmployerUserByEmailQueryVariables>;
export function refetchGetEmployerUserByEmailQuery(variables: Types.GetEmployerUserByEmailQueryVariables) {
      return { query: GetEmployerUserByEmailDocument, variables: variables }
    }
export const CountUserByEmailDocument = gql`
    query CountUserByEmail($email: String!) {
  user_aggregate(where: {email: {_ilike: $email}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountUserByEmailQuery__
 *
 * To run a query within a React component, call `useCountUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCountUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables> & ({ variables: Types.CountUserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables>(CountUserByEmailDocument, options);
      }
export function useCountUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables>(CountUserByEmailDocument, options);
        }
export function useCountUserByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables>(CountUserByEmailDocument, options);
        }
export type CountUserByEmailQueryHookResult = ReturnType<typeof useCountUserByEmailQuery>;
export type CountUserByEmailLazyQueryHookResult = ReturnType<typeof useCountUserByEmailLazyQuery>;
export type CountUserByEmailSuspenseQueryHookResult = ReturnType<typeof useCountUserByEmailSuspenseQuery>;
export type CountUserByEmailQueryResult = Apollo.QueryResult<Types.CountUserByEmailQuery, Types.CountUserByEmailQueryVariables>;
export function refetchCountUserByEmailQuery(variables: Types.CountUserByEmailQueryVariables) {
      return { query: CountUserByEmailDocument, variables: variables }
    }
export const UpdateCategoryRegisterDocument = gql`
    mutation UpdateCategoryRegister($id: uuid!, $category: jsonb!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {category: $category}) {
    id
    category
  }
}
    `;
export type UpdateCategoryRegisterMutationFn = Apollo.MutationFunction<Types.UpdateCategoryRegisterMutation, Types.UpdateCategoryRegisterMutationVariables>;

/**
 * __useUpdateCategoryRegisterMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryRegisterMutation, { data, loading, error }] = useUpdateCategoryRegisterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateCategoryRegisterMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCategoryRegisterMutation, Types.UpdateCategoryRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCategoryRegisterMutation, Types.UpdateCategoryRegisterMutationVariables>(UpdateCategoryRegisterDocument, options);
      }
export type UpdateCategoryRegisterMutationHookResult = ReturnType<typeof useUpdateCategoryRegisterMutation>;
export type UpdateCategoryRegisterMutationResult = Apollo.MutationResult<Types.UpdateCategoryRegisterMutation>;
export type UpdateCategoryRegisterMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCategoryRegisterMutation, Types.UpdateCategoryRegisterMutationVariables>;
export const OnboardQuestionsDocument = gql`
    query OnboardQuestions {
  question(where: {section: {_eq: "onboard"}}, order_by: {order: asc}) {
    id
    order
    question
    explainer
    type
    options
  }
}
    `;

/**
 * __useOnboardQuestionsQuery__
 *
 * To run a query within a React component, call `useOnboardQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>(OnboardQuestionsDocument, options);
      }
export function useOnboardQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>(OnboardQuestionsDocument, options);
        }
export function useOnboardQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>(OnboardQuestionsDocument, options);
        }
export type OnboardQuestionsQueryHookResult = ReturnType<typeof useOnboardQuestionsQuery>;
export type OnboardQuestionsLazyQueryHookResult = ReturnType<typeof useOnboardQuestionsLazyQuery>;
export type OnboardQuestionsSuspenseQueryHookResult = ReturnType<typeof useOnboardQuestionsSuspenseQuery>;
export type OnboardQuestionsQueryResult = Apollo.QueryResult<Types.OnboardQuestionsQuery, Types.OnboardQuestionsQueryVariables>;
export function refetchOnboardQuestionsQuery(variables?: Types.OnboardQuestionsQueryVariables) {
      return { query: OnboardQuestionsDocument, variables: variables }
    }
export const GetOnboardDocument = gql`
    query GetOnboard($id: uuid!) {
  user_by_pk(id: $id) {
    onboard_at
  }
}
    `;

/**
 * __useGetOnboardQuery__
 *
 * To run a query within a React component, call `useGetOnboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOnboardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOnboardQuery, Types.GetOnboardQueryVariables> & ({ variables: Types.GetOnboardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOnboardQuery, Types.GetOnboardQueryVariables>(GetOnboardDocument, options);
      }
export function useGetOnboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOnboardQuery, Types.GetOnboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOnboardQuery, Types.GetOnboardQueryVariables>(GetOnboardDocument, options);
        }
export function useGetOnboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetOnboardQuery, Types.GetOnboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetOnboardQuery, Types.GetOnboardQueryVariables>(GetOnboardDocument, options);
        }
export type GetOnboardQueryHookResult = ReturnType<typeof useGetOnboardQuery>;
export type GetOnboardLazyQueryHookResult = ReturnType<typeof useGetOnboardLazyQuery>;
export type GetOnboardSuspenseQueryHookResult = ReturnType<typeof useGetOnboardSuspenseQuery>;
export type GetOnboardQueryResult = Apollo.QueryResult<Types.GetOnboardQuery, Types.GetOnboardQueryVariables>;
export function refetchGetOnboardQuery(variables: Types.GetOnboardQueryVariables) {
      return { query: GetOnboardDocument, variables: variables }
    }
export const UpdateOnboardDocument = gql`
    mutation UpdateOnboard($id: uuid!, $onboard_at: timestamptz!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {onboard_at: $onboard_at}) {
    id
  }
}
    `;
export type UpdateOnboardMutationFn = Apollo.MutationFunction<Types.UpdateOnboardMutation, Types.UpdateOnboardMutationVariables>;

/**
 * __useUpdateOnboardMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardMutation, { data, loading, error }] = useUpdateOnboardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      onboard_at: // value for 'onboard_at'
 *   },
 * });
 */
export function useUpdateOnboardMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOnboardMutation, Types.UpdateOnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOnboardMutation, Types.UpdateOnboardMutationVariables>(UpdateOnboardDocument, options);
      }
export type UpdateOnboardMutationHookResult = ReturnType<typeof useUpdateOnboardMutation>;
export type UpdateOnboardMutationResult = Apollo.MutationResult<Types.UpdateOnboardMutation>;
export type UpdateOnboardMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOnboardMutation, Types.UpdateOnboardMutationVariables>;
export const GetAnswerByQuestionIdDocument = gql`
    query GetAnswerByQuestionID($question_id: uuid!) {
  user_answer(where: {question_id: {_eq: $question_id}}) {
    id
    answer
  }
}
    `;

/**
 * __useGetAnswerByQuestionIdQuery__
 *
 * To run a query within a React component, call `useGetAnswerByQuestionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswerByQuestionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswerByQuestionIdQuery({
 *   variables: {
 *      question_id: // value for 'question_id'
 *   },
 * });
 */
export function useGetAnswerByQuestionIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables> & ({ variables: Types.GetAnswerByQuestionIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables>(GetAnswerByQuestionIdDocument, options);
      }
export function useGetAnswerByQuestionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables>(GetAnswerByQuestionIdDocument, options);
        }
export function useGetAnswerByQuestionIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables>(GetAnswerByQuestionIdDocument, options);
        }
export type GetAnswerByQuestionIdQueryHookResult = ReturnType<typeof useGetAnswerByQuestionIdQuery>;
export type GetAnswerByQuestionIdLazyQueryHookResult = ReturnType<typeof useGetAnswerByQuestionIdLazyQuery>;
export type GetAnswerByQuestionIdSuspenseQueryHookResult = ReturnType<typeof useGetAnswerByQuestionIdSuspenseQuery>;
export type GetAnswerByQuestionIdQueryResult = Apollo.QueryResult<Types.GetAnswerByQuestionIdQuery, Types.GetAnswerByQuestionIdQueryVariables>;
export function refetchGetAnswerByQuestionIdQuery(variables: Types.GetAnswerByQuestionIdQueryVariables) {
      return { query: GetAnswerByQuestionIdDocument, variables: variables }
    }
export const UpsertOnboardAnswersDocument = gql`
    mutation UpsertOnboardAnswers($objects: [user_answer_insert_input!]!) {
  insert_user_answer(
    objects: $objects
    on_conflict: {constraint: user_answer_user_id_question_id_key, update_columns: [answer]}
  ) {
    affected_rows
  }
}
    `;
export type UpsertOnboardAnswersMutationFn = Apollo.MutationFunction<Types.UpsertOnboardAnswersMutation, Types.UpsertOnboardAnswersMutationVariables>;

/**
 * __useUpsertOnboardAnswersMutation__
 *
 * To run a mutation, you first call `useUpsertOnboardAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOnboardAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOnboardAnswersMutation, { data, loading, error }] = useUpsertOnboardAnswersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertOnboardAnswersMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertOnboardAnswersMutation, Types.UpsertOnboardAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertOnboardAnswersMutation, Types.UpsertOnboardAnswersMutationVariables>(UpsertOnboardAnswersDocument, options);
      }
export type UpsertOnboardAnswersMutationHookResult = ReturnType<typeof useUpsertOnboardAnswersMutation>;
export type UpsertOnboardAnswersMutationResult = Apollo.MutationResult<Types.UpsertOnboardAnswersMutation>;
export type UpsertOnboardAnswersMutationOptions = Apollo.BaseMutationOptions<Types.UpsertOnboardAnswersMutation, Types.UpsertOnboardAnswersMutationVariables>;
export const GetUserLocationDocument = gql`
    query GetUserLocation($id: uuid!) {
  user_by_pk(id: $id) {
    id
    location {
      id
      label
      region
      district
    }
  }
}
    `;

/**
 * __useGetUserLocationQuery__
 *
 * To run a query within a React component, call `useGetUserLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserLocationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables> & ({ variables: Types.GetUserLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables>(GetUserLocationDocument, options);
      }
export function useGetUserLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables>(GetUserLocationDocument, options);
        }
export function useGetUserLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables>(GetUserLocationDocument, options);
        }
export type GetUserLocationQueryHookResult = ReturnType<typeof useGetUserLocationQuery>;
export type GetUserLocationLazyQueryHookResult = ReturnType<typeof useGetUserLocationLazyQuery>;
export type GetUserLocationSuspenseQueryHookResult = ReturnType<typeof useGetUserLocationSuspenseQuery>;
export type GetUserLocationQueryResult = Apollo.QueryResult<Types.GetUserLocationQuery, Types.GetUserLocationQueryVariables>;
export function refetchGetUserLocationQuery(variables: Types.GetUserLocationQueryVariables) {
      return { query: GetUserLocationDocument, variables: variables }
    }
export const UpdateUserLocationDocument = gql`
    mutation UpdateUserLocation($id: uuid!, $location_id: uuid!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {location_id: $location_id}) {
    id
    location_id
  }
}
    `;
export type UpdateUserLocationMutationFn = Apollo.MutationFunction<Types.UpdateUserLocationMutation, Types.UpdateUserLocationMutationVariables>;

/**
 * __useUpdateUserLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocationMutation, { data, loading, error }] = useUpdateUserLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      location_id: // value for 'location_id'
 *   },
 * });
 */
export function useUpdateUserLocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserLocationMutation, Types.UpdateUserLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserLocationMutation, Types.UpdateUserLocationMutationVariables>(UpdateUserLocationDocument, options);
      }
export type UpdateUserLocationMutationHookResult = ReturnType<typeof useUpdateUserLocationMutation>;
export type UpdateUserLocationMutationResult = Apollo.MutationResult<Types.UpdateUserLocationMutation>;
export type UpdateUserLocationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserLocationMutation, Types.UpdateUserLocationMutationVariables>;
export const GetProfileCompletionDocument = gql`
    query GetProfileCompletion {
  user(limit: 1) {
    email
    phone
    first_name
    last_name
    category
    educations: user_educations(where: {deleted_at: {_is_null: true}}) {
      id
    }
    licences: user_licences(where: {deleted_at: {_is_null: true}}) {
      id
    }
    experiences: user_experiences(order_by: {end_date: desc}) {
      start_date
      end_date
      current
    }
    answers: user_answers(where: {question: {section: {_eq: "onboard"}}}) {
      answer
      question {
        type
      }
    }
    profile_photo
    about
    location_id
  }
}
    `;

/**
 * __useGetProfileCompletionQuery__
 *
 * To run a query within a React component, call `useGetProfileCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCompletionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileCompletionQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>(GetProfileCompletionDocument, options);
      }
export function useGetProfileCompletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>(GetProfileCompletionDocument, options);
        }
export function useGetProfileCompletionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>(GetProfileCompletionDocument, options);
        }
export type GetProfileCompletionQueryHookResult = ReturnType<typeof useGetProfileCompletionQuery>;
export type GetProfileCompletionLazyQueryHookResult = ReturnType<typeof useGetProfileCompletionLazyQuery>;
export type GetProfileCompletionSuspenseQueryHookResult = ReturnType<typeof useGetProfileCompletionSuspenseQuery>;
export type GetProfileCompletionQueryResult = Apollo.QueryResult<Types.GetProfileCompletionQuery, Types.GetProfileCompletionQueryVariables>;
export function refetchGetProfileCompletionQuery(variables?: Types.GetProfileCompletionQueryVariables) {
      return { query: GetProfileCompletionDocument, variables: variables }
    }