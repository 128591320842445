import { ReactElement } from 'react';
import { Empty, SkeletonCard } from '..';

type Render<T> = (a: T) => JSX.Element;

export type ListProps<T = any> = {
  data?: T[];
  loading?: boolean;
  render: Render<T>;
  empty?: ReactElement<typeof Empty>;
};

export const List = <T,>({
  data,
  empty = <></>,
  loading = false,
  render,
}: ListProps<T>): ReactElement<any, any> | null =>
  data?.length ? (
    <>{data.map((entry, i) => render({ ...entry, key: i }))}</>
  ) : loading ? (
    <div className="flex flex-col space-y-4">
      <SkeletonCard />
      <SkeletonCard />
    </div>
  ) : (
    empty
  );
