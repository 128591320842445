import { PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';
import { FallbackLoader, FooterEmployer, Navbar, PageMeta } from 'components';

export type LayoutProps = PropsWithChildren<{
  navbar?: boolean;
  footer?: boolean;
  meta?: PageMeta;
  headerPosition?: 'fixed' | 'relative' | 'absolute';
  overflow?: 'hidden' | 'auto' | 'scroll';
  className?: string;
}>;

export const Layout: React.FC<LayoutProps> = ({
  navbar = true,
  footer = true,
  children,
  meta,
  overflow,
}) => (
  <>
    <PageMeta {...meta} />
    <div className="flex min-h-screen flex-col">
      {navbar && <Navbar />}

      <main
        className={twJoin(
          'flex flex-1 flex-col justify-start z-0 relative',
          overflow ? overflow : 'overflow-hidden'
        )}
      >
        <FallbackLoader>{children}</FallbackLoader>
      </main>

      {footer && <FooterEmployer />}
    </div>
  </>
);
