import { captureException } from '@sentry/nextjs';
import { RadioGroup } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CardElement, useStripe } from '@stripe/react-stripe-js';
import {
  PaymentIntent,
  PaymentIntentResult,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { twMerge } from 'tailwind-merge';
import { useEffect, useState, VFC } from 'react';
import {
  Button,
  FullPageLoader,
  StripeElements,
  useMessages,
} from 'components';
import { ExistingCard } from 'components/CreditCards/CardPayment/ExistingCard';
import {
  ALERT_APIException,
  ALERT_CardSelectionStateError,
  ALERT_PaymentApproved,
  ALERT_PaymentCancelled,
  ALERT_PaymentError,
} from 'components/CreditCards/CardPayment/messages';
import { Checkbox } from 'components/forms';
import { usePaymentMethods } from 'utils/stripe';

type CardPaymentProps = {
  onSuccess?: () => void | Promise<any>;
  clientSecret: string;
  onBackButton?: () => void | Promise<any>;
};

// wrapper needed so the inner component can use stripe hooks
export const CardPayment: VFC<CardPaymentProps> = (props) => (
  <StripeElements>
    <Cards {...props} />
  </StripeElements>
);

const Cards: VFC<CardPaymentProps> = ({
  onSuccess,
  clientSecret,
  onBackButton,
}) => {
  const { paymentMethods, loading, defaultId, setDefault } =
    usePaymentMethods();
  const { alert, dismiss } = useMessages();

  // Existing
  const [selected, setSelected] = useState(defaultId);
  const [processing, setProcessing] = useState<boolean>(false);
  useEffect(() => setSelected(defaultId), [defaultId]);

  const [shouldSetDefault, setShouldSetDefault] = useState(false);
  const handleSelection = (id: string) => {
    setSelected(id);
    setShowNew(false);
    id === defaultId && setShouldSetDefault(false);
  };

  // New Card
  const stripe = useStripe();

  const [card, setCard] = useState<StripeCardElement>();
  const [cardState, setCardState] = useState<
    Partial<StripeCardElementChangeEvent>
  >({});

  // UI state
  const [showNew, setShowNew] = useState(false);
  useEffect(() => {
    setShowNew(!paymentMethods?.length);
  }, [paymentMethods]);

  const [save, setSave] = useState(false);
  const [cardError, setCardError] = useState<string>();
  const handleShowNew = () => {
    if (showNew) return;
    setShowNew(true);
    setSelected(undefined);
    setSave(false);
    setShouldSetDefault(false);
  };
  useEffect(
    () => setCardError(cardState.error?.message),
    [cardState.error?.message]
  );

  useEffect(() => {
    if (!processing) {
      dismiss('PROCESSING_PAYMENT');
      return;
    }
    alert({
      key: 'PROCESSING_PAYMENT',
      type: 'processing',
      title: 'Processing payment',
      dismissable: false,
    });
  }, [processing]);

  // actions
  const handleConfirm = async () => {
    if (showNew) payWithNew();
    else if (selected) payWithExisting();
    else {
      console.error('state error', { showNew, selected });
      alert(ALERT_CardSelectionStateError);
    }
  };

  const payWithExisting = async () => {
    if (!selected || !stripe) return;
    setProcessing(true);
    try {
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: selected,
      });
      setProcessing(false);

      handlePaymentResponse(response);
    } catch (err) {
      alert(ALERT_APIException);
      captureException(err);
    } finally {
      setProcessing(false);
    }
  };

  const payWithNew = async () => {
    if (!card || !stripe) return;
    setProcessing(true);
    try {
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card },
        ...(save && {
          // this saves the card straight after the transaction
          // it's a better method than
          setup_future_usage: 'off_session',
          save_payment_method: true,
        }),
      });

      handlePaymentResponse(response);
    } catch (err) {
      alert(ALERT_APIException);
      setProcessing(false);
    }
  };

  const handlePaymentResponse = (response: PaymentIntentResult) => {
    const { paymentIntent, error } = response;
    if (paymentIntent) onPaymentSuccess(paymentIntent);
    else error && onPaymentError(error);
  };

  const onPaymentSuccess = async (paymentIntent: PaymentIntent) => {
    // https://stripe.com/docs/payments/intents#intent-statuses
    switch (paymentIntent.status) {
      case 'succeeded':
        //note: the business logic that handles payment is done in /api/stripe/webhook

        alert(ALERT_PaymentApproved);

        if (
          save &&
          (shouldSetDefault || !defaultId) &&
          paymentIntent.payment_method &&
          typeof paymentIntent.payment_method === 'string'
        ) {
          await setDefault(paymentIntent.payment_method);
        }

        onSuccess && onSuccess();
        break;
      default:
        alert(ALERT_PaymentCancelled);
        break;
    }
  };

  const onPaymentError = (error: StripeError) => {
    alert({
      ...ALERT_PaymentError,
      ...(error.message && { message: error.message }),
    });
  };

  if (loading) return <FullPageLoader />;
  if (!paymentMethods) return <>Error</>;

  return (
    <div>
      <label className="mb-1 block text-sm uppercase tracking-wider text-grey">
        Select Payment Method
      </label>

      {/* Existing Cards */}
      {paymentMethods?.length > 0 && (
        <RadioGroup
          value={showNew ? undefined : selected}
          onChange={handleSelection}
          className="-space-y-1"
        >
          {paymentMethods?.map(
            ({ id, card }) =>
              card && (
                <ExistingCard
                  key={id}
                  {...{ id, card }}
                  isDefault={id === defaultId}
                />
              )
          )}
        </RadioGroup>
      )}
      {selected && selected !== defaultId && (
        <Checkbox
          className="mt-2"
          label="Set as default card for future payments"
          checked={shouldSetDefault}
          onChange={({ target: { checked } }) => setShouldSetDefault(checked)}
        />
      )}

      {/* Add New */}
      <label
        htmlFor="add-new-card"
        className="mb-2 mt-4 inline-flex cursor-pointer text-sm uppercase tracking-wider text-grey"
        onClick={handleShowNew}
      >
        <PlusIcon className="mr-3 w-4" />
        <div>Use New Card</div>
      </label>
      <div className={twMerge('space-y-1', !showNew && 'hidden')}>
        <CardElement
          id="add-new-card2"
          options={{
            hidePostalCode: true,
          }}
          onReady={setCard}
          onChange={setCardState}
          // onChange={handleCardChange}
          className={twMerge(
            'rounded border border-light bg-white p-4',
            cardError &&
              'border-red-500 focus:border-red-700 focus:ring-red-500'
          )}
        />

        <div className="relative min-h-[16px] text-xs text-red-500">
          {cardError}
        </div>

        <Checkbox
          label="Save this card for future payments"
          checked={save}
          onChange={({ target: { checked } }) => {
            setSave(checked);
            setShouldSetDefault(false);
          }}
        />
      </div>
      <div className="flex w-full justify-end space-x-2">
        {!!onBackButton && (
          <Button
            size="small"
            color="grey-light"
            className="mt-4"
            onClick={onBackButton}
          >
            Back
          </Button>
        )}
        <Button
          size="small"
          className="mt-4"
          disabled={
            (showNew && (!cardState.complete || !!cardError)) || processing
          }
          loading={processing}
          onClick={handleConfirm}
        >
          {processing ? 'Processing' : 'Confirm'}
        </Button>
      </div>
    </div>
  );
};
