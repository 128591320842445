import { FC } from 'react';
import { useRouter } from 'next/router';
import {
  AddButton,
  EducationCard,
  ExperienceCard,
  Header,
  LicenceCard,
} from 'components';
import { User_Education, User_Experience, User_Licence } from 'database/types';

type DefaultProps = {
  role?: 'applicant' | 'employer';
};

type ExperienceProps = DefaultProps & {
  user_experiences?: User_Experience[];
};

export const ExperienceBlock: FC<ExperienceProps> = ({
  user_experiences = [],
  role = 'employer',
}) => {
  const router = useRouter();
  const showAction = role === 'applicant' && user_experiences.length < 1;
  return (
    <div>
      <div className="flex-row justify-between">
        <Header
          {...(showAction && {
            action: (
              <AddButton onClick={() => router.push('/employee/profile')} />
            ),
          })}
        >
          Experience
        </Header>
      </div>
      <div className="space-y-8">
        {user_experiences.map((item) => (
          <ExperienceCard {...item} key={item.id} card={false} />
        ))}
      </div>
    </div>
  );
};

type LicenceProps = DefaultProps & {
  user_licences?: User_Licence[];
};

export const LicenceBlock: FC<LicenceProps> = ({
  user_licences = [],
  role = 'employer',
}) => {
  const router = useRouter();
  const showAction = role === 'applicant' && user_licences.length < 1;
  return (
    <div>
      <div className="flex-row justify-between">
        <Header
          {...(showAction && {
            action: (
              <AddButton onClick={() => router.push('/employee/profile')} />
            ),
          })}
        >
          Licences
        </Header>
      </div>
      <div className="space-y-8">
        {user_licences.map((item) => (
          <LicenceCard {...item} key={item.id} card={false} />
        ))}
      </div>
    </div>
  );
};

type EducationProps = DefaultProps & {
  user_educations?: User_Education[];
};

export const EducationBlock: FC<EducationProps> = ({
  user_educations = [],
  role = 'employer',
}) => {
  const router = useRouter();
  const showAction = role === 'applicant' && user_educations.length < 1;
  return (
    <div>
      <div className="flex-row justify-between">
        <Header
          {...(showAction && {
            action: (
              <AddButton onClick={() => router.push('/employee/profile')} />
            ),
          })}
        >
          Education
        </Header>
      </div>
      <div className="space-y-8">
        {user_educations.map((item) => (
          <EducationCard {...item} key={item.id} card={false} />
        ))}
      </div>
    </div>
  );
};
