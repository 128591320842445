import { twMerge } from 'tailwind-merge';
import { ChangeEvent, FC, InputHTMLAttributes } from 'react';

export type InputProps = InputHTMLAttributes<Element> & {
  className?: string;
  onChange: (a: string) => void;
  value: string;
};

export const Input: FC<InputProps> = ({
  className,
  onChange,
  value,
  ...rest
}) => (
  <div>
    <input
      {...rest}
      type="number"
      className={twMerge(
        'monthpicker block w-full rounded border border-light py-4 pl-6 pr-10 text-base shadow-sm placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey',
        className
      )}
      value={value}
      min="1900"
      max="2100"
      onChange={(a: ChangeEvent<HTMLInputElement>) => {
        const n = a.target.value;
        if (value?.length === 4 && n?.length >= 4) return;
        if (n === '' && value?.length !== 1) return;
        onChange(n);
      }}
      placeholder="Year"
    />
  </div>
);
