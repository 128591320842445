import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { EmptyProps } from 'types/utils';
import { Modal, ModalProps } from 'components';

type ModalContextProps = {
  modal: (props: ModalProps) => void;
  dismiss: () => void;
};
const ModalContext = createContext({} as ModalContextProps);

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider: FC<PropsWithChildren<EmptyProps>> = ({
  children,
}) => {
  const [props, setProps] = useState<ModalProps>();

  const modal: ModalContextProps['modal'] = ({
    open = true,
    forceFocus = true,
    onClose: onCloseDefault,
    ...rest
  }) => {
    const onClose = () => {
      onCloseDefault && onCloseDefault();
      dismiss();
    };

    const props = { open, forceFocus, onClose, ...rest };
    setProps(props);
  };

  const dismiss = () => {
    // The modal closes with a 300ms transition. So, to avoid content flashing:
    // We first set it to close
    setProps((props) => ({ ...props, open: false }));
    // Then delay resetting the data
    setTimeout(() => setProps(undefined), 300);
  };

  return (
    <ModalContext.Provider value={{ modal, dismiss }}>
      {children}
      <Modal {...props} />
    </ModalContext.Provider>
  );
};
