import { VFC } from 'react';
import { SubscribeButton } from 'components/Subscriptions/SubscribeButton';

export const NoSubs: VFC = () => (
  <div className="space-y-6">
    <div className="my-2 text-center">
      You don't have any active subscriptions
    </div>
    <div className="space-y-2">
      <label className="mb-1 block text-sm uppercase tracking-wider text-grey">
        Available Subscriptions
      </label>
      <div className="flex flex-wrap items-center justify-between gap-4 rounded border border-light bg-white p-4 sm:flex-nowrap">
        <div className="flex shrink flex-col space-y-1">
          <div className="mr-2 text-2xl font-medium">Employer Page</div>
        </div>
        <div className="shrink-0">
          <SubscribeButton />
        </div>
      </div>
    </div>
  </div>
);
