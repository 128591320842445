import { VFC } from 'react';
import { useAuthContext } from 'components/AuthContext';
import { SaveAuth } from './SaveAuth';
import { SaveNoAuth } from './SaveNoAuth';

type Props = {
  job_id?: string;
};

export const SaveButton: VFC<Props> = (props) => {
  const { authenticated } = useAuthContext();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation(); // prevents drawer opening
        e.preventDefault(); // prevents Link route change
      }}
    >
      {authenticated ? <SaveAuth {...props} /> : <SaveNoAuth />}
    </div>
  );
};
