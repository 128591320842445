import React, { type VFC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Pill } from 'components';

export type PillManagerProps = {
  children: string;
};

export const PillManager: VFC<PillManagerProps> = ({ children }) => {
  const redColor =
    children === 'Withdrawn' || children === 'Rejected' || children === 'Draft';

  return (
    <Pill
      className={twMerge(
        redColor && 'text-error-500 bg-error-100/50',
        children === 'Applied' && 'text-blue-500 bg-blue-100/50',
        children === 'Hired' && 'text-green-500 bg-green-100',
        children === 'Shortlisted' && 'text-orange-500 bg-orange-100'
      )}
    >
      {children}
    </Pill>
  );
};
