import _isEqual from 'lodash.isequal';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ControllerFieldState } from 'react-hook-form';

type ErrorsType = Record<string, ControllerFieldState['error']>;

type ErrorContextProps = PropsWithChildren<{
  // temp any until we can pass args through to component
  errors: any;
  setErrors?: Dispatch<SetStateAction<ErrorsType>>;
}>;

export const ErrorContext = createContext<ErrorContextProps>({
  errors: {},
  setErrors: undefined,
});

export const ErrorProvider = ({
  children,
  errors: e = {},
  ...props
}: ErrorContextProps): JSX.Element => {
  const [errors, setErrors] = useState<ErrorsType>(e);
  useEffect(() => {
    if (!_isEqual(e, errors)) setErrors(e);
  }, [errors, e]);
  return (
    <ErrorContext.Provider {...props} value={{ errors }}>
      {children}
    </ErrorContext.Provider>
  );
};

type UseErrorResult = {
  error?: ControllerFieldState['error'];
};

export const useFieldError = (name?: string): UseErrorResult => {
  const { errors } = useContext(ErrorContext);
  const error = name ? errors?.[name] : undefined;
  return {
    error,
  };
};
