import { signInWithRedirect } from 'aws-amplify/auth';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import React, { ButtonHTMLAttributes } from 'react';
import { useJitsu } from '@jitsu/nextjs';
import { Facebook, Google } from 'components/icons';

export type UpdatedSocialButtonProps = ButtonHTMLAttributes<Element> & {
  signup?: boolean;
  variant: 'google' | 'facebook';
};

export const UpdatedSocialButton: React.FC<UpdatedSocialButtonProps> = ({
  className,
  signup = false,
  variant = 'google',
  ...props
}) => {
  const { track } = useJitsu();
  const { pathname, query } = useRouter();
  return (
    <button
      {...(signup && { id: `__social_register_${variant}` })}
      type="button"
      className={twMerge(
        'bg-white border border-solid border-[#D0D5DD] rounded-lg h-11 flex items-center justify-center',
        className
      )}
      onClick={() => {
        !pathname.startsWith('/auth') && !pathname.startsWith('/register')
          ? localStorage.setItem(
              '__federated_signin_origin',
              JSON.stringify({ pathname, query })
            )
          : localStorage.removeItem('__federated_signin_origin');
        // Hack this keeps complaining.
        track(`__federated_${signup ? 'signup' : 'login'}_event`, {
          origin: variant,
        });
        signInWithRedirect({
          provider: variant === 'google' ? 'Google' : 'Facebook',
        });
      }}
      {...props}
    >
      {variant === 'google' ? (
        <Google className="mr-3 size-6" />
      ) : (
        <Facebook fill="#1877F2" className="mr-3 size-6" />
      )}
      <p className="font-inter text-base font-semibold text-[#344054] ">
        {signup ? 'Sign up' : 'Log in'} with{' '}
        {variant === 'google' ? 'Google' : 'Facebook'}
      </p>
    </button>
  );
};
