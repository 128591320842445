import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useGetAnonEnumsQuery, Enum_Dial_Code, Maybe } from 'database/types';
import { useMessages } from 'components/Messages';
import useIsBrowser from 'utils/hooks/useIsBrowser';
import { useFieldError, Errors, InputProps, Select, InputLabel } from '..';

type Props = Omit<InputProps, 'onChange' | 'defaultValue'> & {
  defaultValue?: Maybe<string>;
  onChange: (a: string) => void;
};

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export function InputPhone({
  className,
  defaultValue,
  disabled,
  label,
  noValidation,
  required,
  onChange,
  placeholder = 'Enter a value',
  name,
}: Props): JSX.Element {
  const isBrowser = useIsBrowser();
  const [dc, setDialCode] = useState<Enum_Dial_Code['label']>('+64');
  const [phone, setPhone] = useState<string>('');
  const { data, loading } = useGetAnonEnumsQuery();
  const alert = useMessages();
  const { error } = useFieldError(name);

  useEffect(() => {
    if (loading || !defaultValue || !isBrowser) return;
    const a = data?.dial_code.find((i: any) =>
      defaultValue.startsWith(i.value)
    )?.value;
    if (!a) return;
    dc !== a && setDialCode(a);
    const b = defaultValue.slice(a.length);
    phone !== b && setPhone(b as string);
  }, [loading, defaultValue, isBrowser]);

  useEffect(() => {
    if (!isBrowser) return;
    const next = dc + phone;
    onChange(next);
  }, [dc, phone, isBrowser]);

  return (
    <div
      className={twMerge(
        'relative',
        noValidation ? '' : error ? 'mb-2' : 'mb-8',
        className
      )}
    >
      <InputLabel label={label} name={name} required={required} />
      <div className="grid w-full grid-cols-7 gap-2">
        {isBrowser && (
          <Select
            disabled={disabled}
            placeholder="+64"
            className="col-span-7 !mb-0 text-base sm:col-span-2"
            onChange={setDialCode}
            options={data?.dial_code}
            value={dc}
          />
        )}
        <input
          value={phone}
          type="tel"
          className={twMerge(
            'col-span-7 block rounded border border-light py-4 pl-6 pr-10 text-base shadow-sm placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey sm:col-span-5',
            error && 'border-red-500 focus:border-red-700 focus:ring-red-500',
            disabled && 'cursor-not-allowed bg-gray-200'
          )}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            if (e.target.value.startsWith('0'))
              return alert.alert({
                key: 'INPUT_PHONE',
                type: 'warning',
                message: 'leading 0s will be ignored',
                dismissable: true,
                duration: 3000,
              });
            setPhone(e.target.value);
          }}
        />
      </div>
      {!noValidation && <Errors error={error} />}
    </div>
  );
}
