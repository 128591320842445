import * as yup from 'yup';
import axios from 'axios';

export const schema = yup.object({
  firstName: yup.string().required('first name is required'),
  lastName: yup.string().optional(),
  company: yup.string().required('company is required'),
  role: yup.string().optional(),
  location: yup.string().optional(),
  integration: yup.string().optional(),
  requirements: yup.array(yup.string()).optional(),
  email: yup
    .string()
    .email('must be a valid email')
    .required('email is required'),
  phoneNumber: yup.string().required('phone is required'),
});

export interface ContactFormValues {
  firstName: string;
  lastName?: string;
  company: string;
  role?: string;
  location?: string;
  integration?: string;
  requirements?: string[];
  email: string;
  phoneNumber: string;
}

export async function onSubmit(values: ContactFormValues) {
  return axios.post(`/api/contact`, values);
}
