import { MessageType } from 'components';

const ALERT_RequestError: MessageType = {
  key: 'SUB_UPDATE_ERROR',
  title: 'Error Processing Your Request',
  type: 'error',
};
const ALERT_RenewalFailed: MessageType = {
  key: 'SUB_UPDATE_FAIL',
  title: 'Renewal Failed',
  type: 'error',
};
const ALERT_RenewalSuccess: MessageType = {
  key: 'SUB_UPDATED',
  title: 'Renewal Set Up',
  message:
    'Your subscription will be automatically renewed at the end of the current period.',
  duration: 10000,
  type: 'success',
};
const ALERT_CancelFailed: MessageType = {
  key: 'SUB_UPDATE_FAIL',
  title: 'Cancellation Failed',
  type: 'error',
};
const ALERT_CancelSuccess: MessageType = {
  key: 'SUB_UPDATED',
  title: 'Subscription Canceled',
  message:
    "Your subscription will last until the end date and won't be renewed",
  duration: 10000,
  type: 'success',
};
const ALERT_UpdateFailed: MessageType = {
  key: 'SUB_UPDATE_FAIL',
  title: 'Subscription Update Failed',
  type: 'error',
};
const ALERT_UpdateSuccess: MessageType = {
  key: 'SUB_UPDATED',
  title: 'Subscription Updated',
  type: 'success',
};

const ALERT_CreateFailed: MessageType = {
  key: 'SUB_UPDATE_FAIL',
  title: 'Subscription Failed',
  type: 'error',
};
const ALERT_CreateSuccess: MessageType = {
  key: 'SUB_UPDATED',
  title: 'Subscription Created!',
  type: 'success',
};

export {
  ALERT_CancelFailed,
  ALERT_CancelSuccess,
  ALERT_CreateFailed,
  ALERT_CreateSuccess,
  ALERT_RenewalFailed,
  ALERT_RenewalSuccess,
  ALERT_RequestError,
  ALERT_UpdateFailed,
  ALERT_UpdateSuccess,
};
