import { VFC } from 'react';

type TotalDiscountsProps = {
  discount?: number | null;
  title: string;
  subtitle?: string | null;
};

export const TotalDiscounts: VFC<TotalDiscountsProps> = ({
  discount,
  title,
}) => {
  const d = discount ?? 0;
  return d > 0 ? (
    <span className="flex flex-row items-center justify-between text-sm font-medium text-black">
      <div className="grow">{title}</div>
      <div className="flex flex-col text-right">
        <span>-${(d / 100).toFixed(2)}</span>
      </div>
    </span>
  ) : null;
};
