import { SVGAttributes } from 'react';

type EmailIconProps = SVGAttributes<SVGSVGElement>;

const EmailIcon = (props: EmailIconProps) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.66675 3.83398L8.47085 8.59685C9.02182 8.98254 9.29731 9.17538 9.59697 9.25007C9.86166 9.31605 10.1385 9.31605 10.4032 9.25007C10.7029 9.17538 10.9783 8.98254 11.5293 8.59685L18.3334 3.83398M5.66675 14.6673H14.3334C15.7335 14.6673 16.4336 14.6673 16.9684 14.3948C17.4388 14.1552 17.8212 13.7727 18.0609 13.3023C18.3334 12.7675 18.3334 12.0674 18.3334 10.6673V5.33398C18.3334 3.93385 18.3334 3.23379 18.0609 2.69901C17.8212 2.2286 17.4388 1.84615 16.9684 1.60647C16.4336 1.33398 15.7335 1.33398 14.3334 1.33398H5.66675C4.26662 1.33398 3.56655 1.33398 3.03177 1.60647C2.56137 1.84615 2.17892 2.2286 1.93923 2.69901C1.66675 3.23379 1.66675 3.93385 1.66675 5.33398V10.6673C1.66675 12.0674 1.66675 12.7675 1.93923 13.3023C2.17892 13.7727 2.56137 14.1552 3.03177 14.3948C3.56655 14.6673 4.26662 14.6673 5.66675 14.6673Z"
      stroke="#F17732"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmailIcon;
