import { FC, useEffect } from 'react';
import {
  UpdatedMonthPicker,
  UpdatedMonthPickerProps,
} from 'components/forms/UpdatedMonthPicker';
import { EducationData } from '.';

type Props = {
  complete?: boolean;
  resetField: (a: keyof EducationData) => void;
} & UpdatedMonthPickerProps;

// Make sure we don't remove the name prop from the rest of our properties
export const CustomInputDate: FC<Props> = ({
  complete,
  resetField,
  ...props
}) => {
  useEffect(() => {
    !complete && resetField(props?.name as keyof EducationData);
  }, [complete, resetField, props?.name]);

  return complete ? <UpdatedMonthPicker {...props} /> : null;
};
