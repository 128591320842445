import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import LinkDefault, { LinkProps as LinkPropsDefault } from 'next/link';

export type LinkProps = PropsWithChildren<
  Omit<LinkPropsDefault, 'href'> & {
    href?: LinkPropsDefault['href'];
    className?: string;
  }
>;

// we need to pass ...props to the underlyig <a> for headlessui
// https://headlessui.dev/react/menu#integrating-with-next-js
export const Link: FC<LinkProps> = ({
  className,
  href = '',
  children,
  scroll,
  ...props
}) => (
  <LinkDefault
    href={href}
    scroll={scroll ?? !!href}
    passHref
    className={twMerge(className)}
    {...props}
  >
    {children}
  </LinkDefault>
);
