// Third Parties
import { twMerge } from 'tailwind-merge';

// Types
import type { PropsWithChildren } from 'react';
type EmployerButtonProps = PropsWithChildren<{
  children: string;
  className?: string;
  size?: 'base' | 'lg';
  variant?: 'primary' | 'secondary' | 'tertiary';
}>;

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const EmployerButton = ({
  children,
  className,
  size = 'base',
  variant = 'primary',
  ..._rest
}: EmployerButtonProps): JSX.Element => {
  let colors = 'text-white bg-orange-500 border border-solid border-orange-400';
  let dimensions = 'h-9';
  let fontSize: 'sm' | 'base' = 'sm';

  if (variant === 'secondary') {
    colors = 'text-orange-500';
  } else if (variant === 'tertiary') {
    colors = 'text-black';
  }

  if (size === 'lg') {
    dimensions = 'h-12';
    fontSize = 'base';
  }

  return (
    <button
      className={twMerge(
        'rounded-full px-8 flex justify-center items-center w-max',
        dimensions,
        colors,
        className
      )}
      {..._rest}
    >
      <EmployerText as="p" size={fontSize}>
        {children}
      </EmployerText>
    </button>
  );
};
