import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Control, useWatch } from 'react-hook-form';
import { useRouter } from 'next/router';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { InputLabel, useFieldError, Errors } from 'components/forms';
import { useEmailExists } from 'components/forms/InputEmail/useEmailExists';
import { Link } from 'components/Link';
import { Loading } from 'components/icons';

export type InputEmailProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> & {
  control: Control<any>;
  className?: string;
  label?: ReactNode;
  name: string;
  noValidation?: boolean;
};

// Make sure to forward the name prop to the input as a 'name' prop otherwise forms will stop working
export const InputEmail = forwardRef<HTMLInputElement, InputEmailProps>(
  function InputRef(
    { control, className, disabled, label, noValidation, required, ..._props },
    ref
  ) {
    const { pathname, query } = useRouter();
    const value = useWatch({ control, name: _props.name });
    const { setEmail, loading, userExists } = useEmailExists();
    const { error } = useFieldError(_props?.name);

    useEffect(() => {
      setEmail(value);
    }, [value]);

    return (
      <div
        className={twMerge(
          'relative',
          noValidation ? '' : error ? 'mb-2' : 'mb-8',
          className
        )}
      >
        <InputLabel label={label} name={_props?.name} required={required} />
        <div className="relative">
          <input
            ref={ref}
            type="text"
            className={twMerge(
              'block w-full rounded border border-light py-4 pl-6 pr-10 text-base text-black shadow-sm placeholder:text-grey focus:border-black focus:ring-1 focus:ring-grey',
              error && 'border-red-500 focus:border-red-700 focus:ring-red-500',
              disabled && 'cursor-not-allowed bg-gray-200'
            )}
            disabled={disabled}
            {..._props}
          />
          {loading && (
            <Loading className="absolute right-4 top-4 self-center" />
          )}
        </div>
        {!noValidation && <Errors error={error} />}
        {userExists && (
          <div className="mt-2 flex flex-row items-center space-x-1">
            <InformationCircleIcon className="size-5 text-primary" />
            <span className="relative text-sm text-grey">
              A user already exists with this email address.{' '}
              <Link
                className="font-medium text-primary hover:underline"
                href={{ pathname, query, hash: 'login' }}
              >
                Click here to sign in
              </Link>
            </span>
          </div>
        )}
      </div>
    );
  }
);
