import { FC, PropsWithChildren, ReactChild } from 'react';
import { twMerge } from 'tailwind-merge';
import { Pill } from 'components';

export type PricingCardProps = PropsWithChildren<{
  id: string;
  price: string | number;
  title: ReactChild;
  active?: boolean;
  onClick?: () => void;
}>;

export const PricingCard: FC<PricingCardProps> = ({
  title,
  active,
  price,
  children,
  onClick,
}) => (
  <div
    className={twMerge(
      'overflow-hidden rounded bg-white px-4 py-6 shadow sm:px-6 sm:py-8',
      active && 'ring-1 ring-black'
    )}
    onClick={onClick}
  >
    <div className="space-y-2">
      <div className="flex min-h-12 items-start justify-between space-x-4">
        <span className="text-3xl font-medium">
          {typeof price === 'string'
            ? price
            : price.toLocaleString('en-NZ', {
                style: 'currency',
                currency: 'NZD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
        </span>
        {active && (
          <Pill className="!bg-black !text-base !font-normal !text-white">
            Selected
          </Pill>
        )}
      </div>
      <h3 className="text-xl font-medium">{title}</h3>

      {children}
    </div>
  </div>
);
