import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';
import { signUp } from 'aws-amplify/auth';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import { useJitsu } from '@jitsu/nextjs';
import { useEffect, useState } from 'react';
import { Button, Link } from 'components';
import {
  Checkbox,
  ErrorProvider,
  Input,
  InputPhone,
  RegisterData,
} from 'components/forms';
import { useMessages } from 'components/Messages';
import { Maybe } from 'database/types';
import Validation from './Validation';

export type RegisterEmployerData = RegisterData & {
  company: string;
};

export type RegisterEmployerProps = {
  setUserInfo: React.Dispatch<any>;
};

function initDefaults({
  company = '',
  email = '',
  first_name = '',
  last_name = '',
  phone = '',
}: Record<string, string> = {}): RegisterEmployerData {
  return {
    company,
    email,
    first_name,
    last_name,
    password: '',
    phone,
    terms: false,
  };
}

export const RegisterEmployer = ({
  setUserInfo,
}: RegisterEmployerProps): Maybe<JSX.Element> => {
  const { pathname, query, isReady } = useRouter();
  const { alert } = useMessages();
  const { track } = useJitsu();
  const [isSet, setIsSet] = useState<boolean>(false);

  const DEFAULT_VALUES = initDefaults();

  const { control, formState, handleSubmit, setValue, register, watch } =
    useForm<RegisterEmployerData>({
      defaultValues: { ...DEFAULT_VALUES },
      resolver: yupResolver(Validation) as any,
    });

  useEffect(() => {
    if (!isReady || isSet) return;
    if (query) {
      const values = initDefaults(query as Record<string, string>);
      Object.keys(values).forEach((k) => {
        const value = values[k as keyof RegisterEmployerData];
        value && setValue(k as any, value);
      });
    }
    setIsSet(true);
  }, [isReady, isSet, query]);

  const onSubmit = async ({
    email,
    password,
    first_name,
    last_name,
    phone,
    company,
  }: RegisterEmployerData) => {
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            given_name: first_name,
            family_name: last_name,
            phone_number: phone,
            'custom:company': company,
          },
        },
      });

      track('employer_register', {
        email,
        first_name,
        last_name,
        phone,
        company,
      });

      setUserInfo({ username: email, password, complete: true });
    } catch (error) {
      if (error instanceof Error) {
        alert({
          key: 'Registration',
          type: 'error',
          dismissable: true,
          title: 'Issue with registration',
          message: error.message,
        });
      }
      captureException(error);
    }
  };

  const termsAccepted = watch('terms');

  return (
    <div>
      <form
        id="register-employer"
        onSubmit={handleSubmit(onSubmit)}
        className="my-6"
      >
        <ErrorProvider errors={formState.errors}>
          <div className="grid grid-cols-12 gap-x-4">
            <div className="col-span-12 md:col-span-5">
              <Input
                {...register('first_name')}
                autoFocus
                required
                label="First name"
                placeholder="First name"
              />
            </div>
            <div className="col-span-12 md:col-span-7">
              <Input
                {...register('last_name')}
                required
                label="Last name"
                placeholder="Last name"
              />
            </div>
          </div>
          <Input
            {...register('company')}
            required
            label="Company Name"
            placeholder="Company name"
          />

          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <InputPhone
                required
                value={field.value}
                label="Phone"
                onChange={(a: string) => field.onChange(a)}
              />
            )}
          />
          <Input
            {...register('email')}
            required
            autoCapitalize="false"
            label="Email address"
            placeholder="Email address"
          />
          <Input
            {...register('password')}
            required
            autoCorrect="false"
            autoCapitalize="false"
            type="password"
            label="Password"
            placeholder="Password"
          />
          <Checkbox
            {...register('terms')}
            label={
              <>
                By clicking Register you are indicating that you have read and
                acknowledged the{' '}
                <Link
                  className="whitespace-nowrap text-gray-900 underline hover:text-gray-600"
                  href="/terms"
                >
                  "Terms of Service"
                </Link>{' '}
                and{' '}
                <Link
                  className="whitespace-nowrap text-gray-900 underline hover:text-gray-600"
                  href="/privacy"
                >
                  "Privacy Policy"
                </Link>
                .
              </>
            }
          />
          <div className="mt-6 text-center">
            <Button
              id="__register_employer"
              className="mt-6 text-center"
              type="submit"
              color="black"
              disabled={!termsAccepted || formState.isSubmitting}
              loading={formState.isSubmitting}
            >
              Register
            </Button>
          </div>
        </ErrorProvider>
      </form>
      <p className="mt-6 text-center text-gray-500">
        Already have an account?{' '}
        <Link
          href={{
            pathname,
            query,
            hash: 'login',
          }}
          className="text-primary underline hover:text-primary-600"
        >
          Sign in
        </Link>
      </p>
    </div>
  );
};
