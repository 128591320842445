import { captureException } from '@sentry/nextjs';
import { getUrl } from 'aws-amplify/storage';
import { twMerge } from 'tailwind-merge';
import { useEffect, useState, VFC } from 'react';
import { useAuthContext } from 'components/AuthContext';
import { Maybe, useGetUserByPkQuery } from 'database/types';

export type AvatarProps = {
  alt?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  aws_key?: Maybe<string>;
};

export const Avatar: VFC<AvatarProps> = ({
  alt = '',
  size = 'medium',
  aws_key,
}) => {
  const { user } = useAuthContext();
  const { data } = useGetUserByPkQuery({
    variables: { id: user?.sub ?? '' },
    skip: !!aws_key ?? !user?.sub,
  });
  const profile_photo = aws_key ?? data?.user_by_pk?.profile_photo;

  const [url, setUrl] = useState<string>('/assets/images/default_avatar.jpg');
  const [key, setKey] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading || !profile_photo || profile_photo === key) return;
    setLoading(true);
    (async () => {
      try {
        const { url } = await getUrl({
          key: profile_photo,
          options: { accessLevel: 'guest' },
        });
        setUrl(url.toString());
      } catch (err) {
        setUrl(profile_photo);
        captureException(err);
      } finally {
        setKey(profile_photo);
        setLoading(false);
      }
    })();
  }, [setUrl, loading, setLoading, profile_photo, url, key, setKey]);

  return (
    <img
      className={twMerge(
        'inline-block',
        'size-24',
        size === 'tiny' && 'size-9',
        size === 'small' && 'size-14',
        size === 'large' && 'size-36',
        'rounded-full ring-2 ring-white'
      )}
      src={url}
      alt={alt}
    />
  );
};
