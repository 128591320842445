import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';
import { Link } from 'components';

type StyledLinkProps = PropsWithChildren<{
  url: string;
  icon: any;
  className?: string;
  disabled?: boolean;
  matches?: (string | RegExp)[];
}>;

export const PortalLink: FC<StyledLinkProps> = ({
  url,
  icon: Icon,
  className,
  children,
  disabled = false,
  matches,
}) => {
  const router = useRouter();
  const { pathname } = router;

  const active =
    pathname === url ||
    matches?.some((value) =>
      typeof value === 'string' ? value === pathname : value.test(pathname)
    );

  if (disabled) {
    return (
      <span
        className={twMerge(
          'inline-flex w-full min-w-max cursor-not-allowed items-center px-5 py-3 text-center text-charcoal-200 sm:px-8',
          className
        )}
      >
        <Icon color="#8d8d8c" stroke="#8d8d8c" className="mr-3 size-5" />
        <span className="hidden md:flex">{children}</span>
      </span>
    );
  }

  return (
    <Link
      className={twMerge(
        'group inline-flex w-full min-w-max items-center px-5 py-3 text-center hover:bg-grey-light sm:px-8',
        className,
        active ? 'text-orange-500' : 'text-charcoal-500'
      )}
      href={url}
    >
      <Icon
        color={active ? '#F17732' : '#1c1b19'}
        stroke={active ? '#F17732' : '#1c1b19'}
        className="mr-3 size-5"
      />
      <span className="hidden md:flex">{children}</span>
    </Link>
  );
};

export const MobileLink: FC<StyledLinkProps> = ({ className, ...props }) => (
  <PortalLink
    {...props}
    className={twMerge(
      '!sm:px-1 !w-32 !min-w-[128px] justify-center place-self-center !px-1',
      className
    )}
  />
);
