import React, { VFC } from 'react';
import { Popover } from '@headlessui/react';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import { Link, LinkButton } from 'components';
import { LogoEmployer } from 'components/LogoEmployer';
import { NavbarItemProps } from 'components/Navbar/Navbar';
import { NavLink } from 'components/Navbar/NavLink';
import { UserMenu } from 'components/Navbar/UserMenu';

export const DesktopNavEmployer: VFC<NavbarItemProps> = ({
  authenticated,
  className,
}) => {
  const { query, pathname } = useRouter();
  const { employerId } = query;
  return (
    <header className={twMerge('relative w-full bg-black', className)}>
      <div className="mx-auto flex items-center justify-between space-x-4 px-8 py-4 xl:px-16">
        {/* Logo */}
        <Link className="h-10 max-h-10" showActive={false} href="/portal">
          <span className="sr-only">Employer Portal</span>
          <LogoEmployer />
        </Link>
        {/* Desktop items */}
        <Popover.Group as="nav" className="flex items-center space-x-8">
          {!authenticated && (
            <>
              <NavLink
                showActive={false}
                href={{ pathname, query, hash: 'login' }}
              >
                Login
              </NavLink>
            </>
          )}

          {authenticated && (
            <NavLink
              href={{
                pathname: `/portal${employerId ? '/[employerId]' : ''}/jobs`,
                query,
              }}
            >
              Jobs
            </NavLink>
          )}

          {authenticated ? (
            <UserMenu type="employer" />
          ) : (
            <>
              <NavLink className="py-3" href="/portal/register">
                Register
              </NavLink>
              <LinkButton
                href={`${process.env.NEXT_PUBLIC_SITE_URL}`}
                className="py-3"
                buttonProps={{ color: 'white', size: 'small' }}
              >
                Main site
              </LinkButton>
            </>
          )}
        </Popover.Group>
      </div>
    </header>
  );
};
