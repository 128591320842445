// Framework
import { useEffect, useRef, useState } from 'react';

// Third Parties
import type { LottiePlayer } from 'lottie-web';

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const LottieJobsWeek = () => {
  const lottieTarget = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && lottieTarget.current) {
      const animation = lottie.loadAnimation({
        container: lottieTarget.current,
        renderer: 'canvas' as any,
        loop: true,
        autoplay: false, // Changed to false to not autoplay
        path: '/assets/images/portal/WeWriteJob_Lottie.json',
      });

      lottieTarget.current.addEventListener('mouseenter', () => {
        animation.play();
      });

      lottieTarget.current.addEventListener('mouseleave', () => {
        animation.pause();
      });

      animation.setSubframe(false);

      return () => animation.destroy();
    }
  }, [lottie]);

  return <div className="h-auto w-full lg:h-[264px]" ref={lottieTarget}></div>;
};
