import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'components';
import { Job_Question, Maybe, ApplyForJobDocument } from 'database/types';
import { useFormSubmit } from 'utils';
import { WorkExperienceBody } from 'components/forms/WorkExperience/FormBody';
import { ErrorProvider, InputPhone, RadioBoolean } from '..';
import makeValidation from './Validation';

const DEFAULT_EXPERIENCE = {
  company: '',
  current: true,
  description: '',
  end_date: null,
  start_date: null,
  title: '',
};

const formatValues = ({
  job_id,
  application: ap,
  user_experience: ux,
  user_id,
  phone,
}: any): ApplicationQuestionsValues => ({
  application: {
    ...(ap && {
      id: ap.id,
      answers: ap.answers ?? {},
      status: 'applied',
    }),
    job_id,
  },
  user_experience: ux
    ? {
        ...DEFAULT_EXPERIENCE,
        id: ux.id,
        company: ux.company,
        current: ux.current,
        description: ux.description,
        end_date: ux.end_date,
        start_date: ux.start_date,
        title: ux.title,
      }
    : DEFAULT_EXPERIENCE,
  user_id,
  phone,
});

export type ApplicationQuestionsValues = {
  application: {
    id?: Maybe<string>;
    job_id?: Maybe<string>;
    answers: Record<string, boolean>;
  };
  user_experience: {
    id?: Maybe<string>;
    title: Maybe<string>;
    description: Maybe<string>;
    company: Maybe<string>;
    current: boolean;
    start_date: Maybe<string>;
    end_date: Maybe<string>;
  };
  user_id: string;
  phone: string;
};

export type ApplicationQuestionProps = {
  job_id: string;
  questions?: Pick<Job_Question, 'id' | 'job_id' | 'type' | 'question'>[];
  defaultValues: ApplicationQuestionsValues;
  callback: () => void;
  back: () => void;
};

export const ApplicationQuestions: FC<ApplicationQuestionProps> = ({
  back,
  callback,
  defaultValues,
  job_id,
  questions = [],
}) => {
  const isUpdate = !!defaultValues?.application?.id;
  const resolver = yupResolver(makeValidation(questions));
  const { control, formState, handleSubmit, register, setValue } = useForm({
    resolver: resolver as any,
    defaultValues: formatValues({ ...defaultValues, job_id }),
  });

  const onSubmit = useFormSubmit({
    formName: 'EmployerQuestions',
    mutation: ApplyForJobDocument,
    onSuccess: {
      message: `Application ${isUpdate ? 'updated' : 'submitted'}`,
      callback,
    },
  });
  return (
    <form
      id="application_questions"
      onSubmit={handleSubmit((vars) => onSubmit(vars))}
      className="my-4"
    >
      <ErrorProvider errors={formState.errors as any}>
        {questions?.map(({ id, question }) => (
          <RadioBoolean
            control={control}
            name={`application.answers.${id}`}
            key={id}
            label={question}
          />
        ))}
        <div className="my-4 text-[28px] font-medium leading-9 tracking-tight">
          Recent Experience
        </div>
        <WorkExperienceBody
          control={control}
          register={register}
          setValue={setValue}
          prefix="user_experience."
        />
        <Controller
          control={control}
          name="phone"
          render={({ field }) => (
            <InputPhone
              required
              label="Contact number"
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              defaultValue={defaultValues.phone}
            />
          )}
        />
        <div className="flex justify-end">
          <Button
            type="button"
            className="mr-2 px-12"
            color="white"
            onClick={back}
          >
            Back
          </Button>
          <Button className="px-12" type="submit" color="black">
            Next
          </Button>
        </div>
      </ErrorProvider>
    </form>
  );
};
