import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Maybe } from 'database/types';

const CMSClient = (
  previewToken: Maybe<string> = null
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: previewToken
      ? 'https://cms.tradejobsnz.co.nz/api?token=' + previewToken
      : 'https://cms.tradejobsnz.co.nz/api',
  });

  const token = process.env.CMS_TOKEN;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  return new ApolloClient({
    ssrMode: true,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default CMSClient;
