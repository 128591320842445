import { useEffect } from 'react';
import { useJitsu } from '@jitsu/nextjs';
import { Job } from 'database/types';

type JobViewTrackProps = {
  id: string;
  listing_type: Job['listing_type'];
  view_type?: 'impression' | 'intent';
};

export function useJobViewTrack({
  id,
  listing_type,
  view_type = 'impression',
}: JobViewTrackProps): null {
  const { track } = useJitsu();

  useEffect(() => {
    track('job_view', {
      job_id: id,
      listing_type,
      view_type,
    });
  }, []);

  return null;
}
