import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export type PaginationTileProps = PropsWithChildren<{
  active?: boolean;
  disabled?: boolean;
  arrow?: boolean;
}>;

export const PaginationTile: FC<PaginationTileProps> = ({
  active,
  disabled,
  children,
  arrow,
}) => (
  <div
    className={twMerge(
      'relative mx-1 inline-flex h-8 w-8 min-w-min items-center justify-center rounded border border-charcoal-100 px-2 sm:h-12 sm:w-12 sm:text-lg text-charcoal-500',
      active
        ? 'bg-orange-50 border-orange-500'
        : disabled
          ? 'border-none bg-transparent'
          : 'bg-white hover:border-charcoal-500 hover:bg-grey-light',
      arrow && 'border-none'
    )}
  >
    {children}
  </div>
);
