import { cloneElement, FC, ReactElement, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { AlertIcon } from './AlertIcon';
import { Actions } from './Actions';

export type AlertProps = {
  actions?: ReactElement;
  className?: string;
  size?: 'small' | 'large';
  dismiss?: () => void;
  dismissable?: boolean;
  message?: string;
  type?: 'error' | 'info' | 'warning' | 'success' | 'primary';
  title: React.ReactNode;
};

const AlertInternal: FC<AlertProps> = ({
  actions = null,
  className,
  dismiss,
  dismissable,
  message,
  size,
  type,
  title,
}) => {
  const [dismissed, setDismiss] = useState<boolean>(false);
  const onDismiss = () => {
    if (dismiss) {
      dismiss();
      return;
    }
    setDismiss(true);
  };
  if (!title) return null; // Hide component if title is empty! DNR
  return (
    <div
      className={twMerge(
        'my-4 rounded-md p-4',
        dismissed && 'hidden',
        type === 'success'
          ? 'bg-green-50'
          : type === 'error'
            ? 'bg-red-50'
            : type === 'warning'
              ? 'bg-yellow-50'
              : type === 'info'
                ? 'bg-blue-50'
                : type === 'primary'
                  ? 'bg-primary'
                  : 'bg-gray-50',
        className
      )}
    >
      <div className="flex items-center">
        <div
          className={twMerge(
            'grow',
            !(type || type === 'primary') && 'ml-3',
            (dismissable || actions) && 'mr-3'
          )}
        >
          <div className="flex flex-row items-center space-x-2">
            {type && (
              <div className="shrink-0">
                <AlertIcon type={type} />
              </div>
            )}
            <h3
              className={twMerge(
                size === 'small'
                  ? 'text-sm font-normal'
                  : size === 'large'
                    ? 'text-base font-medium'
                    : 'text-base font-medium',
                type === 'success'
                  ? 'text-green-800'
                  : type === 'error'
                    ? 'text-red-800'
                    : type === 'warning'
                      ? 'text-yellow-800'
                      : type === 'info'
                        ? 'text-blue-800'
                        : type === 'primary'
                          ? 'text-white'
                          : 'text-gray-800'
              )}
            >
              {title}
            </h3>
          </div>
          {message && (
            <div
              className={twMerge(
                'mt-2 text-sm',
                type === 'success'
                  ? 'text-green-700'
                  : type === 'error'
                    ? 'text-red-700'
                    : type === 'warning'
                      ? 'text-yellow-700'
                      : type === 'info'
                        ? 'text-blue-700'
                        : type === 'primary'
                          ? 'text-primary-100'
                          : 'text-gray-700'
              )}
            >
              {message}
            </div>
          )}
        </div>
        <div
          className={twMerge(
            'flex shrink flex-col',
            dismissable ? 'justify-between' : 'justify-end'
          )}
        >
          {dismissable && (
            <div className="pl-3">
              <div className="-m-1.5">
                <button
                  type="button"
                  className={twMerge(
                    'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
                    type === 'success'
                      ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
                      : type === 'error'
                        ? 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50'
                        : type === 'warning'
                          ? 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50'
                          : type === 'info'
                            ? 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50'
                            : type === 'primary'
                              ? 'bg-primary-100 text-primary-500 hover:bg-primary-100 focus:ring-primary-600 focus:ring-offset-primary-100'
                              : 'bg-gray-50 text-gray-500 hover:bg-gray-100 focus:ring-gray-600 focus:ring-offset-gray-50'
                  )}
                  onClick={onDismiss}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          )}
          {actions ? cloneElement(actions, { type }) : null}
        </div>
      </div>
    </div>
  );
};

type AlertInternalType = typeof AlertInternal;
interface AlertInterface extends AlertInternalType {
  Actions: typeof Actions;
}
const Alert = AlertInternal as AlertInterface;
Alert.Actions = Actions;

export { Alert };
