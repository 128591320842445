import type { GetEmployeeProfileQuery } from 'database/types';
import Markdown from 'markdown-to-jsx';

import { Maybe } from 'database/types';

type ProfileEditAboutProps = {
  userData?: GetEmployeeProfileQuery['user_by_pk'];
};

function ProfileEditAbout({
  userData,
}: ProfileEditAboutProps): Maybe<JSX.Element> {
  if (!userData) return null;

  const charCount = (text: string = ''): boolean => {
    const stripHtml = text.replace(/<[^>]*>/g, '');
    return stripHtml.length >= 2;
  };

  const aboutText = userData?.about ?? '';

  return (
    <div>
      <div className="relative z-0">
        {charCount(aboutText) && (
          <>
            <p className="mb-4 text-lg font-bold -tracking-two-percent text-charcoal-300">
              About
            </p>
            <Markdown className="prose mb-6 font-medium -tracking-two-percent text-charcoal-500">
              {aboutText}
            </Markdown>
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileEditAbout;
