import * as yup from 'yup';
import { dateRegEx } from '..';

const Validation = yup.object({
  title: yup.string().required('title is required'),
  organisation: yup.string().required('organisation name is required'),
  issue_date: yup
    .string()
    .required('issue date is required')
    .matches(dateRegEx, 'not a valid date'),
  expires: yup.boolean().required('please select an option'),
  expiry_date: yup
    .string()
    .nullable()
    .matches(dateRegEx, 'not a valid date')
    .test(
      'expirty_date_valid',
      'expirty date is required',
      (value, { parent }) => !parent.expires || !!value
    ),
});

export default Validation;
