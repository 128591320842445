import { FC } from 'react';
import { Stripe } from 'stripe';

import { CartLine } from 'components/Cart/CartLine';
import { DiscountLine } from 'components/Cart/DiscountLine';
import { TotalDiscounts } from 'components/Cart/TotalDiscounts';
import { InvoiceItem } from 'components/Cart/InvoiceItem';

type Props = Stripe.Invoice;

export const Invoice: FC<Props> = ({
  discounts,
  lines,
  subtotal,
  total,
  total_discount_amounts,
  tax,
}) => {
  const d = total_discount_amounts?.reduce((r, e) => (r += e.amount), 0);
  return (
    <div className="space-y-2">
      {lines?.data.map((lineItem) => {
        const { description, id, price } = lineItem;
        return (
          <InvoiceItem
            key={id}
            title={description as string}
            subtitle={price?.nickname as string}
            {...lineItem}
          />
        );
      })}
      {(discounts as Stripe.Discount[])?.map((discount, i) => (
        <DiscountLine key={i} {...discount} />
      ))}
      <div className="w-full border-t border-gray-300" />
      <CartLine title="Subtotal" price={subtotal} />
      <TotalDiscounts title="Discounts" discount={d} />
      <div className="w-full border-t border-gray-300" />

      <CartLine alt title="GST (15%)" price={tax} />
      <div className="w-full border-t border-gray-300" />

      <CartLine title="Total" price={total} />
    </div>
  );
};
