import { VFC } from 'react';
import { useRouter } from 'next/router';
import { SavedJobIcon } from 'components/icons';
import { Link, useMessages } from '../..';

export const SaveNoAuth: VFC = () => {
  const { pathname, query } = useRouter();
  const { alert } = useMessages();

  const handleClick = () => {
    alert({
      key: 'SAVE_NO_AUTH',
      duration: 5000,
      dismissable: true,
      title: (
        <>
          <Link
            href={{
              pathname,
              query,
              hash: 'login',
            }}
          >
            <span className="text-secondary-500">Sign in</span>
          </Link>{' '}
          or{' '}
          <Link href="/register">
            <span className="text-secondary-500">Register</span>
          </Link>{' '}
          to use the save function
        </>
      ),
    });
  };
  return (
    <div className="shrink-0">
      <button
        className="inline-flex items-center justify-start rounded-lg bg-orange-50 px-4 py-2 text-orange"
        onClick={handleClick}
        aria-label="Save job"
      >
        <SavedJobIcon stroke="#F17732" className="h-5" />
        <span className="ml-2 whitespace-nowrap">Save job</span>
      </button>
    </div>
  );
};
