import React, { useCallback, VFC } from 'react';
import { Pill, Table, TableProps } from 'components';
import { EmployerUsersMenu } from 'components/pages/PortalEmployer';
import { GetEmployerUsersQuery } from 'database/types';

export type TableEmployerUsersRowData = [
  string,
  string,
  // hide access_level for now
  // string
];

export type UsersRow = NonNullable<GetEmployerUsersQuery['employer_user']>;

export type TableEmployerUsersProps = Pick<TableProps, 'loading' | 'empty'> & {
  users?: UsersRow;
};

export const TableEmployerUsers: VFC<TableEmployerUsersProps> = ({
  users,
  ...props
}) => {
  const headers = ['Name', 'Surname', 'Email', 'Phone', 'Access Level'];

  const makeTableRows = useCallback(
    () =>
      ({ users = [] }: TableEmployerUsersProps): TableProps['rows'] =>
        users?.map(
          ({
            id,
            access_level: { label },
            user: { email, first_name, last_name, phone },
          }) => ({
            id,
            data: [
              first_name,
              last_name,
              email,
              phone ?? '–',
              <Pill key={id}>{label}</Pill>,
            ],
            menuElement: <EmployerUsersMenu {...{ id, email }} />,
          })
        ),
    []
  );

  return (
    <Table
      {...props}
      headers={headers}
      rows={makeTableRows()({ users: users })}
    />
  );
};
