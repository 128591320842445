import {
  LocationClient,
  Place,
  SearchPlaceIndexForTextCommand,
  SearchPlaceIndexForTextCommandInput,
} from '@aws-sdk/client-location';
import { captureException } from '@sentry/nextjs';
import { Location } from 'database/types';
import { isNonNullish } from 'types/utils';

const AWSLocationClient = new LocationClient({
  region: 'ap-southeast-2',
  logger: console,
  credentials: {
    accessKeyId: process.env.ACCESS_KEY_ID as string,
    secretAccessKey: process.env.SECRET_ACCESS_KEY as string,
  },
});

export const getAWSLocationData = async (
  text: string,
  options?: Partial<SearchPlaceIndexForTextCommandInput>
): Promise<Place[]> => {
  try {
    const { Results } = await AWSLocationClient.send(
      new SearchPlaceIndexForTextCommand({
        Text: text,
        IndexName: 'trade-jobs-nz-here',
        MaxResults: 5,
        Language: 'en',
        ...options,
      })
    );

    if (!Results) return [];

    return Results.map(({ Place }) => Place).filter(isNonNullish);
  } catch (e) {
    captureException(e);
  }
  return [];
};

export type AWSPlace = Place;

export const mapPlaceToLocation = ({
  Geometry,
  AddressNumber,
  Country,
  Label,
  Municipality,
  Neighborhood,
  PostalCode,
  Region,
  Street,
  SubRegion,
}: Place): Partial<Location> => ({
  label: Label,
  number: AddressNumber,
  street: Street,
  neighbourhood: Neighborhood,
  municipality: Municipality,
  district: SubRegion,
  region: Region,
  country: Country,
  postal_code: PostalCode,
  geometry: Geometry?.Point
    ? {
        type: 'Point',
        coordinates: Geometry?.Point as [number, number],
      }
    : undefined,
});
