import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { captureException } from '@sentry/nextjs';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Location } from 'database/types';

type LocationAPIHook = {
  loading: boolean;
  error?: Error;
  data: Partial<Location>[];
  searchTerm: Maybe<string>;
  setSearchTerm: Dispatch<SetStateAction<Maybe<string>>>;
  MIN_TERM_LENGTH: number;
  setFilterCountries?: Dispatch<SetStateAction<boolean>>;
};

type Options = {
  limit?: number;
  initialValue?: string;
  filterCountries?: boolean;
};

export const useLocationAPI = ({
  limit = 5,
  initialValue = '',
  filterCountries = true,
}: Options = {}): LocationAPIHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Location[]>([]);
  const [error, setError] = useState<Error | undefined>();
  const [searchTerm, setSearchTerm] = useState<Maybe<string>>(initialValue);
  const [completed, setCompleted] = useState<string>('');
  const MIN_TERM_LENGTH = 5;

  useEffect(() => {
    if (
      loading ||
      !searchTerm ||
      searchTerm.length < MIN_TERM_LENGTH ||
      completed === searchTerm ||
      (data?.[0] && searchTerm === data[0].label)
    )
      return;

    const debounced = _.debounce(
      async () => {
        setLoading(true);
        try {
          const { data } = await axios.request({
            url: `${process.env.NEXT_PUBLIC_SITE_URL}/api/location/${searchTerm}`,
            params: {
              limit,
              filterCountries,
            },
          });
          setData(data.data);
        } catch (err) {
          if (err instanceof Error) {
            setError(err);
          }
          captureException(err);
        } finally {
          setLoading(false);
          setCompleted(searchTerm);
        }
      },
      300,
      { trailing: true }
    );
    debounced();
    return debounced.cancel;
  }, [loading, searchTerm, completed, data]);

  return {
    loading,
    data,
    error,
    searchTerm,
    setSearchTerm,
    MIN_TERM_LENGTH,
  };
};
