import { ImageProps } from 'next/image';
import { Maybe } from 'database/types';

// import BottomBar from 'components/forms/employer/JobFormPost/SimpleJobFormCart/BottomBar';
import { Products } from 'components/Products';

export interface Bundle {
  id: string;
  features?: Array<string>;
  price: number;
  description: string;
  recommended?: boolean;
  checkout?: boolean;
  subtitle?: Maybe<string>;
  title: string;
  info: Array<{
    id: string;
    title: string;
    description: string;
    image: [ImageProps];
  }>;
}

export type SmallTabProps = {
  bundles: Array<Bundle>;
};

export function SmallTab({ bundles }: SmallTabProps): JSX.Element {
  const selectedId = bundles.find((i) => i.recommended)?.id ?? '';
  return (
    <div className="space-y-16">
      <Products bundles={bundles} selectedId={selectedId} />
    </div>
  );
}
