import { FC, PropsWithChildren, ReactChild } from 'react';
import { twMerge } from 'tailwind-merge';

export type TableCellProps = PropsWithChildren<{
  loading?: boolean;
  children?: ReactChild;
  className?: string;
}>;

export const TableCell: FC<TableCellProps> = ({
  loading,
  className,
  children,
}) => {
  if (loading)
    return (
      <td className={twMerge('h-5 px-6 py-4', className)}>
        <span className="skeleton-box inline-block size-full rounded" />
      </td>
    );

  return (
    <td
      className={twMerge(
        'min-h-[68px] truncate whitespace-nowrap rounded px-6 py-4 text-sm',
        className
      )}
    >
      {children ?? '-'}
    </td>
  );
};
