'use client';
// Framework
import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

// Components
import { EmployerText } from 'components/selfService/typography/EmployerText';
import { EmployerButton } from 'components/selfService/EmployerButton';
import { MoveUpAnimation } from 'components/selfService/EmployerHero/MoveUpAnimation';

import { Section } from '../Section';

import { BackgroundPattern } from './BackgroundPattern';
import CheckMark from './CheckMark';
/*--------------------------------------------------------------------*/

/**
 * Component
 */

export function EmployerHero(): JSX.Element {
  const [email, setEmail] = useState('');
  return (
    <Section>
      <div className="absolute bottom-0 left-1/2 z-10 min-h-[802px] max-w-full -translate-x-1/2 overflow-hidden">
        <BackgroundPattern className="absolute inset-0 z-0 h-[802px] w-[1038px]" />
      </div>
      <div className="relative z-30 mx-auto flex w-full max-w-screen-2xl flex-col justify-between pb-0 md:flex-row md:pb-24">
        <div className="order-2 flex w-full flex-col justify-center gap-4 sm:pl-4 md:order-1 md:w-1/2 md:pr-16 2xl:pl-0">
          <div className="w-11/12">
            <EmployerText
              as="h2"
              size="xl"
              className="flex gap-1.5 text-orange-500"
            >
              <span className="flex items-start justify-center pt-1.5">
                <CheckMark className="rounded-full bg-orange-500 p-[2px]" />
              </span>
              <MoveUpAnimation />
            </EmployerText>
            <EmployerText as="h1" size="7xl" className="my-4 text-charcoal-500">
              Quicker, better, trades recruitment.
            </EmployerText>
            <EmployerText
              as="p"
              size="lg"
              className="font-normal text-charcoal-400"
            >
              Trade Jobs NZ is the trades exclusive search solution that Tradies
              themselves trust and believe in. It's trades recruitment done
              right.
            </EmployerText>
          </div>

          <div className="flex w-full max-w-md flex-col space-y-4">
            <div className="flex w-full flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full grow rounded-full border border-gray-300 px-4 py-2"
                onChange={(e) => {
                  const email = e.target.value;
                  setEmail(email);
                }}
              />
              <Link
                href={`/portal/register?email=${encodeURIComponent(email)}`}
                passHref
              >
                <EmployerButton
                  size="lg"
                  className="w-full max-w-md sm:mx-0 md:shrink"
                >
                  Register
                </EmployerButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="order-1 mb-8 flex w-full justify-end pl-4 md:order-2 md:mb-0 md:w-1/2 md:pl-0">
          <div className="relative z-0 h-[280px] w-[712px] overflow-hidden md:h-[562px]">
            <Image
              alt=""
              src="/assets/images/portal/hero.jpeg"
              className="h-auto w-full rounded-lg border border-[E48D5C] object-cover mix-blend-multiply"
              fill
              sizes="(max-width: 768px) 100vw, 50vw"
              priority
            />
          </div>
        </div>
      </div>
    </Section>
  );
}
