import {
  EnvelopeIcon,
  PhoneIcon,
  StarIcon as StarIconOutlined,
  HandThumbDownIcon as ThumbDownIconOutlined,
  HandThumbUpIcon as ThumbUpIconOutlined,
} from '@heroicons/react/24/outline';
import {
  StarIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/solid';
import { captureException } from '@sentry/nextjs';
import Markdown from 'markdown-to-jsx';
import React, { useState, VFC } from 'react';
import { DocumentList, Header, useMessages } from 'components';
import {
  isBooleanQuestion,
  QuestionBoolean,
  QuestionString,
  QuestionWithAnswer,
} from 'components/Application/questions';
import { Button } from 'components/Button';

import {
  Application as ApplicationType,
  Enum_Application_Status_Enum,
  User_Education,
  User_Experience,
  User_Licence,
  useUpdateApplicationStatusMutation,
} from 'database/types';
import {
  EducationBlock,
  ExperienceBlock,
  LicenceBlock,
} from 'components/Application/Blocks';
import { ProfilePhoto } from './ProfilePhoto';

export type ApplicationProps = Omit<ApplicationType, 'questions'> & {
  questions?: QuestionWithAnswer[];
  role: 'employer' | 'applicant';
};

export const Application = ({
  id,
  questions,
  role,
  user,
  application_status,
}: ApplicationProps): JSX.Element => {
  const { alert } = useMessages();
  const {
    first_name,
    last_name,
    email,
    phone,
    profile_photo,
    about,
    user_experiences,
    user_educations,
    user_licences,
  } = user;
  const [updateStatus] = useUpdateApplicationStatusMutation();

  const [status, setStatus] = useState(
    application_status.value as Enum_Application_Status_Enum
  );
  const handleStatus = async (value: Enum_Application_Status_Enum) => {
    try {
      await updateStatus({
        variables: {
          id,
          status: value,
          updated_at: new Date().toISOString(),
        },
      });
      setStatus(value);
    } catch (err) {
      if (err instanceof Error) {
        alert({
          key: 'UpdateStatus_Error',
          type: 'error',
          title: 'Error updating application',
          message: err?.message,
          dismissable: true,
        });
      }
      captureException(err);
    }
  };

  return (
    <div className="p-6">
      <div className="space-y-4 sm:space-y-6">
        {/* Header info */}
        <div className="flex flex-row items-center space-x-8">
          {profile_photo && (
            <div className="h-full">
              <ProfilePhoto
                imgKey={profile_photo}
                alt={`${first_name} ${last_name} (profile)`}
              />
            </div>
          )}
          <div className="grow space-y-4">
            <div className="flex flex-col flex-wrap space-y-2">
              <h1 className="text-[28px] font-medium leading-9 tracking-tight">
                {first_name} {last_name}
              </h1>
              <a
                href={`mailto:${email}`}
                className="inline-flex flex-row items-center space-x-4 text-base text-grey hover:text-primary"
              >
                <EnvelopeIcon className="w-4 self-center" />
                <span>{email}</span>
              </a>
              <a
                href={`tel:${phone}`}
                className="inline-flex flex-row items-center space-x-4 text-base text-grey hover:text-primary"
              >
                <PhoneIcon className="w-4" />
                <span>{phone}</span>
              </a>
            </div>
          </div>
        </div>

        {/* Actions */}
        {role === 'employer' && (
          <div className="!mt-4 flex w-full flex-col flex-wrap sm:flex-row sm:justify-end sm:space-x-4">
            {status === 'rejected' ? (
              <Button
                color="grey-dark"
                onClick={() => handleStatus('applied')}
                className="my-2"
              >
                <HandThumbDownIcon className="mr-3 w-6" /> Rejected
              </Button>
            ) : (
              <Button
                color="white"
                onClick={() => handleStatus('rejected')}
                className="my-2"
              >
                <ThumbDownIconOutlined className="mr-3 w-6" /> Reject
              </Button>
            )}
            {status === 'shortlisted' ? (
              <Button
                color="grey-dark"
                onClick={() => handleStatus('applied')}
                className="my-2"
              >
                <StarIcon className="mr-3 w-6" />
                Shortlisted
              </Button>
            ) : (
              <Button
                color="white"
                onClick={() => handleStatus('shortlisted')}
                className="my-2"
              >
                <StarIconOutlined className="mr-3 w-6" />
                Shortlist
              </Button>
            )}

            {status === 'hired' ? (
              <Button
                color="primary"
                onClick={() => handleStatus('applied')}
                className="my-2"
              >
                <HandThumbUpIcon className="mr-3 w-6" />
                Hired
              </Button>
            ) : (
              <Button
                color="white"
                onClick={() => handleStatus('hired')}
                className="my-2"
              >
                <ThumbUpIconOutlined className="mr-3 w-6" />
                Hire
              </Button>
            )}
          </div>
        )}

        {/* About */}
        {about && (
          <div className="space-y-2">
            <Header>About the Applicant</Header>
            <Markdown>{about}</Markdown>
          </div>
        )}

        <div className="!mt-10 space-y-10">
          {/* Questions */}
          {!!questions?.length && (
            <div>
              <Header>Screening Questions</Header>
              {questions.map((question, i) =>
                isBooleanQuestion(question) ? (
                  <div key={i} className="mb-4">
                    <QuestionBoolean {...question} />
                  </div>
                ) : (
                  <div key={i} className="mb-4">
                    <QuestionString {...question} />
                  </div>
                )
              )}
            </div>
          )}

          {/* User History */}
          <ExperienceBlock
            role={role}
            user_experiences={user_experiences as User_Experience[]}
          />
          <LicenceBlock
            role={role}
            user_licences={user_licences as User_Licence[]}
          />
          <EducationBlock
            role={role}
            user_educations={user_educations as User_Education[]}
          />
          <div className="space-y-8">
            <DocumentList user_id={user?.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApplicationCard: VFC<ApplicationProps> = (props) => (
  <div className="default-padding space-y-6 rounded bg-white p-4 shadow sm:p-8 min-h-screen">
    <Application {...props} />
  </div>
);
