//Framework
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import _get from 'lodash.get';

// Components
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { ApplicationCard } from 'components';
import { mapApplication } from 'components/Application/questions';
import CloseIcon from 'components/forms/SelectCloud/CloseIcon';
import { Loading } from 'components/icons/Loading';

import { Application, useGetApplicationByIdQuery, Maybe } from 'database/types';

type ApplicantSideBarProps = {
  applicationId: Maybe<string>;
  allApplicantsIds: string[];
  setApplicationId: (id: Maybe<string>) => void;
};

export const ApplicantSideBar = ({
  applicationId,
  allApplicantsIds,
  setApplicationId,
}: ApplicantSideBarProps): Maybe<JSX.Element> => {
  const [hasNext, setHasNext] = React.useState(false);
  const [hasPrev, setHasPrev] = React.useState(false);
  const { data, loading } = useGetApplicationByIdQuery({
    variables: { id: `${applicationId}` },
    skip: !applicationId,
    pollInterval: 10000,
  });

  useEffect(() => {
    const currentIndex = allApplicantsIds.findIndex(
      (id) => id === applicationId
    );
    setHasNext(currentIndex < allApplicantsIds.length - 1);
    setHasPrev(currentIndex > 0);
  }, [applicationId]);

  const handleApplicantsNavigation = (direction: 'prev' | 'next') => {
    const currentIndex = allApplicantsIds.findIndex(
      (id) => id === applicationId
    );
    const index = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;
    const nextId = allApplicantsIds[index];
    setApplicationId(nextId);
  };

  if (!applicationId) return null;

  return (
    <div>
      <>
        <div
          className="fixed inset-0 z-40 h-screen w-screen bg-black/70"
          onClick={() => setApplicationId(null)}
        />
        <div
          // initial={{ x: '100%' }}
          // animate={{ x: 0 }}
          // exit={{ x: '100%' }}
          // transition={{ duration: 0.3 }}
          className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto lg:w-1/2"
        >
          <div className="absolute right-4 top-4 flex gap-1">
            <button
              type="button"
              onClick={() => handleApplicantsNavigation('prev')}
              disabled={!hasPrev}
              className="disabled:opacity-20"
            >
              <ChevronLeftIcon className="w-6" />
            </button>
            <button
              onClick={() => handleApplicantsNavigation('next')}
              type="button"
              disabled={!hasNext}
              className="disabled:opacity-20"
            >
              <ChevronRightIcon className="w-6" />
            </button>
            <button
              onClick={() => setApplicationId(null)}
              aria-label="Close application sidebar"
              className="z-10"
            >
              <CloseIcon opacity={1} />
            </button>
          </div>
          <div className="bg-white">
            {loading ? (
              <div className="default-padding space-y-6 rounded bg-white p-4 shadow sm:p-8 min-h-screen">
                <Loading/>
              </div>
            ) : (
              <ApplicationCard
                role="employer"
                {...mapApplication(data?.application_by_pk as Application)}
              />
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default ApplicantSideBar;
