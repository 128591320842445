// Third parties
import { motion } from 'framer-motion';

// Types
type FadeFromTopAnimationProps = {
  children: React.ReactNode[];
  delay?: number;
  duration?: number;
  className?: string;
  isIntersecting?: boolean;
};

/*--------------------------------------------------------------------*/

/**
 * Component
 */

export const FadeFromTopAnimation = ({
  children,
  delay = 200,
  duration = 300,
  className,
  isIntersecting = true,
}: FadeFromTopAnimationProps): JSX.Element => {
  const variants = {
    onScreen: { opacity: 1, translateY: '0' },
    offScreen: { opacity: 0, translateY: '-2rem' },
  };

  return (
    <div className={className}>
      {children.map((child, index) => (
        <motion.div
          key={index}
          animate={isIntersecting ? 'onScreen' : 'offScreen'}
          variants={variants}
          transition={{
            duration: duration / 1000,
            delay: (index * delay) / 1000,
          }}
        >
          {child}
        </motion.div>
      ))}
    </div>
  );
};
