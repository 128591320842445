import { forwardRef } from 'react';

type HandleModalVersionProps = {
  version?: 'v1' | 'v2';
  children: React.ReactNode;
};

export const HandleModalVersion = forwardRef<
  HTMLInputElement,
  HandleModalVersionProps
>(function HandleModalVersionRef({ version = 'v1', children }, ref) {
  if (version === 'v1') {
    return (
      <div
        ref={ref}
        className="relative inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
      >
        {children}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className="relative inline-block w-[390px] overflow-hidden rounded-xl border border-solid border-charcoal-50 bg-cream-500 p-8 text-left align-middle shadow-xl transition-all"
    >
      {children}
    </div>
  );
});
