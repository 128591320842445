import { twMerge } from 'tailwind-merge';
import { VFC } from 'react';

export type QuestionBooleanProps = {
  question: string;
  answer: boolean;
};

export const QuestionBoolean: VFC<QuestionBooleanProps> = ({
  question,
  answer,
}) => (
  <div className="flex justify-between space-x-6">
    <span>{question}</span>
    <span
      className={twMerge('font-medium', answer ? 'text-primary' : 'text-grey')}
    >
      {answer ? 'Yes' : 'No'}
    </span>
  </div>
);
